import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../components/loading/Loading.js';
import store from '../stores';
import { trackPageViewStart, trackPageViewEnd, trackActionTrigger } from '../stores/action';

import '../sass/loader.sass';

window.hash = '';
window.isScroll = true;
window.prevHistory = {
  pathname: '',
  hash: '',
  key: '',
};

const Loader = props => {
  const history = useHistory();
  const $html = document.getElementsByTagName('html')[0];
  const $body = document.body;
  const [timer, setTimer] = React.useState(null);

  React.useLayoutEffect(() => {
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;

    if (typeof window !== 'undefined') {
      if (window.prevHistory.pathname !== window.location.pathname) {
        window.prevHistory = {
          origin: window.location.origin,
          ...window.location,
        };

        window.scrollTo(0, 0);

        setTimer(
          setTimeout(() => {
            setTimer(clearTimeout(timer));
            window.isScroll = false;
            loadingAnimate();
            if (window.location.hash) {
              setTimer(
                setTimeout(() => {
                  setTimer(clearTimeout(timer));
                  gotoHash(window.location.hash);
                }, 300)
              );
            }
          }, 300)
        );
      }

      if (
        window.prevHistory.pathname === window.location.pathname &&
        window.location.hash !== window.prevHistory.hash
      ) {
        window.isScroll = false;
        window.prevHistory = window.location;
        gotoHash(window.location.hash);
      }
    }
  }, [timer, loadingAnimate, gotoHash]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.prevHistory.pathname !== window.location.pathname) {
        setTimer(
          setTimeout(() => {
            setTimer(clearTimeout(timer));
            loadingAnimate();
            if (window.location.hash) {
              setTimer(
                setTimeout(() => {
                  setTimer(clearTimeout(timer));
                  gotoHash(window.location.hash);
                }, 300)
              );
            }
            //end
          }, 300)
        );
      }

      window.onload = e => {
        e.preventDefault();

        document.body.className = '';
        detectPath();
        loadingAnimate();
        if (window.location.hash !== '') {
          setTimer(
            setTimeout(() => {
              window.isScroll = false;
              setTimer(clearTimeout(timer));
              setTimer(
                setTimeout(() => {
                  setTimer(clearTimeout(timer));
                  gotoHash(window.location.hash);
                }, 50)
              );
            }, 1500)
          );
        }

        window.prevHistory = {
          pathname: window.location.pathname,
          hash: window.location.hash,
          origin: window.location.origin,
          search: window.location.search,
        };

      };
    }

    return function cleanup() {
      unlisten();
    }
  }, [loadingAnimate, timer, gotoHash, history, $html.className, $html.classList, $body.classList, unlisten]);

  const unlisten = history.listen((listener, action) => {
    if (typeof window !== 'undefined') {
      if (window.prevHistory === '') {
        window.prevHistory = listener;
      }

      if (window.prevHistory.pathname !== listener.pathname) {
        //start
        document.body.className = '';
        detectPath();
        $html.className = 'is-loading is-loading-block';
        $html.classList.remove('fade-in');
        $body.classList.add('is-loading');
        let elem = window || document.documentElement;

        window.prevHistory = {
          origin: window.location.origin,
          ...listener,
        };

        elem.scroll(0, 0);
        setTimer(
          setTimeout(() => {
            setTimer(clearTimeout(timer));
            loadingAnimate();
            if (listener.hash) {
              setTimer(
                setTimeout(() => {
                  setTimer(clearTimeout(timer));
                  gotoHash(listener.hash);
                }, 50)
              );
            }
            //end
          }, 50)
        );
      }

      if (window.prevHistory.pathname === listener.pathname && listener.hash !== window.prevHistory.hash) {
        window.hash = listener.hash;
        window.isScroll = false;
        window.prevHistory = listener;
        gotoHash(listener.hash);
      }
    }
  })

  const detectPath = () => {
    const query = new URLSearchParams(window.location.search);

    if (window.channelId === 'SUPPERAPP' || (query.get('channelId') && query.get('channelId') === 'SUPERAPP')) {
      document.body.classList.add('is-webview');
    }
    document.body.classList.add('is-eservice');


    if (window.location.pathname.indexOf('help-center') > -1) {
      document.body.classList.add('is-help-center');
    }
    // else {
    //   if (window.location.pathname.indexOf('/ebu/') > -1) {
    //     document.body.classList.add('is-ebu');
    //   }
    // }
  };

  const gotoHash = useCallback(hash => {
    if (hash === '#' || hash === '' || !document.getElementById(hash.slice(1))) return;
    let target = document.getElementById(hash.slice(1));
    let rect = target.getBoundingClientRect();
    let elem = window || document.documentElement;
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (window.isIE) {
      // console.log(`isIE`, rect, elem.scrollTop);
      elem.scroll(0, rect.top + scrollY - 100);
    } else {
      // console.log(`notIE`, scrollY + rect.top - 100);
      elem.scrollTo({
        left: 0,
        top: scrollY + rect.top - 100,
        behavior: 'smooth',
      });
    }

    setTimer(
      setTimeout(() => {
        window.isScroll = true;
        setTimer(clearTimeout(timer));
      }, 200)
    );
  });

  const loadingAnimate = useCallback(() => {
    window.loading = true;
    setTimer(
      setTimeout(() => {
        if (window.prevHistory.pathname !== '') {
          if (window.isIE) {
            let elem = window || document.documentElement;
            elem.scroll(0, 0);
          } else {
            window.scrollTo(0, 0);
          }
          if ($body.getElementsByClassName('fui-header').length)
            $body.getElementsByClassName('fui-header')[0].setAttribute('style', '');
        }

        $html.classList.add('fade-in');
        $html.classList.remove('is-loading-block');
        $html.classList.remove('is-loading');
        $body.classList.remove('is-loading');
        setTimeout(() => {
          $html.classList.remove('fade-in');
        }, 1500)

        window.loading = false;
        setTimer(clearTimeout(timer));
      }, 200)
    );
  });

  return (
    <section className='loading'>
      <Loading />
    </section>
  );
};

export default Loader;
