import React from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class LabelInput extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      submitted: false,
      isInvalid: false,
      required: this.props.required || false,
      placeholder: this.props.placeholder || '',
      maxLength: this.props.maxLength || 200,
    };

    this.resetInput = this.resetInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.input.current.value = this.props.getValue();
  }

  componentDidUpdate(prevProps) {
    // console.group(prevProps.value,
    if (prevProps.getValue() !== this.props.getValue()) {
      this.input.current.value = this.props.getValue();
    }
    if (this.props.isFormSubmitted() !== this.state.submitted) {
      // console.log(this.props.value)
      this.setState({
        submitted: this.props.isFormSubmitted(),
        isInvalid: this.props.showError(),
      });
    }
  }

  resetInput() {
    this.input.current.value = '';
    this.props.setValue(null);
  }

  handleChange(event) {
    this.props.setValue(this.input.current.value);
    this.setState({
      submitted: this.props.isFormSubmitted(),
      isInvalid: this.props.showRequired() || this.props.showError(),
    });
    this.props.onChange(this.props.name, this.input.current.value);
  }

  render() {
    const errorMessage = this.props.getErrorMessage();
    return (
      <div className={`${this.props.className ? this.props.className : ''} form-group ${!this.props.isPristine() && this.state.isInvalid ? 'is-invalid' : ''}`}>
        {!!this.props.label ? (
          <label htmlFor={`input-${this.props.name}`} className={this.state.required ? 'is-required' : ''}>
            {this.props.label}
          </label>
        ) : (
            ''
          )}
        <div className='text-input'>
          <input
            onBlur={this.handleChange}
            id={`input-${this.props.name}`}
            type='text'
            ref={this.input}
            onChange={this.handleChange}
            onKeyUp={this.handleChange}
            placeholder={!!this.props.placeholder ? this.props.placeholder : ''}
            value={this.props.getValue() || ''}
            maxLength={this.state.maxLength}
          />
          {this.props.getValue() ? (
            <div className='reset' onClick={this.resetInput}>
              <i className='icon-close'></i>
            </div>
          ) : (
              ''
            )}
        </div>
        {!this.props.isPristine() && this.state.isInvalid ? <span className='error-message'>{errorMessage}</span> : ''}
      </div>
    );
  }
}

LabelInput.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
};

export default withFormsy(LabelInput);
