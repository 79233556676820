import React from 'react';
import { Grid } from '@material-ui/core';
import Header from '../../components/partials/header/Default';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import LifeCircleDramaCarousel from '../../components/partials/card/LifeCircleDramaCarousel';
import LifeCircleSongsCarousel from '../../components/partials/card/LifeCircleSongsCarousel';
import LifeCircleNewsCarousel from '../../components/partials/card/LifeCircleNewsCarousel';
import LifeCircleGoodsCarousel from '../../components/partials/card/LifeCircleGoodsCarousel';
import LifeCirclePromoCarousel from '../../components/partials/card/LifeCirclePromoCarousel';
import SectionAd2 from '../../components/partials/SectionAd2';
import LifeCircleCarousel from '../../components/partials/card/LifeCircleCarousel';

import LifeCircleBanner from '../../components/partials/banner/LifeCircleBanner';

import ToolsItem from '../../components/item/ToolsItem';

import Button from '../../components/Button';
// import AnchorDetect from 'react-anchor-scroll-detect';
import AnchorDetect from '../../components/AnchorDetect';
// import * as Scroll from 'react-scroll';
// import { Link, div, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import OnVisible, { setDefaultProps } from 'react-on-visible';

import * as Mock from '../../mock/LifeCircle';
setDefaultProps({
  bounce: true,
  percent: 20
});

class drama extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0
    }
  }

  render() {
    return (
      <main className="life-circle">
        <Header />
        <NavAnchor
          pageTitle='遠傳生活圈'
          button={{
            text: '回生活圈',
            link: '/',
          }}
        />

        <AnchorDetect className="vertical-anchor-nav" items={['anchor0', 'anchor1', 'anchor2', 'anchor3', 'anchor4']} activeClass="active" offsetTop={145}>
          <li><span>8:00AM</span></li>
          <li><span>9:00AM</span></li>
          <li><span>4:30PM</span></li>
          <li><span>8:00PM</span></li>
          <li><span>11:00PM</span></li>
        </AnchorDetect>
        
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '遠傳生活圈', link: '/' },
            { text: '追劇人生', link: '' },
          ]}
          color='black'
        />

        <OnVisible id="anchor0">
          <LifeCircleBanner {...Mock.dramaBanner0} />
          <LifeCircleDramaCarousel {...Mock.dramaCard} />
        </OnVisible>
          
        <OnVisible id="anchor1">
          <LifeCircleBanner {...Mock.dramaBanner1} />
          <LifeCircleNewsCarousel {...Mock.newsCard} />
        </OnVisible>

        <OnVisible id="anchor2">
          <LifeCircleBanner {...Mock.dramaBanner2} />
          <LifeCircleSongsCarousel {...Mock.koreaSongsCard} />
          <LifeCircleSongsCarousel {...Mock.japanSongsCard} />
        </OnVisible>

        <OnVisible id="anchor3">
          <LifeCircleBanner {...Mock.dramaBanner3} />
          <LifeCircleGoodsCarousel {...Mock.dramaGoodsCard} />
        </OnVisible>

        <OnVisible id="anchor4">
          <LifeCircleBanner {...Mock.dramaBanner4} />
          <LifeCircleSongsCarousel {...Mock.classicSongsCard} />
        </OnVisible>

        
        <ToolsItem {...Mock.dramaToolsItem} />
 
        <LifeCirclePromoCarousel {...Mock.dramaPromoCard} />
  
        <SectionAd2 {...Mock.eventPromoData} />

        <LifeCircleCarousel {...Mock.dramaLifeCircleCard} />
      </main>
    );
  }
}

export default drama;
