import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link';

class HotWord extends React.Component {
  constructor(props) {
    super(props);
    this.keywords = React.createRef();
    this.state = {
      currentTab: 0,
      currentScroll: 0,
      scroller: false,
      hotword: []
    };

    this.setScroller = this.setScroller.bind(this);
    this.doScroll = this.doScroll.bind(this);
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', (e) => this.setScroller);
    }
  }

  componentDidMount() {
    this.setScroller();
    this.setHotword();
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.hotword !== this.props.hotword) {
      this.setHotword();
    }
  }

  setHotword = () => {
    this.setState({ hotword: this.props.hotword.reduce((accr, word, i) => {
      if (word.active) accr.push(word)
      return accr;
    }, [])})
  }

  detectLast() {
    if (!this.keywords.current) return false;
    return (
      this.state.currentScroll + this.keywords.current.clientWidth >= this.keywords.current.children[0].clientWidth
    );
  }

  doScroll = (dir) => {
    let clientW = this.keywords.current.clientWidth;
    if (typeof window !== 'undefined' && window.isIE) {
      if (dir === 'left') this.keywords.current.scrollLeft = this.keywords.current.scrollLeft - clientW;
      if (dir === 'right') this.keywords.current.scrollLeft = this.keywords.current.scrollLeft + clientW;
    } else {
      if (dir === 'left')
        this.keywords.current.scrollTo({
          left: this.keywords.current.scrollLeft - clientW,
          top: 0,
          behavior: 'smooth',
        });
      if (dir === 'right')
        this.keywords.current.scrollTo({
          left: this.keywords.current.scrollLeft + clientW,
          top: 0,
          behavior: 'smooth',
        });
    }
    this.setState({ currentScroll: this.keywords.current.scrollLeft });
  };

  setScroller() {
    let outer = this.keywords.current.clientWidth;
    let inner = this.keywords.current.children[0].clientWidth;

    if (outer < inner) {
      this.setState({ scroller: true });

      this.keywords.current.addEventListener('scroll', (e) => {
        this.setState({ currentScroll: e.target.scrollLeft });
      });
    }
  }

  hasIndex = (word) => {
    let index = -1;
    if(this.state.hotword.length)
      this.state.hotword.forEach((hot, i) => {
        if(hot.value === word.value) index = i
      })

    return index;
  }

  handleClick(item, index, active) {
    let hot = Object.assign(this.state.hotword);
    let oldIndex = this.hasIndex(item);

    if(oldIndex > -1) {
      hot.splice(oldIndex, 1);
    } else {
      hot.push(item)
    }

    this.setState({
      hotword: hot
    });

    if(this.props.onChange) {
      this.props.onChange(hot);
    }
  }

  render() {
    return (
      <div className='keyword-container'>
        <div
          className={`keyword-list ${this.state.scroller ? 'is-scroller' : ''} ${
            this.state.currentScroll > 0 ? 'is-show-before' : ''
          } ${this.detectLast() ? 'is-hide-after' : ''}`}>
          <div className='label'>{this.label}</div>
          <div className='keyword-content' ref={this.keywords}>
            <div className='keyword-group'>
              {this.props.hotword.map((word, i) => (
                <Link
                  role='button'
                  key={`hot-search-${i}`}
                  to={`#`}
                  className={`fui-button is-label ${this.hasIndex(word) > -1 ? 'is-active' : ''}`}
                  onClick={() => { this.handleClick(word, i) }}
                >
                  {word.showName}
                </Link>
              ))}
            </div>
          </div>
          {this.state.scroller ? (
            <div className='keyword-action'>
              <button
                onClick={(e) => {
                  this.doScroll('left');
                }}
                className={`fui-button is-text ${this.state.currentScroll === 0 ? 'disabled' : ''}`}>
                <i className='icon-chevron-left'></i>
              </button>
              <button
                onClick={(e) => {
                  this.doScroll('right');
                }}
                className={`fui-button is-text ${this.detectLast() ? 'disabled' : ''}`}>
                <i className='icon-chevron-right'></i>
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

HotWord.propTypes = {
  path: PropTypes.string,
  hotword: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
  onChange: PropTypes.func
};

export default HotWord;
