import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionCarousel3 from '../../components/partials/card/SectionCarousel3';
import ScrewedSlider from '../../components/partials/carousel/ScrewedSlider';
import PanelButton from '../../components/panelContent/PanelButton';
import Link from '../../components/Link';
class FourPointFive extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <main className="four-point-five">
        <Header />
        <NavAnchor
          pageTitle='<a href="#">4.5語音＋上網方案</a>'
          button={{
            link: '#',
            text: '續約優惠',
          }}
          tabs={[
            { label: '好方案', link: '#program' },
            { label: '推薦方案', link: '#recommand' },
            { label: '智慧生活', link: '#smartLife' },
            { label: '你可能會喜歡', link: '#likes' }
          ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '4.5語音＋上網方案', link: '/' }
          ]}
          color='black'
        />
        <ScrewedSlider
          id='program'
          marquee={false}
          title=''
          moreLink={null}
          list={[
            {
              name: '青春無價，就要給你學生價!',
              shortname: '青春無價',
              color: '#fcdc6d',
              meta: '',
              image: '/resources/cbu/ratePlan/tab-1.png',
              circleImage: '/resources/cbu/ratePlan/tab-1-circle.png',
              alt: "",
              content: '<img class="mr-2" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5>',
              link: '#',
            },
            {
              name: '小資族聰明省荷包，拿最潮愛Phone',
              shortname: '聰明小資',
              color: '#ffa188',
              meta: '',
              image: '/resources/cbu/ratePlan/tab-2.png',
              circleImage: '/resources/cbu/ratePlan/tab-2-circle.png',
              alt: "",
              content: '<img class="mr-2" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5>',
              link: '#',
            },
            {
              name: '家庭吃到飽，一機搞定所有上網需求！',
              shortname: '輕鬆顧家',
              color: '#9edfe9',
              meta: '',
              image: '/resources/cbu/ratePlan/tab-3.png',
              circleImage: '/resources/cbu/ratePlan/tab-3-circle.png',
              alt: "",
              content: '<img class="mr-2" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5>',
              link: '#',
            },
            {
              name: '樂齡新娛樂，輕鬆付，快樂拿！',
              shortname: '樂齡享受',
              color: '#95a3a4',
              meta: '',
              image: '/resources/cbu/ratePlan/tab-4.png',
              circleImage: '/resources/cbu/ratePlan/tab-4-circle.png',
              alt: "",
              content: '<img class="mr-2" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5>',
              link: '#',
            }
          ]}
        />
        <SectionCarousel3 {...{
          title: '推薦方案',
          id: "recommand",
          cards: [
            {
              image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
              title: '輕鬆換新機，再送網購金',
              description: '申辦999(含)以上方案搭配精選手機，送friDay購物折扣金$1000元。',
              link: '#',
            },
            {
              image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
              title: '遠傳iPhone11全系列熱銷中',
              description: '上網吃到飽+網內/網外免費打',
              link: '#',
            },
            {
              image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
              title: '超值續約優惠 盡在遠傳',
              description: '熱銷手機、精選平板、超夯智慧生活商品、friDay購物商品任你選',
              link: '#',
            },
          ],
          more: { text: '看更多', link: '#' },
        }} />
        <SectionCarousel3 {...{
          title: '智慧生活',
          id: "smartLife",
          cards: [
            {
              image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
              title: '新學期、好學期，陪孩子一...',
              description: '學生憑證享指定專案再折$1,000優惠',
              link: '#',
            },
            {
              image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
              title: 'Apple Watch Series 5',
              description: '搭配超夯專案Apple Watch 5 $0起',
              link: '#',
            },
            {
              image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
              title: '超級騎機Gogoro最高折$2...',
              description: '絕配雙飽月付999享上網+網內吃到飽，完成試騎還有機會把Gogoro騎回家',
              link: '#',
            },
          ],
          more: { text: '看更多', link: '#' },
        }} />
        <section id="autoConnection" className="p-0">
          <div className="fui-container">
            <h1 className="">其他服務與資費</h1>
            <div className="d-flex">
              <PanelButton link='#' text='加值服務與特別優惠方案' target="_blank" />
              <PanelButton link='#' text='資費試算' target="_blank" />
            </div>
          </div>
        </section>
        <SectionCarousel3 {...{
          title: '你可能有興趣',
          id: "likes",
          cards: [
            {
              image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
              title: '日本5日不降速上網卡',
              description: 'SIM卡即插即用，每日不到100元上網不降速吃到飽，熱點分享可雙人使用！',
              link: '#',
            },
            {
              image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
              title: '老客戶獨享：月租優惠&贈品',
              description: '月租最低66折，再送購物金與電影套餐！',
              link: '#',
            },
            {
              image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
              title: '遠傳大無限，家用上網新選擇',
              description: '月付$599全家上網吃到飽，免約工、簽線、連接多台設備，一擊搞定全家連網',
              link: '#',
            },
          ],
          more: { text: '看更多', link: '#' },
        }} />
      </main>
    );
  }
}

export default FourPointFive;