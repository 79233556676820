import React from 'react';
import { withRouter } from 'react-router-dom';
import Link from './Link';
import PropTypes from 'prop-types';

class EmmaService extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fixBottom: 24,
      showIframe: false,
      miniIframe: true,
      distance: typeof window !== 'undefined' && window.innerWidth < 960 ? 72 : 76,
      isMobile: window.innerWidth <= 768 || (window.innerWidth <= 1024 && window.innerWidth < window.innerHeight),
    };
    this.emma = React.createRef();
    this.scrollTimeout = false;
    this.clearTimeout = false;
    this.openTimeout = false;
  }

  setScrollTimer = () => {
    this.scrollTimeout = setTimeout(() => {
      this.closeEmma();
      clearTimeout(this.scrollTimeout);
    }, 3000);
  };

  setPosition = () => {
    if (typeof window === 'undefined') return;

    if (!document.getElementsByTagName('footer').length || !this.emma.current) return;

    clearTimeout(this.scrollTimeout);

    let footer =
      document.body.clientHeight -
      document.getElementsByTagName('footer')[0].clientHeight -
      window.innerHeight +
      this.state.distance;
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (scrollY > footer && !document.getElementsByTagName('html')[0].classList.contains('promotion-open')) {
      let header = document.getElementsByClassName('fui-header')[0];
      let bottom = scrollY - footer + header.clientHeight + this.emma.current.clientHeight / 4;

      this.setState({
        fixBottom:
          bottom + 70 > window.innerHeight
            ? window.innerHeight - 120 + header.clientHeight
            : bottom - header.clientHeight,
      });
    } else {
      this.setState({
        fixBottom: 24,
      });
    }

    if (scrollY > 400) {
      this.openEmma();
      this.setScrollTimer();
    } else {
      this.closeEmma();
    }
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (this.props.show) {
        window.addEventListener('scroll', (e) => {
          this.setPosition();
        });
      }

      window.onmessage = (e) => {
        if (e.data === 'close-emma') {
          this.setState({
            miniIframe: true,
            showIframe: false,
          });
        }
      };
    }

    let timer = null;

    timer = setTimeout(() => {
      this.setPosition();
      clearInterval(timer);
    }, 5000);
  }

  openEmma() {
    if (!this.emma.current) return;
    this.emma.current.classList.add('is-open');
  }

  closeEmma() {
    if (!this.emma.current) return;
    this.emma.current.classList.remove('is-open');
    this.clearTimeout = setTimeout(() => {
      this.openEmma();
      clearTimeout(this.clearTimeout);
    }, 12000);
  }

  toggleIframe = (event) => {
    this.setState(
      {
        showIframe: true,
        miniIframe: !this.state.miniIframe,
      },
      () => {
        // [AA Tracking]
        if (window && typeof window.ctrf === 'function')
          window.ctrf(event || {}, `${this.state.showIframe && !this.state.miniIframe ? 'Tab_展開' : 'Tab_關閉'}`);
      }
    );
    // if( !!this.state.showIframe === true )
  };

  renderIcon = () => {
    return this.props.useEmmaAvatar ? (
      <div className='avatar'>
        <img src={process.env.PUBLIC_URL + '/resources/common/images/Chatbot_Emma_24f.gif'} alt='聯繫我們' />
      </div>
    ) : (
      <div className='icon'>
        <img src={process.env.PUBLIC_URL + '/resources/common/images/chat.png'} alt='聯繫我們' />
      </div>
    );
  };

  renderText() {
    if (typeof window === 'undefined') return;

    if (window.location.pathname.indexOf('5g') > -1) {
      return window.$isEn ? 'Contact Us' : '我有興趣';
    }
    if (this.props.useEmmaAvatar) {
      return window.$isEn ? 'Free Consultant' : '問問愛瑪';
    }

    return window.$isEn ? 'Contact Us' : '聯繫我們';
  }

  render() {
    return this.props.show ? (
      <div
        ref={this.emma}
        className={`emma-service ${this.state.showIframe && !this.state.miniIframe ? 'iframe-open' : ''}`}
        style={{ bottom: this.state.fixBottom }}
        id='emma'>
        <div className='iframe-container'>
          {/* <div className="iframe-header">
            <div>遠傳客服小幫手</div>
            <div role="button" onClick={this.toggleIframe}>
              <i className="icon-close"></i>
            </div>
          </div> */}
          {this.state.showIframe ? (
            <iframe
              id='imcsIframe'
              src={
                process.env.REACT_APP_EMMA_URL +
                `/index_new_mobile.do?${this.state.isMobile ? 'location=mobileWeb' : 'location=web'}`
              }
            />
          ) : null}
        </div>
        {this.props.link ? (
          <Link to={this.props.link || '#'} className='emma-service-button'>
            {this.renderIcon()}
            <div className='text'>{this.renderText()}</div>
          </Link>
        ) : (
          <div className='emma-service-button' onClick={this.toggleIframe}>
            {this.renderIcon()}
            <div className='text'>{this.renderText()}</div>
          </div>
        )}
      </div>
    ) : null;
  }
}

EmmaService.propTypes = {
  link: PropTypes.string,
  useEmmaAvatar: PropTypes.bool,
  show: PropTypes.bool, // Boolean
  text: PropTypes.string,
};

export default withRouter(EmmaService);
