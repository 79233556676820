export const splashPromotion = {
  image: {
    md: '/resources/cbu/cbu-index/img-splash-promo@2x.jpg',
    sm: '/resources/cbu/cbu-index/mobile-spash-promotion.jpg',
  },
  title: 'Google Pixel 4 打造完美手機體驗',
  description: '遠傳獨家銷售，現在馬上來體驗',
  action: {
    link: '/',
    text: '立即購買'
  }
}

export const bannerTab = {
  tabs: {
    name: 'EbuBannerTab',
    list: [
      {
        // link: '/index/phone',
        name: 'tab-1',
        icon: '/resources/cbu/cbu-index/phone.svg',
        focusIcon: '/resources/cbu/cbu-index/phone-active.svg',
        label: '手機',
      },
      {
        // link: '/index/rate',
        name: 'tab-2',
        icon: '/resources/cbu/cbu-index/pay.svg',
        focusIcon: '/resources/cbu/cbu-index/pay-active.svg',
        label: '資費',
      },
      {
        // link: '/index/lifecircle',
        name: 'tab-3',
        icon: '/resources/cbu/cbu-index/lifestyle.svg',
        focusIcon: '/resources/cbu/cbu-index/lifestyle-active.svg',
        label: '生活圈',
      },
      {
        // link: '/index/discount',
        name: 'tab-4',
        icon: '/resources/cbu/cbu-index/promotion.svg',
        focusIcon: '/resources/cbu/cbu-index/promotion-active.svg',
        label: '優惠',
      },
      {
        // link: '/index/service',
        name: 'tab-5',
        icon: '/resources/cbu/cbu-index/service.svg',
        focusIcon: '/resources/cbu/cbu-index/service-active.svg',
        label: '服務',
      },
    ],
  },
  tabContent: [
    {
      image: {
        md: '/resources/cbu/cbu-index/kv-bg.jpg',
        sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
      },
      subOption: [
        {
          md: '/resources/cbu/cbu-index/kv-bg.jpg',
          sm: '/resources/cbu/cbu-index/kv-bg-sm@2x.jpg',
        },
        {
          md: '/resources/cbu/cbu-index/kv-bg.jpg',
          sm: '/resources/cbu/cbu-index/kv-bg-sm@2x.jpg',
        },
      ],
      content: {
        dropdown: [
          {
            text: '地表最佳拍照手機大比拼1',
            value: '地表最佳拍照手機大比拼1',
            actions: [
              {
                text: '查看',
                link: '/',
              },
              {
                text: '查看',
                link: '/',
              }
            ],
          }
        ],
        tags: [
          {
            text: 'Airpods',
            link: '#'
          },
          {
            text: 'iPhone 11',
            link: '#'
          },
          {
            text: 'Note 10',
            link: '#'
          }
        ]
      },
      promotion: {
        image: {
          default: '/resources/cbu/cbu-index/promo-iphone.png',
          retina: '/resources/cbu/cbu-index/promo-iphone@2x.png'
        },
        title: '遠傳 iPhone 11 享樂1+1',
        description: '帶走最新 AirPods，免費再送 friDay影音',
        action: {
          text: '更多詳情',
          link: '#',
          target: '_blank'
        }
      }
    },
    {
      image: {
        md: '/resources/cbu/cbu-index/kv-bg.jpg',
        sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
      },
      subOption: [
        {
          md: '/resources/cbu/cbu-index/kv-bg.jpg',
          sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
        },
        {
          md: '/resources/cbu/cbu-index/kv-bg.jpg',
          sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
        },
      ],
      content: {
        dropdown: [
          {
            text: '嚴選網路門市限定方案',
            value: '嚴選網路門市限定方案',
            actions: [
              {
                text: '查看',
                link: '/',
              }
            ],
          },
          {
            text: '嚴選網路門市限定方案',
            value: '嚴選網路門市限定方案',
            actions: [
              {
                text: '查看',
                link: '/',
              }
            ],
          },
        ],
        tags: [
          {
            text: '老客戶續約',
            link: '#',
          },
          {
            text: '499吃到飽',
            link: '#',
          },
          {
            text: '出國上網',
            link: '#',
          },
        ]
      },
      promotion: {
        image: {
          default: '/resources/cbu/cbu-index/promo-applew.png',
          retina: '/resources/cbu/cbu-index/promo-applew@2x.png'
        },
        title: 'Apple Watch Series 5 LTE 11/8上市 現正開放預購',
        // description: '帶走最新 AirPods，免費再送 friDay影音',
        action: {
          text: '更多詳情',
          link: '#',
          target: '_blank'
        }
      }
    },
    {
      image: {
        md: '/resources/cbu/cbu-index/kv-bg.jpg',
        sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
      },
      subOption: [
        {
          md: '/resources/cbu/cbu-index/kv-bg.jpg',
          sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
        },
        {
          md: '/resources/cbu/cbu-index/kv-bg.jpg',
          sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
        },
        {
          md: '/resources/cbu/cbu-index/kv-bg.jpg',
          sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
        },
        {
          md: '/resources/cbu/cbu-index/kv-bg.jpg',
          sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
        },
      ],
      content: {
        dropdown: [
          {
            text: '享受無限追劇的美好人生 1',
            value: '享受無限追劇的美好人生 1',
            actions: [
              {
                text: '查看',
                link: '/',
              },
            ],
          },
          {
            text: '別再追隨別人將資金用在對的科技上',
            value: '別再追隨別人將資金用在對的科技上',
            actions: [
              {
                text: '查看',
                link: '/',
              },
            ],
          },
          {
            text: '創造全新價值',
            value: '創造全新價值',
            actions: [
              {
                text: '查看',
                link: '/',
              },
            ],
          },
          {
            text: '別再追隨別人的腳步將資金用在對的科技上',
            value: '別再追隨別人的腳步將資金用在對的科技上',
            actions: [
              {
                text: '了解更多3-4',
                link: '/',
              },
            ],
          },
        ],
        tags: [
          {
            text: 'friday影音優質好劇',
            link: '#'
          },
          {
            text: '超值折價券',
            link: '#'
          }
        ]
      },
      promotion: {
        image: {
          default: '/resources/cbu/cbu-index/promo-iphone.png',
          retina: '/resources/cbu/cbu-index/promo-iphone@2x.png'
        },
        title: '遠傳 iPhone 11 享樂1+1',
        description: '帶走最新 AirPods，免費再送 friDay影音',
        action: {
          text: '更多詳情',
          link: '#',
          target: '_blank'
        }
      }
    },
    {
      image: {
        md: '/resources/cbu/cbu-index/kv-bg.jpg',
        sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
      },
      subOption: [
        {
          md: '/resources/cbu/cbu-index/kv-bg.jpg',
          sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
        },
        {
          md: '/resources/cbu/cbu-index/kv-bg.jpg',
          sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
        },
      ],
      content: {
        dropdown: [
          {
            text: 'Uber Eats 新客優惠金',
            value: 'Uber Eats 新客優惠金',
            actions: [
              {
                text: '查看',
                link: '/',
              },
            ],
          },
          {
            text: '別再追隨別人將資金用在對的科技上',
            value: '別再追隨別人將資金用在對的科技上',
            actions: [
              {
                text: '查看',
                link: '/',
              },
            ],
          },
        ],
        tags: [
          {
            text: '優惠一次看',
            link: '#',
          },
          {
            text: '白金會員禮',
            link: '#',
          },
          {
            text: '5G免費上網',
            link: '#',
          },
        ],
      },
      promotion: {
        image: {
          default: '/resources/cbu/cbu-index/promo-travel-online.png',
          retina: '/resources/cbu/cbu-index/promo-travel-online@2x.png'
        },
        title: '送日本上網吃到飽 再享冬遊北海道',
        description: '帶走最新 AirPods，免費再送 friDay影音',
        action: {
          text: '更多詳情',
          link: '#',
          target: '_blank'
        }
      }
    },
    {
      image: {
        md: '/resources/cbu/cbu-index/kv-bg.jpg',
        sm: '/resources/cbu/cbu-index/kv-bg-sm.jpg',
      },
      content: {
        links: [
          {
            text: '看帳單',
            link: '#',
          },
          {
            text: '想繳費',
            link: '#',
          },
          {
            text: '查上網用量',
            link: '#',
          },
          {
            text: '查交易記錄',
            link: '#',
          },
          {
            text: '查合約資訊',
            link: '#',
          }
        ],
      },
      promotion: {
        image: {
          default: '/resources/cbu/cbu-index/promo-service-fox.png',
          retina: '/resources/cbu/cbu-index/promo-service-fox@2x.png'
        },
        title: '聰明的愛講智慧音箱',
        description: '帶走最新 AirPods，免費再送 friDay影音',
        action: {
          text: '更多詳情',
          link: '#',
          target: '_blank'
        }
      }
    },
  ],
};


export const promotionCard = {
  title: '你可能會有興趣',
  cards: [
    {
      link: '#',
      image: '/resources/cbu/cbu-index/home-card-hoverbg.jpg',
      retinaImage: '/resources/cbu/cbu-index/home-card-hoverbg@2x.jpg',
      meta: '都會趨勢',
      title: '小米體重計2只要 $399',
      description: '喝杯水都可測量到的精準度喝杯水都可測量到的精準度喝杯水都可測量到的精準度喝杯水都可測量到的精準度',
    },
    {
      link: '#',
      image: '/resources/cbu/cbu-index/home-card-hoverbg.jpg',
      retinaImage: '/resources/cbu/cbu-index/home-card-hoverbg@2x.jpg',
      meta: '都會趨勢',
      title: '上網吃到飽，騎車騎到飽',
      description: '遠傳 X Gogoro Network',
    },
    {
      link: '#',
      image: '/resources/cbu/cbu-index/home-card-hoverbg.jpg',
      retinaImage: '/resources/cbu/cbu-index/home-card-hoverbg@2x.jpg',
      meta: '都會趨勢',
      title: '一起倒數金馬56頒獎典禮',
      description: 'friDay影音獨家線上直播',
    },
    {
      link: '#',
      image: '/resources/cbu/cbu-index/home-card-hoverbg.jpg',
      retinaImage: '/resources/cbu/cbu-index/home-card-hoverbg@2x.jpg',
      meta: '都會趨勢',
      title: '5G零延遲，讓你與世界同步',
      description: '遠立即了解最新的5G技術，跟上時代潮流',
    },
    {
      link: '#',
      image: '/resources/cbu/cbu-index/home-card-hoverbg.jpg',
      retinaImage: '/resources/cbu/cbu-index/home-card-hoverbg@2x.jpg',
      meta: '都會趨勢',
      title: '遠傳生活圈與你共渡日常',
      description: '你值得更輕鬆、更享受的生活',
    },
    {
      link: '#',
      image: '/resources/cbu/cbu-index/home-card-hoverbg.jpg',
      retinaImage: '/resources/cbu/cbu-index/home-card-hoverbg@2x.jpg',
      meta: '都會趨勢',
      title: '搶先預約 iPhone 11',
      description: '最新iPhone11、iPhone11 Pro線上排隊不用揮汗',
    },
  ]
}

export const cbuNews = {
  title: '消息與公告',
  tab: {
    name: 'cbu-news-tab',
    list: [
      {
        label: '最新消息'
      },
      {
        label: '服務公告'
      }
    ]
  },
  tabContent: [
    {
      cards: [
        {
          link: '#',
          public_at: '2020/04/21',
          meta: '業務',
          title: '開啟自學模式沒「疫」外！遠傳攜手線上教育領導品牌...'
        },
        {
          link: '#',
          public_at: '2020/04/20',
          meta: '最新消息',
          title: '遠傳推出最強母親節「寵i獻禮」全新iPhone SE 0元起'
        },
        {
          link: '#',
          public_at: '2020/04/19',
          meta: 'CSR',
          title: '響應世界地球日50週年 遠傳呼朋引伴擴大影響力'
        }
      ],
      more: {
        text: '看更多',
        link: '#'
      }
    },
    {
      cards: [
        {
          link: '#',
          public_at: '2020/04/21',
          meta: '業務',
          title: '開啟自學模式沒「疫」外！遠傳攜手線上教育領導品牌...'
        },
        {
          link: '#',
          public_at: '2020/04/20',
          meta: '最新消息',
          title: '遠傳推出最強母親節「寵i獻禮」全新iPhone SE 0元起'
        },
        {
          link: '#',
          public_at: '2020/04/19',
          meta: 'CSR',
          title: '響應世界地球日50週年 遠傳呼朋引伴擴大影響力'
        }
      ],
      more: {
        text: '看更多',
        link: '#'
      }
    }
  ]
}

export const greetings = {
  greetingMsg: '',
  bubbles: [
    {
      type: 'text',
      text: '我是愛瑪！請問你想找什麼服務呢？還是想看看我們的最新優惠呢？'
    },
    {
      type: 'cards',
      cards: [
        {
          description: '讓我來幫你：',
          actions: [
            {
              link: '#',
              text: '去繳費'
            },
            {
              link: '#',
              text: '查合約'
            }
          ]
        }
      ]
    },
    {
      type: 'cards',
      cards: [
        {
          image: {
            default: '/resources/cbu/cbu-index/bubble-image-1.jpg',
            retina: '/resources/cbu/cbu-index/bubble-image-1@2x.jpg'
          },
          title: '本月送免費看一部影片喔!',
          description: '話題電影、日劇、韓劇應有盡有',
          actions: [
            {
              link: '#',
              text: '馬上領取'
            }
          ]
        },
        {
          image: {
            default: '/resources/cbu/cbu-index/bubble-image-2.jpg',
            retina: '/resources/cbu/cbu-index/bubble-image-2@2x.jpg'
          },
          title: '中午不知道要吃什麼嗎?!',
          description: 'Uber Eats 送遠傳新客優惠金200元',
          actions: [
            {
              link: '#',
              text: '立即索取'
            }
          ]
        },
        {
          image: {
            default: '/resources/cbu/cbu-index/bubble-image-3.jpg',
            retina: '/resources/cbu/cbu-index/bubble-image-3@2x.jpg'
          },
          title: 'friday 57 存錢也能同時賺錢！!',
          description: '現在申辦即可享 2.6% 利率 ',
          actions: [
            {
              link: '#',
              text: '馬上申辦'
            }
          ]
        },
        {
          image: {
            default: '/resources/cbu/cbu-index/bubble-image-3.jpg',
            retina: '/resources/cbu/cbu-index/bubble-image-3@2x.jpg'
          },
          title: 'friday 57 存錢也能同時賺錢！!',
          description: '現在申辦即可享 2.6% 利率 ',
          actions: [
            {
              link: '#',
              text: '馬上申辦'
            }
          ]
        }
      ]
    }
  ]
}

export const lifeCirclePromotion = {
  title: '享受你的生活圈',
  slider: [
    {
      image: {
        md: '/resources/cbu/cbu-index/home-1-1200-x-430.jpg',
        sm: '/resources/cbu/cbu-index/home-1-330-x-360.jpg'
      },
      alt: '',
      title: '追劇人生',
      description: '追劇讓我暫時脫離現實，體驗 1% 極致的美好人生。',
      action: {
        text: '看更多追劇故事',
        link: '#',
        target: '_self',
      },
      icons: [
        {
          link: '#',
          img: '/resources/cbu/cbu-index/friday-shopping-1@2x.png',
          alt: '金泰熙著裝',
          text: '金泰熙著裝',
          description: 'friDay購物'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/friday-57@2x.png',
          alt: '懶人記帳本',
          text: '懶人記帳本',
          description: 'friDay 57'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/friday-music@2x.png',
          alt: '必聽的梨泰院',
          text: '必聽的梨泰院',
          description: 'friDay 音樂'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/friday-photo@2x.png',
          alt: '保存甜蜜回憶',
          text: '保存甜蜜回憶',
          description: 'friDay相片書'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/laundry@2x.png',
          alt: '幫你洗衣服',
          text: '幫你洗衣服',
          description: '潔衣家洗衣服務'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/flight@2x.png',
          alt: '出國上網',
          text: '出國上網',
          description: '遠遊卡'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/flight-video@2x.png',
          alt: '直播新聞',
          text: '直播新聞',
          description: 'friDay影音'
        },
      ]
    },
    {
      image: {
        md: '/resources/cbu/cbu-index/home-2-1200-x-430.jpg',
        sm: '/resources/cbu/cbu-index/home-2-330-x-360.jpg'
      },
      alt: '',
      title: '斜槓老爸',
      description: '不用當超人，當個懂得生活的斜槓老爸。',
      action: {
        text: '看更多斜槓老爸',
        link: '#',
        target: '_self',
      },
      icons: [
        {
          link: '#',
          img: '/resources/cbu/cbu-index/friday-shopping-1@2x.png',
          alt: '金泰熙著裝',
          text: '金泰熙著裝',
          description: 'friDay購物'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/friday-57@2x.png',
          alt: '懶人記帳本',
          text: '懶人記帳本',
          description: 'friDay 57'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/friday-music@2x.png',
          alt: '必聽的梨泰院',
          text: '必聽的梨泰院',
          description: 'friDay 音樂'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/friday-photo@2x.png',
          alt: '保存甜蜜回憶',
          text: '保存甜蜜回憶',
          description: 'friDay相片書'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/laundry@2x.png',
          alt: '幫你洗衣服',
          text: '幫你洗衣服',
          description: '潔衣家洗衣服務'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/flight@2x.png',
          alt: '出國上網',
          text: '出國上網',
          description: '遠遊卡'
        },
        {
          link: '#',
          img: '/resources/cbu/cbu-index/flight-video@2x.png',
          alt: '直播新聞',
          text: '直播新聞',
          description: 'friDay影音'
        },
      ]
    },
  ]
}

export const productMap = {
  title: '我想尋找...',
  data: [
    {
      title: '找網路限定優惠',
      list: [
        { text: '網路門市限定吃到飽', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '新申辦門號', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '攜碼到遠傳', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '老客戶續約', link: '#', target: '_self', icon: 'chevron-right' },
      ],
    },
    {
      title: '找產品資費',
      list: [
        { text: '資費方案', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '優惠方案', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '易付/預付卡', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '國際漫遊', link: '#', target: '_self', icon: 'chevron-right' },
      ],
    },
    {
      title: '找我的專區',
      list: [
        { text: '會員資料查詢', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '訂閱會員好康報', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '密碼變更', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '領取本月免費會員好禮', link: '#', target: '_self', icon: 'chevron-right' },
      ],
    },
    {
      title: '找幫助',
      list: [
        { text: '服務公告', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '帳單/繳費/儲值', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '來電過濾黑名單設定', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '門市據點查詢', link: '#', target: '_self', icon: 'chevron-right' },
      ],
    },
  ]
}