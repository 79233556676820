import axios from 'axios';

export const rechargeOption = async() => {
  /**
   * 若要串接 API
   * 1. 換上正確的 url
   * 2. 檢查 return 是否成功回傳
   * 3. 可再根據需要做判斷與處理後再回傳
   *
//   */
//     return axios('https://staging.vas.fetnet.net/eService_Revamp/api/mock/prepaidDetailController/initPrepaidRechargePage').then(result => {
//       return result.rateChangeInfo;
//     })
  return {
    "msisdn": "012345678",
    "alreadyExpired": true,
    "balanceAmountOverLimit": true,
    "userIsPostpaid": true,
    "amountList": [
        {
            "amount": 100,
            "description": ""
        },
        {
            "amount": 300,
            "description": "儲值成功後即回饋 500MB 上網"
        },
        {
            "amount": 500,
            "description": "儲值成功後即回饋 1.2GB 上網"
        },
        {
            "amount": 1000,
            "description": "儲值成功後即回饋 2.4GB 上網"
        }
    ],
    "havePostpaid": true
  }
}

export const prepaidUsage = async() => {
  /**
   * 若要串接 API
   * 1. 換上正確的 url
   * 2. 檢查 return 是否成功回傳
   * 3. 可再根據需要做判斷與處理後再回傳
   *
   * return axios('https://staging.vas.fetnet.net/eService_Revamp/api/mock/prepaidDetailController/queryPrepaidDetail').then(result => {
   *  return result.rateChangeInfo;
   * })
  */
  return {
    "msisdn": "012345678",
    "msisdnStatus": "G",
    "alreadyExpired": true,
    "expireDate": "2222/22/22",
    "rechargeBalance": "$1,000",
    "rewardBalance": "$1,000",
    "pageSize": 2,
    "dataUsagePlanList": [
        {
            "name": "計日限量型",
            "startDate": null,
            "endDate": null,
            "dueDate": "2222/22/22",
            "totalUsage": null,
            "usedUsage": null,
            "overageUsage": "2GB",
            "byDay": false,
            "byData": false,
            "byDayLimit": true
        },
        {
            "name": "計量型",
            "startDate": null,
            "endDate": null,
            "dueDate": null,
            "totalUsage": "3GB",
            "usedUsage": "1GB",
            "overageUsage": "2GB",
            "byDay": false,
            "byData": true,
            "byDayLimit": false
        },
        {
            "name": "計日型",
            "startDate": null,
            "endDate": null,
            "dueDate": "2222/22/22",
            "totalUsage": null,
            "usedUsage": null,
            "overageUsage": null,
            "byDay": true,
            "byData": false,
            "byDayLimit": false
        }
    ],
    "rewardUsagePlanList": [
        {
            "name": "計量型",
            "startDate": null,
            "endDate": null,
            "dueDate": null,
            "totalUsage": "3GB",
            "usedUsage": "1GB",
            "overageUsage": "2GB",
            "byDay": false,
            "byData": true,
            "byDayLimit": false
        },
        {
            "name": "計日型",
            "startDate": null,
            "endDate": null,
            "dueDate": "2222/22/22",
            "totalUsage": null,
            "usedUsage": null,
            "overageUsage": null,
            "byDay": true,
            "byData": false,
            "byDayLimit": false
        }
    ],
    "totalUsagePlan": {
        "name": null,
        "startDate": null,
        "endDate": null,
        "dueDate": null,
        "totalUsage": "3GB",
        "usedUsage": "1GB",
        "overageUsage": "2GB",
        "byDay": false,
        "byData": false,
        "byDayLimit": false
    }
  }

}