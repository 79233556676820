import React from 'react';
import Button from '../Button';

import PropTypes from 'prop-types';

class PanelButton extends React.Component {
  render() {
    return (
      <div className={`fui-panel-button ${!!this.props.className ? this.props.className : ''}`}>
        <Button
          link={this.props.link}
          target={this.props.target}
          btnStyle={this.props.btnStyle === 'solid' ? 'secondary' : 'text'}
        >
          {this.props.text}
        </Button>
      </div>
    );
  }
}

PanelButton.propTypes = {
  btnStyle: PropTypes.string, // text || solid, default text
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
  target: PropTypes.string // _blank
};

export default PanelButton;
