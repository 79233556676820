import React, { Component } from 'react'
import FormBanner from '../../components/partials/banner/FormBanner';
import Modal from 'react-modal';
import ImageUpload from '../../components/form/ImageUpload';
import RadioGroup from '../../components/form/RadioGroup';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Dropdown from "../../components/Dropdown";
import LabelInput from '../../components/form/LabelInput';
import LabelTextarea from '../../components/form/LabelTextarea';
import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';
let form = {
  name: { value: '', required: true },
  type: { value: '0', required: true },
  phone: { value: '', required: true },
  cellphone: { value: '', required: true },
  phone2: { value: '', required: true },
  fax: { value: '', required: true },
  email: { value: '', required: true },
  submitWay: { value: '', required: false },
  issue: { value: '', required: true },
  address: { value: '', required: true },
  captcha: { value: '', required: true },
}
let cardForm = {
  identity_image_1: { value: null, required: true },
  identity_image_2: { value: null, required: true },
  identity_image_3: { value: null, required: true }
}
class Coop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: form,
      deleteTarget: '',
      cardForm: cardForm,
      isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
      modalOpen: false,
    }
  }
  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', e => {
        this.setState({
          isMobile: window.innerWidth < 960
        })
      })
  }
  addFile = (name, file) => {
    // console.log({ name, file });
    let cardForm = Object.assign(this.state.cardForm);
    cardForm[name].value = Object.assign(file[0], {
      preview: URL.createObjectURL(file[0]),
    });

    this.setState({
      cardForm,
    });
  };
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }
  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }
  deleteFile = (name) => {
    this.setState({
      deleteTarget: name,
      modalOpen: true
    })
  };
  confirmDelete = () => {
    let cardForm = Object.assign(this.state.cardForm);
    cardForm[this.state.deleteTarget].value = null;
    this.setState({
      cardForm,
      deleteTarget: '',
      modalOpen: false
    });
  }
  reset = () => {
    let newForm = Object.assign(this.state.form);
    let newCardForm = Object.assign(this.state.cardForm);
    newForm = {
      name: { value: '', required: true },
      type: { value: '0', required: true },
      phone: { value: '', required: true },
      cellphone: { value: '', required: true },
      email: { value: '', required: true },
      submitWay: { value: '', required: false },
      issue: { value: '', required: true },
      captcha: { value: '', required: true },
      phone2: { value: '', required: true },
      fax: { value: '', required: true },
      address: { value: '', required: true },
    };
    newCardForm = {
      identity_image_1: { value: null, required: true },
      identity_image_2: { value: null, required: true },
      identity_image_3: { value: null, required: true }
    };
    this.setState({
      form: newForm,
      cardForm: newCardForm
    })
    this.forceUpdate()
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='合作提案'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="合作提案"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <div className='fui-container'>
          <Formsy className='w-100' onValid={() => this.enableButton()} onInvalid={() => this.disableButton()}>
            <h3 className="is-text-darkgray70">請填寫資料</h3>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <LabelInput
                  name='name'
                  required
                  label='寄件人姓名'
                  className="hide-required"
                  placeholder='輸入寄件人姓名'
                  value={this.state.form.name.value}
                  validationErrors='請輸入正確的寄件人姓名'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelInput
                  name='phone'
                  required
                  label='公司名稱'
                  className="hide-required"
                  placeholder='輸入公司名稱'
                  value={this.state.form.phone.value}
                  validationErrors='請輸入正確的公司名稱'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelInput
                  name='address'
                  required
                  label='公司地址'
                  className="hide-required"
                  placeholder='輸入公司地址'
                  value={this.state.form.address.value}
                  validationErrors='請輸入正確的公司地址'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelInput
                  name='email'
                  required={true}
                  label='電子郵件'
                  className="hide-required"
                  placeholder='輸入電子郵件'
                  value={this.state.form.email.value}
                  validationErrors='請輸入正確的電子郵件'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelInput
                  name='cellphone'
                  required
                  label='聯絡電話一'
                  className="hide-required"
                  placeholder='輸入聯絡電話一'
                  value={this.state.form.cellphone.value}
                  validationErrors='請輸入正確的聯絡電話一'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelInput
                  name='phone2'
                  required={true}
                  label='聯絡電話二(選填)'
                  className="hide-required"
                  placeholder='輸入聯絡電話二(選填)'
                  value={this.state.form.phone2.value}
                  validationErrors='請輸入正確的聯絡電話二(選填)'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelInput
                  name='fax'
                  required={true}
                  label='傳真電話(選填)'
                  className="hide-required"
                  placeholder='輸入傳真電話(選填)'
                  value={this.state.form.fax.value}
                  validationErrors='請輸入正確的傳真電話(選填)'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  validationErrors='請選擇下方試算方案'
                  onChange={this.onChange}
                  label='提案類型'
                  name='type'
                  className='is-3-in-row hide-required'
                  required={this.state.form.type.required}
                  default={this.state.form.type.value}
                  options={[
                    {
                      value: '0',
                      label: '廣告合作'
                    },
                    {
                      value: '1',
                      label: '行銷合作'
                    },
                    {
                      value: '3',
                      label: '多媒體服務提案'
                    },
                    {
                      value: '4',
                      label: '手機與配件產品合作'
                    },
                    {
                      value: '5',
                      label: '門市加盟與展店提案'
                    },
                    {
                      value: '6',
                      label: '新事業/新技術提案'
                    },
                    {
                      value: '7',
                      label: '企業方案'
                    },
                    {
                      value: '8',
                      label: '其他'
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="hide-required form-group mb-0">
                  <LabelTextarea
                    placeholder='請輸入內容'
                    label='提案內容'
                    className='mb-2'
                    maxLength={80}
                    name='issue'
                    value={this.state.form.issue.value}
                    onChange={this.onChange}
                  />
                </div>
              </Grid>

              <Grid container spacing={2} className='fui-upload-container'>
                <Grid item xs={12}>
                  <div className="hide-required form-group mb-0 w-100">
                    <label className='mb-0'>上傳資料</label>
                  </div>
                  <ul style={{ paddingLeft: 30 }} className='mb-3 w-100'>
                    <li style={{ lineHeight: 1.75 }}>支援的檔案類型：Acrobat PDF, Microsoft Word, Microsoft Excel, Microsoft PowerPoint。</li>
                    <li style={{ lineHeight: 1.75 }}>最多可以上傳3筆檔案，每筆資料不得超過2MB。</li>
                  </ul>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <ImageUpload
                    name='identity_image_1'
                    addFile={(files) => this.addFile('identity_image_1', files)}
                    deleteFile={() => this.deleteFile('identity_image_1')}
                    files={[this.state.cardForm.identity_image_1.value]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <ImageUpload
                    name='identity_image_2'
                    addFile={(files) => this.addFile('identity_image_2', files)}
                    deleteFile={() => this.deleteFile('identity_image_2')}
                    files={[this.state.cardForm.identity_image_2.value]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <ImageUpload
                    name='identity_image_3'
                    addFile={(files) => this.addFile('identity_image_3', files)}
                    deleteFile={() => this.deleteFile('identity_image_3')}
                    files={[this.state.cardForm.identity_image_3.value]}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6} className='mt-md-8 mt-3 pb-0'>
                <div className="captcha">
                  <div className="form-group mb-0">
                    {/* <input id="vertifyNum" placeholder="請輸入驗證碼" className="form-control" /> */}
                    <LabelInput
                      name='captcha'
                      required
                      label=''
                      className="hide-required"
                      placeholder='輸入驗證碼'
                      value={this.state.form.captcha.value}
                      validationErrors='請輸入正確的驗證碼'
                      onChange={this.onChange}
                    />
                    <div className="captcha-box">
                      <div className="captcha-number">
                        <img src="https://cldup.com/gZ7MdGePW6-3000x3000.png" alt="驗證碼圖片" />
                      </div>
                      <button type="button" className="captcha-reload">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcYiIiIeHh39/f4iIiIeHh4eHh4iIiIiIiIiIiIeHh4eHh4WFhYiIiImJiYiIiOpIBKYAAAAPdFJOUwDfIBCgfT+/75DPYFCvUPAcHYoAAAErSURBVHjatVKxSgNBFHzEeCKxOUHbTSpLt1Hbs7JUEOsNIraxsLALYiHYnHDgD1hoFwt7W2vF2m84uSMxiCNvdzWDKaycZt/Ozt7OvHvyb5i5v8XFS1sYyQkUwzviGq8IKEn7qPsbA6D+4WaB0ZUVOTBAJhEOKHzRMqgitwAsx3IFuAxVh77v8BGKbSzKN5oorfcDtCchDHbC4TtZ7uFMl1W8ETmHT10ekHFgVOGdLncmL3Xpwwph4Ld6RAgXMRSGmyI30jTHabokfL1hoMjE8EMd5Sq2FKXq22lalo41pqZl6bVPWwtLx9Y3fmRZ+iwh55FMoUcN7dIfLmI5X1MPyqBIBhN7zThFSR9kzwE43tvKeZh0MiIKMrAW2SdhtDYBnO/KL6wf7v8x7V/iaqjxLs+LqgAAAABJRU5ErkJggg==" alt="button" />
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>

              <div className="page-action mb-md-10 w-100 mt-md-4 mt-2">
                <Button btnStyle='primary' size="large" className={!!this.state.form.captcha.value ? '' : 'disabled'} onClick={() => { this.submit() }}>確認送出</Button>
                <Button btnStyle='secondary' size="large" onClick={() => { this.setState({ step: 1 }) }}>取消</Button>
                <div className={`align-center mb-md-- mb-7 ${this.state.isMobile ? 'd-inline-block w-100' : 'd-inline'}`}>
                  <a onClick={() => { this.reset() }} className="underline-link is-text-black50 is-text-medium" style={{ margin: 12 }}>
                    重填表單
                </a>
                </div>
              </div>
            </Grid>
          </Formsy>
        </div>
        <Modal
          onAfterOpen={e => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={e => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel="Example Modal"
          className="fui-prompt-modal">
          <div className="fui-prompt-modal-body">
            <h4>您將刪除檔案</h4>
            <p>是否確定刪除上傳檔案？</p>
            <div className="fui-buttons">
              <Button btnStyle='primary' onClick={() => this.confirmDelete()}>確定</Button>
              <Button btnStyle='secondary' onClick={() => this.setState({ modalOpen: false, deleteTarget: '' })}>取消</Button>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}

export default Coop;