import React from 'react';
import Link from '../Link';
import PropTypes from 'prop-types';
import Button from '../Button';

const Card = props => {
  const [isEn, setIsEn] = React.useState(false);

  const [isMobile, setIsMobile] = React.useState(typeof window !== 'undefined' && window.innerWidth < 768);

  React.useEffect(() => {
    setIsEn(typeof window !== 'undefined' && window.$isEn);
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', e => {
        setIsMobile(window.innerWidth < 768)
      })
    } 
  }, []);

  const renderCardContent = () => {
    return (
      <div className='fui-card-action'>
        {props.image ? (
          <div className='fui-card-image'>
            <img src={props.image} srcSet={props.retinaImage || props.image} alt={props.meta} style={{ width: 114, height: 114 }} />
          </div>
        ) : (
          ''
        )}
        <div className='fui-card-caption'>
          <div className='fui-card-content'>
            <h6 className='fui-card-title'>{props.title}</h6>
            {!props.album ? (
              ''
            ) : (
              <p className='fui-card-description body-1' dangerouslySetInnerHTML={{ __html: props.album }}></p>
            )}
            {!props.signer ? (
              ''
            ) : (
              <p className='fui-card-description body-1' dangerouslySetInnerHTML={{ __html: props.signer }}></p>
            )}
            <Button btnStyle='secondary' link={props.link} size='small' className="">{props.action.text}</Button>
          </div>
        </div>
      </div>
    );
  };

  return <div className={`fui-card ${props.className ? props.className : ''}`}>{renderCardContent()}</div>;
};

Card.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  album: PropTypes.string,
  signer: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};

export default Card;
