import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';

class CbuPromotion extends React.Component {
  constructor(props) {
    super(props);
    this.promo = React.createRef();
    this.state = {
      isVisible: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScroll, false);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.getScroll);
  }

  getScroll = () => {
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    this.setState({
      isVisible: this.promo.current.offsetTop - scrollY < window.innerHeight / 2,
    });
  };

  render() {
    return (
      <section
        id={this.props.id}
        ref={this.promo}
        className={`fui-cbu-promotion ${this.state.isVisible ? 'is-open' : ''}`}>
        <Link to={this.props.action.link} target={this.props.action.target || '_self'} onClick={(event) => {
          // [AA Tracking]
          if (window && typeof(window.ctrf) === 'function') window.ctrf(event || {}, `${this.props.image.alt || this.props.title}`);
        }}>
          <div className='fui-container'>
            <img
              src={this.props.image.default}
              srcSet={this.props.image.retina || this.props.image.default}
              alt={this.props.image.alt || this.props.title}
            />
            <div className='content'>
              <h4 dangerouslySetInnerHTML={{ __html: this.props.title }}></h4>
              {this.props.description ? <p>{this.props.description}</p> : null}
            </div>
            <div className='action'>
              <div className='fui-button is-secondary is-reverse'>
                <span className='text'>{this.props.action.text}</span>
              </div>
            </div>
          </div>
        </Link>
      </section>
    );
  }
}

CbuPromotion.propTypes = {
  image: PropTypes.shape({
    default: PropTypes.string,
    retina: PropTypes.string,
    alt: PropTypes.string,
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    target: PropTypes.string,
  }),
};

export default CbuPromotion;
