import React, { Component } from 'react';
import TabPane from '../../components/tab/TabPane';
import Tab from '../../components/tab/Tab';
import PropTypes from 'prop-types'
import Slider from 'react-slick';
import Button from '../../components/Button';
import SectionAd3 from '../../components/partials/SectionAd3';
import SectionCarousel3 from '../../components/partials/card/SectionCarousel3';
class AddValueSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSubTab: 0,
        }
        this.programSetting = {
            dots: false,
            infinite: false,
            arrows: true,
            slidesToShow: 3,
            draggable: true,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        arrows: true,
                        slidesToShow: 1,
                        variableWidth: true,
                    },
                },

                {
                    breakpoint: 768,
                    settings: {
                        arrows: true,
                        slidesToShow: 2,
                        variableWidth: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: true,
                        slidesToShow: 1,
                        variableWidth: true
                    }
                }
            ],
        };
    }
    changeSubTab = value => {
        this.setState({
            currentSubTab: value,
        });
    };
    render() {
        return (
            <div className="ribbon-bg">
                <section className='fui-solution-tab'>
                    <div className='tab-content'>
                        {
                            <TabPane active={true}>
                                <div className='fui-container'>
                                    <h1 id={this.props.id} className="add-value-title">{this.props.h1}</h1>
                                    <Tab {...this.props.tab} default={0} onChange={e => this.changeSubTab(e)} />
                                    {this.props.tabContent.map((child, j) => (
                                        < TabPane
                                            active={this.state.currentSubTab === j}
                                            key={`${this.props.tab.name}-${j}-cards`}>
                                            {
                                                <Slider {...this.programSetting} className='horizontal-cards rounded-arrow'>
                                                    {child.program.map((card, idx) => (
                                                        <div key={idx} className='fui-card is-card-program'>
                                                            {card.tag ? <div className={`tag tag-${card.tag.color}`}>{card.tag.text}</div> : null}
                                                            <img src={process.env.PUBLIC_URL + card.icon} alt={card.iconAlt} />
                                                            <h3 className="mb-0">{card.title}</h3>
                                                            <ul>
                                                                {card.list.map(item => (
                                                                    <li key={item}>
                                                                        <i className="icon-check is-text-accent" />
                                                                        <div className='subtitle is-text-darkgray50'>{item}</div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            {card.button.length > 1 ? <div className="form-group two-buttons">
                                                                <Button btnStyle='primary' size='medium' link={card.button[0].link}>{card.button[0].title}</Button>
                                                                <Button btnStyle='secondary' size='medium' link={card.button[1].link}>{card.button[1].title}</Button>
                                                            </div> : <div className="form-group mt-2">
                                                                    <Button btnStyle='primary' size='medium' link={card.button[0].link}>{card.button[0].title}</Button>
                                                                </div>}
                                                        </div>
                                                    ))}
                                                </Slider>
                                            }
                                        </TabPane>
                                    ))}
                                </div>
                            </TabPane>
                        }
                    </div>
                </section>
                <SectionAd3
                    {...this.props.ad}
                />
                <SectionCarousel3 {...this.props.carousel} />
            </div>
        );
    }
}

AddValueSection.propTypes = {
    id: PropTypes.string,
    h1: PropTypes.string,
    tab: PropTypes.object,
    tabContent: PropTypes.array,
    ad: PropTypes.object,
    carousel: PropTypes.object,
    more: PropTypes.object,
}

export default AddValueSection;