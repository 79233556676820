import React from 'react';
import { Grid } from '@material-ui/core';
import MultiRuler from '../../components/partials/MultiRuler';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';

import * as Validation from '../../utils/validation';

class Lost extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef()
        this.state = {
            analysisList: [
                {
                    name: '數據服務',
                    color: '#5cc3d2'
                },
                {
                    name: '語音服務',
                    color: '#9edfe9'
                },
                {
                    name: '代收服務',
                    color: '#f3cf63'
                },
                {
                    name: '國際服務',
                    color: '#fc7b66'
                },
                {
                    name: '加值服務',
                    color: '#ffa188'
                },
                {
                    name: '數據與語言服務',
                    color: '#eff4f1'
                },
            ],
            analysisData: [
                {
                    title: '3G',
                    analysis: [
                        {
                            percentage: 20,
                            color: '#5cc3d2'
                        },
                        {
                            percentage: 20,
                            color: '#9edfe9'
                        },
                        {
                            percentage: 20,
                            color: '#f3cf63'
                        },
                        {
                            percentage: 20,
                            color: '#fc7b66'
                        },
                        {
                            percentage: 20,
                            color: '#ffa188'
                        },
                    ]
                },
                {
                    title: '4G',
                    analysis: [
                        {
                            percentage: 20,
                            color: '#5cc3d2'
                        },
                        {
                            percentage: 20,
                            color: '#9edfe9'
                        },
                    ]
                },
                {
                    title: '5G',
                    analysis: [
                        {
                            percentage: 20,
                            color: '#5cc3d2'
                        },
                        {
                            percentage: 20,
                            color: '#9edfe9'
                        },
                    ]
                },
            ],
            percentage: 20,
            canSubmit: false,
            form: {
                id: { value: '', required: true },
                id_number: { value: '', required: true },
            },
            login: false,
            noData: false
        }
    }

    onChange = (name, value) => {
        let newForm = Object.assign(this.state.form);
        newForm[name].value = value;
        this.setState({
            form: newForm
        })
    }


    enableButton = () => {
        this.setState({
            canSubmit: true
        })
    };
    disableButton = () => {
        this.setState({
            canSubmit: false
        })
    };

    render() {
        return (
            <main>
                <NavAnchor
                    pageTitle='漫遊與門號服務'
                    button={{
                        text: '回個人專區',
                        link: '/',
                    }}
                />

                <FormBanner
                    title="掛失服務"
                    image={{
                        md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
                        sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
                    }}
                />

                <section className="pt-md-7 pt-4">
                    <div className="fui-container">
                        <h4 className="is-text-darkgray50 mb-md-7 mb-4">當您的SIM卡遺失或被竊，您可透過網站辦理掛失服務。</h4>
                        <div className="paper">
                            <h4 className="mb-0 mt-md-4 mt-0">掛失須知</h4>
                            <ul className="green-dot-list">
                                <li className='is-text-medium'>計費方式：月租費用和所有須付費加值服務將照常收費，接聽電話無須收費。</li>
                            </ul>
                        </div>
                        <div className="paper list-info pb-md-7 pb-4">
                            <h4 className="mt-md-4 mt-0">掛失期間功能限制說明</h4>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <img src="/resources/cbu/e-service/images/available-feature@2x.png" alt="img" />
                                    <div className="d-inline-block">
                                        <h4 className="mb-0">可使用功能</h4>
                                        <ul className="check-list">
                                            <li>
                                                <p className="m-0 d-inline-block">接聽電話</p>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <img src="/resources/cbu/e-service/images/unavailable-feature@2x.png" alt="img" />
                                    <div className="d-inline-block">
                                        <h4 className="mb-0">不可使用功能</h4>
                                        <ul className="check-list uncheck-list">
                                            <li>
                                                <p className="m-0 d-inline-block">撥打電話</p>
                                            </li>
                                            <li>
                                                <p className="m-0 d-inline-block">手機上網</p>
                                            </li>
                                            <li>
                                                <p className="m-0 d-inline-block">發送文字、MMS多媒體簡訊</p>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        {
                            this.state.login ?
                                <section className="pb-md-0 pb-5">
                                    <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={8}>
                                                <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                                                <LabelInput
                                                    className="mb-2 hide-required"
                                                    name='id_number'
                                                    required={this.state.form.id_number.required}
                                                    label='證照號碼'
                                                    validations={{
                                                        idNumberValid: Validation.idNumberValid
                                                    }}
                                                    validationErrors={{
                                                        isDefaultRequiredValue: '請輸入證照號碼'
                                                    }}
                                                    placeholder='請輸入證照號碼'
                                                    value={this.state.form.id_number.value}
                                                    onChange={this.onChange}
                                                />
                                                <p className="body-1 is-text-darkgray50 mt-0 mb-3">
                                                    身分說明：<br />
                                                        個人戶：身分證字號<br />
                                                        公司戶：統一編號或稅籍編號<br />
                                                        外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                                                    </p>
                                                <div className="page-action">
                                                    <Button
                                                        onClick={() => { this.setState({ login: true }) }}
                                                        link="#"
                                                        className={this.state.canSubmit ? '' : 'disabled'}
                                                        btnStyle='primary'
                                                        size='large'>
                                                        送出申請
                                                     </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Formsy>
                                </section> : <div className="page-action mt-md-7 "><Button onClick={() => { this.setState({ login: true }) }} link="#" className="mb-0" btnStyle='primary' size='large'>申請掛失服務</Button></div>
                        }

                    </div>
                </section>
                <SectionCollapseInfo
                    title="貼心小叮嚀"
                    content={`
                            <ol>
                            <li>掛失期間月租費用和所有須付費加值服務都將持續計算收費，若您需異動服務項目請洽詢客服中心。</li>
                            <li>掛失期間門號無法外撥，且無法手機上網和發送文字/MMS多媒體簡訊，僅可接聽電話(接聽電話無須收費)。</li>
                            <li>如您欲進行補卡可透過「遺失補卡」申請，申請後您可於2~3個工作天內於帳單地址收到卡片，補卡完成將於下期帳單收取換卡費300元．(若需寄至其他地址請透過客服中心處理)。</li>
                            </ol>
                        `}
                />


            </main >
        )
    }
}

export default Lost;