import React from 'react';

import { Grid } from '@material-ui/core';
import Item from '../item/Item';

import Autocomplete from 'react-autocomplete';
import Button from '../Button';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class MenuAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    let query = '';
    if (typeof window !== 'undefined') {
      query = new URLSearchParams(window.location.search);
    }
    this.inputElem = React.createRef();
    this.state = {
      isEBU: typeof window !== 'undefined' && window.location.pathname.indexOf('ebu') > -1,
      showKeywordMenu: true,
      selectedItem: null,
      keywordMenu: this.props.defaultKeyword.slice(0, 6),
      keyword: query.get('keyword') || '',
      currentMenu: 0,
      currentMenuContent: this.props.content[0],
      inputEmpty: false,
    };
  }

  highlightKeyword(word) {
    if (!this.state.keyword || typeof this.state.keyword !== 'string') return word;
    // debugger
    let value = this.state.keyword.toLowerCase();
    let index = word.toLowerCase().indexOf(value);
    
    if (index === -1 || value.value === '') {
      return word;
    } else {
      return (
        word.substring(0, index) +
        "<span class='highlight'>" +
        word.substring(index, index + value.length) +
        '</span>' +
        word.substring(index + value.length)
      );
    }
  }

  countryMenu = (comp) => {
    const $this = this;

    return (
      <div className='fui-menu-body'>
        <Grid container>
          <Grid item xs={4} sm={3} md={3} lg={3}className='menu-header'>
            <div className='menu-content'>
              <div className='list'>
                {this.props.tabs.map((label, i) => (
                  <h6
                    role='button'
                    key={`menu-header-item-${i}`}
                    className={`fui-item mt-0 mb-2 ${i === this.state.currentMenu ? 'is-active' : ''}`}
                    onClick={(e) => this.changeCategory(i)}>
                    {label.label}
                  </h6>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={8} sm={9} md={9} lg={9} className='menu-body pb-0'>
            <div className='menu-content'>
              <Grid container>
                <Grid item xs={12}md={12} className='mb-2'>
                  <Grid container>
                    {this.state.currentMenuContent.all.map((item, i) => (
                      <Grid item xs={12} md={6} key={`meun-content-list-item-${i}`}>
                        <div className='fui-item item' role='button' onClick={e => this.setCountry(item.showName)}>
                          <span className='content'>{item.showName}</span>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  resetInput = () => {
    this.setState({
      keyword: '',
      value: '',
    });
  };

  setCountry = (item) => {
    const $this = this;
    this.setState({
      showKeywordMenu: false,
      keyword: item,
      value: item
    });

    this.forceUpdate();
    setTimeout(() => {
      if($this.props.onClick) {
        $this.props.onClick(item);
      } else {
        $this.doSearch();
      }
    }, 300)
  }

  handleKeyDown = (event) => {
    const $this = this;

    if (event.keyCode === 13) {
      let value = event.currentTarget.value
      this.setState({
        showKeywordMenu: true,
        keyword: this.state.keywordMenu.length ? this.state.keywordMenu[0].showName : value
      })
      
      this.forceUpdate();
      this.inputElem.current.blur();

      setTimeout(() => {
        if($this.props.onClick) {
          $this.props.onClick(this.state.keyword);
        } else {
          $this.doSearch();
        }
      }, 300)
    }
  };

  handleSelect = (value, item) => {
    this.setState({
      value: value,
      keyword: value
    })

    this.forceUpdate();

    setTimeout(() => {
      if(this.props.onClick) {
        this.props.onClick(item.showName);
      } else {
        this.doSearch();
      }
    }, 300)
  }

  handleChange = (e) => {
    this.setState({
      showKeywordMenu: true,
      keyword: e.currentTarget.value,
    });

    let menu = this.searchKeyword(e.currentTarget.value);
    this.setState({ keywordMenu: menu });
    this.forceUpdate();
  }

  doOpen = () => {
    if(this.state.keyword === '') {
      this.inputElem.current.getElementsByTagName('input')[0].focus();
    } else {
      if(this.props.onClick) {
        this.props.onClick(this.state.keyword);
      } else {
        this.doSearch();
      }
    }
  }

  changeCategory = (i) => {
    this.setState({
      currentMenu: i,
      currentMenuContent: this.props.content[i],
    });

    this.forceUpdate();
  };

  searchKeyword = (val) => {
    let value = val.toLowerCase();
    // let value = val;
    if (value === '') return [];

    return this.props.content.reduce((accr, menuContent, index) => {
      // let result = menuContent.all.filter((item) => item.text.toLowerCase().indexOf(value) > -1);
      let result = menuContent.all.filter((item) => item.value.toLowerCase().indexOf(value) > -1 || item.showName.toLowerCase().indexOf(value) > -1);
      return [...accr, ...result];
    }, []);
  };

  inputEmpty = () => {
    this.setState({
      inputEmpty: true,
    });
  };

  doSearch() {
    this.props.history.push(`/originNumberRoaming/Country?country=${this.state.keyword}`);
  }

  render() {
    const $this = this;
    return (
      <div className='menu-autocomplete'>
        <Grid container>
          <Grid item md={12}>
            <div className='keyword-input-container' id={this.props.id} ref={this.inputElem}>
              {this.props.title ? <h2>{this.props.title}</h2> : ''}
              <Autocomplete
                wrapperStyle={{ width: '100%', position: 'relative' }}
                getItemValue={(item) => item.showName}
                items={this.state.keywordMenu}
                value={this.state.keyword}
                onKeyDown={e => this.handleKeyDown(e)}
                renderInput={(props) => (
                  <div className={`fui-input-group is-keyword`}>
                    <div className='fui-input'>
                      <input
                        {...props}
                        type='text'
                        value={this.state.keyword}
                        onBlur={e => e.target.blur()}
                        onChange={e => this.handleChange(e)}
                        placeholder={this.props.placeholder || '立即搜尋 IOT'}
                        onKeyDown={e => this.handleKeyDown(e)}
                      />
                      {this.state.value !== '' ? (
                        <div className='reset' onClick={e => this.resetInput(e)}>
                          <i className='icon-close'></i>
                        </div>
                      ) : null}
                    </div>
                    <div className='fui-action only-icon'>
                      <div className='fui-button is-primary' role='button' onClick={e => this.doOpen(e)}>
                        <span className='text'><img src={"/resources/cbu/e-service/images/down.svg"} className="" alt="" /></span>
                      </div>
                    </div>
                  </div>
                )}
                onSelect={(value, item) => this.handleSelect(value, item)}
                renderMenu={(children) => {
                  // console.log('value', this.value, this)
                  return !this.state.keyword || this.state.keyword === '' ? (
                    $this.countryMenu($this)
                  ) : (
                    this.state.keywordMenu.length ? 
                      <div className={`fui-dropdown fui-keyword-menu ${!this.state.showKeywordMenu ? 'd-none' : 'd-block'}`}>{children}</div>
                    : <div className={`fui-dropdown fui-keyword-menu ${!this.state.showKeywordMenu ? 'd-none' : 'd-block'}`}><div className='fui-item is-empty is-text-gray100'>
                      <span className='prefix is-text-gray100'>
                        <img src="/resources/cbu/e-service/images/pin.svg" width='24' alt="" />
                      </span>
                      <div className='content is-text-gray100'>
                        很抱歉，我們找不到這個目的地，請輸入其他關鍵字。
                      </div>
                    </div>
                    </div>
                  );
                }}
                renderItem={(item, isHighlighted) => {
                  return (
                    <div
                      key={`autocompelte-${item.showName || item}-${Math.random()}`}
                      className={`fui-item ${isHighlighted ? 'is-selected' : ''}`}>
                      <span className='prefix'>
                        <img src="/resources/cbu/e-service/images/location.svg" width='24' alt="" />
                      </span>
                      <div
                        className='content'
                        dangerouslySetInnerHTML={{
                          __html: this.highlightKeyword(item.showName),
                        }}></div>
                    </div>
                  );
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MenuAutocomplete.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  keyword: PropTypes.string,
  defaultKeyword: PropTypes.arrayOf(PropTypes.string),
  storeType: PropTypes.string,
  menu: PropTypes.any,
  content: PropTypes.any,
  onClick: PropTypes.func,
};

export default withRouter(MenuAutocomplete);
