import React, { Component } from 'react'
import Lottie from 'react-lottie'
import * as animationData from './FET_loading.json'

export default class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return <main>
      <Lottie options={defaultOptions}
        height={90}
        width={90}
        isStopped={this.state.isStopped}
        isPaused={this.state.isPaused} />
      {/* <button style={buttonStyle} onClick={() => this.setState({ isStopped: true })}>stop</button>
      <button style={buttonStyle} onClick={() => this.setState({ isStopped: false })}>play</button>
      <button style={buttonStyle} onClick={() => this.setState({ isPaused: !this.state.isPaused })}>pause</button> */}
    </main>
  }
}
