import React, { Component } from 'react'
import { Grid } from '@material-ui/core';

import Breadcrumb from '../../components/Breadcrumb';
import Panel from '../../components/panel/Panel';
import PanelInformation from '../../components/panelContent/PanelInformation';
import Paginate from '../../components/paginate';
import Link from '../../components/Link';
class Notice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            searchResult: [],
            faqItems: [],
            filterItems: [],
            pageOfItems: [],
            pageSetting: {
                pageSize: 5,
            },
            url: '',
        }
        this.onChangePage = this.onChangePage.bind(this);
    }
    onChangePage(pageOfItems, selectedPage) {
        console.log({ pageOfItems, selectedPage });
        // const query = new URLSearchParams(this.props.location.search);
        // query.set('page', selectedPage);
        // const searchParam = query.toString();
        // this.props.history.push(`${this.props.location.pathname}?${searchParam}`);

        // // update page of items
        // this.updatePageOfItems(pageOfItems);
    }
    render() {
        return (
            <main className="notice">

                <Breadcrumb
                    breadcrumb={[
                        { text: '首頁', link: '/' },
                        { text: '產品 ‧ 資費', link: '/' },
                        { text: '月租型', link: '/' },
                        { text: '資費方案', link: '/' },
                        { text: '手機與平板費率', link: '/' },
                        { text: '費率異動公告', link: '/' },
                    ]}
                    color='black'
                />
                <div className="fui-container">
                    <Grid container>
                        <Panel className="is-bg-white d-flex fui-table">
                            <Grid item xs={12} sm={12} md={9} className='pr-md-4'>
                                <h2 className="is-text-medium mt-0">費率異動公告</h2>
                                <PanelInformation
                                    title='<h5 class="m-0 is-text-black50 is-text-medium">限定通路限時優惠方案</h5>'
                                    date='<p class="is-text-gray100 m-0 is-text-medium">2020/03/10</p>'
                                    content={`
                  <div class="compareTable content-16 clearfix">
                  <div class="tableLeft">
                      <div class="tableHead">
                          <table>
                              <tbody>
                                  <tr style="height: 52px;">
                                      <th>州別</th>
                                  </tr>
                                  <tr style="height: 260px;">
                                      <th>亞洲</th>
                                  </tr>
                                  <tr style="height: 52px;">
                                      <th>美洲</th>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="tableRight">
                      <div class="tableBody">
                          <div class="spaceBox">
                              <table class="compareList">
                                  <tbody>
                                      <tr style="height: 52px;">
                                          <td>國家</td>
                                          <td>國碼</td>
                                          <td>每分鐘通話費</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>香港</td>
                                          <td>852</td>
                                          <td>NT $6.7</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>日本/南韓</td>
                                          <td>81/82</td>
                                          <td>NT $9.1</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>中國</td>
                                          <td>86</td>
                                          <td>NT $8.4</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>新加坡</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>馬来西亞</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>美國/加拿大</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                  </tbody>
                              </table> 
                          </div>
                      </div>
                  </div>
              </div>
              <div class="descbox line editBox">
                <div class="mainTitle">注意事項：
                <div class="unfold">
                  <ol>
                    <li>限遠傳4G新絕配及4G好機零客戶申辦。</li>
                    <li>任意網內價費率方案僅適用於以上所列之資費。</li>
                    <li>申裝、退租及變更本服務隔日生效。申裝、退租及異動時，本服務當期月租費依門號計費週期按實際使用天數比例計算。</li>
                    <li>申裝生效前以及退租生效後，撥打網外語音產生之語音通信費，均依客戶所屬4G資費單價計收。</li>
                    <li>用戶可至遠傳電信直營門市、遠傳電信特約服務中心、或透過客戶服務中心申辦即可。</li>
                    <li>任意網僅享有所申辦費率之網內優惠價格，不包含國際電話、大眾電信、市內電話等。</li>
                  </ol>
                </div>
              </div>
                  `}
                                />
                                <PanelInformation
                                    title='<h5 class="m-0 is-text-black50 is-text-medium">限定通路限時優惠方案</h5>'
                                    date='<p class="is-text-gray100 m-0 is-text-medium">2020/03/10</p>'
                                    content={`
                  <div class="compareTable content-16 clearfix">
                  <div class="tableLeft">
                      <div class="tableHead">
                          <table>
                              <tbody>
                                  <tr style="height: 52px;">
                                      <th>州別</th>
                                  </tr>
                                  <tr style="height: 260px;">
                                      <th>亞洲</th>
                                  </tr>
                                  <tr style="height: 52px;">
                                      <th>美洲</th>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="tableRight">
                      <div class="tableBody">
                          <div class="spaceBox">
                              <table class="compareList">
                                  <tbody>
                                      <tr style="height: 52px;">
                                          <td>國家</td>
                                          <td>國碼</td>
                                          <td>每分鐘通話費</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>香港</td>
                                          <td>852</td>
                                          <td>NT $6.7</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>日本/南韓</td>
                                          <td>81/82</td>
                                          <td>NT $9.1</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>中國</td>
                                          <td>86</td>
                                          <td>NT $8.4</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>新加坡</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>馬来西亞</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>美國/加拿大</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                  </tbody>
                              </table> 
                          </div>
                      </div>
                  </div>
              </div>
                  `}
                                />
                                <PanelInformation
                                    title='<h5 class="m-0 is-text-black50 is-text-medium">限定通路限時優惠方案</h5>'
                                    date='<p class="is-text-gray100 m-0 is-text-medium">2020/03/10</p>'
                                    content={`
                  <div class="compareTable content-16 clearfix">
                  <div class="tableLeft">
                      <div class="tableHead">
                          <table>
                              <tbody>
                                  <tr style="height: 52px;">
                                      <th>州別</th>
                                  </tr>
                                  <tr style="height: 260px;">
                                      <th>亞洲</th>
                                  </tr>
                                  <tr style="height: 52px;">
                                      <th>美洲</th>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="tableRight">
                      <div class="tableBody">
                          <div class="spaceBox">
                              <table class="compareList">
                                  <tbody>
                                      <tr style="height: 52px;">
                                          <td>國家</td>
                                          <td>國碼</td>
                                          <td>每分鐘通話費</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>香港</td>
                                          <td>852</td>
                                          <td>NT $6.7</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>日本/南韓</td>
                                          <td>81/82</td>
                                          <td>NT $9.1</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>中國</td>
                                          <td>86</td>
                                          <td>NT $8.4</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>新加坡</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>馬来西亞</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>美國/加拿大</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                  </tbody>
                              </table> 
                          </div>
                      </div>
                  </div>
              </div>
                  `}
                                />
                                <PanelInformation
                                    title='<h5 class="m-0 is-text-black50 is-text-medium">限定通路限時優惠方案</h5>'
                                    date='<p class="is-text-gray100 m-0 is-text-medium">2020/03/10</p>'
                                    content={`
                  <div class="compareTable content-16 clearfix">
                  <div class="tableLeft">
                      <div class="tableHead">
                          <table>
                              <tbody>
                                  <tr style="height: 52px;">
                                      <th>州別</th>
                                  </tr>
                                  <tr style="height: 260px;">
                                      <th>亞洲</th>
                                  </tr>
                                  <tr style="height: 52px;">
                                      <th>美洲</th>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="tableRight">
                      <div class="tableBody">
                          <div class="spaceBox">
                              <table class="compareList">
                                  <tbody>
                                      <tr style="height: 52px;">
                                          <td>國家</td>
                                          <td>國碼</td>
                                          <td>每分鐘通話費</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>香港</td>
                                          <td>852</td>
                                          <td>NT $6.7</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>日本/南韓</td>
                                          <td>81/82</td>
                                          <td>NT $9.1</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>中國</td>
                                          <td>86</td>
                                          <td>NT $8.4</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>新加坡</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>馬来西亞</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>美國/加拿大</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                  </tbody>
                              </table> 
                          </div>
                      </div>
                  </div>
              </div>
                  `}
                                />
                                <Paginate
                                    initialPage={1}
                                    pageCount={8}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    containerClassName='fui-pagination'
                                    previousLabel={<i className='icon-chevron-left'></i>}
                                    nextLabel={<i className='icon-chevron-right'></i>}
                                    firstLabel={<i className='icon-first'></i>}
                                    lastLabel={<i className='icon-last'></i>}
                                    onPageChange={this.pageChange}
                                />
                                <Link to={'#'} className='fui-button is-text pr-0'>
                                    <i className='icon-chevron-left mr-1'></i>
                                    <span>返回資費總覽</span>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <div className="sidebar">
                                    <div className="sidebarBox">
                                        <div className="box max boxShadow">
                                            <div className="mainTitle">
                                                資費試算
                      </div>
                                            <p>那種資費最符合你的預算 讓FETnet幫你精打細算</p>
                                            <a href="/postpaid/rateplan/rateplan-simulation.html" target="_self" className="button fui-button">
                                                立即試算
                        </a>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Panel>
                    </Grid>
                </div>
            </main>
        );
    }
}

export default Notice;