import React from 'react';
import { Grid } from '@material-ui/core'

import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import RadioGroup from '../../components/form/RadioGroup';
import RadioButtons from '../../components/form/RadioButtons';

import FormBanner from '../../components/partials/banner/FormBanner';

class getPhoneBillPaySettingOpenPage extends React.Component {
  constructor(props) {
    super(props);
    this.form1 = React.createRef();
    this.form2 = React.createRef();
    this.state = {
      form1CanSubmit: false,
      form2CanSubmit: false,
      step: 1,
      form_1: {
        open_trade: { value: '1', required: false },
      },
      form_2: {
        auth_code: { value: '', required: true },
      }
    }
  }

  enableButton1 = (e) => {
    this.setState({ form1CanSubmit: true });
  }

  disableButton1 = (e) => {
      this.setState({ form1CanSubmit: false });
  }

  enableButton2 = (e) => {
    this.setState({ form2CanSubmit: true });
  }

  disableButton2 = (e) => {
      this.setState({ form2CanSubmit: false });
  }

  form1Change = (name, value) => {
    let newForm = Object.assign(this.state.form_1);
    newForm[name].value = value;
    
    this.setState({
      form_1: newForm
    })
  }

  form2Change = (name, value) => {
    let newForm = Object.assign(this.state.form_2);
    newForm[name].value = value;
    
    this.setState({
      form_2: newForm
    })
  }

  StepSubmit = () => {
    this.setState({
      step: 2
    })
  }

  submit = () => {
    this.props.history.push('/phoneBillPaySettingController/getPhoneBillPaySettingOpenResultPage')
  }

  render() {
    const { form_1, form_2 } = this.state
    return (
      <main>
        
        <NavAnchor
          pageTitle='帳單代收'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="小額交易設定"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />

          <section className={this.state.step === 1 ? 'd-block' : 'd-none'}>
            <div className="fui-container">
              <Formsy onValid={this.enableButton1} onInvalid={this.disableButton1} ref={this.form1}>

                <div className='form-group'>
                  <h4 className='form-description is-text-darkgray50'>為確保你的帳戶安全，將進行遠傳密碼驗證，才能完成此設定。</h4>
                  <div className='is-text-accent text-sm'>
                    <span className='required-dot'></span> 必填
                  </div>
                </div>
                <div className='form-group'>
                  <label className="is-required">開啟小額交易</label>
                  <RadioButtons
                    name='open_trade'
                    options={[
                      { value: '1', label: '確定開啟小額交易功能' }
                    ]}
                    selected={form_1.open_trade.value}
                    getSelected={e => this.form1Change('open_trade', e)}
                  />
                </div>
                <div className="form-group two-buttons">
                  <Button btnStyle='primary' size='large' onClick={this.StepSubmit} disabled={!this.state.form1CanSubmit}>下一步</Button>
                  <Button btnStyle='secondary' link='/e-service' size='large'>取消</Button>
                </div>
              </Formsy>
            </div>
          </section>

          <section className={this.state.step === 2 ? 'd-block' : 'd-none'}>
            <div className="fui-container">
              <Formsy onValid={this.enableButton2} onInvalid={this.disableButton2} ref={this.form2}>
                <div className='form-group'>
                  <h4 className='form-description is-text-darkgray50'>簡訊驗證碼已發送至0912-345-678，請於10分鐘內輸入，若未收到驗證碼請按重新發送。</h4>
                  <div className='is-text-accent text-sm'>
                    <span className='required-dot'></span> 必填
                  </div>
                </div>
                <div className='form-group'>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelInput
                        name='auth_code'
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入驗證碼',
                        }}
                        required={form_2.auth_code.required}
                        label="輸入驗證碼"
                        value={form_2.auth_code.value}
                        onChange={this.form2Change}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="form-group two-buttons">
                  <Button btnStyle='primary' size='large' onClick={this.submit} disabled={!this.state.form2CanSubmit}>送出</Button>
                  <Button btnStyle='secondary' link='/e-service' size='large'>重新發送(100)</Button>
                </div>
              </Formsy>
            </div>
          </section>

      </main>
    );
  }
}

export default getPhoneBillPaySettingOpenPage;
