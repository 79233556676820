import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import PanelList from '../../components/panelContent/PanelList';
import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import CollapseDiscount from '../../components/collapse/CollapseDiscount';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import * as Mock from '../../mock/ContractsInfo';
import * as ContractService from '../../service/ContractService';

class getQueryContractsInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.collapse = React.createRef();
    this.state = {
      contractList: [],
    };

    ContractService.getContractInfo().then((data) => {
      let contractList = data.reduce((accu, value, index) => {
        let info = [{ text: `合約時間：${value.startDate}~${value.endDate}` }];
        let discount = [];

        value.contractDiscountInfoList.map((item) => {
          discount.push({
            text: `${item.name}(${item.startDate}~${item.endDate})`,
          });
        });

        if (value.contractLimit) {
          info.push({ text: `費率限制：${value.contractLimit}` });
        }

        if (value.contractSuspendInfoList) {
          info.push({ text: `續約資格：${value.contractSuspendInfoList}` });
        }

        accu.push({
          name: value.name,
          info: info,
          discount: discount,
        });

        return accu;
      }, []);

      this.setState({
        contractList: contractList,
      });
    });
  }

  render() {
    return (
      <main>

        <NavAnchor {...Mock.navAnchor} />
        <FormBanner
          title='合約資訊查詢'
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className='page-header'>
          <div className='fui-container'>
            <h4 className='is-text-darkgray50 mb-0 mt-md-3'>您可於此處查詢自己目前尚未到期的合約內容</h4>
          </div>
        </section>
        <section className='page-body'>
          <div className='fui-container'>
            <div className='fui-segments'>
              {/* <div className='fui-segment-title'>
                <h4 className='mt-0 mb-2'>您目前的費率</h4>
              </div> */}
              {this.state.contractList.length
                ? this.state.contractList.map((contract, i) => (
                  <div className='fui-segment' key={`rate-plan-segment-${i}`}>
                    <div className='rate-plan-box mb-2'>
                      <h5>方案</h5>
                      <h2>{contract.name}</h2>
                    </div>
                    <PanelList prefix='bulleted' list={contract.info} />
                    <h5 className='mb-2'>折扣優惠</h5>
                    <CollapseDiscount list={contract.discount} />
                  </div>
                ))
                : null}
            </div>
            <div className='page-action'>
              <Button btnStyle='primary' size='large'>
                立即續約
              </Button>
              <Button link='/rateChangeController/rateChangeInitPage' btnStyle='secondary' size='large'>
                費率變更/異動
              </Button>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default getQueryContractsInfoPage;
