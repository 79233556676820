import React from 'react';
import Card from '../../card/Card';

import PropTypes from 'prop-types';

class PromotionCard extends React.Component {
  render() {
    return (
      <section>
        <div className='fui-container'>
          <h2>{this.props.title}</h2>
          <div className='fui-cards is-cbu-promotion-cards'>
            {this.props.cards.map((card, i) => (
              <Card id={`interestCard-${i + 1}`} {...card} key={`promotion-card-${i}`} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

PromotionCard.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      retinaImage: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
      target: PropTypes.string, // _self || _blank
    })
  ),
};

export default PromotionCard;
