import React from 'react';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import Button from '../Button'


const CheckboxList = (props) => {
  const [selected, setSelected] = React.useState((props.selected).split(/,/g));

  React.useEffect(() => {
    setSelected((props.selected).split(/,/g))
  }, [props.selected])

  const handleChange = (event) => {
    let newSelected = Object.assign(selected);
    let val = event.currentTarget.value;
    if (event.currentTarget.checked) {
      newSelected.push(val);
    } else {
      newSelected.splice(newSelected.indexOf(val), 1);
    }

    setSelected(newSelected);
    
    props.onChange(props.name, newSelected.toString());
  }

  const getOptionChecked = (val) => {
    return selected.indexOf(val) > -1
  }

  return (
    <div className='checkbox-group'>
      {props.options.map((option, i) => (
        <label className='checkbox is-icon-box' key={`checkbox-${props.name}-${i}`}>
          {
            getOptionChecked(option.value) ? 
              (<input 
                type='checkbox' 
                name={props.name}
                value={option.value}
                onChange={handleChange} 
                disabled={props.disabled}
                checked={true}
              />) : 
              (<input 
                type='checkbox' 
                name={props.name}
                value={option.value}
                onChange={handleChange} 
                disabled={props.disabled}
                checked={false}
              />)
          }
          
          <div className='content'>
            <div className="main">
              <div className='text'>{option.label}</div>
              <div className='description'>{option.description}</div>
            </div>
            {
              option.status !== '' ? (
                <div className="extra">
                  <div className="fui-label">
                    {
                      option.status === 'progress' ? (
                        <span className='text'>新增申請中</span>
                      ) : (
                        <span className='text'>已申請</span>
                      )
                    }
                  </div>
                </div>
              ) : null
            }
            
          </div>
        </label>
      ))}
    </div>
  )
}

class ParkingCheckAreaSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progressList: this.props.options.filter(option => option.status==='progress'),
      areaList: this.props.options.filter(option => option.status!=='progress'),
      defaultChecked: this.props.default ? this.props.default.split(/,/g) : [],
      selected: this.props.default ? this.props.default.split(/,/g) : [],
    };

  }

  render() {
    return (
      <section className={`page-body ${this.props.className || ''}`}>
        <div className="fui-container">
          <div className="paper">
            <Formsy>
              <h4>請選擇代收地區（多選）</h4>
              <div className="parking-area-selector">
                <CheckboxList 
                  name={this.props.name}
                  options={this.state.areaList}
                  selected={this.props.selected.value}
                  onChange={(n, v) => this.props.onChange(n, v)}
                />
              </div>
            </Formsy>
          </div>

          {
            this.state.progressList.length ? (
              <div className="paper">
                <h4>異動審核的代收地區</h4>
                <div className="parking-area-selector">
                  <CheckboxList
                    name={this.props.name}
                    disabled={true}
                    options={this.state.progressList}
                    selected={this.props.selected.value}
                  />
                </div>
              </div>
            ) : null
          }
          <div className="page-action">
            <Button btnStyle="primary" size="large" onClick={this.props.close}>確定</Button>
            <Button btnStyle="secondary" size="large" onClick={this.props.reset}>取消</Button>
          </div>
        </div>
      </section>
    );
  }
}

ParkingCheckAreaSection.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.object
  ),
  onChange: PropTypes.func,
  reset: PropTypes.func,
  close: PropTypes.func,
};

export default ParkingCheckAreaSection;
