import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import AdaptHeightBanner from '../../components/partials/banner/AdaptHeightBanner';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import Breadcrumb from '../../components/Breadcrumb';
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';

class filterInstruction extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <main>
        <Header />
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#',
          }}
        />
        <Breadcrumb
          color='white'
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '來電過濾設定', link: '/cbu/' },
            { text: '來電過濾服務說明', link: '#' },
          ]}
        />
        <AdaptHeightBanner
          height={{
            desktop: 360,
            mobile: 180
          }}
          bgImg={{
            md: '/resources/cbu/img/cbu-eservice-call-filtering-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-eservice-call-filtering-750x400.jpg',
          }}
          title='來電過濾服務說明'
        />
        <section className="ribbon-bg pb-md-8 pb-7 pt-md-7 pt-4">
          <div className='fui-container'>
            <CollapsePaper
              title="什麼是來電過濾"
              className='mb-2'
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <h6 className='is-text-regular mt-0 mb-0'>來電過濾可幫您過濾指定門號、無法顯示號碼、刻意隱藏號碼、或國際電話的來電，讓您減少陌生電話或他人的騷擾。</h6>
                </Grid>
              </Grid>
            </CollapsePaper>
            <CollapsePaper
              title="申請來電過濾只需簡單一步"
              className='mb-2'
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <h6 className='is-text-regular mt-0 mb-0'>遠傳用戶手機直撥888(MVPN用戶手機直撥99888)，告知客服人員要申請【來電過濾】即可。也可至官網登錄後，於門號功能設定中，開啟來電過濾設定。(路徑:首頁 > 用戶服務 > 門號服務/漫遊 > 門號服務總覽頁)</h6>
                </Grid>
              </Grid>
            </CollapsePaper>
            <CollapsePaper
              title="來電過濾費用"
              className='mb-2'
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <h6 className='is-text-regular mt-0 mb-0'>月租費每月30元，可設定50組門號</h6>
                </Grid>
              </Grid>
            </CollapsePaper>
            <CollapsePaper
              title="自由選擇系統過濾方式"
              className='mb-2'
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <h6 className='is-text-regular mt-0 mb-0 mb-3'>【來電過濾】提供的過濾方式有以下兩種：</h6>
                  <ul className="step-list-small">
                    <li className=''>
                      <div className="circle">1</div>
                      拒絕接通
                    </li>
                    <li className=''>
                      <div className="circle">2</div>
                      導入語音信箱
                    </li>
                  </ul>
                  <h6 className='is-text-regular mt-3 mb-0'>選擇後，無法顯示號碼、刻意隱藏號碼、國際電話來電或黑名單上的來電， 都會以相同的方式進行過濾。</h6>
                </Grid>
              </Grid>
            </CollapsePaper>
            <CollapsePaper
              title="自由選擇過濾項目"
              className='mb-2'
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <h6 className='is-text-regular mt-0 mb-0 mb-3'>【來電過濾】可以過濾的來電包括以下四種：</h6>
                  <ul className="step-list-small">
                    <li className=''>
                      <div className="circle">1</div>
                      無法顯示號碼的來電
                    </li>
                    <li className=''>
                      <div className="circle">2</div>
                      刻意用手機隱藏號碼的來電
                    </li>
                    <li className=''>
                      <div className="circle">3</div>
                      黑名單上的來電
                    </li>
                    <li className=''>
                      <div className="circle">4</div>
                      國際電話的來電
                    </li>
                    <li className=''>
                      <div className="circle">5</div>
                      了解更多，請參考 來電過濾服務說明
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </CollapsePaper>
            <CollapsePaper
              title="什麼是黑名單"
              className='mb-2'
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <h6 className='is-text-regular mt-0 mb-0'>您可將拒接的電話設定為黑名單，無論是手機號碼或市話，系統將會擋掉您所設定的號碼來電。</h6>
                </Grid>
              </Grid>
            </CollapsePaper>
            <CollapsePaper
              title="如何設定黑名單"
              className='mb-2'
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <h6 className='is-text-regular mt-0 mb-0'>開通來電過濾服務後，即可至黑名單設定的頁面進行設定或撥打888，由客服人員協助設定。</h6>
                </Grid>
              </Grid>
            </CollapsePaper>
            <CollapsePaper
              title="誰可以申請「來電過濾」"
              className='mb-2'
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <h6 className='is-text-regular mt-0 mb-0'>除預付卡用戶外，一般月租型用戶或企業用戶皆可申裝。</h6>
                </Grid>
              </Grid>
            </CollapsePaper>
          </div>
        </section>
      </main>
    );
  }
}

export default filterInstruction;