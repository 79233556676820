export const HelpCenterMock = {
  cbuLink: '//www.fetnet.net',
  ebuLink: '/ebu/index',
  logoLink: '/ebu/index',
  loginlink: '#',
  lang: 'zh-TW',
  switch: {
    on: '商務',
    off: '個人',
  },
  logo: '/resources/common/images/fetnet-logo.png',
  aboutLink: '關於遠傳',
  keywordInput: '想要找什麼？',
  footerLink: ['聯絡客服', '網站地圖'],
  searchHelpers: [
    {
      link: '#',
      image: '/resources/common/images/store.png',
      text: '找門市',
    },
    {
      link: '/ebu/help-center?tab=ebu',
      image: '/resources/common/images/help-center.png',
      text: '幫助中心',
    },
    {
      link: '#',
      image: '/resources/common/images/download-catalog.png',
      text: '最新型錄',
    },
  ],
  keywords: [
    {
      text: '繳費',
    },
    {
      text: '電子帳單',
    },
    {
      text: '交易記錄',
    },
    {
      text: '吃到飽',
    },
    {
      text: '續約',
    },
    {
      text: '國際漫遊',
    },
    {
      text: '月月好禮優惠',
    },
    {
      text: '小愛講',
    },
    {
      text: 'friday拍拍本',
    },
    {
      text: '遠傳小額付費登入網頁',
    },
  ],
  notLoginMenu: [
    [
      { link: '/', text: '登入/註冊' }
    ],
    [
      { link: '/', text: '個人專區' }
    ],
    [
      { link: '/', text: '我的訂單' },
      { link: '/', text: 'Happy Go 優惠' },
    ],
    [
      { link: '/', text: '帳戶管理' },
    ]
  ],
  rentLoginMenu: [
    [
      { link: '/', text: '個人專區' },
      { link: '/', text: '線上繳費' },
      { link: '/', text: '帳單查詢' },
      { link: '/', text: '合約查詢' },
    ],
    [
      { link: '/', text: '我的訂單' },
      { link: '/', text: 'Happy Go 優惠' },
    ],
    [
      { link: '/', text: '帳戶管理' },
      { link: '/', text: '登出' },
    ]
  ],
  prepaidLoginMenu: [
    [
      { link: '/', text: '個人專區' },
      { link: '/', text: '語音儲值' },
      { link: '/', text: '網路儲值' },
      { link: '/', text: '餘額/上網/到期日查詢' },
    ],
    [
      { link: '/', text: '我的訂單' },
      { link: '/', text: 'Happy Go 優惠' },
    ],
    [
      { link: '/', text: '帳戶管理' },
      { link: '/', text: '登出' },
    ]
  ],
  noMsisdnLoginMenu: [
    [
      { link: '/', text: '個人專區' },
    ],
    [
      { link: '/', text: '我的訂單' },
      { link: '/', text: 'Happy Go 優惠' },
    ],
    [
      { link: '/', text: '帳戶管理' },
    ]
  ],
  listMaxLength: 4,
  mobileMenu: {
    content: {
      menu: [
        {
          title: '個人用戶',
          list: [
            {
              icon: '/resources/cbu/cbu-index/phone.svg',
              iconActive: '/resources/cbu/cbu-index/phone-active.svg',
              text: '常見問題',
              child: [
                { link: '/', text: '帳單/繳費/合約', },
                { link: '//', text: '網路購物與商品' },
                { link: '//', text: '遠傳生活圈', },
                { link: '//', text: '其他常見問題', }
              ]
            },
            {
              icon: '/resources/cbu/cbu-index/phone.svg',
              iconActive: '/resources/cbu/cbu-index/phone-active.svg',
              text: '門市服務',
              child: [
                { link: '#', text: '找門市', },
                { link: '#', text: '門市預約及查詢', },
                { link: '#', text: '預約門市換號', },
                { link: '#', text: '服務申請證件提醒', },
              ],
            },
            {
              icon: '/resources/cbu/cbu-index/phone.svg',
              iconActive: '/resources/cbu/cbu-index/phone-active.svg',
              text: '客服中心',
              child: [
                { link: '/', text: '客服信箱' },
                { link: '/', text: '服務公告' },
                { link: '/', text: '行動客服APP' },
              ]
            },
            {
              icon: '/resources/cbu/cbu-index/phone.svg',
              iconActive: '/resources/cbu/cbu-index/phone-active.svg',
              text: '資訊學習',
              child: [
                { link: '/', text: '手機/平板操作說明' },
                { link: '/', text: '免費學習課程' },
              ]
            },
            {
              icon: '/resources/cbu/cbu-index/phone.svg',
              iconActive: '/resources/cbu/cbu-index/phone-active.svg',
              text: '維修保固',
              child: [
                { link: '/', text: '保固查詢' },
                { link: '/', text: '網路預約報修' },
                { link: '/', text: '網路預約報修查詢' },
                { link: '/', text: '維修進度查詢' },
              ]
            },
            {
              icon: '/resources/cbu/cbu-index/phone.svg',
              iconActive: '/resources/cbu/cbu-index/phone-active.svg',
              text: '其他服務',
              child: [
                { link: '/', text: '網內外門號查詢' },
                { link: '/', text: '電信特殊號碼查詢' },
                { link: '/', text: '網路涵蓋率查詢' },
                { link: '/', text: '固網電子帳單' },
                { link: '/', text: '寬頻服務' },
              ]
            },
          ],
        },
        {
          title: '企業用戶',
          list: [
            {
              icon: '/resources/cbu/cbu-index/phone.svg',
              iconActive: '/resources/cbu/cbu-index/phone-active.svg',
              text: '總覽',
              child: [
                { link: '/', text: '$499 飆網吃到飽' },
                { link: '/', text: '$149 輕速雙飽' },
                { link: '/', text: '$199 夠用最實在' },
                { link: '/', text: '$399 網內飽 + 夯機 $0' },
                { link: '/', text: '$399 吃到飽 + 夯機 $0' },
                { link: '/', text: '推薦續約' },
              ]
            },
            {
              icon: '/resources/cbu/cbu-index/phone.svg',
              iconActive: '/resources/cbu/cbu-index/phone-active.svg',
              text: '文件下載',
              child: [
                { link: '/', text: '4.5G 資費' },
                { link: '/', text: '預付卡' },
                { link: '/', text: '國際電話' },
                { link: '/', text: 'Wi-Fi 上網' },
              ]
            },
            {
              icon: '/resources/cbu/cbu-index/phone.svg',
              iconActive: '/resources/cbu/cbu-index/phone-active.svg',
              text: '常見問題',
              child: [
                { link: '/', text: '月租用戶原號漫遊' },
                { link: '/', text: '想去哪？就搜哪！' },
                { link: '/', text: '預付卡原號漫遊' },
                { link: '/', text: '出國神卡 - 遠遊卡' },
                { link: '/', text: '來台旅客上網卡' },
                { link: '/', text: '漫遊開關設定' },
                { link: '/', text: '機上漫遊' },
              ]
            },
            {
              icon: '/resources/cbu/cbu-index/phone.svg',
              text: '線上問題反應',
              child: [
                { link: '/', text: '遠傳大無線' },
                { link: '/', text: '遠傳大寬頻光纖' },
              ]
            },
          ],
        },
        {
          title: '聯絡我們',
          link: '/help-center/contact'
        },
        {
          title: '服務條款',
          link: '/help-center/terms-of-service/privacy'
        },
      ],
      moreCase: '/ebu/performance',
    },
    promotion: {
      image: {
        md: '/resources/ebu/images/ebu-medium-ad-01-600x260.jpg',
        sm: '/resources/ebu/images/ebu-medium-ad-01-600x260.jpg'
      },
      title: '遠傳企業Office 365 輕享方案',
      content: '最殺的價格、超彈性的付費、完整雲端行動辦公 遠傳一次幫你搞定！',
      action: {
        link: '#',
        text: '了解 Office 365 輕享',
      },
    },
  },
  desktopMenu: [
    {
      text: '個人用戶',
      layout: 'list-2',
      content: {
        menu: [
          {
            title: '常見問題',
            list: [
              { link: '/', text: '帳單/繳費/合約', },
              { link: '/', text: '網路購物與商品' },
              { link: '/', text: '遠傳生活圈', },
              { link: '/', text: '其他常見問題', }
            ],
            more: {
              link: '/content/ebu/tw/sitemap.html',
              text: '更多幫助',
            },
          },
          {
            title: '門市服務',
            list: [
              { link: '#', text: '找門市', },
              { link: '#', text: '門市預約及查詢', },
              { link: '#', text: '預約門市換號', },
              { link: '#', text: '服務申請證件提醒', },
            ]
          },
          {
            title: '客服中心',
            list: [
              {
                link: '/content/ebu/tw/solution/large-enterprise/l3/large-enterprise-iot/IoT-intro.html',
                text: '客服信箱',
              },
              {
                link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                text: '服務公告',
              },
              {
                link: '/content/ebu/tw/product/security/security_product-l31.html',
                text: '行動客服APP',
              },
            ],
          },
          {
            title: '資訊學習',
            list: [
              {
                link: '/content/ebu/tw/solution/large-enterprise/l3/large-enterprise-iot/IoT-intro.html',
                text: '手機/平板操作說明',
              },
              {
                link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                text: '免費學習課程',
              },
            ],
          },
          {
            title: '維修保固',
            list: [
              {
                link: '/content/ebu/tw/solution/large-enterprise/l3/large-enterprise-iot/IoT-intro.html',
                text: '保固查詢',
              },
              {
                link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                text: '網路預約報修',
              },
              {
                link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                text: '網路預約報修查詢',
              },
              {
                link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                text: '維修進度查詢',
              },
            ],
          },
          {
            title: '其他服務',
            list: [
              {
                link: '/content/ebu/tw/solution/large-enterprise/l3/large-enterprise-iot/IoT-intro.html',
                text: '網內外門號查詢',
              },
              {
                link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                text: '電信特殊號碼查詢',
              },
              {
                link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                text: '網路涵蓋率查詢',
              },
              {
                link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                text: '固網電子帳單',
              },
              {
                link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                text: '寬頻服務',
              },
            ],
          },
        ],

        commercial: {
          image: {
            md: '/resources/cbu/life-circle-curation/images/sidebar-promotion-help-center.jpg',
            sm: '/resources/cbu/life-circle-curation/images/sidebar-promotion-help-center.jpg'
          },
          title: '立即訂閱遠傳趨勢觀點電子報',
          content: '讓您隨時掌握科技新知與數位趨勢！',
          action: {
            text: '了解更多',
            link: '#',
          },
        },
      },
    },
    {
      text: '企業用戶',
      layout: 'list-2',
      content: {
        menu: [
          {
            title: '常見問題',
            list: [
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/volte/volte-vowifi-intro.html',
                text: '遠傳物聯網',
              },
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/mvpn/mvpn-intro.html',
                text: '雲端運算',
              },
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/wvpn.html',
                text: '主機代管',
              },
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/fet-uc/fet-uc-intro.html',
                text: '大寬頻企業光纖',
              },
            ],
            more: {
              link: '/content/ebu/tw/sitemap.html',
              text: '更多幫助',
            },
          },
          {
            title: '企業服務',
            list: [
              {
                link: '/content/ebu/tw/product/broadband_product/broadband.html',
                text: 'Office 365',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/voice.html',
                text: '企業光纖申請',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/data-circuit.html',
                text: '域名線上申請',
              },
            ],
          },
          {
            title: '資訊查詢',
            list: [
              {
                link: '/content/ebu/tw/product/broadband_product/broadband.html',
                text: '國內區碼',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/voice.html',
                text: '世界電話國碼',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/data-circuit.html',
                text: '中國城市區碼',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/data-circuit.html',
                text: 'METG流量',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/data-circuit.html',
                text: '文件下載',
              },
            ],
          },
        ],

        commercial: {
          image: {
            md: '/resources/cbu/life-circle-curation/images/sidebar-promotion-help-center.jpg',
            sm: '/resources/cbu/life-circle-curation/images/sidebar-promotion-help-center.jpg'
          },
          title: '立即訂閱遠傳趨勢觀點電子報',
          content: '讓您隨時掌握科技新知與數位趨勢！',
          action: {
            text: '了解更多',
            link: '#',
          },
        },
      },
    },
    {
      text: '聯絡我們',
      link: '/help-center/contact',
    },
    {
      text: '服務條款',
      link: '/help-center/terms-of-service/privacy',
    },
  ],
};


export const hotword = [
  {
    text: '遠傳物聯網',
    link: '/help-center/search?keyword=遠傳物聯網',
  },
  {
    text: '遠傳物聯網',
    link: '/help-center/search?keyword=遠傳物聯網',
  },
  {
    text: '遠傳物聯網',
    link: '/help-center/search?keyword=遠傳物聯網',
  },
  {
    text: '遠傳物聯網',
    link: '/help-center/search?keyword=遠傳物聯網',
  },
  {
    text: '遠傳物聯網',
    link: '/help-center/search?keyword=遠傳物聯網',
  },
];

export const shortcut = [
  {
    link: '#',
    image: '/resources/cbu/help-center/images/store.svg',
    text: '找門市',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/change-number.svg',
    text: '預約門市換號',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/news.svg',
    text: '服務公告',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/search-number.svg',
    text: '門號查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/search-doc.svg',
    text: '保固查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/net.svg',
    text: '網路涵蓋率',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/report.svg',
    text: '網路預約報修',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/download.svg',
    text: '操作手冊下載',
  },
];

export const searchCard = {
  title: '想查詢什麼?',
  cards: [
    {
      title: '帳單/繳費/合約',
      description: '有關合約、帳單明細、各式繳費與儲值說明',
      link: '#',
      linkMb: '#'
    },
    {
      title: '網路購物與商品',
      description: '有關商品說明、線上購買、寄送及售後服務等事項',
      link: '#',
      linkMb: '#'
    },
    {
      title: '遠傳生活圈',
      description: '有關遠傳數位加值服務、HAPPYGO和遠傳幣累積點數的常見問題與說明',
      link: '#',
      linkMb: '#'
    },
    {
      title: '月租型',
      description: '有關遠傳月租型方案說明',
      link: '#',
      linkMb: '#'
    },
  ],
  more: {
    text: '看更多',
    link: '#',
  },
};  
