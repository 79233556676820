import React, { Component } from 'react'
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import Tooltip from '../../components/Tooltip'
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
class Fixeup extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef()
    this.state = {
      form: {
        id_number: { value: '', required: true },
        auth_code: { value: '', required: true },
      },
      canSubmit: false
    }
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    // console.log('onChange', name, value)
    this.setState({
      form: newForm,
    });
  };
  render() {
    return (
      <main>
        <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
          <NavAnchor
            pageTitle='交易/異動紀錄'
            button={{
              text: '回個人專區',
              link: '#'
            }} />
          <FormBanner
            title="維修進度查詢"
            image={{
              md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
              sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
            }}
          />
          <div className="fui-container">
            <h4 className='is-text-darkgray50 my-md-7'>提供您查詢至遠傳門市送修的設備維修進度。</h4>
            <div className="paper mt-md-2 mt-2 mb-2 pb-md-7 pb-4">
              <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-2">
                <h4 className='m-0 is-text-medium'>查詢須知</h4>
              </div>
              <ul className="green-dot-list is-text-medium mb-0">
                <li>「維修進度查詢」不提供”已取件”之維修商品查詢。</li>
                <li>若無法出現維修資料，請確認輸入查詢條件是否正確，建議您可返回原送修門市查詢維修狀態。</li>
              </ul>
            </div>
          </div>
          <section className='no-bg pb-0 mb-md-2 pt-0'>
            <div className="fui-container">
              <div className="fui-segments">
                <div className="paper mb-0">
                  <h4 className="mb-3 mt-md-4 mt-0 is-text-medium">請輸入維修單號</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <div className="d-flex justify-between">
                        <h6 className='mt-0 mb-3 is-text-medium'>維修單號</h6>
                        <Tooltip
                          parentNode={null}
                          content={<i className='icon-information is-text-darkgray100 ml-1 d-flex'></i>}
                          trigger="click"
                          tooltip={'請輸入12碼維修單單號。'}
                        />
                      </div>
                      <LabelInput
                        className="hide-required mb-0"
                        name='id_number'
                        required={this.state.form.id_number.required}
                        label=''
                        // validations={{
                        //   idNumberValid: Validation.idNumberValid
                        // }}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入維修單號'
                        }}
                        placeholder='請輸入'
                        value={this.state.form.id_number.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                </div>
                <CollapsePaper
                  title="維修中心資訊"
                  className="collapse-table"
                  openCollapse={e => { console.log(e) }}
                  open={false}
                >
                  <div className="mt-2 pb-2">
                    <small className=''>
                      德儀維修中心 僅維修APPLE產品，非APPLE產品可透過遠傳門市，代送修至全虹維修中心。
                    </small>
                    <div className="compareTable content-16 clearfix mt-md-3 mt-2 mb-md-3 mb-0 w-200">
                      <div className="tableLeft" style={{ borderRight: '2px solid #E8ECF0' }}>
                        <div className="tableHead">
                          <table>
                            <tbody>
                              <tr>
                                <th className='is-bg-lightgray70 is-text-medium'>
                                  維修站名稱
                                </th>
                              </tr>
                              <tr>
                                <th className='is-text-regular'>
                                  台大旗艦服務中心
                                </th>
                              </tr>
                              <tr>
                                <th className='is-text-regular'>
                                  台南民族服務中心
                                </th>
                              </tr>
                              <tr>
                                <th className='is-text-regular'>
                                  高雄建國維修中心
                                </th>
                              </tr>
                              <tr>
                                <th className='is-text-regular'>
                                  中壢Nova服務中心
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tableRight">
                        <div className="tableBody">
                          <div className="spaceBox">
                            <table className="compareList not-fixed">
                              <tbody>
                                <tr>
                                  <th className='is-bg-lightgray70 is-text-medium'>地址</th>
                                  <th className='is-bg-lightgray70 is-text-medium'>電話</th>
                                  <th className='is-bg-lightgray70 is-text-medium'>營業時間</th>
                                </tr>
                                <tr className='is-bg-white is-text-regular'>
                                  <td>台北市中正區羅斯福路三段292號3樓</td>
                                  <td>無電話服務</td>
                                  <td className='align-left' rowSpan="3">∙ 週一 ~ 週六 12:00-21:00<br />∙ 週六-收件與取件,無現埸維修</td>
                                </tr>
                                <tr className='is-bg-white is-text-regular'>
                                  <td>台南市中西區民族路二段231號​2樓​</td>
                                  <td>無電話服務</td>
                                </tr>
                                <tr className='is-bg-white is-text-regular'>
                                  <td>高雄市三民區建國二路150號</td>
                                  <td>無電話服務</td>
                                </tr>
                                <tr className='is-bg-white is-text-regular'>
                                  <td>桃園市中壢區中正路389號3樓</td>
                                  <td>無電話服務</td>
                                  <td className='align-left'>∙ 週一 ~ 週日 11:00-21:00</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CollapsePaper>
              </div>
            </div>
          </section>
          <section className='pb-md-7'>
            <div className="fui-container">
              <Grid container>
                <Grid item xs={12} sm={12} md={5}>
                  <div className="captcha">
                    <div className="form-group mb-0">
                      <LabelInput
                        className="hide-required mb-0 form-control"
                        name='auth_code'
                        required={this.state.form.auth_code.required}
                        label=''
                        // validations={{
                        //   idNumberValid: Validation.idNumberValid
                        // }}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入驗證碼'
                        }}
                        placeholder='請輸入驗證碼'
                        value={this.state.form.auth_code.value}
                        onChange={this.onChange}
                      />
                      {/* <input id="vertifyNum" placeholder="請輸入驗證碼" className="form-control" /> */}
                      <div className="captcha-box">
                        <div className="captcha-number">
                          <img src="https://cldup.com/gZ7MdGePW6-3000x3000.png" alt="驗證碼圖片" />
                        </div>
                        <button type="button" className="captcha-reload">
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcYiIiIeHh39/f4iIiIeHh4eHh4iIiIiIiIiIiIeHh4eHh4WFhYiIiImJiYiIiOpIBKYAAAAPdFJOUwDfIBCgfT+/75DPYFCvUPAcHYoAAAErSURBVHjatVKxSgNBFHzEeCKxOUHbTSpLt1Hbs7JUEOsNIraxsLALYiHYnHDgD1hoFwt7W2vF2m84uSMxiCNvdzWDKaycZt/Ozt7OvHvyb5i5v8XFS1sYyQkUwzviGq8IKEn7qPsbA6D+4WaB0ZUVOTBAJhEOKHzRMqgitwAsx3IFuAxVh77v8BGKbSzKN5oorfcDtCchDHbC4TtZ7uFMl1W8ETmHT10ekHFgVOGdLncmL3Xpwwph4Ld6RAgXMRSGmyI30jTHabokfL1hoMjE8EMd5Sq2FKXq22lalo41pqZl6bVPWwtLx9Y3fmRZ+iwh55FMoUcN7dIfLmI5X1MPyqBIBhN7zThFSR9kzwE43tvKeZh0MiIKMrAW2SdhtDYBnO/KL6wf7v8x7V/iaqjxLs+LqgAAAABJRU5ErkJggg==" alt="button" />
                        </button>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className="page-action">
                    <Button btnStyle="primary" size="large" className={this.state.canSubmit ? '' : 'disabled'} onClick={() => { console.log('hi') }}>送出查詢</Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </section>
          <SectionCollapseInfo
            title="貼心小叮嚀"
            content={`
            1. 本公司銷售產品，若客戶有維修需求者，請洽全省遠傳門市，將會為您代送全虹或德誼維修中心或維修商。</br>
            2. 若產品故障原因超出原廠保固期限、範圍時(例如：受潮、液晶破裂、機板破損變形或其他人為損壞等)，客戶須付費維修，實際維修收費標準依全虹或德誼維修中心、維修商規定及各原廠保固條款。</br>
            3. 送修產品將由全虹或德誼維修中心初步進行檢測,若有需要收費以進行檢測，維修者將進一步與客戶連絡並報價。若無需收費者，將逕行產品之檢測、維修或更換等事宜，不另行通知。另為有效進行各項維修檢測事宜，請客戶於接獲報價通知後儘速回覆確認，逾期（超過3個工作日）將視為客戶放棄付費(若有)維修處理。</br>
            4. 送修商品於維修過程必須進行拆除外殼、更換零件等程序，故送修商品如有包膜或其他增添之配件可能因而受損，全虹或德誼對此種損害將不承擔任何責任。</br>
            5. 自門市通知取回送修商品之日起算6個月後，客戶仍未取回送修商品時，本公司得逕行處分該送修商品；如本公司未能與送修者取得聯繫，則自維修申請單據開立之日起算6個月後，客戶仍未取回送修商品時，本公司亦得逕行處分該送修商品。
          `}
          />
        </Formsy>
      </main>
    );
  }
}

export default Fixeup;