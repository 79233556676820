import React from 'react';
import PropTypes from 'prop-types';

const FlatCard = props => {    
    return (
        <div className={`fui-card ${props.className ? props.className : ''}`}>
            <a className='fui-card-action' href={props.link ? props.link : ''} onClick={(event) => {
                // [AA Tracking]
                if (window && typeof(window.ctrf) === 'function') window.ctrf(event || {}, `${props.title}`);
            }}>
            {
                props.image ? (
                    <div className="fui-card-image">
                        <img src={process.env.PUBLIC_URL + props.image} srcSet={process.env.PUBLIC_URL + (props.retinaImage || props.image)} alt={props.meta} /> 
                    </div>
                ) : ''
            }
                <div className='fui-card-caption'>
                    {
                        (!props.icon) ? '' : (
                            <div className='icon'>
                                <img src={process.env.PUBLIC_URL + props.icon} width="80" alt='' />
                            </div>
                        )
                    }
                    <div className='fui-card-content'>
                        <h4 className="fui-card-title">
                            {props.title}
                        </h4>
                        {
                            (!props.description) ? '': (
                                <p className="fui-card-description" dangerouslySetInnerHTML={{__html: props.description}}>
                                </p>
                            )
                        }
                    </div>
                </div>
            </a>
        </div>
    )
}

FlatCard.propTypes = {
    className: PropTypes.string,
    link: PropTypes.string,
    retinaImage: PropTypes.string,
    image: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
};

export default FlatCard;