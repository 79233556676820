import React from 'react';
import Link from '../Link';
import Button from '../Button';
import PropTypes from 'prop-types';

class NavAnchor extends React.Component {
  constructor(props) {
    super(props);
    this.elem = React.createRef();
    this.anchor = React.createRef();
    this.state = {
      defaultTop: 0,
      scrollPos: 0,
    };

    this.doHashChange = this.doHashChange.bind(this);
  }

  doHashChange(tab, index) {
    Array.from(this.anchor.current.children).forEach((elem, i) => {
      if (index === i) elem.classList.add('is-active');
      else elem.classList.remove('is-active');
    });
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({
        defaultTop:
          window.innerWidth < 960 ? 50 : this.elem.current.offsetTop || this.elem.current.parentElement.offsetTop,
      });
      window.addEventListener('resize', e => {
        if (this.elem.current) {
          this.setState({
            defaultTop:
              window.innerWidth < 960 ? 50 : this.elem.current.offsetTop || this.elem.current.parentElement.offsetTop,
          });
        }

        if (this.props.tabs) {
          this.anchorActive()
        }
      });

      window.addEventListener('mousewheel', this.navScroller);
      window.addEventListener('scroll', this.navScroller);
    }
  }

  anchorActive = () => {
    var isMobile = window.innerWidth < 960;
    let lastIndex = 0;

    this.props.tabs.forEach((tab, i) => {
      let elem = document.getElementById(tab.link.slice(1));
      // console.log( this.anchor.current)
      if (elem && null !== this.anchor.current) {
        let anchor = this.anchor.current.children[i];
        let rect = elem.getBoundingClientRect();
        let y = window.scrollY || document.documentElement.scrollTop;
        if (!isMobile) {
          if (rect.top > 0 && rect.top < 150) {
            anchor.classList.add('is-active');
          } else {
            anchor.classList.remove('is-active');
          }
        } else {
          if (rect.top > 0 && rect.top < 150) lastIndex = i;
        }
      }
    });
  }

  navScroller = e => {
    if (!this.elem.current) {
      return;
    }

    let scrollY = window.scrollY || document.documentElement.scrollTop;
    let headerDom = Array.from(document.getElementsByTagName('header'));
    let header = headerDom[0].clientHeight;

    if (headerDom.length) {
      if (scrollY > this.state.scrollPos && scrollY > 0) {
        headerDom.map(dom => {
          dom.style.transform = `translateY(${(-scrollY < -header ? -header : -scrollY)}px)`;
        });

        if (scrollY >= header) {
          this.elem.current.classList.add('is-fixed');
        }
      }

      if (scrollY < this.state.scrollPos && scrollY < this.state.defaultTop + header) {
        this.elem.current.classList.remove('is-fixed');
        // console.log('dom', scrollY, scrollY <= 0 ? 0 : -scrollY)
        headerDom.map(dom => {
          dom.style.transform = `translateY(${(scrollY <= 0 ? 0 : -scrollY)}px)`;
        });
      }
    }

    this.setState({ scrollPos: scrollY });

    var isMobile = window.innerWidth < 960;
    let lastIndex = 0;

    if (this.props.tabs) {
      this.anchorActive();
    }

    if (isMobile) {
      Array.from(this.anchor.current.children).forEach((item, i) => {
        if (i === lastIndex) item.classList.add('is-active');
        else item.classList.remove('is-active');
      });
    }
  }
  render() {
    return (
      <div className={`fui-nav-anchor`} ref={this.elem}>
        <div className='fui-container'>
          <div className='content'>
            <div className='subtitle' dangerouslySetInnerHTML={{ __html: this.props.pageTitle }}></div>
            <div className='page-anchor' ref={this.anchor}>
              {this.props.tabs
                ? this.props.tabs.map((tab, i) => (
                  <Link
                    to={tab.link}
                    key={`page-anchor-${i}`}
                    className={`page-anchor-item ${typeof window !== 'undefined' && tab.link === window.location.pathname ? 'is-active' : ''}`}
                    onClick={e => this.doHashChange(tab, i)}>
                    {tab.label}
                  </Link>
                ))
                : null}
            </div>
          </div>
          <div className='extra d-flex'>
            {!!this.props.button && this.props.button.length > 1 ?
              this.props.button.map((item, j) => {
                return (
                  <Button {...item} key={item + j} size='small' btnStyle={j == 0 ? 'primary' : 'secondary'} className={j == 0 ? 'mr-2' : ''}>
                    {item.text}
                  </Button>
                )
              }) : null
            }
            {!this.props.button === false && this.props.button.text ? (
              <Button {...this.props.button} size='small' btnStyle='secondary'>
                {this.props.button.text}
              </Button>
            ) : (
                ''
              )}
          </div>
        </div>
      </div>
    );
  }
}

NavAnchor.propTypes = {
  pageTitle: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
  button: PropTypes.any,
  onChange: PropTypes.func,
};

export default NavAnchor;
