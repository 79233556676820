import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import Modal from 'react-modal';

import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import RadioGroup from '../../components/form/RadioGroup';
import Checkbox from '../../components/form/Checkbox';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import Select from '../../components/form/Select';
import numberFormatter from '../../utils/numberFormatter';

class fillBillBankPayDataPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.bankAccount = React.createRef();
    this.state = {
      step: 1,
      canSubmit: false,
      modalOpen: false,
      form: {
        bank_code: { value: '', required: true },
        bank_account: { value: '', required: true },
        auto_pay: { value: false, required: false },
        agree: { value: false, required: true },
      },
    };
  }

  componentDidMount() {
    this.setInputFilter(this.bankAccount.current.getElementsByTagName('input')[0], function (value) {
      return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
  }

  setInputFilter = (textbox, inputFilter) => {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onChange = (n, v) => {
    let form = Object.assign(this.state.form);
    form[n].value = v;

    console.log(n, v);

    this.setState({
      form: form,
    });
  };

  nextStep = () => {
    this.setState({
      step: 2,
    });
  };

  beforeSubmit = () => {
    if (this.state.form.auto_pay.value === true) {
      this.setState({
        modalOpen: true,
      });
    } else {
      this.submit();
    }
  };

  submit = (autopay) => {
    let form = this.state.form;
    if (!autopay) {
      form.auto_pay.value = false;
    }

    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form, step } = this.state;

    return (
      <main>

        <NavAnchor
          pageTitle='銀行帳號繳費'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title='銀行帳號繳費'
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section>
          <div className='fui-container'>
            <h4 className='is-text-darkgray70 my-0'>請確認應繳費項，並填寫付款資訊。</h4>
            <div className='is-text-accent text-sm'>
              <span className='required-dot'></span>必填
            </div>
            <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
              <div className='form-group mt-5'>
                <label className="mt-2">應繳金額</label>
                <div>
                  <h2 className='is-text-error'>{numberFormatter(1401)} 元</h2>
                </div>
              </div>
              <div className="pb-5">
                <Grid container>
                  <Grid item xs={12} sm={6} md={5}>
                    <div className='form-group'>
                      <label className='is-required'>銀行代碼</label>
                      <Select
                        required={form.bank_code.required}
                        options={[
                          { text: 'Bank code 1111', value: '1111' },
                          { text: 'Bank code 2222', value: '2222' },
                        ]}
                        label='請選擇'
                        name='bank_code'
                        value={form.bank_code.value}
                        onChange={this.onChange}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container className='input-with-checkbox with-note' spacing={2}>
                  <Grid item xs={12} sm={6} md={5} ref={this.bankAccount}>
                    <LabelInput
                      label='銀行帳號(限本人)'
                      name='bank_account'
                      required={form.bank_account.required}
                      value={form.bank_account.value}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Checkbox
                      name='auto_pay'
                      label='設定此銀行帳號每月扣款'
                      value='1'
                      checked={form.auto_pay.value}
                      onChange={this.onChange}
                    />
                  </Grid>
                </Grid>
                <small className='is-text-gray100'>本付款機制採用SSL 256 bites安全加密機制</small>
              </div>
              <CheckboxCollapse
                name='agree'
                label='本人已詳閱及了解此注意事項並同意遵守'
                validationError='完整內容收合與展開'
                validations={{
                  isChecked: form => form.agree
                }}
                checked={form.agree.value}
                value={form.agree.value}
                getCheckbox={(e) => this.onChange('agree', e)}
                collapseContent={`
                <p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。</p>
                <p>使用「全國繳費平台」繳納遠傳電信服務費用注意事項：</p>
                <p>
                1.限以門號申請本人活存帳戶使用此服務。<br/>
                2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。
                </p>
                `}
              />

              <div className='page-action'>
                <Button btnStyle='primary' size='large' onClick={this.beforeSubmit} disabled={!this.state.canSubmit}>
                  結帳
                </Button>
                <Button btnStyle='secondary' size='large' onClick={(e) => this.setState({ step: 1 })}>
                  上一步
                </Button>
              </div>
            </Formsy>
          </div>
        </section>
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5>設定自動扣繳</h5>
            <p>你同意將此次付款銀行帳號設為日後自動扣繳帳號？</p>

            <div className='fui-buttons two-button'>
              <Button btnStyle='primary' onClick={(e) => this.submit(true)}>
                同意
              </Button>
              <Button btnStyle='secondary' onClick={(e) => this.submit(false)}>
                下次再設定
              </Button>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}

Modal.setAppElement('#root');

export default fillBillBankPayDataPage;
