import React, { Component } from 'react'
import Pagination from '../../components/paginate/Pagination';
import NavAnchor from '../../components/partials/NavAnchor';
import Modal from 'react-modal';
import LoadMore from '../../components/LoadMore'
import Button from '../../components/Button';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import { Grid } from '@material-ui/core';
let rentalList = [
  {
    id: '001',
    name: '折抵儲值金額10元',
    info: 'HAPPY GO 點數40點',
    point: 40,
    discount: 10
  },
  {
    id: '002',
    name: '折抵儲值金額20元',
    info: 'HAPPY GO 點數80點',
    point: 80,
    discount: 20
  },
  {
    id: '003',
    name: '折抵儲值金額30元',
    info: 'HAPPY GO 點數120點',
    point: 120,
    discount: 30
  },
  {
    id: '004',
    name: '折抵儲值金額40元',
    info: 'HAPPY GO 點數160點',
    point: 160,
    discount: 40
  },
  {
    id: '005',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
  {
    id: '006',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
  {
    id: '007',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
  {
    id: '008',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
  {
    id: '009',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
  {
    id: '010',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
  {
    id: '011',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
  {
    id: '012',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
  {
    id: '013',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
  {
    id: '014',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
  {
    id: '015',
    name: '折抵儲值金額50元',
    info: 'HAPPY GO 點數200點',
    point: 200,
    discount: 50
  },
]
class HappyGo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasPagination: true,
      table: {
        head: ['項目', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        content: [
          ['兌換時間', '商品代碼', '商品項目', '折抵點數', '交易結果'],
          ['2020-07-08 18:11:21	', '1001', '折抵帳單金額50元', '50', '點數不足'],
          ['2020-07-08 18:11:21	', '1001', '折抵帳單金額50元', '50', '成功'],
          ['2020-07-08 18:11:21	', '1001', '折抵帳單金額50元', '50', '成功'],
          ['2020-07-08 18:11:21	', '1001', '折抵帳單金額50元', '50', '成功'],
          ['2020-07-08 18:11:21	', '1001', '折抵帳單金額50元', '50', '成功'],
          ['2020-07-08 18:11:21	', '1001', '折抵帳單金額50元', '50', '成功'],
          ['2020-07-08 18:11:21	', '1001', '折抵帳單金額50元', '50', '成功'],
          ['2020-07-08 18:11:21	', '1001', '折抵帳單金額50元', '50', '成功'],
          ['2020-07-08 18:11:21	', '1001', '折抵帳單金額50元', '50', '成功'],
          ['2020-07-08 18:11:21	', '1001', '折抵帳單金額50元', '50', '成功'],
        ]
      },
      //rental or prepaid
      customerType: 'rental',
      currentDiscount: {},
      discountSuccess: false,
      loadLimit: 5,
      showMore: false,
      modalOpen: false,
      currentTab: 0,
      login: true,
      phoneNumber: '0916****56',
      point: '22 點',
      tab2Info: `
      1. 兌換方式：卡友必須先成為快樂購網站之會員，經由快樂購網站身分認證後，始可兌換商品。<br/>
      2. 兌換對象：本網頁商品僅限遠傳用戶兌換，原和信用戶以及外籍人士暫不適用。<br/>
      3. 兌換生效：遠傳月租型用戶兌換帳單金額可於當期折抵。一經兌換成功，網站系統將即時自帳戶中扣點，恕不退還快樂購點數、折現或變更兌換商品。<br/>
      4. 兌換原則：a.用戶卡友兌換當時持有之快樂購點數需為有效點數，點數折抵之帳單金額可小於、等於或大於實際帳單金額，若有溢繳金額將自動遞延至下期帳單扣除，恕不退還現金或點數。b.帳單折抵項目，不限兌換次數，點數足夠時可同時多次兌換單一商品或多項商品。<br/>
      5. 遠傳保有修改活動辦法及條款之一切權利。
    `,
      tab3Info: `
      1.  同一帳單月份重覆兌換相同商品，已扣除之快樂購點數，將於次月月底前調回。<br/>
      2. 遠傳保有修改活動辦法及條款之一切權利。<br/>
      `,
      rentalList: [],
      prepaidList: [
        {
          id: '001',
          name: '兌換儲值金額50元',
          info: 'HAPPY GO 點數200點',
          point: 200,
          discount: 50
        },
        {
          id: '002',
          name: '兌換儲值金額100元',
          info: 'HAPPY GO 點數400點',
          point: 400,
          discount: 100
        },
        {
          id: '003',
          name: '兌換儲值金額300元',
          info: 'HAPPY GO 點數12-0點',
          point: 1200,
          discount: 300
        },
        {
          id: '004',
          name: '兌換儲值金額500元',
          info: 'HAPPY GO 點數2000點',
          point: 500,
          discount: 2000
        },
        {
          id: '005',
          name: '兌換儲值金額500元',
          info: 'HAPPY GO 點數2000點',
          point: 500,
          discount: 2000
        },
        {
          id: '006',
          name: '兌換儲值金額500元',
          info: 'HAPPY GO 點數2000點',
          point: 500,
          discount: 2000
        },
        {
          id: '007',
          name: '兌換儲值金額50元',
          info: 'HAPPY GO 點數200點',
          point: 200,
          discount: 50
        }
      ]
    }
  }
  onChangePage(pageOfItems, selectedPage) {
    // this.props.history.push(`${this.props.location.pathname}?keyword=${this.state.keyword}&page=${selectedPage}`);

    // update page of items
    // this.updatePageOfItems(pageOfItems);
    // console.log('on change');
  }
  componentDidMount() {
    this.setState({
      showMore: this.state.rentalList.length > 0 ? (this.state.rentalList.length > 5) : (this.state.prepaidList.length > 5)
    })
  }
  tabChange = (i) => {
    this.setState({
      currentTab: i
    })

    this.forceUpdate();
  }
  loadData = () => {
    this.setState({
      showMore: false,
      loadLimit: this.state.rentalList.length > 0 ? this.state.rentalList.length : this.state.prepaidList.length
    })
    this.forceUpdate()
  }
  discount = (item) => {
    this.setState({
      modalOpen: true,
      currentDiscount: item
    })
    console.log(item);
  }
  submit = () => {
    this.setState({
      modalOpen: false
    })
    console.log("折抵點數: " + JSON.stringify(this.state.currentDiscount));
  }
  render() {
    return (
      <main className='happy-go'>
        <NavAnchor
          pageTitle='個人專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="HAPPY GO 優惠"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <NavContentTab1
          default={0}
          pageTitle='帳戶設定'
          tabs={{
            name: 'page-tab',
            list: [
              { label: '如何積點' },
              { label: '點數折抵' },
              { label: '折抵記錄查詢' }
            ]
          }}
          button={{
            text: '回個人專區',
            link: '/',
          }}
          onChange={this.tabChange}
        />
        {this.state.login ? <section>
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-md-7">此服務提供您 HAPPY GO 點數折抵及記錄查詢</h4>
            <div className="paper">
              <h4 className='pt-md-4 mb-md-2 mb-1'>HAPPY GO 點數</h4>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      帳號/門號
                      </h5>
                    <h2 className='mb-0'>{this.state.phoneNumber}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      目前 HAPPY GO 點數
                    </h5>
                    <h2 className='mb-0'>{this.state.point}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <small className="is-text-gray100 mb-md-4 d-block is-text-bold">此點數會在完成帳單折抵後1天內重新計算</small>
                </Grid>
              </Grid>
            </div>
            {this.state.currentTab === 0 ? <div className="paper mb-md-7 mb-4">
              <h4 className='pt-md-4 mb-md-6'>月租型用戶 易通卡</h4>
              <h6 className='mt-0 mb-2'>如何積點？</h6>
              <p className='mb-md-8 mb-4'>帳單金額每 $100 元 累積 1 點，繳款入帳三天內自動累點(僅限遠傳月租型用戶)</p>
              <h6 className='mt-0 mb-2'>點數可以做什麼？</h6>
              <ul className="green-dot-list mb-md-4 mb-0">
                <li>折抵帳單：每 40點 折 $10 元</li>
                <li>折抵簡訊：每 70點 折 5則簡訊 (一個月)</li>
              </ul>
            </div> : null}
            {this.state.currentTab === 1 ? <div>
              <div className="paper mb-2">
                <h4 className='pt-md-4 mb-md-4'>折抵帳單、簡訊或兌換儲值金方式</h4>
                <ul className="green-dot-list mb-md-4 mb-0">
                  <li>手機免費直撥 878 按 2輸入【商品代碼】預付型手機直撥 868</li>
                  <li>全省遠傳補單機提供「HAPPY GO 點數查詢」「兌換紅利商品」服務</li>
                  <li>持HAPPY GO卡親洽全省遠傳直營門市，直接扣點折抵手機帳單</li>
                </ul>
              </div>
              <div className="paper mb-md-7 mb-4">
                <h4 className='pt-md-4 mb-md-4'>{this.state.rentalList.length > 0 ? "月租型用戶專區" : "預付型用戶專區"}</h4>
                <div className="transfer">
                  {this.state.rentalList.length > 0 ? this.state.rentalList.map((item, i) => {
                    if (i < this.state.loadLimit) {
                      return (
                        <div key={item.name + i} className="transfer--list">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <div className="subtitle is-text-darkgray50">商品項目</div>
                              <h5>{item.name}</h5>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <div className="subtitle is-text-darkgray50">折抵點數</div>
                              <h5>{item.info}</h5>
                            </Grid>
                            <Grid item xs={12} sm={4} className='d-flex flex-align-center justify-center'>
                              <div className="page-action w-100 m-0 align-center pt-md-0 pt-1">
                                <Button onClick={() => this.discount(item)} className='mb-0' btnStyle='secondary' size="medium">立即折抵</Button>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    }
                  }) :
                    this.state.prepaidList.map((item, i) => {
                      if (i < this.state.loadLimit) {
                        return (
                          <div key={item.name + i} className="transfer--list">
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4}>
                                <div className="subtitle is-text-darkgray50">商品項目</div>
                                <h5>{item.name}</h5>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <div className="subtitle is-text-darkgray50">折抵點數</div>
                                <h5>{item.info}</h5>
                              </Grid>
                              <Grid item xs={12} sm={4} className='d-flex flex-align-center justify-center'>
                                <div className="page-action w-100 m-0 align-center pt-md-0 pt-1">
                                  <Button onClick={() => this.discount(item)} className='mb-0' btnStyle='secondary' size="medium">立即折抵</Button>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        )
                      }
                    })
                  }
                </div>
                {this.state.rentalList.length > 0 ? this.state.rentalList.length > 5 ? <LoadMore
                  className='mt-md-7 mb-md-4 mt-4 mb-0'
                  moreLabel="點選看完整內容"
                  noMoreLabel="已經沒有更多內容囉！"
                  click={e => this.loadData()}
                  load={this.state.showMore}
                /> : null : this.state.prepaidList.length > 5 ? <LoadMore
                  className='mt-md-7 mb-md-4 mt-4 mb-0'
                  moreLabel="點選看完整內容"
                  noMoreLabel="已經沒有更多內容囉！"
                  click={e => this.loadData()}
                  load={this.state.showMore}
                /> : null}
              </div>
            </div> : null}
            {this.state.currentTab === 2 ? <div>
              <div className="paper mb-0">
                <h4 className='pt-md-4 mb-md-4 mb-2'>折抵記錄</h4>
                <small className="is-text-gray100 mb-md-4 mb-3 d-block is-text-bold">同一帳單月份重複兌換相同商品，已扣除之快樂購點數，將於次月月底前調回。</small>
                <div className="compareTable stick-to-right w-142 content-16 clearfix text-wrap bill-table mt-0">
                  <div className="tableLeft">
                    <div className="tableHead">
                      <table className='table-gray-head'>
                        <tbody>
                          {this.state.table.head.map((item, i) => {
                            return (
                              <tr key={item}>
                                <td className="align-center is-text-medium">{item}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tableRight">
                    <div className="tableBody">
                      <div className="spaceBox">
                        <table className="compareList table-gray-head">
                          <tbody>
                            {this.state.table.content.map((list, i) => (
                              <tr key={list + i}>
                                {list.map((item, j) => (
                                  <td className={i === 0 ? 'is-text-medium' : 'is-text-regular'} key={item + j}>{item}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.hasPagination ? <Pagination
                  items={['1', '2', '3', '4']}
                  onChangePage={this.onChangePage}
                  initialPage={1}
                  pageSize={2} /> : null}
              </div>
            </div> : null}
          </div>
        </section> : <section>
            <div className="fui-container">
              <h4 className="is-text-darkgray50 mb-md-7">此服務提供您 HAPPY GO 點數折抵及記錄查詢</h4>
              <div className="paper">
                <h4 className='pt-md-4 mb-md-6'>月租型用戶 易通卡</h4>
                <h6 className='mt-0 mb-2'>如何積點？</h6>
                <p className='mb-md-8 mb-4'>帳單金額每 $100 元 累積 1 點，繳款入帳三天內自動累點(僅限遠傳月租型用戶)</p>
                <h6 className='mt-0 mb-2'>點數可以做什麼？</h6>
                <ul className="green-dot-list mb-md-4 mb-0">
                  <li>折抵帳單：每 40點 折 $10 元</li>
                  <li>折抵簡訊：每 70點 折 5則簡訊 (一個月)</li>
                </ul>
              </div>
              <div className="paper mb-md-7">
                <h4 className='pt-md-4 mb-md-6'>遠傳大寬頻用戶 光纖/ADSL</h4>
                <h6 className='mt-0 mb-2'>如何積點？</h6>
                <p className='mb-md-8 mb-4'>繳費每滿 $100 元  累積 1 點，經系統確認繳費金額無誤後七個工作日內累點</p>
                <h6 className='mt-0 mb-2'>點數可以做什麼？</h6>
                <ul className="green-dot-list">
                  <li>折抵帳單：每 4 點 折 $1 元，折抵無上限</li>
                </ul>
                <a className='underline-link is-text-black50 text-sm mb-md-4 is-text-bold'>前往大寬頻 進行折抵 </a>
              </div>
              <Button className='mb-0' btnStyle='secondary' size="medium">線上申辦 HAPPY GO</Button>
            </div>
          </section>}
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ login: !this.state.login })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>登入切換</Button>
              <Button onClick={() => this.setState({ rentalList: this.state.rentalList.length === 0 ? rentalList : [] })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>月租型用戶/預付型用戶切換</Button>
              <Button onClick={() => this.setState({ discountSuccess: true, modalOpen: true })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>8.2.1.4_個人專區_HappyGo優惠_HappyGo折抵_兌換交易失敗popup</Button>
            </div>
          </div>
        </div>
        {this.state.currentTab !== 0 ? <SectionCollapseInfo
          title="貼心小叮嚀"
          content={this.state.currentTab == 1 ? this.state.tab2Info : this.state.tab3Info}
        /> : null}
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          {this.state.discountSuccess ? <div className='fui-prompt-modal-body'>
            <h5 className='align-center'>兌換交易失敗</h5>
            <p className='mt-0 align-center'>出現問題，請稍後再試</p>
            <img style={{ width: 140 }} src="/resources/cbu/e-service/images/failed@2x.png" alt="fail" />
            <div className="page-action">
              <Button btnStyle='primary' onClick={() => this.setState({ modalOpen: false, discountSuccess: false })}>確定</Button>
            </div>
          </div> : <div className='fui-prompt-modal-body pb-md-4 pb-2'>
              <h5 className='align-center'>兌換項目確認</h5>
              <table className="text-wrap">
                <tbody>
                  <tr>
                    <th className='is-bg-lightgray70'>商品名稱</th>
                    <td className="is-bg-white align-left">{this.state.currentDiscount.name}</td>
                  </tr>
                  <tr>
                    <th className='is-bg-lightgray70'>需要點數</th>
                    <td className="is-bg-white align-left">{this.state.currentDiscount.point}</td>
                  </tr>
                  <tr>
                    <th className='is-bg-lightgray70'>折抵金額</th>
                    <td className="is-bg-white align-left">{this.state.currentDiscount.discount}</td>
                  </tr>
                </tbody>
              </table>
              <div className="page-action">
                <Button btnStyle='primary' onClick={e => this.submit()}>立即折抵</Button>
                <Button btnStyle='secondary' onClick={e => this.setState({ modalOpen: false })}>取消</Button>
              </div>
            </div>}
        </Modal>
      </main>
    );
  }
}

export default HappyGo;