import React, { Component } from 'react';
import PropTypes from 'prop-types';
class Ruler extends Component {
    constructor(props) {
        super(props);
        this.state = {}

    }
    render() {
        return (
            <div className={`fui-ruler ${!!this.props.className ? this.props.className : ''} ${this.props.size == 'large' ? 'main' : ''}`}>
                <div className="percentage" style={{ width: (this.props.percentage + (this.props.percentage < 3 && this.props.percentage !== 0 ? 3 : 0)) + '%' }}>
                    <span className={this.props.percentage == 0 ? 'zero' : ''}>
                        {this.props.showPercentage ? <div>{this.props.percentage}%</div> : null}
                    </span>
                </div>
            </div>
        );
    }
}

Ruler.propTypes = {
    className: PropTypes.string,
    showPercentage: PropTypes.bool,
    size: PropTypes.string,
    percentage: PropTypes.number,
}

export default Ruler;