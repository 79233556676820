import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

class ServiceTag extends React.Component {
  constructor(props) {
    super(props);
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.scrollCenter();
      });
  }

  scrollCenter() {
    let width = typeof window !== 'undefined' ? window.innerWidth : 0;
    let container = document.getElementsByClassName('.service-tag-container')[0];
    let scroller = document.getElementsByClassName('.service-scroller')[0];
    if (!container) return;
    // debugger
    if (scroller.clientWidth > width) {
      // debugger
      container.scrollLeft = (scroller.clientWidth - width) / 2;
    }
  }

  componentDidMount() {
    this.scrollCenter();
  }

  render() {
    return (
      <section className='service-tags' id={this.props.id}>
        <div className='fui-container'>
          <h3 className='align-center'>{this.props.title}</h3>
          <Grid container justify='center'>
            <Grid item xs={12} sm={12} md={10}>
              <div className='service-tag-container'>
                <div className='service-scroller'>
                  {this.props.tags.map((item, key) => (
                    <Link key={`service-tag-${key}`} to={item.link} target={item.target || '_self'} className='fui-button is-tag'>
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  }
}

ServiceTag.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

export default ServiceTag;
