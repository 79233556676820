import React from 'react';
import Slider from "react-slick";
import Link from '../../Link';
import Button from '../../Button';

import ArrowLeftWhite from '../../animateArrow/ArrowLeftWhite';
import ArrowRightWhite from '../../animateArrow/ArrowRightWhite';
import ProductBgArrow from '../../animateArrow/ProductBgArrow';
import Card from '../../card/DramaCard';

import PropTypes from 'prop-types';

const LifeCircleDramaCarousel = (props) => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          variableWidth: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true
        }
      }
    ],
  }

  const renderBg = () => {
    switch (props.bgStyle) {
      case 'arrow':
        return <div className="section-bg"><ProductBgArrow /></div>
      default:
        return (
          <div className="section-bg">
            <ArrowLeftWhite />
            <ArrowRightWhite />
          </div>
        )
    }
  }

  return (
    <section className="fui-horzonal-cards drama-list">
      {renderBg()}
      <div className="fui-container">
        <h2 className="section-title">
          {props.title}
        </h2>
        <Slider {...settings} className="horizontal-cards">
          {
            props.cards.map((card, idx) => (
              <Card key={idx} {...card} />
            ))
          }
        </Slider>
        {
          props.more ? (
            <div className="align-center more">
              <Button btnStyle='secondary' link={props.more.link} size='' className="" target={props.more.target}>{props.more.text}</Button>
            </div>
          ) : ''
        }
      </div>
    </section>
  );
}

LifeCircleDramaCarousel.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.objectOf(Card)
  ),
  bgStyle: PropTypes.string,
  more: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string
  })
}

export default LifeCircleDramaCarousel;