import React from 'react';
// import Button from '../../Button';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const LifeCircleBanner = (props) => {
  const [isMobile, setIsMobile] = React.useState(typeof window !== 'undefined' && window.innerWidth < 768);

  React.useEffect(() => {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        setIsMobile(window.innerWidth < 768);
      });
  });

  return (
    <div className={`banner ${props.type} ${props.mainBanner ? 'main-banner' : ''}`} id={props.id}>
      <div className='img'>
        <img src={props.image.md} alt={props.alt} className='d-none d-md-block' />
        <img src={props.image.sm} alt={props.alt} className='d-block d-md-none' />
      </div>
      <div className='fui-container h-0'>
        <div className='text'>
          <Grid container>
            <Grid item xs={12}>
              {props.mainBanner ? (
                <img src={props.slogan.md} alt={props.sloganAlt} className='d-none d-md-block mb-5' />
              ) : (
                ''
              )}
              {props.mainBanner ? (
                <img src={props.slogan.sm} alt={props.sloganAlt} className='d-block d-md-none mb-5' />
              ) : (
                ''
              )}
              <Grid container className={`${props.type == 'img-left' ? 'justify-flex-start justify-lg-flex-end' : ''}`}>
                <Grid item xs={9} sm={5}>
                  <img src={props.icon} alt='icon' className='d-block' />
                  <h4 className='mt-0 mb-1 time'>{props.subtitle}</h4>
                  <h1 className='my-0 wording'>{props.title}</h1>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

LifeCircleBanner.propTypes = {
  id: PropTypes.string,
  mainBanner: PropTypes.bool,
  slogan: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  image: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  link: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  sloganAlt: PropTypes.string,
  icon: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

export default LifeCircleBanner;
