import React from 'react';
import Collapse from '../../collapse/Collapse';
import Link from '../../Link';

import PropTypes from 'prop-types'

class SectionFaq extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentOpen: false,
            current: -1
        }
        this.collapseChnage = this.collapseChnage.bind(this)
        
    }

    collapseChnage(open, index) {
        this.setState({
            current: index,
            currentOpen: open
        })
    }

    render() {
        return (
            <section className="fui-faq-collapse" id={this.props.id}>
                <div className={this.props.container?'fui-container':null}>
                {
                    this.props.title ? <h3 className="is-text-darkgray50">{this.props.title}</h3> : ''
                }
                    <div className="fui-collapse-group">
                    {
                        this.props.collapseList.map((collapse, i) => (
                            <Collapse 
                            key={`collapse-group-${i}`} 
                            title={collapse.title} 
                            content={collapse.content} 
                            open={this.state.current === i ? this.state.currentOpen : false}
                            onChange={open => this.collapseChnage(open, i)} />
                        ))
                    }
                    </div>
                    {this.props.hideHelpCenter?null:<p className="is-text-darkgray50">
                        還有不了解的嗎？<br/>
                        到 <Link to={this.props.to} target={this.props.target}>幫助中心</Link> 可以替你解答更多！
                    </p>}
                </div>
            </section>
        )
    }
}

SectionFaq.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    container: PropTypes.bool,
    hideHelpCenter: PropTypes.bool,
    to: PropTypes.string,
    collapseList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string,
            open: PropTypes.bool
        })
    )
}

export default SectionFaq;