import React from 'react';

import PropTypes from 'prop-types';

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth < 768
    }
  }

  componentDidMount() {
    window.addEventListener('resize', e => {
      this.setState({
        isMobile: window.innerWidth < 768
      })
    })
  }

  render() {
    const { isMobile } = this.state;
    const { progress } = this.props;
    return (
      <div className={`fui-progress-bar ${this.props.bold ? 'is-bold' : ''}`}>
        <div 
        className={`fui-progress ${this.props.color ? 'is-'+this.props.color : ''}`} 
        style={{width: this.props.progress + '%'}}>
          <div className={`fui-progress-number ${((isMobile && progress < 20) || (isMobile && progress <= Number('20')) || (!isMobile && progress < 10) || (!isMobile && progress <= Number('10'))) ? 'is-zero' : ''}`}>{this.props.progress}%</div>
        </div>
      </div>
    )
  }
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
  color: PropTypes.string,  // red | blue
  bold: PropTypes.bool,    // false
}

export default ProgressBar;