import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

let bill1 = {
  detailLink: '#123',
  date: '2020年04月',
  period: '2020/03/20 至 2020/04/19',
  phoneNumber: '0916-814-747',
  endDate: '2020/04/20',
  fee: [
    {
      title: '電信費用',
      fee: 0,
      isRed: false
    },
    {
      title: '小額代收',
      fee: 120,
      isRed: false
    },
    {
      title: '本期新增總額',
      fee: 120,
      isRed: true
    }
  ],
  typeForm: {
    title: '國內通話',
    table: {
      head: ['項目', '網內互打總時數', '他網行動總時數', '市內通話總時數', '智慧網路總時數'],
      content: [
        ['總時數', '金額(元)'],
        ['00:08:07', '0.00元'],
        ['00:02:54', '0.00元'],
        ['00:00:27', '0.00元'],
        ['00:00:27', '0.00元'],
      ],
      sum: ['小計', '199元']
    }
  },
  detailForm: {
    title: '費用類別明細',
    table: {
      head: ['日期', '109/03/22', '109/04/03', '109/04/04', '109/04/11', '109/04/03', '109/04/03', '109/04/03', '109/04/03', '109/04/03', '109/04/03'],
      content: [
        ['起始時間', '收費時段', '類型/業者', '電話號碼', '通話時間', '金額(元)'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
      ]
    }
  },
  typeList: {
    head: '篩選結果小計',
    body: ['通話時間：00:11:28', '金額：$0.0000']
  }
}
let bill2 = {
  detailLink: '#123',
  date: '2020年05月',
  period: '2020/04/20 至 2020/05/19',
  phoneNumber: '0916-814-747',
  endDate: '2020/05/20',
  fee: [
    {
      title: '電信費用',
      fee: 1000,
      isRed: false
    },
    {
      title: '小額代收',
      fee: 120,
      isRed: false
    },
    {
      title: '本期新增總額',
      fee: 120,
      isRed: true
    }
  ],
  typeForm: {
    title: '國內通話',
    table: {
      head: ['項目', '網內互打總時數', '他網行動總時數', '市內通話總時數', '智慧網路總時數'],
      content: [
        ['總時數', '金額(元)'],
        ['00:08:07', '0.00元'],
        ['00:02:54', '0.00元'],
        ['00:00:27', '0.00元'],
        ['00:00:27', '0.00元'],
      ],
      sum: ['小計', '199元']
    }
  },
  detailForm: {
    title: '費用類別明細',
    table: {
      head: ['日期', '109/03/22', '109/04/03', '109/04/04', '109/04/11', '109/04/03', '109/04/03', '109/04/03', '109/04/03', '109/04/03', '109/04/03'],
      content: [
        ['起始時間', '收費時段', '類型/業者', '電話號碼', '通話時間', '金額(元)'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
        ['16:52:57', '減價', '他網-中華', '0960520180', '00:00:35', '0.00元'],
      ]
    }
  },
  typeList: {
    head: '篩選結果小計',
    body: ['通話時間：00:11:28', '金額：$0.0000']
  }
}

class CallDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sorting: '',
      ascending: false,
      canSubmit: false,
      form: {
        id: { value: '', required: true },
      },
      selectPeriod: { value: '0', text: '2020/04月份' },
      selectBill: { value: '0', text: '0916-814-747' },
      periodOption: [
        { value: '0', text: '2020/04月份' },
        { value: '1', text: '2020/05月份' }
      ],
      billOption: [
        { value: '0', text: '0916-814-747' },
        { value: '1', text: '0911-814-747' }
      ],
      currentBill: bill1,
      searchType: { value: '0', text: '請選擇篩選項目' },
      filterType: { value: '0', text: '全部' }
    }
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }

  selectPeriod = (val) => {
    console.log(parseInt(val));

    console.log(this.state.periodOption[parseInt(val)]);

    this.setState({
      selectPeriod: this.state.periodOption[parseInt(val)]
    })
    if (val === '0') {
      this.setState({
        currentBill: bill1
      })
    } else {
      this.setState({
        currentBill: bill2
      })
    }
  }
  selectBill = (val, v) => {
    // console.log(parseInt(val));
    console.log({ val, v });
    this.setState({
      selectBill: v
    })
  }
  changeTab = () => {
    // console.log('changed tab');
  }
  selectedSearchType = (val, n, i) => {
    this.setState({
      searchType: n
    })
  }

  getTable = () => {
    if (!!this.state.currentBill.detail.table) {
      return (
        <table className="text-wrap bill-table">
          {this.state.currentBill.detail.table.map((item) => {
            return (
              <tbody key={item.head.title}>
                <tr>
                  <th className="align-left pl-5 ">{item.head.title}</th>
                  <th className="align-right  pr-5">{item.head.content}</th>
                </tr>

                {item.body.map((bodyItem) => {
                  return (
                    <tr key={bodyItem.title}>
                      <td className="align-left pl-5 ">
                        {bodyItem.title}
                        {!!bodyItem.tooltip ? <Tooltip
                          parentNode={null}
                          content={<i className='icon-information is-text-darkgray100 ml-1 d-flex'></i>}
                          trigger="click"
                          tooltip={bodyItem.tooltip}
                        /> : null}

                      </td>
                      <td className="align-right  pr-5">{bodyItem.content}</td>
                    </tr>
                  )
                })}
              </tbody>
            )
          })}
          <tbody>
            <tr>
              <td colSpan="2" className="px-5 py-4 w-100">
                <div className="d-flex justify-between flex-align-center">
                  <h6 className='m-0 is-text-black100'>{this.state.currentBill.detail.sum.title}</h6>
                  <h2 className="is-text-error m-0">{this.state.currentBill.detail.sum.content}</h2>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )
    } else {
      return
    }
  }

  onChangePage(pageOfItems, selectedPage) {
    // this.props.history.push(`${this.props.location.pathname}?keyword=${this.state.keyword}&page=${selectedPage}`);

    // update page of items
    // this.updatePageOfItems(pageOfItems);
    // console.log('on change');
  }

  sortingFunc = (sorting) => {
    // console.log(sorting);
    if (this.state.sorting == sorting) {
      this.setState({
        sorting: sorting,
        ascending: !this.state.ascending
      })
    } else {
      this.setState({
        sorting: sorting,
        ascending: false
      })
    }
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="帳單查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <NavContentTab1
          default={1}
          tabs={{
            name: 'page-tab',
            list: [
              { label: '費用項目' },
              { label: '通話明細' },
            ]
          }}
          onChange={this.changeTab}
        />

        <section className="query-index pb-0">
          <div className="fui-container">
            <Dropdown
              className="is-button d-block d-md-none"
              list={this.state.billOption}
              label={this.state.selectBill.text}
              arrow={true}
              hasCheck={false}
              onChange={this.selectBill}
            />
            <Dropdown
              className="is-button d-block d-md-none"
              list={this.state.periodOption}
              label={this.state.selectPeriod.text}
              arrow={true}
              hasCheck={false}
              onChange={(item) => this.selectPeriod(item)}
            />
            <h2 className="mb-0">{this.state.currentBill.date}帳單</h2>
            <div className="d-flex justify-between flex-align-center">
              <div className="body is-text-darkgray50">
                費用計算期間：{this.state.currentBill.period}
              </div>
              <div className="d-inline-flex float-right" style={{ flex: '1 1 auto', justifyContent: "flex-end" }}>
                <Dropdown
                  className="is-button d-none d-md-block mr-4"
                  list={this.state.billOption}
                  label={this.state.selectBill.text}
                  arrow={true}
                  hasCheck={false}
                  onChange={this.selectBill}
                />
                <Dropdown
                  className="is-button d-none d-md-block"
                  list={this.state.periodOption}
                  label={this.state.selectPeriod.text}
                  arrow={true}
                  hasCheck={false}
                  onChange={(item) => this.selectPeriod(item)}
                />
              </div>

            </div>
            <div className="paper mt-md-4 mt-2 mb-2">
              <div className="d-flex justify-between flex-align-center mt-md-4 mt-2 mb-md-4 mb-2">
                <h4 className='m-0'>帳務資訊</h4>
                <Link className='fui-button is-secondary is-small mb-0 mr-0' to={this.state.currentBill.detailLink}>
                  <span className='text'>04月明細帳單下載</span>
                </Link>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      門號
                      </h5>
                    <h2 className='mb-0'>{this.state.currentBill.phoneNumber}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      結帳日期
                    </h5>
                    <h2 className='mb-0'>{this.state.currentBill.endDate}</h2>
                  </div>
                </Grid>
              </Grid>
              <h4 className='mt-2 mt-md-5'>費用明細</h4>
              <div className="frammer mb-0 mb-md-4">
                {this.state.currentBill.fee.map((item, index) => {
                  return (
                    <div className="frammer--list" key={index}>
                      <h5>{item.title}</h5>
                      {item.isRed ? <h2 className='is-text-error'>{item.fee.toLocaleString()}元</h2> : <h4>{item.fee.toLocaleString()}元</h4>}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className='usage-rate no-bg pb-5 mb-md-5 mb-2'>
          <div className="fui-container">
            <div className="fui-segments">
              <div className="paper mb-0">
                <h4 className="mb-2 mt-md-3 mt-0">快速查詢欲查詢的費用類別</h4>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={5} lg={4}>
                    <Dropdown
                      className="is-button ml-0"
                      list={[
                        { value: '', text: '國內通話' },
                        { value: '', text: '國際通話' },
                      ]}
                      label={this.state.searchType.text}
                      arrow={true}
                      hasCheck={false}
                      onChange={(item, n, i) => this.selectedSearchType(item, n, i)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} className="mb-md-5 mb-0">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-md-3 mt-1 mb-2">
                      {this.state.currentBill.typeForm.title}
                    </h5>
                    <div className="compareTable content-16 clearfix text-wrap bill-table mt-0">
                      <div className="tableLeft">
                        <div className="tableHead">
                          <table>
                            <tbody>
                              {this.state.currentBill.typeForm.table.head.map((item, i) => {
                                return (
                                  <tr key={item}>
                                    <td className={`align-center ${i == 1 ? 'is-text-medium' : ''}`}>{item}</td>
                                  </tr>
                                )
                              })}
                              <tr>
                                <td className="is-bg-white">
                                  {this.state.currentBill.typeForm.table.sum[0]}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tableRight auto-width">
                        <div className="tableBody">
                          <div className="spaceBox">
                            <table className="compareList odd-table">
                              <tbody>
                                {this.state.currentBill.typeForm.table.content.map((list, i) => (
                                  <tr key={list + i}>
                                    {list.map((item, j) => (
                                      <td key={item + j}>{item}</td>
                                    ))}
                                  </tr>
                                ))}

                                <tr className='is-bg-white'>
                                  <td colSpan="1">
                                    {/* {this.state.currentBill.typeForm.table.sum[1]} */}
                                  </td>
                                  <td colSpan="1" className='is-text-error'>
                                    {this.state.currentBill.typeForm.table.sum[1]}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <h5 className="is-text-darkgray50 is-text-regular m-0">
                      {this.state.currentBill.detailForm.title}
                    </h5>
                  </Grid>
                  <Grid item xs={12} sm={3} className='d-none d-md-block'></Grid>
                  <Grid item xs={12} sm={3}>
                    <Dropdown
                      className="is-button ml-0"
                      list={[
                        { value: '', text: '日期' },
                        { value: '', text: '起始時間' },
                        { value: '', text: '收費時段' },
                        { value: '', text: '類型/業者' },
                        { value: '', text: '電話號碼' },
                        { value: '', text: '通話時間' },
                        { value: '', text: '金額(元)' },
                      ]}
                      label={this.state.searchType.text}
                      arrow={true}
                      hasCheck={false}
                      onChange={(item, n, i) => this.selectedSearchType(item, n, i)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Dropdown
                      className="is-button ml-0"
                      list={[
                        { value: '', text: '全部' }
                      ]}
                      label={this.state.filterType.text}
                      arrow={true}
                      hasCheck={false}
                      onChange={(item, n, i) => this.selectedSearchType(item, n, i)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="compareTable content-16 clearfix text-wrap bill-table mt-md-0 mt-2 mb-3">
                      <div className="tableLeft">
                        <div className="tableHead">
                          <table>
                            <tbody>
                              <tr>
                                <td className='is-bg-lightgray70 is-text-medium'>
                                  {this.state.currentBill.typeList.head}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tableRight auto-width">
                        <div className="tableBody">
                          <div className="spaceBox">
                            <table className="compareList not-fixed">
                              <tbody>
                                <tr>
                                  {this.state.currentBill.typeList.body.map((item, i) => {
                                    return <td key={item + i}>{item}</td>
                                  })}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="compareTable content-16 clearfix text-wrap bill-table mt-0">
                      <div className="tableLeft">
                        <div className="tableHead">
                          <table>
                            <tbody>
                              {this.state.currentBill.detailForm.table.head.map((item, i) => {
                                return (
                                  <tr key={item + i}>
                                    {i == 0 ?
                                      <td
                                        onClick={() => { this.sortingFunc(item) }}
                                        className="is-bg-lightgray70 align-center is-text-medium">
                                        {item}
                                        <i className={`${this.state.sorting == item && this.state.ascending ? 'rotate180' : ''} icon-chevron-down`} />
                                      </td>
                                      :
                                      <td className="align-center is-text-medium">{item}</td>}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tableRight auto-width">
                        <div className="tableBody">
                          <div className="spaceBox">
                            <table className="compareList not-fixed">
                              <tbody>
                                {this.state.currentBill.detailForm.table.content.map((list, i) => (
                                  <tr key={list + i}>
                                    {list.map((item, j) => {
                                      if (i == 0) {
                                        return (
                                          <td
                                            onClick={() => { this.sortingFunc(item) }}
                                            className="is-bg-lightgray70" key={item + j}>
                                            {item}
                                            <i className={`${this.state.sorting == item && this.state.ascending ? 'rotate180' : ''} icon-chevron-down`} />
                                            {/* <i className={this.state.sorting == item || !!this.state.sorting ? 'icon-chevron-down' : 'icon-chevron-up'} /> */}
                                          </td>
                                        )
                                      } else {
                                        return (
                                          <td key={item + j}>{item}</td>
                                        )
                                      }
                                    })}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      items={['1', '2', '3', '4']}
                      onChangePage={this.onChangePage}
                      initialPage={1}
                      pageSize={2} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </section>
        <SectionCollapseInfo
          title="注意事項"
          content={`
            1. 此服務提供您查詢或下載最近6個月的帳單費用及使用明細。</br>
            2. 此服務之帳單費用不包含主帳戶之收費金額，若需查詢請下載帳單PDF檔案。</br>
            3. 明細帳單下載：當您下載檔案後，開啟檔案時請輸入您的身份資料驗證。
          `}
        />
      </main >
    )
  }
}

export default CallDetail;