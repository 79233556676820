import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import Tab from '../../components/tab/Tab';
import TabPane from '../../components/tab/TabPane';
import Tooltip from '../../components/Tooltip';
import Link from '../../components/Link';
import Collapse from '../../components/collapse/Collapse';

import LabelInput from '../../components/form/LabelInput';
import { setInputFilter } from '../../utils/inputFilter';

import Button from '../../components/Button';;

class payPage extends React.Component {
  constructor(props) {
    super(props);
    this.tooltipContent = React.createRef();
    this.bankForm = React.createRef();
    this.creditForm = React.createRef();
    this.phoneNumber = React.createRef();
    this.phoneNumber2 = React.createRef();
    this.identityInput = React.createRef();
    this.identityInput2 = React.createRef();
    this.state = {
      bankAccountCanSubmit: false,
      creditCardCanSubmit: false,
      currentTab: 0,
      bankForm: {
        msid: { value: '', required: true },
        id_number: { value: '', required: true },
      },
      creditForm: {
        msid: { value: '', required: true },
        id_number: { value: '', required: true },
      }
    }
  }

  componentDidMount() {
    setInputFilter(this.phoneNumber.current.getElementsByTagName('input')[0], function (value) {
      return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    });

    setInputFilter(this.phoneNumber2.current.getElementsByTagName('input')[0], function (value) {
      return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
    setInputFilter(this.identityInput.current.getElementsByTagName('input')[0], function (value) {
      return /^\w*$/.test(value); // Allow digits and '.' only, using a RegExp
    });

    setInputFilter(this.identityInput2.current.getElementsByTagName('input')[0], function (value) {
      return /^\w*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
  }

  changeTab = (value) => {
    this.setState({
      currentTab: value
    })
  }

  enableButton = () => {
    if (this.state.currentTab === 0)
      this.setState({
        bankAccountCanSubmit: true
      })
    else
      this.setState({
        creditCardCanSubmit: true
      })
  }

  disableButton = () => {
    if (this.state.currentTab === 0)
      this.setState({
        bankAccountCanSubmit: false
      })
    else
      this.setState({
        creditCardCanSubmit: false
      })
  }

  bankFormOnChange = (n, v) => {
    let form = Object.assign(this.state.bankForm);
    form[n].value = v;

    this.setState({
      bankForm: form
    })
  }

  creditFormOnChange = (n, v) => {
    let form = Object.assign(this.state.creditForm);
    form[n].value = v;

    this.setState({
      creditForm: form
    })
  }

  bankSubmit = () => {
    this.props.history.push('/billBankPayController/billBankPayPage')
  }

  creditSubmit = () => {
    this.props.history.push('/billCreditcardPayController/billCreditcardPayPage')
  }

  render() {
    const { currentTab, bankForm, creditForm } = this.state;

    return (
      <main>

        <NavAnchor
          pageTitle="線上繳費"
          button={{
            text: "回個人專區", link: '/'
          }}
        />
        <Breadcrumb breadcrumb={[
          { link: '/', text: '個人首頁' },
          { link: '/', text: '個人專區' },
          { link: '/', text: '帳單與繳費' },
          { link: '/', text: '我要繳費' },
        ]} />
        <section className='fui-login-section'>
          <div className="fui-container">
            <h2 className="my-0">線上繳費</h2>
            <h4 className="mt-0 is-text-darkgray50">選擇最便利的繳費方式</h4>
            <Tab
              name='login-tab'
              list={[
                { label: '銀行帳號繳費' },
                { label: '信用卡線上刷卡' },
              ]}
              onChange={this.changeTab}
            />

            <TabPane active={currentTab === 0}>
              <div className="fui-segments">
                <div className="fui-segment">
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.bankForm}>
                        <div ref={this.phoneNumber}>
                          <LabelInput
                            label="門號"
                            name='msid'
                            required={true}
                            validationErrors={{
                              isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                            }}
                            placeholder='請輸入門號'
                            value={bankForm.msid.value}
                            onChange={this.bankFormOnChange}
                          />
                        </div>

                        <div className='tooltip-container-box'>
                          <span className="float-right tooltip-container" ref={this.tooltipContent}>
                            <Tooltip
                              content={<i className='icon-information'></i>}
                              trigger="click"
                              tooltip={`
                                <div>身份說明:</div>
                                <ol>
                                  <li>個人戶：身分證字號</li>
                                  <li>公司戶：統一編號或稅籍編號</li>
                                  <li>外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼</li>
                                </ol>
                              `}
                            />
                          </span>
                          <div ref={this.identityInput}>
                            <LabelInput
                              label="身份證號碼／統編"
                              name='id_number'
                              required={true}
                              validationErrors={{
                                isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                              }}
                              placeholder='請輸入身份證號碼／統編'
                              value={bankForm.id_number.value}
                              onChange={this.bankFormOnChange}
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          {/* Recaptcha 串接後未勾選按鈕不可點擊 */}
                          <img src='https://cldup.com/p-jwfiXPY0-3000x3000.png' alt='recaptcha' width="auto" />
                        </div>

                        <Button btnStyle='primary' size='large' disabled={!this.state.bankAccountCanSubmit} onClick={this.bankSubmit}>確認送出</Button>
                      </Formsy>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Link to="#" className='promotion'>
                        <img src={process.env.PUBLIC_URL + `resources/cbu/e-service/images/bg-login-promotion.png`} srcSet={process.env.PUBLIC_URL + `resources/cbu/e-service/images/bg-login-promotion@2x.png`} width="508" alt="" />
                        <div className="content">
                          <h2 className="is-text-white mt-2 mt-md-4 mb-0 mb-md-3">自動扣繳免紙本</h2>
                          <h5 className="is-text-white my-2 my-md-4">改變從現在開始，登入後申請自動扣繳免紙本</h5>
                          <Button btnStyle='primary' reverse={true} className="is-text-accent">申請自動扣繳</Button>
                        </div>
                      </Link>
                    </Grid>
                  </Grid>
                </div>
                <Collapse
                  title="貼心小叮嚀"
                  content={`
                    1. 此功能不需帳號及密碼，只要輸入身分資料完成登入檢核就可進行繳費。<br/>
                    2. 快速登入後僅能進行繳費功能，若需使用其他功能，請先進行登入。<br/>
                    3. 繳納費用非一般性消費支出，請洽各發卡銀行確認是否有刷卡紅利或現金回饋。
                  `}
                />
              </div>
            </TabPane>

            <TabPane active={currentTab === 1}>
              <div className="fui-segments">
                <div className="fui-segment">
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.creditForm}>
                        <div ref={this.phoneNumber2}>
                          <LabelInput
                            label="門號"
                            name='msid'
                            required={true}
                            validationErrors={{
                              isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                            }}
                            placeholder='請輸入門號'
                            value={creditForm.msid.value}
                            onChange={this.creditFormOnChange}
                          />
                        </div>
                        <div className='tooltip-container-box'>
                          <span className="float-right tooltip-container" ref={this.tooltipContent}>
                            <Tooltip
                              content={<i className='icon-information'></i>}
                              trigger="click"
                              required={true}
                              tooltip={`
                                <div>身份說明:</div>
                                <ol>
                                  <li>個人戶：身分證字號</li>
                                  <li>公司戶：統一編號或稅籍編號</li>
                                  <li>外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼</li>
                                </ol>
                              `}
                            />
                          </span>
                          <div ref={this.identityInput2}>
                            <LabelInput
                              label="身份證號碼／統編"
                              name='id_number'
                              required={true}
                              validationErrors={{
                                isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                              }}
                              placeholder='請輸入身份證號碼／統編'
                              value={creditForm.id_number.value}
                              onChange={this.creditFormOnChange}
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          {/* Recaptcha 串接後未勾選按鈕不可點擊 */}
                          <img src='https://cldup.com/p-jwfiXPY0-3000x3000.png' alt='recaptcha' width="auto" />
                        </div>

                        <Button btnStyle='primary' size='large' disabled={!this.state.creditCardCanSubmit} onClick={this.creditSubmit}>確認送出</Button>
                      </Formsy>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Link to="#" className='promotion'>
                        <img src={process.env.PUBLIC_URL + `resources/cbu/e-service/images/bg-login-promotion.png`} srcSet={process.env.PUBLIC_URL + `resources/cbu/e-service/images/bg-login-promotion@2x.png`} width="508" alt="" />
                        <div className="content">
                          <h2 className="is-text-white mt-2 mt-md-4 mb-0 mb-md-3">自動扣繳免紙本</h2>
                          <h5 className="is-text-white my-2 my-md-4">改變從現在開始，登入後申請自動扣繳免紙本</h5>
                          <Button btnStyle='primary' reverse={true} className="is-text-accent">申請自動扣繳</Button>
                        </div>
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </TabPane>

          </div>
        </section>
      </main>
    )
  }
}

export default payPage;