import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';



class FourGPurchaseRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emptyTable: {},
            table: {
                head: ['加購日期', '2014/12/31', '2014/12/30', '2014/12/24'],
                content: [
                    ['方案名稱', '可使用量', '使用期限'],
                    ['4G上網計量包1GB', '1.0GB(1,024MB)', '2014/12/31'],
                    ['4G上網計量包1GB', '1.0GB(1,024MB)', '2014/12/31'],
                    ['4G上網計量包1GB', '1.0GB(1,024MB)', '2014/12/31'],
                ]
            },
            currentTable: {}
        }
    }

    render() {
        return (
            <main>
                <NavAnchor
                    pageTitle='帳單與用量'
                    button={{
                        text: '回個人專區',
                        link: '#'
                    }} />
                <FormBanner
                    title="加購記錄查詢"
                    image={{
                        md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
                        sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
                    }}
                />

                <section className="usage-rate mt-7 no-bg">
                    <div className="fui-container">
                        <div className="fui-segments no-shadow is-bg-initial">
                            <h4 className="is-text-darkgray50 mb-7">
                                提供您最近6個月的上網加購記錄。
                            </h4>
                            <div className="paper mt-md-4 mt-2 mb-0 pb-md-7 pb-4">
                                <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-4 mb-2">
                                    <h4 className='m-0'>您最近六個月加購記錄如下</h4>
                                </div>
                                {Object.keys(this.state.currentTable).length === 0 ? <div>
                                    <div className="body is-text-darkgray50">
                                        無加購上網用量記錄
                                    </div>
                                </div> : <div className="compareTable content-16 clearfix text-wrap bill-table mt-0">
                                        <div className="tableLeft">
                                            <div className="tableHead">
                                                <table>
                                                    <tbody>
                                                        {this.state.currentTable.head.map((item, i) => {
                                                            return (
                                                                <tr key={item}>
                                                                    <td className={`align-center ${i == 1 ? 'is-text-medium' : ''} ${i == 0 ? 'is-bg-lightgray70' : ''}`}>{item}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="tableRight">
                                            <div className="tableBody">
                                                <div className="spaceBox">
                                                    <table className="compareList">
                                                        <tbody>
                                                            {this.state.currentTable.content.map((list, i) => (
                                                                <tr key={list + i} className={i == 0 ? 'is-bg-lightgray70' : ''}>
                                                                    {list.map((item, j) => (
                                                                        <td key={item + j}>{item}</td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </section>
                {/* remove in production */}
                <div className="switcher py-5">
                    <div className="fui-container">
                        <div className="d-flex flex-column">
                            <p>測試用資料切換</p>
                            <Button onClick={() => this.setState({ currentTable: this.state.table })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.7.1_帳單與用量_4G加購記錄查詢</Button>
                            <Button onClick={() => this.setState({ currentTable: this.state.emptyTable })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.7.2_帳單與用量_4G加購記錄查詢</Button>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

export default FourGPurchaseRecord;