import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Link from '../Link';

const ToolsItem = (props) => {
  return (
    <section className='tools-list' id={props.id}>
      <div class='align-center'>
        {props.title ? (
          <div>
            <h1 className='with-comma'>{props.title}</h1>
            <h4 className='is-text-regular'>
              {props.subtitle1}
              <span className='is-text-error border-bottom'>{props.subtitleHighlight}</span>
              {props.subtitle2}
            </h4>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='tools-item'>
        <Grid container>
          {props.item.map((item, i) => (
            <Grid item>
              <Link to={item.link} target={item.target}>
                <img src={item.image} alt={item.imageAlt} className='' />
                <h5 className='my-0'>{item.title}</h5>
                <div className='body-1'>{item.description}</div>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};

ToolsItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  withComma: PropTypes.string,
  subtitle1: PropTypes.string,
  subtitleHighlight: PropTypes.string,
  subtitle2: PropTypes.string,
  item: PropTypes.shape({
    img: PropTypes.string,
    title: PropTypes.string,
    imageAlt: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    target: PropTypes.string,
  }),
};

export default ToolsItem;
