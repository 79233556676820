import React from 'react';
import NavAnchor from '../../components/partials/NavAnchor';

import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import Modal from 'react-modal';
import Link from '../../components/Link';
import FormBanner from '../../components/partials/banner/FormBanner';
import Button from '../../components/Button';

import * as Mock from '../../mock/RoamingPlan';

class ApplyConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
  }

  openModal = () => {
    this.setState({
      modalOpen: true
    })
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊方案申請/查詢'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="漫遊上網方案申請"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="form-section roaming-plan no-bg">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-4 mb-lg-6">請確認申請內容，並送出申請</h4>

            <h4 className="is-text-darkgray50">申請方案</h4>

            <div className="fui-table-response mb-2 mb-lg-4">
              <table className="confirm-table">
                <tbody>
                  <tr>
                    <th>方案</th>
                    <td>多國家上網吃到飽</td>
                  </tr>
                  <tr>
                    <th>適用國家</th>
                    <td>請查看 <Link to='#' className='' className="is-text-error info-link" onClick={() => { this.setState({ modalOpen: true }) }}>適用國家列表</Link></td>
                  </tr>
                  <tr>
                    <th>使用期間</th>
                    <td>2020/05/26 17:30 - 2020/05/30 17:30 </td>
                  </tr>
                  <tr>
                    <th>天數</th>
                    <td>4 天</td>
                  </tr>
                  <tr>
                    <th>預估費用</th>
                    <td>請以實際出帳金額為主</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="is-text-gray100 body-2 mb-1">※實際費用請依帳單金額為主</div>

            <div className="is-text-gray100 body-2 mb-1">※漫遊費用將併入原門號帳單收費</div>


            <div className="button-group mt-5">
              <div className="form-group two-buttons">
                <Button btnStyle='primary' size='large'>送出申請</Button>
                <Button btnStyle='secondary' size='large'>上一步</Button>
              </div>
            </div>

          </div>
        </section>

        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            1. 本方案啟動後即收取方案全額費用，無法退費。申裝前，請先確認旅行目的地屬於優惠適用國家，以使用優惠上網。<br>
2. 方案生效期間，上網量提前用完則方案等同提前到期，於適用國家將無法上網。方案終止後，未用完之上網量將歸零不得遞延。申請亞洲輕量包暢用型生效後，若經使用無法申請退費或取消，無比例分攤收費。<br>
3. 『亞洲輕量包暢用型1GB』於用戶申辦並收到優惠生效簡訊後連續10日(連續240小時有效)享1GB優惠; 『亞洲輕量包暢用型2GB』於用戶申辦並收到優惠生效簡訊後連續10日(連續240小時有效)享2GB優惠，若於到期日前上網優惠傳輸量已用完，將無法於該優惠適用國家繼續使用上網功能。可選擇申辦加價購服務，以回復上網功能。加價購方式：當漫遊上網傳輸使用達70%及98%時，系統會發送提醒通知，可回傳簡訊直接購買亞洲輕量包暢用型500MB/ NT$99。收到簡訊回覆1，即可加購(回傳加購簡訊不收費)。10日到期後方案終止，未用完之傳輸量將歸零且不得要求遞延、折現或為其他主張。<br>
4. 若用戶門號安裝「取消雙向簡訊加購傳輸量服務」，於國外使用亞洲輕量包暢用型，亦無法透過70% & 98%雙向簡訊進行用量加購。<br>
5. 提醒您，出國前可先關閉手機、App等自動更新程式，以避免程式自動更新，使用到您所購買的定量包上網傳輸量。<br>
6. 以上優惠方案僅適用於特定漫遊國家，用戶需於漫遊國使用漫遊行動上網才享優惠，於非優惠國家(含船舶、航空)使用漫遊上網服務依公告之一般國際漫遊上網費率收費。<br>7. 最新優惠適用國家可洽客服專線+886936010888 。用戶於全球撥打客服中心專線免費<br>
8. 因經營成本及其他相關因素之變動，遠傳保留修改或終止本優惠費率與優惠活動之權利，詳細費率、優惠費率適用期間及活動內容以遠傳網站公告為主。<br>
          `}
        />
        

        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5 className='align-center'>方案詳細說明</h5>
            <p className='align-left mt-0'>上網費率</p>
            <div className="fui-table-response mb-2 mb-lg-4">
              <table className="modal-roaming-country-table">
                <thead>
                  <tr>
                    <th>用量</th>
                    <th>上網費率</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>大於（含） 2MB</td>
                    <td>$99 / 天</td>
                  </tr>
                  <tr>
                    <td>小於 2MB</td>
                    <td>$99 / 天</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="align-left mb-3 notice">
              <p className='is-text-medium mb-1'>注意事項</p>
              <p className=''>1.本優惠費率以到達當地開始上網起，連續24小時為一日計價。<br/>
              2.適用對象: 至國外漫遊登錄在KT/SKT之遠傳月租型客戶。出國前，請致電客服中心開啟/確認國際數據漫遊服務。手機直撥123/888或 MVPN用戶99888 (免費)；免付費客服專線0800-058-885。市話撥449-5888(市內計費)，在國外時請電+886936010888。</p>
            </div>

            <div className="fui-buttons justify-center">
              <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
            </div>



            <h5 className='align-center'>多國家上網吃到飽</h5>
            <p className='align-left mt-0'>適用國家及費率</p>
            <div className="fui-table-response mb-2 mb-lg-4">
              <table className="modal-roaming-country-table">
                <thead>
                  <tr>
                    <th>國家</th>
                    <th>上網費率</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>中國、香港、澳門</td>
                    <td>$ 0 / 天</td>
                  </tr>
                  <tr>
                    <td>韓國、新加坡、泰國、印尼、菲律賓</td>
                    <td>$99 / 天</td>
                  </tr>
                  <tr>
                    <td>馬來西亞、日本、關島、塞班、天寧島、羅塔島、美國、加拿大、波多黎各</td>
                    <td>$299 / 天</td>
                  </tr>
                  <tr>
                    <td>柬埔寨、越南、澳洲、紐西蘭、法國、以色列、竂國</td>
                    <td>$399 / 天</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="align-left mb-3 notice">
              <p className='is-text-medium mb-1'>貼心提醒</p>
              <p className=''>1. 優惠費率以一網一日計價，用戶若於一日內漫遊到多個國家/地區的網路，會依網數進行一日計費 (例如：用戶若於一天內漫遊至第二個國家/地區的網路，將分別依二網用量各自一日計價，以此類推)。<br/>
              2. 本優惠費率以到達當地開始上網起，連續24小時為一日計價。</p>
            </div>

            <div className="fui-buttons justify-center">
              <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
            </div>






            <h5 className='align-center'>全球定量包 1GB</h5>
            <p className='align-left mt-0'>適用國家</p>

            <div className="fui-table-response mb-2 mb-lg-4">
              <table className="modal-roaming-package-table">
                <tbody>
                  <tr>
                    <th>歐洲（39 國）</th>
                    <td>阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國</td>
                  </tr>
                  <tr>
                    <th>美洲（8 國）</th>
                    <td>阿根廷、貝里斯、加拿大、智利、波多黎各、美屬維京群島（處女島）、美國、巴拿馬</td>
                  </tr>
                  <tr>
                    <th>大洋洲（2 國）</th>
                    <td>澳洲、紐西蘭</td>
                  </tr>
                  <tr>
                    <th>非洲（6 國）</th>
                    <td>剛果民主共和國、迦納、莫三比克、南非、坦尚尼亞、埃及</td>
                  </tr>
                  <tr>
                    <th>亞洲（5 國）</th>
                    <td>印度、哈蕯克、蒙古、斯里蘭卡、土耳其</td>
                  </tr>
                  <tr>
                    <th>中東（5 國）</th>
                    <td>巴基斯坦、以色列、科威特、卡達、沙烏地阿拉伯</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="fui-buttons justify-center">
              <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
            </div>
          </div>
        </Modal>

      </main>
    )
  }
}

export default ApplyConfirm;