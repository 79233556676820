import React from 'react';
import { NavLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import LinkItem from '../../item/LinkItem';
import FlatCard from '../../card/FlatCard';

const MenuContent1 = (props) => {
  const renderList = (array) =>
    array &&
    array.map((item, i) => (
      <LinkItem key={`meun-content-list-item-${i}`} link={item.link} className='item'>
        {item.text}
      </LinkItem>
    ));

  return (
    <Grid item sm={10} md={10} lg={8} xl={10} className='menu-body'>
      <div className='menu-content'>
        <Grid container spacing={2}>
          {props.menu.map((menuItem, i) => (
            <Grid key={`menu-content-list-${i}`} item md={props.menu.length === 4 ? 3 : 4} id={`${props.id}-${i + 2}`}>
              <h6>{menuItem.title}</h6>
              {renderList(menuItem.list)}
              {menuItem.more && menuItem.more.text ? (
                <LinkItem link={menuItem.more.link} icon='chevron-right' className='more'>
                  {menuItem.more.text}
                </LinkItem>
              ) : null}
            </Grid>
          ))}
        </Grid>
        {!!props.cards && props.cards.length > 0 ? (
          <div className='fui-card-container mt-5 pt-5' id={`${props.id}-${props.menu.length + 2}`}>
            <h6 className='mb-2'>主題企劃</h6>
            <div className='fui-cards is-flat'>
              {props.cards.map((card, i) => (
                <FlatCard key={`box-card-${i}`} {...card} className={`box ${card.bg}`} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {props.more ? (
        <div className='menu-action'>
          <div role='button' className='action'>
            <NavLink to={props.more.link}>
              {props.more.text} <i className='icon-chevron-right'></i>
            </NavLink>
          </div>
        </div>
      ) : null}
    </Grid>
  );
};

export default MenuContent1;
