import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

import * as Mock from '../../mock/EServiceIndex';


/**
 * 
 * this.props.history.push('/resultController/ResultPage', {
      type: 'success',  // success ||  fail
      pageName: '帳單繳費',
      title: '購買成功',
      description: `你已完成上網用量加購 <br/>方案名稱：4G上網計量包3GB / 500`,
      actions: [
        {
          text: '查詢購買記錄',
          link: '#',
          btnStyle: 'primary'
        },
        {
          text: '前往個人專區',
          link: '#',
          btnStyle: 'secondary'
        }
      ]
    })
 */

class ResultPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'success',
      title: '修改成功',
      description: '修改成功',
      ...this.props.location.state,
      showActions: window.channelId !== 'SUPPERAPP'
    }
  }

  render() {
    return (
      <main className={this.state ? 'd-block' : 'd-none'}>
        <NavAnchor
          pageTitle={this.state.pageName || ''}
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <section className="e-service-result is-success">
          <div className="fui-container">
            <Grid container>
              <Grid item xs={12}>
                {
                  this.state.type === 'fail' ? (
                    <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed@2x.png'} alt='' />
                  ) : (
                      <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/failed.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/failed@2x.png'} alt='' />
                    )
                }

                <div className="content">
                  <h4 className="mb-0 mt-3">{this.state.title || ''}</h4>
                  <p className='body1' dangerouslySetInnerHTML={{
                    __html: this.state.description || ''
                  }}></p>
                </div>
              </Grid>
            </Grid>

            {
              this.state.actions && this.state.actions.length && this.state.showActions ? (
                <div className='action align-center mt-5'>
                  {
                    this.state.actions.map((act, i) => (
                      <Button key={`result-action-${i}`} {...act} size='large'>{act.text}</Button>
                    ))
                  }
                </div>
              ) : null
            }

          </div>
        </section>
        <SectionCarousel1 {...Mock.promotionCard} />
      </main>
    )
  }
}

export default ResultPage;