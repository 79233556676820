import React from 'react';
import Link from './Link';
import PropTypes from 'prop-types';

class Breadcrumb extends React.Component {
  overflow = (val) => {
    if (val.length > 20) {
      return val.slice(0, 20) + '...';
    } else {
      return val;
    }
  };

  render() {
    const style = { cursor: 'default' };
    return (
      <section className='fui-breadcrumb-container' style={{ top: this.props.top }}>
        <div className='fui-container'>
          <ul className={`fui-breadcrumb is-${this.props.color}`}>
            {this.props.breadcrumb.map((item, idx) => (
              <li key={`breadcrumb-item-${idx}`}>
                <Link to={item.link}>{this.overflow(item.text)}</Link>
                {/* <a href={item.link}>{this.overflow(item.text)}</a> */}
                {/* <a href={"javascript:void(0)"} style={style} >{this.overflow(item.text)}</a> */}
              </li>
            ))}
          </ul>
        </div>
      </section>
    );
  }
}

Breadcrumb.propTypes = {
  color: PropTypes.string, // black | white
  top: PropTypes.number, // container position
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired, // 麵包屑連結必要
      text: PropTypes.string.isRequired, // 麵包屑名稱必要
    })
  ),
};

export default Breadcrumb;
