import React from 'react';
import Link from '../Link';
import PropTypes from 'prop-types';

const BreakView = props => {
  const { breakLabel, breakClassName, breakLinkClassName, onClick } = props;
  const className = breakClassName || 'break';

  return (
    <li className={className}>
      <Link
        className={breakLinkClassName}
        onClick={onClick}
        role="button"
        tabIndex="0"
        to="#"
        onKeyPress={onClick}
      >
        {breakLabel}
      </Link>
    </li>
  );
};

BreakView.propTypes = {
  breakLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  breakClassName: PropTypes.string,
  breakLinkClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default BreakView;