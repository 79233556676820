import React, { Component } from 'react'
import NavContentTab1 from '../../components/partials/NavContentTab1';
import Checkbox from '../../components/form/Checkbox';
import RadioGroup from '../../components/form/RadioGroup';
import TextInput from '../../components/form/TextInput';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Dropdown from "../../components/Dropdown";
import Modal from 'react-modal';
import Button from '../../components/Button';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
// 8.1.5.4_個人專區_帳戶管理_電子發票設定_電信電子發票設定_展開
// https://zpl.io/aNXoM3Z
class ReceiptSetting extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      modalLoader: 'autoChecking',
      modalOpen: false,
      canSubmit: false,
      loaderForm: false,
      donateTarget: [
        {
          value: '1',
          text: '1'
        },
        {
          value: '2',
          text: '2'
        },
      ],
      selectDonateTarget: '',
      form: {
        loader: {
          value: '1'
        },
        loaderSeries: {
          value: ''
        },
        integrate: {
          value: false
        },
        year: {
          value: ''
        },
        loaderNum: {
          value: ''
        },
        validCode: {
          value: ''
        },
        loaderCertification: {
          value: ''
        },
        donation: {
          value: '1'
        },
        donateOthers: {
          value: ''
        },
        receiptSetting: {
          value: '2'
        },
        bingo: {
          value: '2'
        },
      }
    }
  }
  changeTab = () => {
    // console.log('changed tab');
  }
  onChange = (name, value) => {
    console.log(name, value);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    this.setState({
      form: newForm
    })
  }
  inputChange = (event, val) => {
    let newForm = Object.assign(this.state.form);
    newForm[val].value = event.currentTarget.value;

    this.setState({
      form: newForm
    })
  }
  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };
  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };
  openModal = (val) => {
    this.setState({
      modalOpen: true,
      modalLoader: val
    })

  }
  loaderFormCanSubmit = (val) => {
    this.setState({
      loaderForm: val
    })
  }
  getLoader = () => {
    return this.state.form.loader.value === '1' ?
      <Grid item xs={12} sm={12}>
        <Formsy onValid={() => { this.loaderFormCanSubmit(true) }} onInvalid={() => { this.loaderFormCanSubmit(false) }}>
          <Checkbox
            name="integrate"
            label="我想將帳單載具號碼歸戶到財政部電子發票整合服務平台"
            value="0"
            className="mt-0 mb-3"
            checked={this.state.form.integrate.value}
            onChange={this.onChange}
          />
          <div className={`receipt-loader ${this.state.form.integrate.value ? 'd-block' : 'd-none'}`}>
            <div className="receipt-loader--header">
              <h4 className="is-text-darkgray50">
                載具號碼
          </h4>
            </div>
            <div className="receipt-loader--body">
              <p>請填入帳單載具號碼，於電信帳單第一頁右上方的20碼帳單載具號碼於下方欄位（詳細可看<a onClick={() => { this.openModal('loaderInstruction') }} className="info-link">圖片解說</a>），完成歸戶設定之後，即可透過財政部電子發票整合服務平台，查詢遠傳所開立的發票資訊。(因每期號碼不同，請每月收到帳單後進行歸戶設定)。</p>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <label className='mt-4 mb-2'>年期別(5碼)</label>
                  <TextInput
                    type='text'
                    name='year'
                    required={true}
                    maxLength="5"
                    minLength="5"
                    placeholder="請輸入年期別"
                    validationError={"請輸入年期別"}
                    getErrorMessage={() => { return "請輸入年期別" }}
                    validations={{
                      maxLength: 5,
                      minLength: 5
                    }}
                    value={this.state.form.year.value}
                    onChange={val => this.onChange('year', val)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <label className='mt-4 mb-2'>載具流水號(10碼)</label>
                  <TextInput
                    type='text'
                    name='loaderSeries'
                    required={true}
                    placeholder="請輸入載具流水號"
                    validationError={"請輸入載具流水號"}
                    getErrorMessage={() => { return "請輸入載具流水號" }}
                    value={this.state.form.loaderSeries.value}
                    onChange={val => this.onChange('loaderSeries', val)}
                  />

                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <label className='mt-4 mb-2'>檢核碼(5碼)</label>
                  <TextInput
                    type='text'
                    name='validCode'
                    required={true}
                    maxLength="5"
                    minLength="5"
                    placeholder="請輸入檢核碼"
                    validations={{
                      maxLength: 5,
                      minLength: 5
                    }}
                    validationError="請輸入檢核碼(5碼)"
                    getErrorMessage={() => { return "請輸入檢核碼(5碼)" }}
                    value={this.state.form.validCode.value}
                    onChange={val => this.onChange('validCode', val)}
                  />
                </Grid>
              </Grid>
              <div className="page-action mt-md-6 mb-md-2">
                <Button className={`mb-md-0 ${this.state.loaderForm ? '' : 'disabled'}`} btnStyle='primary' onClick={(e) => { console.log('hi') }}>
                  送出
						    </Button>
                <Button className='mb-0' btnStyle='secondary' onClick={(e) => { console.log('hi') }}>
                  取消
						    </Button>
              </div>
            </div>
          </div>
        </Formsy>
      </Grid > : null
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳戶服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="電子發票設定"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <NavContentTab1
          default={1}
          tabs={{
            name: 'page-tab',
            list: [
              { label: '加值服務電子發票設定' },
              { label: '電信電子發票設定' },
            ]
          }}
          onChange={this.changeTab}
        />
        <section className="pt-md-7 pt-2 pb-md-7 pb-6">
          {/* <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}> */}
          <div className="fui-container">
            <div className="paper mt-2 mb-2">
              <h4 className='mt-md-2 mb-2'>加值服務電子發票設定說明</h4>
              <ul className="green-dot-list">
                <li>您可在此設定電信費帳單的發票相關設定，不包含其他服務 (如friDay影音、friDay音樂、Google Play商店消費、iTunes store商店消費.....等)。</li>
                <li>若您的電信帳單整合了多組門號，以任一門號進行電子發票設定後，此設定也會同步更新到其他門號。</li>
                <li>發票捐贈、手機條碼、自然人憑證載具及取消發票設定，設定/異動將於下期帳單生效；轉帳代繳用戶電子發票中獎小額獎金匯入原扣款帳戶設定，將於10分鐘生效。</li>
                <li>轉帳代繳用戶電子發票中獎小額獎金匯入原扣款帳戶作業，限已辦理郵局/銀行自動轉帳扣款成功所開立之發票(若您已索取過紙本發票或己設定發票捐贈、手機條碼、自然人憑證，不適用本項服務)。</li>
              </ul>
            </div>
            <div className="paper mt-md-2 mt-2 merged">
              <h4 className='mt-md-2 mb-2'>發票載具設定</h4>
              <div className='form-group mb-md-4 mb-0'>
                <div className='radio-group'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <label>請選擇載具</label>
                      <label className='radio-button'>
                        <p className='m-0'>帳單載具號碼</p>
                        <input
                          type='radio'
                          name="loader"
                          value={1}
                          checked={this.state.form.loader.value === '1'}
                          onChange={e => this.onChange('loader', e.currentTarget.value)}
                        />
                        <span className='checkmark'></span>
                      </label>
                      <small className="is-text-gray100 mb-3">未設定則使用帳單載具號碼做為儲存電子發票的載具</small>
                    </Grid>
                    {this.getLoader()}
                  </Grid>
                  <Formsy className="w-100" onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <div className="radio-textInput">
                          <label className='radio-button'>
                            <p className='m-0'>手機條碼</p>
                            <input
                              type='radio'
                              name="loader"
                              value={2}
                              checked={this.state.form.loader.value === '2'}
                              onChange={e => this.onChange('loader', e.currentTarget.value)}
                            />
                            <span className='checkmark'></span>
                          </label>
                          <TextInput
                            type='text'
                            name='loaderNum'
                            required={false}
                            disabled={this.state.form.loader.value !== '2'}
                            className="w-100"
                            placeholder="請輸入手機條碼"
                            validationError={"請輸入手機條碼"}
                            getErrorMessage={() => { return "請輸入手機條碼" }}
                            onChange={val => this.onChange('loaderNum', val)}
                          />
                        </div>
                        <small className="is-text-gray100 mt-2 d-block mb-3">您需持有手機載具，按此<a href="#" className="info-link">申請手機條碼</a></small>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div className="radio-textInput">
                          <label className='radio-button'>
                            <p className='m-0'>自然人憑證</p>
                            <input
                              type='radio'
                              name="loader"
                              value={3}
                              checked={this.state.form.loader.value === '3'}
                              onChange={e => this.onChange('loader', e.currentTarget.value)}
                            />
                            <span className='checkmark'></span>
                          </label>
                          <TextInput
                            type='text'
                            name='loaderCertification'
                            required={false}
                            className="w-100"
                            disabled={this.state.form.loader.value !== '3'}
                            placeholder="請輸入自然人憑證"
                            validationError={"請輸入自然人憑證"}
                            value={this.state.form.loaderCertification.value}
                            onChange={val => this.onChange('loaderCertification', val)}
                          />
                        </div>
                        <small className="is-text-gray100 mt-2 d-block">您需持有自然人憑證，按此<a href="#" className="info-link">申請自然人憑證條碼密碼</a></small>
                      </Grid>
                    </Grid>
                  </Formsy>
                </div>
              </div>
            </div>
            <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
              <div className="paper merged">
                <h4 className='mt-md-2 mb-2'>發票捐贈設定</h4>
                <div className='form-group mb-md-4 mb-0'>
                  <label>請選擇捐贈方式</label>
                  <div className='radio-group'>
                    <Grid item xs={12} sm={8}>
                      <div className="radio-textInput">
                        <label className='radio-button radio-textInput--300'>
                          <p className='m-0'>選擇受捐贈團體</p>
                          <input
                            type='radio'
                            name="donation"
                            value={1}
                            checked={this.state.form.donation.value === '1'}
                            onChange={e => this.onChange('donation', e.currentTarget.value)}
                          />
                          <span className='checkmark'></span>
                        </label>
                        <Dropdown
                          className={`is-button w-100 ${this.state.form.donation.value !== '1' ? 'disabled' : ''}`}
                          list={this.state.donateTarget}
                          label={!!this.state.selectDonateTarget.text ? this.state.selectDonateTarget.text : '請選擇'}
                          arrow={true}
                          hasCheck={false}
                          onChange={(val, v) => this.setState({ selectDonateTarget: v })}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <div className="radio-textInput mb-md-3 mb-0">
                        <label className='radio-button radio-textInput--300'>
                          <p className='m-0'>其他受捐贈團體捐贈碼</p>
                          <input
                            type='radio'
                            name="donation"
                            value={2}
                            checked={this.state.form.donation.value === '2'}
                            onChange={e => this.onChange('donation', e.currentTarget.value)}
                          />
                          <span className='checkmark'></span>
                          <a href="#" className="d-block d-md-none underline-link is-text-black50 body-1">查詢捐贈碼</a>
                        </label>
                        <TextInput
                          type='text'
                          name='donateOthers'
                          disabled={this.state.form.donation.value !== '2'}
                          required={false}
                          className="w-100"
                          maxLength="10"
                          placeholder="請輸入捐贈碼"
                          validationError={"請輸入捐贈碼"}
                          value={this.state.form.donateOthers.value}
                          onChange={val => this.onChange('donateOthers', val)}
                        />
                      </div>
                    </Grid>
                    <a href="#" className="d-none d-md-block mt-2 ml-2 underline-link is-text-black50">查詢捐贈碼</a>
                  </div>
                </div>
              </div>
              <div className="paper merged">
                <h4 className='mt-md-2 mb-2'>發票設定</h4>
                <RadioGroup
                  className="mb-md-2 mb-0"
                  label=''
                  name="receiptSetting"
                  options={[
                    { label: '設定捐贈/歸戶', value: '1' },
                    { label: '不設定捐贈/歸戶', value: '2' }
                  ]}
                  default="2"
                  onChange={this.onChange}
                />
              </div>
              <div className="paper">
                <h4 className='mt-md-2 mb-2'>轉帳代繳用戶電子發票中獎小額獎金匯入原扣款帳戶</h4>
                <p>
                  若您已索取過紙本發票或己設定發票捐贈、手機條碼、自然人憑證，不適用本項服務。<br />
                  若選擇同意自動匯入，則發票中獎獎金4000元（含）以下，將自動匯入原扣款郵局/銀行帳戶，更多兌獎詳情可參考 <a onClick={() => { this.openModal('autoChecking') }} className="info-link">自動兌獎相關說明</a>。<br />
                  若選擇不同意自動匯入，則需至四大超商多媒體機(KIOSK)列印中獎電子發票證明聯，自行兌獎。
                </p>
                <RadioGroup
                  className="mb-md-2 mb-0 mt-3"
                  label=''
                  name="bingo"
                  options={[
                    { label: '同意，自動匯入帳戶', value: '1' },
                    { label: '不同意，自行兌獎', value: '2' }
                  ]}
                  default="2"
                  onChange={this.onChange}
                />
              </div>
              <div className="page-action mt-md-7">
                <Button
                  className='mb-md-0'
                  size="large"
                  btnStyle='primary'
                  onClick={(e) => this.setState({ modalOpen: false })}
                  disabled={!!!this.state.selectDonateTarget && !!!this.state.form.donateOthers.value}
                >
                  確定變更
						  </Button>
                <Button className='mb-md-0' size="large" btnStyle='secondary' onClick={(e) => this.setState({ modalOpen: false })}>
                  取消
						  </Button>
              </div>
            </Formsy>
          </div>
          {/* </Formsy> */}
        </section>
        <SectionCollapseInfo
          title="注意事項"
          content={`
						1. 將發票歸戶至財政部共通性載具(手機條碼、自然人憑證)，或已設定發票捐贈者，將由財政部通知發票中獎，您也可至財政部電子發票整合服務平台查詢與設定中獎通知；財政部免付費服務專線：0800-521-988。<br/>
						2. 單次性捐贈發票，請先歸戶至財政部共通性載具，再至財政部電子發票整合服務平台進行發票捐贈。<br/>
						3. 發票一經捐贈即不可反悔與更改，中獎權利立即轉移。若受贈單位不具合法受贈資格，發票中獎獎金將歸入國庫，不予發放。<br/>
						4. 電信費轉帳代繳用戶發票小額獎金匯入原扣款帳戶作業，限已辦理郵局/銀行自動轉帳扣款成功所開立之發票（若您已索取過紙本發票或已設定發票捐贈、手機條碼、自然人憑證，不適用本項服務）。<br/>
            5. 轉帳代繳用戶電子發票中獎小額獎金匯入原扣款帳戶作業，適用獎項：統一發票四獎4,000元、五獎1,000元、六獎200元及雲端發票專屬仟元獎2,000元、雲端發票專屬百元獎500元 。<br/>
            6. 適用電信費轉帳代繳用戶發票小額獎金匯入原扣款帳戶者，無法以四大超商多媒體機(KIOSK)列印中獎電子發票證明聯，且依規定本公司亦不能提供中獎紙本電子發票證明聯，以避免重複兌獎。
					`}
        />
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className={`fui-prompt-modal ${this.state.modalLoader == 'loaderInstruction' ? 'is-large' : ''}`}>
          <div className='fui-prompt-modal-body'>
            {this.state.modalLoader == 'loaderInstruction' ? <div>
              <h5>載具號碼圖片解說</h5>
              <div className="scrollable-modal-body align-left">
                <ul className="green-dot-list align-left">
                  <li>完成歸戶設定之後，即可透過財政部電子發票整合服務平台，查詢遠傳所開立的發票資訊。</li>
                  <li>選擇帳單載具號碼歸戶，因每期載具號碼不同，請於每月收到帳單進行載具的歸戶設定。</li>
                  <li>帳單載具號碼為每期電信費帳單第一頁的右上方。(下圖圈選處)</li>
                </ul>
                <img className="w-100" src="/resources/cbu/img/img-invoice-carrier-info@2x.jpg" alt="img" />
              </div>
              <div className="page-action align-center mt-0">
                <Button className='mb-0 mt-3' btnStyle='primary' onClick={(e) => this.setState({ modalOpen: false })}>
                  我瞭解了
						</Button>
              </div>
            </div> : <div>
                <h5 className="align-center">自動兌獎相關說明</h5>
                <div className="scrollable-modal-body align-left">
                  <small className="is-text-light mb-4 d-block">107年10月1日起，轉帳代繳用戶扣款成功，電信電子發票中獎，小額獎金將直接匯入原扣款帳戶，不需自行列印發票進行兌領獎，真的好方便！</small>
                  <ul className="green-dot-list">
                    <li>法源依據：依財政部105年2月16日公布實施之「公用事業申請辦理代用戶指定帳戶匯入雲端發票中獎獎金作業規定」。</li>
                    <li>適用對象：由金融單位或郵政機構存款帳戶自動扣款繳交電信資費者。（不含信用卡帳戶）</li>
                    <li>適用獎項：統一發票四獎4,000元、五獎1,000元、六獎200元及雲端發票專屬仟元獎2,000元、雲端發票專屬百元獎500元 。</li>
                    <li>作業方式：
                    <ul className="is-decimal">
                        <li>金融/郵政機構回傳電信費扣繳成功資訊至本公司3日內，本公司將完整之客戶扣款帳號併同電子發票資料上傳財政部「電子發票整合服務平台」(簡稱大平台)。</li>
                        <li>大平台於開獎月28日提供發票中獎清冊，本公司下載後將寄送中獎通知。</li>
                        <li>財政部印刷廠依大平台於開獎月28日提供之發票中獎清冊，於「開獎日次月6日」，將獎金匯款資訊傳送至相關金融/郵政機構匯入原電信費扣款帳戶。 （例如107年9～10月發票，11/25開獎，中獎獎金於12/6匯入）</li>
                        <li>倘因存款帳號除戶等原因造成獎金匯入失敗，本公司及金融(郵政)機構將另行通知用戶協助查明，財政部印刷廠於第一次匯款日次月20日或領獎期限前一日將中獎獎金補匯入用戶指定帳戶，例如107年9～10月發票，將於1月20日或3月4日再次匯款。</li>
                      </ul>
                    </li>
                  </ul>
                  <p className="is-text-bold">
                    注意事項
                </p>
                  <ol className="is-decimal">
                    <li>電信費轉帳代繳用戶發票小額獎金直接匯入原扣款帳戶，如『不同意』，請至本公司官網申請，自生效日起，扣款繳費完成開立之發票中獎時，請自行至四大超商利用多媒體機(KIOSK)，輸入繳費憑證上之「載具號碼」，列印中獎電子發票證明聯向至指定兌換獎金之商店或金融機構兌獎。</li>
                    <li>適用電信費轉帳代繳用戶發票小額獎金匯入原扣款帳戶者，無法以四大超商多媒體機(KIOSK)列印中獎電子發票證明聯，且依規定本公司亦不能提供中獎紙本電子發票證明聯，以避免重複兌獎。</li>
                    <li>本項服務僅限統一發票四獎4,000元、五獎1,000元、六獎200元及雲端發票專屬仟元獎2,000元、雲端發票專屬百元獎500元獎項，其他發票獎項中獎時 ，請自行至四大超商多媒體機(KIOSK)列印中獎電子發票證明聯向至指定兌換獎金之商店或金融機構兌獎。請妥善保存「電信費繳款通知」，以免影響您的權益！</li>
                    <li>已索取過紙本發票或已設定發票捐贈、手機條碼、自然人憑證者，不適用本項服務。</li>
                  </ol>
                </div>
                <div className="page-action align-center mt-0">
                  <Button className='mb-0 mt-3' btnStyle='primary' onClick={(e) => this.setState({ modalOpen: false })}>
                    我瞭解了
						      </Button>
                </div>
              </div>}
          </div>

        </Modal>
      </main>
    );
  }
}

export default ReceiptSetting;