import { TYPES } from './../action';
import { helpCenterInitialState } from './../state';

export default function helpCenterReducer(state = helpCenterInitialState, action) {
  switch (action.type) {
    case TYPES.SEARCH_HELP_CENTER_KEYWORD:
      return Object.assign({}, state, {
        helpCenterSearchKeyword: action.keyword || state.helpCenterSearchKeyword,
        storeType: action.storeType
      });
    case TYPES.SEARCH_HELP_CENTER_HOTKEY:
      return Object.assign({}, state, {
        helpCenterSearchHot: action.hot,
      });
    case TYPES.SEARCH_HELP_CENTER_CATEGORY:
      return Object.assign({}, state, {
        helpCenterSearchCategory: action.category,
      });
    case TYPES.SEARCH_HELP_CENTER_CATEGORY_MENU:
      return Object.assign({}, state, {
        helpCenterFaqCategoryMenu: action.menu,
      });
    case TYPES.SET_HELP_CENTER_SEARCHPATH:
      return Object.assign({}, state, {
        searchPath: action.url,
      });
    default:
      return state;
  }
}
