import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';
import LabelInput from '../../components/form/LabelInput';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import Select from '../../components/form/Select';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import numberFormatter from '../../utils/numberFormatter';

class prepaidRoamingByBankPage extends React.Component {
  constructor(props) {
    super(props);
    this.bankInput = React.createRef()
    this.state = {
      canSubmit: false,
      form: {
        id_number: { value: '', required: true },
        receipt: { value: '1', required: true },
        receipt_extra_2: { value: '', required: true },
        receipt_extra_3: { value: '', required: true },
        bank_code: { value: '', required: true },
        bank_account: { value: '', required: true },
        agree: { value: false, required: true },
      }
    }
  }

  componentDidMount() {
    this.setInputFilter(this.bankInput.current.getElementsByTagName('input')[0], function (value) {
      return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
  }

  setInputFilter = (textbox, inputFilter) => {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    this.setState({
      form: newForm
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  submit = () => {
    // this.props.history.push('/prepaidRoamingBalanceController/prepaidRoamingByBankPage');
  };

  render() {
    return (
      <main>


        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <FormBanner
          title="預付卡漫遊上網加購"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section>
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
            <div className="fui-container">
              <h4 className="is-text-darkgray70 my-0">請確認購買內容，並填寫付款資訊</h4>
              <div className='is-text-accent text-sm'>
                <span className='required-dot'></span>必填
              </div>
              <div className='mb-5'>
                <h4 className="is-text-darkgray50 mt-5 mb-2">
                  購買內容
                </h4>
                <div className="fui-table-response">
                  <table>
                    <thead>
                      <tr>
                        <th>購買門號</th>
                        <th>方案名稱</th>
                        <th>金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0926-899-868</td>
                        <td>超4代易付180MB_60天_$180(1.2GB組合)</td>
                        <td>$180.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr className='my-5' />
                <h4 className="is-text-darkgray50 mb-2">
                  付款資料
                </h4>

                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div className="form-group">
                      <label className="is-required">銀行代碼</label>
                      <Select
                        required={true}
                        name="bank_code"
                        label="請選擇"
                        options={[
                          { text: 'text', value: '1' }
                        ]}
                        value={this.state.form.bank_code.value}
                        onChange={this.onChange} />
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="with-note">
                  <Grid item xs={12} md={6} ref={this.bankInput}>
                    <LabelInput
                      required={true}
                      label="銀行帳號(限本人)"
                      name="bank_account"
                      value={this.state.form.bank_account.value}
                      onChange={this.onChange}
                    />
                    <smalll className="form-note is-text-gray100">本付款機制採用SSL 256 bites安全加密機制</smalll>
                  </Grid>
                </Grid>

                <hr className="mb-5" />
                <h4 className="is-text-darkgray50 mb-2">
                  發票明細
                </h4>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="form-group">
                      <label className="is-required">發票捐贈</label>
                      <div className="radio-buttons">
                        <div className="radio-group receipt-radio">
                          <div>
                            <label className="radio-button">
                              <p>不捐贈</p>
                              <input
                                type='radio'
                                name="receipt"
                                value='1'
                                checked={this.state.form.receipt.value === '1'}
                                onChange={e => this.onChange('receipt', e.currentTarget.value)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div>
                            <label className="radio-button">
                              <p>
                                捐贈，選擇單位
                              </p>
                              <input
                                type='radio'
                                name="receipt"
                                value='2'
                                checked={this.state.form.receipt.value === '2'}
                                onChange={e => this.onChange('receipt', e.currentTarget.value)} />
                              <span className="checkmark"></span>
                            </label>
                            <Select
                              name="receipt_extra_2"
                              label="請選擇"
                              disabled={this.state.form.receipt.value !== '2'}
                              value={this.state.form.receipt_extra_2.value}
                              onChange={this.onChange}
                              options={[
                                { text: '111', value: '111' }
                              ]}
                            />
                          </div>
                          <div>
                            <label className="radio-button">
                              <p>
                                捐贈，填寫愛心碼
                              </p>
                              <input
                                type='radio'
                                name="receipt"
                                value='3'
                                checked={this.state.form.receipt.value === '3'}
                                onChange={e => this.onChange('receipt', e.currentTarget.value)}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <div>
                              <input
                                type="text"
                                name="receipt_extra_3"
                                disabled={this.state.form.receipt.value !== '3'}
                                placeholder="請填寫"
                                value={this.state.form.receipt_extra_3.value}
                                onChange={e => this.onChange('receipt_extra_3', e.currentTarget.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <small className="is-text-darkgray50">※捐贈發票後，不提供索取紙本發票及更改捐贈對象</small>
                    </div>
                  </Grid>
                </Grid>

                <CheckboxCollapse
                  name="agree"
                  label="本人已詳閱及了解此注意事項並同意遵守"
                  validationError='請詳閱及了解此注意事項'
                  validations={{
                    isChecked: form => form.agree
                  }}
                  checked={this.state.form.agree.value}
                  value={this.state.form.agree.value}
                  getCheckbox={e => this.onChange('agree', e)}
                  collapseContent={`
                    <p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。</p>
                    <p>使用「全國繳費平台」繳納遠傳電信服務費用注意事項：</p>
                    <p>
                    1.限以門號申請本人活存帳戶使用此服務。<br/>
                    2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                    3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                    4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                    5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。
                    </p>
                  `}
                />
              </div>

              <div className="page-action">
                <Button btnStyle="primary" size="large" disabled={!this.state.canSubmit} onClick={this.submit}>確認付款</Button>
              </div>
            </div>
          </Formsy>
        </section>
      </main>
    )
  }
}

export default prepaidRoamingByBankPage;