import React from 'react';
import { Grid } from '@material-ui/core';
import KeywordInput from '../../KeywordInput';
import HotWord from '../../HotWord';
import PropTypes from 'prop-types';

class SearchBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined' ) {
      window.addEventListener('resize', e => {
        this.setState({
          isMobile: window.innerWidth < 768
        })
      })
    }
  }

  render () {
    return (
      <section className='fui-banner is-search' style={{
        backgroundImage: `url(${this.state.isMobile ? this.props.bgImg.sm : this.props.bgImg.md})`
      }}>
        <div className="fui-container">
          <Grid container justify='center'>
            <Grid item xs={12} sm={12} md={8}>
              <KeywordInput
                keyword={this.state.keyword}
                {...this.props.keyword}
              />

              <HotWord {...this.props.hot} />
            </Grid>
          </Grid>
        </div>
      </section>
    )
  }
}

SearchBanner.propTypes = {
  bgImg: {
    md: PropTypes.string,
    sm: PropTypes.string
  },
  keyword: {
    defaultKeyword: PropTypes.arrayOf(
      PropTypes.string
    ),
    path: PropTypes.string,
    placeholder: PropTypes.string
  },
  hot: {
    hotword: PropTypes.arrayOf(
      PropTypes.string
    ),
    path: PropTypes.string
  }
}

export default SearchBanner;