import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import Modal from 'react-modal';

import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import RadioGroup from '../../components/form/RadioGroup';
import Checkbox from '../../components/form/Checkbox';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import Select from '../../components/form/Select';
import numberFormatter from '../../utils/numberFormatter';


class billBankPayPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      step: 1,
      canSubmit: false,
      modalOpen: false,
      form: {
        bank_code: { value: '', required: true },
        bank_account: { value: '', required: true },
        auto_pay: { value: false, required: false },
        agree: { value: false, required: true },
      }
    }
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  onChange = (n, v) => {
    let form = Object.assign(this.state.form);
    form[n].value = v;

    console.log(n, v)

    this.setState({
      form: form
    })
  }

  nextStep = () => {
    this.setState({
      step: 2
    })
  }

  beforeSubmit = () => {
    if (this.state.form.auto_pay.value === true) {
      this.setState({
        modalOpen: true
      })
    } else {
      this.submit()
    }
  }

  submit = (autopay) => {
    let form = this.state.form
    if (!autopay) {
      form.auto_pay.value = false
    }

    this.setState({
      modalOpen: false
    })
  }

  render() {
    const { form, step } = this.state;

    return (
      <main>

        <NavAnchor
          pageTitle='銀行帳號繳費'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="銀行帳號繳費"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        {
          step === 1 ? (
            <div>
              <section className='pt-2 pt-md-5'>
                <div className="fui-container">
                  <div className="paper">
                    <h4>帳單明細</h4>
                    <table className="text-wrap">
                      <tbody>
                        <tr>
                          <th>手機門號</th>
                          <td className="align-left">0936-000-123</td>
                        </tr>
                        <tr>
                          <th>結帳日期</th>
                          <td className="align-left">2014 / 06 / 23	</td>
                        </tr>
                        <tr>
                          <th>繳費日期</th>
                          <td className="align-left">2014 / 06 / 23	</td>
                        </tr>
                        <tr>
                          <th>本期新增金額</th>
                          <td className="align-left">1,401元</td>
                        </tr>
                        <tr>
                          <th>累積應繳金額</th>
                          <td className="align-left">1,401元</td>
                        </tr>
                        <tr>
                          <th>最後一次繳費記錄</th>
                          <td className="align-left">於 2014 / 06 / 27 繳費，金額為 $ 1,541。</td>
                        </tr>
                      </tbody>
                    </table>
                    <h4 className="mt-5">應繳費項</h4>
                    <table className="payment-table">
                      <tbody>
                        <tr>
                          <th className='align-left' valign='middle'>
                            <Grid container>
                              <Grid item xs={6}>
                                <span>電信費用</span>
                              </Grid>
                              <Grid item xs={6}>
                                <span className="float-right">1,401元</span>
                              </Grid>
                            </Grid>
                          </th>
                        </tr>
                        <tr>
                          <th className='align-left' valign='middle'>
                            <Grid container>
                              <Grid item xs={6}>
                                <span>帳單總額</span>
                              </Grid>
                              <Grid item xs={6}>
                                <h2 className="is-text-error my-0 float-right">1,401元</h2>
                              </Grid>
                            </Grid>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="page-action">
                    <Button btnStyle='primary' size='large' onClick={this.nextStep}>填寫付款資料</Button>
                    <Button btnStyle='secondary' size='large' link="/payController/payPage">取消</Button>
                  </div>
                </div>
              </section>
              <section className='pt-2 pt-md-5'>
                <div className="fui-container">
                  <div className="paper">
                    <h4>應繳費項</h4>
                    <p className='is-text-darkgray50'>親愛的用戶，你的門號目前無帳款需繳納，謝謝。</p>
                  </div>
                  <div className="page-action">
                    <Button btnStyle='primary' size='large'>查未結帳金額／上網用量</Button>
                    <Button btnStyle='secondary' size='large' link="/payController/payPage">立即續約</Button>
                  </div>
                </div>
              </section>
            </div>
          ) : null
        }

        {
          step === 2 ? (
            <section>
              <div className="fui-container">
                <h4 className="is-text-darkgray70 my-0">請確認應繳費項，並填寫付款資訊。</h4>
                <div className='is-text-accent text-sm mb-2'>
                  <span className='required-dot'></span>必填
                </div>
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                  <div className='form-group mt-5'>
                    <label className="mt-2">應繳金額</label>
                    <div>
                      <h2 className="is-text-error">{numberFormatter(1401)} 元</h2>
                    </div>
                  </div>
                  <div className='mb-5 pb-5'>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={5}>
                        <div className='form-group'>
                          <label className="is-required">銀行代碼</label>
                          <Select
                            required={form.bank_code.required}
                            options={[
                              { text: 'Bank code 1111', value: '1111' },
                              { text: 'Bank code 2222', value: '2222' }
                            ]}
                            label="請選擇"
                            name="bank_code"
                            value={form.bank_code.value}
                            onChange={this.onChange}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container className="input-with-checkbox with-note" spacing={2}>
                      <Grid item xs={12} sm={6} md={5}>
                        <LabelInput
                          label="銀行帳號(限本人)"
                          name="bank_account"
                          required={form.bank_account.required}
                          value={form.bank_account.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <Checkbox
                          name="auto_pay"
                          label="設定此銀行帳號每月扣款"
                          value='1'
                          checked={form.auto_pay.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                    <small className="is-text-gray100">本付款機制採用SSL 256 bites安全加密機制</small>
                  </div>
                  <CheckboxCollapse
                    name="agree"
                    label="本人已詳閱及了解此注意事項並同意遵守"
                    validationError='請詳閱及了解此注意事項'
                    validations={{
                      isChecked: form => form.agree
                    }}
                    checked={form.agree.value}
                    value={form.agree.value}
                    getCheckbox={e => this.onChange('agree', e)}
                    collapseContent={`
                    <p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。</p>
                    <p>使用「全國繳費平台」繳納遠傳電信服務費用注意事項：</p>
                    <p>
                    1.限以門號申請本人活存帳戶使用此服務。<br/>
                    2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                    3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                    4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                    5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。
                    </p>
                    `}
                  />

                  <div className="page-action">
                    <Button btnStyle='primary' size='large' onClick={this.beforeSubmit} disabled={!this.state.canSubmit}>結帳</Button>
                    <Button btnStyle='secondary' size='large' onClick={e => this.setState({ step: 1 })}>上一步</Button>
                  </div>
                </Formsy>
              </div>
            </section>
          ) : null
        }

        {
          step === 1 ? (
            <SectionCollapseInfo
              title="貼心小叮嚀"
              content={`
                <ol>
                  <li>此功能不需帳號及密碼，只要輸入身分資料完成登入檢核就可進行繳費。</li>
                  <li>快速登入後僅能進行繳費功能，若需使用其他功能，請先進行登入。</li>
                  <li>繳納費用非一般性消費支出，請洽各發卡銀行確認是否有刷卡紅利或現金回饋。</li>
                </ol>
              `}
            />
          ) : null
        }

        <Modal
          onAfterOpen={e => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={e => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel="Example Modal"
          className="fui-prompt-modal">
          <div className="fui-prompt-modal-body">
            <h5>設定自動扣繳</h5>
            <p>你同意將此次付款銀行帳號設為日後自動扣繳帳號？</p>

            <div className="fui-buttons two-button">
              <Button btnStyle='primary' onClick={e => this.submit(true)}>同意</Button>
              <Button btnStyle='secondary' onClick={e => this.submit(false)}>下次再設定</Button>
            </div>
          </div>
        </Modal>
      </main>
    )
  }
}

Modal.setAppElement('#root');

export default billBankPayPage;