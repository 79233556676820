import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import PropTypes from 'prop-types';
import LoadMore from '../../components/LoadMore';
class InstructionItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEn: false,
            currentArticleLoadMore: true,
        };
    }
    render() {
        return (
            <div className="mt-md-7 mt-4">
                <h4 className="mb-md-3 mb-2">
                    {this.props.title}
                </h4>
                <Grid container spacing={2}>
                    {this.props.list ? this.props.list.map((item, i) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} key={item.name + i}>
                                <Link
                                    to={item.link}
                                    className="w-100"
                                    target='_self'>
                                    <div className="paper d-flex flex-align-center justify-between mb-0">
                                        {item.name}
                                        <img src="/resources/cbu/img/download.svg" alt="img" />
                                    </div>
                                </Link>
                            </Grid>
                        )
                    }) : null}
                    <div className="fui-container">
                        <LoadMore
                            click={this.props.loadMore}
                            className="mb-0 mt-md-7 mt-2"
                            load={this.props.settingLoad}
                            moreLabel={this.state.isEn ? 'More' : '展開看更多'}
                            noMoreLabel={this.state.isEn ? 'No More Article' : '沒有更多'}
                        />
                    </div>
                </Grid>
            </div>
        );
    }
}
InstructionItems.propTypes = {
    hotTag: PropTypes.string,
    tag: PropTypes.array,
    name: PropTypes.string,
    list: PropTypes.array,
    brand: PropTypes.string,
    image: PropTypes.array,
}
export default InstructionItems;