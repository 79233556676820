import React from 'react';

import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import Link from '../../components/Link';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

import * as Mock from '../../mock/EServiceIndex';

class prepaidDepositFail extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      canSubmit: false
    }
  }

  enableButton = (e) => {
    this.setState({ canSubmit: true });
  }

  disableButton = (e) => {
    this.setState({ canSubmit: false });
  }

  render() {
    return (
      <main>
        

        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <section className="e-service-result is-success">
          <div className="fui-container">
            <Grid>
              <Grid xs={12}>
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/failed.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/failed@2x.png'} alt='' />
                <div className="content">
                  <h4 className="mb-0 mt-3">儲值失敗</h4>
                  <p className='body1'>糟糕，似乎發生問題了</p>
                  <div className='action'>
                    <Button btnStyle='primary' size='large'>返回帳單代收</Button>
                    <Button link='/' btnStyle='secondary' size='large'>前往個人專區</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <SectionCarousel1 {...Mock.promotionCard} />
      </main>
    );
  }
}

export default prepaidDepositFail;
