import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import Button from '../../components/Button';
import Select from '../../components/form/Select';
import CheckGroup from '../../components/form/CheckGroup';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import moment from 'moment'
let bookedRecord = {
    head: ['動作'],
    content: [
        ['場次', '日期', '時間', '主題', '地點'],
        ['1台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
        ['2台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
        ['3台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
        ['4台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
        ['5台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
        ['6台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
        ['7台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
        ['8台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
        ['9台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
        ['10台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
        ['11台中', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號'],
    ]
}
let record = {
    head: ['動作'],
    content: [
        ['場次', '日期', '時間', '主題', '地點', '狀態'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號', '尚有名額'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號', '尚有名額'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號台北市內湖區瑞光路468號', '尚有名額'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號', '尚有名額'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號', '尚有名額'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號', '尚有名額'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號', '尚有名額'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號', '尚有名額'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號', '尚有名額'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號', '尚有名額'],
        ['3', '2020/06/15', '00:10~11:00', '全民小學堂', '台北市內湖區瑞光路468號', '尚有名額'],
    ]
}
let bookedEvent = [
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
    {
        bookEvent: 'book event 1',
        name: '王大明'
    },
]
let recordEvent = [
    {
        bookEvent: 'book event 1',
        locationLink: 'go to store 1'
    },
    {
        bookEvent: 'book event 2',
        locationLink: 'go to store 2'
    },
    {
        bookEvent: 'book event 3',
        locationLink: 'go to store 3'
    },
    {
        bookEvent: 'book event 4',
        locationLink: 'go to store 4'
    },
    {
        bookEvent: 'book event 5',
        locationLink: 'go to store 5'
    },
    {
        bookEvent: 'book event 6',
        locationLink: 'go to store 6'
    },
    {
        bookEvent: 'book event 7',
        locationLink: 'go to store 7'
    },
    {
        bookEvent: 'book event 8',
        locationLink: 'go to store 8'
    },
    {
        bookEvent: 'book event 9',
        locationLink: 'go to store 9'
    },
    {
        bookEvent: 'book event 10',
        locationLink: 'go to store 10'
    },
    {
        bookEvent: 'book event 11',
        locationLink: 'go to store 11'
    },
]
class ServiceBooking extends Component {
    constructor(props) {
        super(props);
        this.form01 = React.createRef()
        this.form02 = React.createRef()
        this.form03 = React.createRef()
        this.state = {
            cancelEvent: {},
            modalOpen: false,
            openForm: false,
            titleHeight: [],
            bookedTitleHeight: [],
            getTitleLength: [],
            record: [],
            course: record,
            form: {
                number: {
                    value: '',
                    label: ''
                },
                bookingService: {
                    value: '', required: false
                },
                recordEvent: recordEvent,
                receipt_send_city: { value: '', require: false },
                receipt_send_area: { value: '', require: false },
                store: { value: '', require: false },
                date: { value: '', require: false },
                time: { value: '', require: false },
                name: { value: '', require: false },
                contactNumber: { value: '', require: false },
                serviceNumber: { value: '', require: false },
                userName: { value: '', require: true },

            },
            isMobile: false,
            canSubmit01: false,
            canSubmit02: false,
            canSubmit03: false,
        }
    }
    enableButton = (val) => {
        switch (val) {
            case 'form01':
                this.setState({
                    canSubmit01: true
                })
                break;
            case 'form02':
                this.setState({
                    canSubmit02: true
                })
                break;
            case 'form03':
                this.setState({
                    canSubmit03: true
                })
                break;
            default:
                break;
        }

    };
    disableButton = (val) => {
        switch (val) {
            case 'form01':
                this.setState({
                    canSubmit01: false
                })
                break;
            case 'form02':
                this.setState({
                    canSubmit02: false
                })
                break;
            case 'form03':
                this.setState({
                    canSubmit03: false
                })
                break;
            default:
                break;
        }
    };
    cleanTable = () => {
        this.setState({
            record: {},
            course: {}
        });
    };
    onChangePage(pageOfItems, selectedPage) {
        // this.props.history.push(`${this.props.location.pathname}?keyword=${this.state.keyword}&page=${selectedPage}`);

        // update page of items
        // this.updatePageOfItems(pageOfItems);
        // console.log('on change');
    }
    onChange = (name, value) => {
        // console.log({ name, value });
        let newForm = Object.assign(this.state.form);
        newForm[name].value = value;
        this.setState({
            form: newForm
        })
    }
    componentDidMount() {
        // this.setState({
        //     record: bookedRecord
        // })

        window.addEventListener('resize', this.updateDimensions);
        for (let i = 0; i < record.content.length - 1; i++) {
            record.head.push('點此報名')
        }
        for (let i = 0; i < bookedRecord.content.length - 1; i++) {
            bookedRecord.head.push('點此取消報名')
        }
        this.getTitleLength();
        this.getBookedTitleLength();
    }
    updateDimensions = () => {
        this.setState({
            isMobile: window.innerWidth < 960,
        });
    };
    getTitleLength = () => {
        let length = 0
        let content = this.state.course.content
        let head = this.state.course.head
        let store = []
        if (!!content > 0) {
            content.map((row, i) => {
                let result = 0
                row.map((target, j) => {
                    result = target.replace(/[^\x00-\xff]/g, "xx").length;
                    length = length < result ? result : length
                })
                // console.log(`result: `, result);
                store.push({
                    row: i,
                    result: length,
                    height: length < 25 ? 66 : Math.floor((length / 16 * 32) + 36)
                })

                length = 0
                result = 0
            })
            head.map((header, i) => {
                let result = header.replace(/[^\x00-\xff]/g, "xx").length;
                // console.log({ index: i, result: store[i].result, temp: result });
                if (store[i].result < result) {
                    store[i].height = result < 19 ? 66 : (Math.floor((result / 24 * 32) + 36))
                }
            })
            // console.log(`store: `, store);
            this.setState({
                titleHeight: store
            })
        }
    }
    getBookedTitleLength = () => {
        let length = 0
        let content = this.state.record.content
        let head = this.state.record.head
        let store = []
        if (!!content > 0) {
            content.map((row, i) => {
                let result = 0
                row.map((target, j) => {
                    result = target.replace(/[^\x00-\xff]/g, "xx").length;
                    length = length < result ? result : length
                })
                // console.log(`result: `, result);
                store.push({
                    row: i,
                    result: length,
                    height: length < 25 ? 66 : Math.floor((length / 16 * 32) + 36)
                })

                length = 0
                result = 0
            })
            head.map((header, i) => {
                let result = header.replace(/[^\x00-\xff]/g, "xx").length;
                // console.log({ index: i, result: store[i].result, temp: result });
                if (store[i].result < result) {
                    store[i].height = result < 19 ? 66 : (Math.floor((result / 24 * 32) + 36))
                }
            })
            // console.log(`store: `, store);
            this.setState({
                bookedTitleHeight: store,
            })
        }
    }
    getHeight = (i, target) => {
        let data = target === 'course' ? this.state.titleHeight : this.state.bookedTitleHeight

        if (data.length > 0 && !!data[i]) {
            return data[i].height
        } else {
            return 0
        }
    }
    form03Validation = () => {
        return this.state.canSubmit03 && !!this.state.form.receipt_send_area.value && !!this.state.form.receipt_send_city.value && !!this.state.form.store.value && !!this.state.form.date.value && !!this.state.form.time.value
    }
    bookEvent = (i) => {
        let event = recordEvent
        // console.log(event[i - 1].bookEvent);
        this.props.history.push('/course/booking')
    }
    cancelEvent = (i) => {
        let event = bookedEvent
        // console.log(event[i - 1].bookEvent);
        // this.props.history.push('/course/booking')
        this.setState({
            modalOpen: true,
            cancelEvent: {
                index: i,
                name: event[i - 1].name
            }
        })
    }
    gotoEvent = (i) => {
        let event = recordEvent
        // console.log(event[i - 1].locationLink);
    }
    bookBtn = (item, i) => {
        return (
            <a key={i} className='underline-link is-text-black50' onClick={() => this.bookEvent(i)}>{item}</a>
        )
    }
    cancelBtn = (item, i) => {
        return (
            <a key={i} className='underline-link is-text-black50' onClick={() => this.cancelEvent(i)}>{item}</a>
        )
    }
    getLocation = (item, index) => {
        return (
            <a
                onClick={() => this.gotoEvent(index)}
                className="is-text-darkgray70 d-flex flex-align-center justify-center"
            >
                {item}
                <img style={{ width: 30 }} src={process.env.PUBLIC_URL + "/resources/cbu/img/pin.svg"} alt="pin" />
            </a>
        )
    }
    confirmCancel = () => {
        let i = this.state.cancelEvent.index
        bookedRecord.content.splice(i, 1)
        bookedRecord.head.splice(i, 1)
        // console.log(bookedRecord.content);
        this.setState({
            cancelEvent: {},
            record: bookedRecord,
            modalOpen: false
        })
    }
    getBookedRecord = () => {
        // simulating API async
        setTimeout(() => {
            this.setState({ record: bookedRecord })
            this.getBookedTitleLength();
        }, 500);
    }
    render() {
        return (
            <main>
                <NavAnchor
                    pageTitle='交易/異動記錄'
                    button={{
                        text: '回個人專區',
                        link: '#'
                    }} />
                <FormBanner
                    title="免費課程報名記錄"
                    image={{
                        md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
                        sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
                    }}
                />

                <section className="usage-rate mt-md-7 mt-4 no-bg">
                    <div className="fui-container">
                        <div className="fui-segments no-shadow is-bg-initial">
                            <h4 className="is-text-darkgray50 mb-md-7 mb-5">
                                想知道遠傳有什麼課程可以學習嗎？此服務提供您免費報名最新課程，快來看看呦！
                            </h4>
                            <Formsy onValid={() => this.enableButton('form01')} onInvalid={() => this.disableButton('form01')} ref={this.form01}>
                                <div className="paper mt-md-4 mt-2 mb-0 pb-md-3 pb-2 pt-md-4 pt-2 merged">
                                    <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-3 mb-0">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={5}>
                                                        <h4>請輸入欲查詢的門號</h4>
                                                        <LabelInput
                                                            className="hide-required mb-0"
                                                            name='number'
                                                            required={true}
                                                            label='門號'
                                                            validationErrors={{
                                                                isDefaultRequiredValue: '請輸入門號'
                                                            }}
                                                            placeholder='請輸入門號'
                                                            value={this.state.form.number.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <div className="page-action">
                                                            <Button
                                                                onClick={() => { this.getBookedRecord() }}
                                                                link="#"
                                                                className={`${this.state.canSubmit01 ? '' : 'disabled'}`}
                                                                btnStyle='primary'
                                                                size='large'>
                                                                查詢報名記錄
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Formsy>
                            <div className="paper mb-0 pb-md-7 pb-4 pt-md-4 pt-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <h4 className="mt-md-4">以下為您目前的門市預約記錄</h4>
                                        {Object.keys(this.state.record).length === 0 ? <div>
                                            <div className="body is-text-darkgray50">查無已報名的課程記錄</div>
                                        </div> :
                                            <div>
                                                <div className="compareTable content-16 clearfix text-wrap bill-table mt-0 mb-md-2">
                                                    <div className="tableLeft">
                                                        <div className="tableHead">
                                                            <table className='text-wrap'>
                                                                <tbody>
                                                                    {this.state.record.head.map((item, i) => {
                                                                        return (
                                                                            <tr
                                                                                className={`${i === 0 ? 'is-bg-lightgray70' : ''}`}
                                                                                style={{ height: this.getHeight(i, 'booked') - (this.state.isMobile ? 6 : 0) }}
                                                                                key={item + i}
                                                                            >
                                                                                <td
                                                                                    className={`align-center ${i === 1 ? 'is-text-medium' : ''}`}
                                                                                >
                                                                                    {i > 0 ? this.cancelBtn(item, i) : item}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="tableRight">
                                                        <div className="tableBody">
                                                            <div className="spaceBox">
                                                                <table className="d-table text-wrap">
                                                                    <tbody>
                                                                        {this.state.record.content.map((list, i) => (
                                                                            <tr
                                                                                style={{ height: this.getHeight(i, 'booked') - (this.state.isMobile ? 6 : 0) }}
                                                                                key={list + i}
                                                                                className={i == 0 ? 'is-bg-lightgray70' : ''}
                                                                            >
                                                                                {list.map((item, j) => (
                                                                                    <td
                                                                                        style={{ minWidth: j === 0 ? 60 : null, }}
                                                                                        key={item + j}
                                                                                    >
                                                                                        {item}
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <small className="d-block mt-1 is-text-darkgray50">＊如欲取消預約，請點選「取消預約」按鈕，輸入預約時所填寫之姓名</small>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </div>
                            <Formsy onValid={() => this.enableButton('form01')} onInvalid={() => this.disableButton('form01')} ref={this.form01}>
                                <div className="paper mt-2 mb-0 pb-md-2 pb-4 pt-md-4 pt-2">
                                    <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-2 mb-0">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                <h4>所有課程</h4>
                                                {Object.keys(this.state.course).length !== 0 ? <div>
                                                    <div className="compareTable content-16 clearfix text-wrap bill-table mt-0 mb-md-2 w-142">
                                                        <div className="tableLeft">
                                                            <div className="tableHead">
                                                                <table className='text-wrap'>
                                                                    <tbody>
                                                                        {this.state.course.head.map((item, i) => {
                                                                            return (
                                                                                <tr
                                                                                    className={`${i === 0 ? 'is-bg-lightgray70' : ''}`}
                                                                                    style={{ height: this.getHeight(i, 'course') - (this.state.isMobile ? 6 : 0) }}
                                                                                    key={item + i}>
                                                                                    <td
                                                                                        className={`align-center ${i === 1 ? 'is-text-medium' : ''}`}
                                                                                    >
                                                                                        {i > 0 ? this.bookBtn(item, i) : item}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="tableRight">
                                                            <div className="tableBody">
                                                                <div className="spaceBox">
                                                                    <table className="d-table text-wrap">
                                                                        <tbody>
                                                                            {this.state.course.content.map((list, i) => (
                                                                                <tr
                                                                                    style={{ height: this.getHeight(i, 'course') - (this.state.isMobile ? 6 : 0) }}
                                                                                    key={list + i}
                                                                                    className={i == 0 ? 'is-bg-lightgray70' : ''}
                                                                                >
                                                                                    {list.map((item, j) => (
                                                                                        <td
                                                                                            style={{ minWidth: j === 0 ? 60 : null, }}
                                                                                            key={item + j}
                                                                                        >
                                                                                            {j === 4 && i > 0 ? this.getLocation(item, i) : item}
                                                                                        </td>
                                                                                    ))}
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.course.content.length > 10 ? <Pagination
                                                        items={['1', '2', '3', '4']}
                                                        onChangePage={this.onChangePage}
                                                        initialPage={1}
                                                        pageSize={2} /> : null}
                                                </div>
                                                    : <div className="body is-text-darkgray50">課程規劃中，敬請期待！</div>}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Formsy>
                        </div>
                    </div>
                </section>
                <Modal
                    onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
                    onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
                    isOpen={this.state.modalOpen}
                    contentLabel='Example Modal'
                    className='fui-prompt-modal'>
                    <div className='fui-prompt-modal-body'>
                        <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                            <div className="align-left">
                                <h5 className='mb-4'>取消報名</h5>
                                <LabelInput
                                    className="mb-2"
                                    name='userName'
                                    required={this.state.form.userName.required}
                                    label='姓名'
                                    validationErrors={{
                                        isDefaultRequiredValue: '請輸入姓名'
                                    }}
                                    placeholder='請輸入姓名'
                                    value={this.state.form.userName.value}
                                    onChange={this.onChange}
                                />
                                <small className='is-text-gray100'>＊如欲取消報名，請輸入報名時所填寫之姓名</small>
                            </div>
                        </Formsy>
                        <div className="fui-buttons two-button mt-6">
                            <Button btnStyle='primary' className={`mb-0 ${this.state.form.userName.value === this.state.cancelEvent.name ? '' : 'disabled'}`} onClick={this.confirmCancel}>確認送出</Button>
                            <Button btnStyle='secondary' className='mb-0' onClick={() => { this.setState({ modalOpen: false }) }}>取消</Button>
                        </div>
                    </div>
                </Modal>
                {/* remove in production */}
                <div className="switcher py-5">
                    <div className="fui-container">
                        <div className="d-flex flex-column">
                            <p>測試用資料切換</p>
                            <Button onClick={() => this.cleanTable()} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>無資料</Button>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default ServiceBooking;