import React, { Component } from 'react'
import NavAnchor from '../../components/partials/NavAnchor';
import Link from '../../components/Link';
import { Grid } from '@material-ui/core';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';

class CourseBooking extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef()
    this.state = {
      canSubmit: false,
      form: {
        name: {
          value: ''
        },
        number: {
          value: ''
        },
        agree: {
          value: false
        }
      }
    }
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }
  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }
  onChange = (name, value) => {
    // console.log({ name, value });
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }
  submit = () => {
    console.log("submit");
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='交易/異動記錄'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="免費課程報名記錄"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
          <section className="usage-rate mt-md-7 mt-4 no-bg">
            <div className="fui-container">
              <div className="fui-segments no-shadow is-bg-initial">
                <h4 className="is-text-darkgray50 mb-md-7 mb-5">
                  想知道遠傳有什麼課程可以學習嗎？此服務提供您免費報名最新課程，快來看看呦！
                            </h4>
                <div className="paper mt-md-4 mt-2 mb-2 pb-md-3 pb-2 pt-md-4 pt-2">
                  <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-3 mb-0">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <h4 className='mb-md-0 mb-0'>課程內容</h4>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="rate-plan-box">
                          <h5 className='mb-1'>主題</h5>
                          <h2>全民小學堂</h2>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="rate-plan-box">
                          <h5 className='mb-1'>日期</h5>
                          <h2>2020/07/03 10:00~11:00</h2>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <ul className="green-dot-list mb-md-1 mb-0">
                          <li>場次：台北</li>
                          <li>上課地點：台北市內湖區瑞光路468號</li>
                          <li>開放人數：10人</li>
                          <li>課程大綱：全民小學堂，一起學新知</li>
                        </ul>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="paper mb-md-10 mb-7 pb-md-7 pb-4 pt-md-4 pt-2">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <h4 className='mt-md-4 mb-2'>請填寫報名資料</h4>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className='mb-md-7 mb-4'>
                      <LabelInput
                        className="hide-required mb-0"
                        name='name'
                        required={true}
                        label='姓名'
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入姓名'
                        }}
                        placeholder='請輸入姓名'
                        value={this.state.form.name.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        className="hide-required mb-0"
                        name='number'
                        required={true}
                        label='聯絡電話'
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入聯絡電話'
                        }}
                        placeholder='請輸入聯絡電話'
                        value={this.state.form.number.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                </div>

                <CheckboxCollapse
                  name='agree'
                  label='本人已詳閱及知悉遠傳電信依個人資料保護法第八條告知事項。'
                  validationError='完整內容收合與展開'
                  validations={{
                    isChecked: form => this.state.form.agree
                  }}
                  checked={this.state.form.agree.value}
                  value={this.state.form.agree.value}
                  getCheckbox={(e) => this.onChange('agree', e)}
                  collapseContent={`
                <p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。</p>
                <p>使用「全國繳費平台」繳納遠傳電信服務費用注意事項：</p>
                <p>
                1.限以門號申請本人活存帳戶使用此服務。<br/>
                2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。
                </p>
                `}
                />

                <div className='page-action mt-0 mb-0'>
                  <Button btnStyle='primary' size='large' onClick={this.submit} disabled={!this.state.form.agree.value || !this.state.canSubmit}>
                    確認報名
                  </Button>
                  <Link to={`/course`} className='fui-button is-secondary is-large'>
                    <span className="text">返回所有課程列表</span>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </Formsy>
      </main >
    );
  }
}

export default CourseBooking;