import React from 'react';
import Button from '../../Button'

import PropTypes from 'prop-types'

class SectionBanner2 extends React.Component {
    // console.log(color)
    render() {
        return (
            <section className={`fui-banner is-product is-style-2 ${this.props.className ? this.props.className : ''}`}>
                <div className="banner-img">
                    <div className="d-none d-sm-block" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.props.image.md})` }}></div>
                    <div className="d-block d-sm-none" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.props.image.sm})` }}></div>
                </div>
                {/* <img src={this.props.image.md} srcSet={this.props.image.retinaMd || this.props.image.md} className='d-none d-sm-block' alt={this.props.title} />
                <img src={this.props.image.sm} srcSet={this.props.image.retinaSm || this.props.image.sm} className="d-block d-sm-none" alt={this.props.title} /> */}
                <div className="fui-container">
                    <div className="caption">
                        <h1 className="my-0" dangerouslySetInnerHTML={{ __html: this.props.title }}></h1>
                        <div className="subtitle mt-0" dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
                        {
                            this.props.action ? (
                                <Button {...this.props.action} btnStyle='primary'>{this.props.action.text}</Button>
                            ) : null
                        }
                    </div>
                </div>
            </section>
        )
    }
}

SectionBanner2.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    className: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
        md: PropTypes.string,
        retinaMd: PropTypes.string,
        sm: PropTypes.string,
        retinaSm: PropTypes.string
    }),
    action: PropTypes.shape({
        icon: PropTypes.string,
        link: PropTypes.string,
        text: PropTypes.string
    }),
    onChange: PropTypes.func
}

export default SectionBanner2;