import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

class ArticleBanner extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className='article-banner'>
        <div className='img'>
          <img src={this.props.image} alt={this.props.alt} />
        </div>
        <div className='wording'>
          <div className='fui-container'>
            <Grid container>
              <Grid item xs={12} md={7}>
                <h5
                  className='mb-2'
                  style={{
                    color: this.props.titleColor ? this.props.titleColor : '#f05c38',
                  }}>
                  {this.props.title}
                </h5>
                <h1
                  style={{
                    color: this.props.descColor ? this.props.descColor : '',
                  }}>
                  {this.props.desc}
                </h1>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    );
  }
}

ArticleBanner.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
  titleColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};
export default ArticleBanner;
