import React, { Component } from 'react';
import PropTypes from 'prop-types'
import SectionAd3 from '../../components/partials/SectionAd3';
import SectionCarousel3 from '../../components/partials/card/SectionCarousel3';
import CarouselWithTag from '../../components/partials/carousel/CarouselWithTag'
class AddValueSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSubTab: 0,
            isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
        }
        this.programSetting = {
            dots: false,
            infinite: false,
            arrows: true,
            slidesToShow: 3,
            draggable: true,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        arrows: true,
                        slidesToShow: 1,
                        variableWidth: true,
                    },
                },
            ],
        };
    }
    changeSubTab = value => {
        this.setState({
            currentSubTab: value,
        });
    };
    render() {
        return (
            <div className="ribbon-bg">
                <CarouselWithTag
                    title={this.props.title}
                    cards={this.props.cards}
                    id={this.props.id}
                />
                <section id="whyPrepaid" className="why-prepaid">
                    <div className="fui-container">
                        <h3 className="head heading-2">為什麼選擇預付卡？</h3>
                        <ul>
                            <li>
                                <img src={process.env.PUBLIC_URL + "/resources/cbu/prepaid/images/prepaid-feature-1.svg"} alt="feature" />
                                <div className="info">
                                    <h4>自由搭配更省錢</h4>
                                    <ul>
                                        <li>
                                            <i className="icon-check is-text-accent" />
                                            免綁約、免月租
                                        </li>
                                        <li>
                                            <i className="icon-check is-text-accent" />
                                            方案便宜可負擔
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <img src={process.env.PUBLIC_URL + "/resources/cbu/prepaid/images/prepaid-feature-2.svg"} alt="feature" />
                                <div className="info">
                                    <h4>申辦儲值很簡單</h4>
                                    <ul>
                                        <li>
                                            <i className="icon-check is-text-accent" />
                                            申辦流程快速
                                        </li>
                                        <li>
                                            <i className="icon-check is-text-accent" />
                                            儲值方式多元
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <img src={process.env.PUBLIC_URL + "/resources/cbu/prepaid/images/prepaid-feature-3.svg"} alt="feature" />
                                <div className="info">
                                    <h4>用多用少你決定</h4>
                                    <ul>
                                        <li>
                                            <i className="icon-check is-text-accent" />
                                            用多少買多少
                                        </li>
                                        <li>
                                            <i className="icon-check is-text-accent" />
                                            上網流量可自動展延
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <section id="wayOfApply" className="apply-steps" style={{ backgroundImage: `url(${this.state.isMobile ? this.props.applySteps.mobileBackground : this.props.applySteps.background})` }}>
                    <div className="fui-container">
                        <h2>{this.props.applySteps.title}</h2>
                        <div className="subtitle">{this.props.applySteps.subtitle}</div>
                        <ul>
                            <li>
                                <div className="circle">1</div>
                                <div className="body">年滿20歲，攜帶雙證件至遠傳/全虹門市，填寫申請書</div>
                            </li>
                            <li>
                                <div className="circle">2</div>
                                <div className="body">請將包裝內的sim卡插入手機後開機</div>
                            </li>
                            <li>
                                <div className="circle">3</div>
                                <div className="body">依據指南說明輸入PIN碼及上網設定，開通後即可使用</div>
                            </li>
                        </ul>
                    </div>
                </section>
                <SectionAd3
                    {...this.props.ad}
                />
                <SectionCarousel3 {...this.props.carousel} />
            </div >
        );
    }
}

AddValueSection.propTypes = {
    id: PropTypes.string,
    h1: PropTypes.string,
    cards: PropTypes.array,
    title: PropTypes.string,
    ad: PropTypes.object,
    carousel: PropTypes.object,
    more: PropTypes.object,
}

export default AddValueSection;