import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import * as Action from './stores/actions';
import { connect } from 'react-redux';

import withTrackingRoute from './utils/withTrackingRoute';
import Loader from './components/Loader';
import EmmaService from './components/Emma';
import Cookie from './components/Cookie';
import GoTop from './components/GoTop';
import Header from './components/partials/header/Default';
import Footer from './components/partials/footer/Default';

// loading animation
import Error from './pages/Error'
import getPhoneBillPaySettingInitPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingInitPage'
import getPhoneBillPaySettingSmsValidateErrorPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingSmsValidateErrorPage'
import getPhoneBillPaySettingOpenPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingOpenPage'
import getPhoneBillPaySettingOpenResultPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingOpenResultPage'
import getPhoneBillPaySettingCheckResetPasswordPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingCheckResetPasswordPage'
import getPhoneBillPaySettingValidateRocidPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingValidateRocidPage'
import getPhoneBillPaySettingSelectAmountPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingSelectAmountPage'
import RateChangeInitPage from './pages/rateChangeController/RateChangeInitPage'
import getQueryContractsInfoPage from './pages/queryContractsInfoController/getQueryContractsInfoPage'
import getQueryRecordPage from './pages/queryRecordController/getQueryRecordPage'
import fillPrepaidDepositDataPage from './pages/prepaidDepositController/fillPrepaidDepositDataPage'
import prepaidRoamingBuyPage from './pages/prepaidRoamingBalanceController/prepaidRoamingBuyPage'
import prepaidRoamingByBankPage from './pages/prepaidRoamingBalanceController/prepaidRoamingByBankPage'
import prepaidRoamingByCreditPage from './pages/prepaidRoamingBalanceController/prepaidRoamingByCreditPage'
import serviceCoveragePage from './pages/serviceCoverageController/serviceCoveragePage'

import prepaidExtraBuy from './pages/prepaidDepositController/prepaidExtraBuy'
import prepaidRapidDepositPage from './pages/prepaidDepositController/prepaidRapidDepositPage'
import prepaidDetailQueryPage from './pages/prepaidDepositController/prepaidDetailQueryPage'

import prepaidDepositPage from './pages/prepaidDepositController/prepaidDepositPage'
import depositCardPage from './pages/prepaidDepositController/depositCardPage'
import prepaidDepositSuccess from './pages/prepaidDepositController/prepaidDepositSuccess'
import prepaidDepositFail from './pages/prepaidDepositController/prepaidDepositFail'

import getBarcodePayInitPage from './pages/billBarcodePayController/getBarcodePayInitPage'
import fourGExtraBuyPage from './pages/four4GExtraBuyController/4GExtraBuyPage'
import fourGExtraBuySubmitPage from './pages/four4GExtraBuyController/4GExtraBuySubmitPage'
import ChangePassword from './pages/ChangePassword'
import payPage from './pages/payController/payPage'
import PayNothingPage from './pages/payController/PayNothingPage'
import payDepositPage from './pages/payController/payDepositPage'
import billBankPayPage from './pages/billBankPayController/billBankPayPage'
import fillBillBankPayDataPage from './pages/billBankPayController/fillBillBankPayDataPage'
import billCreditcardPayPage from './pages/billCreditcardPayController/billCreditcardPayPage'
import fillBillCreditcardPayDataPage from './pages/billCreditcardPayController/fillBillCreditcardPayDataPage'

import StoreFinder from './pages/StoreFInder'
import FourPointFive from './pages/ratePlan/FourPointFiveG'
import RatePlan from './pages/ratePlan/RatePlan'
import Notice from './pages/ratePlan/Notice'
import AddValue from './pages/prepaid/AddValue'
import Application from './pages/prepaid/Application'
import Foreign from './pages/prepaid/Foreign'
import ForeignEng from './pages/prepaid/ForeignEng'
import Program from './pages/prepaid/Program'
import ProgramAmount from './pages/prepaid/ProgramAmount'
import ProgramDaily from './pages/prepaid/ProgramDaily'
import PrepaidAddValue from './pages/prepaid/PrepaidAddValue'

import Wifi from './pages/otherService/Wifi'
import WifiManual from './pages/otherService/WifiManual'
import OO7 from './pages/otherService/OO7'

import parkingfeePage from './pages/parkingfeeController/parkingfeePage'
import parkingfeeChangeRecordPage from './pages/parkingfeeController/parkingfeeChangeRecordPage'
import parkingfeeInfoPage from './pages/parkingfeeController/parkingfeeInfoPage'
import parkingfeeInitPage from './pages/parkingfeeController/parkingfeeInitPage'
import ResultPage from './pages/resultController/ResultPage'
import Discount from './pages/Discount'
import SearchResult from './pages/SearchResult'
import CbuIndex from './pages/CbuIndex'

import NonLoginPrepaidDepositPage from './pages/prepaidDepositController/NonLoginPrepaidDepositPage'

import drama from './pages/lifeCircle/drama'
import slashie from './pages/lifeCircle/slashie'
import article from './pages/lifeCircle/article'

import qRateInitPage from './pages/qRateController/qRateInitPage'
import fourGExtraBuyQueryPage from './pages/four4GExtraBuyController/4GExtraBuyQueryPage'
import usedBytesQueryPage from './pages/fourGUsedbytesSearchController/usedBytesQueryPage'
import Query4GNoLimited from './pages/fourGUsedbytesSearchController/Query4GNoLimited'
import FeeDetail from './pages/billSearchController/FeeDetail'
import CallDetail from './pages/billSearchController/CallDetail'
import CallDetailAnalysis from './pages/billSearchController/CallDetailAnalysis'
import getBillSearchInitPage from './pages/billSearchController/getBillSearchInitPage'

import Course from './pages/course'
import CourseBooking from './pages/course/CourseBooking'
import QueryPay from './pages/billQuery/QueryPay'
import QueryPayEmpty from './pages/billQuery/QueryPayEmpty'
import QueryPayNoBill from './pages/billQuery/QueryPayNoBill'

import filteringSetting from './pages/filtering/Setting'
import filteringSuccess from './pages/filtering/Success'
import FilterInstruction from './pages/filtering/FilterInstruction'
import Blacklist from './pages/filtering/Blacklist'
import BlacklistSuccess from './pages/filtering/BlacklistSuccess'

import RoamingPlanSearch from './pages/roamingPlan/Search'
import RoamingPlanSearchApplied from './pages/roamingPlan/SearchApplied'
import RoamingPlanApply from './pages/roamingPlan/Apply'
import RoamingPlanApplyConfirm from './pages/roamingPlan/ApplyConfirm'
import RoamingPlanHistory from './pages/roamingPlan/History'
import RoamingPlanCancel from './pages/roamingPlan/Cancel'
import RoamingPlanAdd from './pages/roamingPlan/Add'
import International from './pages/phase3/International'

import HappyGo from './pages/happy-go'

import Reminder from './pages/reminder'
import ChangeNumber from './pages/ChangeNumber'
import Bulletin from './pages/Bulletin'
import Fixup from './pages/fixup'

import Profile1 from './pages/profile/Profile1'
import Profile2 from './pages/profile/Profile2'
import ProfileLogin from './pages/profile/ProfileLogin'
import Profile from './pages/profile/Profile'
import Fcoin from './pages/profile/Fcoin'

import ChangeRecord from './pages/ChangeRecord'
import FeedbackRecord from './pages/FeedbackRecord'
import ServiceBooking from './pages/ServiceBooking'

import FixQuery from './pages/fixQuery'

import BookingFixup from './pages/bookingFixup'
import Lottery from './pages/Lottery'
import LotteryContent from './pages/Lottery/content'

import Coop from './pages/Coop'
import Warranty from './pages/warranty'

import Lost from './pages/roamingService/Lost'
import LostSuccess from './pages/roamingService/Success'
import LostFail from './pages/roamingService/Fail'

import instruction from './pages/instruction/Index'
import instructionDownload from './pages/instruction/Download'
import NumberSearch from './pages/numberSearch/Index'

import ReceiptSetting from './pages/receiptSetting/ReceiptSetting'
import PaymentInfo from './pages/payment-info/index'
import AutoBill from './pages/AutoBill/'

import getUnpaidAmoutAndUsedbytesPage from './pages/unpaidAmoutAndUsedbytesController/getUnpaidAmoutAndUsedbytesPage'
import BillResend from './pages/billQuery/BillResend'
import BillResendSuccess from './pages/billQuery/BillResendSuccess'
import Newsletter from './pages/memberData/Newsletter'
import MemberData from './pages/memberData/MemberData'

// window.history.pushState('', null, './');
let path = '';
// 如果按瀏覽器上一頁，則 reload 畫面
if (typeof window !== 'undefined') {
  window.addEventListener('popstate', function () {
    window.location.reload(true);
  });

  path = window.location.pathname;
}

const App = (state) => {
  const getEmma = () => {
    return {
      text: path.indexOf('/cbu/5g') > -1 ? '我有興趣' : null,
      link: getEmmaLink(),
      show: true,
      useEmmaAvatar: true
    }
  }
  const getEmmaLink = () => {
    return path.indexOf('/cbu/5g') > -1 ? 'https://enterprise.fetnet.net/content/ebu/tw/5g/form/5G-cbu-form.html' : ''
  }
  const displayEmma = () => {
    let notDisplayGroup = [
      '/404',
      '/under-maintainance',
      '/ebu/news',
      '/ebu/news/newscontent',
      '/ebu/news/newscontentversion2',
      '/ebu/form/micro-form',
      '/ebu/form/medium-form',
      '/ebu/form/large-form',
      '/ebu/form/public-sector-form',
      '/ebu/form/cloud-form',
      '/ebu/form/smart-wifi-form',
      '/ebu/form/event-form',
      '/ebu/form/5g-form',
      '/ebu/form/5g-event',
      '/ebu/5g',
      '/broadcast',
      '/entrymainframepage',
      '/seednet',
      '/seednet-domain',
      '/office365',
      '/mrtg',
      '/ebu/business-login',
      '/error',
      '/module/section',
    ];
    let result = true;
    for (let i = 0; i < notDisplayGroup.length; i++) {
      if (notDisplayGroup[i] === path.toLowerCase()) {
        result = false
        break
      } else {
        result = true
      }
    }
    // 

    return result;
  };


  return (
    <Router
      basename={process.env.PUBLIC_URL}
    >
      <Loader />
      <Header />
      <ParallaxProvider>
        <Switch>
          <Route path='/' exact component={withTrackingRoute(CbuIndex, '首頁')} />
          <Route path='/index' exact component={withTrackingRoute(CbuIndex, '首頁')} />
          {/* 5.5.0_幫助中心_手機/平板操作說明 */}
          <Route path='/instruction' exact component={instruction} />
          <Route path='/instruction/download' exact component={instructionDownload} />
          {/* 5.7.0_幫助中心_網內外門號查詢 */}
          <Route path='/numberSearch' exact component={NumberSearch} />
          {/* 5.15.0_幫助中心_服務申請證件提醒 */}
          <Route path='/reminder' exact component={Reminder} />
          {/* 5.16.0_幫助中心_預約門市換號 */}
          <Route path='/changeNumber' exact component={ChangeNumber} />
          {/* 5.19.0_服務公告 */}
          <Route path='/bulletin/' exact component={Bulletin} />
          {/* 5.13.0_幫助中心_網路預約報修查詢 */}
          <Route path='/fix-query' exact component={FixQuery} />
          {/* 5.12.0_幫助中心_網路預約報修 */}
          <Route path='/booking-fixup' exact component={BookingFixup} />
          {/* 5.11.0_幫助中心_保固查詢 */}
          <Route path='/warranty' exact component={Warranty} />
          {/* 7.1.0 */}
          {/* 7.1.0.1_帳單與用量_帳單查詢_主頁 */}
          <Route path='/billSearchController/getBillSearchInitPage' exact component={getBillSearchInitPage} />
          <Route path='/billSearchController/call-detail' exact component={CallDetail} />
          <Route path='/billSearchController/call-detail-analysis' exact component={CallDetailAnalysis} />
          <Route path='/billSearchController/fee-detail' exact component={FeeDetail} />
          <Route path='/four4GExtraBuyController/4GExtraBuyQueryPage' exact component={fourGExtraBuyQueryPage} />
          {/* 7.1.1.1_帳單與用量_本期應繳金額_主頁-尚有欠款金額 */}
          <Route path='/bill-query/pay' exact component={QueryPay} />
          <Route path='/bill-query/pay-empty' exact component={QueryPayEmpty} />
          <Route path='/bill-query/no-bill' exact component={QueryPayNoBill} />

          {/* 7.1.2.1_未出帳金額/上網用量_主頁/上網用量 */}
          <Route path='/unpaidAmoutAndUsedbytesController/getUnpaidAmoutAndUsedbytesPage' exact component={getUnpaidAmoutAndUsedbytesPage} />
          {/* 7.1.4.15_帳單與用量_補寄帳單_email補寄 */}
          <Route path='/resend-bill' exact component={BillResend} />
          <Route path='/resend-bill/success/' exact component={BillResendSuccess} />
          {/* 7.1.5.1_帳單與用量_4G即時用量查詢_主頁 */}
          <Route path='/fourGUsedbytesSearchController/usedBytesQueryPage' exact component={usedBytesQueryPage} />
          <Route path='/fourGUsedbytesSearchController/4g-usage-no-limited' exact component={Query4GNoLimited} />
          {/* 7.1.6 */}
          <Route path='/four4GExtraBuyController/4GExtraBuyPage' exact component={fourGExtraBuyPage} />
          <Route path='/four4GExtraBuyController/4GExtraBuySubmitPage' exact component={fourGExtraBuySubmitPage} />

          {/* 7.1.8 */}
          <Route path='/phoneBillPaySettingController' exact component={getPhoneBillPaySettingInitPage} />
          <Route path='/phoneBillPaySettingController/getPhoneBillPaySettingCheckResetPasswordPage' exact component={getPhoneBillPaySettingCheckResetPasswordPage} />
          <Route path='/phoneBillPaySettingController/getPhoneBillPaySettingValidateRocidPage' exact component={getPhoneBillPaySettingValidateRocidPage} />
          <Route path='/phoneBillPaySettingController/getPhoneBillPaySettingSelectAmountPage' exact component={getPhoneBillPaySettingSelectAmountPage} />
          <Route path='/phoneBillPaySettingController/getPhoneBillPaySettingOpenPage' exact component={getPhoneBillPaySettingOpenPage} />
          <Route path='/phoneBillPaySettingController/getPhoneBillPaySettingSmsValidateErrorPage' exact component={getPhoneBillPaySettingSmsValidateErrorPage} />
          <Route path='/phoneBillPaySettingController/getPhoneBillPaySettingOpenResultPage' exact component={getPhoneBillPaySettingOpenResultPage} />

          {/* 7.2.3_繳費項目_自動扣繳申請 */}
          <Route path='/auto-bill' exact component={AutoBill} />
          {/* 7.2.2_繳費項目_繳款方式 */}
          <Route path='/payment-info' exact component={PaymentInfo} />
          {/* 7.3.0_合約費率_目前費率查詢 */}
          <Route path='/qRateController/qRateInitPage' exact component={qRateInitPage} />
          {/* 7.3.1.1 */}
          <Route path='/rateChangeController/rateChangeInitPage' exact component={RateChangeInitPage} />


          {/* 7.3.3 */}
          <Route path='/queryContractsInfoController/getQueryContractsInfoPage' exact component={getQueryContractsInfoPage} />

          {/* 7.4.0.1_漫遊與門號服務_掛失服務_主頁 */}
          <Route path='/roamingService/lost' exact component={Lost} />
          <Route path='/roamingService/lost-success' exact component={LostSuccess} />
          <Route path='/roamingService/lost-fail' exact component={LostFail} />

          {/* 7.4.12_漫遊與門號服務_撥打國際電話 */}
          <Route path='/international' exact component={International} />
          {/* 7.5.2_交易/異動紀錄_設備維修進度查詢 */}
          <Route path='/fixup' exact component={Fixup} />
          {/* 拉皮，移到 HTML */}
          {/* <Route path='/roamingConfigController/getRoamingConfigPage' exact component={getRoamingConfigPage} /> */}
          {/* 7.5.1_交易/異動紀錄_門號/服務異動記錄 */}
          <Route path='/change-record/' exact component={ChangeRecord} />
          {/* 7.5.3_交易/異動紀錄_通訊問題反應記錄 */}
          <Route path='/feedback-record' exact component={FeedbackRecord} />
          {/* 7.5.4_交易/異動紀錄_門市預約記錄 */}
          <Route path='/serviceBooking' exact component={ServiceBooking} />

          {/* 7.5.5_交易/異動紀錄_免費課程報名記錄 */}
          <Route path='/course' exact component={Course} />
          <Route path='/course/booking' exact component={CourseBooking} />
          {/* 7.5.6 停車費代收記錄查詢 */}
          <Route path='/parkingfeeController/parkingfeePage' exact component={parkingfeePage} />
          <Route path='/parkingfeeController/parkingfeeChangeRecordPage' exact component={parkingfeeChangeRecordPage} />

          {/* 7.6.0 */}
          <Route path='/prepaidDepositController/prepaidExtraBuy' exact component={prepaidExtraBuy} />
          <Route path='/prepaidDepositController/fillPrepaidDepositDataPage' exact component={fillPrepaidDepositDataPage} />
          <Route path='/queryRecordController/getQueryRecordPage' exact component={getQueryRecordPage} />
          {/* 7.6.8 */}
          <Route path='/prepaidDepositController/prepaidRapidDepositPage' exact component={prepaidRapidDepositPage} />

          {/* 7.6.1 */}
          <Route path='/prepaidRoamingBalanceController/prepaidRoamingBuyPage' exact component={prepaidRoamingBuyPage} />
          <Route path='/prepaidRoamingBalanceController/prepaidRoamingByBankPage' exact component={prepaidRoamingByBankPage} />
          <Route path='/prepaidRoamingBalanceController/prepaidRoamingByCreditPage' exact component={prepaidRoamingByCreditPage} />
          {/* 7.4.7.1_漫遊與門號服務_來電過濾_主頁 */}
          <Route path='/filtering/setting' exact component={filteringSetting} />
          <Route path='/filtering/success' exact component={filteringSuccess} />
          <Route path='/filtering/instruction' exact component={FilterInstruction} />
          {/* 7.4.7.7_漫遊與門號服務_來電過濾_加入黑名單入口 */}
          {/* 7.4.7.10_漫遊與門號服務_來電過濾_過濾名單設定_尚未開啟來電過濾 */}
          <Route path='/filtering/blacklist' exact component={Blacklist} />
          <Route path='/filtering/blacklist-success' exact component={BlacklistSuccess} />

          {/* 7.6.2 */}
          <Route path='/prepaidDepositController/prepaidDetailQueryPage' exact component={prepaidDetailQueryPage} />

          {/* 7.6.3 */}
          <Route path='/prepaidDepositController/prepaidDepositPage' exact component={prepaidDepositPage} />
          <Route path='/prepaidDepositController/depositCardPage' exact component={depositCardPage} />
          <Route path='/prepaidDepositController/prepaidDepositSuccess' exact component={prepaidDepositSuccess} />
          <Route path='/prepaidDepositController/prepaidDepositFail' exact component={prepaidDepositFail} />

          {/* 7.6.8 預付卡專區_快速儲值(未登入狀態) */}
          <Route path='/prepaidDepositController/NonLoginPrepaidDepositPage' exact component={NonLoginPrepaidDepositPage} />

          {/* 7.2.1 */}
          <Route path='/billBarcodePayController/getBarcodePayInitPage' exact component={getBarcodePayInitPage} />

          {/* 7.4.16 */}
          <Route path='/roamingPlan/Search' exact component={RoamingPlanSearch} />
          <Route path='/roamingPlan/SearchApplied' exact component={RoamingPlanSearchApplied} />
          <Route path='/roamingPlan/Apply' exact component={RoamingPlanApply} />
          <Route path='/roamingPlan/ApplyConfirm' exact component={RoamingPlanApplyConfirm} />
          <Route path='/roamingPlan/History' exact component={RoamingPlanHistory} />
          <Route path='/roamingPlan/Cancel' exact component={RoamingPlanCancel} />
          <Route path='/roamingPlan/Add' exact component={RoamingPlanAdd} />

          {/* 5.9.0 */}
          <Route path='/serviceCoverageController/serviceCoveragePage' exact component={serviceCoveragePage} />

          {/* 8.1.3 */}
          <Route path='/change-password' exact component={ChangePassword} />
          {/* 8.1.5.3_個人專區_帳戶管理_電子發票設定_電信電子發票設定 */}
          <Route path='/receipt-setting' exact component={ReceiptSetting} />
          {/* 8.2 Happy Go */}
          <Route path='/happy-go' exact component={HappyGo} />

          {/* 5.3 */}
          <Route path='/store-finder' exact component={StoreFinder} />
          {/* 10.1 */}
          <Route path='/rate-plan/4.5G' exact component={FourPointFive} />
          <Route path='/rate-plan/rate-plan' exact component={RatePlan} />
          <Route path='/rate-plan/notice' exact component={Notice} />

          {/* 10.2 - 10.3 */}
          <Route path='/prepaid/add-value' exact component={AddValue} />
          <Route path='/prepaid/application' exact component={Application} />
          <Route path='/prepaid/program' exact component={Program} />
          <Route path='/prepaid/foreign' exact component={Foreign} />
          <Route path='/prepaid/foreign-eng' exact component={ForeignEng} />
          <Route path='/prepaid/prepaid-add-value' exact component={PrepaidAddValue} />
          <Route path='/prepaid/prepaid-amount' exact component={ProgramAmount} />
          <Route path='/prepaid/prepaid-daily' exact component={ProgramDaily} />

          {/* 10.6 - 10.8 */}
          <Route path='/otherService/Wifi' exact component={Wifi} />
          <Route path='/otherService/WifiManual' exact component={WifiManual} />
          <Route path='/otherService/007' exact component={OO7} />

          {/* 11.1.0_中獎公告 */}
          <Route path='/lottery' exact component={Lottery} />
          <Route path='/lottery/content' exact component={LotteryContent} />

          {/* 11.6.0_合作提案 */}
          <Route path='/coop' exact component={Coop} />

          {/* 7.2.0 */}
          <Route path='/payController/payPage' exact component={payPage} />
          <Route path='/payController/payNothingPage' exact component={PayNothingPage} />
          <Route path='/payController/payDepositPage' exact component={payDepositPage} />
          <Route path='/billBankPayController/billBankPayPage' exact component={billBankPayPage} />
          <Route path='/billBankPayController/fillBillBankPayDataPage' exact component={fillBillBankPayDataPage} />
          <Route path='/billCreditcardPayController/billCreditcardPayPage' exact component={billCreditcardPayPage} />
          <Route path='/billCreditcardPayController/fillBillCreditcardPayDataPage' exact component={fillBillCreditcardPayDataPage} />

          {/* 7.4.7.1_漫遊與門號服務_來電過濾_主頁 */}
          <Route path='/filtering/setting' exact component={filteringSetting} />
          <Route path='/filtering/success' exact component={filteringSuccess} />

          {/* 7.4.22 */}
          <Route path='/parkingfeeController/parkingfeeInfoPage' exact component={parkingfeeInfoPage} />
          <Route path='/parkingfeeController/parkingfeeInitPage' exact component={parkingfeeInitPage} />

          {/* 8.1.1_個人專區_帳戶管理_訂閱電子報 */}
          <Route path='/newsletter' exact component={Newsletter} />
          {/* 8.1.0_個人專區_帳戶管理_個人資料維護_未設定 */}
          <Route path='/member-data' exact component={MemberData} />
          {/* 9.2.0 */}
          <Route path='/discount' exact component={Discount} />
          <Route path='/resultController/ResultPage' exact component={ResultPage} />

          {/* 生活圈 */}
          <Route path='/lifeCircle/drama' exact component={drama} />
          <Route path='/lifeCircle/slashie' exact component={slashie} />
          <Route path='/lifeCircle/article' exact component={article} />

          {/* 1.5.* */}
          <Route path='/search/result' exact component={withTrackingRoute(SearchResult, 'search-result')} />
          {/* sitemap */}
          {/* <Route path='/sitemap' exact component={Sitemap} /> */}

          {/* 遠傳幣 */}
          {/* 7.0 個人專區 */}
          <Route path='/profile' exact component={Profile} />
          {/* 7.0.0.1 */}
          <Route path='/profile1' exact component={Profile1} />
          <Route path='/profile2' exact component={Profile2} />
          <Route path='/fcoin' exact component={Fcoin} />
          <Route path='/profile-login' exact component={ProfileLogin} />

          <Route component={Error} />
        </Switch>
      </ParallaxProvider>
      <Footer adShow={true} />
      <GoTop />

      <EmmaService {...getEmma()} />
      <Cookie />
    </Router>
  );
};

const ReduxExample = connect(state => state, { ...Action })(App);

export default ReduxExample;
