import React from 'react';
import {Parallax} from 'react-scroll-parallax';

const RedArrow = (props) => {
    return (
        <Parallax className="animate-red-arrow" y={['200px', '0px']}>
            <svg width="100%" viewBox="0 0 705 511" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient x1="100%" y1="0%" x2="0%" y2="0%" id="redarrow-gradient-1">
                        <stop stopColor="#AE1702" offset="0%"></stop>
                        <stop stopColor="#E72511" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon fill="url(#redarrow-gradient-1)" fillRule="nonzero" transform="translate(352.500000, 255.500000) scale(-1, 1) translate(-352.500000, -255.500000) " points="705 0 705 511 0 511"></polygon>
                </g>
            </svg>
        </Parallax>
    )
}

export default RedArrow;
