import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Link from '../Link';
class InstructionList extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="fui-instruction-list">
                <Grid item xs={12} sm={12}>
                    <h2 className="">{this.props.title}</h2>
                </Grid>
                <Grid container spacing={2}>
                    {this.props.list.map((item, i) => {
                        return (
                            <Grid item xs={6} sm={3} key={item.text}>
                                <Link
                                    to={item.link}
                                    className='fui-card'
                                >
                                    <div className='fui-card-caption'>
                                        <div className='fui-card-content align-center'>
                                            <div className="img-wrapper">
                                                <img className="w-100" src={process.env.PUBLIC_URL + item.img.url} alt={item.img.alt} />
                                            </div>
                                            <div className='heading-6 is-text-regular align-left mt-2'>
                                                {item.text}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        );
    }
}

export default InstructionList;