import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Button from '../../components/Button';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

let bill1 = {
    detailLink: '#123',
    date: '2020年04月',
    period: '2020/03/20 至 2020/04/19',
    phoneNumber: '0916-814-747',
    endDate: '2020/04/20',
    fee: [
        {
            title: '電信費用',
            fee: 0,
            isRed: false
        },
        {
            title: '小額代收',
            fee: 120,
            isRed: false
        },
        {
            title: '本期新增總額',
            fee: 120,
            isRed: true
        }
    ],
    typeForm: {
        title: '國內通話',
        table: {
            head: ['月份', '2020/04', '2020/03', '2020/02', '2020/01', '2019/12', '2019/11'],
            content: [
                [
                    {
                        title: '月租費',
                        tooltip: '月租費'
                    },
                    {
                        title: '國內通話',
                        tooltip: '國內通話'
                    },
                    {
                        title: '加值服務',
                        tooltip: '加值服務'
                    },
                    {
                        title: '國際服務',
                        tooltip: '國際服務'
                    },
                    {
                        title: '其他服務',
                        tooltip: '其他服務'
                    },
                    {
                        title: 'firDay服務',
                        tooltip: 'firDay服務'
                    },
                    {
                        title: '合計',
                        tooltip: '合計'
                    },
                ],
                ['64.00元', '0.00元', '0.00元', '0.00元', '0.00元', '0.00元', '64.00元'],
                ['100.00元', '0.00元', '0.00元', '0.00元', '0.00元', '0.00元', '100.00元'],
                ['199.00元', '0.00元', '0.00元', '0.00元', '0.00元', '0.00元', '199.00元'],
                ['199.00元', '0.00元', '0.00元', '0.00元', '0.00元', '0.00元', '199.00元'],
                ['199.00元', '0.00元', '0.00元', '0.00元', '0.00元', '0.00元', '199.00元'],
                ['199.00元', '0.00元', '0.00元', '0.00元', '0.00元', '0.00元', '199.00元']
            ]
        }
    }
}
let multipleBill = [
    {
        date: '2020/04',
        percentage: 3,
        display: 13
    },
    {
        date: '2020/03',
        percentage: 0,
        display: 0
    },
    {
        date: '2020/02',
        percentage: 5,
        display: 99
    },
    {
        date: '2020/01',
        percentage: 6,
        display: 999
    },
    {
        date: '2019/12',
        percentage: 7,
        display: 199
    },
    {
        date: '2019/11',
        percentage: 4,
        display: 199
    },
]

class CallDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectBill: { value: '0', text: '0916-814-747' },
            billOption: [
                { value: '0', text: '0916-814-747' },
                { value: '1', text: '0911-814-747' }
            ],
            bill: multipleBill,
            sorting: '',
            ascending: false,
            canSubmit: false,
            form: {
                id: { value: '', required: true },
            },
            selectPeriod: { value: '0', text: '2020/04月份' },
            periodOption: [
                { value: '0', text: '2020/04月份' },
                { value: '1', text: '2020/05月份' }
            ],
            currentBill: bill1,
            searchType: { value: '0', text: '請選擇篩選項目' },
            filterType: { value: '0', text: '全部' }
        }
    }

    onChange = (name, value) => {
        let newForm = Object.assign(this.state.form);
        newForm[name].value = value;
        this.setState({
            form: newForm
        })
    }

    selectPeriod = (val) => {
        console.log(parseInt(val));

        console.log(this.state.periodOption[parseInt(val)]);

        this.setState({
            selectPeriod: this.state.periodOption[parseInt(val)]
        })
        if (val === '0') {
            this.setState({
                currentBill: bill1
            })
        }
    }
    changeTab = () => {
        console.log('changed tab');
    }
    selectedSearchType = (val) => {
        this.setState({
            searchType: val
        })
    }


    onChangePage(pageOfItems, selectedPage) {
        // this.props.history.push(`${this.props.location.pathname}?keyword=${this.state.keyword}&page=${selectedPage}`);

        // update page of items
        // this.updatePageOfItems(pageOfItems);
        // console.log('on change');
    }

    sortingFunc = (sorting) => {
        // console.log(sorting);
        if (this.state.sorting == sorting) {
            this.setState({
                sorting: sorting,
                ascending: !this.state.ascending
            })
        } else {
            this.setState({
                sorting: sorting,
                ascending: false
            })
        }
    }
    selectBill = (val, v) => {
        // console.log(parseInt(val));
        console.log({ val, v });
        this.setState({
            selectBill: v
        })
    }
    render() {
        return (
            <main>
                <NavAnchor
                    pageTitle='帳單與用量'
                    button={{
                        text: '回個人專區',
                        link: '#'
                    }} />
                <FormBanner
                    title="六期帳單金額"
                    image={{
                        md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
                        sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
                    }}
                />
                <section className="">
                    <div className="fui-container">
                        {!!this.state.bill ?
                            <div>
                                {this.state.bill.length > 1 ? <div className="paper mt-md-4 mt-2 mb-2 pt-md-7 pt-3">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className='d-block d-md-none'>
                                            <Dropdown
                                                className="is-button mb-1"
                                                list={this.state.billOption}
                                                label={this.state.selectBill.text}
                                                arrow={true}
                                                hasCheck={false}
                                                onChange={this.selectBill}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={8}>
                                            <div className="mb-md-4 mb-4">
                                                <h4 className='m-0'>帳單分析說明</h4>
                                                <p>此服務提供您了解近6個月帳單費用的分佈，並可由下方圖表了解各月份帳單的費用高低。</p>
                                            </div>
                                        </Grid>
                                        <Grid item sm={12} md={4} className='d-none d-md-block'>
                                            <Dropdown
                                                className="is-button"
                                                list={this.state.billOption}
                                                label={this.state.selectBill.text}
                                                arrow={true}
                                                hasCheck={false}
                                                onChange={this.selectBill}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className="bill-ruler">
                                        {this.state.bill.map((item, i) => {
                                            return (
                                                <div key={item.date + i} className="bill-ruler-wrapper">
                                                    <h5>{item.date}</h5>
                                                    <div className={`fui-ruler ${i == 0 ? 'main' : ''} ${i === 3 ? 'fetnet-coin' : ''}`}>
                                                        <div className="middle-border" />
                                                        <div className={`percentage ${item.percentage === 100 ? 'full-width' : ''}`} style={{ width: (item.percentage === 0 ? 0 : (item.percentage < 6 && item.percentage !== 0 ? 6 : item.percentage)) + '%' }}>
                                                            <span className={item.percentage === 0 ? 'zero' : ''}>
                                                                <div style={{ marginRight: item.percentage === 100 ? -20 : 0 }}>{item.display}元</div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>


                                    <div className="compareTable w-142 content-16 clearfix text-wrap bill-table mt-0 stick-to-right">
                                        <div className="tableLeft">
                                            <div className="tableHead">
                                                <table className="table-gray-head">
                                                    <tbody>
                                                        {this.state.currentBill.typeForm.table.head.map((item, i) => {
                                                            return (
                                                                <tr key={item}>
                                                                    {i == 0 ? <th className={`align-center ${i == 1 ? 'is-text-medium' : ''}`}>
                                                                        {item}
                                                                    </th> : <td className={`align-center`}>
                                                                            {item}
                                                                        </td>}
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="tableRight">
                                            <div className="tableBody">
                                                <div className="spaceBox">
                                                    <table className="compareList table-gray-head">
                                                        <tbody>
                                                            {this.state.currentBill.typeForm.table.content.map((list, i) => (
                                                                <tr key={list + i}>
                                                                    {list.map((item, j) => (
                                                                        <td key={item + j}>
                                                                            {i == 0 ? <div key={item + j}>
                                                                                <div className="mr-1 d-inline-block">{item.title}</div>
                                                                                <Tooltip
                                                                                    content={<i className='icon-information i-24 is-text-darkgray100'></i>}
                                                                                    trigger="click"
                                                                                    tooltip={item.tooltip}
                                                                                />
                                                                            </div> : <div>{item}</div>}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    :
                                    null}
                                {this.state.bill.length == 0 ?
                                    <div className="paper mt-md-4 m-0">
                                        <div className="mt-md-4 mt-0 mb-0">
                                            <h4 className='m-0'>帳單分析說明</h4>
                                            <p className='body mb-md-6 is-text-darkgray50'>親愛的用戶，您的門號目前僅有一期帳單資料，可使用「本月帳單費用分析」來了解各項收費的金額即佔比，謝謝。</p>
                                            <div className="page-action">
                                                <Button onClick={() => console.log("click")} className='m-0' btnStyle='primary' size='large'>本月帳單費用分析</Button>
                                            </div>
                                        </div>
                                    </div> : null
                                }
                            </div>
                            : <div className="paper mt-md-4 m-0">
                                <div className="mt-md-4 mt-0 mb-0">
                                    <h4 className='m-0'>帳單分析說明</h4>
                                    <p className='body mb-md-6 is-text-darkgray50'>親愛的用戶，您的門號目前沒有資料，謝謝。</p>
                                </div>
                            </div>
                        }


                    </div>
                </section>
                {/* remove in production */}
                <div className="switcher py-5">
                    <div className="fui-container">
                        <div className="d-flex flex-column">
                            <p>測試用資料切換</p>
                            <Button onClick={() => this.setState({ bill: !!this.state.bill ? [] : multipleBill })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.0.9_帳單與用量_六期帳單金額_只有一筆</Button>
                            <Button onClick={() => this.setState({ bill: !!this.state.bill ? null : multipleBill })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.0.10_帳單與用量_六期帳單金額_空值</Button>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

export default CallDetail;