import React from 'react';
import Item from '../item/Item';
import PropTypes from 'prop-types';

class PanelList extends React.Component {
  renderItem() {
    return this.props.list.map((item, i) => (
      <Item key={`list-item-${i}`} number={i + 1} prefix={this.props.prefix}>
        {item.text}
      </Item>
    ));
  }

  render() {
    return <div className='fui-list'>{this.renderItem()}</div>;
  }
}

PanelList.propTypes = {
  prefix: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(Item).isRequired,
};

export default PanelList;
