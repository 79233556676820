import React from 'react';
import TabPane from '../../components/tab/TabPane';
import PropTypes from 'prop-types'
import Slider from 'react-slick';
import Panel from '../../components/panel/Panel';
import PanelTab from '../../components/panelContent/PanelTab';
import PanelInformation from '../../components/panelContent/PanelInformation';
import PanelTable from '../../components/panelContent/PanelTable';
import CarouselWithTag from '../../components/partials/carousel/CarouselWithTag'
class ForeignContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.programSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 3,
      draggable: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 1,
            variableWidth: true,
          },
        },
      ],
    };
  }
  render() {
    return (
      <div className="ribbon-bg">
        <CarouselWithTag
          title={this.props.carousel.title}
          cards={this.props.carousel.cards}
          id={this.props.carousel.id}
        />
        <section className="how-to-dial pt-0" id="howTo">
          <div className="fui-container">
            <h3 className="mb-4 mt-0 heading-2">{this.props.dial.title}</h3>
            <Panel>
              <div dangerouslySetInnerHTML={{ __html: this.props.dial.panel }}></div>
              <PanelTable
                content={this.props.panelTable}
              />
            </Panel>
            <PanelInformation title={this.props.panelInformationTitle} content={this.props.panelInformationContent} />
          </div>
        </section>
        <section id="whereToBuy">
          <div className="fui-container">
            <h2 className="mb-4 mt-0">{this.props.whereToBuy.title}</h2>
            <Panel>
              <PanelTab
                activeTab={0}
                tabs={this.props.whereToBuy.tabs}>
                <div dangerouslySetInnerHTML={{ __html: this.props.whereToBuy.tabContent }}></div>
                <div>hi</div>
                <div>hi</div>
              </PanelTab>
            </Panel>
          </div>
        </section>
      </div>
    );
  }
}
ForeignContent.propTypes = {
  carousel: PropTypes.object,
  dialTitle: PropTypes.string,
  pane: PropTypes.string,
  panelTable: PropTypes.string,
  panelInformationTitle: PropTypes.string,
  panelInformationContent: PropTypes.string,
  whereToBuy: PropTypes.object,
}
export default ForeignContent;