import React from 'react';
import Collapse from '../../collapse/Collapse';
import PropTypes from 'prop-types'

class CollapsePaper2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentOpen: false,
      current: -1
    }
    this.collapseChnage = this.collapseChnage.bind(this)

  }

  collapseChnage(open, index) {
    this.setState({
      current: index,
      currentOpen: open
    })
  }

  render() {
    return (
      <section className="fui-collapse-paper" id={this.props.id}>
        <div className={this.props.container ? 'fui-container' : null}>
          {
            this.props.title ? <h3 className="is-text-darkgray50">{this.props.title}</h3> : ''
          }
          <div className="fui-collapse-group">
            {
              this.props.collapseList.map((collapse, i) => (
                <Collapse
                  key={`collapse-group-${i}`}
                  title={collapse.title}
                  content={collapse.content}
                  open={this.state.current === i ? this.state.currentOpen : false}
                  onChange={open => this.collapseChnage(open, i)} />
              ))
            }
          </div>
        </div>
      </section>
    )
  }
}

CollapsePaper2.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  container: PropTypes.bool,
  hideHelpCenter: PropTypes.bool,
  to: PropTypes.string,
  collapseList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      open: PropTypes.bool
    })
  )
}

export default CollapsePaper2;