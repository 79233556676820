import React, { Component } from 'react'
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';
import Dropdown from "../../components/Dropdown";
import Button from '../../components/Button';
import PropTypes from 'prop-types';
class CardProductSingle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sldierSetting: {
                dots: true,
                infinite: false,
                arrows: true,
                slidesToShow: 1,
                draggable: true,
                adaptiveHeight: false,
            }
        }
    }
    render() {
        return (
            <section className='fui-product-section pb-0 pt-md-7 mb-4'>
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                    <div className="fui-container">
                        <div className='fui-panel position-relative main-shadow'>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    {/* 產品圖 */}
                                    <Slider {...this.state.sldierSetting} className='fui-product-slider'>
                                        {this.props.list[0].image.map((img, i) => (
                                            <img src={img} alt='' key={`fui-product-slider-${i}`} />
                                        ))}
                                    </Slider>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className=''>
                                    {/* 產品內容 */}

                                    <h4 className="is-text-bold">
                                        {this.props.list[0].name}
                                    </h4>
                                    <div dangerouslySetInnerHTML={{
                                        __html: this.props.list[0].content
                                    }}></div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Formsy>
            </section>
        );
    }
}
CardProductSingle.propTypes = {
    hotTag: PropTypes.string,
    tag: PropTypes.array,
    name: PropTypes.string,
    list: PropTypes.array,
    brand: PropTypes.string,
    image: PropTypes.array,
}
export default CardProductSingle;