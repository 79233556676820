export const breadcrumb = [
  { text: '商務優惠', link: '/ebu' },
  { text: '優惠', link: '/ebu/promotion' },
];

export const pageHeader = {
  pageTitle: '優惠',
  tabs: [],
  button: {
    text: '優惠通知',
    link: '#'
  }
};

export const sliders = [
  {
    type: '限時特惠中',
    title: '4.5G方案最低199 更快網速',
    img: {
      md: '/resources/cbu/discount/cbu-4.5G-1920x360.jpg',
      sm: '/resources/cbu/discount/cbu-4.5g-750x640.jpg'
    },
    action: {
      text: '馬上申辦',
      link: '#'
    },
  },
  {
    type: '限時特惠中',
    title: '4.5G方案最低199 更快網速',
    img: {
      md: '/resources/cbu/discount/cbu-4.5G-1920x360.jpg',
      sm: '/resources/cbu/discount/cbu-4.5g-750x640.jpg'
    },
    action: {
      text: '馬上申辦',
      link: '#'
    },
  },
  {
    type: '限時特惠中',
    title: '4.5G方案最低199 更快網速',
    img: {
      md: '/resources/cbu/discount/cbu-4.5G-1920x360.jpg',
      sm: '/resources/cbu/discount/cbu-4.5g-750x640.jpg'
    },
    action: {
      text: '馬上申辦',
      link: '#'
    },
  },
  {
    id: '004',
    type: '頭家輕鬆配系列產品',
    title: '市內電話輕鬆省',
    img: {
      md: '/resources/cbu/discount/cbu-4.5G-1920x360.jpg',
      sm: '/resources/cbu/discount/cbu-4.5g-750x640.jpg'
    },
    action: {
      text: '馬上申辦',
      link: '#'
    },
  },
];

export const menu = {
  name: '優惠類別',
  currentSelected: '',
  content: [
    // {
    //   name: '熱門優惠',
    //   id: 'a',
    // },
    {
      name: '4.5G語音+上網',
      id: 'b',
      content: [
        {
          name: '單門號',
          id: 'b01',
        },
        {
          name: '上網微微飽',
          id: 'b02',
        },
        {
          name: '飆網吃到飽',
          id: 'b03',
        },
        {
          name: '上網搭手機',
          id: 'b04',
        },
        {
          name: '上網搭家電',
          id: 'b05',
        },
        {
          name: '上網搭寬頻',
          id: 'b06',
        },
        {
          name: '上網搭生活娛樂',
          id: 'b06',
        },
      ],
    },
    {
      name: '推薦續約',
      id: 'c',
      content: [
        {
          name: '行銷01',
          id: 'c01',
        },
        {
          name: '行銷02',
          id: 'c02',
        },
      ],
    },
    {
      name: '網路門市限定',
      id: 'd'
    },
    {
      name: '家用上網',
      id: 'e'
    },
    {
      name: '遠傳易付卡',
      id: 'f'
    },
    {
      name: '出國上網',
      id: 'g'
    },
    {
      name: '用戶專屬優惠',
      id: 'h'
    },
    {
      name: 'friDay優惠',
      id: 'i'
    },
  ],
};

export const a = [
  {
    id: 'topic-01',
    articleType: '4.5G語音+上網',
    title: '青春無價，就要給你學生價',
    img: 'https://placeimg.com/480/480/any',
    brief: '488上網標到飽，＋100把超夯手機、3C $0帶回家！',
    link: '#',
  },
  {
    id: 'topic-02',
    articleType: '4.5G語音+上網',
    title: '4.5G方案最低199 更快網速',
    img: 'https://placeimg.com/480/480/any',
    brief: '4.5G上網方案月付199~2699一超殺吃到飽特惠方案，讓你把手機、智慧3C、家電、平板等多種熱賣。',
    link: '#',
  },
  {
    id: 'topic-03',
    articleType: '4.5G語音+上網',
    title: '樂齡生活更有趣！輕鬆月付149起！',
    img: 'https://placeimg.com/480/480/any',
    brief: '月付149，想上網+通話優惠，再加50元家電手機0元帶走。',
    link: '#',
  },
  {
    id: 'topic-04',
    articleType: '4.5G語音+上網',
    title: '月付999 Galazy A30s/A51/A70 $0起，週週再抽任天堂Switch+健身環。',
    img: 'https://placeimg.com/480/480/any',
    brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
    link: '#',
  },
  {
    id: 'topic-05',
    ribbon: '網路限定優惠',
    articleType: '4.5G語音+上網',
    title: '網路門市本月限定，月付$499+$100，送無線快充充電盤。',
    img: 'https://placeimg.com/480/480/any',
    brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
    link: '#',
  },
];

export const productMap = {
  title: '我想尋找...',
  data: [
    {
      title: '找商品',
      list: [
        { text: '主打新機', link: '#', icon: 'chevron-right'},
        { text: '智慧家電', link: '#', icon: 'chevron-right'},
        { text: '出國遠遊卡', link: '#', icon: 'chevron-right'},
        { text: '福利品', link: '#', icon: 'chevron-right'},
      ],
    },
    {
      title: '找方案',
      list: [
        { text: '網路獨享資費', link: '#', icon: 'chevron-right'},
        { text: '自選門號', link: '#', icon: 'chevron-right'},
        { text: '本月續約方案', link: '#', icon: 'chevron-right'},
        { text: '家用寬頻', link: '#', icon: 'chevron-right'},
      ],
    },
    {
      title: '找網購服務',
      list: [
        { text: '訂單查詢', link: '#', icon: 'chevron-right'},
        { text: '訂單相關問題', link: '#', icon: 'chevron-right'},
        { text: '配送說明', link: '#', icon: 'chevron-right'},
        { text: '超商取貨說明', link: '#', icon: 'chevron-right'},
      ],
    },
    {
      title: '找幫助',
      list: [
        { text: '我要發問', link: '#', icon: 'chevron-right'},
        { text: '常見網購問題', link: '#', icon: 'chevron-right'},
        { text: '續約客服回撥', link: '#', icon: 'chevron-right'},
        { text: '手機平板操作手冊', link: '#', icon: 'chevron-right'},
      ],
    },
  ]
}

export const loadMoreData = [
  {
    id: 'topic-04',
    articleType: '4.5G語音+上網',
    title: '月付999 Galazy A30s/A51/A70 $0起，週週再抽任天堂Switch+健身環。',
    img: 'https://placeimg.com/640/480/any',
    brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
    link: '#',
  },
  {
    id: 'topic-05',
    articleType: '4.5G語音+上網',
    title: '網路門市本月限定，月付$499+$100，送無線快充充電盤。',
    img: 'https://placeimg.com/640/480/any',
    brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
    link: '#',
  },
];
