import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import scriptLoader from 'react-async-script-loader';

import Breadcrumb from '../../components/Breadcrumb';
import NavAnchor from '../../components/partials/NavAnchor';

import Select from '../../components/form/Select';
import LabelInput from '../../components/form/LabelInput';

import Button from '../../components/Button';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import FooterAd from '../../components/ad/FooterAd';


function USGSOverlay(bounds, image, map) {
  this.bounds_ = bounds;
  this.image_ = image;
  this.map_ = map;

  this.div_ = null;

  this.setMap(map);
}

class serviceCoveragePage extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.map = null;
    this.overlay = null;
    this.state = {
      showMore: false,
      canSubmit: false,
      form: {
        coverage: { value: 'UMTS上網涵蓋', required: true },
        city: { value: '', required: true },
        area: { value: '', required: true },
        keyword: { value: '', required: true },
      },
    };
  }

  static defaultProps = {
    center: { lat: 23.52423302157123, lng: 122.19314350206582 },
    zoom: 8,
  };

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        USGSOverlay.prototype = new window.google.maps.OverlayView();

        USGSOverlay.prototype.onAdd = function () {
          var div = document.createElement('div');
          div.style.border = 'none';
          div.style.borderWidth = '0px';
          div.style.position = 'absolute';

          var img = document.createElement('img');
          img.src = this.image_;
          img.style.width = '100%';
          img.style.height = '100%';
          div.appendChild(img);

          this.div_ = div;

          var panes = this.getPanes();
          panes.overlayImage.appendChild(this.div_);
        };

        USGSOverlay.prototype.draw = function () {
          var overlayProjection = this.getProjection();
          var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
          var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

          var div = this.div_;
          div.style.left = sw.x + 'px';
          div.style.top = ne.y + 'px';
          div.style.width = ne.x - sw.x + 'px';
          div.style.height = sw.y - ne.y + 'px';
        };

        USGSOverlay.prototype.toggleDOM = function () {
          if (this.getMap()) {
            this.setMap(null);
          } else {
            this.setMap(this.map_);
          }
        };

        const lat = 23.52423302157123;
        const lng = 122.19314350206582;

        this.map = new window.google.maps.Map(this.mapRef.current, {
          center: { lat: lat, lng: lng },
          mapTypeControl: false,
          zoom: 8,
          // icon: {
          //     origin: new window.google.maps.Point(130, 130),
          // },
        });

        var bounds = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(21.895068, 118.1542083),
          new window.google.maps.LatLng(26.3827966, 122.0195408)
        );

        // The photograph is courtesy of the U.S. Geological Survey.
        var srcImage = window.location.origin;
        srcImage += '/resources/e-service/images/coverage4G.png';
        
        this.overlay = new USGSOverlay(bounds, srcImage, this.map);
      }
    }
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    console.log(name, value)
    this.setState({
      form: newForm,
    });
  };

  disableButton = () => {
    const {form} = this.state;
    if(form.city.value !== '' || form.area.value !== '' || form.keyword.value !== '') {
      this.setState({
        canSubmit: true,
      });
    } else {
      this.setState({
        canSubmit: false,
      });
    }
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  submit = () => {
    if (this.state.form.payment.value === '1')
      this.props.history.push(`/voiceDepositController/voiceDepositByBankPage`);
    if (this.state.form.payment.value === '2')
      this.props.history.push(`/voiceDepositController/voiceDepositByCreditPage`);
  };

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }

  render() {
    const { form } = this.state
    return (
      <main>
        
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回首頁',
            link: '/',
          }}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '幫助中心', link: '#' },
            { text: '資訊與公告', link: '#' },
            { text: '網路涵蓋率查詢', link: '#' },
          ]}
        />
        <section className='coverage-section'>
          <div className='fui-container'>
            <h3>網路涵蓋率查詢</h3>
            <div className='search-bar'>
              <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={2}>
                    <Select
                      name='coverage'
                      label='UMTS上網涵蓋'
                      value={form.coverage.value}
                      options={[
                        { text: 'UMTS上網涵蓋', value: 'UMTS上網涵蓋' },
                        { text: '語音涵蓋', value: '語音涵蓋' },
                        { text: '4.5G LTE 上網涵蓋', value: '4.5G LTE 上網涵蓋' }
                      ]}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2}>
                    <Select
                      required={true}
                      name='city'
                      label='選擇縣市'
                      value={form.city.value}
                      options={[{ text: '台北市', value: '台北市' }]}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2}>
                    <Select
                      required={true}
                      name='area'
                      label='選擇區域'
                      value={form.area.value}
                      options={[{ text: '中正區', value: '中正區' }]}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <LabelInput 
                    label=''
                    required={true}
                    placeholder='ex.瑞光路'
                    name='keyword'
                    value={form.keyword.value}
                    onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <Button disabled={!(form.city.value !== '' || form.area.value !== '' || form.keyword.value !== '')} btnStyle='primary' size='large'>
                      查詢
                    </Button>
                  </Grid>
                </Grid>
              </Formsy>
            </div>
            <div className='map-contianer' ref={this.mapRef}>
              {/* <MapWithAnOverlayView 
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAEtGDgMxN3WjDZchzkMCNz3l2PWdPtKxM&v=3&libraries=geometry"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />} /> */}
            </div>
            {
              form.coverage.value === 'UMTS上網涵蓋' ? (
                <div className='fui-collapse coverage-list'>
                  <div className='collapse-body'>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            <div className='level-1'></div>
                          </th>
                          <th>
                            <div className='level-2'></div>
                          </th>
                          <th>
                            <div className='level-3'></div>
                          </th>
                          <th>
                            <div className='level-4'></div>
                          </th>
                          <th>
                            <div className='level-5'></div>
                          </th>
                        </tr>
                      </thead>

                      {this.state.showMore ? (
                        <tbody>
                          <tr>
                            <th>室外及室內涵蓋感受度</th>
                            <td>室外優 室內好</td>
                            <td>室外好 室內可</td>
                            <td>室外可 室內弱</td>
                            <td>有涵蓋但弱訊區</td>
                            <td>無涵蓋及弱訊區</td>
                          </tr>
                          <tr>
                            <th>下載服務速率 (理論最高值)</th>
                            <td>21Mbps涵蓋</td>
                            <td>7.2Mbps涵蓋</td>
                            <td>3.6Mbps涵蓋</td>
                            <td>1Mbps涵蓋</td>
                            <td>0-1Mbps涵蓋</td>
                          </tr>
                          <tr>
                            <th>下載服務連線速率參考值</th>
                            <td>512Kbps - 8640Kbps</td>
                            <td>512Kbps - 8640Kbps</td>
                            <td>384Kbps - 3600Kbps</td>
                            <td>16Kbps - 512Kbps</td>
                            <td>0Kbps - 16Kbps</td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <th>室外及室內涵蓋感受度</th>
                            <td>室外優 室內好</td>
                            <td>室外好 室內可</td>
                            <td>室外可 室內弱</td>
                            <td>有涵蓋但弱訊區</td>
                            <td>無涵蓋及弱訊區</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              ) : null
            }
            {
              form.coverage.value === '語音涵蓋' ? (
                <div className='fui-collapse coverage-list'>
                  <div className='collapse-body'>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th width="20%">
                            <div className='level-1'></div>
                          </th>
                          <th width="20%">
                            <div className='level-3'></div>
                          </th>
                          <th width="20%">
                            <div className='level-4'></div>
                          </th>
                          <th width="20%">
                            <div className='level-5'></div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>收訊品質	</th>
                          <td>優良</td>
                          <td>普通</td>
                          <td>尚可</td>
                          <td>劣</td>
                        </tr>
                        <tr>
                          <th>說明</th>
                          <td>通話穩定，語音清晰</td>
                          <td>通話穩定，偶有語音不清</td>
                          <td>通話尚可，部分語音不清</td>
                          <td>抗爭中</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null 
            }
            {
              form.coverage.value === '4.5G LTE 上網涵蓋' ? (
                <div className='fui-collapse coverage-list'>
                  <div className='collapse-body'>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            <div className='level-1'></div>
                          </th>
                          <th>
                            <div className='level-2'></div>
                          </th>
                          <th>
                            <div className='level-3'></div>
                          </th>
                          <th>
                            <div className='level-4'></div>
                          </th>
                          <th>
                            <div className='level-5'></div>
                          </th>
                        </tr>
                      </thead>

                      {this.state.showMore ? (
                        <tbody>
                          <tr>
                            <th>說明</th>
                            <td>室外優 室內好</td>
                            <td>室外好 室內可</td>
                            <td>室外可 室內弱</td>
                            <td>有涵蓋但弱訊區</td>
                            <td>無涵蓋及弱訊區</td>
                          </tr>
                          <tr>
                            <th>訊號數</th>
                            <td>4~5格</td>
                            <td>3~4格</td>
                            <td>2~3格</td>
                            <td>1~2格</td>
                            <td>0~1格</td>
                          </tr>
                          <tr>
                            <th>下載服務連線速率參考值</th>
                            <td>2Mbps~50Mbps</td>
                            <td>2Mbps~30Mbps</td>
                            <td>2Mbps~20Mbps</td>
                            <td>2Mbps~10Mbps</td>
                            <td>0Kbps~2Kbps</td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <th>室外及室內涵蓋感受度</th>
                            <td>室外優 室內好</td>
                            <td>室外好 室內可</td>
                            <td>室外可 室內弱</td>
                            <td>有涵蓋但弱訊區</td>
                            <td>無涵蓋及弱訊區</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              ) : null
            }
            
            {!this.state.showMore ? (
              <div className='align-center'>
                <div role='button' onClick={(e) => this.setState({ showMore: true })}>
                  點選看完整內容 <i className='icon-plus'></i>
                </div>
              </div>
            ) : null}
          </div>
        </section>
        <SectionCollapseInfo
          title='貼心小叮嚀'
          content={`
          1. 4.5G LTE行動上網涵蓋地圖呈現最後更新為2018/12/03。<br/>
          2. 若客戶於室內使用，因受建物遮蔽效應及室內裝潢影響，收訊品質及連線速率，可能會不如室外，其收訊品質及連線速率，有可能會較劣於室外，甚至收不到信號。 點此下載"<a href='#'>4G事業計畫書摘要及偏遠地區/公有建物涵蓋清單</a>"(建議使用Chrome瀏覽器開啟)。<br/>
          3. <a href='#'>行動上網測速</a>。<br/>
          4. 使用遠傳4.5G LTE網路需於4.5G LTE涵蓋範圍下，申請4.5G LTE資費方案並使用特定4.5G LTE終端設備。<br/>
          5. 行動通訊網路實際連線速率會因使用地點之地形、建物遮蔽、終端設備、客戶移動速度、使用人數或其他環境因素有所差異。建議用戶可透過客服查詢您經常使用地點之行動通訊網路涵蓋狀況。<br/>
          6. 本系統所提供之訊號涵蓋圖，係依據刊登當時基地台設置情形繪製，實際行動通訊涵蓋狀況及室內外之通訊品質，仍以現場實際測量為準。<br/>
          7. 建議使用IE9以上版本、Chrome或Firefox等瀏覽器。<br/>
          8. 4G用戶之語音服務未申請VoLTE則採用CSFB(Circuit Switched Fallback)技術，經實測並在95%的信心水準下，其通話接續時間約7~8秒。
        `}
        />
        <FooterAd
          image={{
            md: '/resources/e-service/images/cbu-promotion-banner-02-1920x156.jpg',
            sm: '/resources/e-service/images/cbu-promotion-banner-02-750x360.jpg',
          }}
          title='新學期 好學習 — 學生憑證享指定方案再折 $1,000'
          action={{
            text: '更多詳情',
            link: '#',
          }}
        />
      </main>
    );
  }
}

export default scriptLoader(
  [
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyAEtGDgMxN3WjDZchzkMCNz3l2PWdPtKxM'
  ])(
  serviceCoveragePage
);
