import React from 'react';
import PropTypes from 'prop-types';

class PanelTip extends React.Component {
    constructor(props) {
        super(props);
        this.body = React.createRef();
        this.state = {
            open: false,
            contentHeight: 0
        }

        this.toggleTip = this.toggleTip.bind(this)
        this.setContentHeight = this.setContentHeight.bind(this)
    }

    setContentHeight() {
        this.setState({
            contentHeight: this.body.current.children[0].clientHeight
        })
    }

    toggleTip() {
        this.setContentHeight()
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        return (
            <div className={`fui-tip is-collapse ${this.state.open ? 'is-open' : ''}`}>
                <div className="fui-tip-title" role="button" onClick={this.toggleTip}>
                    {this.props.title}
                </div>
                <div 
                ref={this.body} 
                className="fui-tip-body"
                style={{
                    height: this.state.open ? this.state.contentHeight : 0
                }}
                >
                    <div className="text" dangerouslySetInnerHTML={{__html: this.props.content}}></div>
                </div>
            </div>
        )
    }
}

PanelTip.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
}

export default PanelTip;