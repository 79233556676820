import { TYPES } from "../action";
import { emmaInitialState } from "../state";
export default function emmaReducer(state = emmaInitialState, action) {
  switch (action.type) {
    case TYPES.SET_EMMA_DATA:
      return Object.assign({}, state, { ...action.data });
    default:
      return state;
  }
}
