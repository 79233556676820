import React from 'react';
import {Parallax} from 'react-scroll-parallax';

const ArrowLeftWhite = (props) => {
    return (
        <Parallax className="animate-arrow-left" x={['-1050px', '-550px']}>
            <svg width="1644px" height="530px" viewBox="0 0 1644 530" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient x1="38.771879%" y1="66.102744%" x2="100%" y2="66.102744%" id="linearGradient-1">
                        <stop stopColor="#FFFFFF" offset="0%"></stop>
                        <stop stopColor="#EAEAEA" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="-4.01040435%" y1="2.83582263%" x2="85.7251691%" y2="78.0700462%" id="linearGradient-2">
                        <stop stopColor="#EFEFEF" offset="0%"></stop>
                        <stop stopColor="#FFFFFF" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g fillRule="nonzero">
                        <polygon fill="url(#linearGradient-1)" points="0 530 1644 530 1560.94155 371 0 371"></polygon>
                        <polygon fill="url(#linearGradient-2)" points="1196 206.00784 1644 530 1644 323.664122 1196 0"></polygon>
                    </g>
                </g>
            </svg>
        </Parallax>
    );
}

export default ArrowLeftWhite;