import axios from 'axios';

export const extraBuySubmit = async () => {
  // return axios('api/mock/extraBuyController/extraBuy').then(response => {
  //   return response.extraBuyResult
  // })

  return {
    "offerName": "4G上網計量包3GB",
    "price": "500"
  }
}

export const extraBuyInfo = async () => {
  // return axios('api/mock/extraBuyController/extraBuy').then(response => {
  //   return response.extraBuyResult
  // })

  return {
    extraBuyItemList: [
      {
        offerCode: '21345678',
        offerName: '4G上網計量包200MB',
        normalPrice: '200',
        discountPrice: '100',
        desc: '上網計量包200MB',
        hasDiscount: true,
      },
      {
        offerCode: '21345678',
        offerName: '4G上網計量包200MB',
        normalPrice: '300',
        discountPrice: null,
        desc: '上網計量包500MB',
        hasDiscount: false,
      },
      {
        offerCode: '21345678',
        offerName: '4G上網計量包1GB',
        normalPrice: '400',
        discountPrice: '300',
        desc: '上網計量包1GB',
        hasDiscount: true,
      },
      {
        offerCode: '21345678',
        offerName: '4G上網計量包3GB',
        normalPrice: '500',
        discountPrice: '400',
        desc: '上網計量包3GB',
        hasDiscount: true,
      },
    ],
    currentOffer: '',
    hasExtraBuy: true,
    unLimit: true,
  };
};
