import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import Link from '../../components/Link';

import ProgressBar from '../../components/ProgressBar';

import numberFormatter from '../../utils/numberFormatter'

import Modal from 'react-modal';
import SwitchCard from '../../components/partials/card/SwitchCard'
import ServiceEntry from '../../components/partials/card/ServiceEntry'
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';
import AppPromotion from '../../components/partials/AppPromotion';

import Button from '../../components/Button';

import * as Mock from '../../mock/RoamingPlan';

const progressList = [
  {
    title: '亞洲輕量包暢用型 2GB',
    range: '2020/09/12 10:00 - 2020/09/20 10:00',
    day: 9,
    total: 2048,
    use: 0
  },
  {
    title: '亞洲輕量包暢用型 2GB',
    range: '2020/09/12 10:00 - 2020/09/20 10:00',
    day: 9,
    total: 2048,
    use: 0
  },
  {
    title: '亞洲輕量包暢用型 2GB',
    range: '2020/09/12 10:00 - 2020/09/20 10:00',
    day: 9,
    total: 2048,
    use: 1896
  },
]

class SearchApplied extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      form: {
        type: { value: false },
      },
    }
  }

  render() {
    return (
      <main className="">
        <NavAnchor
          pageTitle='漫遊方案申請/查詢'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '漫遊方案申請/查詢', link: '' },
          ]}
          color='black'
        />

        <section className="roaming-plan roaming-plan-search">
          <div className="fui-container">
            <Grid>
              <Grid item sm={12}>
                <h2 className="mb-1">漫遊方案申請/查詢</h2>
                <h4 className="sub-head is-text-regular">
                  已申請：<span className="">1</span> 筆
                  <span className="action-arrow" onClick={e => this.setState({ modalOpen: true })} role='button'>
                    <span>查詢記錄/取消 <img src={'/resources/cbu/e-service/images/right.svg'} alt='' />
                    </span>
                  </span>
                </h4>
              </Grid>
            </Grid>
          </div>

          <div className="fui-container">

            <Grid container>
              <Grid item sm={12} md={12}>
                <div className="fui-cards w-100 is-roaming-plan-card">
                  <div className='fui-card'>
                    <div className="fui-card-action">
                      <div className="fui-card-content">
                        <h4 className="fui-card-title">韓國漫遊吃到飽</h4>
                        <p className="mt-0 mb-3">
                          使用期間：2020/05/26 17:30 - 2020/05/30 17:30 <br />
                          漫遊天數：4 天<br />
                          網路尚未使用
                        </p>
                        <div className='fui-card-extra'>
                          <Button btnStyle='secondary' size='' link="#" className="w-sm-100">查看明細</Button>
                        </div>
                        <div className="fui-card-tag">
                          <span className="applied">已申請</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fui-cards w-100 is-roaming-plan-card">
                  <div className='fui-card'>
                    <div className="fui-card-action">
                      <div className="fui-card-content">
                        {
                          progressList.map((progress, i) => (
                            <div className='fui-progress-box' key={`progress-container-${i}`}>
                              <h4 className="fui-card-title">{progress.title}</h4>
                              <div className="mb-3">
                                <div className="body1 d-desktop last-block">
                                  剩餘 <span className='last'>{progress.total - progress.use}MB</span>
                                </div>
                                <div className="body1">
                                  使用期限：{progress.range}<br />
                                  漫遊天數：{progress.day}天<br />
                                  共 {progress.total < 1000 ? progress.total + 'MB' : numberFormatter(progress.total / 1000, 2)}GB ({numberFormatter(progress.total)}MB) /
                                  已使用 {numberFormatter(progress.use / 1000, 2)}GB ({numberFormatter(progress.use)}MB)
                                </div>
                                <ProgressBar progress={numberFormatter(progress.use / progress.total * 100)} />

                                {numberFormatter(progress.use / progress.total * 100) > 90 ?
                                  <p className="is-text-darkgray50 notice">你的上網流量已達 90%，建議你可加購上網流量，以繼續使用網路。</p>
                                  : null
                                }
                                <div className="body1 d-mobile last-block">
                                  剩餘 <span className='last'>{progress.total - progress.use}MB</span>
                                </div>
                              </div>

                              { numberFormatter(progress.use / progress.total * 100) > 90 ?
                                <div className="fui-progress-box mb-3">
                                  <div className="fui-segments">
                                    <div className="fui-segment">
                                      <h4 className="fui-card-title">已加購500MB上網流量</h4>
                                      <div className="">
                                        <div className="body1 last-block">
                                          剩餘 <span className='last'>500MB</span>
                                        </div>
                                        <div className="body1">
                                          共 500 MB / 已使用 0.00 MB
                                        </div>
                                        <ProgressBar progress={0} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                : null
                              }


                              <div className='fui-card-extra'>
                                {numberFormatter(progress.use / progress.total * 100) > 90 ?
                                  <div className="button-group">
                                    <div className="two-buttons">
                                      <Button btnStyle='primary' size='' link="#">加購上網流量</Button>
                                      <Button btnStyle='secondary' size='' link="#" className="">查看明細</Button>
                                    </div>
                                  </div>
                                  : <Button btnStyle='secondary' size='' link="#" className="w-sm-100">查看明細</Button>
                                }
                              </div>
                            </div>
                          ))
                        }

                        <div className="fui-card-tag">
                          <span className="using">使用中</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="fui-cards w-100 is-roaming-plan-card">
                  <div className='fui-card'>
                    <div className="fui-card-action">
                      <div className="fui-card-content">
                        <h4 className="fui-card-title">計量7日型數據漫遊優惠費率</h4>
                        <p className="mt-0 mb-3">
                          使用期間：2020/05/11 10:00 - 2020/05/20 10:00<br />
                          漫遊天數：7 天<br />
                          網路尚未使用
                        </p>
                        <div className='fui-card-extra'>
                          <Button btnStyle='secondary' size='' link="#" className="w-sm-100">查看明細</Button>
                        </div>
                        <div className="fui-card-tag">
                          <span className="using">使用中</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fui-cards w-100 is-roaming-plan-card">
                  <div className='fui-card'>
                    <div className="fui-card-action">
                      <div className="fui-card-content">
                        <h4 className="fui-card-title">中港澳月租特殊型</h4>
                        <p className="mt-0 mb-3">
                          開始時間：2020/05/11 10:00<br />
                          漫遊天數：依實際使用天數而定<br />
                          網路尚未使用
                        </p>
                        <div className='fui-card-extra'>
                          <div className="button-group">
                            <div className="two-buttons">
                              <Button btnStyle='primary' size='' link="#" className="">聯絡客服</Button>
                              <Button btnStyle='secondary' size='' link="#" className="">查看明細</Button>
                            </div>
                          </div>
                        </div>
                        <div className="fui-card-tag">
                          <span className="applied">已申請</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </Grid>
            </Grid>

          </div>

          <div className="fui-container">
            <div className="fui-cards is-apply-card">
              <div className="fui-card">
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <h4 className="fui-card-title">漫遊上網方案申請</h4>
                    <p>超過 100 個優惠方案等你申請！</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Link to="#" className="action-arrow">
                      <span>立即申請 <img src={'/resources/cbu/e-service/images/right.svg'} alt='' />
                      </span>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>

          <SwitchCard {...Mock.switchCard} />

          <ServiceEntry {...Mock.serviceCard} />

          <SectionCarousel1 {...Mock.promotionCard} />

          <SectionFaqTab {...Mock.faqTab} />


          <AppPromotion
            bgImage='/resources/cbu/help-center/images/bg-all.png'
            title="遠傳行動客服 APP"
            subtitle="貼心服務不用等"
            description={`
              <p>
                以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
                <br />
                還有許多用戶獨享驚喜優惠
              </p>`
            }
            icon='/resources/cbu/help-center/images/img-logo-fet.png'
            android={{
              qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
              icon: '/resources/cbu/help-center/images/google-play.png',
              link: '/',
              title: '支援 Android 7 以上版本'
            }}
            ios={{
              qrCode: '/resources/cbu/help-center/images/qr-ios.png',
              icon: '/resources/cbu/help-center/images/app-store.png',
              link: '/',
              title: '支援 iOS 10 以上版本'
            }}
          />

        </section>

      </main>
    );
  }
}

export default SearchApplied;
