import React from 'react';
import { Grid } from '@material-ui/core';

import TooltipCard from '../../components/card/TooltipCard';
import AppPromotion from '../../components/partials/AppPromotion';
import ProductMap from '../../components/partials/ProductMap';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import Tooltip from '../../components/Tooltip';
import Collapse from '../../components/collapse/Collapse';
import Button from '../../components/Button';
import numberFormatter from '../../utils/numberFormatter'

import * as Mock from '../../mock/EServiceIndex';

const boards = [
  {
    title: 'Google Play/iTunes/Microsoft',
    list: [
      {
        label: '當月總額度',
        price: 8000
      },
      {
        label: '已使用額度',
        price: 8000
      },
      {
        label: '剩餘額度',
        price: 8000
      }
    ],
    hint: '可於Play商店、APP Store、Microsoft Store & XBOX，透過遠傳電信帳單付費購買您喜愛的APP、數位服務(電子書和電影等)、或是於APP內購買虛擬商品(LINE貼圖、遊戲點數等) ，並將該費用併入電信帳單出帳。',
    action: {
      text: '調整額度繳費',
      link: '/phoneBillPaySettingController/getPhoneBillPaySettingSelectAmountPage'
    }
  },
  {
    title: '其他小額代收',
    list: [
      {
        label: '當月總額度',
        price: 12000
      },
      {
        label: '已使用額度',
        price: 3000
      },
      {
        label: '剩餘額度',
        price: 9000
      }
    ],
    hint: '可使用於第三方遊戲點數購買(MyCard、GASH、Sonet、Garena…等)、或其他生活應用類服務(Pi拍錢包、line Music、ez訂…等)。',
    action: {
      text: '調整額度繳費',
      link: '/phoneBillPaySettingController/getPhoneBillPaySettingSelectAmountPage'
    }
  }
]

class getPhoneBillPaySettingInitPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0
    }
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單代收'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '帳單與繳費', link: '/pay-and-bill' },
          ]}
          color='black'
        />
        <div className='is-e-service-section'>
          <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-cbu-arrow-pattern.png'} alt='' className="arrow" />
          <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/bg-cbu-colorslash-pattern-1440x620.png'} alt='' className="bg d-none d-md-block" />
          <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/bg-cbu-colorslash-pattern-375x704.png'} alt='' className="bg d-block d-md-none" />
          <div className='fui-container'>
            <h2 className='my-0'>帳單代收管理</h2>
            <h4 className='mt-0 is-text-darkgray50'>進行帳單代收服務開關或重設交易密碼</h4>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TooltipCard title='小額代收設定' description='尚未開啟小額代收' tooltip='當開啟小額代收，你可使用與遠傳電信合作之小額商家服務購買數位內容，包含線上音樂、影音、遊戲虛寶儲值等商品。' action={{
                  text: '立即開啟', link: '/phoneBillPaySettingController/getPhoneBillPaySettingOpenPage', btnStyle: 'primary'
                }} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TooltipCard title='交易密碼設定' description='尚未設定（需先開啟小額代收）' tooltip='當你開啟小額付費功能系統將發送交易密碼簡訊，需自行輸入4~8位密碼；日後於服務端進行交易，透過此密碼進行交易驗證，即可完成服務購買。' action={{
                  text: '設定', link: '#', btnStyle: 'primary'
                }} />
              </Grid>
            </Grid>
          </div>
        </div>
        <section className="fui-section-board">
          <div className="fui-container">
            <h2 className="mb-0">當月代收額度</h2>
            <h4 className="subtitle is-text-darkgray50">
              查詢當月帳單代收額度及使用狀況，並可進行額度調整繳費
              <Tooltip
                parentNode={null}
                content={<i className='icon-information'></i>}
                trigger="click"
                tooltip={'當你當月代收額度用量已滿，但仍有使用上的需要，可先繳交部分已使用費用 (固定金額)，系統將為你恢復部分固定金額之使用額度，各服務額度分列，無法共用。'} />
            </h4>
            <div className="fui-segments">
              {
                boards.map((board, i) => (
                  <div className="fui-segment" key={`service-segmen-${i}`}>
                    <h4>{board.title}</h4>
                    <Grid container spacing={2} className='fui-boards mb-0'>
                      {
                        board.list.map((item, j) => (
                          <Grid item xs={12} sm={12} md={4} key={`service-segmen-${i}-item-${j}`}>
                            <div className="fui-board">
                              <h5>{item.label}</h5>
                              <h3>$ {numberFormatter(item.price)}</h3>
                            </div>
                          </Grid>
                        ))
                      }
                    </Grid>
                    <p className="body is-text-darkgray50 mb-md-8 mb-4">
                      {board.hint}
                    </p>
                    {
                      board.action ? (
                        <Button link={board.action.link} btnStyle='primary' size='large'>{board.action.text}</Button>
                      ) : null
                    }

                  </div>
                ))
              }
              <Collapse title="貼心小叮嚀" content={`
                <ol>
                  <li>當月總額度：當月代收服務同一身份證號下可使用之服務總額度，此交易總額度係依據交易量與繳費信用由系統自動調整，其可能因繳費狀況、季節活動促銷、額度調整繳費…等原因不定期異動。</li>
                  <li>已使用額度金額，可能因服務提供廠商批次請款時間差影響，其仍依實際交易為準。</li>
                  <li>取消交易之額度恢復，將待收到服務提供商通知後，立即為您恢復額度，其依各廠商執行時間略有不同，建議您24小時後，再行使用額度。</li>
                  <li>部分商品及交易，例訂閱定期收費性…等產品，會依各服務類型、交易狀況、交易量及交易收費頻率…等情況，放寬該筆交易額度，雖該筆交易度金額累計超過總額度，仍可完成交易。</li>
                  <li>當您當月交易已達額度上限時，將依交易量及信用評分結果，彈性提供最後一筆交易額度，故總額度不變下，本月使用額度將略高於總額度金額。</li>
                </ol>
              `} />
            </div>
          </div>
        </section>
        <SectionCarousel1 {...Mock.promotionCard} />
        <AppPromotion
          bgImage={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/appdownload-1920x400.png'}
          title="遠傳行動客服 APP"
          subtitle="貼心服務不用等"
          description={`
            <p>
              以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
              <br />
              還有許多用戶獨享驚喜優惠
            </p>`
          }
          icon={process.env.PUBLIC_URL + '/resources/cbu/help-center/images/img-logo-fet.png'}
          android={{
            qrCode: process.env.PUBLIC_URL + '/resources/cbu/help-center/images/qr-android.jpg',
            icon: process.env.PUBLIC_URL + '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 Android 7 以上版本'
          }}
          ios={{
            qrCode: process.env.PUBLIC_URL + '/resources/cbu/help-center/images/qr-android.jpg',
            icon: process.env.PUBLIC_URL + '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 Android 7 以上版本'
          }}
        />
        <ProductMap id='product-map' {...Mock.productMapData} />
      </main>
    );
  }
}

export default getPhoneBillPaySettingInitPage;
