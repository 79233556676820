import React from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      required: this.props.required || false,
      options: this.props.options,
      selected: this.props.default || null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.default) {
      this.props.setValue(this.props.default);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.default !== this.props.default) {
      this.setState({
        selected: this.props.default,
      });
    }
  }

  handleChange(event) {
    //   debugger
    if (event.currentTarget.checked) {
      this.setState({ selected: event.currentTarget.value });
      this.props.setValue(this.state.selected);
      this.props.onChange(this.props.name, event.currentTarget.value);
    }
  }

  render() {
    const errorMessage = this.props.getErrorMessage();
    return (
      <div className={`form-group ${this.props.className ? this.props.className : ''}`}>
        <h4 className={`${this.state.required ? 'is-required' : ''} heading-4`}>{this.props.label}</h4>

        <div className='radio-group'>
          {this.props.options.map((option, i) => (
            <label className='radio is-icon-box' key={`radio-${this.props.name}-${i}`}>
              <input
                type='radio'
                name={this.props.name}
                value={option.value}
                onChange={this.handleChange}
                defaultChecked={this.props.default === option.value}
              />
              <div className='content'>
                <h6 className=''>{option.label}</h6>
                <div style={{ lineHeight: '32px' }} className='body mt-2 d-table' dangerouslySetInnerHTML={{ __html: option.content }} />
              </div>
            </label>
          ))}
        </div>
        {!!errorMessage ? <span className='error-message'>{errorMessage}</span> : null}
      </div>
    );
  }
}

RadioGroup.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  default: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        default: PropTypes.string,
        selected: PropTypes.string,
      }),
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
};

export default withFormsy(RadioGroup);
