import React from 'react';
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types';

class CollapsePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEn: false,
            open: false,
            collapseHeight: 0
        }
        this.contentBody = React.createRef();
        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.setContentHeight = this.setContentHeight.bind(this)
    }

    componentDidUpdate () {
        if((typeof window !== 'undefined' && window.$isEn) !== this.state.isEn) {
            this.setState({
                isEn: typeof window !== 'undefined' && window.$isEn
            })
        }
    }

    setContentHeight() {
        this.setState({
            collapseHeight: this.contentBody.current.children[0].clientHeight
        })
    }

    toggleCollapse() {
        this.setContentHeight()
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        return (
            <div className={`fui-panel is-collapse ${this.state.open ? 'is-open' : ''}`}>
                <h4 className="fui-panel-title">{this.props.title}</h4>
                <article className="fui-panel-body" dangerouslySetInnerHTML={{ __html: this.props.description }}></article>
                <div className="fui-collapse-body"
                    ref={this.contentBody}
                    style={{ height: this.state.open ? this.state.collapseHeight : 0 }}>
                    <Grid container spacing={5}>
                        <Grid item md={4}>
                            <h3>{this.props.collapseTitle}</h3>
                        </Grid>
                        <Grid item md={8}>
                            {
                                /* container type: /panelContent/PanelTitle1 | panelContent/PanelTitle2 | panelContent/PanelContent **/
                            }
                            {this.props.children}
                        </Grid>
                    </Grid>
                </div>
                <div className="fui-collapse-action">
                    <button className="fui-button" onClick={this.toggleCollapse}>
                        <span className="text">{!this.state.open ? this.state.isEn ? 'More' : '看更多內容' : this.state.isEn ? 'Less' : '收合'}</span>
                        <i className={`icon-${!this.state.open ? 'plus' : 'minus'}`}></i>
                    </button>
                </div>
            </div>
        )
    }
}

CollapsePanel.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    collapseTitle: PropTypes.string.isRequired,
    children: PropTypes.any
}

export default CollapsePanel;