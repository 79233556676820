import React from 'react';
import Sldier from 'react-slick';
import Button from '../../Button';
import Link from '../../Link';
import PropTypes from 'prop-types';

class LifeCirclePromotion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      sliderSetting: {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
      },
    };
  }

  componentDidMount() {
    // TODO: 取 arrowBtn 的方式建議優化
    let arrowBtn = document.getElementsByClassName('slick-arrow');
    let indexPromo = document.getElementsByClassName('fui-lifecircle-promotion');
    
    if (indexPromo.length > 0 && arrowBtn[2] && arrowBtn[3]) {
      arrowBtn[2].setAttribute('id', 'lifecircle-0-1');
      arrowBtn[3].setAttribute('id', 'lifecircle-0-2');

      arrowBtn[2].addEventListener("click", (event) => {
        // [AA Tracking]
        if (window && typeof(window.ctrf) === 'function') window.ctrf(event || {}, `Tab_左`);
      });
      arrowBtn[3].addEventListener("click", (event) => {
        // [AA Tracking]
        if (window && typeof(window.ctrf) === 'function') window.ctrf(event || {}, `Tab_右`);
      });
    }
  }

  updateSlider = (oldIndex, newIndex) => {
    this.setState({
      currentSlide: newIndex,
    });
  };

  render() {
    return (
      <section className='fui-lifecircle-promotion'>
        <div className='fui-container'>
          <h2>{this.props.title}</h2>
          <Sldier {...this.state.sliderSetting} beforeChange={this.updateSlider}>
            {this.props.slider.map((slide, i) => (
              <Link
                to={slide.action.link}
                target={slide.action.target}
                key={`fui-lifecircle-promotion-${i}`}
                id={`lifecircle-${i + 1}-1`}
                onClick={() => {
                  // [AA Tracking]
                  if (window && window.s_objectID !== undefined) window.s_objectID = `${slide.title}`;
                }}>
                <div className='bg'>
                  <img src={slide.image.md} className='d-none d-sm-block' alt={slide.alt} />
                  <img src={slide.image.sm} className='d-block d-sm-none' alt={slide.alt} />
                </div>
                <div className='caption'>
                  <h2>{slide.title}</h2>
                  <h6>{slide.description}</h6>
                  <Button reverse={true} btnStyle='secondary' size='large'>
                    {slide.action.text}
                  </Button>
                </div>
              </Link>
            ))}
          </Sldier>
          <div className='fui-lifecycle-icons'>
            {this.props.slider.map((slide, i) => (
              <div
                className={`lifecycle-icon-container ${this.state.currentSlide === i ? 'fade-in' : ''}`}
                key={`lifecycle-icon-${i}`}
                id={`lifecircle-${i + 1}-2`}>
                {slide.icons.map((item, j) => (
                  <Link to={item.link} target={item.target} key={`lifecycle-icon-${i}-${j}`} className='lifecycle-icon' 
                  onClick={() => {
                    // [AA Tracking]
                    if (window && window.s_objectID !== undefined) window.s_objectID = `數位加值服務`;
                  }}>
                    <div className='icon'>
                      <img src={item.img} alt={item.alt} />
                    </div>
                    <div className='content'>
                      <h6>{item.text}</h6>
                      <div className='body2 is-text-gray100'>{item.description}</div>
                    </div>
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

LifeCirclePromotion.propTypes = {
  title: PropTypes.string,
  slider: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      alt: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      action: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
        target: PropTypes.string,
      }),
      icons: PropTypes.arrayOf(
        PropTypes.shape({
          img: PropTypes.string,
          alt: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          target: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    })
  ),
};

export default LifeCirclePromotion;
