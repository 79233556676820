import React from 'react';
import NavAnchor from '../../components/partials/NavAnchor';

import Modal from 'react-modal';
import Link from '../../components/Link';
import FormBanner from '../../components/partials/banner/FormBanner';
import Button from '../../components/Button';

class Cancel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
  }

  openModal = () => {
    this.setState({
      modalOpen: true
    })
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊方案申請/查詢'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="取消漫遊上網方案"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="form-section roaming-plan no-bg">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-4 mb-lg-6">請確認欲取消的漫遊上網方案內容</h4>

            <div className="fui-table-response mb-2 mb-lg-4">
              <table className="confirm-table">
                <tbody>
                  <tr>
                    <th>方案</th>
                    <td>韓國漫遊吃到飽</td>
                  </tr>
                  <tr>
                    <th>適用國家</th>
                    <td>韓國</td>
                  </tr>
                  <tr>
                    <th>使用期間</th>
                    <td>2020/05/26 17:30 - 2020/05/30 17:30 </td>
                  </tr>
                  <tr>
                    <th>天數</th>
                    <td>4 天</td>
                  </tr>
                  <tr>
                    <th>網路流量</th>
                    <td>上網吃到飽</td>
                  </tr>
                  <tr>
                    <th>預估費用</th>
                    <td>$396</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="button-group mt-5">
              <div className="form-group two-buttons">
                <Button btnStyle='primary' size='large' onClick={() => { this.setState({ modalOpen: true }) }}>取消方案</Button>
                <Button btnStyle='secondary' size='large'>回上一頁</Button>
              </div>
            </div>

          </div>
        </section>

        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5 className='align-center'>成功取消</h5>
            <p className='mt-0 mb-3 align-center'>你已成功取消方案</p>
            <div className="fui-buttons two-button justify-center">
              <Button btnStyle='primary' className=''>重新申請</Button>
              <Button btnStyle='secondary' onClick={() => { this.setState({ modalOpen: false }) }}>回申請記錄</Button>
            </div>
          </div>
        </Modal>

      </main>
    )
  }
}

export default Cancel;