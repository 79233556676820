import React from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';

const FooterAd = props => {
  const [isMd, setIsMd] = React.useState(typeof window !== 'undefined' && window.innerWidth >= 768);

  React.useEffect(() => {
    if(typeof window !== 'undefined') {
      window.onresize = () => {
        setIsMd(window.innerWidth >= 768);
      };
    }
  });

  return (
    <div
      className='fui-footer-promo'
      style={{
        backgroundImage: `url(${isMd ? props.image.md : props.image.sm})`,
      }}>
      <Link to={props.action.link} target={props.action.target}>
        <div className='promotion-container'>
          <div className="content">
            <h4 className='fui-section-promo-title' dangerouslySetInnerHTML={{ __html: props.title }}></h4>
          </div>
          <div className='action'>
            <div className='promotion-button'>
              {props.action.text}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

FooterAd.propTypes = {
  image: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  title: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    target: PropTypes.string,
    btnStyle: PropTypes.string, // only 'primary' or 'secondary
  })
};

export default FooterAd;
