import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import Modal from 'react-modal';
import FormBanner from '../../components/partials/banner/FormBanner';
import PanelFigure1 from '../../components/panelContent/PanelFigure1';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';

class Warranty extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      isMobile: window.innerWidth < 960
    }
    this.state = {
      step: 1,
      canSubmit: false,
      modalOpen: false,
      currentModal: 'ios',
      detail: {
        head: {
          title: 'IMEI(手機序號)',
          content: '352008099891704'
        },
        body: [
          {
            title: '保固截止日',
            content: '2018/12/21'
          },
          {
            title: '廠牌',
            content: 'SAMSUNG'
          },
          {
            title: '機型',
            content: 'Samsung GALAXY Note 8'
          },
          {
            title: '保險資格',
            content: '無'
          }
        ],
      },
      form: {
        imei: { value: '', required: true },
      },
    };
  }
  componentDidMount() {
    window.addEventListener('resize', e => {
      this.setState({
        isMobile: window.innerWidth < 960
      })
    })
  }
  submit = () => {
    if (this.state.form.imei.value === '352008099891704') {
      this.setState({
        step: 2,
        currentModal: 'notValidCode'
      })
    } else {
      this.setState({
        step: 1,
        modalOpen: true,
        currentModal: 'notValidCode'
      })
    }

  }
  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onChange = (n, v) => {
    let form = Object.assign(this.state.form);
    form[n].value = v;

    this.setState({
      form: form,
    });
  };

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title='保固查詢'
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className={this.state.step === 1 ? 'd-block' : 'd-none'}>
          <div className='fui-container'>
            <div className="paper">
              <h4 className='mt-md-4'>請輸入欲查詢的門號</h4>
              <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                <div className="">
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <LabelInput
                        className="mb-0 hide-required"
                        name='imei'
                        required={this.state.form.imei.required}
                        label='IMEI碼'
                        validations={{
                          // idNumberValid: Validation.idNumberValid
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入IMEI碼'
                        }}
                        placeholder='請輸入IMEI碼'
                        value={this.state.form.imei.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <small className="is-text-gray100 d-block mb-md-0 mt-2">全台遠傳門市/全虹維修站提供維修代收服務，不限通路所銷售手機、平板，原廠授權，原廠零件，品質保證。<br />※小米商品僅限遠傳販售</small>
                  </Grid>
                </div>
                <div className='page-action'>
                  <Button btnStyle='primary' size='large' onClick={this.submit} disabled={!this.state.canSubmit}>
                    查詢
                </Button>
                </div>
                <p className="is-text-gray50">
                  測試提示：輸入"352008099891704"<br />
                  可看到5.11.0.5_幫助中心_保固查詢_有查詢資料結果
                </p>
              </Formsy>
            </div>
            <div className="paper mb-0">
              <h4 className='mt-md-4 mb-0'>如何查詢手機IMEI說明</h4>
              <p className='mb-md-7 mb-4'>※提醒：此項功能無法支援Apple產品保固資訊查詢，需請透過Apple官網進行查詢，謝謝。</p>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <div className="d-flex mb-md-3">
                    <div style={{ maxWidth: this.state.isMobile ? 130 : 160 }} className="d-block">
                      <img className='w-100' src="/resources/cbu/img/dial-query@2x.png" alt="dial-query" />
                    </div>
                    <div className="d-flex flex-column justify-center">
                      <h4 className='mb-0'>撥號查詢</h4>
                      <p className="mt-0 is-text-darkgray70 is-text-medium">撥號畫面輸入＊# 06 #查詢</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="d-flex mb-md-3">
                    <div style={{ maxWidth: this.state.isMobile ? 130 : 160 }} className="d-block">
                      <img className='w-100' src="/resources/cbu/img/phone-sim-card@2x.png" alt="phone-sim-card" />
                    </div>
                    <div className="d-flex flex-column justify-center">
                      <h4 className='mb-0'>SIM卡卡匣</h4>
                      <p className="mt-0 is-text-darkgray70 is-text-medium">從 SIM 卡匣上查詢 IMEI</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="d-flex mb-md-3">
                    <div style={{ maxWidth: this.state.isMobile ? 130 : 160 }} className="d-block">
                      <img className='w-100' src="/resources/cbu/img/phone-body@2x.png" alt="phone-body" />
                    </div>
                    <div className="d-flex flex-column justify-center">
                      <h4 className='mb-0'>機身或電蓋上</h4>
                      <p className="mt-0 is-text-darkgray70 is-text-medium">從手機機身或電蓋上查詢IMEI</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="d-flex">
                    <div style={{ maxWidth: this.state.isMobile ? 130 : 160 }} className="d-block">
                      <img className='w-100' src="/resources/cbu/img/phone-setting@2x.png" alt="phone-setting" />
                    </div>
                    <div className="d-flex flex-column justify-center">
                      <h4 className='mb-1'>手機系統查詢</h4>
                      <a style={{ width: 'max-content' }} onClick={() => { this.setState({ modalOpen: true, currentModal: 'ios' }) }} className="is-text-medium underline-link m-0 is-text-black50 d-table">
                        Apple iPhone
                      </a>
                      <a style={{ width: 'max-content' }} onClick={() => { this.setState({ modalOpen: true, currentModal: 'android' }) }} className="is-text-medium underline-link m-0 is-text-black50 d-table">
                        Android
                      </a>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </section>
        <section className={this.state.step === 2 ? 'd-block' : 'd-none'}>
          <div className='fui-container'>
            <div className="paper mb-0">
              <h4 className='mt-md-4'>保固狀態說明</h4>
              <table className="text-wrap bill-table mt-0 mb-md-4 odd">
                <tbody>
                  <tr>
                    <th className="">{this.state.detail.head.title}</th>
                    <td className="">{this.state.detail.head.content}</td>
                  </tr>
                  {this.state.detail.body.map((item) => {
                    return (
                      <tr key={item.title}>
                        <th className="">
                          {item.title}
                        </th>
                        <td className="">{item.content}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </section>
        {this.state.step === 2 ? <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            1. 請消費者保留購買証明或原廊保国卡,做為辦鲜保固之佐証資料<br/>
            2. 上述查詢的保国载止日期並非保險到期日,有開保險到期日請洽原購買門市<br/>
            3. 上述保險資格為查詢時的保險狀態,賽際保險資格仍視您送修商品時的保險狀態而定
          `}
        /> : null}
        <Modal
          onAfterOpen={e => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={e => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel="Example Modal"
          className={`fui-prompt-modal ${this.state.currentModal === 'notValidCode' ? '' : 'is-large'}`}>
          {this.state.currentModal === 'android' ? <main className="prepaid flex-align-center d-flex" style={{ margin: "0 auto" }}>
            <div id="howTo" className="fui-prompt-modal-body">
              <h5>Android 系統查詢 IMEI</h5>
              <div className="scrollable-modal-body is-bg-white pb-0">
                <p className='mb-4'>從手機[設定] >[系統] >[關於手機] >[狀態] > [IMEI資訊] 功能中查詢</p>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/android-imei-step-1@2x.jpg' caption='Step 1' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/android-imei-step-2@2x.jpg' caption='Step 2' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/android-imei-step-3@2x.jpg' caption='Step 3' />
                  </Grid>
                </Grid>
              </div>
              <div className="page-action mb-2">
                <Button btnStyle='primary' onClick={() => this.setState({ modalOpen: false, currentModal: '' })}>我瞭解了</Button>
              </div>
            </div>
          </main> : null}
          {this.state.currentModal === 'ios' ? <main className="prepaid flex-align-center d-flex" style={{ margin: "0 auto" }}>
            <div id="howTo" className="fui-prompt-modal-body">
              <h5>Apple iPhone 手機系統查詢 IMEI</h5>
              <div className="scrollable-modal-body is-bg-white pb-0">
                <p className='mb-4'>從手機[設定] > [一般] > [關於本機]功能中查詢。</p>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/ios-imei-step-1@2x.jpg' caption='Step 1' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/ios-imei-step-2@2x.jpg' caption='Step 2' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/ios-imei-step-3@2x.jpg' caption='Step 3' />
                  </Grid>
                </Grid>
              </div>
              <div className="page-action mb-2 mt-0">
                <Button btnStyle='primary' onClick={() => this.setState({ modalOpen: false, currentModal: '' })}>我瞭解了</Button>
              </div>
            </div>
          </main> : null}
          {this.state.currentModal === 'notValidCode' ? <div className='fui-prompt-modal-body'>
            <h5>非遠傳、全虹出貨</h5>
            <p className='mb-6 mt-0'>查無保固資訊，若非遠傳、全虹出貨，請直接至遠傳門市或全虹維修中心送修</p>
            <div className="mb-0 mt-0">
              <Button className='mb-0' btnStyle='primary' onClick={() => this.setState({ modalOpen: false, currentModal: '' })}>確定</Button>
            </div>
          </div> : null}
        </Modal>
      </main>
    );
  }
}

export default Warranty;
