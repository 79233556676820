import React from 'react';
import Link from './Link';
import PropTypes from 'prop-types';

const Button = (props) => {
  const handleClick = (e) => {
    if (props.onClick) props.onClick(e);
  };

  const combileClass = () => {
    let className = 'fui-button ';
    if (props.btnStyle) className += `is-${props.btnStyle} `;
    if (props.size) className += `is-${props.size} `;
    if (props.reverse) className += `is-reverse `;
    if (props.className) className += props.className;

    return className;
  };

  return props.link ? (
    <Link
      to={props.link}
      className={combileClass()}
      onClick={handleClick}
      disabled={props.disabled}
      target={props.target || '_self'}>
      <span className='text'>{props.children}</span>
      {props.btnStyle === 'text' ? <i className='icon-chevron-right'></i> : ''}
    </Link>
  ) : (
    <button className={combileClass()} onClick={handleClick} disabled={props.disabled}>
      <span className='text'>{props.children}</span>
      {props.btnStyle === 'text' ? <i className='icon-chevron-right'></i> : ''}
    </button>
  );
};

Button.propTypes = {
  link: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  btnStyle: PropTypes.string, // primary | secondary | text | arrow | label | tag
  size: PropTypes.string, // large | small
  reverse: PropTypes.bool, // default false
  disabled: PropTypes.bool, // default false
  onClick: PropTypes.func, //
};

export default Button;
