import { TYPES } from '../action';
import { formInitialState } from '../state';
export default function formReducer(state = formInitialState.formPayload, action) {
  switch (action.type) {
    case TYPES.SEND_FORM_GENDER_DATA:
      return Object.assign({}, state, { ...action.payload });
    case TYPES.SEND_FORM_SELECT_DATA:
      return Object.assign({}, state, { ...action.payload });
    case TYPES.SEND_FORM_RECAPTCHA:
      return Object.assign({}, state, { robot: action.payload });
    case TYPES.SEND_FORM_AGREETEXT:
      return Object.assign({}, state, { AGREE_TEXT_1: action.payload });
    case TYPES.SEND_FORM_INPUT_DATA:
      return Object.assign({}, state, { ...action.payload });
    case TYPES.SEND_FORM_CUSTOM_DATA:
      return Object.assign({}, state, { ...action.payload });
    default:
      return state;
  }
}
