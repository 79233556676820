import React from 'react';
import Link from '../Link';
import PropTypes from 'prop-types';

const Card = props => {
  const [isEn, setIsEn] = React.useState(false);

  React.useEffect(() => {
    setIsEn(typeof window !== 'undefined' && window.$isEn);
  }, []);

  const renderCardContent = () => {
    return (
      <Link className='fui-card-action' to={props.link}>
        {props.image ? (
          <div className='fui-card-image'>
            <img src={props.image} srcSet={props.retinaImage || props.image} alt={props.meta} />
          </div>
        ) : (
          ''
        )}
        <div className='fui-card-caption'>
          <div className='fui-card-content'>
            {!props.public_at ? '' : <div className='fui-card-date'>{props.public_at}</div>}
            {!props.meta ? '' : <div className='fui-card-meta'>{props.meta}</div>}
            <h4 className='fui-card-title'>
              {!props.icon ? (
                ''
              ) : (
                <div className='icon'>
                  <img src={props.icon} alt={props.meta} />
                </div>
              )}
              <div className='text'>{props.title}</div>
            </h4>
            {!props.description ? (
              ''
            ) : (
              <h6 className='fui-card-description' dangerouslySetInnerHTML={{ __html: props.description }}></h6>
            )}
          </div>
        </div>
      </Link>
    );
  };

  return <div className={`fui-card fui-card-life-circle ${props.className ? props.className : ''}`}>{renderCardContent()}</div>;
};

Card.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  retinaImage: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};

export default Card;
