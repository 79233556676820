import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Dropdown from '../../components/Dropdown';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';

let numberService = {
  hasPagination: true,
  table: {
    head: [
      '異動項目',
      '遺失補卡',
      '遺失補卡',
      '帳單地址/電話變更',
      '遺失補卡',
      '遺失補卡',
      '掛失服務',
      '恢復通話',
      '遺失補卡',
      '預約門號',
      '語音信箱設定',
      '語音信箱：開啟<br/>未接通狀態宣告：關閉<br/>標準歡迎詞版本：精簡<br/>來電留言語音提醒：開啟',
    ],
    content: [
      ['異動日期', '異動時間', '異動內容', '處理結果'],
      ['2020/06/15', '17:55', '申請補卡', '成功'],
      ['2020/06/15', '17:54', '申請補卡', '成功'],
      ['2020/06/15', '17:53', '申請補卡', '成功'],
      ['2020/06/15', '12:16', '修改「帳單地址」', '成功'],
      ['2020/05/27', '17:55', '申請補卡', '成功'],
      ['2020/05/25', '17:08', '申請卡片掛失', '成功'],
      ['2020/05/25', '17:04', '申請補卡', '成功'],
      ['2020/05/15', '17:55', '申請補卡', '成功'],
      [
        '2020/03/17',
        '10:03',
        '申請計量7日型數據漫遊優惠費率，生效日期2020/05/13 23:00，結束日期2020/05/20 23:00',
        '成功',
      ],
      ['2020/01/31', '10:38', '申請補卡', '成功'],
      ['2020/01/31', '10:37', '申請補卡', '成功'],
    ],
  },
};
let billService = {
  hasPagination: true,
  table: {
    head: [
      '2020/01/14 16:27:09',
      '2020/01/14 16:27:09',
      '2020/01/14 16:27:09',
      '2020/01/14 16:27:09',
      '2020/01/14 16:27:09',
      '2020/01/14 16:27:09',
      '2020/01/14 16:27:09',
      '2020/01/14 16:27:09',
      '2020/01/14 16:27:09',
      '2020/01/14 16:27:09',
      '2020/01/14 16:27:09',
    ],
    content: [
      ['異動事項'],
      ['變更帳單遞送方式-電子Email帳單'],
      ['電子郵件信箱更改'],
      ['電子郵件信箱更改'],
      ['電子郵件信箱更改'],
      ['電子郵件信箱更改'],
      ['電子郵件信箱更改'],
      ['電子郵件信箱更改'],
      ['電子郵件信箱更改'],
      ['電子郵件信箱更改'],
      ['電子郵件信箱更改'],
    ],
  },
};

let pointService = {
  hasPagination: true,
  table: {
    head: [
      '兌換日',
      '2020/06/15',
      '2020/06/15',
      '2020/06/15',
      '2020/06/15',
      '2020/05/27',
      '2020/05/25',
      '2020/05/25',
      '2020/05/22',
      '2020/05/25',
      '2020/05/25',
    ],
    content: [
      ['贈品', '數量', '門號', '扣抵點數'],
      ['線上續約折扣金$500', '1', '0916123456', '12'],
      ['線上續約折扣金$500', '1', '0916123456', '36'],
      ['線上續約折扣金$1,000', '1', '0916123456', '24'],
      ['線上續約折扣金$500', '1', '0916123456', '12'],
      ['線上續約折扣金$750', '1', '0916123456', '24'],
      ['線上續約折扣金$850', '1', '0916123456', '12'],
      ['線上續約折扣金$1,000', '1', '0916123456', '12'],
      ['線上續約折扣金$750', '1', '0916123456', '24'],
      ['線上續約折扣金$850', '1', '0916123456', '12'],
      ['線上續約折扣金$500', '1', '0916123456', '24'],
    ],
  },
};
let parkingService = {
  hasPagination: true,
  table: {
    head: [
      '日期',
      '2020/06/15',
      '2020/06/15',
      '2020/06/15',
      '2020/06/15',
      '2020/05/27',
      '2020/05/25',
      '2020/05/25',
      '2020/05/22',
      '2020/05/25',
      '2020/05/25',
    ],
    content: [
      ['車輛種類', '車牌號碼', '申請狀態', '代收地區'],
      ['汽車', '8656-YB', '申請成功', '高雄市路邊停車'],
      ['汽車', '8656-YB', '申請成功', '新北市路邊停車'],
      ['汽車', '8656-YB', '申請成功', '台中市路邊停車'],
      ['汽車', '8656-YB', '申請成功', '台南市路邊停車'],
      ['汽車', '8656-YB', '申請成功', '台北市路邊停車'],
      ['汽車', '8656-YB', '申請成功', '桃園市路邊停車'],
      ['汽車', '8656-YB', '申請成功', '嘉義市路邊停車'],
      ['汽車', '8656-YB', '申請成功', '新竹市路邊停車'],
      ['汽車', '8656-YB', '申請成功', '彰化縣路邊停車'],
      ['汽車', '8656-YB', '申請成功', '新竹市路邊停車'],
    ],
  },
};
let emptyTable = {
  table: [],
};
class ChangeRecord extends React.Component {
  constructor(props) {
    super(props);
    this.tableHead = React.createRef();
    this.tableBody = React.createRef();
    this.state = {
      titleHeight: [],
      getTitleLength: [],
      isMobile: window.innerWidth < 960,
      recordType: '門號相關服務',
      currentTable: numberService,
      noData: '查無異動紀錄。',
      parkingType: '停車費代收相關',
    };
  }
  componentDidMount() {
    this.getTitleLength();
    window.addEventListener('resize', this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({
      isMobile: window.innerWidth < 960,
    });
  };
  cleanTable = () => {
    this.setState({
      currentTable: emptyTable,
    });
  };
  onChangePage(pageOfItems, selectedPage) {
    // this.props.history.push(`${this.props.location.pathname}?keyword=${this.state.keyword}&page=${selectedPage}`);
    // update page of items
    // this.updatePageOfItems(pageOfItems);
    // console.log('on change');
  }

  selectedParkingType = (val) => {
    this.setState({
      parkingType: val,
    });
  };
  selectedType = (val) => {
    // console.log(val);
    this.setState({
      recordType: val,
    });
    switch (val) {
      case '門號相關服務':
        this.setState({
          currentTable: numberService,
          noData: '查無異動紀錄。',
        });
        break;
      case '電子帳單申請及變更':
        this.setState({
          currentTable: billService,
          noData: '查無電子帳單申請及變更記錄。',
        });
        break;
      case '電子帳單集點兌換':
        this.setState({
          currentTable: pointService,
          noData: '查無兌換紀錄。',
        });
        break;
      case '停車費車輛異動紀錄':
        this.setState({
          currentTable: parkingService,
          noData: '查無停車費車輛異動紀錄。',
        });
        break;
      default:
        this.setState({
          currentTable: numberService,
          noData: '查無異動紀錄。',
        });
        break;
    }
    this.forceUpdate();
    setTimeout(() => {
      this.getTitleLength();
    }, 100);
  };
  getTitleLength = () => {
    let length = 0
    let content = this.state.currentTable.table.content;
    let head = this.state.currentTable.table.head;
    // console.log(this.state.content);
    // console.log(this.state.head);
    let store = []
    if (!!content > 0) {
      content.map((row, i) => {
        row.map((target, j) => {
          let result = target.replace(/[^\x00-\xff]/g, "xx").length;
          length = length < result ? result : length
          // console.log(`length: `, length);
        })
        store.push({
          row: i,
          height: length < 25 ? 52 : Math.floor((length / 24 * 32) + 33),
          length: length
        })
        length = 0
      })
      head.map((header, i) => {
        let result = header.replace(/[^\x00-\xff]/g, "xx").length;
        if (store[i].length < result) {
          store[i].height = result < 6 ? 52 : (Math.floor((result / 24 * 32) + 33))
        }
      })
      // console.log(`store: `, store);
      this.setState({
        titleHeight: store
      })
    }
  }
  getHeight = (i) => {
    let data = this.state.titleHeight;
    if (data.length > 0 && !!data[i]) {
      return data[i].height;
    } else {
      return 0;
    }
  };
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='交易/異動紀錄'
          button={{
            text: '回個人專區',
            link: '#',
          }}
        />
        <FormBanner
          title='門號/服務異動記錄'
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className='usage-rate mt-md-7 mt-4 no-bg'>
          <div className='fui-container'>
            <div className='fui-segments no-shadow is-bg-initial'>
              <h4 className='is-text-darkgray50 mb-md-7 mb-5'>提供您查詢門號與服務異動記錄。</h4>
              <div className='paper mt-md-4 mt-2 mb-0 pb-md-3 pb-4'>
                <div className='d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-3 mb-3'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={this.state.recordType == '停車費車輛異動紀錄' ? 4 : 8}>
                      <h4 className='m-0'>近一個月記錄</h4>
                    </Grid>
                    {this.state.recordType == '停車費車輛異動紀錄' ? (
                      <Grid item xs={12} md={4}>
                        <Dropdown
                          className='is-button ml-0'
                          list={[{ value: '停車費代收相關', text: '停車費代收相關' }]}
                          label={this.state.parkingType}
                          arrow={true}
                          hasCheck={false}
                          onChange={(item) => this.selectedParkingType(item)}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12} md={4}>
                      <div className='float-right w-100'>
                        <Dropdown
                          className='is-button ml-0'
                          list={[
                            { value: '門號相關服務', text: '門號相關服務' },
                            { value: '電子帳單申請及變更', text: '電子帳單申請及變更' },
                            { value: '電子帳單集點兌換', text: '電子帳單集點兌換' },
                            { value: '停車費車輛異動紀錄', text: '停車費車輛異動紀錄' },
                          ]}
                          label={this.state.recordType}
                          arrow={true}
                          hasCheck={false}
                          onChange={(item) => this.selectedType(item)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {!!this.state.currentTable.table.head ? (
                  <div>
                    {Object.keys(this.state.currentTable.table).length === 0 ? (
                      <div>
                        <div className='body is-text-darkgray50 mb-md-5 mb-0'>{this.state.noData}</div>
                      </div>
                    ) : (
                        <div className='compareTable content-16 clearfix text-wrap bill-table mt-0 mb-md-2 w-200 stick-to-right'>
                          <div className='tableLeft'>
                            <div className='tableHead'>
                              <table className='text-wrap' ref={this.tableHead}>
                                <tbody>
                                  {this.state.currentTable.table.head.map((item, i) => {
                                    return (
                                      <tr
                                        style={{ height: this.getHeight(i) - (this.state.isMobile ? 6 : 0) }}
                                        className={`${i == 0 ? 'is-bg-lightgray70' : ''}`}
                                        key={item + i}>
                                        <td
                                          className={`align-center ${i == 1 ? 'is-text-medium' : ''}`}
                                          dangerouslySetInnerHTML={{ __html: item }}
                                        />
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className='tableRight w-200'>
                            <div className='tableBody'>
                              <div className='spaceBox'>
                                <table className='text-wrap' ref={this.tableBody}>
                                  <tbody>
                                    {this.state.currentTable.table.content.map((list, i) => (
                                      <tr
                                        style={{ height: this.getHeight(i) - (this.state.isMobile ? 6 : 0) }}
                                        key={list + i}
                                        className={i == 0 ? 'is-bg-lightgray70' : ''}>
                                        {list.map((item, j) => (
                                          <td key={item + j}>{item}</td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {this.state.currentTable.hasPagination ? (
                      <Pagination
                        items={['1', '2', '3', '4']}
                        onChangePage={this.onChangePage}
                        initialPage={1}
                        pageSize={2}
                      />
                    ) : null}
                  </div>
                ) : (
                    <div>
                      {this.state.currentTable.table.length > 0 ? (
                        <div className='fui-table-response'>
                          <table className='table-gray-head'>
                            <tbody>
                              {this.state.currentTable.table.map((row, i) => {
                                return (
                                  <tr key={i}>
                                    {row.map((item, j) => {
                                      return (
                                        <td key={item + j} width='50%'>
                                          {item}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          {this.state.currentTable.hasPagination ? (
                            <Pagination
                              items={['1', '2', '3', '4']}
                              onChangePage={this.onChangePage}
                              initialPage={1}
                              pageSize={2}
                            />
                          ) : null}
                        </div>
                      ) : (
                          <div className='body is-text-darkgray50 mb-md-5 mb-0'>{this.state.noData}</div>
                        )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </section>
        {/* remove in production */}
        <div className='switcher py-5'>
          <div className='fui-container'>
            <div className='d-flex flex-column'>
              <p>測試用資料切換</p>
              <Button
                onClick={() => this.cleanTable()}
                link='#'
                className='mb-lg-0 mb-md-2 mb-0'
                btnStyle='secondary'
                size='large'>
                無資料
              </Button>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ChangeRecord;
