import React from 'react';
import {Grid} from '@material-ui/core';
import PropTypes from 'prop-types';

class ImageContent extends React.Component {
  render () {
    return (
      <section className="fui-image-content">
        <div className="fui-container">
          <Grid container direction={this.props.imgPlace === 'left' ? 'row-reverse' : 'row'} spacing={5}>
            <Grid item xs={12} sm={6} md={6}>
              <h3>
                <div className='subtitle is-text-darkgray50'>
                  {this.props.meta}
                </div>
                {this.props.title}
              </h3>
              <div dangerouslySetInnerHTML={{__html: this.props.content}}></div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <img src={process.env.PUBLIC_URL + this.props.img} srcSet={process.env.PUBLIC_URL + (this.props.retinaImage || this.props.img)} alt="" />
            </Grid>
          </Grid>
        </div>
      </section>
    )
  }
}

ImageContent.propTypes = {
  img: PropTypes.string,
  retinaImg: PropTypes.string,
  meta: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.any,
  imgPlace: PropTypes.string // left || right
}

export default ImageContent