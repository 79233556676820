import React, { Component } from 'react'
import TabPane from '../../tab/TabPane';
import PropTypes from 'prop-types'
import Slider from 'react-slick';
import Button from '../../Button';
class CarouselWithTag extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.programSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 3,
      draggable: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 1,
            variableWidth: true,
          },
        },
      ],
    };
  }
  renderButton = (button) => {
    if (button) {
      return (
        button.length > 1 ? <div className="form-group two-buttons">
          <Button btnStyle='primary' size='medium' link={button[0].link}>{button[0].title}</Button>
          <Button btnStyle='secondary' size='medium' link={button[1].link}>{button[1].title}</Button>
        </div> : <div className="form-group mt-2 mb-0">
            <Button btnStyle='primary' size='medium' link={button[0].link}>{button[0].title}</Button>
          </div>
      )
    }
  }
  render() {
    return (
      <section className='fui-solution-tab foreign-prepaid'>
        <div className='tab-content'>
          {
            <TabPane active={true}>
              <div className='fui-container'>
                <h1 id={this.props.id}>{this.props.title}</h1>
                <Slider {...this.programSetting} className='horizontal-cards'>
                  {this.props.cards.map((card, idx) => (
                    <div key={idx} className='fui-card is-card-program'>
                      {card.tag ? <div className={`tag tag-${card.tag.color}`}>{card.tag.text}</div> : null}
                      <img src={process.env.PUBLIC_URL + card.icon} alt={card.iconAlt} />
                      <h3 className="mb-0">{card.title}</h3>
                      <h5 className="align-center m-0 is-text-black50" dangerouslySetInnerHTML={{ __html: card.subTitle }}></h5>
                      <ul className={!!card.button ? '' : 'pb-0'}>
                        {card.list.map(item => (
                          <li key={item}>
                            <i className="icon-check is-text-accent" />
                            <div className='subtitle is-text-darkgray50'>{item}</div>
                          </li>
                        ))}
                      </ul>
                      {this.renderButton(card.button)}
                    </div>
                  ))}
                </Slider>
              </div>
            </TabPane>
          }
        </div>
      </section>
    )
  }
}

CarouselWithTag.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  cards: PropTypes.array
}
export default CarouselWithTag;