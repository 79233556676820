import React from 'react';

import { Grid } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';

import NavAnchor from '../components/partials/NavAnchor';
// import Button from '../components/Button';
const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place) => {
    bounds.extend(new maps.LatLng(
      place.geometry.location.lat,
      place.geometry.location.lng,
    ));
  });
  return bounds;
};
const Marker = ({ text }) => <div className="marker"></div>;
const apiIsLoaded = (map, maps, places) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};

const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};


class StoreFinder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      places: [],
    }
  }
  static defaultProps = {
    center: { lat: 25.046618, lng: 121.517907 },
    zoom: 13
  };
  componentDidMount() {
    this.setState({
      places: [
        {
          name: '台北車站直營店',
          id: '001',
          geometry: {
            location: {
              lat: 25.046618,
              lng: 121.517907
            }
          }
        },
        {
          name: '台北館前直營店',
          id: '002',
          geometry: {
            location: {
              lat: 25.045560,
              lng: 121.515106
            }
          }
        },
        {
          name: '南投彰南直營店',
          id: '003',
          geometry: {
            location: {
              lat: 23.880898,
              lng: 120.691864
            }
          }
        },
      ]
    });
  }
  // _onClick = ({ x, y, lat, lng, event }) => console.log(x, y, lat, lng, event)

  onDrag = (map, maps, places) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };
  render() {
    const { places } = this.state
    return (
      <main>
        
        <NavAnchor
          pageTitle='帳單代收'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <section className="store">
          <div className="fui-container">
            <Grid>
              <h1>Store</h1>
              <div className="map">
                <GoogleMapReact
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}
                  yesIWantToUseGoogleMapApiInternals
                  bootstrapURLKeys={{ key: 'AIzaSyAEtGDgMxN3WjDZchzkMCNz3l2PWdPtKxM' }}
                // onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
                // onClick={this._onClick}
                // onDrag={this.onDrag}
                >
                  {places.map(place => (
                    <Marker
                      key={place.id}
                      text={place.name}
                      lat={place.geometry.location.lat}
                      lng={place.geometry.location.lng}
                    />
                  ))}
                </GoogleMapReact>
              </div>
            </Grid>
          </div>
        </section>
      </main>
    );
  }
}

export default StoreFinder;