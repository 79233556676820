import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
// import Breadcrumb from '../../components/Breadcrumb';
import Link from '../../components/Link';

import ProgressBar from '../../components/ProgressBar';
import numberFormatter from '../../utils/numberFormatter'


import Modal from 'react-modal';
import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import Button from '../../components/Button';

import * as Mock from '../../mock/RoamingPlan';

const progressList = [
  {
    title: '亞洲輕量包暢用型 2GB',
    range: '2020/09/12 10:00 - 2020/09/20 10:00',
    day: 9,
    total: 2048,
    use: 1896
  },
]

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      form: {
        type: { value: false },
      },
    }
  }

  render() {
    return (
      <main className="">
        <NavAnchor
          pageTitle='漫遊方案申請/查詢'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <FormBanner
          title="漫遊上網方案申請"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        {/* <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '漫遊方案申請/查詢', link: '' },
          ]}
          color='black'
        /> */}

        <section className="roaming-plan pb-0 no-bg">
          <div className="fui-container">
            <Grid>
              <Grid item sm={12}>
                <h3 className="mb-4">近一年記錄</h3>
                {/* <h3 className="sub-head">
                  已申請：<span className="">1</span> 筆
                  <span className="action-arrow" onClick={e => this.setState({ modalOpen: true })} role='button'>
                    <span>查詢記錄/取消 <img src={'/resources/cbu/e-service/images/right.svg'}  alt='' />
                    </span>
                  </span>
                </h3> */}
              </Grid>
            </Grid>
          </div>

          <div className="fui-container">

            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <div className="fui-cards w-100 is-roaming-plan-card is-detail">
                  <div className='fui-card'>
                    <div className="fui-card-action">
                      <div className="fui-card-content">
                        <h4 className="fui-card-title">韓國漫遊吃到飽</h4>
                        <p className="mt-0 mb-3">
                          使用期間：2020/05/26 17:30 - 2020/05/30 17:30 <br />
                          漫遊天數：4 天<br />
                          網路尚未使用
                        </p>
                        <div className='fui-card-extra'>
                          <Button btnStyle='secondary' size='' link="#">再次申請</Button>
                        </div>
                        <div className="fui-card-tag">
                          <span className="applied">已申請</span>
                          <span className="using">使用中</span>
                          <span className="over">已結束</span>
                          <span className="cancel">已取消</span>
                        </div>
                      </div>
                    </div>
                    <SectionCollapseInfo
                      title="方案明細"
                      content={`
                        <div class="fui-table-response mb-2 mb-lg-4">
                          <table class="history-detail-table">
                            <tbody>
                              <tr>
                                <th>方案</th>
                                <td>韓國漫遊吃到飽</td>
                              </tr>
                              <tr>
                                <th>適用國家</th>
                                <td>韓國</td>
                              </tr>
                              <tr>
                                <th>使用期間</th>
                                <td>2020/05/11 10:00 - 2020/05/20 10:00</td>
                              </tr>
                              <tr>
                                <th>天數</th>
                                <td>4 天</td>
                              </tr>
                              <tr>
                                <th>網路流量</th>
                                <td>上網吃到飽</td>
                              </tr>
                              <tr>
                                <th>預估費用</th>
                                <td>$396</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        
                        <div class="mt-3 mb-1 is-text-gray100 text">※欲查看方案注意事項，請至<a href='#' target="_blank" class="is-text-black50">各國漫遊費率</a>介紹查詢</div>
                        <div class="mb-3 mb-1 is-text-gray100 text">※如有漫遊方案相關問題，可撥空前往<a href='#' target="_blank" class="is-text-black50">遠傳門市</a>、撥打客服電話 (國內) <a href='tel:0800058885' target="_blank" class="is-text-black50">0800-058-885</a> ; (國外) <a href='tel:+886936010888' target="_blank" class="is-text-black50">+886936010888</a>、或也可透過<a href='#' target="_blank" class="is-text-black50">客服即時通</a>查詢唷！</div>
                        <div class="fui-buttons">
                          <a rel="noopener" class="fui-button is-secondary is-small is-text-sm" target="_self" href="#"><span class="text">取消申請</span></a>
                        </div>
                      `}
                    />
                  </div>
                </div>

                <div className="fui-cards w-100 is-roaming-plan-card is-detail">
                  <div className='fui-card'>
                    <div className="fui-card-action">
                      <div className="fui-card-content">
                        {
                          progressList.map((progress, i) => (
                            <div className='fui-progress-box' key={`progress-container-${i}`}>
                              <h4 className="fui-card-title">{progress.title}</h4>
                              <div className="mb-3">
                                <div className="body1 d-desktop last-block">
                                  剩餘 <span className='last'>{progress.total - progress.use}MB</span>
                                </div>
                                <div className="body1">
                                  使用期限：{progress.range}<br />
                                  漫遊天數：{progress.day}天<br />
                                  共 {progress.total < 1000 ? progress.total + 'MB' : numberFormatter(progress.total / 1000, 2)}GB ({numberFormatter(progress.total)}MB) /
                                  已使用 {numberFormatter(progress.use / 1000, 2)}GB ({numberFormatter(progress.use)}MB)
                                </div>
                                <ProgressBar progress={numberFormatter(progress.use / progress.total * 100)} />

                                {numberFormatter(progress.use / progress.total * 100) > 90 ?
                                  <p className="is-text-darkgray50 notice">你的上網流量已達 90%，建議你可加購上網流量，以繼續使用網路。</p>
                                  : null
                                }
                                <div className="body1 d-mobile last-block">
                                  剩餘 <span className='last'>{progress.total - progress.use}MB</span>
                                </div>
                              </div>

                              { numberFormatter(progress.use / progress.total * 100) > 90 ?
                                <div className="fui-progress-box mb-3">
                                  <div className="fui-segments">
                                    <div className="fui-segment">
                                      <h4 className="fui-card-title">已加購500MB上網流量</h4>
                                      <div className="">
                                        <div className="body1 last-block">
                                          剩餘 <span className='last'>500MB</span>
                                        </div>
                                        <div className="body1">
                                          共 500 MB / 已使用 0.00 MB
                                        </div>
                                        <ProgressBar progress={0} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                : null
                              }


                              <div className='fui-card-extra'>
                                {numberFormatter(progress.use / progress.total * 100) > 90 ?
                                  <Button btnStyle='primary' size='' link="#">加購上網流量</Button>
                                  : null
                                }
                                <Button btnStyle='secondary' size='' link="#">再次申請</Button>
                              </div>
                            </div>
                          ))
                        }

                        <div className="fui-card-tag">
                          <span className="using">使用中</span>
                        </div>
                      </div>
                    </div>
                    <SectionCollapseInfo
                      title="方案明細"
                      content={`
                        <div class="fui-table-response mb-2 mb-lg-4">
                          <table class="history-detail-table">
                            <tbody>
                              <tr>
                                <th>方案</th>
                                <td>亞洲輕量包暢用型 2GB</td>
                              </tr>
                              <tr>
                                <th>適用國家</th>
                                <td>新加坡、韓國、香港、澳門、馬來西亞、泰國</td>
                              </tr>
                              <tr>
                                <th>使用期間</th>
                                <td>2020/05/11 10:00 - 2020/05/20 10:00</td>
                              </tr>
                              <tr>
                                <th>天數</th>
                                <td>9 天</td>
                              </tr>
                              <tr>
                                <th>網路流量</th>
                                <td>2GB</td>
                              </tr>
                              <tr>
                                <th>預估費用</th>
                                <td>$259</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="is-text-darkgray50 mt-5 mb-3 is-text-medium text">加購流量明細</div>
                        <div class="fui-table-response mb-2 mb-lg-4">
                          <table class="history-detail-table">
                            <tbody>
                              <tr>
                                <th>方案</th>
                                <td>亞洲輕量包暢用型 500MB</td>
                              </tr>
                              <tr>
                                <th>適用國家</th>
                                <td>新加坡、韓國、香港、澳門、馬來西亞、泰國</td>
                              </tr>
                              <tr>
                                <th>使用期間</th>
                                <td>原方案的上網流量使用完畢後，立即生效</td>
                              </tr>
                              <tr>
                                <th>天數</th>
                                <td>10 天</td>
                              </tr>
                              <tr>
                                <th>網路流量</th>
                                <td>500MB</td>
                              </tr>
                              <tr>
                                <th>預估費用</th>
                                <td>$99</td>
                              </tr>
                              <tr>
                                <th>申請時間</th>
                                <td>2020/05/01</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="mt-3 mb-1 is-text-gray100 text">※欲查看方案注意事項，請至<a href='#' target="_blank" class="is-text-black50">各國漫遊費率</a>介紹查詢</div>
                        <div class="mb-3 mb-1 is-text-gray100 text">※如有漫遊方案相關問題，可撥空前往<a href='#' target="_blank" class="is-text-black50">遠傳門市</a>、撥打客服電話 (國內) <a href='tel:0800058885' target="_blank" class="is-text-black50">0800-058-885</a> ; (國外) <a href='tel:+886936010888' target="_blank" class="is-text-black50">+886936010888</a>、或也可透過<a href='#' target="_blank" class="is-text-black50">客服即時通</a>查詢唷！</div>
                        <div class="fui-buttons">
                          <a rel="noopener" class="fui-button is-secondary is-small is-text-sm" target="_self" href="#"><span class="text">取消加購</span></a>
                        </div>
                      `}
                    />
                  </div>
                </div>


                <div className="fui-cards w-100 is-roaming-plan-card is-detail">
                  <div className='fui-card'>
                    <div className="fui-card-action">
                      <div className="fui-card-content">
                        <h4 className="fui-card-title">計量7日型數據漫遊優惠費率</h4>
                        <p className="mt-0 mb-3">
                          申請時間：2020/03/15 10:00 於遠傳行動客服 APP 申請<br />
                          使用期間：2020/03/21 10:00 - 2020/05/30 10:00 <br />
                          漫遊天數：7 天<br />
                          網路已使用完畢
                        </p>
                        <div className='fui-card-extra'>
                          <Button btnStyle='secondary' size='' link="#">再次申請</Button>
                        </div>
                        <div className="fui-card-tag">
                          <span className="over">已結束</span>
                        </div>
                      </div>
                    </div>
                    <SectionCollapseInfo
                      title="方案明細"
                      content={`
                        <div class="fui-table-response mb-2 mb-lg-4">
                          <table class="history-detail-table">
                            <tbody>
                              <tr>
                                <th>方案</th>
                                <td>計量7日型數據漫遊優惠費率</td>
                              </tr>
                              <tr>
                                <th>適用國家</th>
                                <td>愛爾蘭</td>
                              </tr>
                              <tr>
                                <th>使用期間</th>
                                <td>2020/05/11 10:00 - 2020/05/20 10:00</td>
                              </tr>
                              <tr>
                                <th>天數</th>
                                <td>7 天</td>
                              </tr>
                              <tr>
                                <th>網路流量</th>
                                <td>依實際使用狀況而定</td>
                              </tr>
                              <tr>
                                <th>預估費用</th>
                                <td>$259</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      `}
                    />
                  </div>
                </div>


                <div className="fui-cards w-100 is-roaming-plan-card is-detail">
                  <div className='fui-card'>
                    <div className="fui-card-action">
                      <div className="fui-card-content">
                        <h4 className="fui-card-title">中港澳月租特殊型</h4>
                        <p className="mt-0 mb-3">
                          申請時間：2020/03/15 10:00 於遠傳行動客服 APP 申請<br />
                          開始時間：2020/02/11 10:00<br />
                          漫遊天數：依實際使用天數而定<br />
                          網路尚未使用
                        </p>
                        <div className='fui-card-extra'>
                          <Button btnStyle='secondary' size='' link="#">聯絡客服</Button>
                        </div>
                        <div className="fui-card-tag">
                          <span className="cancel">已取消</span>
                        </div>
                      </div>
                    </div>
                    <SectionCollapseInfo
                      title="方案明細"
                      content={`
                        <div class="fui-table-response mb-2 mb-lg-4">
                          <table class="history-detail-table">
                            <tbody>
                              <tr>
                                <th>方案</th>
                                <td>計量7日型數據漫遊優惠費率</td>
                              </tr>
                              <tr>
                                <th>適用國家</th>
                                <td>阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國</td>
                              </tr>
                              <tr>
                                <th>使用期間</th>
                                <td>2020/05/11 10:00 - 2020/05/20 10:00</td>
                              </tr>
                              <tr>
                                <th>天數</th>
                                <td>7 天</td>
                              </tr>
                              <tr>
                                <th>網路流量</th>
                                <td>依實際使用狀況而定</td>
                              </tr>
                              <tr>
                                <th>預估費用</th>
                                <td>$259</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      `}
                    />
                  </div>
                </div>

              </Grid>
            </Grid>

            <div className="mt-3 mb-6">
              <Link to="#" className="">
                <img src={'/resources/cbu/e-service/images/arrow-left.svg'} alt='' className="align-middle" />回漫遊主頁
              </Link>
            </div>

          </div>


          <SectionCollapseInfo
            title="貼心小叮嚀"
            content={`
            1. 本方案啟動後即收取方案全額費用，無法退費。申裝前，請先確認旅行目的地屬於優惠適用國家，以使用優惠上網。<br>
2. 方案生效期間，上網量提前用完則方案等同提前到期，於適用國家將無法上網。方案終止後，未用完之上網量將歸零不得遞延。申請亞洲輕量包暢用型生效後，若經使用無法申請退費或取消，無比例分攤收費。<br>
3. 『亞洲輕量包暢用型1GB』於用戶申辦並收到優惠生效簡訊後連續10日(連續240小時有效)享1GB優惠; 『亞洲輕量包暢用型2GB』於用戶申辦並收到優惠生效簡訊後連續10日(連續240小時有效)享2GB優惠，若於到期日前上網優惠傳輸量已用完，將無法於該優惠適用國家繼續使用上網功能。可選擇申辦加價購服務，以回復上網功能。加價購方式：當漫遊上網傳輸使用達70%及98%時，系統會發送提醒通知，可回傳簡訊直接購買亞洲輕量包暢用型500MB/ NT$99。收到簡訊回覆1，即可加購(回傳加購簡訊不收費)。10日到期後方案終止，未用完之傳輸量將歸零且不得要求遞延、折現或為其他主張。<br>
4. 若用戶門號安裝「取消雙向簡訊加購傳輸量服務」，於國外使用亞洲輕量包暢用型，亦無法透過70% & 98%雙向簡訊進行用量加購。<br>
5. 提醒您，出國前可先關閉手機、App等自動更新程式，以避免程式自動更新，使用到您所購買的定量包上網傳輸量。<br>
6. 以上優惠方案僅適用於特定漫遊國家，用戶需於漫遊國使用漫遊行動上網才享優惠，於非優惠國家(含船舶、航空)使用漫遊上網服務依公告之一般國際漫遊上網費率收費。<br>7. 最新優惠適用國家可洽客服專線+886936010888 。用戶於全球撥打客服中心專線免費<br>
8. 因經營成本及其他相關因素之變動，遠傳保留修改或終止本優惠費率與優惠活動之權利，詳細費率、優惠費率適用期間及活動內容以遠傳網站公告為主。<br>
          `}
          />

        </section>
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5 className='align-center'>關閉漫遊通話設定？</h5>
            <p className='mt-0 align-center'>關閉漫遊通話設定後，將無法從國外撥打電話回台灣或其他國家，同時也會關閉國際直撥功能。你確定要關閉漫遊通話設定？</p>
            <div className="fui-buttons two-button justify-center">
              <Button btnStyle='primary' className=''>重新申請</Button>
              <Button btnStyle='secondary' onClick={() => { this.setState({ modalOpen: false }) }}>關閉</Button>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}

export default History;
