import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import Select from '../../components/form/Select';
import Tooltip from '../../components/Tooltip';
import Breadcrumb from '../../components/Breadcrumb';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import RadioGroup from '../../components/form/RadioGroup';
import TextInput from '../../components/form/TextInput';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';

import * as Validation from '../../utils/validation';
class AutoBill extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef()
    this.creditGroup = React.createRef();
    this.state = {
      currentPayment: '自行繳款',
      step: 0,
      formStep01: false,
      formStep02: false,
      formStep03: false,
      selectPayment: '',
      form: {
        number: {
          value: '',
        },
        numberName: {
          value: '',
        },
        numberRegister: {
          value: '',
        },
        payment: {
          value: '自行繳款',
          text: '自行繳款'
        },
        account: {
          value: '',
          text: ''
        },
        registerName: {
          value: '',
          text: ''
        },
        id: {
          value: '',
          text: ''
        },
        credit_number_1: {
          value: '',
          text: ''
        },
        credit_number_2: {
          value: '',
          text: ''
        },
        credit_number_3: {
          value: '',
          text: ''
        },
        credit_number_4: {
          value: '',
          text: ''
        },
        credit_month: {
          value: '',
          text: ''
        },
        credit_year: {
          value: '',
          text: ''
        },
        creditCardName: {
          value: '',
          text: ''
        },
        creditcardId: {
          value: '',
          text: ''
        },
      }
    }
  }
  onChange = (name, value) => {
    // console.log({ name, value });
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }
  enableButton = (val) => {
    // console.log(`val: `, val);
    this.setState({
      [val]: true
    })
  };
  disableButton = (val) => {
    this.setState({
      [val]: false
    })
  };
  selectPayment = (val) => {
    this.setState({
      selectPayment: val
    })
  }
  componentDidMount() {
    let creditInputs = Array.from(this.creditGroup.current.getElementsByTagName('input'));
    creditInputs.map((input, i) => {
      this.setInputFilter(input, function (value) {
        return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
      });

      input.addEventListener('keyup', e => {
        let index = creditInputs.indexOf(e.target);
        // console.log('input:' + index)
        if (e.currentTarget.value.length === 4 && index < 3) {
          creditInputs[index + 1].focus();
        }
      })
    })
  }
  setInputFilter = (textbox, inputFilter) => {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }
  getCreditStatus = () => {
    let form = this.state.formStep03
    let date = this.state.form.credit_month.value && this.state.form.credit_year.value
    let card = this.state.form.credit_number_1.value && this.state.form.credit_number_2.value && this.state.form.credit_number_3.value && this.state.form.credit_number_4.value
    return form && date && card
  }
  scrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }
  render() {
    return (
      <div>
        {this.state.step == 0 ? <main>
          <Header />
          <NavAnchor
            pageTitle='繳費項目'
            button={{
              text: '立即續約',
              link: '#',
            }}
          />
          <Breadcrumb
            color='black'
            breadcrumb={[
              { text: '個人首頁', link: '/' },
              { text: '優惠', link: '/cbu/' },
              { text: '自動扣繳申請', link: '/cbu/auto-bill' },
            ]}
          />
          <section className="ribbon-bg pb-md-7 pb-10">
            <div className='fui-container'>
              <h1 className='mt-md-7 mb-2'>自動扣繳申請</h1>
              <h4 className="is-text-darkgray50">
                設定每個月自動扣繳服務，讓您不用擔心忘記繳費！遠傳自動從您指定的銀行或信用卡完成扣款，不用任何手續費！安全又方便！
          </h4>
              <div className="paper mb-0">
                <ul className="step-list">
                  <li className='pb-md-7'>
                    <div className="circle h-100">1</div>
                    <div className="d-inline">
                      <h5 className='pt-1 mb-1 mt-0'>選擇扣款方式</h5>
                      <h6>請先登入會員，線上申辦就不須再寄回紙本申請書。</h6>
                    </div>
                  </li>
                  <li className='pb-md-7'>
                    <div className="circle h-100">2</div>
                    <div className="d-inline">
                      <h5 className='pt-1 mb-1 mt-0'>填寫資料</h5>
                      <h6>使用行動電話本人銀行帳戶或信用卡，依序填入資料確認無誤後送出。</h6>
                    </div>
                  </li>
                  <li className='pb-md-3'>
                    <div className="circle h-100">3</div>
                    <div className="d-inline">
                      <h5 className='pt-1 mb-1 mt-0'>確認資料，完成申請</h5>
                      <h6>申請成功後，將發送簡訊至您的門號，提醒您生效時間。</h6>
                    </div>
                  </li>
                </ul>
                <div className="page-action">
                  <Button onClick={() => { this.setState({ step: 1 }); this.scrollToTop() }} className='' btnStyle='primary' size='large'>立即申請</Button>
                  <Button className='mb-0' btnStyle='secondary' size='large' link="#">下載空白申請書</Button>
                </div>
              </div>
            </div>
          </section>
        </main> : null}

        {this.state.step == 1 ? <main>
          <Header />
          <NavAnchor
            pageTitle='繳費項目'
            button={{
              text: '立即續約',
              link: '#',
            }}
          />
          <FormBanner
            title="自動扣繳申請"
            image={{
              md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
              sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
            }}
          />
          <section>
            <div className="fui-container">
              <Formsy onValid={() => this.enableButton('formStep01')} onInvalid={() => this.disableButton('formStep01')} ref={this.form}>
                <h4 className="is-text-darkgray50 mb-2">選擇扣繳方式</h4>
                <div className='is-text-accent text-sm'>
                  <span className='required-dot'></span> 必填
                </div>
                <Grid container spacing={2} className='mt-md-5'>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <label className="is-required">
                        門號
                      </label>
                      <TextInput
                        type='tel'
                        name='number'
                        required={true}
                        validationError={"請輸入門號"}
                        value={this.state.form.number.value}
                        onChange={val => this.onChange('number', val)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className='mt-md-5'>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <label className="is-required">
                        門號登記姓名
                      </label>
                      <TextInput
                        type='tel'
                        name='numberName'
                        required={true}
                        validationError={"請輸入門號"}
                        value={this.state.form.numberName.value}
                        onChange={val => this.onChange('numberName', val)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className='mt-md-5'>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <label className="is-required">
                        門號登記證號
                      </label>
                      <TextInput
                        type='tel'
                        name='numberRegister'
                        required={true}
                        validationError={"請輸入門號"}
                        value={this.state.form.numberRegister.value}
                        onChange={val => this.onChange('numberRegister', val)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className='mt-md-5 mb-md-7'>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <label>
                        目前繳費方式
                      </label>
                      <div className="fui-textarea is-bg-white px-2 py-3">
                        {this.state.currentPayment}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <RadioGroup
                  label='請選擇繳款方式'
                  name="payment"
                  options={[
                    { label: '銀行帳號', value: '1' },
                    { label: '信用卡', value: '2' }
                  ]}
                  default={'1'}
                  onChange={this.onChange}
                />
              </Formsy>
              <div className="page-action mt-md-9">
                <Button onClick={() => { this.setState({ step: 2 }); this.scrollToTop() }} className={this.state.formStep01 ? '' : 'disabled'} btnStyle='primary' size='large'>下一步</Button>
              </div>
            </div>
          </section>
          <SectionCollapseInfo
            title='貼心小叮嚀'
            content={`
          1. 請輸入您欲申辦轉帳的門號及用戶資料。<br/>
          2. 不限定使用門號申請人本人之信用卡/銀行，信用卡有效期限仍為一個月以上。<br/>
          3. 若使用非門號申請人本人之信用卡/銀行辦理，請於信用卡/銀行帳戶持有人資料欄填寫正確持有人資料。<br/>
          4. 繳納費用非一般性消費支出，請洽各發卡銀行確認是否有刷卡紅利或現金回饋。<br/>
          5. 請列印申請書，並逐聯簽/蓋開戶印鑑或簽名，寄回〝22000板橋國慶郵局第342號信箱遠傳電信股份有限公司 - 自動轉帳組〞，郵寄辦理作業時間約需30天。<br/>
          6. 銀行轉帳如有塗改，請逐聯加蓋開戶印鑑/簽名更正。<br/>
          7. 若您的門號已有辦理信用卡自動轉帳，遠傳將會針對到期卡自動展延有效期。<br/>
          8. 各帳單帳戶所包含之門號，請自行查閱每月帳單；或洽本公司客服確認。<br/>
          9.  因同一帳單帳戶須採取相同繳費方式，若指定門號所屬之帳單帳戶包含其他門號(包含未來新增之門號)，該其他門號亦為本授權書效力所及。
        `}
          />
        </main> : null}

        <main className={`${this.state.step === 2 ? 'd-block' : 'd-none'}`}>
          <Header />
          <NavAnchor
            pageTitle='繳費項目'
            button={{
              text: '立即續約',
              link: '#',
            }}
          />
          <FormBanner
            title="自動扣繳申請"
            image={{
              md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
              sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
            }}
          />
          <section className={`${this.state.form.payment.value !== '2' ? 'd-block' : 'd-none'}`}>
            <div className="fui-container">
              <Formsy onValid={() => this.enableButton('formStep02')} onInvalid={() => this.disableButton('formStep02')} ref={this.form}>
                <h4 className="is-text-darkgray50 mb-2">填寫銀行帳號資料</h4>
                <div className='is-text-accent text-sm'>
                  <span className='required-dot'></span> 必填
                  </div>
                <Grid container spacing={2} className='mt-md-5'>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <label className="is-required">
                        銀行名稱
                      </label>
                      <Dropdown
                        className="is-button"
                        list={[
                          { value: '01', text: '01' },
                          { value: '02', text: '02' },
                        ]}
                        label={!!this.state.selectPayment ? this.state.selectPayment : '請選擇'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(item) => this.selectPayment(item)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className='mt-md-5'>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <label className="is-required">
                        帳號
                      </label>
                      <TextInput
                        type='text'
                        name='account'
                        required={true}
                        validationError={"請輸入帳號"}
                        value={this.state.form.account.value}
                        onChange={val => this.onChange('account', val)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className='mt-md-5'>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <label className="is-required">
                        姓名
                      </label>
                      <TextInput
                        type='text'
                        name='registerName'
                        required={true}
                        validationError={"請輸入姓名"}
                        value={this.state.form.registerName.value}
                        onChange={val => this.onChange('registerName', val)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className='mt-md-5 mb-md-7'>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <LabelInput
                        name='id'
                        required={true}
                        label='身分證字號'
                        validations={{
                          idNumberValid: Validation.idNumberValid
                        }}
                        validationErrors={{
                          idNumberValid: '請輸入身分證字號'
                        }}
                        getErrorMessage={() => { return "請輸入身分證字號" }}
                        value={this.state.form.id.value}
                        onChange={this.onChange}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Formsy>
              <div className="page-action mt-md-9">
                <Button className={this.state.formStep02 ? '' : 'disabled'} btnStyle='primary' size='large'>下一步</Button>
              </div>
            </div>
          </section>
          <section className={`${this.state.form.payment.value === '2' ? 'd-block' : 'd-none'}`}>
            <div className="fui-container">
              <Formsy onValid={() => this.enableButton('formStep03')} onInvalid={() => this.disableButton('formStep03')} ref={this.form}>
                <h4 className="is-text-darkgray50 mb-2">填寫信用卡資料</h4>
                <div className='is-text-accent text-sm'>
                  <span className='required-dot'></span> 必填
                  </div>
                <Grid container spacing={2} className='mt-md-5'>
                  <Grid item xs={12} md={10}>
                    <div className="form-group">
                      <label className="is-required">信用卡卡號</label>
                      <div className="credit-input" ref={this.creditGroup}>
                        <input
                          type='tel'
                          required={true}
                          name='creditcard'
                          maxLength='4'
                          value={this.state.form.credit_number_1.value}
                          onChange={e => this.onChange('credit_number_1', e.currentTarget.value)} />
                        <span>-</span>
                        <input
                          type='tel'
                          required={true}
                          name='creditcard'
                          maxLength='4'
                          value={this.state.form.credit_number_2.value}
                          onChange={e => this.onChange('credit_number_2', e.currentTarget.value)} />
                        <span>-</span>
                        <input
                          type='tel'
                          required={true}
                          name='creditcard'
                          maxLength='4'
                          value={this.state.form.credit_number_3.value}
                          onChange={e => this.onChange('credit_number_3', e.currentTarget.value)} />
                        <span>-</span>
                        <input
                          type='tel'
                          required={true}
                          name='creditcard'
                          maxLength='4'
                          value={this.state.form.credit_number_4.value}
                          onChange={e => this.onChange('credit_number_4', e.currentTarget.value)} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <label className="is-required">信用卡有效日期</label>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Dropdown
                            className="is-button"
                            list={[
                              { text: '01', value: '01' },
                              { text: '02', value: '02' },
                              { text: '03', value: '03' },
                              { text: '04', value: '04' },
                              { text: '05', value: '05' },
                              { text: '06', value: '06' },
                              { text: '07', value: '07' },
                              { text: '08', value: '08' },
                              { text: '09', value: '09' },
                              { text: '10', value: '10' },
                              { text: '11', value: '11' },
                              { text: '12', value: '12' }
                            ]}
                            label={!!this.state.form.credit_month.value ? this.state.form.credit_month.value : '月'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(val) => this.onChange('credit_month', val)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Dropdown
                            className="is-button"
                            list={[
                              { text: '20', value: '20' },
                              { text: '21', value: '21' },
                              { text: '22', value: '22' },
                              { text: '23', value: '23' },
                              { text: '24', value: '24' },
                              { text: '25', value: '25' },
                              { text: '26', value: '26' },
                              { text: '27', value: '27' },
                              { text: '28', value: '28' },
                              { text: '29', value: '29' },
                              { text: '30', value: '30' }
                            ]}
                            label={!!this.state.form.credit_year.value ? this.state.form.credit_year.value : '年'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(val) => this.onChange('credit_year', val)}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className='mt-md-5'>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <label className="is-required">
                        持卡人姓名
                        </label>
                      <TextInput
                        type='text'
                        name='creditCardName'
                        required={true}
                        validationError={"請輸入姓名"}
                        getErrorMessage={() => { return "請輸入姓名" }}
                        value={this.state.form.creditCardName.value}
                        onChange={val => this.onChange('creditCardName', val)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className='mt-md-5 mb-md-7'>
                  <Grid item xs={12} md={6}>
                    <div className="form-group mb-2">
                      <LabelInput
                        name='creditcardId'
                        required={true}
                        label='身分證字號'
                        validations={{
                          idNumberValid: Validation.idNumberValid
                        }}
                        validationErrors={{
                          idNumberValid: '請輸入身分證字號'
                        }}
                        getErrorMessage={() => { return "請輸入身分證字號" }}
                        value={this.state.form.creditcardId.value}
                        onChange={this.onChange}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Formsy>
              <div className="page-action mt-md-9">
                <Button className={this.getCreditStatus() ? '' : 'disabled'} btnStyle='primary' size='large'>下一步</Button>
              </div>
            </div>
          </section>

          <SectionCollapseInfo
            title='貼心小叮嚀'
            content={`
          1. 請輸入您欲申辦轉帳的門號及用戶資料。<br/>
          2. 不限定使用門號申請人本人之信用卡/銀行，信用卡有效期限仍為一個月以上。<br/>
          3. 若使用非門號申請人本人之信用卡/銀行辦理，請於信用卡/銀行帳戶持有人資料欄填寫正確持有人資料。<br/>
          4. 繳納費用非一般性消費支出，請洽各發卡銀行確認是否有刷卡紅利或現金回饋。<br/>
          5. 請列印申請書，並逐聯簽/蓋開戶印鑑或簽名，寄回〝22000板橋國慶郵局第342號信箱遠傳電信股份有限公司 - 自動轉帳組〞，郵寄辦理作業時間約需30天。<br/>
          6. 銀行轉帳如有塗改，請逐聯加蓋開戶印鑑/簽名更正。<br/>
          7. 若您的門號已有辦理信用卡自動轉帳，遠傳將會針對到期卡自動展延有效期。<br/>
          8. 各帳單帳戶所包含之門號，請自行查閱每月帳單；或洽本公司客服確認。<br/>
          9.  因同一帳單帳戶須採取相同繳費方式，若指定門號所屬之帳單帳戶包含其他門號(包含未來新增之門號)，該其他門號亦為本授權書效力所及。
        `}
          />
        </main>
      </div>
    );
  }
}

export default AutoBill;