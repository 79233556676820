import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
class QueryEmpty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={
            {
              text: '回個人專區',
              link: '/',
            }
          }
        />

        <FormBanner
          title="本期應繳金額"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="query-index pb-0">
          <div className="fui-container">
            <div className="paper pb-4">
              <p className="body is-text-darkgray50 mt-0 mb-4">親愛的用戶，您的門號目前尚未有帳單產生，您可使用「未出帳金額／上網用量」查詢門號使用詳細情形，謝謝！</p>
              <div className="page-action">
                <Button link="#" btnStyle='primary' className='mb-0' size='large'>未出帳金額／上網用量</Button>
              </div>
            </div>
          </div>
        </section>

      </main >
    )
  }
}

export default QueryEmpty;