import React from 'react'
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class CheckboxCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.content = React.createRef();
    this.state = {
      isEn: false,
      submitted: false,
      isInvalid: false,
      contentHeight: 0,
      open: false,
      checked: this.props.getValue(),
    }
  }

  componentDidMount() {
    this.setState({ contentHeight: this.content.current.children[0].clientHeight + 20 });
  }

  componentDidUpdate() {
    if (this.props.isFormSubmitted() !== this.state.submitted) {
      this.setState({
        submitted: this.props.isFormSubmitted(),
        isInvalid: !this.props.getValue() && this.props.isValidValue(this.state.checked)
      })
    }

    if (this.props.getValue() !== this.state.checked) {
      this.setState({
        checked: this.props.getValue()
      })
    }

    if((typeof window !== 'undefined' && window.$isEn) !== this.state.isEn) {
      this.setState({
          isEn: typeof window !== 'undefined' && window.$isEn
      })
  }
  }

  openDetail = event => {
    this.setState({
      contentHeight: this.content.current.children[0].clientHeight + 20,
      open: !this.state.open
    })
  }

  handleChange = event => {
    this.setState({
      checked: event.currentTarget.checked
    })
    this.props.setValue(event.currentTarget.checked);
    this.props.getCheckbox(event.currentTarget.checked);
  }

  passCheckedVal = () => {
    this.props.getCheckbox(this.state.checked);
  }

  render() {
    const errorMessage = this.props.getErrorMessage();

    return (
      <div className={`fui-collapse is-collapse-checkbox ${this.state.open ? 'is-open' : ''}`}>
        <div className={`checkbox is-agreement`}>
          <input type="checkbox" onChange={this.handleChange} name={this.props.name} id={this.props.name} value='1' checked={this.props.getValue()} />
          <label htmlFor={this.props.name} className="content">
            {this.props.label}
          </label>
          <span className="collapse-trigger" onClick={this.openDetail}>
            <span className="text-sm">{this.state.isEn ? 'more infomation' : '點選看完整內容'}</span>
            <i className={`icon-${this.state.open ? 'minus' : 'plus'}`}></i>
          </span>
        </div>
        <div ref={this.content} className="collapse-body mt-0" style={{ height: this.state.open ? this.state.contentHeight : 0 }}>
          <article dangerouslySetInnerHTML={{ __html: this.props.collapseContent }}></article>
        </div>
        {
          this.state.isInvalid ? (<span className="error-message">{errorMessage}</span>) : ''
        }
      </div>
    );
  }
}

CheckboxCollapse.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  collapseContent: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  value: PropTypes.any,
  getCheckbox: PropTypes.func
}

export default withFormsy(CheckboxCollapse)