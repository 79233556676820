import React, {useEffect} from 'react';

const withTrackingRoute = (Component, title) =>{
  const eventViewStart = new CustomEvent('event-view-start')
  const eventViewEnd = new CustomEvent('event-view-end')
  const app = document.querySelector('#root')

  return props => {
    let location = props.location
    app.dispatchEvent(eventViewStart)
    //fire event-view-start
    document.title = title
    useEffect(() => {
      // Datalayer
      // launchDigialData(location, window.__preLocation)
      //fire event-view-end
      app.dispatchEvent(eventViewEnd)
    }, [location, location.pathname, props.location])

    return <Component {...props} />
  }
}

export default withTrackingRoute;