import React from 'react';
import ImageModal from '../../components/ImageModal';
import Modal from 'react-modal';
import Link from '../../components/Link';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import CheckGroup from '../../components/form/CheckGroup';
import RadioGroup from '../../components/form/RadioGroup';
import FormBanner from '../../components/partials/banner/FormBanner';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import * as Validation from '../../utils/validation';
class BillResend extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.emailInput = React.createRef();
    this.emailForm = React.createRef();
    this.emailChangeForm = React.createRef();
    this.state = {
      emailCanSubmit: false,
      changeEmailModal: false,
      emailStore: '',
      emailValid: false,
      authCode: '',
      modalOpen: false,
      openedImg: '',
      phoneNumber: '0926899868',
      imgOpen: false,
      accountType: { value: '0', text: '帳單所有門號' },
      resentType: '',
      step2Validation: false,
      allNumber: [
        {
          type: 'cellphone',
          number: '0936-123-995'
        },
        {
          type: 'cellphone',
          number: '0931-456-995'
        },
        {
          type: 'landline',
          number: '02-1234567'
        },
        {
          type: 'landline',
          number: '02-8887777'
        },
      ],
      singleNumber: [
        {
          type: 'cellphone',
          number: '0936-123-995'
        }
      ],
      selectNummberType: [],
      mergeNumber: [
        { value: '0', label: '0916-000-995' },
        { value: '1', label: '0928-789-789' },
        { value: '2', label: '0912-477-477' },
      ],
      typeOfEmailBill: [
        {
          value: '0',
          label: '一般帳單',
          icon: {
            default: "/resources/cbu/img/email-bill-1.jpg",
            selected: "/resources/cbu/img/email-bill-1.jpg",
            source: "/resources/cbu/img/email-bill-1.jpg"
          }
        },
        {
          value: '1',
          label: '一般帳單+明細',
          icon: {
            default: "/resources/cbu/img/email-bill-2.jpg",
            selected: "/resources/cbu/img/email-bill-2.jpg",
            source: "/resources/cbu/img/email-bill-2.jpg",
          }
        },
      ],
      form: {
        id_number: { value: '', required: false },
        account: { value: '', required: false },
        accountBarcode: { value: '', required: false },
        emailAddress: { value: '', required: false },
        emailType: { value: '0', required: false },
        email: { value: '123456789@gmail.com', required: true },
        mail: { value: '新北市中和區*******', required: true },
      },
      canSubmit: false,
      step: 1,
      date: '2020/5月份',
      billDate: '2020/05/25',
      sentWay: 'Email 信件及簡訊',
      owningFeeText: '目前帳單<span class="is-text-accent">無欠費，不需繳費</span>。',
      apply: [
        {
          icon: '/resources/cbu/img/text-message-repost.svg',
          iconAlt: 'text-message-repost',
          title: '簡訊補寄',
          content: {
            link: '#',
            linkText: '1小時內',
            text: '可收到，簡訊寄送帳單費用通知'
          },
          button: {
            link: '#',
            text: '申請簡訊補寄',
            type: 'text'
          }
        },
        {
          icon: '/resources/cbu/img/mail-repost.svg',
          iconAlt: 'mail-repost',
          title: '郵局補寄',
          content: {
            link: '#',
            linkText: '2-5天內',
            text: '可收到以郵寄方式補寄的紙本帳單'
          },
          button: {
            link: '#',
            text: '申請郵局補寄',
            type: 'mail'
          }
        },
        // {
        //   icon: '/resources/cbu/img/email-repost.svg',
        //   iconAlt: 'email-repost',
        //   title: 'Email補寄',
        //   content: {
        //     link: '#',
        //     linkText: '1小時內',
        //     text: '可收到，Email補寄的紙本帳單'
        //   },
        //   button: {
        //     link: '#',
        //     text: '申請Email補寄',
        //     type: 'email'
        //   }
        // },
        {
          icon: '/resources/cbu/img/barcode-repost.svg',
          iconAlt: 'barcode-repost',
          title: '繳費條碼補寄',
          content: {
            link: '#',
            linkText: '1小時內',
            text: '可收到，MMS繳費條碼'
          },
          button: {
            link: '#',
            text: '申請繳費條碼補寄',
            type: 'barcode'
          }
        },
        {
          icon: '/resources/cbu/img/online-billing.svg',
          iconAlt: 'online-billing',
          title: '線上帳單',
          content: {
            link: '#',
            linkText: null,
            text: '立即查看費用與明細，了解帳單分析'
          },
          button: {
            link: '#',
            text: '查看線上帳單',
            type: 'online'
          }
        },
      ]
    }
  }
  componentDidMount() {
    this.setState({
      selectNummberType: this.state.allNumber
    })
  }
  getColumns = () => {
    return 12 / this.state.apply.length
  }
  selectNumber = (val) => {
    // this.setState({
    //   selectPeriod: val
    // })
    // console.log(`val`, val);
  }
  selectAccount = (e, item, idx) => {
    this.setState({
      accountType: item,
      selectNummberType: item.value == 0 ? this.state.allNumber : this.state.singleNumber
    })
    console.log(e, item, idx);
  }
  selectBarcodeAccount = (val) => {
    // this.setState({
    //   selectPeriod: val
    // })
    console.log(`val`, val);
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  onChange = (name, value) => {
    // console.log(this.emailForm);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    // console.log('onChange', name, value)
    this.setState({
      form: newForm,
    });
  };

  selectResendType = (val) => {
    console.log(`val`, val);
    if (val == 'online') {
      return
    } else {
      this.setState({
        resentType: val,
        step: 2
      })
    }
  }
  getBannerTitle = () => {
    switch (this.state.resentType) {
      case '':
        return '補寄帳單'
        break;
      case 'text':
        return '簡訊補寄'
        break;
      case 'email':
        return 'Email 補寄帳單'
        break;
      case 'barcode':
        return '繳費條碼補寄'
        break;
      case 'mail':
        return '郵局補寄'
        break;
      default:
        break;
    }
  }
  openImgModal = (val) => {
    console.log(val);
    this.setState({
      imgOpen: true,
      openedImg: val
    })
  }
  handleChange = () => {
    this.setState({
      authCode: this.input.current.value,
    });
  }
  emailChange = () => {
    this.setState({
      emailStore: this.emailInput.current.value,
    });
  }
  submitAuthCode = () => {
    // console.log(`this.authCode: `, this.input.current.value);
    this.setState({
      modalOpen: false,
      // step: 2
    })
    this.props.history.push('/resend-bill/success')
  }
  submit = () => {
    if (!!this.emailForm.current) {
      this.setState({
        modalOpen: true
      })
    } else if (this.state.resentType === 'email') {
      this.setState({
        modalOpen: true
      })
    } else if (this.state.resentType === 'mail') {
      this.setState({
        modalOpen: true
      })
    } else {
      console.log('submit!');
    }
  }
  getResendForm = () => {
    if (this.state.resentType == 'text') {
      return (
        <div>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} md={12}>
              <h4 className='mb-0'>選擇欲補送簡訊通知之門號</h4>
            </Grid>
            <Grid item xs={12} md={12}>
              <Dropdown
                className="is-button ml-0 mb-2"
                list={[
                  { value: '0', text: '帳單所有門號' },
                  { value: '1', text: '帳單代表號(0936-123-995)' },
                  { value: '2', text: '自行設定' },
                ]}
                label={this.state.accountType.text}
                arrow={true}
                hasCheck={false}
                onChange={(e, item, idx) => this.selectAccount(e, item, idx)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            {this.state.accountType.value == '2' ? <Formsy
              className=''
              onValidSubmit={this.submit}
              onValid={this.enableButton}
              onInvalidSubmit={this.disableButton}
              noValidate
              ref={this.form}>
              <CheckGroup
                onChange={this.onChange}
                validationErrors={{ isDefaultRequiredValue: '選擇欲補送簡訊通知之門號' }}
                label='選擇欲補送簡訊通知之門號'
                name='account'
                className="is-merge-bill mt-md-4 mt-0 mb-0 pb-0"
                required={this.state.form.account.required}
                default={this.state.form.account.value}
                options={this.state.mergeNumber}
              />
            </Formsy> : <Grid container jusify="flex-start" spacing={2}>
                {this.state.selectNummberType.map((item, i) => {
                  return (
                    <Grid item xs={12} sm={4} key={item.number + i}>
                      <div className="border d-flex p-md-2 px-2 py-1 flex-align-center">
                        <img className='mr-1' src={'/resources/common/images/' + (item.type == 'cellphone' ? '' : 'contact-') + 'phone.svg'} alt={item.type} />
                        {item.number}
                      </div>
                    </Grid>
                  )
                })}
              </Grid>}
          </Grid>
        </div>
      )
    } else if (this.state.resentType == 'barcode') {
      return (
        <div>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} md={12}>
              <h4 className='mb-0'>選擇欲補送繳費條碼通知之門號</h4>
            </Grid>
            <Grid item xs={12} md={12}>
              <Dropdown
                className="is-button ml-0 mb-2"
                list={[
                  { value: '0', text: '帳單所有門號' },
                  { value: '1', text: '帳單代表號(0936-123-995)' },
                  { value: '2', text: '自行設定' },
                ]}
                label={this.state.accountType.text}
                arrow={true}
                hasCheck={false}
                onChange={(e, item, idx) => this.selectAccount(e, item, idx)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            {this.state.accountType.value == '2' ? <Formsy
              className=''
              onValidSubmit={this.submit}
              onValid={this.enableButton}
              onInvalidSubmit={this.disableButton}
              noValidate
              ref={this.form}>
              <CheckGroup
                onChange={this.onChange}
                validationErrors={{ isDefaultRequiredValue: '選擇欲補送簡訊通知之門號' }}
                label='選擇欲補送簡訊通知之門號'
                name='account'
                className="is-merge-bill mt-md-4 mt-0 mb-0 pb-0"
                required={this.state.form.account.required}
                default={this.state.form.account.value}
                options={this.state.mergeNumber}
              />
            </Formsy> : <Grid container jusify="flex-start" spacing={2}>
                {this.state.selectNummberType.map((item, i) => {
                  return (
                    <Grid item xs={12} sm={4} key={item.number + i}>
                      <div className="border d-flex p-md-2 px-2 py-1 flex-align-center">
                        <img className='mr-1' src={'/resources/common/images/' + (item.type == 'cellphone' ? '' : 'contact-') + 'phone.svg'} alt={item.type} />
                        {item.number}
                      </div>
                    </Grid>
                  )
                })}
              </Grid>}
          </Grid>
        </div>
      )
    } else if (this.state.resentType === 'email') {
      return (
        <div>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={8} md={8}>
              <h4 className='mb-1'>補寄帳單寄送Email</h4>
            </Grid>
            <Grid item xs={4} md={4}>
              <a onClick={() => { this.setState({ changeEmailModal: true }) }} className="mt-md-7 mt-1 info-link align-right w-100">
                更改Email
              </a>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="email-form">
                <div className="email-form--left">Email</div>
                <div className="email-form--right">{this.state.form.email.value}</div>
              </div>
            </Grid>

          </Grid>
          <Grid item xs={12} md={12}>
            <Formsy
              className=''
              onValidSubmit={this.submit}
              onValid={this.enableButton}
              onInvalidSubmit={this.disableButton}
              noValidate
              ref={this.form}>
              <RadioGroup
                validationErrors='請選擇寄送的帳單樣式'
                onChange={this.onChange}
                label='請選擇寄送的帳單樣式'
                name='emailType'
                className="is-popup is-two mb-0"
                openModal={this.openImgModal}
                required={this.state.form.emailType.required}
                default={this.state.form.emailType.value}
                options={this.state.typeOfEmailBill}
              />

            </Formsy>
          </Grid>
        </div>
      )
    }
    else if (this.state.resentType === 'mail') {
      return (
        <div>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} md={12}>
              <h4 className='mb-1'>帳單將郵寄至您登記的地址</h4>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="email-form">
                <div className="email-form--right pl-1">{this.state.form.mail.value}</div>
              </div>
            </Grid>

          </Grid>
          <Grid item xs={12} md={12}>
            <Formsy
              className=''
              onValidSubmit={this.submit}
              onValid={this.enableButton}
              onInvalidSubmit={this.disableButton}
              noValidate
              ref={this.form}>
              <RadioGroup
                validationErrors='請選擇寄送的帳單樣式'
                onChange={this.onChange}
                label='請選擇寄送的帳單樣式'
                name='emailType'
                className="is-popup is-two mb-0"
                openModal={this.openImgModal}
                required={this.state.form.emailType.required}
                default={this.state.form.emailType.value}
                options={this.state.typeOfEmailBill}
              />

            </Formsy>
          </Grid>
          <Grid item xs={12} md={12}>
            <small className="is-text-darkgray50 d-block mt-3">
              為保護您資料的安全，此服務需進行本人門號認證，將發送認證碼簡訊至您的門號<span className="ml-1">{this.state.phoneNumber}</span>
            </small>
          </Grid>
        </div>
      )
    }
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title={this.getBannerTitle()}
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        {this.state.step == 1 ? <section className="usage-rate pt-md-7 pt-4 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              <h4 className="is-text-darkgray50 mb-md-7 mb-6">
                {this.state.date}的帳單已訂於 {this.state.billDate} 以 {this.state.sentWay}方式發送給您。
                <div
                  className='d-inline-block'
                  dangerouslySetInnerHTML={{
                    __html: this.state.owningFeeText || '',
                  }}
                />若需補寄帳單，可選擇下列方式補寄最近一期帳單。或使用「線上帳單」服務查詢近 6 期帳單。
              </h4>
              <div className="paper mt-md-4 mt-2 mb-0 pb-md-7 pb-0 pt-md-7 pt-2">
                <Grid container justify="center" spacing={4}>
                  {this.state.apply.map((item, i) => {
                    return (
                      <Grid key={item.title} item xs={12} sm={12} md={this.getColumns()} className='content'>
                        <img src={item.icon} alt={item.iconAlt} />
                        <h4 className='mt-1 mb-md-2 mb-0'>{item.title}</h4>
                        <p className='min-height-64 body mb-md-6 mb-4 mt-md-2 mt-1'>{!!item.content.linkText ? <span className="is-text-accent">{item.content.linkText}</span> : null}{item.content.text}</p>
                        <div className='action mb-md-0 mb-2'>
                          <Button onClick={() => this.selectResendType(item.button.type)} className='mb-0 fui-button is-secondary'>
                            {item.button.text}
                          </Button>
                        </div>
                      </Grid>
                    )
                  })}
                </Grid>

              </div>
            </div>
          </div>
        </section> : null}
        {this.state.step == 2 ? <div>
          <section className="query-index resend-bill">
            <div className="fui-container">
              <div className="fui-segments no-shadow is-bg-initial">
                <div className="paper mt-md-0 mt-2 mb-0 pb-md-7 pb-3 pt-md-4 pt-2">
                  {this.getResendForm()}
                </div>
                {!this.state.step2Validation && this.state.resentType !== 'email' ? <div className='page-action'><Button onClick={() => this.setState({ step2Validation: true })} className='mt-7' link="#" btnStyle='primary' size='large'>下一步</Button></div> : null}
                {!this.state.step2Validation && this.state.resentType == 'email' ?
                  <div className='page-action'>
                    <Button
                      onClick={() => this.setState({ step2Validation: true })}
                      className="mt-7"
                      link="#" btnStyle='primary' size='large'>
                      下一步
                  </Button>
                  </div> : null}
              </div>
              {this.state.step2Validation ? <div className="mt-7">
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                      <LabelInput
                        className="mb-2"
                        name='id_number'
                        required={this.state.form.id_number.required}
                        label='證照號碼'
                        validations={{
                          idNumberValid: Validation.idNumberValid
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入證照號碼'
                        }}
                        placeholder='請輸入證照號碼'
                        value={this.state.form.id_number.value}
                        onChange={this.onChange}
                      />
                      <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                        身分說明：<br />
                        個人戶：身分證字號<br />
                        公司戶：統一編號或稅籍編號<br />
                        外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                      </p>
                      <div className='page-action'>
                        <Button onClick={() => { this.submit() }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>送出申請</Button>
                      </div>
                    </Grid>
                  </Grid>
                </Formsy>
              </div> : null}
            </div>
          </section>
        </div>
          : null}


        {this.state.changeEmailModal ? <Modal
          // onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          // onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.changeEmailModal}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <Formsy onValid={() => { this.setState({ emailCanSubmit: true }) }} onInvalid={() => { this.setState({ emailCanSubmit: false }) }} ref={this.emailChangeForm}>
              <h5 className='align-left'>更改Email</h5>
              <p className='mt-4 mb-5 align-left'>請於下方填入您欲寄送之Email，帳單將會補寄至此Email地址。</p>
              <div className="d-flex flex-column text-input">
                <LabelInput
                  className="mb-2"
                  name='email'
                  required={true}
                  label={null}
                  validations="isEmail"
                  validationErrors={{ isEmail: '請輸入Email', isRequired: '請輸入Email' }}
                  placeholder='請輸入Email'
                  value={this.state.form.email.value}
                  onChange={this.onChange}
                />
              </div>
              <Button onClick={() => { this.setState({ changeEmailModal: false }) }} link="#" className={`mb-lg-0 mb-2 ${this.state.emailCanSubmit ? '' : 'disabled'}`} btnStyle='primary'>確定</Button>
              <Button onClick={() => this.setState({ changeEmailModal: false })} link="#" className='mb-0' btnStyle="secondary">取消</Button>
            </Formsy>
          </div>
        </Modal> : null}
        {this.state.modalOpen ? <Modal
          // onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          // onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5 className='align-left'>門號認證</h5>
            <p className='mt-4 mb-5 align-left'>為保護您資料的安全，此服務需進行本人門號認證，目前已發送驗證碼至您的門號 {this.state.phoneNumber}</p>

            <div className="d-flex flex-column text-input">
              <input
                onBlur={this.handleChange}
                // id={`input-${this.props.name}`}
                type='text'
                ref={this.input}
                onChange={this.handleChange}
                onKeyUp={this.handleChange}
                placeholder="請輸入認證碼"
                value={this.state.authCode}
                maxLength={8}
              />
              <small className='d-flex flex-row mt-2 is-text-medium'>
                沒有收到認證碼？ <a className="is-text-accent">重新發送</a>
              </small>
            </div>
            <Button onClick={() => { this.submitAuthCode() }} link="#" className={`mb-lg-0 mb-2 ${!!this.state.authCode ? '' : 'disabled'}`} btnStyle='primary'>確定</Button>
            <Button onClick={() => this.setState({ modalOpen: false })} link="#" className='mb-0' btnStyle="secondary">取消</Button>
          </div>
        </Modal> : null}
        {this.state.imgOpen && (
          <ImageModal
            mainSrc={this.state.openedImg}
            onCloseRequest={() => this.setState({ imgOpen: false })}
          />
        )}
      </main >
    )
  }
}

export default BillResend;