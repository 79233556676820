import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import Link from '../components/Link';

import NavAnchor from '../components/partials/NavAnchor';
import Button from '../components/Button';
import FormBanner from '../components/partials/banner/FormBanner';
import LabelPassword from '../components/form/LabelPassword';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      form: {
        old_pwd: {value: '', required: true},
        new_pwd: {value: '', required: true},
        confirmation_pwd: {value: '', required: true},
      }
    }
  }

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }

  onChange = (name, value) => {
    let form = Object.assign(this.state.form)
    form[name].value = value 
    this.setState({
      form: form
    })
  }

  submit = () => {
    console.log('SUBMIT')
  }

  render () {
    const {canSubmit, form } = this.state;

    return (
      <main>
        
        <NavAnchor 
          pageTitle='帳戶設定'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="密碼變更"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} 
        />
        <section className="page-body">
          <div className="fui-container">
            <Grid container justify="center">
              <Grid item xs={12} sm={12} md={10}>
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                  <h4 className="is-text-darkgray50">請輸入您原先設定的密碼，系統將把您自訂的新密碼同時傳送至您的手機及電子郵件。</h4>
                  <Grid contianer>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelPassword
                        name="old_pwd"
                        label="原始密碼"
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入原始密碼'
                        }}
                        maxLength='14'
                        required={true}
                        placeholder="需包含英文及數字共6~14碼"
                        value={form.old_pwd.value}
                        onChange={this.onChange}
                      />
                      <LabelPassword
                        name="new_pwd"
                        label="新密碼"
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入新密碼'
                        }}
                        maxLength='14'
                        required={true}
                        placeholder="需包含英文及數字共6~14碼"
                        value={form.new_pwd.value}
                        onChange={this.onChange}
                      />
                      {
                        form.new_pwd.value.length >  1 ? (
                          // 過短 : is-short  弱 : is-weak  普通 : is-normal  強 : is-strong
                          <div className="password-strength is-strong">
                            {/* <div>密碼過短</div>
                            <div>密碼強度 弱！</div>
                            <div>密碼強度 普通！</div> */}
                            <div>密碼強度 強！</div>
                          </div>
                        ) : null
                      }

                      <LabelPassword
                        name="confirmation_pwd"
                        label="新密碼再次輸入"
                        maxLength='14'
                        validationErrors={{
                          isDefaultRequiredValue: '請再次輸入新密碼'
                        }}
                        required={true}
                        placeholder=""
                        value={form.confirmation_pwd.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <div className='page-action'>
                    <Button btnStyle='primary' size='large' disabled={!canSubmit} onClick={this.submit}>確認送出</Button>
                    <p>系統將定期提醒您修改密碼，確保資料安全！密碼設定問題請參考<Link to='#'>設定教學</Link>。</p>
                  </div>
                </Formsy>
              </Grid>
            </Grid>
          </div>
        </section>
      </main>
    );
  }
}

export default ChangePassword;