import React from 'react';
import PropTypes from 'prop-types';

class ArticleCitationPanel extends React.Component {
    render() {
        return (
            <div className={`fui-panel article-citation-panel ${this.props.className}`}>
                <div className='fui-container fui-container-article'>
                    <div className='with-comma single-comma'>
                        <div className='is-text-black50' dangerouslySetInnerHTML={{__html: this.props.children}}></div>
                    </div>
                </div>
            </div>
        )
    }
}

ArticleCitationPanel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,    
}

export default ArticleCitationPanel;