import React from 'react';
import Tooltip from '../../components/Tooltip'
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      required: this.props.required || false,
      options: this.props.options,
      selected: this.props.default || null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.default) {
      this.props.setValue(this.props.default);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.default !== this.props.default) {
      this.setState({
        selected: this.props.default,
      });
    }
  }

  handleChange(event) {
    //   debugger
    if (event.currentTarget.checked) {
      this.setState({ selected: event.currentTarget.value });
      this.props.setValue(this.state.selected);
      this.props.onChange(this.props.name, event.currentTarget.value);
    }
  }

  render() {
    const errorMessage = this.props.getErrorMessage();
    return (
      <div className={`form-group ${this.props.className ? this.props.className : ''}`}>
        <label className={`${this.state.required ? 'is-required' : ''} justify-start-sm justify-between`}>
          {this.props.label}
          {this.props.tooltip ? <Tooltip
            parentNode={null}
            className="floa-right"
            content={<i className='icon-information is-text-darkgray100 ml-1 d-flex'></i>}
            trigger="click"
            tooltip={this.props.tooltip}
          /> : null}
        </label>

        <div className='radio-group'>
          {this.props.options.map((option, i) => (
            <label className='radio is-icon-box' key={`radio-${this.props.name}-${i}`}>
              <input
                type='radio'
                name={this.props.name}
                value={option.value}
                onChange={this.handleChange}
                defaultChecked={this.props.default === option.value}
              />
              <div className='content'>
                {option.icon ? (
                  <div className='icon'>
                    <img src={process.env.PUBLIC_URL + option.icon.default} className='default' alt={option.label} />
                    <img src={process.env.PUBLIC_URL + option.icon.selected} className='selected' alt={option.label} />
                    {this.props.className.indexOf('is-popup') > -1 ? <div onClick={() => { this.props.openModal(option.icon.source) }} className="open-image">
                      <i className="icon-search" />
                    </div> : null}

                  </div>
                ) : (
                    ''
                  )}
                <div className='text' dangerouslySetInnerHTML={{
                  __html: option.label
                }}>
                </div>
              </div>
            </label>
          ))}
        </div>
        {!!errorMessage ? <span className='error-message'>{errorMessage}</span> : null}
      </div>
    );
  }
}

RadioGroup.defaultProps = {
  className: ''
}
RadioGroup.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  default: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        default: PropTypes.string,
        selected: PropTypes.string,
      }),
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
};

export default withFormsy(RadioGroup);
