import React, { Component } from 'react';
import FormBanner from '../../components/partials/banner/FormBanner';
import Select from '../../components/form/Select';
import Checkbox from '../../components/form/Checkbox';
import PanelFigure1 from '../../components/panelContent/PanelFigure1';
import CheckGroup from '../../components/form/CheckGroup';
import Modal from 'react-modal';
import LabelTextarea from '../../components/form/LabelTextarea';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import RadioGroupComplex from '../../components/form/RadioGroupComplex';
import RadioGroup from '../../components/form/RadioGroup';
import Formsy from 'formsy-react';
import Dropdown from "../../components/Dropdown";
import LabelInput from '../../components/form/LabelInput';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';
class FixQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: '',
      canSubmit: false,
      step: 1,
      modalOpen: false,
      currentDeleteItem: null,
      table: {
        head: ['網路預約單號', 'NS2007070001', 'NS2007070002'],
        content: [
          ['維修單號', 'IMEI(手機序號)', '送件方式', '廠牌', '機型', '維修金額', '報修資料修改'],
          ['', '352008099891704', '處理中', 'SAMSUNG', 'Samsung GALAXY Note 8', '', { id: 1 }],
          ['', '352008099891705', '處理中', 'SAMSUNG', 'Samsung GALAXY Note 8', '', { id: 2 }],
        ]
      },
      form: {
        fixup: { value: '0', required: true },
        return: { value: false, required: false },
        agree: { value: false, required: true },
        name: { value: '', required: true },
        brand: { value: '', required: true },
        model: { value: '', required: true },
        brand1: { value: '', required: true },
        model1: { value: '', required: true },
        pickUpTime: { value: '上午', required: true },
        pickUpCity: { value: '縣市', required: true },
        pickUpDist: { value: '鄉鎮市區', required: true },
        pickUpAddr: { value: '', required: true },
        returnCity: { value: '縣市', required: true },
        returnDist: { value: '鄉鎮市區', required: true },
        returnAddr: { value: '', required: true },
        spare: { value: '1', required: true },
        receipt: { value: '0', required: true },
        imei: { value: '', required: true },
        phone: { value: '', required: true },
        taxtID: { value: '', required: true },
        company: { value: '', required: true },
        cellphone: { value: '', required: true },
        email: { value: '', required: true },
        submitWay: { value: '', required: false },
        issue: { value: '', required: true },
        fixStation: { value: '', required: true },
        reservationDate: { value: '', required: true },
        reservationTime: { value: '', required: true },
      },
      selectModel: {
        text: 'SAMSUNG GALAXY Note8',
        value: 'SAMSUNG GALAXY Note8'
      },
      isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
    }
  }
  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', e => {
        this.setState({
          isMobile: window.innerWidth < 960
        })
      })
  }
  selectModel = (val, v) => {
    // console.log(parseInt(val));
    // console.log({ val, v });
    this.setState({
      selectModel: v
    })
  }
  searchIMEI = () => {
    if (!!this.state.form.imei.value) {
      this.openModal('isIMEI')
    }
  }
  openModal = (val) => {
    this.setState({
      modalType: val,
      modalOpen: true
    })
  }

  onChange = (name, value, i, x) => {
    let newForm = Object.assign(this.state.form);
    if (name === 'return' && value === true) {
      newForm['returnCity'].value = newForm['pickUpCity'].value;
      newForm['returnDist'].value = newForm['pickUpDist'].value;
      newForm['returnAddr'].value = newForm['pickUpAddr'].value;
    } else {
      newForm[name].value = value;
    }
    this.setState({
      form: newForm
    })
  }
  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }
  submit = () => {
    console.log("submit data");
  }
  scrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  }
  step2 = () => {
    // console.log(this.state.form.fixup.value);
    if (this.state.form.fixup.value === '0') {
      this.setState({
        modalOpen: true,
        modalType: 'hint'
      })
    } else {
      this.setState({ step: 2 })
      this.scrollToTop()
    }
  }
  getTitle = () => {
    if (this.state.step === 1) {
      return '網路預約報修'
    } else {
      switch (this.state.form.fixup.value) {
        case '0':
          return '網路預約報修 <br/>到府收送'
          break;
        case '1':
          return '網路預約報修 <br/>親送門市'
          break;
        case '2':
          return '網路預約報修 <br/>親送維修站'
          break;

        default:
          return '網路預約報修'
          break;
      }
    }

  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title={this.getTitle()}
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <Formsy className='w-100' onValid={() => this.enableButton()} onInvalid={() => this.disableButton()}>
          {this.state.step === 2 ?
            <section>
              {this.state.form.fixup.value === '0' ?
                <div className="fui-container">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h4 className="is-text-darkgray50 mb-0">
                        遠傳一般會員，選擇到府收送服務將會先酌收物流運費 <span className="is-text-accent">$330</span>
                      </h4>
                    </Grid>
                    <Grid item xs={12} md={6} className='position-relative'>
                      <div className="hide-required form-group">
                        <LabelInput
                          name='imei'
                          label='IMEI(手機序號)'
                          className="hide-required mb-2"
                          placeholder='請輸入IMEI'
                          value={this.state.form.imei.value}
                          onChange={this.onChange}
                        />
                        <small className='is-text-gray100'>您可點此了解
                <a onClick={() => { this.openModal('introIMEI') }} className="is-text-black50 hover-underline-link">如何查詢手機IMEI</a>、
                <a onClick={() => { this.openModal('iOS') }} className="is-text-black50 hover-underline-link">如何關閉find my iPhone</a>、
                <a onClick={() => { this.openModal('android') }} className="is-text-black50 hover-underline-link">如何移除Google鎖</a>
                        </small>
                      </div>
                      <a onClick={() => { this.searchIMEI() }} style={{ position: 'absolute', right: 15, top: 14 }} className="d-block d-md-none is-text-medium is-text-accent">查詢IMEI</a>
                    </Grid>
                    <a onClick={() => { this.searchIMEI() }} style={{ marginTop: 68 }} className="d-none d-md-block is-text-medium is-text-accent">
                      查詢IMEI
          </a>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group">
                        <label>廠牌</label>
                        <Select
                          label="請選擇廠牌"
                          name='brand1'
                          options={[
                            { text: 'Apple', value: 'Apple' },
                            { text: 'Samsung', value: 'Samsung' },
                          ]}
                          value={this.state.form.brand1.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group">
                        <label>機型</label>
                        <Select
                          label="請選擇機型"
                          name='model1'
                          options={[
                            { text: 'iPhone X', value: 'iPhone X' },
                            { text: 'iPhone XR', value: 'iPhone XR' },
                          ]}
                          value={this.state.form.model1.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="hide-required form-group mb-0">
                        <LabelTextarea
                          placeholder='請輸入內容'
                          label='故障原因(選填)'
                          className='mb-2'
                          maxLength={200}
                          name='issue'
                          value={this.state.form.issue.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='name'
                        required
                        label='姓名'
                        className="hide-required"
                        placeholder='輸入姓名'
                        value={this.state.form.name.value}
                        validationErrors='請輸入正確的姓名'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='phone'
                        required
                        label='市話'
                        className="hide-required"
                        placeholder='輸入市話'
                        value={this.state.form.phone.value}
                        validationErrors='請輸入正確的市話'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='cellphone'
                        required
                        label='手機'
                        className="hide-required"
                        placeholder='輸入手機'
                        value={this.state.form.cellphone.value}
                        validationErrors='請輸入正確的手機'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='email'
                        required={true}
                        label='Email'
                        className="hide-required"
                        placeholder='輸入Email'
                        value={this.state.form.email.value}
                        validationErrors='請輸入正確的Email'
                        onChange={this.onChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <hr className='m-0' />
                    </Grid>
                    <Grid item xs={12}>
                      <h4 className="is-text-darkgray50 mb-0 mt-md-7 mt-2">請填寫取件資訊</h4>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group">
                        <label>取件時段</label>
                        <Select
                          label="請選擇取件時段"
                          name='pickUpTime'
                          options={[
                            { text: '上午', value: '上午' },
                            { text: '下午', value: '下午' },
                          ]}
                          value={this.state.form.pickUpTime.value}
                          onChange={this.onChange}
                        />
                        <small className="is-text-gray100 mt-2">物流人員將與您聯絡取件時間</small>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <h6 className='m-0 is-text-medium'>取件地址</h6>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group mb-0">
                        <Select
                          label="請選擇縣市"
                          name='pickUpCity'
                          options={[
                            { text: '上午', value: '上午' },
                            { text: '下午', value: '下午' },
                          ]}
                          value={this.state.form.pickUpCity.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group mb-0">
                        <Select
                          label='鄉鎮市區'
                          name='pickUpDist'
                          options={[
                            { text: '上午', value: '上午' },
                            { text: '下午', value: '下午' },
                          ]}
                          value={this.state.form.pickUpDist.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='pickUpAddr'
                        required
                        label=''
                        className="hide-required mb-2"
                        placeholder='請輸入取件地址'
                        value={this.state.form.pickUpAddr.value}
                        validationErrors='請輸入正確的IMEI'
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <div className="form-group mb-0 mt-md-4">
                        <label>送返地址</label>
                        <Checkbox
                          name="return"
                          className="mt-0"
                          label="同取件地址"
                          value="0"
                          checked={this.state.form.return.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        label="請選擇縣市"
                        name='returnCity'
                        options={[
                          { text: '上午', value: '上午' },
                          { text: '下午', value: '下午' },
                        ]}
                        value={this.state.form.returnCity.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        label='鄉鎮市區'
                        name='returnDist'
                        options={[
                          { text: '上午', value: '上午' },
                          { text: '下午', value: '下午' },
                        ]}
                        value={this.state.form.returnDist.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='returnAddr'
                        required
                        label=''
                        className="hide-required mb-2"
                        placeholder='請輸入送返地址'
                        value={this.state.form.returnAddr.value}
                        validationErrors='請輸入正確的送返地址'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <div className="form-group mb-0 mt-4">
                        <RadioGroup
                          onChange={this.onChange}
                          label='是否需要備用機'
                          name='spare'
                          className="mb-0 hide-required"
                          required={this.state.form.spare.required}
                          default={this.state.form.spare.value}
                          options={[
                            {
                              value: '0',
                              label: '不需要'
                            },
                            {
                              value: '1',
                              label: '需要'
                            },
                          ]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <hr />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Grid item xs={12}>
                        <h4 className="is-text-darkgray50">發票資料</h4>
                      </Grid>
                      <div className="form-group mt-3">
                        <label className='mt-0 mb-2'>買受人統一編號</label>
                        <p className='mt-0 mb-2'>非白金會員有收取物流費,可提供發票相關資訊。</p>
                        <RadioGroup
                          onChange={this.onChange}
                          label=''
                          name='receipt'
                          className="mb-0"
                          required={this.state.form.receipt.required}
                          default={this.state.form.receipt.value}
                          options={[
                            {
                              value: '0',
                              label: '無'
                            },
                            {
                              value: '1',
                              label: '有'
                            },
                          ]}
                        />
                      </div>
                    </Grid>


                  </Grid>
                  {this.state.form.receipt.value === '1' ? <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='taxtID'
                        required
                        label='統一編號'
                        className="hide-required"
                        placeholder='輸入統一編號'
                        value={this.state.form.taxtID.value}
                        validationErrors='請輸入正確的統一編號'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='company'
                        label='公司名稱(選填)'
                        className="hide-required"
                        placeholder='輸入公司名稱'
                        value={this.state.form.company.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid> : null}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <div className="page-action mb-0 w-100">
                        <Button btnStyle='primary' size="large" className='mb-0' onClick={() => { console.log("hi"); }}>確認送出</Button>
                      </div>
                    </Grid>
                  </Grid>
                </div> : null}


              {this.state.form.fixup.value === '1' ?
                <div className="fui-container">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} className='position-relative'>
                      <div className="hide-required form-group">
                        <LabelInput
                          name='imei'
                          required
                          label='IMEI(手機序號)'
                          className="hide-required mb-2"
                          placeholder='請輸入IMEI'
                          value={this.state.form.imei.value}
                          validationErrors='請輸入正確的IMEI'
                          onChange={this.onChange}
                        />
                        <small className='is-text-gray100'>您可點此了解
                          <a onClick={() => { this.openModal('introIMEI') }} className="is-text-black50 hover-underline-link">如何查詢手機IMEI</a>、
                          <a onClick={() => { this.openModal('iOS') }} className="is-text-black50 hover-underline-link">如何關閉find my iPhone</a>、
                          <a onClick={() => { this.openModal('android') }} className="is-text-black50 hover-underline-link">如何移除Google鎖</a>
                        </small>
                      </div>
                      <a onClick={() => { this.searchIMEI() }} style={{ position: 'absolute', right: 15, top: 14 }} className="d-block d-md-none is-text-medium is-text-accent">查詢IMEI</a>
                    </Grid>
                    <a onClick={() => { this.searchIMEI() }} style={{ marginTop: 68 }} className="d-none d-md-block is-text-medium is-text-accent">
                      查詢IMEI
          </a>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group">
                        <label>廠牌</label>
                        <Select
                          label="請選擇廠牌"
                          name='brand'
                          options={[
                            { text: 'Apple', value: 'Apple' },
                            { text: 'Samsung', value: 'Samsung' },
                          ]}
                          value={this.state.form.brand.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group">
                        <label>機型</label>
                        <Select
                          label="請選擇機型"
                          name='model'
                          options={[
                            { text: 'iPhone X', value: 'iPhone X' },
                            { text: 'iPhone XR', value: 'iPhone XR' },
                          ]}
                          value={this.state.form.model.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="hide-required form-group mb-0">
                        <LabelTextarea
                          placeholder='請輸入內容'
                          label='故障原因(選填)'
                          className='mb-2'
                          maxLength={200}
                          name='issue'
                          value={this.state.form.issue.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='name'
                        required
                        label='姓名'
                        className="hide-required"
                        placeholder='輸入姓名'
                        value={this.state.form.name.value}
                        validationErrors='請輸入正確的姓名'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='phone'
                        required
                        label='市話'
                        className="hide-required"
                        placeholder='輸入市話'
                        value={this.state.form.phone.value}
                        validationErrors='請輸入正確的市話'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='cellphone'
                        required
                        label='手機'
                        className="hide-required"
                        placeholder='輸入手機'
                        value={this.state.form.cellphone.value}
                        validationErrors='請輸入正確的手機'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='email'
                        required={true}
                        label='Email'
                        className="hide-required"
                        placeholder='輸入Email'
                        value={this.state.form.email.value}
                        validationErrors='請輸入正確的Email'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className="page-action mb-0 w-100">
                        <Button btnStyle='primary' size="large" className='mb-0' onClick={() => { this.submit() }}>確認送出</Button>
                      </div>
                    </Grid>
                  </Grid>

                  {this.state.form.receipt.value === '1' ? <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='taxtID'
                        required
                        label='統一編號'
                        className="hide-required"
                        placeholder='輸入統一編號'
                        value={this.state.form.taxtID.value}
                        validationErrors='請輸入正確的統一編號'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='company'
                        label='公司名稱(選填)'
                        className="hide-required"
                        placeholder='輸入公司名稱'
                        value={this.state.form.company.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className="page-action mb-0 w-100">
                        <Button btnStyle='primary' size="large" className='mb-0' onClick={() => { this.submit() }}>確認送出</Button>
                      </div>
                    </Grid>
                  </Grid> : null}
                </div>
                : null
              }

              {this.state.form.fixup.value === '2' ?
                <div className="fui-container">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} className='position-relative'>
                      <div className="hide-required form-group">
                        <LabelInput
                          name='imei'
                          required
                          label='IMEI(手機序號)'
                          className="hide-required mb-2"
                          placeholder='請輸入IMEI'
                          value={this.state.form.imei.value}
                          validationErrors='請輸入正確的IMEI'
                          onChange={this.onChange}
                        />
                        <small className='is-text-gray100'>您可點此了解
                <a onClick={() => { this.openModal('introIMEI') }} className="is-text-black50 hover-underline-link">如何查詢手機IMEI</a>、
                <a onClick={() => { this.openModal('iOS') }} className="is-text-black50 hover-underline-link">如何關閉find my iPhone</a>、
                <a onClick={() => { this.openModal('android') }} className="is-text-black50 hover-underline-link">如何移除Google鎖</a>
                        </small>
                      </div>
                      <a onClick={() => { this.searchIMEI() }} style={{ position: 'absolute', right: 15, top: 14 }} className="d-block d-md-none is-text-medium is-text-accent">查詢IMEI</a>
                    </Grid>
                    <a onClick={() => { this.searchIMEI() }} style={{ marginTop: 68 }} className="d-none d-md-block is-text-medium is-text-accent">
                      查詢IMEI
          </a>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group">
                        <label>廠牌</label>
                        <Select
                          label="請選擇廠牌"
                          name='brand'
                          options={[
                            { text: 'Apple', value: 'Apple' },
                            { text: 'Samsung', value: 'Samsung' },
                          ]}
                          value={this.state.form.brand.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group">
                        <label>機型</label>
                        <Select
                          label="請選擇機型"
                          name='model'
                          options={[
                            { text: 'iPhone X', value: 'iPhone X' },
                            { text: 'iPhone XR', value: 'iPhone XR' },
                          ]}
                          value={this.state.form.model.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="hide-required form-group mb-0">
                        <LabelTextarea
                          placeholder='請輸入內容'
                          label='故障原因(選填)'
                          className='mb-2'
                          maxLength={200}
                          name='issue'
                          value={this.state.form.issue.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='name'
                        required
                        label='姓名'
                        className="hide-required"
                        placeholder='輸入姓名'
                        value={this.state.form.name.value}
                        validationErrors='請輸入正確的姓名'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='phone'
                        required
                        label='市話'
                        className="hide-required"
                        placeholder='輸入市話'
                        value={this.state.form.phone.value}
                        validationErrors='請輸入正確的市話'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='cellphone'
                        required
                        label='手機'
                        className="hide-required"
                        placeholder='輸入手機'
                        value={this.state.form.cellphone.value}
                        validationErrors='請輸入正確的手機'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='email'
                        required={true}
                        label='Email'
                        className="hide-required"
                        placeholder='輸入Email'
                        value={this.state.form.email.value}
                        validationErrors='請輸入正確的Email'
                        onChange={this.onChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <hr className='m-0' />
                    </Grid>

                    <Grid item xs={12}>
                      <h4 className="is-text-darkgray50 mt-md-5 mt-2 mb-0">
                        請填寫預約資訊
            </h4>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group mb-4">
                        <label>維修站</label>
                        <Select
                          label="請選擇"
                          name='fixStation'
                          options={[
                            { text: 'Apple', value: 'Apple' },
                            { text: 'Samsung', value: 'Samsung' },
                          ]}
                          value={this.state.form.fixStation.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className='d-none d-md-block'>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group">
                        <label>可預約日期</label>
                        <Select
                          label="2020/07/07"
                          name='reservationDate'
                          options={[
                            { text: '2020/07/07', value: '2020/07/07' },
                            { text: '2020/07/08', value: '2020/07/08' },
                          ]}
                          value={this.state.form.reservationDate.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group">
                        <label>可預約時段</label>
                        <Select
                          label="請選擇"
                          name='reservationTime'
                          options={[
                            { text: '09:00', value: '09:00' },
                            { text: '10:00', value: '10:00' },
                          ]}
                          value={this.state.form.reservationTime.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className="page-action mb-0 w-100">
                        <Button btnStyle='primary' size="large" className='mb-0' onClick={() => { this.submit() }}>確認送出</Button>
                      </div>
                    </Grid>
                  </Grid>

                  {this.state.form.receipt.value === '1' ? <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='taxtID'
                        required
                        label='統一編號'
                        className="hide-required"
                        placeholder='輸入統一編號'
                        value={this.state.form.taxtID.value}
                        validationErrors='請輸入正確的統一編號'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        name='company'
                        label='公司名稱(選填)'
                        className="hide-required"
                        placeholder='輸入公司名稱'
                        value={this.state.form.company.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className="page-action mb-0 w-100">
                        <Button btnStyle='primary' size="large" className='mb-0' onClick={() => { this.submit() }}>確認送出</Button>
                      </div>
                    </Grid>
                  </Grid> : null}
                </div>
                : null}

            </section>
            : null
          }

          {this.state.step === 1 ? <section>
            <div className='fui-container'>
              <div className="paper mb-md-8">
                <RadioGroupComplex
                  label='請選擇報修方式'
                  name="fixup"
                  className='mb-0 mt-md-4 is-3-in-row'
                  options={[
                    {
                      label: '到府收送',
                      content: '遠傳一般會員，選擇到府收送服務，將會先酌收物流運費 <span class="is-text-accent">$330</span>。',
                      value: '0'
                    },
                    {
                      label: '親送門市',
                      content: '於網路填完維修資料後，可送修遠傳門市，享有快速收件服務。',
                      value: '1'
                    },
                    {
                      label: '親送維修站',
                      content: '於網路填完維修資料後，可預約維修站享有快速收件服務。',
                      value: '2'
                    }
                  ]}
                  default={this.state.form.fixup.value}
                  onChange={this.onChange}
                />
                <small className="is-text-gray100 mb-md-3 d-block mt-1">全台遠傳門市/全虹維修站提供維修代收服務，不限通路所銷售手機、平板，原廠授權，原廠零件，品質保證。<br />※小米商品僅限遠傳販售</small>
              </div>
              <CheckboxCollapse
                name='agree'
                label='我已閱讀維修服務條款，並同意條款內容。'
                validationError='完整內容收合與展開'
                validations={{
                  isChecked: form => this.state.form.agree
                }}
                checked={this.state.form.agree.value}
                value={this.state.form.agree.value}
                getCheckbox={(e) => this.onChange('agree', e)}
                collapseContent={`
                <small>
                維修服務條款<br/>
                1.本人瞭解並同意遠傳一般會員選擇到府收送服務將會先酌收物流運費NTD$330 ；遠傳白金會員享有到府收送免收物流運費服務。<br/>
                2.基於維修時效考量，本人同意全虹/德誼股份有限公司(以下簡稱維修公司)得視故障程度，依其專業判斷逕行採取更換機板方式維修，無須另行取得本人同意；惟維修公司應將更換後機板之IMEI序號碼連同完修商品併交予本人。<br/>
                3.請於網路報修後至門市或維修站完成實機送修；到府取送之客戶，將由物流人員與本人連絡後，到府取件。以上未於14天內完成交付實機， 系統將自動刪除此筆預約資料。<br/>
                4.本人瞭解並同意送修商品符合保固條件時，將由維修公司免費維修；反之，送修商品如未符合保固條件(包括但不限於逾保固期限)時，維修公司將收取檢測與維修費用。<br/>
                5.本人瞭解採取更換機板方式進行維修時，維修公司對於原故障機板即取得所有權。<br/>
                6.本人交付送修商品予維修公司時，已確認送修商品內含之軟體及資料(包括但不限於本人或第三人之個人資料)均已清除且予以備份，避免軟體及資料的完整性於維修過程中受到影響；維修公司對於維修期間的軟體及資料毀損、滅失等情形將不承擔任何責任。<br/>
                7.本人瞭解送修商品於維修過程須進行拆除外殼、更換零件等程序，該送修商品如有包膜、螢幕保貼或其他添增之配件可能因而受損，維修公司對此種損害不負賠償責任。<br/>
                8.本人瞭解並同意維修公司於送修商品完修後，得安裝原廠提供之最新版本軟體，其與舊版軟體可能存有差異性。<br/>
                9.APPLE iPhone手機送修前請取消開機密碼 /IOS 7.0以上版本需關閉尋找我的iPhone功能。<br/>
                10.A、產品經確認付費維修，將無法反悔不修。B、報價不修，將有可能無法恢復至送修時之狀態<br/>。
                11. 維修商品檢測費用標準：送修商品如未符合保固條件，過保固及保固內人損報價後放棄維修者，將收取維修檢測費(手機：300元(含稅)，電腦產品：1260元(含稅)]。<br/>
                12.自本人交付實機起算6個月後，本人仍未取回送修商品時，維修公司得逕行處分。<br/>
                13.本人同意維修申請單上所載之本人姓名、連絡電話、地址、Email、送修機型、送修日期、故障原因等相關資料，維修公司及原廠得於營運期間與營業所在地區內，基於提供保固及維修等相關服務、調查統計分析、行銷等特定目的及合理關聯範圍內（包括但不限於提供保固及維護服務時徵詢本人意見、完修後通知取件、發送服務滿意度調查、維護本人送修紀錄與基本資料、提供最新保固及維修資訊等）為蒐集、處理及利用。如本人拒絕提供前開相關資料，維修公司及原廠將無法提供完整之保固及維修服務。本人得透過客服專線<br/>
                0800-058-885向維修公司行使如下權利：請求查詢、提供閱覽或製給複製本，或請求補充更正、請求停止蒐集、處理、利用及刪除等權利。如本人留存資料錯誤疏漏，或因本人請求刪除、停止處理利用該等資料，致維修公司無法聯繫本人或提供保固、維修等服務時，遠傳及維修公司將不承擔任何責任。<br/>
                14.可依據維修單號上網查詢維修進度: http://www.fetnet.net， 本人有任何維修相關問題，歡迎致電維修中心洽詢。
                </small>
                `}
              />

              <div className='page-action mt-md-4 mt-3'>
                <Button btnStyle='primary' className={`mb-0 ${this.state.form.agree.value ? '' : 'disabled'}`} size='large' onClick={() => { this.step2() }}>
                  下一步
                </Button>
              </div>
            </div>
          </section> : null}
        </Formsy>
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className={`fui-prompt-modal ${this.state.modalType === 'isIMEI' || this.state.modalType === 'notIMEI' || this.state.modalType === 'hint' ? '' : 'is-large'}`}>
          <div className='fui-prompt-modal-body'>

            {/* <h5 className='align-center'>如何查詢手機IMEI說明</h5> */}
            {this.state.modalType === 'isIMEI' ?
              <div>
                <h5 className='align-center'>IMEI查詢</h5>
                <p>您的電話為遠傳、全虹出貨，請至原購買門市送修</p>
                <div className="page-action">
                  <Button btnStyle='primary' className='mb-0' onClick={() => { this.setState({ modalOpen: false, modalType: '' }) }}>我瞭解了</Button>
                </div>
              </div>
              : null}
            {this.state.modalType === 'hint' ?
              <div>
                <h5 className='align-center'>到府收送須知</h5>
                <p>遠傳一般會員，選擇到府收送服務將會先酌收物流費 $330</p>
                <div className="page-action">
                  <Button btnStyle='primary' className='mb-0' onClick={() => { this.setState({ modalOpen: false, modalType: '', step: 2 }); this.scrollToTop() }}>確定</Button>
                </div>
              </div>
              : null}
            {this.state.modalType === 'notIMEI' ?
              <div>
                <h5 className='align-center'>IMEI查詢</h5>
                <p>查無保固資訊，若非遠傳、全虹出貨，請直接至遠傳門市或全虹維修中心送修</p>
                <div className="page-action">
                  <Button btnStyle='primary' className='mb-0' onClick={() => { this.setState({ modalOpen: false, modalType: '' }) }}>我瞭解了</Button>
                </div>
              </div>
              : null}
            {/* {this.state.modalType === 'IMEI' ? <p>非遠傳、全虹出貨，請至原購買門市送修</p> : null} */}
            {this.state.modalType === 'iOS' ? <div>
              <h5 className='mb-md-0 mb-4'>如何關閉find my iPhone</h5>
              <div className="scrollable-modal-body is-bg-white pt-md-1">
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <figure className="fui-figure is-inline mb-0" style={{ width: '100%' }}>
                      <img
                        src="/resources/cbu/img/ios-find-my-iphone-step-1@2x.png"
                        alt="ios-find-my-iphone-step-1"
                      />
                      <figcaption className='is-accent mb-0'>
                        Step 1：點選最上圖手機名稱
                      </figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <figure className="fui-figure is-inline mb-0" style={{ width: '100%' }}>
                      <img
                        src="/resources/cbu/img/ios-find-my-iphone-step-2@2x.png"
                        alt="ios-find-my-iphone-step-2"
                      />
                      <figcaption className='is-accent mb-0'>
                        Step 2：點選尋找
                      </figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <figure className="fui-figure is-inline mb-0" style={{ width: '100%' }}>
                      <img
                        src="/resources/cbu/img/ios-find-my-iphone-step-3@2x.png"
                        alt="ios-find-my-iphone-step-3"
                      />
                      <figcaption className='is-accent mb-0'>
                        Step 3：點選尋找我的 iPhone
                      </figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <figure className="fui-figure is-inline mb-0" style={{ width: '100%' }}>
                      <img
                        src="/resources/cbu/img/ios-find-my-iphone-step-4@2x.png"
                        alt="ios-find-my-iphone-step-4"
                      />
                      <figcaption className='is-accent mb-0'>
                        Step 4：輸入 Apple ID 密碼
                      </figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <figure className="fui-figure is-inline mb-0" style={{ width: '100%' }}>
                      <img
                        src="/resources/cbu/img/ios-find-my-iphone-step-5@2x.png"
                        alt="ios-find-my-iphone-step-5"
                      />
                      <figcaption className='is-accent mb-0'>
                        Step 5：開關轉灰，即為關閉
                      </figcaption>
                    </figure>
                  </Grid>
                </Grid>
              </div>
              <div className="page-action">
                <Button btnStyle='primary' className='mb-0' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
              </div>
            </div> : null}
            {this.state.modalType === 'android' ? <div>
              <h5 className='mb-md-0 mb-4'>如何刪除Google鎖（刪除Google 鎖與刪除帳戶方式相同）</h5>
              <div className="scrollable-modal-body is-bg-white pt-md-1">
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <figure className="fui-figure is-inline mb-0" style={{ width: '100%' }}>
                      <img
                        src="/resources/cbu/img/android-find-my-iphone-step-1@2x.png"
                        alt="ios-find-my-iphone-step-1"
                      />
                      <figcaption className='is-accent mb-0'>
                        Step 1：點選雲端與帳號
                      </figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <figure className="fui-figure is-inline mb-0" style={{ width: '100%' }}>
                      <img
                        src="/resources/cbu/img/android-find-my-iphone-step-2@2x.png"
                        alt="android-find-my-iphone-step-2"
                      />
                      <figcaption className='is-accent mb-0'>
                        Step 2：點選帳號
                      </figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <figure className="fui-figure is-inline mb-0" style={{ width: '100%' }}>
                      <img
                        src="/resources/cbu/img/android-find-my-iphone-step-3@2x.png"
                        alt="android-find-my-iphone-step-3"
                      />
                      <figcaption className='is-accent mb-0'>
                        Step 3：點選 Google 帳號
                      </figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <figure className="fui-figure is-inline mb-0" style={{ width: '100%' }}>
                      <img
                        src="/resources/cbu/img/android-find-my-iphone-step-4@2x.png"
                        alt="android-find-my-iphone-step-4"
                      />
                      <figcaption className='is-accent mb-0'>
                        刪除帳號即可刪除 Google 鎖
                      </figcaption>
                    </figure>
                  </Grid>
                </Grid>
              </div>
              <div className="page-action">
                <Button btnStyle='primary' className='mb-0' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
              </div>
            </div> : null}
            {this.state.modalType === 'introIMEI' ? <div>
              <h5 className='mb-md-0 mb-4'>如何查詢手機IMEI說明</h5>
              <div className="scrollable-modal-body is-bg-white">
                <Grid container justify='center'>
                  <Grid item xs={12} md={6}>
                    <div className="d-flex flex-align-center mb-2">
                      <img style={{ width: this.state.isMobile ? 130 : 160 }} src="/resources/cbu/img/dial-query@2x.png" alt="dial-query" />
                      <div className="d-flex flex-column ml-4">
                        <h5 className='align-left mt-0 mb-md-1 mb-0'>撥號查詢</h5>
                        <div className="body-1 is-text-darkgray50">
                          撥號畫面輸入＊# 06 # 查詢
                    </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="d-flex flex-align-center mb-2">
                      <img style={{ width: this.state.isMobile ? 130 : 160 }} src="/resources/cbu/img/phone-sim-card@2x.png" alt="phone-sim-card" />
                      <div className="d-flex flex-column ml-4">
                        <h5 className='align-left mt-0 mb-md-1 mb-0'>SIM卡卡匣</h5>
                        <div className="body-1 is-text-darkgray50">
                          從 SIM 卡匣上查詢 IMEI
                    </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="d-flex flex-align-center mb-0">
                      <img style={{ width: this.state.isMobile ? 130 : 160 }} src="/resources/cbu/img/phone-body@2x.png" alt="phone-body" />
                      <div className="d-flex flex-column ml-4">
                        <h5 className='align-left mt-0 mb-md-1 mb-0'>機身或電蓋上</h5>
                        <div className="body-1 is-text-darkgray50">
                          從手機機身或電蓋上查詢IMEI
                    </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="d-flex flex-align-center mb-0">
                      <img style={{ width: this.state.isMobile ? 130 : 160 }} src="/resources/cbu/img/phone-setting@2x.png" alt="phone-setting" />
                      <div className="d-flex flex-column ml-4">
                        <h5 className='align-left mt-0 mb-md-1 mb-0'>手機系統查詢</h5>
                        <a style={{ width: 'fit-content' }} onClick={() => { this.setState({ modalType: 'appleimei' }) }} className="body-1 underline-link is-text-black50 is-text-medium d-table">
                          Apple iPhone
                        </a>
                        <a style={{ width: 'fit-content' }} onClick={() => { this.setState({ modalType: 'androidimei' }) }} className="body-1 underline-link is-text-black50 is-text-medium d-table">
                          Android
                        </a>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="page-action">
                <Button btnStyle='primary' className='mb-0' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
              </div>
            </div> : null}
            {
              this.state.modalType === 'appleimei' ?
                <main className="prepaid flex-align-center d-flex" style={{ margin: "0 auto" }}>
                  <Grid item xs={12} md={12} className='mb-4' id="howTo">
                    <h5 className='align-left mt-0 mb-md-1 mb-0'>Apple iPhone 手機系統查詢 IMEI</h5>
                    <div className="scrollable-modal-body is-bg-white pb-0">
                      <p className='mb-4 mt-0'>從手機[設定] > [一般] > [關於本機]功能中查詢。</p>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                          <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/ios-imei-step-1@2x.jpg' caption='Step 1' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/ios-imei-step-2@2x.jpg' caption='Step 2' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/ios-imei-step-3@2x.jpg' caption='Step 3' />
                        </Grid>
                      </Grid>
                    </div>
                    <div className="page-action">
                      <Button btnStyle='primary' className='mb-0' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
                      <Button btnStyle='secondary' className='mb-0' onClick={() => { this.setState({ modalType: 'introIMEI' }) }}>返回上一頁</Button>
                    </div>
                  </Grid>
                </main> : null
            }
            {
              this.state.modalType === 'androidimei' ?
                <main className="prepaid flex-align-center d-flex" style={{ margin: "0 auto" }}>
                  <Grid item xs={12} md={12} className='mb-4' id="howTo">
                    <h5 className='align-left mt-0 mb-md-1 mb-0'>Android 系統查詢 IMEI</h5>
                    <div className="scrollable-modal-body is-bg-white pb-0">
                      <p className='mb-4 mt-0'>從手機[設定] >[系統] >[關於手機] >[狀態] > [IMEI資訊] 功能中查詢</p>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                          <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/android-imei-step-1@2x.jpg' caption='Step 1' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/android-imei-step-2@2x.jpg' caption='Step 2' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <PanelFigure1 className="m-0" inline={false} image='/resources/cbu/img/android-imei-step-3@2x.jpg' caption='Step 3' />
                        </Grid>
                      </Grid>
                    </div>
                    <div className="page-action">
                      <Button btnStyle='primary' className='mb-0' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
                      <Button btnStyle='secondary' className='mb-0' onClick={() => { this.setState({ modalType: 'introIMEI' }) }}>返回上一頁</Button>
                    </div>
                  </Grid>
                </main> : null
            }

          </div>
        </Modal>
      </main>
    );
  }
}

export default FixQuery;