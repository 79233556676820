import React from 'react';
import ImageModal from '../../components/ImageModal'

import PropTypes from 'prop-types';

class PanelFigure1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    render() {
        return (
            <div className={`${this.props.inline ? 'd-inline' : ''}`}>
                <figure className={`fui-figure ${this.props.inline ? 'is-inline' : ''} ${this.props.className ? this.props.className : ''}`}>
                    <img
                        src={process.env.PUBLIC_URL + this.props.image}
                        srcSet={process.env.PUBLIC_URL + (this.props.retinaImage || this.props.image)}
                        alt={this.props.image}
                        style={{ maxWidth: this.props.maxWidth }}
                        onClick={() => this.setState({ isOpen: true })}
                    />
                    {
                        this.props.caption ? (
                            <figcaption>{this.props.caption}</figcaption>
                        ) : ''
                    }
                </figure>
                {
                    this.state.isOpen && (
                        <ImageModal
                            mainSrc={this.props.retinaImage || this.props.image}
                            onCloseRequest={() => this.setState({ isOpen: false })} />
                    )
                }
            </div>
        )
    }
}

PanelFigure1.propTypes = {
    className: PropTypes.string,
    inline: PropTypes.bool,
    retinaImage: PropTypes.string,
    image: PropTypes.string.isRequired,
    caption: PropTypes.string,
    maxWidth: PropTypes.number
}

export default PanelFigure1