import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import Link from '../../Link';
import SectionBanner2 from './SectionBanner2';
import PropTypes from 'prop-types'
class BannerWithLink extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <section id={this.props.id} className="pb-0 banner-with-link">
        <h2 className="">{this.props.title}</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <SectionBanner2
              image={this.props.image}
              title={this.props.bannerTitle}
              description={this.props.bannerDescription}
              action={this.props.bannerAction}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {this.props.link.map((item) => {
              return <div className="paper" key={item.title}>
                <Link target={item.target} to={item.link}>
                  {item.title}
                </Link>
                <img src="/resources/cbu/prepaid/images/external.svg" alt="external" />
              </div>
            })}
          </Grid>
        </Grid>
      </section>
    );
  }
}

BannerWithLink.propTypes = {
  title: PropTypes.string,
}

export default BannerWithLink;