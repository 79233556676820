import React from 'react';
import Button from '../../components/Button';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import RadioGroup from '../../components/form/RadioGroup';
import CheckGroup from '../../components/form/CheckGroup';
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import CollapsePanel from '../../components/panel/CollapsePanel';
import PanelTip from '../../components/panelContent/PanelTip';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import * as Validation from '../../utils/validation';
let data = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '0913456789',
    '0913456780',
    '0913456781',
    '0913456789',
    '0913456780',
    '0913456781',
    '0913456789',
    '0913456780',
    '0913456781',
    '0913456789',
    '0913456780',
    '999',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '0913456789',
    '0913456780',
    '0913456781',
    '0913456789',
    '0913456780',
    '0913456781',
    '0913456780',
    '0913456781',
]

let currentPageItems = []

class Blacklist extends React.Component {
    constructor(props) {
        super(props);
        this.collapse = React.createRef();
        this.onChangePage = this.onChangePage.bind(this);
        this.state = {
            key: 0,
            modalOpen: false,
            currentDeletion: '',
            currentDeleteNumber: null,
            minHeight: window.innerWidth < 768 ? 240 : 160,
            collapseHeight: 0,
            deleteResult: false,
            collapseOpen: false,
            blacklist: true,
            form: {
                blacklist: [],
                setting: {
                    value: '',
                    text: ''
                }
            },
            currentPageItems: [],
            initialPage: 1,
            page: [],
            canSubmit: false,
            open: false,
            minHeight: 0,
            currentPage: 1
        }
    }
    onChange = (name, value) => {
        let newForm = Object.assign(this.state.form);
        newForm[name].value = value;
        this.setState({
            form: newForm,
        });
    };
    toggleEditable = (name, e, item, i, save) => {
        // console.log(name, item, save);

        if (!save) {
            let newForm = Object.assign(this.state.form);
            newForm[name][i].editable = !item.editable;
            newForm[name][i].tempValue = null
            this.setState({
                form: newForm,
            });
        } else {
            let newForm = Object.assign(this.state.form);
            newForm[name][i].editable = !item.editable;
            newForm[name][i].value = item.tempValue
            this.setState({
                form: newForm,
            });
        }
    };
    deleteItem = (name, e, item, i) => {
        // console.log(name, e, item, i);

        this.setState({
            modalOpen: true,
            currentDeletion: i,
            currentDeleteNumber: item.value
        })
    };
    confirm = () => {
        this.setState({
            modalOpen: false,
            deleteResult: false,
            currentDeletion: null,
            currentDeleteNumber: null
        });
    }
    confirmDelete = () => {
        let newForm = Object.assign(this.state.form);
        newForm['blacklist'].splice(this.state.currentDeletion, 1)
        this.setState({
            form: newForm,
            modalOpen: true,
            deleteResult: true,
            currentDeletion: null
        });

        newForm['blacklist'][this.state.currentDeletion].tempValue = null;
        this.setState({
            form: newForm,
        });


        this.getCurrentItem(parseInt(Math.floor((this.state.form.blacklist.length - 1) / 15) + 1))
        this.getPaginationData()
        this.forceUpdate()
    }
    onChangeBlacklist = (name, item, i) => {
        // console.log(name, item, i);
        let newForm = Object.assign(this.state.form);
        newForm[name][i].tempValue = item;
        this.setState({
            form: newForm,
        });
    };
    defaultFormOnChange = (name, value) => {
        let result = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        // console.log(result);
        let newForm = Object.assign(this.state.form);
        newForm[name].value = result;
        this.setState({
            form: newForm,
        });
    }
    enableButton = () => {
        this.setState({
            canSubmit: true
        })
    };

    disableButton = () => {
        this.setState({
            canSubmit: false
        })
    };
    addItem = () => {
        let form = Object.assign(this.state.form);
        form.blacklist.push({ value: '', editable: true, new: true })
        this.setState({
            form: form
        })
        // this.onChangePage(this.state.currentPageItems, parseInt((this.state.form.blacklist.length - 1) / 15) + 1)
        // console.log(parseInt(Math.floor((this.state.form.blacklist.length - 1) / 15) + 1));
        this.getCurrentItem(parseInt(Math.floor((this.state.form.blacklist.length - 1) / 15) + 1))
        this.getPaginationData()
        this.forceUpdate()
    }
    onChangePage(pageOfItems, selectedPage) {
        // console.log({ pageOfItems, selectedPage });
        this.setState({
            currentPageItems: pageOfItems
        })
        // currentPageItems = pageOfItems
        // console.log(`currentPageItems:`, currentPageItems);
        // this.forceUpdate()
    }
    componentDidMount() {
        this.setCollapseHeight();

        let temp = []
        data.map((item, index) => {
            temp.push({
                value: item,
                editable: false,
                tempValue: item,
                new: false
            })
        })
        // console.log(temp);
        let newForm = Object.assign(this.state.form);
        newForm['blacklist'] = temp;

        this.setState({
            form: newForm
        });
        this.getCurrentItem()
        this.getPaginationData()
        window.addEventListener('resize', e => {
            this.setCollapseHeight()
        })

        this.forceUpdate()
    }
    getCurrentItem = (val) => {
        let curr = []
        let result = []
        this.state.form.blacklist.map((item, index) => {
            curr.push(index)
        })
        // // console.log(`val: `, val);
        // console.log(`curr: `, curr);

        result = curr.filter((item, index) => {
            if (val === 1) {
                return item < 15
            } else if (val === 2) {
                return item < 30 && item > 14
            } else if (val === 3) {
                return item < 45 && item > 29
            } else if (val === 4) {
                return item > 44 && item < 50
            } else {
                return item < 15
            }
        })

        // console.log(`result: `, result);

        this.setState({
            currentPageItems: result,
            currentPage: !!val ? val : 1
        });
    }
    getPaginationData = () => {
        let page = []
        this.state.form.blacklist.map((item, index) => {
            page.push((index + 1).toString())
        })
        this.setState({
            page: page
        });
    }
    openCollapse = () => {
        this.setCollapseHeight();
        this.setState({ open: true })
    }

    setCollapseHeight() {
        this.setState({
            collapseHeight: this.collapse.current.children[0].clientHeight
        });
    }

    getDisplayItem = (i) => {
        let result = false
        this.state.currentPageItems.map(item => {
            if (item === i) {
                result = true
            }
        })
        return result ? 'd-block' : 'd-none'
    }
    render() {
        return (
            <main>
                <NavAnchor
                    pageTitle='漫遊與門號服務'
                    button={{
                        text: '回個人專區',
                        link: '#'
                    }} />
                <FormBanner
                    title="過濾名單設定"
                    image={{
                        md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
                        sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
                    }}
                />

                <section className="pt-md-4 pt-2 no-bg">
                    <div className="fui-container">
                        <div className="fui-segments no-shadow is-bg-initial">
                            {!this.state.blacklist ? <div>
                                <div className="paper mt-md-4 mt-2 mb-md-7 mb-2 pb-md-7 pb-4">
                                    <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-4 mb-2">
                                        <h4 className='m-0 is-text-medium'>黑名單設定</h4>
                                    </div>
                                    <p className="body is-text-darkgray50 m-0">您尚未開通來電過濾服務</p>
                                </div>
                                <div className='page-action'>
                                    <Button
                                        onClick={() => { console.log('go'); }}
                                        className={`mb-0`}
                                        btnStyle='primary'
                                        size='large'>
                                        回上一頁
                                </Button>
                                </div>
                            </div> : <div>
                                    <div className="paper mt-md-4 mt-2 mb-0 pb-2">
                                        <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                                            <CheckGroup
                                                label='接聽設定'
                                                name="setting"
                                                className='mb-0 mt-md-4'
                                                options={[
                                                    { label: '接聽所有隱藏號碼來電', value: 'allHiddenNumbers' },
                                                    { label: '接聽所有國際號碼來電', value: 'allNationalNumbers' }
                                                ]}
                                                default={this.state.form.setting.value}
                                                onChange={this.onChange}
                                            />
                                            <h5 className='is-text-medium'>黑名單設定(最多50名)</h5>
                                            <div className="car-information-list" key={this.state.key}>
                                                {
                                                    this.state.form.blacklist.map((item, i) => (
                                                        <div className={`car-information-item ${this.getDisplayItem(i)}`} key={`ar-information-item-${i}`}>
                                                            <div className="car-information-header">
                                                                <div className="content">序號 {i + 1}</div>
                                                            </div>
                                                            <div className="car-information-body">
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={12} md={6}>
                                                                        <div className="form-group">
                                                                            <label>黑名單號碼</label>
                                                                            <div>
                                                                                <input
                                                                                    type='text'
                                                                                    name='blacklist'
                                                                                    disabled={!item.editable}
                                                                                    value={this.state.form.blacklist[i].tempValue !== null ? this.state.form.blacklist[i].tempValue : this.state.form.blacklist[i].value}
                                                                                    onChange={(e) => this.onChangeBlacklist('blacklist', e.target.value, i)}
                                                                                />
                                                                            </div>
                                                                            {item.editable && !item.new ? <div className='page-action'>
                                                                                <Button
                                                                                    onClick={(e) => this.toggleEditable('blacklist', e, item, i, true)}
                                                                                    className={!!this.state.form.blacklist[i].tempValue ? '' : 'disabled'}
                                                                                    btnStyle='primary'
                                                                                >
                                                                                    確定修改
                                                                            </Button>
                                                                                <Button
                                                                                    onClick={(e) => this.toggleEditable('blacklist', e, item, i, false)}
                                                                                    btnStyle='secondary'
                                                                                >
                                                                                    取消
                                                                            </Button>
                                                                            </div> : null}
                                                                        </div>
                                                                    </Grid>
                                                                    {item.editable && item.new ? null : <Grid item xs={12} sm={12} md={6}>
                                                                        <div className="form-group">
                                                                            <div className='page-action mt-md-6 mt-2'>
                                                                                <Button
                                                                                    onClick={(e) => this.toggleEditable('blacklist', e, item, i, false)}
                                                                                    className="mb-md-2 mb-2"
                                                                                    btnStyle='secondary'>
                                                                                    修改
                                                                        </Button>
                                                                                <Button
                                                                                    onClick={(e) => this.deleteItem('blacklist', e, item, i)}
                                                                                    className="mb-md-2 mb-0"
                                                                                    btnStyle='secondary'>
                                                                                    刪除
                                                                        </Button>
                                                                            </div>
                                                                        </div>
                                                                    </Grid>}
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                            {this.state.form.blacklist.length < 50 ? <div className="car-information-action">
                                                <div role="button" className="fui-button d-block is-large" onClick={this.addItem}>
                                                    <span className='text'>新增黑名單</span>
                                                    <i className='icon-plus'></i>
                                                </div>
                                            </div> : null}
                                            {this.state.page.length > 0 ? <ul className="fui-pagination">
                                                <li className={`previous ${this.state.currentPage === 1 ? 'disabled' : ''}`}>
                                                    <a onClick={() => this.getCurrentItem(1)}>
                                                        <i className="icon-first"></i>
                                                    </a>
                                                </li>
                                                <li
                                                    className={`previous ${this.state.currentPage === 1 ? 'disabled' : ''}`}
                                                    onClick={() => { this.getCurrentItem(this.state.currentPage - 1) }}
                                                >
                                                    <a>
                                                        <i className="icon-chevron-left"></i>
                                                    </a>
                                                </li>
                                                <li className={this.state.currentPage === 1 ? 'selected' : ''}>
                                                    <a onClick={() => this.getCurrentItem(1)}>1</a>
                                                </li>
                                                {this.state.page.length > 15 ? <li className={this.state.currentPage === 2 ? 'selected' : ''}>
                                                    <a onClick={() => this.getCurrentItem(2)}>2</a>
                                                </li>
                                                    : null}
                                                {this.state.page.length > 30 ? <li className={this.state.currentPage === 3 ? 'selected' : ''}>
                                                    <a onClick={() => this.getCurrentItem(3)}>3</a>
                                                </li>
                                                    : null}
                                                {this.state.page.length > 45 ? <li className={this.state.currentPage === 4 ? 'selected' : ''}>
                                                    <a onClick={() => this.getCurrentItem(4)}>4</a>
                                                </li>
                                                    : null}
                                                <li
                                                    className={`next ${this.state.currentPage === parseInt(Math.floor((this.state.form.blacklist.length - 1) / 15) + 1) ? 'disabled' : ''}`}
                                                    onClick={() => { this.getCurrentItem(this.state.currentPage + 1) }}
                                                >
                                                    <a>
                                                        <i className="icon-chevron-right"></i>
                                                    </a>
                                                </li>
                                                <li className={`next ${this.state.currentPage === parseInt(Math.floor((this.state.form.blacklist.length - 1) / 15) + 1) ? 'disabled' : ''}`}>
                                                    <a onClick={() => { this.getCurrentItem(parseInt(Math.floor((this.state.form.blacklist.length - 1) / 15) + 1)) }}>
                                                        <i className="icon-last"></i></a>
                                                </li>
                                            </ul>
                                                : null}
                                        </Formsy>
                                    </div>
                                    <div className="collapse-info">
                                        <div className="main">
                                            <div className="fui-collapse" ref={this.collapse} style={{
                                                height: `${this.state.open ? this.state.collapseHeight : 0}px`
                                            }}>
                                                <div className="fui-collapse-body">
                                                    <div className="d-block">
                                                        <h5 className="m-0">黑名單設定說明</h5>
                                                        <ul className="green-dot-list is-text-black50">
                                                            <li>新增：將你想過瀟掉的號碼填入空攔位中，可按「新增名單」一次填寫多筆資料，最多填寫50筆門號，填完後按下「儲存變更」即可。</li>
                                                            <li>修改：若想修改黑名單號碼，可按「修改」，這時原本無法更改的號碼將變成可修改的狀態，修改完成後按下「確認修改」，並按下「儲存變更」即可。</li>
                                                            <li>刪除：如果想要刪除已經設定好的號碼，可按「刪除」，確認刪除成功後，按下「儲存變更即可」。</li>
                                                            <li>門號請依來電時顯示之號碼輸入：
                                                        <ol>
                                                                    <li>(1)行動電話需為十碼,例扣：0936-xxx-xxx</li>
                                                                    <li>(2)一般室話需含區域號碼,例如：02-xxxxxxx</li>
                                                                    <li>(3)國際電話需由國碼開始輸入：例如:86137-xxxxxxxxxx(如行動電話號碼及區域碼首位数宇為0,需去除)。</li>
                                                                </ol>
                                                            </li>
                                                            <li>無法顯示號碼來電與刻意隱藏來電：如果你不希望系統過濾掉「無法顯示號碼」以及「刻意用手機際就號碼」的來電，請勾選「接聽所有隱藏號碼來電」的選項，勾選後記得按下「儲存變更」權。</li>
                                                            <li>拒接國際電話來電：如果你不想接到國際電話的來電,請取消勾選「接聽所有國際號碼來電」的選項，按下「儲存變更」即完成設定。</li>
                                                            <li>目前暫無法提供智慧型手機及平板電腦設定黑名單。</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="extra">
                                            {
                                                this.state.open ? (
                                                    <div className="expand-btn" role="button" onClick={e => this.setState({ open: false })}>
                                                        <span>查看黑名單設定說明</span>
                                                        <i className="icon-minus"></i>
                                                    </div>
                                                ) : (
                                                        <div className="expand-btn" onClick={this.openCollapse}>
                                                            <span>查看黑名單設定說明</span>
                                                            <i className="icon-plus"></i>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div >
                                    <div className='page-action'>
                                        <Button
                                            onClick={() => { console.log('go'); }}
                                            onClick={() => { this.props.history.push('/filtering/blacklist-success') }}
                                            btnStyle='primary'
                                            size='large'>
                                            儲存變更
                                    </Button>
                                        <Button
                                            onClick={() => { console.log('go'); }}
                                            className={`mb-0`}
                                            btnStyle='secondary'
                                            size='large'>
                                            取消
                                    </Button>
                                    </div>

                                </div>}

                        </div>
                    </div>
                </section>
                <Modal
                    onAfterOpen={e => document.getElementsByTagName('html')[0].classList.add('modal-open')}
                    onAfterClose={e => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
                    isOpen={this.state.modalOpen}
                    contentLabel="Example Modal"
                    className="fui-prompt-modal">
                    {
                        this.state.deleteResult ? (
                            <div className="fui-prompt-modal-body">
                                <h5>刪除成功</h5>
                                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed.png'} alt='completed' />
                                <p className='mt-0 mb-4'>您已刪除黑名單號碼 {this.state.currentDeleteNumber}</p>
                                <div className="fui-buttons">
                                    <Button btnStyle='primary' onClick={() => this.confirm()}>確定</Button>
                                </div>
                            </div>
                        ) : (
                                <div className="fui-prompt-modal-body">
                                    <h5>您將刪除黑名單號碼</h5>
                                    <p className='mt-0 mb-4'>確定刪除 {this.state.currentDeleteNumber} 嗎？</p>
                                    <div className="fui-buttons">
                                        <Button btnStyle='primary' onClick={() => this.confirmDelete()}>確定</Button>
                                        <Button btnStyle='secondary' onClick={() => this.setState({ modalOpen: false })}>取消</Button>
                                    </div>
                                </div>
                            )
                    }

                </Modal>
                {/* remove in production */}
                <div className="switcher py-5">
                    <div className="fui-container">
                        <div className="d-flex flex-column">
                            <p>測試用資料切換</p>
                            <Button onClick={() => this.setState({ blacklist: !this.state.blacklist })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.7.10_漫遊與門號服務_來電過濾_過濾名單設定_尚未開啟來電過濾</Button>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

export default Blacklist;