import React from 'react';
import {Parallax} from 'react-scroll-parallax';

const ArrowRightWhite = (props) => {
    return (
        <Parallax className="animate-arrow-right" x={['90%', '70%']}>
            <svg width="1645px" height="530px" viewBox="0 0 1645 530" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient x1="38.771879%" y1="66.102744%" x2="100%" y2="66.102744%" id="linearGradient-1">
                        <stop stopColor="#FFFFFF" offset="0%"></stop>
                        <stop stopColor="#EAEAEA" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="-0.0741680193%" y1="6.77459816%" x2="85.7251691%" y2="78.0700462%">
                        <stop stopColor="#EFEFEF" offset="0%"></stop>
                        <stop stopColor="#FFFFFF" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(822.500000, 265.000000) scale(-1, 1) translate(-822.500000, -265.000000) " fillRule="nonzero">
                        <polygon fill="url(#linearGradient-1)" points="0.528824834 530 1644.52882 530 1561.47038 371 0.528824834 371"></polygon>
                        <polygon fill="url(#linearGradient-2)" points="1196.52882 206.00784 1644.52882 530 1644.52882 323.664122 1196.52882 0"></polygon>
                    </g>
                </g>
            </svg>
        </Parallax>
    );
}

export default ArrowRightWhite;