import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import CheckGroup from '../../components/form/CheckGroup';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Modal from 'react-modal';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        head: {
          title: '項目',
          content: '狀態'
        },
        filter: {
          title: '來電過濾'
        },
        state: {
          title: '過濾方式'
        }
      },
      type: 'success',
      promotion: {
        title: '你的專屬優惠',
        cards: [
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
            title: '本月影片免費看!',
            description: '話題電影、日劇、韓劇等等應有盡有',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
            title: '中午不知道吃什麼？',
            description: 'Uber Eats 送遠傳新客優惠金200元',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
            title: '連假打算出國？',
            description: '遠遊卡吃到飽上網不再煩惱用量 ',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
            title: '本月影片免費看!',
            description: '話題電影、日劇、韓劇等等應有盡有',
            link: '#',
          },
        ]
      }
    }
  }
  render() {
    let params = new URLSearchParams(this.props.location.search);
    let filter = params.get('filter');
    let status = params.get('status');
    // console.log('filter: ' + filter);
    // console.log('status: ' + status);

    return (
      <main>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />

        <section className="e-service-result is-success mt-4">
          <div className="fui-container">
            <Grid container>
              <Grid item xs={12}>
                {
                  this.state.type !== 'fail' ? (
                    <img src={'/resources/cbu/e-service/images/completed.png'} srcSet={'/resources/cbu/e-service/images/completed@2x.png'} alt='' />
                  ) : (
                      <img src={'/resources/cbu/e-service/images/failed.png'} srcSet={'/resources/cbu/e-service/images/failed@2x.png'} alt='' />
                    )
                }

                <div className="content">
                  <h4 className="mb-0 mt-3">{this.state.title || ''}</h4>
                  <div className='body1'>
                    {filter == 'false' ? <div>
                      <h4 className="mb-1">來電過濾關閉</h4><p className="mt-0 mb-4">親愛的用戶，來電過濾已關閉，將於  <span className="is-text-accent">10分鐘</span> 後生效，謝謝。</p>
                    </div> : <div>
                        <h4 className="mb-1">來電過濾開啟</h4><p className="mt-0 mb-4">親愛的用戶，來電過濾已開啟，將於 <span className="is-text-accent">10分鐘</span> 後生效。此功能每月收取費用 <span className="is-text-accent">$30元</span> ，使用不足一個月按比例計算，敬請留意，謝謝！</p>
                      </div>}
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="paper">
              <h4 className='align-left'>設定詳情</h4>
              <table className="text-wrap gray-th bill-table table-fixed">
                <tbody>
                  <tr>
                    <th className="align-center">{this.state.table.head.title}</th>
                    <th className="align-center">{this.state.table.head.content}</th>
                  </tr>
                  <tr>
                    <td className="align-center">{this.state.table.filter.title}</td>
                    <td className="align-center">{filter == 'true' ? '開啟' : '關閉'}</td>
                  </tr>
                  {filter == 'true' ? <tr>
                    <td className="align-center">{this.state.table.state.title}</td>
                    <td className="align-center">{status == 'true' ? '轉入語音信箱' : '直接掛斷電話'}</td>
                  </tr> : null}
                </tbody>
              </table>
            </div>
            <div className='fui-buttons two-buttons mt-md-5 mt-2 pt-2'>
              <Button className='d-block d-md-inline-block' link="#" btnStyle='primary' size='large'>返回過濾名單設定</Button>
              <Button className='d-block d-md-inline-block' link="#" btnStyle="secondary" size='large'>返回個人專區</Button>
            </div>


          </div>
          <SectionCarousel1 {...this.state.promotion} />
        </section>
      </main >
    )
  }
}

export default Success;