import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import AppPromotion from '../../components/partials/AppPromotion';
import Link from '../../components/Link';
import Button from '../../components/Button';
import PanelTab from '../../components/panelContent/PanelTab';
import Panel from '../../components/panel/Panel';
import LinkItem from '../../components/item/LinkItem';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import HappyGoCard from '../../components/partials/card/HappyGoCard';
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';

import * as Mock from '../../mock/ProfileMock';

class Profile extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='個人專區'
          button={{
            text: '推薦資費',
            link: '#'
          }} />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/' },
          ]}
          color='black'
        />
        {/* Guest */}
        <section className="login-recommend">
          <div className="fui-container">
            <h2 className="mb-2">早安！</h2>
            <h4 className="is-text-medium is-text-darkgray50 mb-3">推薦給你遠傳生活圈的優惠資費，或是直接 <a href="#" className="fui-button is-text">登入會員</a>。</h4>
            <div className="fui-cards three-card no-scrollbar">
              <div className='fui-card is-guest'>
                <div className="fui-card-action align-center">
                  <div className="fui-card-content">
                    <img src="/resources/cbu/img/prepaid-plan-voice-icon-01@2x.png" alt="img" />
                    <h3 className="">攜碼限定資費 直接辦絕對Hen可以</h3>
                    <div className='fui-card-extra'>
                      <Button btnStyle='primary' size='large' link="#">了解更多</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='fui-card is-guest'>
                <div className="fui-card-action">
                  <div className="fui-card-content align-center">
                    <img src="/resources/cbu/img/prepaid-plan-voice-icon-02@2x.png" alt="img" />
                    <h3 className="">攜碼限定資費 直接辦絕對Hen可以</h3>
                    <div className='fui-card-extra'>
                      <Button btnStyle='primary' size='large' link="#">了解更多</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='fui-card is-guest is-promotion'>
                <div className="fui-card-action">
                  <div className="fui-card-content">
                    <h4 className="">還不是生活圈會員嗎？加入會員享遠傳生活圈優惠！</h4>
                    <div className='fui-card-extra'>
                      <Button btnStyle='primary' size='large' className="is-reverse is-text-accent" link="#">加入會員</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="login-recommend">
          <div className="fui-container">
            <h2 className="mb-2">早安！</h2>
            <h4 className="is-text-medium is-text-darkgray50 mb-3">推薦給你遠傳生活圈的優惠資費，或是直接 <a href="#" className="fui-button is-text">登入會員</a>。</h4>
            <div className="fui-cards three-card no-scrollbar">
              <div className='fui-card is-guest'>
                <div className="fui-card-action align-center">
                  <div className="fui-card-content">
                    <img src="/resources/cbu/img/prepaid-plan-voice-icon-01@2x.png" alt="img" />
                    <h3 className="">攜碼限定資費 直接辦絕對Hen可以</h3>
                    <div className='fui-card-extra'>
                      <Button btnStyle='primary' size='medium' link="#">了解更多</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='fui-card is-guest'>
                <div className="fui-card-action">
                  <div className="fui-card-content align-center">
                    <img src="/resources/cbu/img/prepaid-plan-voice-icon-02@2x.png" alt="img" />
                    <h3 className="">攜碼限定資費 直接辦絕對Hen可以</h3>
                    <div className='fui-card-extra'>
                      <Button btnStyle='primary' size='medium' link="#">了解更多</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='fui-card is-guest'>
                <div className="fui-card-action">
                  <div className="fui-card-content align-center">
                    <img src="/resources/cbu/img/prepaid-plan-voice-icon-02.png" alt="img" />
                    <h3 className="">線上直播 玩手遊 視訊通話 畫面絕對不卡卡</h3>
                    <div className='fui-card-extra'>
                      <Button btnStyle='primary' size='medium' link="#">了解更多</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='fui-member-info ribbon-bg'>
          <section className='fui-help-shortcut is-bg-none'>
            <div className='fui-container fui-shortcut-container d-block'>
              <h2>熱門服務</h2>
              <div className='is-text-darkgray50 d-block d-md-none'>想找更多電信服務嗎？可以從網站選單的「個人專區」找到喔！</div>
              <div className="d-flex">
                {Mock.shortcut.map((item, i) => (
                  <Link to={item.link} key={`help-center-shortcut-${i}`} className='fui-shortcut-item'>
                    <img src={item.image} alt={item.text} />
                    <div className='text'>{item.text}</div>
                  </Link>
                ))}
              </div>
            </div>
          </section>
          <section className="sitemap-tab d-none d-md-block">
            <div className="fui-container">
              <h2>找更多電信服務</h2>
              <PanelTab
                tabs={{
                  name: 'panel-tab',
                  list: [
                    { name: 'panel-tab-1', label: '帳單/費用' },
                    { name: 'panel-tab-2', label: '合約費率' },
                    { name: 'panel-tab-3', label: '漫遊與門號服務' },
                    { name: 'panel-tab-4', label: '交易/異動記錄' },
                    { name: 'panel-tab-5', label: '預付卡專區' },
                    { name: 'panel-tab-6', label: '帳戶服務' },
                  ],
                }}>
                {/* Tab 1 Content start */}
                <Panel>
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <Link to='#' className='sub-link-title'>
                        <h6 className='mt-0'>頭家經營管理</h6>
                      </Link>
                      <div className='fui-list'>
                        <LinkItem link='#' icon='chevron-right'>帳單查詢</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>本期應繳金額</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>未結帳金額/上網用量</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>繳費/代收交易/發票查詢</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>補寄帳單</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>4G上網用量加購</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>帳單代收設定/額度管理</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>電信帳單開立發票</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>4G加購記錄查詢</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>4G即時用量查詢</LinkItem>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Link to='#' className='sub-link-title'>
                        <h6 className='mt-0'>繳費服務</h6>
                      </Link>
                      <div className='fui-list'>
                        <LinkItem link='#' icon='chevron-right'>線上繳費</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>銀行帳號繳費</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>信用卡繳費</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>繳費方式</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>自動扣繳申請/異動</LinkItem>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Link to='#' className='sub-link-title'>
                        <h6 className='mt-0'>帳單通知設定</h6>
                      </Link>
                      <div className='fui-list'>
                        <LinkItem link='#' icon='chevron-right'>帳務及繳費通知設定</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>帳單地址/電話變更</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>「合併帳單」申請</LinkItem>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Link to='#' className='sub-link-title'>
                        <h6 className='mt-0'>電子帳單</h6>
                      </Link>
                      <div className='fui-list'>
                        <LinkItem link='#' icon='chevron-right'>電子帳單申請</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>電子帳單變更</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>電子帳單五大好處</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>點數兌換</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>帳單小達人</LinkItem>
                      </div>
                    </Grid>
                  </Grid>
                </Panel>
                {/* Tab 1 Content end */}
                {/* Tab 1 Content start */}
                <Panel>
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <Link to='#' className='sub-link-title'>
                        <h6 className='mt-0'>頭家經營管理</h6>
                      </Link>
                      <div className='fui-list'>
                        <LinkItem link='#' icon='chevron-right'>帳單查詢</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>本期應繳金額</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>未結帳金額/上網用量</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>繳費/代收交易/發票查詢</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>補寄帳單</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>4G上網用量加購</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>帳單代收設定/額度管理</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>電信帳單開立發票</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>4G加購記錄查詢</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>4G即時用量查詢</LinkItem>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Link to='#' className='sub-link-title'>
                        <h6 className='mt-0'>繳費服務</h6>
                      </Link>
                      <div className='fui-list'>
                        <LinkItem link='#' icon='chevron-right'>線上繳費</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>銀行帳號繳費</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>信用卡繳費</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>繳費方式</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>自動扣繳申請/異動</LinkItem>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Link to='#' className='sub-link-title'>
                        <h6 className='mt-0'>帳單通知設定</h6>
                      </Link>
                      <div className='fui-list'>
                        <LinkItem link='#' icon='chevron-right'>帳務及繳費通知設定</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>帳單地址/電話變更</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>「合併帳單」申請</LinkItem>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Link to='#' className='sub-link-title'>
                        <h6 className='mt-0'>電子帳單</h6>
                      </Link>
                      <div className='fui-list'>
                        <LinkItem link='#' icon='chevron-right'>電子帳單申請</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>電子帳單變更</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>電子帳單五大好處</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>點數兌換</LinkItem>
                        <LinkItem link='#' icon='chevron-right'>帳單小達人</LinkItem>
                      </div>
                    </Grid>
                  </Grid>
                </Panel>
                {/* Tab 1 Content end */}

              </PanelTab>
            </div>
          </section>

          <HappyGoCard {...Mock.happygo} />
          <SectionCarousel1 {...Mock.promotionCard} />
        </div>
        <SectionFaqTab {...Mock.faqTab} />
        <AppPromotion
          bgImage='/resources/cbu/help-center/images/bg-all.png'
          title="加入遠傳生活圈，滿載好康"
          subtitle="立即下載App，享好康回饋！"
          description={`
            <p>
              以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
              <br />
              還有許多用戶獨享驚喜優惠
            </p>`
          }
          icon='/resources/cbu/help-center/images/img-logo-fet.png'
          android={{
            qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
            icon: '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 Android 7 以上版本'
          }}
          ios={{
            qrCode: '/resources/cbu/help-center/images/qr-ios.png',
            icon: '/resources/cbu/help-center/images/app-store.png',
            link: '/',
            title: '支援 iOS 10 以上版本'
          }}
        />
      </main>
    );
  }
}

export default Profile;