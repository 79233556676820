import React from 'react';
import Formsy from 'formsy-react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';
import Link from '../Link';

class NewsletterCheckGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkAll: false,
            required: this.props.required || false,
            options: this.props.options,
            defaultChecked: this.props.default ? this.props.default.split(/,/g) : [],
            selected: [],
            allChecked: false,
            form: {
                accept: { value: '1', required: true },
            }
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.default) {
            this.props.setValue(this.props.default);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.default !== this.props.default) {
            // console.log(prevProps.default, this.props.default)
            this.setState({
                defaultChecked: this.props.default ? this.props.default.split(/,/g) : [],
            });
        }
    }

    handleChange(event) {
        let selected = Object.assign(this.state.selected);
        let val = event.currentTarget.value;
        if (event.currentTarget.checked) {
            selected.push(val);
        } else {
            selected.splice(selected.indexOf(val), 1);
        }

        this.setState({ selected: selected });
        this.props.setValue(selected.length ? selected.toString() : null);
        this.props.onChange(this.props.name, selected.toString());
        // console.log(this.props.options.length === this.state.selected.length);
        this.setState({
            allChecked: this.props.options.length === this.state.selected.length
        })
        this.forceUpdate()
    }

    getDefaultCheck = (option) => {
        let isCheck = this.state.selected.indexOf(option.value) > -1;
        // console.log(this.state.selected, option, isCheck);
        return isCheck;
    }
    onChange = (name, value) => {
        let form = Object.assign(this.state.form);
        form[name].value = value;
        this.setState({
            form: form
        })
    }
    enableButton = () => {
        this.setState({
            canSubmit: true
        })
    }

    disabledButton = () => {
        this.setState({
            canSubmit: false
        })
    }
    selectAll = () => {
        let all = this.props.options.length
        let result = []
        if (!this.state.checkAll) {
            for (let i = 0; i < all; i++) {
                result.push(i.toString())
            }
            this.setState({
                selected: result,
                checkAll: true,
                allChecked: true
            })
        } else {
            this.setState({
                selected: result,
                checkAll: false,
                allChecked: false
            })
        }
    }
    getAllCheck = () => {
        // console.log(JSON.stringify(this.state.selected.length === this.props.options.length));
        return JSON.stringify(this.state.selected.length === this.props.options.length)
    }
    openPreview = (event, i) => {
        console.log({ event, i });
        event.preventDefault()
        this.props.openPreview(i)
    }
    render() {
        const errorMessage = this.props.getErrorMessage();

        return (
            <div className='form-group newsletter-check'>
                <div className="header">
                    <h4 className={`${this.state.required ? 'is-required' : ''} mt-md-4 mt-0`}>{this.props.label}</h4>
                    <label className="checkbox">
                        <input type="checkbox" onChange={this.selectAll} name="accept" value={this.state.checkAll} checked={this.state.allChecked} />
                        <div className="content">全選</div>
                    </label>
                </div>
                <div className='checkbox-group'>
                    {this.props.options.map((option, i) => (
                        <label className='checkbox is-icon-box' key={`checkbox-${this.props.name}-${i}`}>
                            <input
                                type='checkbox'
                                name={this.props.name}
                                value={option.value}
                                onChange={this.handleChange}
                                checked={this.getDefaultCheck(option)}
                            />
                            <div className='content'>
                                <img src={option.img.url} alt={option.img.alt} />
                                <div className="d-inline-block">
                                    <h5>{option.label}</h5>
                                    <small className="is-text-darkgray50">{option.date}</small>
                                    <p>{option.content}</p>
                                    <a onClick={(event) => { this.openPreview(event, i) }}>
                                        {option.link.name}
                                    </a>
                                </div>
                                {option.icon ? <div className='icon'>{option.icon}</div> : ''}
                            </div>
                        </label>
                    ))}
                </div>
                {!!errorMessage ? <span className='error-message'>{errorMessage}</span> : ''}
                {/* </Formsy> */}
            </div>
        );
    }
}

NewsletterCheckGroup.propTypes = {
    required: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    default: PropTypes.string,
    openPreview: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    onChange: PropTypes.func,
};

export default withFormsy(NewsletterCheckGroup);
