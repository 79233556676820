import React, { Component } from 'react'
import { withFormsy } from 'formsy-react';
import formatNumber from '../../utils/numberFormatter';
import PropTypes from 'prop-types';
class RadioCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numbers: this.props.numbers
    }
  }
  handleChange = (e, opt) => {
    this.props.setValue(e.currentTarget.value);
    this.props.onChange(this.props.name, e.currentTarget.value, opt);
  };
  render() {
    return (
      <div className="radio-carousel-container">
        <div className='radio-buttons is-number-group'>
          {this.state.numbers.map((option, j) => (
            <label className='radio-button' key={`radio-button-${option}-${j}`}>
              <input
                type='radio'
                value={option.value}
                defaultChecked={option.value === this.state.selected}
                name={this.props.name}
                onChange={(e) => this.handleChange(e, option)}
              />
              <div className='content'>
                <div className='phone-number'>
                  {option.label}
                  {option.price ? (
                    <div>
                      <div className='fui-tag'>+${formatNumber(option.price)}</div>
                    </div>
                  ) : null}
                </div>
                {option.value === this.state.selected && option.price ? (
                  <div className='text-sm'>費用將於首筆帳單扣抵</div>
                ) : null}
              </div>
              <span className='checkmark'></span>
            </label>
          ))}
        </div>
      </div>
    );
  }
}
RadioCarousel.propTypes = {
  // name: PropTypes.string,
  // keyword: PropTypes.string,
  // value: PropTypes.string,
  // tags: PropTypes.array,
  // search: PropTypes.bool,
  // searchLabel: PropTypes.string,
  // options: PropTypes.array,
  // onChange: PropTypes.func,
};
export default withFormsy(RadioCarousel);