import React from "react";

class GoTop extends React.Component {
  constructor(props) {
    super(props);
    this.elem = React.createRef();
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      // console.log("gotop componentDidMount");
      window.addEventListener("scroll", e => {
        // console.log("scroll");
        this.setPosition();
      });

      this.elem.current.addEventListener("click", e => {
        if (window.isIE) {
          let elem = window || document.documentElement;
          elem.scroll(0, 0);
        } else {
          window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth"
          });
        }

        // [AA Tracking]
        if (window && typeof(window.ctrf) === 'function') window.ctrf(e || {}, `往上`);
      });
    }

    this.setPosition();
  }

  setPosition = () => {
    if (typeof window === "undefined") return;

    let scrollY = window.scrollY || document.documentElement.scrollTop;
    // console.log("setPosition", scrollY, this.elem);
    if (scrollY > 400) {
      this.openGotop();
    } else this.closeGotop();
  };

  openGotop = () => {
    if (!this.elem.current) return;
    // console.log("openGotop", this.elem.current);
    this.elem.current.classList.add("is-show");
  };

  closeGotop = () => {
    if (!this.elem.current) return;
    this.elem.current.classList.remove("is-show");
  };

  render() {
    return <div className="fui-go-top" ref={this.elem}></div>;
  }
}

export default GoTop;
