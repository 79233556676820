import React from 'react';
import { Grid } from '@material-ui/core';
import Header from '../../components/partials/header/Default';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import LifeCircleGoodsCarousel from '../../components/partials/card/LifeCircleGoodsCarousel';
import LifeCircleCarousel from '../../components/partials/card/LifeCircleCarousel';

import LifeCircleBanner from '../../components/partials/banner/LifeCircleBanner';

import ArticleBanner from '../../components/partials/banner/ArticleBanner';
import Panel from '../../components/panel/ArticlePanel';
import PanelFigure from '../../components/panelContent/ArticlePanelFigure';
import ArticleCitationPanel from '../../components/panel/ArticleCitationPanel';
import ArticleAuthorPanel from '../../components/panel/ArticleAuthorPanel';
import ArticleAuthorTagsPanel from '../../components/panel/ArticleAuthorTagsPanel';
import ArticleSharePanel from '../../components/panel/ArticleSharePanel';

import PanelButton from '../../components/panelContent/ArticlePanelButton';

import Link from '../../components/Link';
import Button from '../../components/Button';
import VideoModal from '../../components/VideoModal';

import AnchorDetect from '../../components/AnchorDetect';

import OnVisible, { setDefaultProps } from 'react-on-visible';

import * as Mock from '../../mock/LifeCircle';
setDefaultProps({
  bounce: true,
  percent: 20,
});

class article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      currentVideo: 'https://youtu.be/x-w0meRxubU',
      openModal: false,
    };
    this.playVideo = this.playVideo.bind(this);
  }
  closeModal = () => {
    this.setState({
      openModal: false,
      currentVideo: '',
      alterVideo: '',
    });
  };

  playVideo(slide) {
    this.setState({
      openModal: true,
      currentVideo: slide.videoLink,
      alterVideo: slide.alterVideo,
    });
  }

  render() {
    return (
      <main className='life-circle article'>
        <Header />
        <NavAnchor
          pageTitle='遠傳生活圈'
          button={{
            text: '回生活圈',
            link: '/',
          }}
        />

        <AnchorDetect
          className='vertical-anchor-nav'
          items={['anchor0', 'anchor1', 'anchor2', 'anchor3', 'anchor4']}
          activeClass='active'
          offsetTop={145}>
          <li>
            <span>來開箱</span>
          </li>
          <li>
            <span>配件介紹</span>
          </li>
          <li>
            <span>來試用</span>
          </li>
          <li>
            <span>來PK!</span>
          </li>
          <li>
            <span>寫在最後</span>
          </li>
        </AnchorDetect>

        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '遠傳生活圈', link: '/' },
            { text: '開箱智慧生活', link: '/' },
            { text: '2020居家必備的...', link: '' },
          ]}
          color='black'
        />

        <ArticleBanner
          image='/resources/cbu/life-circle-article/images/cbu-lifecircle-hero-1920x360.jpg'
          title='開箱智慧生活'
          desc='2020居家必備的一組吸塵器！無線、高續航、配件齊全萬元初'></ArticleBanner>

        <Panel className='article-intro-panel'>
          <p>
            常在關注募資網的朋友應該對「石頭科技」並不陌生，前年推出的「小瓦掃地機器人」，以CP值破表的稱號，熱銷全台超過4,000台、集資金額超過3,600萬台幣，而引起熱議。
            <br />
            <br />
            今年初它們在眾所矚目下同樣推出懶人救星的清潔家電—「H6
            旗艦無線吸塵器」，同樣也不讓人失望，引發各界好評。就讓編輯為大家開箱、實際試用看看這款產品吧。
          </p>
        </Panel>

        <OnVisible id='anchor0'>
          <Panel className=''>
            <h2 className='mt-0'>來開箱</h2>
            <p>
              這邊收到的樣品是「H6 旗艦無線吸塵器」標配單機組，目前在friDay購物上可以獨家買到
              <span className='is-text-accent'>「豪華配件組」</span>、
              <span className='is-text-accent'>「掃地機器人超值組」</span>還有
              <span className='is-text-accent'>「立架式充電架」</span>
              等超優惠組合，可依個人需求不同做選購，再加上最近母親節近了，也非常適合買回家送媽媽當做
              <span className='highlight border-bottom'>母親節禮物</span>（4/15-5/10 friDay購物全站滿5,000送300）。
            </p>
          </Panel>
        </OnVisible>

        <OnVisible id='anchor1'>
          <Panel className=''>
            <h2 className='mt-0'>配件介紹</h2>
            <img src='/resources/cbu/life-circle-article/images/article-img-01.jpg' alt='' />

            <p>
              石頭科技果然繼續秉持著CP值破表的精神，配件不囉嗦，儘管只是單機組，還是給你完完整整的：「地板刷」、「除蹣床刷」、「隙縫吸頭」、「短毛刷」、還有多一根可互相組裝的「延長支架」以及首發限量的「可延伸軟管」，等於
              <span className='highlight border-bottom'>4種刷頭</span>
              <span className='highlight border-bottom'>（地板刷/除蹣床刷/隙縫吸頭/短毛刷）</span>x{' '}
              <span className='highlight border-bottom'>3種連接方式</span>
              <span className='highlight border-bottom'>（不接/接延長桿/接軟管）</span>= 一共
              <span className='highlight border-bottom'>12種搭配</span>，絕對滿足一個家庭基本所需的打掃用途。
            </p>
            <p class='is-text-bold pt-3'>地板刷：適合地毯、木板、地磚</p>

            <img src='/resources/cbu/life-circle-article/images/article-img-02.jpg' alt='' />

            <p>
              地板刷刷頭為軟絨滾刷，連接上延長桿後，特別適合拿來使用在清潔如地毯、地磚、木材等地板材質。地板刷最高高度6cm、長度25cm，即使是底部比較低的家具，也不用擔心會有地板刷伸不進去、死角清不到的問題。
            </p>
            <p class='is-text-bold pt-3'>除瞒床刷：適合床墊、窗簾</p>
          </Panel>
        </OnVisible>

        <OnVisible id='anchor2'>
          <Panel className=''>
            <h2 className='mt-0'>來試用</h2>
            <p>獨步全球持久續航</p>
            <img src='/resources/cbu/life-circle-article/images/article-img-03.jpg' alt='' />
            <p>
              在這樣試用(玩)過一輪四種不同接頭後，電量還有剩！據官網說法：「將高能量的動力型聚合物鋰電池首創用於吸塵器。同
              Apple 手機、BMW
              電動車的電池技術，將「高續航」、「耐用持久」、「重量輕盈」等優勢集於一身。」一次充飽電，可以連續使用90分鐘！將「高續航」、「耐用持久」、「重量輕盈」等優勢集於一身。」一次充飽電，可以連續使用90分鐘！
            </p>

            <PanelButton link='#' text='延伸閱讀：如何在舊宅打造智能家庭' target='_blank' className='is-text-accent' />
          </Panel>
        </OnVisible>

        <ArticleCitationPanel children='在這樣試用(玩)過一輪四種不同接頭後，電量還有剩！據官網說法：「將高能量的動力型聚合物鋰電池首創用於吸塵器。同 Apple 手機、BMW 電動車的電池技術，將「高續航」、「耐用持久」、「重量輕盈」等優勢集於一身。」一次充飽電，可以連續使用90分鐘！'></ArticleCitationPanel>

        <Panel className='article-gallery-panel'>
          <PanelFigure
            inline={true}
            image='/resources/cbu/life-circle-article/images/article-img-04.jpg'
            caption='隙縫吸頭：適合窗台'
          />
          <PanelFigure
            inline={true}
            image='/resources/cbu/life-circle-article/images/article-img-05.jpg'
            caption='隙縫吸頭：汽車內等窄小空間'
          />
          <PanelFigure
            inline={false}
            image='/resources/cbu/life-circle-article/images/article-img-06.jpg'
            caption='圖片來源：嘖嘖募資網-石頭科技 H6旗艦無線吸塵器'
          />
        </Panel>

        <OnVisible id='anchor3'>
          <div className='fui-container fui-container-article'>
            <h2>來PK!</h2>
            {/* <VideoModal
              open={this.state.openModal}
              videoUrl={this.state.currentVideo}
              alterVideo={this.state.alterVideo}
              onClose={this.closeModal}
            /> */}
            <iframe
              src='https://www.youtube.com/embed/x-w0meRxubU'
              frameborder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen></iframe>
          </div>
        </OnVisible>

        <LifeCircleGoodsCarousel {...Mock.articleGoodsCard} />

        <OnVisible id='anchor4'>
          <Panel className=''>
            <h2 className='mt-0'>寫在最後</h2>
            <p>
              最近受疫情影響，各企業為減少傳染的機率開始實施在家上班（Work From
              Home），原本不覺得家裡有多亂，但現在待在家的時間變長了，才開始覺得家中一切不入眼、想要尋覓清潔家電的你並不孤單，十分推薦這台
              <span className='is-text-accent'>石頭科技H6無線吸塵器</span>
              ，不只是價錢親民、功能表現也非常優異、不亞於國際大廠，最重要的是它很輕（1.4kg）！所以你不限於清潔腰部以下的環境，頭頂上的（例如：冷氣孔）你也可以將吸塵器抬起來，持久清潔不手酸。如果你不在乎品牌迷思，保有開放的心態很願意嘗試新創的好東西，那就是非H6莫屬了！
            </p>
          </Panel>
        </OnVisible>

        <ArticleAuthorTagsPanel
          borderAuthor1='文字：江懷哲'
          borderAuthor2='攝影：Yiya Chou'
          tags={[
            {
              title: '智能家電',
              url: '#',
            },
            {
              title: 'DYSON',
              url: '#',
            },
            {
              title: 'friDay購物',
              url: '#',
            },
            {
              title: '遠傳幣',
              url: '#',
            },
            {
              title: '智慧家電',
              url: '#',
            },
            {
              title: '打掃神器',
              url: '#',
            },
          ]}></ArticleAuthorTagsPanel>

        <ArticleAuthorPanel
          img='/resources/cbu/life-circle-article/images/author-avatar-01.png'
          alt=''
          title='江懷哲／躁動的太平洋'
          desc='政大外交輔修歷史系畢業，並曾於加州大學柏克萊分校與北京大學交換學習，現正於英國劍橋大學攻讀碩士學位。目前嘗試用書寫參與世界，文章散見換日線、轉角國際、端傳媒等媒體。'></ArticleAuthorPanel>

        <ArticleSharePanel title='分享好生活' fbLink='#' lineLink='#'></ArticleSharePanel>

        <LifeCircleCarousel {...Mock.articleLifeCircleCard} />
      </main>
    );
  }
}

export default article;
