import React from 'react';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types';

// [AA Tracking]
import { videoTrack } from '../stores/reducer/analytics';

class VideoModal extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
    this.state = {
      open: false,
      videoUrl: '',
      alterVideo: ''
    };
  }

  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      let $htmlTag = document.getElementsByTagName('html')[0];
      this.setState({
        open: this.props.open,
      });

      if (this.props.open) {
        $htmlTag.classList.add('modal-open');
        this.modal.current.classList.add('is-open');
      } else {
        this.modal.current.classList.add('is-closing');
        setTimeout(() => {
          this.modal.current.className = 'fui-modal';
          $htmlTag.classList.remove('modal-open');
        }, 500);
      }
    }

    if (this.props.videoUrl !== this.state.videoUrl || this.props.alterVideo !== this.state.alterVideo) {
      this.setState({
        videoUrl: this.props.videoUrl,
        alterVideo: this.props.alterVideo
      });
      this.forceUpdate();
    }
  }

  getVideoId(url) {
    if (url.indexOf('v=') > -1) {
      return url.split('v=')[1];
    } else {
      return url.split(/\//g).reverse()[0];
    }
  }

  closeModal = e => {
    e.preventDefault();
    this.props.onClose();
  };

  onReady(event) {
    event.target.playVideo();
  }

  onStateChange = (e) => {
    // [AA Tracking]
    videoTrack.yt.onPlayerStateChange(e);
  }

  render() {
    const opt = {
      playerVars: {
        enablejsapi: 1,
      },
    };

    return (
      <div ref={this.modal} className={`fui-modal`}>
        <div className='fui-modal-header'>
          <button className='fui-close' onClick={this.closeModal}>
            <i className='icon-close'></i>
          </button>
        </div>
        <div className='fui-modal-content'>
          <div className='fui-modal-body'>
            {this.state.videoUrl ? (
              <YouTube
                videoId={this.getVideoId(this.state.videoUrl)}
                opt={opt}
                onReady={this.onReady}
                onStateChange={this.onStateChange}
                containerClassName='fui-player'
              />
            ) : (
              // [AA Tracking] HTML5 Video
              <ReactPlayer 
                url={this.state.alterVideo} 
                playing={true} 
                controls={true} 
                config={{ 
                  file: { 
                    attributes: {
                        'data-video-name': `${/([^/.]+)\.[^.]*$/g.exec(this.state.alterVideo) ? /([^/.]+)\.[^.]*$/g.exec(this.state.alterVideo)[1] : ''}`,
                        'data-info': ''
                    } 
                  }
                }}
              />
            )}

          </div>
        </div>
      </div>
    );
  }
}

VideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  videoUrl: PropTypes.string,
  alterVideo: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default VideoModal;
