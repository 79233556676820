import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';

import Button from '../../components/Button';
import Ruler from '../../components/partials/Ruler';
import FormBanner from '../../components/partials/banner/FormBanner';
import numberFormatter from '../../utils/numberFormatter';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

let bill1 = {
  detailLink: '#123',
  date: '2020年04月',
  period: '2020/03/20 00:00 ~ 2020/03/23 18:23',
  info: {
    number: '0903430082',
    endDate: '2020/04/20',
    programName: '4G新絕配999'
  },
  fee: [
    {
      title: '網內互打',
      fee: 50,
      isRed: false
    },
    {
      title: '他網行動',
      fee: 50,
      isRed: false
    },
    {
      title: '市內通話',
      fee: 50,
      isRed: false
    },
    {
      title: '行動上網傳輸費',
      fee: 50,
      isRed: false
    },
    {
      title: '合計',
      fee: 1190,
      isRed: true
    },
  ],
  isLimited: false,
  usage: {
    totalUsage: 60,
    canUse: {
      title: '可使用',
      used: '2.49GB',
      rest: '2,548MB',
    },
    used: {
      title: '已使用',
      used: '1.49GB',
      rest: '1,524MB'
    },
    rest: {
      title: '剩餘',
      used: '2.49GB',
      rest: '1.00GB'
    },
    usageDetail: [
      {
        title: '亞洲輕量包中國型2GB',
        // info: `使用期限 2020/09/12<br/>可使用 2.00GB(2,048MB)<br/>已使用 1.49GB(1,524MB)`,
        info: {
          date: '2020/09/12',
          canUse: '2.00GB(2,048MB)',
          used: '1.49GB(1,524MB)'
        },
        percentage: 100,
        valumn: {
          used: '0.51GB',
          total: '522MB'
        }
      },
      {
        title: '亞洲輕量包暢用型500MB',
        info: {
          date: '2020/09/12',
          canUse: '2.00GB(2,048MB)',
          used: '1.49GB(1,524MB)'
        },
        percentage: 0,
        valumn: {
          used: '500MB'
        }
      },
      {
        title: '上網計量包 200MB',
        info: {
          date: '2020/09/12',
          canUse: '2.00GB(2,048MB)',
          used: '1.49GB(1,524MB)'
        },
        percentage: 80,
        valumn: {
          used: '10MB'
        }
      },
      {
        title: '上網計量包 200MB',
        info: {
          date: '2020/09/12',
          canUse: '2.00GB(2,048MB)',
          used: '1.49GB(1,524MB)'
        },
        percentage: 0,
        valumn: {
          used: '200MB'
        }
      },
    ]
  },
  payment: '#',
  resendPayment: '#',

}

class Query4G extends React.Component {
  constructor(props) {
    super(props);
    this.body = React.createRef();
    this.collapseBody = React.createRef();
    this.state = {
      activityOpen: false,
      collapseOpen: false,
      contentHeight: 0,
      form: {
        id: { value: '', required: true },
      },
      currentBill: bill1,
    }
  }

  onChange = () => {
    this.setState(prevState => ({
      currentBill: {
        ...prevState.currentBill,
        isLimited: !this.state.currentBill.isLimited
      }
    }))
  }
  componentDidUpdate = (nextProps) => {
    if (this.props.onChange && (nextProps.open !== this.state.open || this.props.open !== this.state.open)) {
      this.setState({
        collapseOpen: this.props.collapseOpen,
      });
    }
  };
  setContentHeight = () => {
    this.setState({
      contentHeight: this.body.current.clientHeight,
    });
  };
  openCollapse = (event) => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });

    this.forceUpdate();
    this.setContentHeight();

    let collapseTop = (!this.state.open
      ? this.collapseBody.current.parentNode
      : this.collapseBody.current
    ).getBoundingClientRect();
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (window.isIE) {
      let elem = window || document.documentElement;
      elem.scroll(0, scrollY + collapseTop.top - 100);
    } else {
      window.scrollTo({
        left: 0,
        top: scrollY + collapseTop.top - 100,
        behavior: 'smooth',
      });
    }

    // [AA Tracking]
    if (window && typeof window.ctrf === 'function')
      window.ctrf(event || {}, this.state.open ? `Tab_${this.props.title}_收合` : `Tab_${this.props.title}_展開`);
  };
  loadData = () => {
    this.setState(prevState => ({
      currentBill: {
        ...prevState.currentBill,
        usage: {
          ...prevState.currentBill.usage,
          usageDetail: [
            ...prevState.currentBill.usage.usageDetail,
            {
              title: '亞洲輕量包中國型2GB',
              info: {
                date: '2020/09/12',
                canUse: '2.00GB(2,048MB)',
                used: '1.49GB(1,524MB)'
              },
              percentage: 0,
              valumn: {
                used: '500MB'
              }
            },
            {
              title: '亞洲輕量包中國型2GB',
              info: {
                date: '2020/09/12',
                canUse: '2.00GB(2,048MB)',
                used: '1.49GB(1,524MB)'
              },
              percentage: 80,
              valumn: {
                used: '500MB'
              }
            },
          ]
        },

      },
      activityOpen: false
    }))
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '/',
          }
          }
        />

        <FormBanner
          title="4G即時用量查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="query-index pb-md-10 pb-4 mb-md-2 mb-0">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mt-0 mb-5 pb-md-3">
              提供您查詢4.5G門號上網用量的最新使用情形。
            </h4>
            <div className="paper mb-2">
              <h4 className="mt-md-4 mb-md-3 mb-2">
                您目前的費率
              </h4>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-md-3 py-md-3 px-2 py-2">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      查詢門號
                      </h5>
                    <h2 className='mb-0'>{this.state.currentBill.info.number}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-md-3 py-md-3 px-2 py-2">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0 d-sm-block d-flex justify-between">
                      方案
                    </h5>
                    <h2 className='mb-0'>{this.state.currentBill.info.programName}</h2>
                  </div>
                </Grid>
              </Grid>
            </div>

            <h2 className="mb-0 mt-md-5 mt-2 pt-2">已使用上網用量</h2>
            <div className='body is-text-darkgray50 mb-md-4 mb-3 mt-1'>計算期間：{this.state.currentBill.period}</div>

            {this.state.currentBill.isLimited ? <div>
              <div className="paper pb-4 mb-md-2 mb-0">
                <div className="d-inline-block is-text-darkgray50 body">可使用 上網吃到飽不限速 / 已使用</div><div className="is-text-accent d-inline-block ml-1">{this.state.currentBill.usage.used.used}</div>
              </div>
              <div className='page-action mt-md-5 mt-0 pt-md-2 pt-4'>
                <Button link="#" className='mb-md-0 mb-2' btnStyle='primary' size='large'>未出帳金額與上網用量</Button>
                <Button link="#" className='mb-0' btnStyle="secondary" size='large'>帳單查詢</Button>
              </div>
            </div> : <div className="paper merged pb-md-5 pb-4">
                <div className="d-flex justify-between flex-align-center mt-md-4 mt-2 mb-md-4 mb-2">
                  <h4 className='m-0'>用量總覽</h4>
                  <Link className='fui-button is-secondary is-small mb-0 mr-0' to={this.state.currentBill.detailLink}>
                    <span className='text'>升級詳情</span>
                  </Link>
                </div>
                <Ruler
                  className="mb-md-4 mb-1"
                  percentage={this.state.currentBill.usage.totalUsage}
                  size="large"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <div className="is-bg-lightgray70 px-md-3 py-md-3 px-2 py-2">
                      <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                        {this.state.currentBill.usage.canUse.title}
                      </h5>
                      <div className="d-flex flex-align-baseline">
                        <h2 className='mb-0 mr-1'>{this.state.currentBill.usage.canUse.used}</h2>
                      ( <small>{this.state.currentBill.usage.canUse.rest}</small> )
                    </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="is-bg-lightgray70 px-md-3 py-md-3 px-2 py-2">
                      <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                        {this.state.currentBill.usage.used.title}
                      </h5>
                      <div className="d-flex flex-align-baseline">
                        <h2 className='mb-0 mr-1'>{this.state.currentBill.usage.used.used}</h2>
                      ( <small>{this.state.currentBill.usage.used.rest}</small> )
                    </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="is-bg-lightgray70 px-md-3 py-md-3 px-2 py-2">
                      <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                        {this.state.currentBill.usage.rest.title}
                      </h5>
                      <div className="d-flex flex-align-baseline">
                        <h2 className='mb-0 mr-1'>{this.state.currentBill.usage.rest.used}</h2>
                      ( <small>{this.state.currentBill.usage.rest.rest}</small> )
                    </div>
                    </div>
                  </Grid>
                </Grid>

                <div className='page-action fui-buttons mt-md-4 mt-2'>
                  <Button link="#" btnStyle='primary' size='large'>我要加購</Button>
                  <Button link="#" btnStyle="secondary" size='large'>帳單查詢</Button>
                </div>
              </div>}

            {!this.state.currentBill.isLimited ? <div className="paper mb-0 pb-0">
              <h4 className="mb-mb-3 mt-md-4 mt-0">使用明細</h4>

              <div className='fui-collapse with-trigger' ref={this.collapseBody}>
                <div
                  className='collapse-body'
                  style={{
                    height: this.state.collapseOpen ? this.state.contentHeight : 0,
                  }}>
                  <div ref={this.body}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        {this.state.currentBill.usage.usageDetail.map((item, i) => {
                          return (
                            <div key={item.title + i} className="px-3 py-3 border mb-2">
                              <h5 className="mb-1 mt-0">{item.title}</h5>
                              <div className="d-flex justify-between">
                                <div
                                  className="mb-md-2 mb-5 mt-0 subtitle2"
                                // dangerouslySetInnerHTML={{ __html: item.info }}
                                >
                                  <p className="subtitle2 m-0">
                                    使用期限 <span className="is-text-bold">{item.info.date}</span>
                                  </p>
                                  <p className="subtitle2 m-0">
                                    可使用 <span className="is-text-bold">{item.info.canUse}</span>
                                  </p>
                                  <p className="subtitle2 m-0">
                                    已使用 <span className="is-text-bold">{item.info.used}</span>
                                  </p>
                                </div>
                                <div className="d-none d-md-flex flex-row flex-align-baseline">
                                  <div className="subtitle2">
                                    剩餘
                                    </div>
                                  <h3 className="is-text-accent ml-2 mb-0">
                                    {item.valumn.used}
                                  </h3>
                                  <div className="subtitle2 ml-2">
                                    {!!item.valumn.total ? '(' + item.valumn.total + ')' : null}
                                  </div>
                                </div>
                              </div>
                              <Ruler
                                percentage={item.percentage}
                              />
                              <div className="mt-2 d-flex d-sm-none justify-end flex-row align-right flex-align-baseline">
                                <div className="subtitle2">
                                  剩餘
                                    </div>
                                <h3 className="is-text-accent ml-2 mb-0">
                                  {item.valumn.used}
                                </h3>
                                <div className="subtitle2 ml-2">
                                  {!!item.valumn.total ? '(' + item.valumn.total + ')' : null}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                        {
                          this.state.activityOpen ? (
                            <div className='align-center mt-4 mb-4' onClick={() => this.loadData()}>
                              <div role='button' className="d-flex flex-align-center justify-center">
                                <span className="is-text-medium">看更多</span>
                                <i className="icon-plus heading-3 is-text-regular"></i>
                              </div>
                            </div>
                          ) : null
                        }
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className='collapse-trigger' onClick={this.openCollapse}>
                  <span>{!this.state.collapseOpen ? '展開' : '收合'}明細</span>
                  <span className={`icon-${!this.state.collapseOpen ? 'plus' : 'minus'}`}></span>
                </div>
              </div>
            </div> : null}
          </div>
        </section>
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.onChange()} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>吃到飽/即時用量</Button>
            </div>
          </div>
        </div>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            1. 本服務僅適用4G/4.5G用戶。<br/>
            2. 此功能可查詢門號下所有上網用量的使用情形；「剩餘用量」為上網用量的可使用額度。<br/>
            3. 實際可使用量將依您申辦時所提供的優惠為準。<br/>
            4. 若需查詢未結帳之金額，請使用”未結帳金額/上網用量”功能。
          `}
        />
      </main >
    )
  }
}

export default Query4G;