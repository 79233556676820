import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip'
import Button from '../Button'

class TooltipCard extends React.Component {
  render () {
    return (
      <div className="fui-card is-tooltip" ref={this.card}>
        <div className='fui-card-caption'>
          <div className='fui-card-content'>
            <h4 className="fui-card-title mb-0">
              <span>{this.props.title}</span>
              <Tooltip 
              parentNode={null}
              content={<i className='icon-information'></i>}
              trigger="click"
              tooltip={this.props.tooltip} />
            </h4>
            <p className="fui-card-description"> {this.props.description}</p>
          </div>
          <div className='fui-card-extra'>
            {
              this.props.action ? (
                <Button {...this.props.action}>
                  {this.props.action.text}
                </Button>
              ) : null
            }
          </div>
        </div>
      </div>
    )
  }
}

TooltipCard.propTypes = {
  tooltip: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    btnStyle: PropTypes.string,
    disabled: PropTypes.bool,
  }),
}

export default TooltipCard;