import React from 'react';
import Card from '../../card/Card';
import Tab from '../../tab/Tab';
import Button from '../../Button';
import TabPane from '../../tab/TabPane';
import PropTypes from 'prop-types';

class CbuNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      tab: {}
    }
  }

  render() {
    return (
      <section className='fui-cbu-news'>
        <div className='fui-container'>
          <h2>{this.props.title}</h2>
          <Tab {...this.props.tab} onChange={(i, tab) => this.setState({ currentTab: i , tab})} />
          {this.props.tabContent.map((content, i) => (
            <TabPane
              key={`fui-cbu-news-${i}`}
              active={this.state.currentTab === i}
              id={
                this.props.tab.list[i].label === '最新消息'
                  ? 'newsArea'
                  : this.props.tab.list[i].label === '服務公告'
                  ? 'announceArea'
                  : `tab-${i}`
              }>
              <div className='fui-cards is-news'>
                {content.cards.map((card, j) => (
                  <Card key={`fui-cbu-news-${i}-card-${j}`} {...card} />
                ))}
              </div>
              <div className='align-center'>
                {content.more ? (
                  <Button link={content.more.link} btnStyle='secondary' onClick={() => {
                    // [AA Tracking]
                    if (window && window.s_objectID !== undefined) window.s_objectID = `${content.more.text}${this.state.tab.label || ''}`;
                  }}>{content.more.text}</Button>
                ) : null}
              </div>
            </TabPane>
          ))}
        </div>
      </section>
    );
  }
}

CbuNews.propTypes = {
  title: PropTypes.string,
  tab: PropTypes.shape({
    name: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      })
    ),
  }),
  tabContent: PropTypes.arrayOf(
    PropTypes.shape({
      more: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
      }),
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string,
          public_at: PropTypes.string,
          meta: PropTypes.string,
          title: PropTypes.string,
        })
      ),
    })
  ),
};

export default CbuNews;
