import React from 'react';
import Button from '../components/Button';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
    this.state = {
      open: false,
      detailContent: ''
    };
  }

  closeModal = () => {
    this.props.onClose();
  };

  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      let $htmlTag = document.getElementsByTagName('html')[0];
      this.setState({
        open: this.props.open,
      });

      if (this.props.open) {
        $htmlTag.classList.add('modal-open');
        this.modal.current.classList.add('is-open');
      } else {
        this.modal.current.classList.add('is-closing');
        setTimeout(() => {
          this.modal.current.className = 'fui-modal';
          $htmlTag.classList.remove('modal-open');
        }, 500);
      }
    }
  }

  render() {
    return (
      <div ref={this.modal} className={`fui-modal`}>
        <div className='fui-modal-content'>
          <div className='fui-modal-body'>
            <div className='tableBtn-modal'>
              <div className='subtitle is-text-medium mb-2'>
                <h4 className="mt-0 mb-0">其他代收記錄資訊</h4>
              </div>
              <div className='body mb-3'>
                <table className="option-table center-table modal-table">
                  <tbody>
                    <tr>
                      <th>{this.props.detailContent.title1}</th>
                      <td className='align-left'>{this.props.detailContent.content1}</td>
                    </tr>
                    <tr className="d-lg-none">
                      <th>{this.props.detailContent.title2}</th>
                      <td className='align-left'>{this.props.detailContent.content2}</td>
                    </tr>
                    <tr className="d-lg-none">
                      <th>{this.props.detailContent.title3}</th>
                      <td className='align-left'>{this.props.detailContent.content3}</td>
                    </tr>
                    <tr className="d-lg-none">
                      <th>{this.props.detailContent.title4}</th>
                      <td className='align-left'>{this.props.detailContent.content4}</td>
                    </tr>
                    <tr className="d-lg-none">
                      <th>{this.props.detailContent.title5}</th>
                      <td className='align-left'>{this.props.detailContent.content5}</td>
                    </tr>
                    <tr className="d-lg-none">
                      <th>{this.props.detailContent.title6}</th>
                      <td className='align-left'>{this.props.detailContent.content6}</td>
                    </tr>
                    <tr className="d-lg-none">
                      <th>{this.props.detailContent.title7}</th>
                      <td className='align-left'>{this.props.detailContent.content7}</td>
                    </tr>
                    {
                      this.props.detailContent.title8 ? (
                        <tr className="d-lg-none">
                          <th>{this.props.detailContent.title8}</th>
                          <td className='align-left'>{this.props.detailContent.content8}</td>
                        </tr>
                      ) : null
                    }
                    <tr className="d-none d-lg-table-row">
                      <th>{this.props.detailContent.title9}</th>
                      <td className='align-left'>{this.props.detailContent.content9}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Button onClick={this.closeModal} btnStyle='primary' className='fui-button is-primary m-0'>
                我知道了
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  open: PropTypes.bool,
  detailContent: PropTypes.object,
  onClose: PropTypes.func,
};
export default Modal;
