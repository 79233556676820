import React from 'react';

import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import Link from '../../components/Link';

import * as Mock from '../../mock/EServiceIndex';

class prepaidDepositFail extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      canSubmit: false
    }
  }

  enableButton = (e) => {
    this.setState({ canSubmit: true });
  }

  disableButton = (e) => {
    this.setState({ canSubmit: false });
  }

  render() {
    return (
      <main>


        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <section className="e-service-result">
          <div className="fui-container">
            <Grid>
              <Grid xs={12}>
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/illustration-540-px-not-applicable-cbu@2x.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/illustration-540-px-not-applicable-cbu@2x.png'} alt='fail' />
                <div className="content">
                  <h2 className="is-text-darkgray50 mb-0">你的門號/帳號不適用此服務</h2>
                  <p className='subtitle is-text-darkgray100'>如有問題，請洽客服中心，謝謝！</p>
                  <Button className="mt-md-9" btnStyle='primary'>返回個人專區</Button>

                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </main>
    );
  }
}

export default prepaidDepositFail;
