import React from 'react';
import PropTypes from 'prop-types';

class TabPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: this.props.active || false,
    };
  }

  componentDidMount() {
    this.setState({
      isActive: this.props.active || false,
    });
  }

  renderActiveClass() {
    return this.state.isActive ? 'is-active' : '';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active)
      this.setState({
        isActive: this.props.active,
      });
  }

  render() {
    return (
      <div className={`fui-tab-pane ${this.renderActiveClass()}`} id={this.props.id}>
        {this.props.children}
      </div>
    );
  }
}

TabPane.propTypes = {
  active: PropTypes.bool,
  // group: PropTypes.string.isRequired,
  // for: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default TabPane;
