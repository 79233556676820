import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../Link';
import Button from '../../Button';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

let windowHeight = window.innerHeight;
const ScrewSlider = (props) => {
  const settings = {
    infinite: true,
    speed: 1000,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: props.currentTab || 0,
    pauseOnHover: true,
    autoplay: false,
    fade: true,
    customPaging: function (i) {
      for (let j = 0; j < props.list.length; j++) {
        if (i === j) {
          return (
            <a>
              <div className='dot-wrapper'>
                <img
                  src={props.list[i].circleImage}
                  alt={props.list[i].alt}
                  style={{ backgroundColor: props.list[i].color }}
                />
                {/* <div
                className="circle"
                style={{ backgroundColor: props.list[i].color }}
              /> */}
                <h6>{props.list[i].shortname}</h6>
              </div>
              <div className='bar'></div>
              <div className='bar-active' style={{ backgroundColor: props.list[i].color }}>
                <div className='triangle' style={{ borderBottom: `10px solid ${props.list[i].color}` }}></div>
              </div>
            </a>
          );
        }
      }
    },
  };
  const currentItem = React.useRef();
  React.useEffect(() => {
    // console.log(`currentItem.current.clientWidth`, currentItem.current.clientWidth);
    setSlideWidth(currentItem.current.clientWidth);
  }, []);
  const [slideWidth, setSlideWidth] = React.useState(86);

  const prevSlider = React.useRef(null);
  const nextSlider = React.useRef(null);

  const updateSlider = (oldIndex, newIndex) => {
    if (typeof window !== 'undefined' && window.innerWidth < 768) return;

    prevSlider.current.slickGoTo(newIndex);
    nextSlider.current.slickGoTo(newIndex);
  };

  const setPrevSlide = () => {
    let prevData = [...props.list];
    let last = prevData.pop();
    prevData = [last].concat(prevData);
    return prevData;
  };

  const setNextSlide = () => {
    let nextData = [...props.list];
    let first = nextData.shift();
    nextData.push(first);
    return nextData;
  };

  const prevData = setPrevSlide();
  const nextData = setNextSlide();

  window.addEventListener('resize', (e) => {
    // console.log(`###`, currentItem.current.clientWidth);
    setSlideWidth(currentItem.current.clientWidth);
  });
  return (
    <section
      className={`screwed-slider shop-carousel ${windowHeight < 800 && windowHeight > 599 ? 'small-screen' : ''}`}
      id={props.id}>
      <div className=''>
        <Grid container>
          <Grid item md={1} className='carousen-container is-prev'>
            <Slider className='shop-carousel is-prev' ref={prevSlider} {...settings} arrows={false}>
              {prevData.map((item, idx) => (
                <div
                  key={`carousel-${idx}`}
                  align='center'
                  className='shop-carousel-item item-bg'
                  style={{ backgroundImage: `url(${process.env.PUBLIC_URL + item.bg})` }}>
                  <svg
                    width='164px'
                    height='170px'
                    viewBox='0 0 164 170'
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'>
                    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <g
                        id='Artboard'
                        transform='translate(-322.000000, -54.000000)'
                        fill={item.color}
                        fillRule='nonzero'>
                        <g transform='translate(322.000000, 54.000000)'>
                          <polygon id='Rectangle' points='0 0 117.32255 0 163.510965 170 46.2647446 170'></polygon>
                        </g>
                      </g>
                    </g>
                  </svg>

                  <div className='main-img'>
                    <img src={item.image} alt={item.name} />
                  </div>
                  <p>{item.shortname} </p>
                </div>
              ))}
            </Slider>
          </Grid>
          <Grid item xs={12} sm={12} md={10} className='carousen-container center-carousen'>
            <Slider
              className='is-main'
              dots={true}
              arrows={true}
              autoplay={true}
              beforeChange={updateSlider}
              {...settings}>
              {props.list.map((item, idx) => (
                <Link
                  key={`carousel-${idx}`}
                  to={item.link}
                  target={item.target || '_self'}
                  className='shop-carousel-item'>
                  <svg
                    className='rectangle'
                    width={slideWidth - 86 + 'px'}
                    height={windowHeight < 800 && windowHeight > 700 ? '300px' : '320px'}
                    viewBox={'0 0 ' + (slideWidth - 86) + ' 320'}
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'>
                    <g id='rectangle' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <g id='Artboard' transform='translate(-162.000000, -493.000000)' fill='#FFFFFF'>
                        <polygon id='Rectangle' points={'162 493 1105 493 1192 813 248 813'}></polygon>
                      </g>
                    </g>
                  </svg>
                  <div className='skew-bg'></div>
                  <div ref={currentItem} className='item-bg'>
                    <svg
                      width='307px'
                      height={windowHeight < 800 && windowHeight > 700 ? '300px' : '320px'}
                      viewBox={`0 0 307 ${windowHeight < 800 && windowHeight > 700 ? '300px' : '320px'}`}
                      version='1.1'
                      xmlns='http://www.w3.org/2000/svg'>
                      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <g id='Artboard' transform='translate(-883.000000, -493.000000)' fill={item.color}>
                          <polygon
                            id='Rectangle'
                            points='883 493 1102.62209 493 1189.08455 813 969.605345 813'></polygon>
                        </g>
                      </g>
                    </svg>
                    <h2 className=''>{item.name}</h2>
                    <div className='main-img'>
                      <img src={item.image} alt={item.name} />
                    </div>
                    <div className='subtitle' dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    {!!item.moreText && (
                      <Button btnStyle='primary' size='large'>
                        {item.moreText}
                      </Button>
                    )}
                  </div>
                </Link>
              ))}
            </Slider>
          </Grid>
          <Grid item md={1} className='carousen-container is-next'>
            <Slider className='shop-carousel is-next' ref={nextSlider} {...settings} arrows={false}>
              {nextData.map((item, idx) => (
                <div
                  key={`carousel-${idx}`}
                  align='center'
                  className='shop-carousel-item item-bg'
                  style={{ backgroundImage: `url(${process.env.PUBLIC_URL + item.bg})` }}>
                  <svg
                    width='164px'
                    height='170px'
                    viewBox='0 0 164 170'
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'>
                    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <g
                        id='Artboard'
                        transform='translate(-322.000000, -54.000000)'
                        fill={item.color}
                        fillRule='nonzero'>
                        <g id='yellow' transform='translate(322.000000, 54.000000)'>
                          <polygon id='Rectangle' points='0 0 117.32255 0 163.510965 170 46.2647446 170'></polygon>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <div className='main-img'>
                    <img src={item.image} alt={item.name} />
                  </div>
                  <p>{item.shortname} </p>
                </div>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

ScrewSlider.propTypes = {
  marquee: PropTypes.bool,
  title: PropTypes.string,
  moreLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      type: PropTypes.string,
      src: PropTypes.string,
      alterVideo: PropTypes.string,
      text: PropTypes.string,
    }),
  ]),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      background: PropTypes.string,
      meta: PropTypes.string,
      name: PropTypes.string,
      content: PropTypes.string,
      link: PropTypes.string,
      moreText: PropTypes.string,
    })
  ),
};

export default ScrewSlider;
