import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import ImageModal from '../../components/ImageModal';
import Modal from 'react-modal';
import Button from '../../components/Button';
import Checkbox from '../../components/form/Checkbox';
import LabelInput from '../../components/form/LabelInput';
import NewsletterCheckGroup from '../../components/form/NewsletterCheckGroup';
import DatePicker from "react-datepicker";
import RadioButtons from '../../components/form/RadioButtons';
import TW, { options } from 'date-fns/locale/zh-TW';

import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';

class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      emailValidation: false,
      confirmEmail: 'fet123@gmail.com',
      modalOpen: false,
      preview: false,
      currentPreview: null,
      form: {
        email: { value: '', required: true },
        accept: { value: '1', required: true },
        newsletter: {
          value: [],
          required: false,
          options: [
            {
              value: '0',
              label: 'FET會員電子報',
              date: '每月15日發報',
              content: '擁有最大發報量，為訂戶量身提供每月免費專屬好禮、會員專屬活動、續約優惠、免費體驗音樂/影音服務、最新手機評測，搶先掌握官網新資訊',
              link: {
                name: '查看預覽',
                url: '#',
                target: '_blank'
              },
              img: {
                url: '/resources/cbu/img/img-fetmember@3x.jpg',
                alt: 'img'
              }
            },
            {
              value: '1',
              label: '遠傳家族電子報',
              date: '每月5號發報',
              content: '提供會員最新的服務訊息，並月月介紹現下最殺的遠傳會員優惠！所有好康都在遠傳家族電子報，現在就來申請！',
              link: {
                name: '查看預覽',
                url: '#',
                target: '_blank'
              },
              img: {
                url: '/resources/cbu/img/img-fetfamily@3x.jpg',
                alt: 'img'
              }
            },
            {
              value: '2',
              label: 'friDay音樂情報誌',
              date: '每月1日與16日發報',
              content: '每期掌握全球最新流行音樂情報，以最快最豐富的音樂資訊及最方便的音樂歌單收藏，讓每個愛樂者可以走到哪聽到哪，音樂訊息不漏接。',
              link: {
                name: '查看預覽',
                url: '#',
                target: '_blank'
              },
              img: {
                url: '/resources/cbu/img/img-fetmusic@3x.jpg',
                alt: 'img'
              }
            },
            {
              value: '3',
              label: 'FET異業合作訊息',
              date: '不定期發報',
              content: '不定期提供各項異業優惠資訊，讓電子報訂戶取得最新、最快、最熱門的市場好康訊息。',
              link: {
                name: '查看預覽',
                url: '#',
                target: '_blank'
              },
              img: {
                url: '/resources/cbu/img/img-fetcooperation@3x.jpg',
                alt: 'img'
              }
            },
            {
              value: '4',
              label: '產品/服務優惠訊息電子報',
              date: '不定期發報',
              content: '不定期提供網路門市最新促銷優惠，熱門新機開賣資訊，續約/攜碼/新申辦促銷方案，以及產品與數位影音加值服務等訊息。',
              link: {
                name: '查看預覽',
                url: '#',
                target: '_blank'
              },
              img: {
                url: '/resources/cbu/img/img-fetsale@3x.jpg',
                alt: 'img'
              }
            }
          ]
        }
      }
    }
  }

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }

  disabledButton = () => {
    this.setState({
      canSubmit: false
    })
  }

  onChange = (name, value) => {
    let form = Object.assign(this.state.form);
    form[name].value = value;
    this.setState({
      form: form
    })
  }

  nextStep = () => {
    console.log('nextStep');
  }
  // 串email驗證
  validateEmail = () => {
    if (!!this.state.form.email.value) {
      this.setState({ emailValidation: true })
    }
  }
  getNewsletterList = () => {
    let option = this.state.form.newsletter.options
    let result = []
    if (this.state.form.newsletter.value.length > 0) {
      this.state.form.newsletter.value.split(',').map((item, i) => {
        let arr = option.find((el) => el.value == item);
        result.push(arr)
      })
      return result.map((item, i) => {
        return (
          <li key={item + i}>
            {item.label}
          </li>
        )
      })
    }
  }
  submit = () => {
    this.setState({ modalOpen: false })
    console.log(JSON.stringify(this.state.form.newsletter));
  }
  openPreview = (i) => {
    // console.log("open preview");
    this.setState({
      preview: true,
      currentPreview: i
    })
  }
  render() {
    return (
      <main>

        <NavAnchor
          pageTitle='帳戶管理'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="訂閱電子報"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className="form-section pt-md-7 pt-4 pb-md-7 pb-6">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-md-7 mb-4">提供您電子報訂閱服務。</h4>
            <Formsy onValid={this.enableButton} onInvalid={this.disabledButton}>
              {this.state.emailValidation ?
                <div className="paper mb-2">
                  <div className="form-group mb-md-2 mb-0">
                    <h4 className='mb-md-4 mb-2 mt-md-4 mt-0'>您目前電子報訂閱狀態與內容</h4>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <div className="mb-2">
                          <label>電子郵件信箱</label>
                          <div className="checked-email is-bg-lightgray100">
                            <p>{this.state.confirmEmail}</p>
                          </div>
                        </div>
                        <small className="email-validation is-text-darkgray50">如需更換電子郵件信箱，請至<a href="#" className="info-link">個人資料維護</a>設定。</small>
                        <Checkbox
                          name='accept'
                          label="我願意收到遠傳官網訊息與權益通知。"
                          default={this.state.form.accept.value}
                          onChange={this.onChange}
                        />
                        <small className='is-text-darkgray50'>請將member@email.fetnet.net加入信任名單或加入通訊錄，以免錯過相關訊息。</small>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                :
                <div className="paper mb-2">
                  <div className="form-group mb-md-2 mb-0">
                    <h4 className='mb-md-4 mb-2 mt-md-4 mt-0'>您目前電子報訂閱狀態與內容</h4>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <p className="body email-validation is-text-darkgray50 m-0">您還未設定電子郵件，請至<a href="#" className="info-link">個人資料維護</a>設定。</p>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              }
              <div className="paper mb-2 pb-md-4 pb-2">
                <div className="form-group mb-md-2 mb-0">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <NewsletterCheckGroup
                        onChange={this.onChange}
                        validationErrors={{ isDefaultRequiredValue: '請選擇階段' }}
                        label='請勾選您要訂閱的電子報'
                        name='newsletter'
                        required={this.state.form.newsletter.required}
                        default=""
                        openPreview={this.openPreview}
                        options={this.state.form.newsletter.options}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Formsy>
            <div className="page-action mt-md-8 mt-4">
              <Button btnStyle='primary' size='large' onClick={() => this.setState({ modalOpen: true })}>確認</Button>
            </div>
            {this.state.modalOpen ? <Modal
              onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
              onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
              isOpen={this.state.modalOpen}
              contentLabel='Example Modal'
              className='fui-prompt-modal'>
              {this.state.form.newsletter.value.length > 0 ? <div className='fui-prompt-modal-body'>
                <h5>你欲訂閱的電子報</h5>
                <p className='align-left'>請再次確認你所訂閱的電子報，按下「確定」後即完成訂閱</p>
                <ul className="green-dot-list align-left">
                  {this.getNewsletterList()}
                </ul>
                <div className='page-action'>
                  <Button btnStyle='primary' onClick={(e) => this.submit()}>
                    確定
                  </Button>
                </div>
              </div> :
                <div className='fui-prompt-modal-body'>
                  <h5>你欲取消訂閱的電子報</h5>
                  <p className='align-left'>請再次確認你所取消的電子報，按下「確定」後即完成取消訂閱</p>
                  <ul className="green-dot-list align-left">
                    {this.getNewsletterList()}
                  </ul>
                  <div className='page-action'>
                    <Button btnStyle='primary' onClick={(e) => this.submit()}>
                      確定
                  </Button>
                  </div>
                </div>}
            </Modal> : null}
            {/* {this.state.preview ? <Modal
              onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
              onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
              isOpen={this.state.preview}
              contentLabel='Example Modal'
              className='fui-prompt-modal'>
              <div className='fui-prompt-modal-body'>
                {this.state.currentPreview}
              </div>
            </Modal> : null} */}
            {this.state.preview ? <ImageModal
              mainSrc={this.state.form.newsletter.options[this.state.currentPreview].img.url}
              onCloseRequest={() => this.setState({ preview: false })}
            /> : null}
          </div>
        </section>
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ emailValidation: true })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>8.1.1_個人專區_帳戶管理_訂閱電子報</Button>
              <Button onClick={() => this.setState({ emailValidation: false })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>8.1.1.3_個人專區_帳戶管理_訂閱電子報_未設定Email</Button>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default Newsletter;