import React from 'react';

import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

import * as Mock from '../../mock/EServiceIndex';

class getPhoneBillPaySettingOpenResultPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      canSubmit: false
    }
  }

  enableButton = (e) => {
    this.setState({ canSubmit: true });
  }

  disableButton = (e) => {
    this.setState({ canSubmit: false });
  }

  render() {
    return (
      <main>
        
        <NavAnchor
          pageTitle='帳單代收'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <section className="e-service-result is-success">
          <div className="fui-container">
            <Grid container>
              <Grid item xs={12}>
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed.png'} alt='' />
                <div className="content">
                  <h4 className="mb-0 mt-3">開啟申請完成</h4>
                  <p className='body1'>親愛的用戶，已收到你的申請，會以簡訊通知申請結果，敬請留意，謝謝！</p>
                  <div className='action'>
                    <Button btnStyle='primary' size='large'>返回帳單代收</Button>
                    <Button link='/' btnStyle='secondary' size='large'>前往個人專區</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <SectionCarousel1 {...Mock.promotionCard} />
      </main>
    );
  }
}

export default getPhoneBillPaySettingOpenResultPage;
