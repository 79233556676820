import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Card from '../../components/card/Card';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import { Grid } from '@material-ui/core';
import Dropdown from '../../components/Dropdown';
import Link from '../../components/Link';
import InstructionList from '../../components/partials/InstructionList'
// import * as Mock from '../../mock/AddValue.js';
class Instruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      currentType: '',
      currentBrand: '',
      currentModel: '',
      typeList:
        [
          { value: '0', text: '請選擇類別' },
          { value: 'phone', text: '手機' },
          { value: 'pad', text: '平板' },
          { value: 'router', text: '網卡與路由器' },
          { value: 'smart', text: '智慧物聯網' },
        ],
      brandList: [
        {
          value: "0",
          text: "請選擇品牌",
        },
        {
          value: "ASUS",
          text: "ASUS",
        },
        {
          value: "Apple",
          text: "Apple",
        },
        {
          value: "Dynalink",
          text: "Dynalink",
        },
        {
          value: "FarEastone",
          text: "FarEastone",
        },
        {
          value: "Google",
          text: "Google",
        },
        {
          value: "Guider",
          text: "Guider",
        },
        {
          value: "HTC",
          text: "HTC",
        },
        {
          value: "HUAWEI",
          text: "HUAWEI",
        },
        {
          value: "INHON",
          text: "INHON",
        },
        {
          value: "InFocus",
          text: "InFocus",
        },
        {
          value: "Touch",
          text: "Touch",
        },
        {
          value: "Koobee",
          text: "Koobee",
        },
        {
          value: "LG",
          text: "LG",
        },
        {
          value: "Lenovo",
          text: "Lenovo",
        },
        {
          value: "Meitu",
          text: "Meitu",
        },
        {
          value: "NOKIA",
          text: "NOKIA",
        },
        {
          value: "Nextbit",
          text: "Nextbit",
        },
        {
          value: "OPPO",
          text: "OPPO",
        },
        {
          value: "Omate",
          text: "Omate",
        },
        {
          value: "Panasonic",
          text: "Panasonic",
        },
        {
          value: "Razer",
          text: "Razer",
        },
        {
          value: "SAMSUNG",
          text: "SAMSUNG",
        },
        {
          value: "SHARP",
          text: "SHARP",
        },
        {
          value: "SUGAR",
          text: "SUGAR",
        },
        {
          value: "Sony",
          text: "Sony",
        },
        {
          value: "WIZ",
          text: "WIZ",
        },
        {
          value: "Wingsland",
          text: "Wingsland",
        },
        {
          value: "Xiaomi",
          text: "Xiaomi",
        },
        {
          value: "ZTE",
          text: "ZTE"
        }
      ],
      modelList: [],
      pool: [
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-1.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'Xiaomi',
          model: 'Note 8T 64GB',
          text: 'Xiaomi 紅米 Note 8T 64GB',
          link: '#123'
        },
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-2.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'SAMSUNG',
          model: 'S20 5G',
          text: 'SAMSUNG Galaxy S20 5G',
          link: '#234'
        },
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-3.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'Apple',
          model: 'iPhone 11 Pro Max 256GB',
          text: 'iPhone 11 Pro Max 256GB',
          link: '#1111'
        },
      ],
      phone: [
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-1.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'Xiaomi',
          model: 'Note 8T 64GB',
          text: 'Xiaomi 紅米 Note 8T 64GB',
          link: '#123'
        },
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-2.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'SAMSUNG',
          model: 'S20 5G',
          text: 'SAMSUNG Galaxy S20 5G',
          link: '#234'
        },
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-3.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'Apple',
          model: 'iPhone 11 Pro Max 256GB',
          text: 'iPhone 11 Pro Max 256GB',
          link: '#1111'
        },
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-4.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'SAMSUNG',
          model: 'S20 Ultra 5G',
          text: 'SAMSUNG Galaxy S20 Ultra 5G',
          link: '#222'
        },
      ],
      pad: [
        {
          img: {
            url: 'resources/cbu/img/tab-1.jpg',
            alt: 'img'
          },
          type: 'pad',
          brand: 'SAMSUNG',
          model: 'Tab 4',
          text: 'SAMSUNG Galaxy Tab 4',
          link: '#123'
        },
        {
          img: {
            url: 'resources/cbu/img/tab-2.jpg',
            alt: 'img'
          },
          type: 'pad',
          brand: 'MediaPad',
          model: 'T2 8 Pro',
          text: 'MediaPad T2 8 Pro',
          link: '#234'
        },
        {
          img: {
            url: 'resources/cbu/img/tab-3.jpg',
            alt: 'img'
          },
          type: 'pad',
          brand: 'SAMSUNG',
          model: 'Tab A 8.0',
          text: 'SAMSUNG Galaxy Tab A 8.0',
          link: '#1111'
        },
        {
          img: {
            url: 'resources/cbu/img/tab-4.jpg',
            alt: 'img'
          },
          type: 'pad',
          brand: 'Omate',
          model: 'T800A',
          text: 'Omate T800A',
          link: '#222'
        },
      ],
      router: [
        {
          img: {
            url: 'resources/cbu/img/router-1.jpg',
            alt: 'img'
          },
          type: 'router',
          brand: 'HUAWEI',
          model: 'B818-263無線路由器',
          text: 'B818-263無線路由器',
          link: '#123'
        },
        {
          img: {
            url: 'resources/cbu/img/router-2.jpg',
            alt: 'img'
          },
          type: 'router',
          brand: 'FarEastone',
          model: 'FET LRT200',
          text: 'FarEastone FET LRT200',
          link: '#234'
        },
        {
          img: {
            url: 'resources/cbu/img/router-3.jpg',
            alt: 'img'
          },
          type: 'router',
          brand: 'HUAWEI',
          model: 'B315s 4G 無線路由器(白)',
          text: 'HUAWEI B315s 4G 無線路由器(白)',
          link: '#1111'
        },
        {
          img: {
            url: 'resources/cbu/img/router-4.jpg',
            alt: 'img'
          },
          type: 'router',
          brand: 'Dynalink',
          model: 'RTL0031 無線路由器',
          text: 'Dynalink RTL0031 無線路由器',
          link: '#222'
        },
      ],
      smart: [
        {
          img: {
            url: 'resources/cbu/img/smart-1.jpg',
            alt: 'img'
          },
          type: 'smart',
          brand: 'SAMSUNG',
          model: 'Gear Fit2',
          text: 'SAMSUNG Gear Fit2',
          link: '#123'
        },
        {
          img: {
            url: 'resources/cbu/img/smart-2.jpg',
            alt: 'img'
          },
          type: 'smart',
          brand: 'InFocus',
          model: 'W201 小衛星',
          text: 'InFocus W201 小衛星',
          link: '#234'
        },
        {
          img: {
            url: 'resources/cbu/img/smart-3.jpg',
            alt: 'img'
          },
          type: 'smart',
          brand: '4K空拍機Wingsland',
          model: 'Wingsland S6',
          text: '口袋型 GPS 4K空拍機Wingsland S6',
          link: '#1111'
        },
        {
          img: {
            url: 'resources/cbu/img/smart-4.jpg',
            alt: 'img'
          },
          type: 'smart',
          brand: 'FarEastone',
          model: 'BoBee',
          text: 'FarEastone BoBee 守護寶定位裝置',
          link: '#222'
        },
      ]
    }
  }
  componentDidMount() {
    this.resetModel()
  };
  resetModel = () => {
    let result = [
      // { value: '0', text: '請選擇型號' }
    ]
    let data = this.state.pool;
    data.map((item, i) => {
      return (
        result.push(
          {
            value: i,
            text: item.text,
            brand: item.brand,
            type: item.type,
            model: item.model
          })
      )
    })
    this.setState({
      modelList: result
    })
  }
  selectType = (value, i) => {
    console.log(value, i);
    // call APIs
    let result = [
      // { value: '0', text: '請選擇型號' }
    ]
    let brand = this.state.currentBrand
    let data = this.state.modelList.length !== 0 ? this.state.modelList : this.state.pool;
    // let pool = this.state.pool;

    if (value !== '0') {
      let filter = data.filter((item) => {
        // debugger
        // console.log(`data: `, data);
        return (item.type == i.value)
      }).filter((item) => {
        // debugger
        if (!!brand) {
          return (item.brand == brand)
        } else {
          return true
        }
      })
      filter.map((item, index) => {
        return (
          result.push(
            {
              value: i,
              text: item.text,
              brand: item.brand,
              type: item.type,
              model: item.model
            })
        )
      })
      this.setState({
        currentType: i.text,
        modelList: result,
        currentModel: ''
      })
    } else {
      this.resetModel()
      this.setState({
        currentType: i.text,
        currentModel: '請選擇型號',
        currentBrand: '請選擇品牌',
        // key: this.state.key + 1
      })
    }
    // this.updateKey()
  };
  selectBrand = (value, i) => {
    let result = [
      // { value: '0', text: '請選擇型號' }
    ]
    let data = this.state.modelList.length !== 0 ? this.state.modelList : this.state.pool;
    let type = this.state.currentType
    console.log(value, i);
    // call APIs
    if (value !== '0') {
      let filter = data.filter((item) => {
        // debugger
        // console.log(`data: `, data);
        return (item.brand == i.value)
      }).filter((item) => {
        // debugger
        if (!!type) {
          return (item.type == type)
        } else {
          return true
        }
      })
      filter.map((item, index) => {
        return (
          result.push(
            {
              value: i,
              text: item.text,
              brand: item.brand,
              type: item.type,
              model: item.model
            })
        )
      })
      this.setState({
        currentBrand: i.text,
        modelList: result,
        currentModel: ''
      })
    } else {
      this.resetModel()
      this.setState({
        currentBrand: i.text,
        currentType: null,
        currentModel: '請選擇型號',
        // key: this.state.key + 1
      })
    }
    // this.updateKey()
  };
  selectModel = (value, i) => {
    console.log(value, i);
    // call APIs
    this.setState({
      currentModel: i.text
    })
  };
  updateKey = () => {
    this.setState({ key: this.state.key + 1 })
    console.log(this.state.key);
  }
  render() {
    return (
      <main className="Instruction">
        <Header />
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '手機/平板操作說明', link: '/' },
          ]}
          color='white'
        />
        <SectionBanner2
          image={{
            md: '/resources/cbu/img/ebu-aws-banner-1440-x-460@2x.jpg',
            sm: '/resources/cbu/img/group-2.jpg',
          }}
          title='<div class="mt-4">手機/平板操作說明</div>'
          className="is-360 is-prmotion-banner top-0"
          action={null} />
        <div className="ribbon-bg">
          <section className='pb-0 pt-md-7 pt-4'>
            <div className='fui-container mb-3'>
              <h4 className="is-text-darkgray50 mt-0 mb-md-7 mb-4">
                此服務提供您查看各款手機之資訊與相關操作手冊之下載。
              </h4>
              <Grid container spacing={2} className="mb-4">
                <Grid item xs={12} sm={3}>
                  <Dropdown
                    className="is-button"
                    list={this.state.typeList}
                    label={!!this.state.currentType ? this.state.currentType : "請選擇類別"}
                    arrow={true}
                    hasCheck={false}
                    onChange={(item, i) => this.selectType(item, i)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Dropdown
                    className="is-button"
                    list={this.state.brandList}
                    label={!!this.state.currentBrand ? this.state.currentBrand : "請選擇品牌"}
                    arrow={true}
                    hasCheck={false}
                    onChange={(item, i) => this.selectBrand(item, i)}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Dropdown
                    className={`is-button ${this.state.modelList.length == 0 ? 'disabled' : ''}`}
                    list={this.state.modelList}
                    label={!!this.state.currentModel ? this.state.currentModel : "請選擇型號"}
                    arrow={true}
                    hasCheck={false}
                    onChange={(item, i) => this.selectModel(item, i)}
                  />
                </Grid>
              </Grid>
              <div>
                <InstructionList
                  title="手機"
                  list={this.state.phone}
                />
                <InstructionList
                  title="平板"
                  list={this.state.pad}
                />
                <InstructionList
                  title="網卡與路由器"
                  list={this.state.router}
                />
                <InstructionList
                  title="智慧物聯網"
                  list={this.state.smart}
                />
                <div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    );
  }
}
export default Instruction;