import axios from 'axios';


export const cfgMsg = async() => {
  /**
   * 若要串接 API
   * 1. 換上正確的 url
   * 2. 檢查 return 是否成功回傳
   * 3. 可再根據需要做判斷與處理後再回傳
   *
   * return axios('https://staging.vas.fetnet.net/eService_Revamp/api/mock/prepaidDetailController/queryPrepaidDetail').then(result => {
   *  return result.rateChangeInfo;
   * })
  */

  return '您可以了解您名下預付卡門號的使用期限、可用餘額及剩餘上網用量'
}
