import React from 'react';
import Autocomplete from 'react-autocomplete';
import Button from './Button';
import PropTypes from 'prop-types';
import { searchHelpCenterKeyword } from './../stores/action';
import { withRouter } from 'react-router-dom';
import store from './../stores';

class KeywordInput extends React.Component {
  constructor(props) {
    super(props);
    let query = '';
    if (typeof window !== 'undefined') {
      query = new URLSearchParams(window.location.search);
    }
    this.input = React.createRef();
    this.state = {
      isEBU: typeof window !== 'undefined' && window.location.pathname.indexOf('ebu') > -1,
      keywordMenu: this.props.defaultKeyword.slice(0, 6),
      keyword: query.get('keyword') || '',
    };

    this.doSearch = this.doSearch.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const keyword = new URLSearchParams(this.props.location.search).get('keyword');
    const preKeyword = new URLSearchParams(prevProps.location.search).get('keyword');

    if (typeof keyword !== 'undefined' && keyword !== preKeyword) {
      this.setState({
        keyword: keyword,
      });
    }
  }

  highlightKeyword(word) {
    if (!this.state.keyword || typeof this.state.keyword !== 'string') return word;
    let value = this.state.keyword.toLowerCase();
    let index = word.toLowerCase().indexOf(value);

    if (index === -1 || value.value === '') {
      return word;
    } else {
      // console.log(word.substring(0,index) + "<span class='highlight'>" + word.substring(index,index+value.length) + "</span>" + word.substring(index + value.length))
      return (
        word.substring(0, index) +
        "<span class='highlight'>" +
        word.substring(index, index + value.length) +
        '</span>' +
        word.substring(index + value.length)
      );
    }
  }

  resetInput = () => {
    this.setState({
      keyword: '',
    });
  };

  // doSearch(text) {
  //   store.dispatch(searchHelpCenterKeyword({
  //     keyword: this.state.keyword,
  //     storeType: this.props.storeType || 'search'
  //   }));
  // }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.doSearch();
    }
  };

  doSearch(text) {
    if (this.state.keyword) {
      window.location.href = `${this.props.path}?actionName=SEARCH&searchKey=${text || this.state.keyword}`;
    }
  }

  render() {
    return (
      <div className='keyword-input-container' id={this.props.id}>
        {this.props.title ? <h2>{this.props.title}</h2> : ''}
        <Autocomplete
          wrapperStyle={{ width: '100%', position: 'relative' }}
          getItemValue={(item) => item}
          items={this.state.keywordMenu}
          value={this.state.keyword}
          onKeyDowe={this.inputChange}
          renderInput={(props) => (
            <div className={`fui-input-group is-keyword`}>
              <div className='fui-input'>
                <input
                  ref={this.input}
                  {...props}
                  type='text'
                  placeholder={this.props.placeholder || '立即搜尋 IOT'}
                  onKeyDown={this.handleKeyDown}
                />
                {props.value !== '' ? (
                  <div className='reset' onClick={this.resetInput}>
                    <i className='icon-close'></i>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='fui-action'>
                <Button onClick={this.doSearch} btnStyle='primary'>
                  搜尋
                </Button>
              </div>
            </div>
          )}
          onSelect={(value, item) => {
            this.setState({ keyword: item });
          }}
          onChange={(event, value) => {
            this.setState({ keywordMenu: [] });
            this.setState({ keyword: value });
            let menu = this.props.defaultKeyword.filter((menu) => menu.toLowerCase().indexOf(value.toLowerCase()) > -1);
            this.setState({ keywordMenu: menu });
          }}
          renderMenu={(children) => <div className='fui-dropdown fui-keyword-menu'>{children}</div>}
          renderItem={(item, isHighlighted) => (
            <div key={`autocompelte-${item}`} className={`fui-item ${isHighlighted ? 'is-selected' : ''}`}>
              <span className='prefix'>
                <i className='icon-search'></i>
              </span>
              <div
                className='content'
                dangerouslySetInnerHTML={{
                  __html: this.highlightKeyword(item),
                }}></div>
            </div>
          )}
        />
      </div>
    );
  }
}

KeywordInput.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  keyword: PropTypes.string,
  defaultKeyword: PropTypes.arrayOf(PropTypes.string),
  storeType: PropTypes.string,
};

export default withRouter(KeywordInput);
