import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import Collapse from '../../components/collapse/Collapse';
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import Panel from '../../components/panel/Panel';
import TabPane from '../../components/tab/TabPane';
import SectionCarousel3 from '../../components/partials/card/SectionCarousel3';
import { Grid } from '@material-ui/core';
import ServiceTag from '../../components/partials/ServiceTag';
import Dropdown from '../../components/Dropdown';
import Link from '../../components/Link';
// import * as Mock from '../../mock/AddValue.js';
class OO7 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCountry: {
        value: '香港',
        text: '香港',
        code: '852',
        O07: {
          normal: '0.1',
          discount: '0.09'
        },
        O17: {
          normal: '3.8',
          discount: '3.4'
        }
      },
      country: [
        {
          value: '香港',
          text: '香港',
          code: '852',
          O07: {
            normal: '0.1',
            discount: '0.09'
          },
          O17: {
            normal: '3.8',
            discount: '3.4'
          }
        },
        {
          value: '中國大陸',
          text: '中國大陸',
          code: '122',
          O07: {
            normal: '0.2',
            discount: '0.19'
          },
          O17: {
            normal: '3.1',
            discount: '2.4'
          }
        },
        {
          value: '澳門',
          text: '澳門',
          code: '852',
          O07: {
            normal: '0.1',
            discount: '0.09'
          },
          O17: {
            normal: '3.8',
            discount: '3.4'
          }
        },
        {
          value: '日本',
          text: '日本',
          code: '852',
          O07: {
            normal: '0.1',
            discount: '0.09'
          },
          O17: {
            normal: '3.8',
            discount: '3.4'
          }
        },
        {
          value: '印尼',
          text: '印尼',
          code: '852',
          O07: {
            normal: '0.1',
            discount: '0.09'
          },
          O17: {
            normal: '3.8',
            discount: '3.4'
          }
        },
        {
          value: '菲律賓',
          text: '菲律賓',
          code: '852',
          O07: {
            normal: '0.1',
            discount: '0.09'
          },
          O17: {
            normal: '3.8',
            discount: '3.4'
          }
        },
        {
          value: '其他',
          text: '其他',
          code: '852',
          O07: {
            normal: '0.1',
            discount: '0.09'
          },
          O17: {
            normal: '3.8',
            discount: '3.4'
          }
        },
        {
          value: '其他',
          text: '其他',
          code: '852',
          O07: {
            normal: '0.1',
            discount: '0.09'
          },
          O17: {
            normal: '3.8',
            discount: '3.4'
          }
        },
        {
          value: '其他',
          text: '其他',
          code: '852',
          O07: {
            normal: '0.1',
            discount: '0.09'
          },
          O17: {
            normal: '3.8',
            discount: '3.4'
          }
        },
        {
          value: '其他',
          text: '其他',
          code: '852',
          O07: {
            normal: '0.1',
            discount: '0.09'
          },
          O17: {
            normal: '3.8',
            discount: '3.4'
          }
        },
        {
          value: '其他',
          text: '其他',
          code: '852',
          O07: {
            normal: '0.1',
            discount: '0.09'
          },
          O17: {
            normal: '3.8',
            discount: '3.4'
          }
        },
      ]
    }
  }
  dropdownChange = item => {
    this.setState({
      currentCountry: item
    })
  };
  render() {
    return (
      <main className="OO7">
        <Header />
        <NavAnchor
          pageTitle='<a href="#">國際電話</a>'
          button={{
            link: '#',
            text: '聯絡我們',
          }}
          tabs={[
            { label: '費率查詢', link: '#autoConnection' },
            { label: '如何撥打', link: '#howTo' },
            { label: '更多優惠', link: '#promotion' },
            { label: '常見問題', link: '#faq-007' },
          ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '國際電話', link: '/' }
          ]}
          color='white'
        />
        <SectionBanner2
          image={{
            md: '/resources/cbu/otherService/cbu-007-banner-1920x470.jpg',
            sm: '/resources/cbu/otherService/cbu-007-750x720.jpg',
          }}
          title='國際電話'
          description='
            <h6 class="mt-1 is-text-regular">
              市話或手機都能用，直撥 007 / 017 即享有高品質通話及優惠費用！
            </h6>'
          action={null} />
        <div className="ribbon-bg">
          <section className='pb-0'>
            <div className='fui-container mb-3'>
              <section id="autoConnection" className="pb-0 pt-3">
                <h2 className="">國際電話簡介與費率</h2>
                <div className="fui-segments">
                  <div className="paper m-0">
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={12} md={6}>
                        <img className="" src="/resources/otherService/call-007.svg" alt="007" />
                        <h4 className="mb-0">007 國際電話</h4>
                        <p className="body">007 國際電話可從台灣撥打至全球200個以上的國家或地區。本服務提供高品質國際語音專線，讓您通話清晰，溝通超順暢。</p>
                        <button className='fui-button is-secondary ' onClick={e => console.log(e)
                        }>
                          <span className='text'>查看007費率</span>
                        </button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <img className="" src="/resources/otherService/call-017.svg" alt="017" />
                        <h4 className="mb-0">017 經濟型國際電話</h4>
                        <p className="body">017 經濟型國際電話是應用最新穎的VoIP技術，提供完善的通話環境，提供另一項優質又經濟的語音服務，輕鬆通話無負擔。</p>
                        <button className='fui-button is-secondary ' onClick={e => console.log(e)
                        }>
                          <span className='text'>查看017費率</span>
                        </button>
                      </Grid>
                    </Grid>
                    <hr />
                    <div className="search-country">
                      <h4 className="mb-2">快速查詢常用國家</h4>
                      <div>
                        <Dropdown
                          label={this.state.currentCountry.value}
                          list={this.state.country}
                          arrow={true}
                          onChange={item => this.dropdownChange(item)}
                          className="is-button is-left"
                        />
                      </div>
                      <div className="d-flex  flex-align-center">
                        <h4 className="mr-2">{this.state.currentCountry.value}</h4>
                        <h4 className="is-bg-lightgray70 py-1 px-2"><span className="body is-text-regular">國碼：</span>{this.state.currentCountry.code}</h4>
                      </div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <h4 className="mb-2 mt-0 is-text-darkgray50 is-text-regular">007 國際電話費率</h4>
                          <div className="rate-plan-box">
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={12} md={6}>
                                <p className="mt-0 mb-1">一般時段</p>
                                <h2>NT ${this.state.currentCountry.O07.normal}<span className="body is-text-regular ml-1">/秒</span></h2>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6}>
                                <p className="mt-0 mb-1">減價時段</p>
                                <h2>NT ${this.state.currentCountry.O07.discount}<span className="body is-text-regular ml-1">/秒</span></h2>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <h4 className="mb-2 mt-0 is-text-darkgray50 is-text-regular">017 經濟型國際電話費率</h4>
                          <div className="rate-plan-box">
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={12} md={6}>
                                <p className="mt-0 mb-1">一般時段</p>
                                <h2>NT ${this.state.currentCountry.O17.normal}<span className="body is-text-regular ml-1">/分</span></h2>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6}>
                                <p className="mt-0 mb-1">減價時段</p>
                                <h2>NT ${this.state.currentCountry.O17.discount}<span className="body is-text-regular ml-1">/分</span></h2>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <Collapse
                    title="備註說明"
                    content={`
                    1. 此功能不需帳號及密碼，只要輸入身分資料完成登入檢核就可進行繳費。<br/>
                    2. 快速登入後僅能進行繳費功能，若需使用其他功能，請先進行登入。<br/>
                    3. 繳納費用非一般性消費支出，請洽各發卡銀行確認是否有刷卡紅利或現金回饋。
                  `}
                  />
                </div>
              </section>
              <section id="howTo" className="pb-0 mt-0">
                <h2 className="mb-1">如何撥打國際電話</h2>
                <p className="body is-text-darkgray50 mt-0 mb-4">遠傳或非遠傳用戶皆需完成開通服務後即可使用，開通方式因優惠活動而異，詳情請見下方「更多優惠」查看各優惠內容。</p>
                <div className="paper m-1">
                  <h4 className=" mb-2">在台灣撥打國際電話</h4>
                  <ul className="green-dot-list">
                    <li>撥打電話：國際電話代碼 + 國碼 + 區域號碼 + 當地電話號碼。</li>
                  </ul>
                  <h4 className="is-text-darkgray50 is-text-regular mb-2">台灣撥打至美國為例：</h4>
                  <Grid container spacing={2} className="">
                    <Grid item xs={12} md={6}>
                      <div className="rate-plan-box">
                        <div className="mb-2 mt-0 is-text-black50 body">007 國際電話</div>
                        <h4 className="is-text-black50">007 + 1 + 626 + 1234-5678</h4>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="rate-plan-box">
                        <div className="mb-2 mt-0 is-text-black50 body">017 經濟型國際電話</div>
                        <h4 className="is-text-black50">017 + 1 + 626 + 1234-5678</h4>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </section>
            </div>

            <section id="howTo" className="pb-0 mt-0 mb-3">
              <div className="fui-container">
                <h2 className="mb-1">如何撥打國際電話</h2>
                <p className="body is-text-darkgray50 mt-0 mb-4">遠傳或非遠傳用戶皆需完成開通服務後即可使用，開通方式因優惠活動而異，詳情請見下方「更多優惠」查看各優惠內容。</p>
                <div className="paper m-1">
                  <h4 className=" mb-2">在台灣撥打國際電話</h4>
                  <ul className="green-dot-list">
                    <li>撥打電話：國際電話代碼 + 國碼 + 區域號碼 + 當地電話號碼。</li>
                  </ul>
                  <h4 className="is-text-darkgray50 is-text-regular mb-2">台灣撥打至美國為例：</h4>
                  <Grid container spacing={2} className="">
                    <Grid item xs={12} md={6}>
                      <div className="rate-plan-box">
                        <div className="mb-2 mt-0 is-text-black50 body">007 國際電話</div>
                        <h4 className="is-text-black50">007 + 1 + 626 + 1234-5678</h4>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="rate-plan-box">
                        <div className="mb-2 mt-0 is-text-black50 body">017 經濟型國際電話</div>
                        <h4 className="is-text-black50">017 + 1 + 626 + 1234-5678</h4>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </section>

            <SectionCarousel3 {...{
              title: '更多優惠',
              id: "promotion",
              cards: [
                {
                  image: '/resources/cbu/otherService/img-01.png',
                  title: '打 $1 送 $1 帳單折抵',
                  description: '遠傳月租型獨享優惠！不分時段，不分國家，每打1元就送1元回饋金！',
                  link: '#',
                },
                {
                  image: '/resources/cbu/otherService/img-02.png',
                  title: '市話撥打 007 享優惠',
                  description: '免收月租費，撥打到大陸、香港、日本於一般時段及減價時段皆各享有優惠...',
                  link: '#',
                },
                {
                  image: '/resources/cbu/otherService/img-03.png',
                  title: '市話 007 晶鑽專案',
                  description: '申請晶鑽方案，撥打中國大陸、香港、加拿大、美國，不分時段皆享優惠價！',
                  link: '#',
                },
              ],
              more: { text: '看更多', link: '#' },
            }} />
          </section>
        </div>
        <Panel className="is-bg-lightgray70 mb-0">
          <div id="faq-007" className="fui-container">
            <h3 className="is-text-darkgray50 is-text-medium m-0">常見問題</h3>
            <SectionFaq {...{
              collapseList: [
                {
                  title: '世界各國的國碼是什麼？',
                  content: `世界各國的國碼是什麼？`,
                },
                {
                  title: '不是遠傳用戶，也可以撥打 007/017 國際電話嗎？',
                  content: `不是遠傳用戶，也可以撥打 007/017 國際電話嗎？`,
                },
                {
                  title: '請問客服電話是什麼？',
                  content: `請問客服電話是什麼？`,
                },
              ],
              container: false,
              to: '#',
              target: '_blank'
            }} />
          </div>
        </Panel>
        <ServiceTag {...{
          title: '看看大家都搜什麽',
          tags: [
            {
              name: '國際漫遊',
              link: '#',
            },
            {
              name: '外國旅客上網卡',
              link: '#',
            },
            {
              name: '獨家資費',
              link: '#',
            },
            {
              name: '遠傳易付卡',
              link: '#',
            },
          ]
        }} />
      </main>
    );
  }
}
export default OO7;