import React from 'react';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';

import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Panel from '../../components/panel/Panel';

import Modal from 'react-modal';
import Link from '../../components/Link';
import FormBanner from '../../components/partials/banner/FormBanner';
import Button from '../../components/Button';
import Checkbox from '../../components/form/Checkbox';

import RoamingPlanRadio from '../../components/form/RoamingPlanRadio';

import * as Mock from '../../mock/RoamingPlan';

class Add extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      canSubmit: false,
      form: {
        select_plan: { value: '', required: true },
        agree: { value: false, required: false },
      },
    }
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    this.setState({
      form: newForm
    })
  }

  openModal = () => {
    this.setState({
      modalOpen: true
    })
  }

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };

  submit = () => {
    
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊方案申請/查詢'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="漫遊上網流量加購"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="form-section roaming-plan no-bg">
          <div className="fui-container">
            <Formsy>
              <Panel>
                <h4 className="mt-0 mb-4">請選擇要加購的流量</h4>

                <RoamingPlanRadio {...Mock.addPlan} 
                  name='select_plan'
                  selected={this.state.form.select_plan.value}
                  getSelected={e => this.onChange('select_plan', e)}
                  openModal={this.openModal}
                />
              </Panel>

                
              <Checkbox
                name='agree'
                label='我已閱讀並了解欲申請方案的費率及詳細說明'
                value='1'
                checked={this.state.form.agree.value}
                onChange={this.onChange}
              />

              <div className="button-group">
                <div className="form-group two-buttons">
                  <Button btnStyle='primary' size='medium'disabled={!this.state.form.agree.value} >下一步</Button>
                  <Button btnStyle='secondary' size='medium'>取消</Button>
                </div>
              </div>
            </Formsy>
          </div>
        </section>

        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            若您的門號狀態為：欠費限話、暫停、損壞瑕疵補卡等情形，請至遠傳門市或來電客服中心處理。
          `}
        />

        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5 className='align-center'>方案詳細說明</h5>
            <p className='align-left mt-0'>上網費率</p>
            <div className="fui-table-response mb-2 mb-lg-4">
              <table className="modal-roaming-country-table">
                <thead>
                  <tr>
                    <th>用量</th>
                    <th>上網費率</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>大於（含） 2MB</td>
                    <td>$99 / 天</td>
                  </tr>
                  <tr>
                    <td>小於 2MB</td>
                    <td>$99 / 天</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="align-left mb-3 notice">
              <p className='is-text-medium mb-1'>注意事項</p>
              <p className=''>1.本優惠費率以到達當地開始上網起，連續24小時為一日計價。<br/>
              2.適用對象: 至國外漫遊登錄在KT/SKT之遠傳月租型客戶。出國前，請致電客服中心開啟/確認國際數據漫遊服務。手機直撥123/888或 MVPN用戶99888 (免費)；免付費客服專線0800-058-885。市話撥449-5888(市內計費)，在國外時請電+886936010888。</p>
            </div>

            <div className="fui-buttons justify-center">
              <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
            </div>



            {/* <h5 className='align-center'>多國家上網吃到飽</h5>
            <p className='align-left mt-0'>適用國家及費率</p>
            <div className="fui-table-response mb-2 mb-lg-4">
              <table className="modal-roaming-country-table">
                <thead>
                  <tr>
                    <th>國家</th>
                    <th>上網費率</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>中國、香港、澳門</td>
                    <td>$ 0 / 天</td>
                  </tr>
                  <tr>
                    <td>韓國、新加坡、泰國、印尼、菲律賓</td>
                    <td>$99 / 天</td>
                  </tr>
                  <tr>
                    <td>馬來西亞、日本、關島、塞班、天寧島、羅塔島、美國、加拿大、波多黎各</td>
                    <td>$299 / 天</td>
                  </tr>
                  <tr>
                    <td>柬埔寨、越南、澳洲、紐西蘭、法國、以色列、竂國</td>
                    <td>$399 / 天</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="align-left mb-3 notice">
              <p className='is-text-medium mb-1'>貼心提醒</p>
              <p className=''>1. 優惠費率以一網一日計價，用戶若於一日內漫遊到多個國家/地區的網路，會依網數進行一日計費 (例如：用戶若於一天內漫遊至第二個國家/地區的網路，將分別依二網用量各自一日計價，以此類推)。<br/>
              2. 本優惠費率以到達當地開始上網起，連續24小時為一日計價。</p>
            </div>

            <div className="fui-buttons justify-center">
              <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
            </div>






            <h5 className='align-center'>全球定量包 1GB</h5>
            <p className='align-left mt-0'>適用國家</p>

            <div className="fui-table-response mb-2 mb-lg-4">
              <table className="modal-roaming-package-table">
                <tbody>
                  <tr>
                    <th>歐洲（39 國）</th>
                    <td>阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國</td>
                  </tr>
                  <tr>
                    <th>美洲（8 國）</th>
                    <td>阿根廷、貝里斯、加拿大、智利、波多黎各、美屬維京群島（處女島）、美國、巴拿馬</td>
                  </tr>
                  <tr>
                    <th>大洋洲（2 國）</th>
                    <td>澳洲、紐西蘭</td>
                  </tr>
                  <tr>
                    <th>非洲（6 國）</th>
                    <td>剛果民主共和國、迦納、莫三比克、南非、坦尚尼亞、埃及</td>
                  </tr>
                  <tr>
                    <th>亞洲（5 國）</th>
                    <td>印度、哈蕯克、蒙古、斯里蘭卡、土耳其</td>
                  </tr>
                  <tr>
                    <th>中東（5 國）</th>
                    <td>巴基斯坦、以色列、科威特、卡達、沙烏地阿拉伯</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="fui-buttons justify-center">
              <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
            </div> */}
          </div>
        </Modal>

      </main>
    )
  }
}

export default Add;