import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import TextInput from '../../components/form/TextInput';
import Select from '../../components/form/Select';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import RadioGroup from '../../components/form/RadioGroup';
import numberFormatter from '../../utils/numberFormatter';

import Tooltip from '../../components/Tooltip';

class getPhoneBillPaySettingSelectAmountPage extends React.Component {
  constructor(props) {
    super(props);
    this.form1 = React.createRef();
    this.form2 = React.createRef();

    this.validCode = React.createRef();
    this.creditGroup = React.createRef();
    this.bankAccountInput = React.createRef();
    this.state = {
      step: 1,
      canSubmit1: false,
      canSubmit2: false,
      form_1: {
        amount: { value: '3000', required: true },
        payment: { value: '1', required: true }
      },
      form_2: {
        credit_number_1: { value: "", required: true },
        credit_number_2: { value: "", required: true },
        credit_number_3: { value: "", required: true },
        credit_number_4: { value: "", required: true },
        credit_year: { value: null, required: true },
        credit_month: { value: null, required: true },
        credit_code: { value: '', required: true },
        bank_code: { value: '', required: true },
        bank_account: { value: '', required: true },
        agree: { value: false, required: true },
      }
    }
  }

  setInputFilter = (textbox, inputFilter) => {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }

  onChange1 = (name, value) => {
    let bankForm = {
      bank_code: { value: '', required: true },
      bank_account: { value: '', required: true },
      agree: { value: false, required: true }
    }
    let creditForm = {
      credit_number_1: { value: "", required: true },
      credit_number_2: { value: "", required: true },
      credit_number_3: { value: "", required: true },
      credit_number_4: { value: "", required: true },
      credit_year: { value: null, required: true },
      credit_month: { value: null, required: true },
      credit_code: { value: '', required: true },
      agree: { value: false, required: true }
    }

    let newForm = Object.assign(this.state.form_1);
    newForm[name].value = value;

    this.setState({
      form_1: newForm,
      form_2: this.state.form_1.payment.value === '1' ? creditForm : bankForm
    })
  }

  onChange2 = (name, value) => {
    let newForm = Object.assign(this.state.form_2);
    newForm[name].value = value;

    this.setState({
      form_2: newForm
    })
  }

  disableButton1 = () => {
    this.setState({
      canSubmit1: false,
    });
  };

  enableButton1 = () => {
    this.setState({
      canSubmit1: true,
    });
  };

  disableButton2 = () => {
    this.setState({
      canSubmit2: false,
    });
  };

  enableButton2 = () => {
    this.setState({
      canSubmit2: true,
    });
  };

  nextStep = () => {
    this.setState({
      step: 2,
    })

    if (this.state.form_1.payment.value === '1') {
      this.setInputFilter(this.validCode.current.getElementsByTagName('input')[0], function (value) {
        return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
      });

      let creditInputs = Array.from(this.creditGroup.current.getElementsByTagName('input'));
      creditInputs.map((input, i) => {
        this.setInputFilter(input, function (value) {
          return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
        });

        input.addEventListener('keyup', e => {
          let index = creditInputs.indexOf(e.target);
          if (e.currentTarget.value.length === 4 && index < 3) {
            creditInputs[index + 1].focus();
          }
        })
      })
    }
    if (this.state.form_1.payment.value === '2') {
      this.setInputFilter(this.bankAccountInput.current, function (value) {
        return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
      });
    }
  }

  submit = () => {

  }

  render() {
    const { form_1, form_2, step } = this.state;

    return (
      <main>

        <NavAnchor
          pageTitle='帳單代收'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="額度調整繳費"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className={step === 1 ? 'd-block' : 'd-none'}>
          <div className="fui-container">
            <Formsy onValid={this.enableButton1} onInvalid={this.disableButton1} ref={this.form1}>
              <div className='form-group'>
                <h4 className='form-description is-text-darkgray50'>你將針對 Mycard、GASH、Sonet、Garena...等其他小額代收服務額度，進行額度調整繳費，繳費之新額度當月有效。請確認你了解調整額度繳費，完成額度增加後，將無法進行退款。</h4>
                <div className='is-text-accent text-sm'>
                  <span className='required-dot'></span> 必填
                </div>
              </div>
              <div className='form-group'>
                <Grid container>
                  <Grid item xs={12} sm={12} md={5}>
                    <div className="form-group">
                      <label className="is-required">繳費金額</label>
                      <Select
                        label=""
                        required={true}
                        name='amount'
                        options={[
                          { text: '3,000', value: '3000' },
                          { text: '4,000', value: '4000' },
                          { text: '5,000', value: '5000' }
                        ]}
                        value={form_1.amount.value}
                        onChange={this.onChange}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <RadioGroup
                      label='請選擇付款方式'
                      name="payment"
                      options={[
                        { label: '信用卡', value: '1' },
                        { label: '銀行帳號', value: '2' }
                      ]}
                      default={form_1.payment.value}
                      onChange={this.onChange1}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="form-group two-buttons">
                <Button btnStyle='primary' size='large' onClick={this.nextStep} disabled={!this.state.canSubmit1}>下一步</Button>
                <Button btnStyle='secondary' link='/e-service' size='large'>取消</Button>
              </div>
            </Formsy>
          </div>
        </section>
        <section className={step === 2 ? 'd-block' : 'd-none'}>
          <div className="fui-container">
            <Formsy onValid={this.enableButton2} onInvalid={this.disableButton2} ref={this.form2}>
              <div className='form-group'>
                <h4 className='form-description is-text-darkgray50'>你將針對 Mycard、GASH、Sonet、Garena...等其他小額代收服務額度，進行額度調整繳費，繳費之新額度當月有效。請確認你了解調整額度繳費，完成額度增加後，將無法進行退款。</h4>
                <div className='is-text-accent text-sm'>
                  <span className='required-dot'></span> 必填
                </div>
              </div>
              <div className='form-group'>
                <label>應繳金額</label>
                <div>
                  <h1 className='is-text-error'>{numberFormatter(form_1.amount.value)}元</h1>
                </div>
              </div>

              {
                form_1.payment.value === '1' ? (
                  <div>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <div className="form-group">
                          <label className="is-required">信用卡卡號</label>
                          <div className="credit-input" ref={this.creditGroup}>
                            <input
                              type='tel'
                              required={true}
                              name='credit_number[]'
                              maxLength='4'
                              value={form_2.credit_number_1.value}
                              onChange={e => this.onChange2('credit_number_1', e.currentTarget.value)} />
                            <span>-</span>
                            <input
                              type='tel'
                              required={true}
                              name='credit_number[]'
                              maxLength='4'
                              value={form_2.credit_number_2.value}
                              onChange={e => this.onChange2('credit_number_2', e.currentTarget.value)} />
                            <span>-</span>
                            <input
                              type='tel'
                              required={true}
                              name='credit_number[]'
                              maxLength='4'
                              value={form_2.credit_number_3.value}
                              onChange={e => this.onChange2('credit_number_3', e.currentTarget.value)} />
                            <span>-</span>
                            <input
                              type='tel'
                              required={true}
                              name='credit_number[]'
                              maxLength='4'
                              value={form_2.credit_number_4.value}
                              onChange={e => this.onChange2('credit_number_4', e.currentTarget.value)} />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <div className="form-group mb-2">
                          <label className="is-required">信用卡有效日期</label>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Select
                                required={true}
                                name="credit_month"
                                label={"月"}
                                value={form_2.credit_month.value}
                                options={[
                                  { text: '01', value: '01' },
                                  { text: '02', value: '02' },
                                  { text: '03', value: '03' },
                                  { text: '04', value: '04' },
                                  { text: '05', value: '05' },
                                  { text: '06', value: '06' },
                                  { text: '07', value: '07' },
                                  { text: '08', value: '08' },
                                  { text: '09', value: '09' },
                                  { text: '10', value: '10' },
                                  { text: '11', value: '11' },
                                  { text: '12', value: '12' }
                                ]}
                                onChange={this.onChange2} />
                            </Grid>
                            <Grid item xs={6}>
                              <Select
                                name="credit_year"
                                label={"年"}
                                required={true}
                                value={form_2.credit_year.value}
                                options={[
                                  { text: '20', value: '20' },
                                  { text: '21', value: '21' },
                                  { text: '22', value: '22' },
                                  { text: '23', value: '23' },
                                  { text: '24', value: '24' },
                                  { text: '25', value: '25' },
                                  { text: '26', value: '26' },
                                  { text: '27', value: '27' },
                                  { text: '28', value: '28' },
                                  { text: '29', value: '29' },
                                  { text: '30', value: '30' }
                                ]}
                                onChange={this.onChange2} />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <div className="form-group mb-2">
                          <label className="is-required">
                            檢查碼
                            <Tooltip
                              content={<i className='icon-information i-24 is-text-business100'></i>}
                              trigger="click"
                              tooltip={`信用卡背面三碼數字`}
                            />
                          </label>
                          <div className='credit-input' ref={this.validCode}>
                            <TextInput
                              type='tel'
                              name='credit_code'
                              required={true}
                              maxLength="3"
                              validations={{
                                maxLength: 3,
                                minLength: 3
                              }}
                              validationError={"請輸入檢查碼"}
                              value={form_2.credit_code.value}
                              onChange={val => this.onChange2('credit_code', val)}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <img
                      src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured.png'}
                      srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured@2x.png'}
                      alt='' height="32" className="mr-1" />
                    <img
                      src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb.png'}
                      srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb@2x.png'}
                      alt='' height="32" className="mr-1" />
                    <img
                      src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard.png'}
                      srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard@2x.png'}
                      alt='' height="32" className="mr-1" />
                    <img
                      src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa.png'}
                      srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa@2x.png'}
                      alt='' height="32" className="mr-1" />
                  </div>
                ) : null
              }

              {
                form_1.payment.value === '2' ? (
                  <Grid container>
                    <Grid item xs={12} md={5}>
                      <div className="form-group">
                        <label className="is-required">銀行代碼</label>
                        <Select
                          required={true}
                          name="bank_code"
                          label="請選擇"
                          options={[
                            { text: 'BankCode', value: '1' }
                          ]}
                          value={form_2.bank_code.value}
                          onChange={this.onChange2} />
                      </div>
                      <div ref={this.bankAccountInput}>
                        <LabelInput
                          required={true}
                          label="銀行帳號(限本人)"
                          name="bank_account"
                          value={form_2.bank_account.value}
                          onChange={this.onChange2}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="form-group mb-0">
                        <label className='is-required'>注意事項</label>
                        <div>
                          <CheckboxCollapse
                            name="agree"
                            label="本人已詳閱及了解此注意事項並同意遵守"
                            validationError='完整內容收合與展開'
                            validations={{
                              isChecked: form => form.agree
                            }}
                            checked={form_2.agree.value}
                            value={form_2.agree.value}
                            getCheckbox={e => this.onChange2('agree', e)}
                            collapseContent={`
                            <p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。</p>
                            <p>使用「全國繳費平台」繳納遠傳電信服務費用注意事項：</p>
                            <p>
                              1.限以門號申請本人活存帳戶使用此服務。<br/>
                              2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                              3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                              4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                              5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。本人已詳閱及了解上述注意事項並同意遵守
                            </p>
                          `}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : null
              }


              <div className="form-group two-buttons page-action">
                <Button btnStyle='primary' size='large' onClick={this.submit} disabled={!this.state.canSubmit2}>確認送出</Button>
                <Button btnStyle='secondary' size='large' onClick={e => this.setState({ step: 1 })}>取消</Button>
              </div>
            </Formsy>
          </div>
        </section>
        {
          step === 2 ? (
            <SectionCollapseInfo
              title="貼心小叮嚀"
              content={`
                1. 提醒您：此繳費費用，為您同意先行繳交已使用部分固定金額，以恢復部分額度，此繳費無法進行溢繳費退款，請確認同意後，再行繳費。<br/>
                2. 此先行繳交之費用，可用於扣抵實際產出之電信及代收款項，為了您審閱帳單便利，將同時為您將電信及代收帳單繳費聯合併。<br/>
                3. iTunes, GooglePlay, Microsoft：於iTunes(蘋果), GooglePlay(安卓), Microsoft(微軟)交易可使用額度。<br/>
                4. 小額代收：於Mycard、GASH、Sonet、Garena…等其他代收服務。<br/>
                5. 您的帳單需無欠款，且服務功能及額度沒有被關閉，才可使用此服務。<br/>
                6. 當月進行調整繳費次數上限10次。(依狀況彈性調整上限)。<br/>
                7. 此額度調整繳費額度，僅當月有效。 例：7/22繳費3,000元可使用至7/31。
              `}
            />
          ) : null
        }
      </main>
    )
  }
}

export default getPhoneBillPaySettingSelectAmountPage;