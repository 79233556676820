import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paginate from './index';

export default class Pagination extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
  };

  static defaultProps = {
    initialPage: 1,
    pageSize: 10
  };

  constructor(props) {
    super(props);
    this.state = { pager: {} };

    this.onPageUIChange = this.onPageUIChange.bind(this);
  }

  componentDidMount() {
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.items.length !== prevProps.items.length) {
      this.setPage(this.state.pager.totalPages);
      this.forceUpdate()
    }
  }

  onPageUIChange(currentPageObj) {
    this.setPage(currentPageObj.selected + 1)
  }

  setPage(page) {
    const { items, pageSize } = this.props;
    let pager = this.state.pager;

    if (page < 1 || page > pager.totalPages) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(items.length, page, pageSize);

    // get new page of items from items array
    const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

    // update pager obj
    this.setState({ pager: pager });

    // call change page function in parent component
    this.props.onChangePage(pageOfItems, pager.totalPages);
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startIndex: startIndex,
      endIndex: endIndex,
    };
  }

  render() {
    const { pager } = this.state;
    return <div>
      <Paginate
        initialPage={this.props.initialPage - 1}
        forcePage={pager.currentPage - 1}
        pageCount={pager.totalPages}
        pageRangeDisplayed={pager.pageSize}
        marginPagesDisplayed={1}
        containerClassName={`fui-pagination ${this.props.className ? this.props.className : ''}`}
        previousLabel={<i className='icon-chevron-left'></i>}
        nextLabel={<i className='icon-chevron-right'></i>}
        firstLabel={<i className='icon-first'></i>}
        lastLabel={<i className='icon-last'></i>}
        onPageChange={this.onPageUIChange}
      />
    </div>;
  }
}
