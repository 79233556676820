import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import LabelInput from '../../components/form/LabelInput';
import {setInputFilter} from '../../utils/inputFilter';

import * as PrepaidService from '../../service/PrepaidService'

class depositCardPage extends React.Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.pwdInput = React.createRef();
    this.state = {
      form: {
        cardpwd: { value: '', required: true }
      }
    }

    PrepaidService.rechargeOption().then(data => {
      this.setState({
        info: data,
        amountList: data.amountList
      })
    })
  }

  componentDidMount() {
    setInputFilter(this.pwdInput.current.getElementsByTagName("input")[0], function(value) {
      return /^\w*$/.test(value);
    });
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    this.setState({
      form: newForm
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  submit = () => {
    this.props.history.push('/prepaidDepositController/prepaidDepositSuccess');
  };

  render() {
    const { form } = this.state;
    return (
      <main>
        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="儲值卡儲值"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} 
        />
        <section className={`page-body`}>
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
            <div className="fui-container">
              <div className='paper'>
                <Grid container>
                  <Grid item xs={7} md={7}>
                    <h4 className="mb-2 mt-0">儲值門號與餘額</h4>
                  </Grid>
                  <Grid item xs={5} md={5} className='align-right'>
                    <Button btnStyle='secondary' size='small'>查詢上網用量</Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="rate-plan-box">
                      <h5>門號</h5>
                      <h2>0926-899-868</h2>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="rate-plan-box">
                      <h5>餘額</h5>
                      <h2>$209.12</h2>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className='paper'>
                <Grid container>
                  <Grid item md={6} ref={this.pwdInput}>
                    <h4>請輸入14碼儲值卡密碼</h4>
                    <LabelInput
                      name="cardpwd"
                      label='儲值卡密碼'
                      required={true}
                      validationErrors={{
                        isDefaultRequiredValue: '請輸入儲值卡密碼',
                      }}
                      value={form.cardpwd.value}
                      onChange={this.onChange}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className="page-action">
                <Button btnStyle="primary" size='large' disabled={!this.state.canSubmit} onClick={this.submit}>確認</Button>
              </div>
            </div>
          </Formsy>
        </section>

        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            1. 選用月租帳單、銀行帳戶及信用卡支付易付卡門號儲值金額，其月租型門號或銀行帳戶及信用卡的所有人需與易付卡門號所有人為同一身分證號。<br/>
            2. 每張信用卡每月可支付易付卡額度為 $2,000 元，包含儲值及加購上網方案。<br/>
            3. 月租帳單每月累積消費總額依遠傳電信規範，若超過總額，將不得為易付卡支付儲值或加購上網方案。<br/>
            4. 非本國信用卡或非本國銀行帳戶，無法進行支付。<br/>
            5. 預付卡活動可參考「<a href="#">易付卡最新活動</a>」。<br/>
            6. 【門號效期到期通知】當用戶收到客戶名下所持易付卡門號，門號效期到期通知，且提醒須於3日內儲值之權益簡訊，務請客戶於期限內於遠傳電信各相關儲值通路，儘快儲值，以確保客戶門號效期之保留權益。 客戶透過門市、IVR、遠傳官網、行動客服APP及客服中心查詢門號到期日，係因系統執行與客戶權益簡訊仍有時間差距，僅可供部份參考；無法確保門號效期仍具持有效力。<br/>
            7. 每門易付卡餘額總額不得超過$5,000元。<br/>
            8. 易付卡任一活動所贈送的通信費(不含傳輸量)，除另有規範效期外，自每次完成贈送或儲值後6個月內有效，請於贈送效期內使用完畢，逾期失效，無法遞延、轉讓或折換現金<br/>
            9. 遠傳電信保有資費及服務異動修改之權利，最新資費服務內容以遠傳網站公告為主。
          `}
        />
      </main>
    )
  }
}

export default depositCardPage;