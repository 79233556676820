import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';

import Button from '../../components/Button';
import Ruler from '../../components/partials/Ruler';
import FormBanner from '../../components/partials/banner/FormBanner';
import numberFormatter from '../../utils/numberFormatter';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

let bill1 = {
  detailLink: '#123',
  date: '2020年04月',
  period: '2020/03/20 00:00 ~ 2020/03/23 18:23',
  info: {
    number: '0903430082',
    endDate: '2020/04/20',
    programName: '4G新絕配999'
  },
  fee: [
    {
      title: '網內互打',
      fee: 50,
      isRed: false
    },
    {
      title: '他網行動',
      fee: 50,
      isRed: false
    },
    {
      title: '市內通話',
      fee: 50,
      isRed: false
    },
    {
      title: '行動上網傳輸費',
      fee: 50,
      isRed: false
    },
    {
      title: '合計',
      fee: 1190,
      isRed: true
    },
  ],
  usage: {
    isLimited: true,
    totalUsage: 60,
    used: '0KB',
  },
  payment: '#',
  resendPayment: '#',

}

class Query4G extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activityOpen: true,
      form: {
        id: { value: '', required: true },
      },
      currentBill: bill1,
    }
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }
  loadData = () => {
    this.setState(prevState => ({
      currentBill: {
        ...prevState.currentBill,
        usage: {
          ...prevState.currentBill.usage,
          usageDetail: [
            ...prevState.currentBill.usage.usageDetail,
            {
              title: '亞洲輕量包中國型2GB',
              info: `使用期限 2020/09/12<br/>共 2.00GB(2,048MB)/已使用1.49GB(1,524MB)`,
              percentage: 0,
              valumn: {
                used: '500MB'
              }
            },
            {
              title: '亞洲輕量包中國型2GB',
              info: `使用期限 2020/09/12<br/>共 2.00GB(2,048MB)/已使用1.49GB(1,524MB)`,
              percentage: 80,
              valumn: {
                used: '500MB'
              }
            },
          ]
        },

      },
      activityOpen: false
    }))
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '/',
          }
          }
        />

        <FormBanner
          title="4G即時用量查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="query-index">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mt-0 mb-5 pb-md-3">
              提供您查詢4.5G門號上網用量的最新使用情形。
            </h4>
            <div className="paper mb-2">
              <h4 className="mt-md-4 mb-md-3 mb-2">
                您目前的費率
              </h4>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-md-3 py-md-3 px-2 py-2">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      查詢門號
                      </h5>
                    <h2 className='mb-0'>{this.state.currentBill.info.number}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-md-3 py-md-3 px-2 py-2">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0 d-sm-block d-flex justify-between">
                      方案
                    </h5>
                    <h2 className='mb-0'>{this.state.currentBill.info.programName}</h2>
                  </div>
                </Grid>
              </Grid>
            </div>

            <h2 className="mb-0 mt-md-5 mt-2 pt-2">已使用上網用量</h2>
            <div className='body is-text-darkgray50 mb-md-4 mb-3 mt-1'>計算期間：{this.state.currentBill.period}</div>

            {!this.state.currentBill.isLimited ? <div>
              <div className="paper pb-4">
                <div className="d-inline-block is-text-darkgray50 body">可使用 上網吃到飽不限速 / 已使用</div><div className="is-text-accent d-inline-block ml-1">{this.state.currentBill.usage.used}</div>
              </div>
              <div className='d-flex fui-buttons two-buttons mt-md-5 mt-0 pt-md-2 pt-4'>
                <Button link="#" className='mb-lg-0 mb-2' btnStyle='primary' size='large'>未出帳金額與上網用量</Button>
                <Button link="#" className='mb-0' btnStyle="secondary" size='large'>帳單查詢</Button>
              </div>
            </div> : <div className="paper merged pb-md-5 pb-4">
                <h4 className="mb-mb-3 mt-md-4 mt-0">用量總覽</h4>
                <Ruler
                  className="mb-md-4 mb-1"
                  percentage={this.state.currentBill.usage.totalUsage}
                  size="large"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <div className="is-bg-lightgray70 px-md-3 py-md-3 px-2 py-2">
                      <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                        {this.state.currentBill.usage.canUse.title}
                      </h5>
                      <div className="d-flex flex-align-baseline">
                        <h2 className='mb-0 mr-1'>{this.state.currentBill.usage.canUse.used}</h2>
                      ( <small>{this.state.currentBill.usage.canUse.rest}</small> )
                    </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="is-bg-lightgray70 px-md-3 py-md-3 px-2 py-2">
                      <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                        {this.state.currentBill.usage.used.title}
                      </h5>
                      <div className="d-flex flex-align-baseline">
                        <h2 className='mb-0 mr-1'>{this.state.currentBill.usage.used.used}</h2>
                      ( <small>{this.state.currentBill.usage.used.rest}</small> )
                    </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="is-bg-lightgray70 px-md-3 py-md-3 px-2 py-2">
                      <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                        {this.state.currentBill.usage.rest.title}
                      </h5>
                      <div className="d-flex flex-align-baseline">
                        <h2 className='mb-0 mr-1'>{this.state.currentBill.usage.rest.used}</h2>
                      ( <small>{this.state.currentBill.usage.rest.rest}</small> )
                    </div>
                    </div>
                  </Grid>
                </Grid>

                <div className='d-flex fui-buttons two-buttons mt-md-4 mt-2'>
                  <Button link="#" btnStyle='primary' size='large'>我要加購</Button>
                  <Button link="#" btnStyle="secondary" size='large'>帳單查詢</Button>
                </div>
              </div>}

            {!this.state.currentBill.usage.isLimited ? <div className="paper mb-0">
              <h4 className="mb-mb-3 mt-md-4 mt-0">使用明細</h4>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  {this.state.currentBill.usage.usageDetail.map((item, i) => {
                    return (
                      <div key={item.title + i} className="px-3 py-3 border mb-2">
                        <h5 className="mb-1 mt-0">{item.title}</h5>
                        <div className="d-flex justify-between">
                          <p
                            className="mb-3 subtitle2"
                            dangerouslySetInnerHTML={{ __html: item.info }}
                          />
                          <div className="d-none d-md-flex flex-row flex-align-baseline">
                            <div className="subtitle2">
                              剩餘
                            </div>
                            <h3 className="is-text-accent ml-2 mb-0">
                              {item.valumn.used}
                            </h3>
                            <div className="subtitle2 ml-2">
                              {!!item.valumn.total ? '(' + item.valumn.total + ')' : null}
                            </div>
                          </div>
                        </div>
                        <Ruler
                          percentage={item.percentage}
                        />
                        <div className="mt-2 d-flex d-sm-none justify-end flex-row align-right flex-align-baseline">
                          <div className="subtitle2">
                            剩餘
                            </div>
                          <h3 className="is-text-accent ml-2 mb-0">
                            {item.valumn.used}
                          </h3>
                          <div className="subtitle2 ml-2">
                            {!!item.valumn.total ? '(' + item.valumn.total + ')' : null}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  {
                    this.state.activityOpen ? (
                      <div className='align-center mt-4 mb-md-4 mb-0' onClick={() => this.loadData()}>
                        <div role='button'>
                          <span className="is-text-medium">看更多</span>
                          <i className="icon-plus i-30"></i>
                        </div>
                      </div>
                    ) : null
                  }
                </Grid>
              </Grid>
            </div> : null}
          </div>
        </section>

        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            1. 本服務僅適用4G/4.5G用戶。<br/>
            2. 此功能可查詢門號下所有上網用量的使用情形；「剩餘用量」為上網用量的可使用額度。<br/>
            3. 實際可使用量將依您申辦時所提供的優惠為準。<br/>
            4. 若需查詢未結帳之金額，請使用”未結帳金額/上網用量”功能。
          `}
        />
      </main >
    )
  }
}

export default Query4G;