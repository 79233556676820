export const tabContent = [
  {
    tabs: {
      name: 'addValue',
      list: [
        { name: 'amount', label: '網路定量', meta: '計量型上網' },
        { name: 'daily', label: '網路吃到飽', meta: '計日型上網' },
        { name: 'phonecall', label: '網路/簡訊', meta: '通話金' },
      ],
    },
    tabContent: [
      {
        title: '計量型上網',
        program: [
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-cal-icon-01.png',
            iconAlt: 'program1',
            title: '羽量版',
            list: [
              '加量不加價，升級2GB',
              '流量可自動無限展延'
            ],
            tag: {
              text: '熱銷',
              color: 'red'
            },
            button: [
              {
                title: '立即儲值',
                link: '#',
                target: '_self'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-cal-icon-02.png',
            iconAlt: 'program1',
            title: '輕量版',
            list: [
              '加量不加價，升級4GB',
              '流量可自動無限展延'
            ],
            tag: {
              text: '熱銷',
              color: 'blue'
            },
            button: [
              {
                title: '立即儲值',
                link: '#',
                target: '_self'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-cal-icon-03.png',
            iconAlt: 'program1',
            title: '基本版',
            tag: {
              text: '熱銷',
              color: 'gray'
            },
            list: [
              '加量不加價，升級10GB',
              '流量可自動無限展延'
            ],
            button: [
              {
                title: '立即儲值',
                link: '#',
                target: '_self'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-cal-icon-04.png',
            iconAlt: 'program1',
            title: '重量版',
            list: [
              '加量不加價，升級16GB',
              '流量可自動無限展延'
            ],
            button: [
              {
                title: '立即儲值',
                link: '#',
                target: '_self'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-cal-icon-05.png',
            iconAlt: 'program1',
            title: '超值版',
            list: [
              '超值上網 20GB',
              '流量可自動無限展延'
            ],
            button: [
              {
                title: '立即儲值',
                link: '#'
              },
              {
                title: '了解更多',
                link: '#'
              },
            ]
          },

        ]
      },
      {
        title: '計日型上網',
        program: [
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-day-icon-01.png',
            iconAlt: 'program1',
            title: '短天期上網',
            list: [
              '全速上網不限量',
              '可連續使用24小時'
            ],
            button: [
              {
                title: '立即儲值',
                link: '#',
                target: '_self'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-day-icon-02.png',
            iconAlt: 'program1',
            title: '短天期上網',
            list: [
              '全速上網不限量',
              '可連續使用72小時'
            ],
            button: [
              {
                title: '立即儲值',
                link: '#',
                target: '_self'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-day-icon-03.png',
            iconAlt: 'program1',
            title: '短天期上網',
            tag: {
              text: '熱銷',
              color: 'red'
            },
            list: [
              '全速上網不限量',
              '可連續使用120小時'
            ],
            button: [
              {
                title: '立即儲值',
                link: '#',
                target: '_self'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-day-icon-04.png',
            iconAlt: 'program1',
            title: '長天期上網',
            list: [
              '可連續使用150小時'
            ],
            button: [
              {
                title: '立即儲值',
                link: '#',
                target: '_self'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
        ]
      },
      {
        title: '語音通話',
        program: [
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-voice-icon-01.png',
            iconAlt: 'program1',
            title: '微量款',
            list: [
              '可撥打300分鐘語音',
            ],
            button: [
              {
                title: '立即儲值',
                link: '#'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-voice-icon-02.png',
            iconAlt: 'program1',
            title: '基本款',
            tag: {
              text: '熱銷',
              color: 'red'
            },
            list: [
              '遠傳通路限定贈送上網',
            ],
            button: [
              {
                title: '立即儲值',
                link: '#'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-voice-icon-03.png',
            iconAlt: 'program1',
            title: '重量款',
            list: [
              '遠傳通路限定贈送上網',
            ],
            button: [
              {
                title: '立即儲值',
                link: '#'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
          {
            icon: '/resources/cbu/prepaid/images/prepaid-plan-voice-icon-04.png',
            iconAlt: 'program1',
            title: '暢談款',
            list: [
              '遠傳通路限定贈送上網',
            ],
            button: [
              {
                title: '立即儲值',
                link: '#'
              },
              {
                title: '了解更多',
                link: '#',
                target: '_self'
              },
            ]
          },
        ]
      },
    ],
  },
];

export const tabs = {
  name: '5g-tab',
  icon: false,
  title: true,
  list: [
    {
      name: 'cbu',
      meta: '生活',
      label: '迎向未來生活',
    },
    {
      name: 'ebu',
      meta: '商務',
      label: '產業新進化',
    },
  ],
  default: 0
};

export const fiveGNewscards = [
  {
    public_at: '2020/01/16',
    meta: '最新消息',
    title: '遠傳取得發展5G所需頻譜　全面啟動5G業務',
    link: 'https://www.fetnet.net/corporate/NewsReleases-content.html?id=202001171153&_ga=2.188014888.162253882.1584591174-1256035182.1578309344',
  },
  {
    public_at: '2020/01/01',
    meta: '最新消息',
    title: '遠傳助攻2020跨年盛典，百萬收視喜迎5G元年',
    link: 'https://www.fetnet.net/corporate/NewsReleases-content.html?id=202001061449',
  },
  {
    public_at: '2019/12/31',
    meta: '最新消息',
    title: '遠傳4G、5G 雙網齊飆，打造全台最大5G娛樂場域',
    link: 'https://www.fetnet.net/corporate/NewsReleases-content.html?year=2019&id=202001021520',
  },
];

export const fiveGVideos = [
  {
    videoId: '9FaXQK51iM8',
    title: '遠傳x17 Media',
    content: '全台首發用5G訊號直播實境節目',
    imgSmall: '/resources/ebu/images/video-02.jpg',
    imgLarge: '/resources/ebu/images/video-02.jpg',
    videoLink: 'df-gMDkuC08',
  },
  {
    videoId: '9FaXQK51iM8',
    title: '2019台北跨年晚會',
    content: '幕後紀實影片',
    imgSmall: '/resources/ebu/images/video-03.jpg',
    imgLarge: '/resources/ebu/images/video-03.jpg',
    videoLink: 'https://youtu.be/u9YYwJKQ0aQ',
    alterVideo: null,
  },
  {
    videoId: '9FaXQK51iM8',
    title: '遠傳AI智慧驗布機',
    content: '',
    imgSmall: '/resources/ebu/images/video-01.jpg',
    imgLarge: '/resources/ebu/images/video-01.jpg',
    videoLink: 'https://youtu.be/CwfE29SgSZE',
    alterVideo: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
  }
];