import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import CollapseDiscount from '../../components/collapse/CollapseDiscount';
import Ruler from '../../components/partials/Ruler';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import AppPromotion from '../../components/partials/AppPromotion';
import Link from '../../components/Link';
import Button from '../../components/Button';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import PanelList from '../../components/panelContent/PanelList';
import PanelTab from '../../components/panelContent/PanelTab';
import Panel from '../../components/panel/Panel';
import LinkItem from '../../components/item/LinkItem';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import NewsCard from '../../components/partials/card/NewsCards';
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';

import * as Mock from '../../mock/ProfileMock';

class ProfileLogin extends Component {
  constructor(props) {
    super(props);
    this.collapse = React.createRef();
    this.state = {
      open: false,
      minHeight: 0,
      minHeight: window.innerWidth < 768 ? 240 : 160,
      collapseHeight: 0,
      collapseOpen: false,
      userName: '丹尼爾',
      greeting: '午安！',
      userNumber: '0968***560',
      tillDate: '2021年10月14日'
    }

  }
  componentDidMount() {
    this.setCollapseHeight();
    window.addEventListener('resize', e => {
      this.setCollapseHeight()
    })
  }
  openCollapse = () => {
    this.setCollapseHeight();
    this.setState({ open: true })
    // console.log(this.collapse.current.children[0]);
  }

  setCollapseHeight() {
    this.setState({
      collapseHeight: this.collapse.current.children[0].clientHeight
    });
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='生活圈會員'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '生活圈會員', link: '/' },
          ]}
          color='black'
        />
        {/* Postpaid */}
        <section className="login-recommend pb-0">
          <div className="fui-container">
            <h2 className="mb-2">{this.state.greeting}{this.state.userName}</h2>
            <h4 className="is-text-medium is-text-darkgray50 mb-3">{this.state.userNumber}</h4>
            <div className="paper">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={4} className="">
                  <div className="member-badge is-bg-lightgray70">
                    <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-3.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-3@2x.png'} alt="三星會員" />
                    <div className="d-flex flex-column">
                      <small className="is-text-darkgray70">
                        目前等級：
                      </small>
                      <h2 className='mb-0'>三星會員</h2>
                      <small className="is-text-darkgray50">等級效期至{this.state.tillDate}</small>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                  <small className="is-text-darkgray70">累積可記等遠傳幣：</small>
                  <div className="level-info">
                    <div className="d-inline-flex">
                      <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/fcoin.svg'} alt="遠傳幣" />
                      <h1 className="is-text-accent">
                        3,250
                      </h1>
                    </div>
                    <div className="body float-right d-flex is-text-medium">
                      距離四星會員差<img className='mx-1' style={{ width: 24 }} src={process.env.PUBLIC_URL + '/resources/common/member-icon/fcoin.svg'} alt="遠傳幣" />1,300
                    </div>
                  </div>
                  <Ruler
                    className="mb-1"
                    percentage={60}
                    showPercentage={false}
                    size="small"
                  />
                  <small className="is-text-darkgray70">
                    累積區間：2020/01/01-2020/06/01
                  </small>
                </Grid>
              </Grid>
            </div>
          </div>
        </section>

        <section className='fui-member-info ribbon-bg pt-0'>
          <div className="fui-container">
            <CollapsePaper className="mb-2" title="遠傳心生活會員回饋計畫">
              <div className='subtitle'>
                遠傳心生活會員回饋計畫是遠傳推出的全新會員制度，目的是讓您能在遠傳電信以及我們提供各項服務的過程中，享受實質的回饋與尊榮感。當您持您所使用的門號進入遠傳心生活APP即成為遠傳心生活會員，便開始享有會員基本回饋(詳見下表說明)，您於基本回饋中累積的遠傳幣，或於遠傳電信年資累計達到指定門檻時即可於次月升等，並開始享有該等級的回饋，關於會員分級制度可查看【遠傳心生活+會員機制與各等級條件說明】。
              </div>
            </CollapsePaper>
            <CollapsePaper className="mb-2" title="遠傳幣說明">
              <div className='pt-2'>
                <PanelList
                  prefix='bulleted'
                  list={[
                    { text: ' 遠傳幣屬性：計等與不計等。計等遠傳幣可由生活+會員基本回饋中獲得，遠傳幣交易明細會以「首登禮/升等禮/基本回饋/基本回饋(含生日禮)」註記。由其他行銷活動獲得的遠傳幣則為不計等遠傳幣，交易明細會以「活動回饋」註記。' },
                    { text: ' 遠傳幣效期：遠傳幣效期可分為1天、3天、7天、15天、30天、1年、3年共7類。你可從遠傳幣交易明細中的「到期日」確認該幣的到期時間。' },
                    { text: '遠傳幣兌換：遠傳幣價值不分屬性或效期均為1遠傳幣=1元，您可用於有提供遠傳幣折抵的商品或行銷活動上，直接折抵您有效的遠傳幣，折抵的遠傳幣記錄可從遠傳幣交易明細中確認。' },
                  ]}
                />
              </div>
            </CollapsePaper>
            <CollapsePaper title="遠傳心生活會員機制">
              <div className='pt-2'>
                <div className="subtitle">
                  遠傳心生活會員機制與各等級條件說明
                </div>
                <PanelList
                  prefix='bulleted'
                  list={[
                    { text: '會員等級：遠傳心生活會員共分為5個等級，各等級名稱與圖示如下：' }
                  ]}
                />
              </div>
              <div className="member-levels">
                <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-1.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-1@2x.png'} width='120px' alt="level-1" />
                <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-2.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-2@2x.png'} width='120px' alt="level-2" />
                <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-3.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-3@2x.png'} width='120px' alt="level-3" />
                <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-4.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-4@2x.png'} width='120px' alt="level-4" />
                <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/vip.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/vip@2x.png'} width='120px' alt="level-vip" />
              </div>
              <PanelList
                prefix='bulleted'
                list={[
                  { text: '評等機制：會員每月評等，15日給予新等級。' },
                  { text: '會員升等：該月評等結果優於上月等級時，則於當月15日進行升等，等級效期以新等級重新計算：若新等級等同上月等級或低於上月等級，則維持原有等級與原效期。' }
                ]}
              />
            </CollapsePaper>
            <h2 className="mt-5">遠傳心生活會員各等級條件說明及回饋項目</h2>

            <div className='paper mb-0'>
              <PanelTab tabs={{
                name: 'member-benefits-tab',
                list: [
                  { label: '一星' },
                  { label: '二星' },
                  { label: '三星' },
                  { label: '四星' },
                  { label: 'VIP' },
                ]
              }}>
                <div className='member-benefits-content'>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/star.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>如何成為一星會員</h5>
                      <div className='is-text-darkgray50'>
                        申辦遠傳電信之用戶或於遠傳心生活圈/遠傳官網註冊，即可免費成為1星會員。
                      </div>
                      <div className='is-text-darkgray50'>
                        等級效期：一年
                      </div>
                    </div>
                  </div>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/fcoin-outline.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>回饋項目</h5>
                      <div className='is-text-darkgray50'>
                        遠傳電信帳單-回饋 0.3%(出帳日結算，30日入帳)<br />
                        遠傳代收代付帳單-回饋 0.1%(出帳日結算，30日入帳)<br />
                        遠傳預付卡開卡/語音/數據費用-回饋 0.3%(每月月結，次月1日入帳)<br />
                        指定合作廠商基本消費-回饋 0.3%(交易日計算，30日內入帳)<br />
                        首登禮-10幣(首登後次日入帳)<br />
                        生日禮-生日當月消費2倍回饋(依各回饋項目標準)
                      </div>
                    </div>
                  </div>
                </div>
                <div className='member-benefits-content'>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/star.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>如何成為二星會員</h5>
                      <div className='is-text-darkgray50'>
                        使用遠傳電信年資3年以上之用戶或評等日前6個月內於遠傳心生活累積100可計等遠傳幣，即可成為2星會員。
                      </div>
                      <div className='is-text-darkgray50'>
                        等級效期：一年
                      </div>
                    </div>
                  </div>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/fcoin-outline.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>回饋項目</h5>
                      <div className='is-text-darkgray50'>
                        遠傳電信帳單-回饋 0.5%(出帳日結算，30日入帳)<br />
                        遠傳代收代付帳單-回饋 0.1%(出帳日結算，30日入帳)<br />
                        遠傳預付卡開卡/語音/數據費用-回饋 0.5%(每月月結，次月1日入帳)<br />
                        指定合作廠商基本消費-回饋 0.5%(交易日計算，30日內入帳)<br />
                        首登禮-10幣(首登後次日入帳)<br />
                        生日禮-生日當月消費2倍回饋(依各回饋項目標準)<br />
                        升等禮-10幣(升等後次日入帳)
                      </div>
                    </div>
                  </div>
                </div>
                <div className='member-benefits-content'>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/star.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>如何成為三星會員</h5>
                      <div className='is-text-darkgray50'>
                        使用遠傳電信年資5年以上之用戶或評等日前6個月內於遠傳心生活累積300可計等遠傳幣，即可成為3星會員。
                      </div>
                      <div className='is-text-darkgray50'>
                        等級效期：一年
                      </div>
                    </div>
                  </div>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/fcoin-outline.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>回饋項目</h5>
                      <div className='is-text-darkgray50'>
                        遠傳電信帳單-回饋 0.7%(出帳日結算，30日入帳)<br />
                      遠傳代收代付帳單-回饋 0.1%(出帳日結算，30日入帳)<br />
                      遠傳代收代付帳單-回饋 0.1%(出帳日結算，30日入帳)遠傳預付卡開卡/語音/數據費用-回饋 0.7%(每月月結，次月1日入帳)<br />
                      指定合作廠商基本消費-回饋 0.7%(交易日計算，30日內入帳)<br />
                      首登禮-10幣(首登後次日入帳)<br />
                      生日禮-生日當月消費2倍回饋(依各回饋項目標準)<br />
                      升等禮-20幣(升等後次日入帳)
                      </div>
                    </div>
                  </div>
                </div>
                <div className='member-benefits-content'>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/star.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>如何成為四星會員</h5>
                      <div className='is-text-darkgray50'>
                        使用遠傳電信年資10年以上之用戶或評等日前6個月內於遠傳心生活累積1,500可計等遠傳幣，即可成為4星會員。
                      </div>
                      <div className='is-text-darkgray50'>
                        等級效期：一年
                      </div>
                    </div>
                  </div>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/fcoin-outline.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>回饋項目</h5>
                      <div className='is-text-darkgray50'>
                        遠傳電信帳單-回饋 1.0%(出帳日結算，30日入帳)<br />
                      遠傳代收代付帳單-回饋 0.1%(出帳日結算，30日入帳)<br />
                      遠傳預付卡開卡/語音/數據費用-回饋 1.0%(每月月結，次月1日入帳)<br />
                      指定合作廠商基本消費-回饋 1.0%(交易日計算，30日內入帳)<br />
                      首登禮-10幣(首登後次日入帳)<br />
                      生日禮-生日當月消費2倍回饋(依各回饋項目標準)<br />
                      升等禮-30幣(升等後次日入帳)
                      </div>
                    </div>
                  </div>
                </div>
                <div className='member-benefits-content'>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/star.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>如何成為VIP會員</h5>
                      <div className='is-text-darkgray50'>
                        遠傳電信年資達1年且同一證號帳單金額達2,500元以上，或遠傳電信年資達10年且同一證號帳單金額達1,500元以上。
                      </div>
                      <div className='is-text-darkgray50'>
                        等級效期：依遠傳電信給訂之VIP效期為主。<br />
                      特殊權益：
                      <ul className="green-dot-list">
                          <li>24小時資深客服優先接聽服</li>
                          <li>一天內回覆網路通訊品質反應</li>
                          <li>手機免費到府收件送修服務</li>
                          <li>手機零件維修更換享優惠價</li>
                          <li>手機維修期間提供備用手機</li>
                          <li>SIM卡遺失免費補卡乙次</li>
                          <li>續約最高折3,000元</li>
                          <li>現場特殊活動優先報名</li>
                          <li>豐富多樣化精選活動</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/fcoin-outline.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>回饋項目</h5>
                      <div className='is-text-darkgray50'>
                        遠傳電信帳單-回饋 1.0%(出帳日結算，30日入帳)<br />
                      遠傳代收代付帳單-回饋 0.1%(出帳日結算，30日入帳)<br />
                      遠傳預付卡開卡/語音/數據費用-回饋 1.0%(每月月結，次月1日入帳)<br />
                      指定合作廠商基本消費-回饋 1.0%(交易日計算，30日內入帳)<br />
                      首登禮-10幣(首登後次日入帳)<br />
                      生日禮-生日當月消費2倍回饋(依各回饋項目標準)<br />
                      升等禮-50幣(升等後次日入帳)<br />
                      電信續約優惠最高5,000
                      </div>
                    </div>
                  </div>
                </div>
              </PanelTab>
            </div>
            <div className="collapse-info">
              <div className="main">
                <div className="fui-collapse is-bg-lightgray70" ref={this.collapse} style={{
                  height: `${this.state.open ? this.state.collapseHeight : 0}px`
                }}>
                  <div className="fui-collapse-body">
                    <div className="d-block">
                      <ul className="green-dot-list">
                        <li>遠傳電信帳單與遠傳代收代付帳單：每期帳單出帳後，以會員等級計算帳單金額的總回饋價值後，扣除用戶既有的Happy Go回饋價值，與遠傳幣不回饋項目(含代繳預付卡及違約金)，始為實際回饋遠傳幣。<br />
舉例說明：您為遠傳心生活4星會員，並同時享有Happy Go電信帳單回饋。您本月月租帳單金額為1300元，其中包含了300元的代繳預付卡金額，故您所得到的遠傳幣計算方式為：<br />
總回饋價值 - HAPPY GO回饋價值 -遠傳幣不回饋項目價值=(1300元*1%)-(1000元/100*0.25)-(300元*1%)=13-2-3=8遠傳幣</li>
                        <li>預付卡費用採每月月底結算方式，於次月1日回饋至會員帳戶。</li>
                        <li>指定合作廠商包含friDay購物、friDay影音、friDay音樂、friDay相片書、遠傳數位萬事通、遠傳X潔衣家洗衣服務。如有新增/刪減將以公告通知。</li>
                        <li>首登禮：以用戶首次登入遠傳心生活APP或遠傳官網日期認定。</li>
                        <li>生日禮：會員於基本回饋一覽表中1~4項消費計算之，回饋上限為2000遠傳幣，非指定項目中之消費回饋則不認定。</li>
                        <li>升等禮：於用戶評等後如等級上升，則給予對應到達等級的升等禮，跨越的等級升等禮不含概。舉例：您原為遠傳心生活1星會員，於本月升等為4星會員，將得到30遠傳幣升等獎勵。<br />
除了上述基本回饋外，遠傳與有意加入遠傳心生活的各合作廠商均會不定時舉辦會員專屬回饋活動，並於各項活動中回饋遠傳幣(不可計等)，提供您更多的會員福利與更完整的生活服務！ </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="extra">
                {
                  this.state.open ? (
                    <div className="expand-btn" role="button" onClick={e => this.setState({ open: false })}>
                      <span>回饋項目說明</span>
                      <i className="icon-minus"></i>
                    </div>
                  ) : (
                      <div className="expand-btn" onClick={this.openCollapse}>
                        <span>回饋項目說明</span>
                        <i className="icon-plus"></i>
                      </div>
                    )
                }
              </div>
            </div >
          </div>
        </section>

        <SectionCarousel1 {...Mock.promotionCard} />
        <AppPromotion
          bgImage='/resources/cbu/help-center/images/bg-all.png'
          title="加入遠傳生活圈，滿載好康"
          subtitle="立即下載App，享好康回饋！"
          description={`
            <p>
              以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
              <br />
              還有許多用戶獨享驚喜優惠
            </p>`
          }
          icon='/resources/cbu/help-center/images/img-logo-fet.png'
          android={{
            qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
            icon: '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 Android 7 以上版本'
          }}
          ios={{
            qrCode: '/resources/cbu/help-center/images/qr-ios.png',
            icon: '/resources/cbu/help-center/images/app-store.png',
            link: '/',
            title: '支援 iOS 10 以上版本'
          }}
        />
        <SectionFaqTab {...Mock.faqTab2} />
      </main>
    );
  }
}

export default ProfileLogin;