export const meta = {
  title: '遠傳電信::EBU',
  keywords: '遠傳電信, EBU',
  description: '頭家輕鬆配幫您策劃開店的大小事！',
};

export const productMapData = {
  title: '找更多用戶服務',
  data: [
    {
      title: '帳單與繳費',
      list: [
        {
          text: '帳單查詢',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '4G即時用量查詢',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '申請電子帳單',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '交易/繳費/發票記錄查詢',
          icon: 'chevron-right',
          link: '#',
        },
      ],
    },
    {
      title: '繳費服務',
      list: [
        {
          text: '想繳費',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '繳費方式說明',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '自動扣繳申請',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '線上列印「帳單繳費聯」',
          icon: 'chevron-right',
          link: '#',
        },
      ],
    },
    {
      title: '合約費率',
      list: [
        {
          text: '目前費率查詢',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '費率異動/變更',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '推薦費率查詢',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '合約資訊查詢',
          icon: 'chevron-right',
          link: '#',
        },
      ],
    },
    {
      title: '漫遊與門號服務',
      list: [
        {
          text: '漫遊上網包',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '漫遊設定',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '漫遊上網用量查詢',
          icon: 'chevron-right',
          link: '#',
        },
        {
          text: '掛失服務',
          icon: 'chevron-right',
          link: '#',
        },
      ],
    }
  ],
};

export const demoShops = {
  title: '選擇我們的頭家',
  moreLink: '#',
  list: [
    {
      link: '#',
      logo: '/resources/ebu/images/ebu-testimonial-logo/testimonial-logo-micro-01-4x3.png',
      logoWhite: '/resources/ebu/images/ebu-testimonial-logo/testimonial-logo-micro-01-white-4x3.png',
      name: 'The 41 Bistro - 肆拾壹號英式小酒館',
      meta: '',
      content:
        'Smart Wifi 的功能很多樣且彈性，可以自由選擇、設定。對於沒有多餘行銷人力的小店家來說很方便，取代以往自己設計活動耗費的時間。',
    },
    {
      link: '#',
      logo: '/resources/ebu/images/ebu-testimonial-logo/testimonial-logo-micro-02-4x3.png',
      logoWhite: '/resources/ebu/images/ebu-testimonial-logo/testimonial-logo-micro-02-white-4x3.png',
      name: '花草慢時光',
      meta: '',
      content:
        'Smart Wifi 的功能很多樣且彈性，可以自由選擇、設定。對於沒有多餘行銷人力的小店家來說很方便，取代以往自己設計活動耗費的時間。',
    },
    {
      link: '#',
      logo: '/resources/ebu/images/ebu-testimonial-logo/testimonial-logo-micro-03-4x3.png',
      logoWhite: '/resources/ebu/images/ebu-testimonial-logo/testimonial-logo-micro-03-white-4x3.png',
      name: 'Korea salon',
      meta: '',
      content:
        'Smart Wifi 的功能很多樣且彈性，可以自由選擇、設定。對於沒有多餘行銷人力的小店家來說很方便，取代以往自己設計活動耗費的時間。',
    },
    {
      link: '#',
      logo: '/resources/ebu/images/ebu-testimonial-logo/testimonial-logo-micro-04-4x3.png',
      logoWhite: '/resources/ebu/images/ebu-testimonial-logo/testimonial-logo-micro-04-white-4x3.png',
      name: '楓露',
      meta: '',
      content:
        'Smart Wifi 的功能很多樣且彈性，可以自由選擇、設定。對於沒有多餘行銷人力的小店家來說很方便，取代以往自己設計活動耗費的時間。',
    },
  ],
};

export const shopPromoData = {
  image: {
    md: '/resources/ebu/images/ebu-micro-ad-02.jpg',
    sm: '/resources/ebu/images/ebu-micro-ad-02-sm.jpg',
  },
  title: '2020 開店進化論 業界大師不藏私傳授經營實戰心法',
  action: {
    text: '免費報名',
    link: '#',
  },
};

export const promotionArticle = {
  title: '開業技法新知',
  moreLink: '#',
  tabs: {
    name: 'tab-micro',
    list: [
      { name: 'tab-1', label: '全部' },
      { name: 'tab-2', label: '餐飲業' },
      { name: 'tab-3', label: '零售業' },
      { name: 'tab-4', label: '美容業' },
    ],
  },
  data: [
    [
      {
        meta: '高效經營',
        image: '/resources/ebu/images/ebu-micro-article-banner.jpg',
        title: '餐廳經營數位化！怎麼從線上增加來客數？用網路行銷增加客群！',
        description:
          '在數位工具盛行的時代，每間餐廳都會開始經營網路社群，但因為不懂如何做行銷操作，常常在網路行銷上花上很多冤枉錢，開了粉絲團...',
        link: '#',
      },
      {
        meta: '高效經營',
        title: '開餐廳必備｜提高淨利小心機-翻桌率、出餐效率提升技巧',
        description: '',
        link: '#',
      },
      {
        meta: '開店引路',
        title: '沒經驗想開餐廳？開店新手要注意選店、規模、成本、定位，準備好不怕失敗！',
        description: '',
        link: '#',
      },
    ],
    [
      {
        meta: '高效經營',
        image: '/resources/ebu/images/ebu-micro-article-banner.png',
        title: '餐廳經營數位化！怎麼從線上增加來客數？用網路行銷增加客群！',
        description:
          '在數位工具盛行的時代，每間餐廳都會開始經營網路社群，但因為不懂如何做行銷操作，常常在網路行銷上花上很多冤枉錢，開了粉絲團...',
        link: '#',
      },
      {
        meta: '高效經營',
        title: '開餐廳必備｜提高淨利小心機-翻桌率、出餐效率提升技巧',
        description: '',
        link: '#',
      },
      {
        meta: '開店引路',
        title: '沒經驗想開餐廳？開店新手要注意選店、規模、成本、定位，準備好不怕失敗！',
        description: '',
        link: '#',
      },
    ],
    [
      {
        meta: '高效經營',
        image: '/resources/ebu/images/ebu-micro-article-banner.png',
        title: '餐廳經營數位化！怎麼從線上增加來客數？用網路行銷增加客群！',
        description:
          '在數位工具盛行的時代，每間餐廳都會開始經營網路社群，但因為不懂如何做行銷操作，常常在網路行銷上花上很多冤枉錢，開了粉絲團...',
        link: '#',
      },
      {
        meta: '高效經營',
        title: '開餐廳必備｜提高淨利小心機-翻桌率、出餐效率提升技巧',
        description: '',
        link: '#',
      },
      {
        meta: '開店引路',
        title: '沒經驗想開餐廳？開店新手要注意選店、規模、成本、定位，準備好不怕失敗！',
        description: '',
        link: '#',
      },
    ],
  ],
  ad: {
    image: {
      md: '/resources/ebu/images/ebu-micro-ad-01.jpg',
      sm: '/resources/ebu/images/ebu-micro-ad-01-sm.jpg',
    },
    title: '低成本自動蒐集顧客數據，攬客、行銷、會員管理，不再是難事！',
    action: [
      {
        text: '了解集客行銷',
        link: '#',
      },
    ],
  },
};

export const promotionCard = {
  title: '你的專屬優惠',
  cards: [
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
      title: '本月影片免費看!',
      description: '話題電影、日劇、韓劇等等應有盡有',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
      title: '中午不知道吃什麼？',
      description: 'Uber Eats 送遠傳新客優惠金200元',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
      title: '連假打算出國？',
      description: '遠遊卡吃到飽上網不再煩惱用量 ',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
      title: '本月影片免費看!',
      description: '話題電影、日劇、韓劇等等應有盡有',
      link: '#',
    },
  ]
};

export const newsCard = [
  {
    public_at: '2019/10/4',
    meta: '最新消息',
    title: '遠傳5G再秀創舉 攜手北市府打造全臺雙第一 率先啟動...',
    link: '/ebu/news/newsContent',
  },
  {
    public_at: '2019/10/4',
    meta: '最新消息',
    title: '遠傳攜手經濟部水利署 發表全國首例「線上崩塌與濁...',
    link: '/ebu/news/newsContent2',
  },
  {
    public_at: '2019/10/4',
    meta: '服務公告',
    title: '.tw/.台灣網域名稱註冊服務系統維護通知',
    link: '/ebu/news/newsContent2',
  },
];

export const serviceCard = {
  title: '線上申辦',
  cards: [
    {
      title: '企業行動優惠',
      link: '#',
    },
    {
      title: '文件下載',
      link: '#',
    },
    {
      title: '大寬頻企業光纖',
      link: '#',
    },
    {
      title: ' 域名服務',
      link: '#',
    },
  ],
};

export const serviceTags = {
  title: '你可能會有興趣的服務',
  tags: [
    {
      name: '咖啡廳 WiFi',
      link: '#',
    },
    {
      name: '好用 POS 機',
      link: '#',
    },
    {
      name: '經營常客關係',
      link: '#',
    },
    {
      name: '開店小額貸款',
      link: '#',
    },
    {
      name: '進銷存',
      link: '#',
    },
    {
      name: '帶動新客人',
      link: '#',
    },
    {
      name: '精準行銷',
      link: '#',
    },
    {
      name: '顧客資料建檔',
      link: '#',
    },
    {
      name: '一站式服務',
      link: '#',
    },
    {
      name: '展店',
      link: '#',
    },
    {
      name: '店面公播音樂',
      link: '#',
    },
    {
      name: '跨裝置看數據',
      link: '#',
    },
    {
      name: '選店址',
      link: '#',
    },
    {
      name: '安全顧店',
      link: '#',
    },
    {
      name: '記帳',
      link: '#',
    },
  ],
};
