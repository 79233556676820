import React from 'react';
import Button from '../Button';
import DropdownTab from '../tab/DropdownTab';

import PropTypes from 'prop-types';

class NavContentTab1 extends React.Component {
  constructor(props) {
    super(props);
    this.elem = React.createRef();
    this.state = {
      defaultTop: 0,
      scrollPos: 0,
      default: this.props.default || 0
    };

  }

  componentDidMount() {
    this.setState({
      defaultTop: this.elem.current.offsetTop,
    });

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', e => {
        if (!this.elem.current) return;
        this.setState({
          defaultTop: document.getElementsByTagName('header')[0].clientHeight + document.getElementsByClassName('fui-banner')[0] ? document.getElementsByClassName('fui-banner')[0].clientHeight : 0,
        });
      });

      window.addEventListener('scroll', e => {
        if (!this.elem.current) return;
        let scrollY = window.scrollY || document.documentElement.scrollTop;
        let dis = this.state.defaultTop - scrollY;
        let hasAnchor = document.getElementsByClassName('fui-nav-anchor').length > 0;
        let headerDom = Array.from(document.getElementsByTagName('header'));
        let header = headerDom[0].clientHeight;

        if (!hasAnchor && scrollY > this.state.scrollPos && scrollY > this.state.defaultTop) {
          headerDom.map(dom => {
            dom.style.transform = `translateY(${dis < -header ? -header : dis}px)`;
          })

          if (scrollY > this.state.defaultTop + header) {
            this.elem.current.classList.add('is-fixed');
          }
        }

        if (!hasAnchor && scrollY < this.state.scrollPos && scrollY < this.state.defaultTop + header) {
          this.elem.current.classList.remove('is-fixed');
          headerDom.map(dom => {
            dom.style.transform = `translateY(${dis > 0 ? 0 : dis}px)`;
          })
        }

        this.setState({ scrollPos: scrollY });
      });
    }
  }

  handleChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <div className='fui-nav-tab is-content-1' ref={this.elem}>
        <div className='fui-container'>
          <div className='content'>
            <div className='subtitle'>{this.props.pageTitle}</div>
            <DropdownTab tabs={this.props.tabs} default={this.props.default} onChange={e => this.handleChange(e)} />
          </div>
          <div className='extra'>
            {this.props.button ? (
              <Button {...this.props.button} size='small' btnStyle='secondary'>
                {this.props.button.text}
              </Button>
            ) : (
                ''
              )}
          </div>
        </div>
      </div>
    );
  }
}

NavContentTab1.propTypes = {
  tabs: PropTypes.shape({
    default: PropTypes.number,
    name: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        focusIcon: PropTypes.string,
        label: PropTypes.string.isRequired,
        link: PropTypes.string
      })
    ),
  }),
  onChange: PropTypes.func,
};

export default NavContentTab1;
