import React from 'react';
import { Grid } from '@material-ui/core';
import Header from '../../components/partials/header/Default'
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Button from '../../components/Button';
import TabPane from '../../components/tab/TabPane';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Paginate from '../../components/paginate';
import Dropdown from '../../components/Dropdown';
import ReceiptCollapse from '../../components/collapse/ReceiptCollapse';
import Modal from 'react-modal';
import { InvoiceTable } from '../../mock/InvoiceTable';

const receiptList = [
  {
    date: '2020/03/28',
    name: '遠傳FETnet月租帳單儲值',
    amount: 500
  },
  {
    date: '2020/03/28',
    name: '遠傳FETnet信用卡儲值',
    amount: 500
  }
]

const receiptList4 = [
  {
    date: '2020/03/28',
    name: '超4代易付飆網2.2GB組合(效期 60天)',
    amount: 300
  },
  {
    date: '2020/03/28',
    name: '超4代易付1.9GB_60天_贈送(2.2GB組合)',
    amount: 0
  }
]

const receiptList2 = [
  {
    date: '2020/03/28',
    name: '台北市公有停車場費(PTP-20200328000000443187)',
    meta: {
      type: '計次型',
      number: 'CLP00063200328123778',
      hasReceipt: false,
    },
    amount: 90,
    detail: [
      { text: '日期：2020/03/28' },
      { text: '商家名稱：台北市停車管理工程處' },
      { text: '商家電話：02-2726-9600' },
      { text: '金額：90元' },
      { text: '付款方式：電信帳單付款' },
      { text: '交易狀態：成功' },
    ]
  },
  {
    date: '2020/03/28',
    name: '台北市公有停車場費(PTP-20200328000000443187)',
    meta: {
      type: '計次型',
      number: 'CLP00063200328123778',
      hasReceipt: false,
    },
    amount: 30,
    detail: [
      { text: '日期：2020/03/28' },
      { text: '商家名稱：台北市停車管理工程處' },
      { text: '商家電話：02-2726-9600' },
      { text: '金額：90元' },
      { text: '付款方式：電信帳單付款' },
      { text: '交易狀態：成功' },
    ]
  }
]
const receiptList3 = [
  {
    date: '2020/03/28',
    name: '發票號碼：WW4762****',
    meta: {
      deviceNo: '無',
      expireAt: '--',
      type: '索取紙本發票',
      debitNote: '無',
    },
    amount: 899,
    detail: [
      { text: '發票號碼：ZL05207262' },
      { text: '交易日期：2020/03/28' },
      { text: '發票月份：2020/03' },
      { text: '客戶帳號：02-7896546' },
      { text: '發票金額：899元' },
      { text: '折讓單：無' },
      { text: '發票狀態：開立' },
      { text: '發票類型：電信發票' },
      { text: '申請/兌獎期限： -' },
      { text: `載具編號：<br/>載具類別:EJ0115<br/>年期別:10903<br/>載具流水號:BB42045798<br/>檢查碼:9700` },
    ]
  },
  {
    date: '2020/03/28',
    name: '發票號碼：WW4762****',
    meta: {
      deviceNo: '無',
      expireAt: '--',
      type: '捐贈發票',
      debitNote: '無',
    },
    amount: 899,
    detail: [
      { text: '發票號碼：ZL05207262' },
      { text: '交易日期：2020/03/28' },
      { text: '發票月份：2020/03' },
      { text: '客戶帳號：02-7896546' },
      { text: '發票金額：899元' },
      { text: '折讓單：無' },
      { text: '發票狀態：開立' },
      { text: '發票類型：電信發票' },
      { text: '申請/兌獎期限： -' },
      { text: `載具編號：<br/>載具類別:EJ0115<br/>年期別:10903<br/>載具流水號:BB42045798<br/>檢查碼:9700` },
    ]
  },
  {
    date: '2020/03/28',
    name: '發票號碼：WW4762****',
    meta: {
      deviceNo: '無',
      expireAt: '--',
      type: '捐贈發票',
      debitNote: '無',
    },
    amount: 1399,
    detail: [
      { text: '發票號碼：ZL05207262' },
      { text: '交易日期：2020/03/28' },
      { text: '發票月份：2020/03' },
      { text: '客戶帳號：02-7896546' },
      { text: '發票金額：899元' },
      { text: '折讓單：無' },
      { text: '發票狀態：開立' },
      { text: '發票類型：電信發票' },
      { text: '申請/兌獎期限： -' },
      { text: `載具編號：<br/>載具類別:EJ0115<br/>年期別:10903<br/>載具流水號:BB42045798<br/>檢查碼:9700` },
    ]
  }
]

class getQueryRecordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // currentTab: 0,
      currentTab: 2,
      page: 1,
      modalOpen: false,
      addressModalOpen: false,
      currentSelect: 1,
      currentAddr: '',
      currentCity: {
        text: '縣市',
        value: '縣市'
      },
      defaultAddr: '基隆市仁愛區仁一路197號',
      currentDist: {
        text: '鄉鎮地區',
        value: '鄉鎮地區'
      },
      cityList: [
        {
          text: '基隆市',
          value: '基隆市'
        },
        {
          text: '臺北市',
          value: '臺北市'
        },
        {
          text: '新北市',
          value: '新北市'
        },
        {
          text: '宜蘭縣',
          value: '宜蘭縣'
        },
        {
          text: '新竹市',
          value: '新竹市'
        },
        {
          text: '新竹縣',
          value: '新竹縣'
        },
        {
          text: '桃園市',
          value: '桃園市'
        },
        {
          text: '苗栗縣',
          value: '苗栗縣'
        },
        {
          text: '臺中市',
          value: '臺中市'
        },
        {
          text: '彰化縣',
          value: '彰化縣'
        },
        {
          text: '南投縣',
          value: '南投縣'
        },
        {
          text: '嘉義市',
          value: '嘉義市'
        },
        {
          text: '嘉義縣',
          value: '嘉義縣'
        },
        {
          text: '雲林縣',
          value: '雲林縣'
        },
        {
          text: '臺南市',
          value: '臺南市'
        },
        {
          text: '高雄市',
          value: '高雄市'
        },
        {
          text: '屏東縣',
          value: '屏東縣'
        },
        {
          text: '臺東縣',
          value: '臺東縣'
        },
        {
          text: '花蓮縣',
          value: '花蓮縣'
        },
        {
          text: '金門縣',
          value: '金門縣'
        },
        {
          text: '連江縣',
          value: '連江縣'
        },
        {
          text: '澎湖縣',
          value: '澎湖縣'
        },
      ],
      distList: [
        { value: "中正區", text: "中正區" },
        { value: "大同區", text: "大同區" },
        { value: "中山區", text: "中山區" },
        { value: "松山區", text: "松山區" },
        { value: "大安區", text: "大安區" },
        { value: "萬華區", text: "萬華區" },
        { value: "信義區", text: "信義區" },
        { value: "士林區", text: "士林區" },
        { value: "北投區", text: "北投區" },
        { value: "內湖區", text: "內湖區" },
        { value: "南港區", text: "南港區" },
        { value: "文山區", text: "文山區" },
      ]
    }
  }

  tabChange = (i) => {
    this.setState({
      currentTab: i
    })

    this.forceUpdate();
  }

  filterChange = () => {
    console.log()
  }

  openInvoice = () => {
    this.setState({
      modalOpen: true
    })
  }

  pageChange = () => {

  }
  handleChange = item => {
    console.log(`item`, item);
    this.setState({
      currentSelect: item
    })
    // this.forceUpdate()
  };
  selectCity = (item) => {
    this.setState({
      currentCity: item
    })
  }
  selectDist = (item) => {
    this.setState({
      currentDist: item
    })
  }
  addressKeyIn = (e) => {
    // console.log(e.currentTarget.value);
    this.setState({
      currentAddr: e.currentTarget.value
    })
  }
  getValidation = () => {
    if (this.state.currentSelect == 1) {
      return true
    } else {
      if (!!this.state.currentCity && !!this.state.currentDist && !!this.state.currentAddr) {
        return true
      } else {
        return false
      }
    }
  }
  submmit = () => {
    if (this.state.currentSelect == 1) {
      console.log('submit data:' + this.state.defaultAddr);
    } else {
      console.log('submit data:' + this.state.currentCity + this.state.currentDist + this.state.currentAddr);
    }
    this.setState({
      addressModalOpen: false
    })
    this.forceUpdate()
  }
  openModal = () => {
    // console.log('modal open');
    this.setState({
      addressModalOpen: true
    })
  }
  render() {
    const { currentTab } = this.state;
    return (
      <main>

        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="儲值/交易/發票查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <NavContentTab1
          pageTitle='帳戶設定'
          tabs={{
            name: 'page-tab',
            list: [
              { label: '儲值記錄查詢' },
              { label: '代收/網路門市查詢' },
              { label: '發票查詢' },
              { label: '預付卡加購記錄' },
            ]
          }}
          button={{
            text: '回個人專區',
            link: '/',
          }}
          onChange={this.tabChange}
        />
        <TabPane active={currentTab === 0}>
          <section>
            <div className="fui-container">
              <h4 className="is-text-darkgray50">提供您查詢易付卡門號使用線上儲值記錄</h4>
              <div className="paper">
                <Grid container>
                  <Grid item xs={6}>
                    <h4>近六個月記錄</h4>
                  </Grid>
                  <Grid item xs={6} className="align-right">
                    <Button btnStyle='secondary' size="small">下載空白折讓單</Button>
                  </Grid>
                </Grid>
                <div className="receipt-list">
                  {
                    receiptList.map((receipt, i) => (
                      <div className='receipt-item' key={`receipt-list-${i}`}>
                        <h4 className='date'>{receipt.date}</h4>
                        <div className="main">
                          <h5>{receipt.name}</h5>
                          <div className="price">
                            金額 <span className="amount">{receipt.amount}元</span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>

                <Paginate
                  initialPage={this.state.page - 1}
                  pageCount={20}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  containerClassName='fui-pagination'
                  previousLabel={<i className='icon-chevron-left'></i>}
                  nextLabel={<i className='icon-chevron-right'></i>}
                  firstLabel={<i className='icon-first'></i>}
                  lastLabel={<i className='icon-last'></i>}
                  onPageChange={this.pageChange}
                />

                {/* 無記錄 */}
                <div className="receipt-empty my-3">
                  <div className="subtitle2 is-text-darkgray50">查無儲值記錄，謝謝。</div>
                </div>
              </div>
            </div>
          </section>
        </TabPane>
        <TabPane active={currentTab === 1}>
          <section>
            <div className="fui-container">
              <h4 className="is-text-darkgray50">提供您查詢小額付費交易記錄及網路門市交易記錄</h4>
              <div className="paper">
                <div className="receipt-header">
                  <div className="main">
                    <Grid container>
                      <Grid item xs={6}>
                        <h4>近一年記錄</h4>
                      </Grid>
                      <Grid item xs={6} className="align-right">
                        <Button btnStyle='secondary' size="small">下載空白折讓單</Button>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="extra">
                    <Dropdown list={[
                      { text: '2020/10', value: '202010' },
                      { text: '2020/09', value: '202009' },
                      { text: '2020/08', value: '202008' },
                    ]}
                      className="is-button"
                      arrow={true}
                      label="2020/08"
                      selected="2020/08"
                      value="202008" />
                  </div>
                </div>
                <div className="receipt-list">
                  {
                    receiptList2.map((receipt, i) => (
                      <ReceiptCollapse {...receipt} openModal={this.openModal} key={`receipt-collapse-${i}`} layout='record' />
                    ))
                  }
                </div>

                <Paginate
                  initialPage={this.state.page - 1}
                  pageCount={20}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  containerClassName='fui-pagination'
                  previousLabel={<i className='icon-chevron-left'></i>}
                  nextLabel={<i className='icon-chevron-right'></i>}
                  firstLabel={<i className='icon-first'></i>}
                  lastLabel={<i className='icon-last'></i>}
                  onPageChange={this.pageChange}
                />

                {/* 無記錄 */}
                <div className="receipt-empty my-3">
                  <div className="subtitle2 is-text-darkgray50">查無交易記錄，謝謝。</div>
                </div>
              </div>
            </div>
          </section>
        </TabPane>
        <TabPane active={currentTab === 2}>
          <section>
            <div className="fui-container">
              <h4 className="is-text-darkgray50">提供您查詢交易的發票記錄</h4>
              <div className="paper">
                <div className="receipt-header">
                  <div className="main">
                    <Grid container>
                      <Grid item xs={6}>
                        <h4>近一年記錄</h4>
                      </Grid>
                      <Grid item xs={6} className="align-right">
                        <Button btnStyle='secondary' size="small">下載空白折讓單</Button>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="extra">
                    <Dropdown list={[
                      { text: '所有發票', value: '所有發票' },
                    ]}
                      className="is-button"
                      arrow={true}
                      label="所有發票"
                      selected="所有發票"
                      value="所有發票"
                      onChange={this.filterChange} />

                    <Dropdown list={[
                      { text: '2020/10 - 2020/03', value: '202010' },
                      { text: '2020/02 - 2019/11', value: '202009' },
                      { text: '2019/10 - 2019/03', value: '202008' },
                    ]}
                      className="is-button"
                      arrow={true}
                      label="2020/10 - 2020/03"
                      selected="202008"
                      value="202008"
                      onChange={this.filterChange} />
                  </div>
                </div>

                <div className="receipt-list">
                  {
                    receiptList3.map((receipt, i) => (
                      <ReceiptCollapse {...receipt} openModal={this.openModal} key={`receipt-collapse-${i}`} layout='ticket' openInvoice={this.openInvoice} />
                    ))
                  }
                </div>

                <Paginate
                  initialPage={this.state.page - 1}
                  pageCount={20}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  containerClassName='fui-pagination'
                  previousLabel={<i className='icon-chevron-left'></i>}
                  nextLabel={<i className='icon-chevron-right'></i>}
                  firstLabel={<i className='icon-first'></i>}
                  lastLabel={<i className='icon-last'></i>}
                  onPageChange={this.pageChange}
                />

                {/* 無記錄 */}
                <div className="receipt-empty my-3">
                  <div className="subtitle2 is-text-darkgray50">查無發票記錄，謝謝。</div>
                </div>
              </div>
            </div>
          </section>
        </TabPane>
        <TabPane active={currentTab === 3}>
          <section>
            <div className="fui-container">
              <h4 className="is-text-darkgray50">提供您查詢易付卡門號上網用量加購記錄</h4>
              <div className="paper">
                <Grid container>
                  <Grid item xs={6}>
                    <h4>近六個月記錄</h4>
                  </Grid>
                  <Grid item xs={6} className="align-right">
                    <Button btnStyle='secondary' size="small">下載空白折讓單</Button>
                  </Grid>
                </Grid>
                <div className="receipt-list">
                  {
                    receiptList4.map((receipt, i) => (
                      <div className='receipt-item' key={`receipt-list-${i}`}>
                        <h4 className='date'>{receipt.date}</h4>
                        <div className="main">
                          <h5>{receipt.name}</h5>
                          <div className="price">
                            金額 <span className="amount">{receipt.amount}元</span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>

                <Paginate
                  initialPage={this.state.page - 1}
                  pageCount={20}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  containerClassName='fui-pagination'
                  previousLabel={<i className='icon-chevron-left'></i>}
                  nextLabel={<i className='icon-chevron-right'></i>}
                  firstLabel={<i className='icon-first'></i>}
                  lastLabel={<i className='icon-last'></i>}
                  onPageChange={this.pageChange}
                />

                {/* 無記錄 */}
                <div className="receipt-empty my-3">
                  <div className="subtitle2 is-text-darkgray50">查無加購記錄，謝謝。</div>
                </div>
              </div>
            </div>
          </section>
        </TabPane>
        {
          this.state.currentTab === 1 ? (
            <SectionCollapseInfo
              title="貼心小叮嚀"
              content={`
                <ol>
                  <li>小額代收交易不適用抵扣任一折扣優惠且不列入快樂購紅利集點點數計算。</li>
                  <li>此處僅顯示交易發生日期，實際帳單收費將依購買之小額代收產品收費方式或門號計算周期為主。</li>
                  <li>使用小額代收服務所產生的費用將與電信帳單同信封寄送。</li>
                </ol>
              `}
            />
          ) : null
        }
        {
          this.state.currentTab === 2 ? (
            <SectionCollapseInfo
              title="貼心小叮嚀"
              content={`儲值記錄查詢貼心小叮嚀`}
            />
          ) : null
        }

        <Modal
          onAfterOpen={e => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={e => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen || this.state.addressModalOpen}
          contentLabel="Example Modal"
          className="fui-prompt-modal fui-query-modal address-input"
        >
          {this.state.modalOpen ? <div>
            <div className='query-table-content'>
              <div className='w-100 position-relative'>
                <div onClick={e => this.setState({ modalOpen: false })} role='button' className='close'>
                  <i className='icon-close'></i>
                </div>
                <div className='table' dangerouslySetInnerHTML={{ __html: InvoiceTable }}></div>
              </div>
            </div>
          </div> : <div className="fui-prompt-modal-body align-left">
              <h5>索取紙本發票</h5>
              <div className='form-group modal-radio'>
                <div className='radio-buttons'>
                  <div className='radio-group'>
                    <label
                      className='radio-button'
                      onClick={() => this.handleChange(1)}
                    >
                      <div className="d-flex flex-column">
                        <p>使用預設地址</p>
                        <input className='input-column' disabled type='text' name='addr' placeholder={this.state.defaultAddr} />
                      </div>
                      <input
                        type='radio'
                        checked={this.state.currentSelect === 1}
                        name="123"
                        onChange={() => this.handleChange(1)}
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                  <div
                    className='radio-group'
                    onClick={() => this.handleChange(2)}
                  >
                    <label className='radio-button'>
                      <div className="d-flex flex-column">
                        <p>自行輸入</p>
                        <div className="dropdown-wrapper d-flex">
                          <Dropdown
                            label={this.state.currentCity.text}
                            className='is-border mr-2'
                            arrow={true}
                            list={this.state.cityList}
                            onChange={item => this.selectCity(item)} />
                          <Dropdown
                            label={this.state.currentDist.text}
                            className='is-border'
                            arrow={true}
                            list={this.state.distList}
                            onChange={item => this.selectDist(item)} />
                        </div>
                        <input ref={this.addressInput} onKeyUp={event => this.addressKeyIn(event)} className='input-column' type='text' name='addr' placeholder="請輸入地址" />
                      </div>
                      <input
                        type='radio'
                        checked={this.state.currentSelect === 2}
                        name="222"
                        onChange={() => this.handleChange(2)}
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                </div>
                {/* {this.state.isInvalid ? <span className='error-message'>{errorMessage}</span> : ''} */}
              </div>
              <div className="fui-buttons two-button justify-center">
                <Button btnStyle='primary' className={this.getValidation() ? '' : 'disabled'} onClick={() => { this.submmit() }
                }>確定</Button>
                <Button btnStyle='secondary' onClick={() => { this.setState({ addressModalOpen: false }) }
                }>取消</Button>
              </div>
            </div>}



        </Modal>
      </main>
    )
  }
}

export default getQueryRecordPage;