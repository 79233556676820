import React from 'react';
import Tab from '../../tab/Tab';
import Button from '../../Button';
import Dropdown from '../../Dropdown';
import Link from '../../Link';
import CbuPromotion from '../../ad/CbuPromotion';
import { useLocation } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';

const CbuBanner = (props) => {
  const setDefaultTab = props.currentTab >= 0 && props.currentTab < props.tabs.list.length;
  const banner = React.createRef();
  const [currentTab, setCurrentTab] = React.useState(setDefaultTab ? props.currentTab : 0);
  const [isFade, setIsFade] = React.useState(false);
  const [selectItem, setSelectItem] = React.useState(null);
  const [tab, setTab] = React.useState(props.tabs || { list: [] });
  const [tabContent, setTabContent] = React.useState(props.tabContent || []);
  const [isTabSet, setIsTabSet] = React.useState(false);
  const [visibleActive, setVisibleActive] = React.useState(false);
  const pageLocation = useLocation();

  // React.useEffect(() => {
  // let pathname = typeof window !== "undefined" ? window.location.pathname : "";
  const pathname = pageLocation.pathname;
  let sub = [];

  React.useEffect(() => {
    tab.list.map((tab, i) => {
      if (pathname.indexOf(tab.link) > -1) {
        setCurrentTab(i);
      }
    });
  });

  if (!isTabSet && tab && tab.list && tab.list.length) {
    tab.list.map((tab, i) => {
      if (tab.link === pathname) {
        setCurrentTab(i);
        setIsTabSet(true);
      }

      if (!sub[i]) sub.push(0);

      sub[i] = tabContent[i].subOption && tabContent[i].subOption.length ? 0 : -1;
      return false;
    });
  }
  const [subTab, setSubTab] = React.useState(sub);
  // }, [props, tab, tab.list, tabContent]);

  const fireActionButtonClickCustomEvent = (text) => {
    let url = '';
    if (typeof window !== 'undefined') {
      if (text === '免費諮詢') {
        let searchParams = new URLSearchParams(window.location.search.slice(1));
        let regExp = new RegExp(/\/ebu\/..\/.*?\//, 'g').exec(window.location.pathname);
        let pageFrom = regExp && regExp[0] + ' - ' + document.title;
        searchParams.append('pf', encodeURI(pageFrom));
        searchParams.append('al', encodeURI('banner'));
        searchParams.append('an', encodeURI(text));

        url = `?${searchParams}`;
      }
    }
    return url;
  };

  const handleChange = (newValue) => {
    setIsFade(true);
    if (currentTab !== newValue) {
      let sub = Object.assign(subTab);
      sub[newValue] = 0;
      setSubTab(sub);
      setCurrentTab(newValue);
      setSelectItem(null);
    }

    if (props.onChange) props.onChange(newValue);
    setTimeout(() => {
      setIsFade(false);
    }, 600);
  };

  const dropdownChange = (event, item, idx) => {
    let sub = Object.assign(subTab);
    sub[currentTab] = idx;
    let selectedBanner = banner.current.getElementsByClassName('fui-banner-bg')[0];
    selectedBanner.classList.add('fade-out');
    setSubTab(sub);
    setSelectItem(item);
    setTimeout(() => {
      selectedBanner.classList.remove('fade-out');
    }, 500);

    // [AA Tracking]
    if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, `${item.text}`);
  };

  const visibilityChange = (visible) => {
    if (visible) setVisibleActive(true);
  };

  return (
    <div>
      <VisibilitySensor onChange={visibilityChange} offset={100}>
        {({ visible }) => (
          <section
            className={`fui-banner fui-cbu fade-in ${
              !document.getElementsByClassName('.fui-splash-promotion')[0] && !visible && visibleActive ? `` : ''
            }`}
            ref={banner}>
            {tabContent.map((tab, idx) =>
              tab.subOption ? (
                <div key={`banner-bg-${idx}`} className={`fui-banner-bg ${currentTab === idx ? 'is-active' : ''}`}>
                  <img
                    data-src={
                      !tab.subOption || subTab[idx] === -1
                        ? tab.image.md
                        : tab.subOption[subTab[idx]].md || tab.image.md
                    }
                    src={
                      !tab.subOption || subTab[idx] === -1
                        ? tab.image.md
                        : tab.subOption[subTab[idx]].md || tab.image.md
                    }
                    srcSet={
                      !tab.subOption || !tab.subOption.length || subTab[idx] === -1
                        ? tab.image.retinaMd || tab.image.md
                        : tab.subOption[subTab[idx]].retinaMd || tab.subOption[subTab[idx]].md || tab.image.md
                    }
                    className='kv-bg
                  d-none d-md-block lazyload'
                    alt={tab.content.dropdown.length ? tab.content.dropdown[subTab[idx]].text : 'banner1'}
                  />
                  <img
                    data-src={
                      !tab.subOption || !tab.subOption.length || subTab[idx] === -1
                        ? tab.image.retinaSm || tab.image.sm
                        : tab.subOption[subTab[idx]].sm || tab.image.sm
                    }
                    src={
                      !tab.subOption || !tab.subOption.length || subTab[idx] === -1
                        ? tab.image.retinaSm || tab.image.sm
                        : tab.subOption[subTab[idx]].sm || tab.image.sm
                    }
                    srcSet={
                      !tab.subOption || !tab.subOption.length || subTab[idx] === -1
                        ? tab.image.retinaSm || tab.image.sm
                        : tab.subOption[subTab[idx]].retinaSm || tab.subOption[subTab[idx]].sm || tab.image.sm
                    }
                    className='kv-bg d-block d-md-none lazyload'
                    alt={tab.content.dropdown.length ? tab.content.dropdown[subTab[idx]].text : 'banner1'}
                  />
                </div>
              ) : (
                <div key={`banner-bg-${idx}`} className={`fui-banner-bg ${currentTab === idx ? 'is-active' : ''}`}>
                  <img
                    src={tab.image.md}
                    srcSet={tab.image.retinaMd || tab.image.md}
                    className='kv-bg
                    d-none d-sm-block lazyload'
                    alt=''
                  />
                  <img
                    src={tab.image.retinaSm || tab.image.sm}
                    srcSet={tab.image.retinaSm || tab.image.sm}
                    className='kv-bg d-block d-sm-none lazyload'
                    alt={'banner1'}
                  />
                </div>
              )
            )}
            <div className='kv-arrow'>
              <img
                className='lazyload'
                data-src={'/resources/cbu/cbu-index/kv-arrow.png'}
                src={'/resources/cbu/cbu-index/kv-arrow.png'}
                srcSet={'/resources/cbu/cbu-index/kv-arrow@2x.png'}
                className='d-none d-md-block'
                alt='kv'
              />
              <img
                className='lazyload'
                data-src={'/resources/cbu/cbu-index/kv-arrow.png'}
                src={'/resources/cbu/cbu-index/kv-arrow-sm.png'}
                srcSet={'/resources/cbu/cbu-index/kv-arrow-sm@2x.png'}
                className='d-block d-md-none'
                alt='kv'
              />
            </div>
            <div className='fui-container'>
              <div className='fui-tab-container'>
                <div className='fui-tab-header'>
                  {tab.list.length ? <Tab icon={true} default={currentTab} {...tab} onChange={handleChange} /> : null}
                </div>
                <div className='fui-tab-body'>
                  {tabContent.map((tab, idx) => (
                    <div
                      className={`fui-tab-pane ${currentTab === idx ? 'is-active' : ''}`}
                      key={`tabbody-${idx}`}
                      role='tabpanel'
                      id={`simple-tabpanel-${idx}`}
                      aria-labelledby={`simple-tab-${idx}`}>
                      <div className='tab-content' id={`mainView-${idx + 1}-1`}>
                        {tab.content.links ? (
                          <div className='tab-link-content'>
                            {tab.content.links.map((linkItem, i) => (
                              <div key={`tab-${idx}-link-content-${i}`}>
                                <Link
                                  to={linkItem.link}
                                  className='tab-link'
                                  onClick={(event) => {
                                    // [AA Tracking]
                                    if (window && typeof window.ctrf === 'function')
                                      window.ctrf(event || {}, `${linkItem.text}`);
                                  }}>
                                  {linkItem.text}
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : null}
                        {tab.content.dropdown && tab.content.dropdown.length ? (
                          tab.content.dropdown.length == 1 ? (
                            <h3
                              className='tab-title mt-0'
                              dangerouslySetInnerHTML={{
                                __html: tab.content.dropdown[0].text,
                              }}></h3>
                          ) : (
                            <Dropdown
                              className='for-banner'
                              list={tab.content.dropdown}
                              label={!selectItem ? tab.content.dropdown[0].text : selectItem.text}
                              arrow={true}
                              hasCheck={true}
                              onChange={dropdownChange}
                            />
                          )
                        ) : null}
                        {tab.content.dropdown && tab.content.dropdown.length ? (
                          <div className='action'>
                            {!selectItem
                              ? tab.content.dropdown[0].actions
                                ? tab.content.dropdown[0].actions.map((elem, i) => (
                                    <Button
                                      key={`ebu-banner-action-${i}`}
                                      {...elem}
                                      className='is-large'
                                      btnStyle={i === 0 ? 'primary' : 'secondary'}
                                      link={elem.link + fireActionButtonClickCustomEvent(elem.text)}
                                      onClick={(event) => {
                                        // [AA Tracking]
                                        if (window && typeof window.ctrf === 'function')
                                          window.ctrf(event || {}, `${tab.content.dropdown[0].text}_${elem.text}`);
                                      }}>
                                      {elem.text}
                                    </Button>
                                  ))
                                : null
                              : selectItem.actions
                              ? selectItem.actions.map((elem, i) => (
                                  <Button
                                    key={`ebu-banner-action-${i}`}
                                    {...elem}
                                    className='is-large'
                                    btnStyle={i === 0 ? 'primary' : 'secondary'}
                                    link={elem.link + fireActionButtonClickCustomEvent(elem.text)}
                                    onClick={(event) => {
                                      // [AA Tracking]
                                      if (window && typeof window.ctrf === 'function')
                                        window.ctrf(event || {}, `${selectItem.text}_${elem.text}`);
                                    }}>
                                    {elem.text}
                                  </Button>
                                ))
                              : null}
                          </div>
                        ) : null}

                        {tab.content.tags && tab.content.tags.length ? (
                          <div className='fui-banner-tags' id={`mainView-${idx + 1}-2`}>
                            {tab.content.tags.map((tag, k) => (
                              <Link
                                to={tag.link}
                                title={tag.text}
                                className='fui-button is-label'
                                key={`fui-banner-tag-${k}`}>
                                {tag.text}
                              </Link>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}
      </VisibilitySensor>
      {tabContent.map((tab, idx) =>
        currentTab === idx ? (
          <div>
            <CbuPromotion
              id={tabContent.length - 1 === idx ? `mainView-"${idx + 1}-2` : `mainView-${idx + 1}-3`}
              key={`banner-promotion-${idx}`}
              {...tab.promotion}
            />
          </div>
        ) : null
      )}
    </div>
  );
};

CbuBanner.propTypes = {
  tabs: PropTypes.shape({
    name: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        icon: PropTypes.string,
        focusIcon: PropTypes.string,
        label: PropTypes.string.isRequired,
      })
    ),
  }),
  tabContent: PropTypes.arrayOf(
    PropTypes.shape({
      subOption: PropTypes.arrayOf(
        PropTypes.shape({
          md: PropTypes.string,
          retinaMd: PropTypes.string,
          sm: PropTypes.string,
          retinaSm: PropTypes.string,
        })
      ),
      image: PropTypes.shape({
        md: PropTypes.string,
        retinaMd: PropTypes.string,
        sm: PropTypes.string,
        retinaSm: PropTypes.string,
      }),
      content: PropTypes.shape({
        // title: PropTypes.string,
        // actions: PropTypes.arrayOf(PropTypes.objectOf(Button))
      }),
    })
  ),
  onChange: PropTypes.func,
};

export default CbuBanner;
