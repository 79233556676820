import axios from 'axios';

export const getContractInfo = async () => {
  /**
   * 若要串接 API
   * 1. 換上正確的 url
   * 2. 檢查 return 是否成功回傳
   * 3. 可再根據需要做判斷與處理後再回傳
   *
   * axios('https://staging.vas.fetnet.net/eService_Revamp/api/mock/contractController/queryContractsInfo').then(result => {
   *  return result.contractInfoList;
   * })
   */

  return [
    {
      name: '(換)特殊通路限定_新絕配199_限24_單門號',
      startDate: '2017/12/21',
      endDate: '2020/06/20',
      contractLimit: '2021/08/09後方可更改低於4G新絕配199',
      contractSuspendInfoList: null,
      contractDiscountInfoList: [
        {
          name: '上網傳輸輛優惠3GB*24個月',
          startDate: '2019/08/08',
          endDate: '2021/08/20',
        },
        {
          name: '網內通話優惠200分鐘*24個月',
          startDate: '2019/08/08',
          endDate: '2021/08/20',
        },
        {
          name: '他網通話優惠20分鐘*24個月',
          startDate: '2019/08/08',
          endDate: '2021/08/20',
        },
        {
          name: '市話通話優惠5分鐘*24個月',
          startDate: '2019/08/08',
          endDate: '2021/08/20',
        },
      ],
    },
    {
      name: '4G絕配平板方案_高速飆網899_限24_平板案',
      startDate: '2018/11/27',
      endDate: '2020/11/26',
      contractLimit: '2020/11/26後方可更改低於4G高速飆網899',
      contractSuspendInfoList: null,
      contractDiscountInfoList: [
        {
          name: 'Wifly/FET-WiFi免費優惠',
          startDate: '2018/11/27',
          endDate: '無使用期限',
        },
        {
          name: '上網傳輸量優惠5.5GB*24個月',
          startDate: '2018/11/27',
          endDate: '2020/12/25',
        },
        {
          name: '上網傳輸量優惠15GB*24個月',
          startDate: '2018/11/27',
          endDate: '2020/12/25',
        },
      ],
    },
  ];
};
