import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../Button'
import PropTypes from 'prop-types';

const SectionAd2 = (props) => {
    const [isMd, setIsMd] = React.useState(typeof window !== 'undefined' && window.innerWidth >= 768);

    if(typeof window !== 'undefined' )
        window.onresize = () => {
            setIsMd(window.innerWidth >= 768)
        }

    return (
        <section className="fui-section-promo with-pattern"
            style={{
                backgroundImage: `url(${isMd ? props.image.md : props.image.sm})`
            }} id={props.id}>
            <div className="fui-container">
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={6} className='content'>
                        <div className="section-of-promotion-2">
                            <h4 className="fui-section-promo-title" dangerouslySetInnerHTML={{ __html: props.title }}>
                            </h4>
                            {
                                props.description ? (
                                    <p>
                                        {props.description}
                                    </p>
                                ) : ''
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className='action'>
                        {
                            <Button link={props.action.link} btnStyle={props.action.btnStyle || 'secondary'} reverse={true} target={props.action.target}>
                                {props.action.text}
                            </Button>
                        }
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}

SectionAd2.propTypes = {
    image: PropTypes.shape({
        md: PropTypes.string,
        sm: PropTypes.string
    }),
    title: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    target: PropTypes.string,
    action: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
        btnStyle: PropTypes.string // only 'primary' or 'secondary
    })
}

export default SectionAd2;
