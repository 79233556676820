import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';
import RadioButtons from '../../components/form/RadioButtons';
import RadioGroup from '../../components/form/RadioGroup';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import numberFormatter from '../../utils/numberFormatter';

const options = [
  {
    id: '1',
    name: '易付卡漫遊上網包_港澳5日3GB',
    amount: 299,
    usage: '3.00 GB',
    description: '5 日(連續 120 小時)內享兩地合計共 3GB 漫遊上網流量，流量使用完畢終止或到期中止',
  },
  {
    id: '2',
    name: '易付卡漫遊上網包_新加坡5日3GB',
    amount: 549,
    usage: '3.00 GB',
    description: '5 日(連續 120 小時)內享 3GB 漫遊上網流量，流量使用完畢終止或到期中止',
  }
]

class prepaidRoamingBuyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        prepaid_option: {value: '1', required: true},
        payment: { value: '1', required: true }
      }
    }
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    this.setState({
      form: newForm
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  submit = () => {
    if (this.state.form.payment.value === '1')
      this.props.history.push(`/prepaidRoamingBalanceController/prepaidRoamingByBankPage`);

    if (this.state.form.payment.value === '2')
      this.props.history.push(`/prepaidRoamingBalanceController/prepaidRoamingByCreditPage`);
  };

  render() {
    return (
      <main>
        
        <NavAnchor 
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="預付卡漫遊上網加購"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />
        <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
          <section className="page-body">
            <div className="fui-container">
              <div className='paper'>
                <Grid container>
                  <Grid item xs={7} md={7}>
                    <h4 className="mb-2 mt-0">加值門號與餘額</h4>
                  </Grid>
                  <Grid item xs={5} md={5} className='align-right'>
                    <Button btnStyle='secondary' size='small'>查詢漫遊用量</Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="rate-plan-box">
                      <h5>門號</h5>
                      <h2>0926-899-868</h2>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="rate-plan-box">
                      <h5>餘額</h5>
                      <h2>$209.12</h2>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className='paper'>
                <h4 className="mt-0 mb-2">請選擇上網用量方案</h4>
                <div className="fui-table-response">
                  <table className="option-table">
                    <thead>
                      <tr>
                        <th>選擇</th>
                        <th>方案名稱</th>
                        <th width="90">金額</th>
                        <th width="120">用量</th>
                        <th>說明</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        options.map((opt, i) => (
                          <tr key={`option-table-${i}`}>
                            <td>
                              <label>
                                <input 
                                  id={`prepaid-${i}`} 
                                  type="radio" 
                                  name="prepaid_option" 
                                  value={opt.id} 
                                  checked={this.state.form.prepaid_option.value === opt.id}
                                  onChange={e => this.onChange('prepaid_option', opt.id)}
                                />
                                <div className="radio"></div>
                              </label>
                            </td>
                            <td><label htmlFor={`prepaid-${i}`}>{opt.name}</label></td>
                            <td align="center"><label htmlFor={`prepaid-${i}`}>$ {numberFormatter(opt.amount)}</label></td>
                            <td align="center"><label htmlFor={`prepaid-${i}`}>{opt.usage}</label></td>
                            <td><label htmlFor={`prepaid-${i}`}>{opt.description}</label></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <div className='mt-5'>
                  <RadioGroup 
                    label='請選擇付款方式'
                    name="payment"
                    options={[
                      {label: '銀行帳號', value: '1'},
                      {label: '信用卡', value: '2'},
                    ]}
                    default={this.state.form.payment.value} 
                    onChange={this.onChange}
                  />
                </div>
              </div>
              
              <div className="page-action">
                <Button btnStyle="primary" size='large' onClick={this.submit}>下一步</Button>
              </div>
            </div>
          </section>
        </Formsy>
        <SectionCollapseInfo 
        title="貼心小叮嚀"
        content={`
          1.易付出國漫遊上網服務僅適用於4G用戶。<br/>
          2.自申購成功日起30日內有效，於有效期限內抵達國外當地後開機並確認登錄於指定業者(港澳：Hutchison，新加坡：Starhub)優惠網；收到漫遊上網啟用生效簡訊即開始計算上網時數。
        `}
        />
      </main>
    )
  }
}

export default prepaidRoamingBuyPage;