import React, { Component } from 'react'
import Breadcrumb from '../../components/Breadcrumb';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import Link from '../../components/Link';
import Pagination from '../../components/paginate/Pagination';
class Lottery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
      promotion: {
        title: '你的專屬優惠',
        cards: [
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
            title: '本月影片免費看!',
            description: '話題電影、日劇、韓劇等等應有盡有',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
            title: '中午不知道吃什麼？',
            description: 'Uber Eats 送遠傳新客優惠金200元',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
            title: '連假打算出國？',
            description: '遠遊卡吃到飽上網不再煩惱用量 ',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
            title: '本月影片免費看!',
            description: '話題電影、日劇、韓劇等等應有盡有',
            link: '#',
          },
        ]
      },
      detailForm: {
        title: '費用類別明細',
        table: {
          head: ['業者', '指定電信業者', '速博', '中華電信', '亞太電信', '台灣固網'],
          content: [
            {
              table: ['國際接取碼', '計費單位'],
              link: '#'
            },
            ['速博', '元/秒'],
            ['18881', '元/每分'],
            ['007', '元/每秒'],
            ['017', '元/每分'],
            ['009', '元/每分'],
            ['019（限月租型）', '元/每分'],
            ['005', '元/6秒'],
            ['015', '元/每分'],
            ['006', '元/6秒'],
            ['016', '元/每分'],
          ]
        }
      },
      table: {
        head: [
          '活動名稱', '活動起迄日', '公佈日期'
        ],
        body: [
          {
            table: ['愛講定位手錶【兒童節限定】親子同樂雙重抽_得獎名單', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#',
            target: '_blank'
          },
          {
            table: ['白色情人節上網趣_遠傳易付卡與你甜甜圈在一起', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#'
          },
          {
            table: ['遠傳電信網路門市新申辦/攜碼單門號加碼抽紫外線除菌機', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#',
            target: '_blank'
          },
          {
            table: ['新年鼠紅包 行動客服送百貨購物金', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#'
          },
          {
            table: ['電子帳單 獻上新春大禮包 首辦送$8,800購物金 再抽電影免費看', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#'
          },
          {
            table: ['網路門市續約大活動', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#',
            target: '_blank'
          },
          {
            table: ['愛講定位手錶徵文得獎名單', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#'
          },
          {
            table: ['Samsung Galaxy A系列優惠活動 遠傳獨家抽Switch健身環大冒險', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#',
            target: '_blank'
          },
          {
            table: ['2020/2【遠傳會員生日禮】抽好康', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#'
          },
          {
            table: ['2020/1-2月【遠傳會員獎不完 月月有好禮】抽好康', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#'
          },
          {
            table: ['friDay相片書2019年11~12月印製抽獎活動中獎名單', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#',
            target: '_blank'
          },
          {
            table: ['愛的明信片~傳遞旅遊祝福 製作專屬明信片 參加即送折價券、分享抽三萬旅遊金', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#'
          },
          {
            table: ['愛講定位手錶滿意度問卷2月份得獎名單', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#',
            target: '_blank'
          },
          {
            table: ['冬天給你好元氣 行動客服免費請你吃暖暖早餐', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#'
          },
          {
            table: ['2019/10-2020/1【遠傳會員生日禮】抽好康', '2020/03/01~2020/04/04', '2020/04/17'],
            link: '#'
          },
        ]
      }
    }
  }
  onChangePage(pageOfItems, selectedPage) {
    // this.props.history.push(`${this.props.location.pathname}?keyword=${this.state.keyword}&page=${selectedPage}`);

    // update page of items
    // this.updatePageOfItems(pageOfItems);
    // console.log('on change');
  }
  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', e => {
        this.setState({
          isMobile: window.innerWidth < 960
        })
      })
  }
  msieversion = () => {
    let ua = window.navigator.userAgent;
    let isIE = /MSIE|Trident/.test(ua);
    let isEdge = ua.indexOf('Edge') > 0;
    if (isIE || isEdge) {
      // console.log('is ie');
      return true;
    } else {
      return false;
    }
  }
  // check Safari
  render() {
    return (
      <main>
        <Breadcrumb
          breadcrumb={[
            { link: '/', text: '首頁' },
            { link: '', text: '中獎公告' },
          ]}
        />
        <div className="fui-container pt-1 pb-md-6 pb-8">
          <h2 className='pt-md-10 pt-4'>中獎公告</h2>
          <div className="paper pt-md-7">
            <table className="d-block text-wrap table-gray-head mt-0 mb-4" style={this.msieversion ? null : { outline: '2px solid #E8ECF0', outlineOffset: -2 }}>
              <tbody className='d-table' style={{ overflow: "scroll" }}>
                <tr>
                  {this.state.table.head.map((item, i) => {
                    return (
                      <th key={i} className="">{item}</th>
                    )
                  })}
                </tr>
                {this.state.table.body.map((row, i) => {
                  return (
                    <tr key={i}>
                      {row.table.map((item, j) => {
                        // console.log(`item: `, item);
                        return (
                          <td key={j} className="align-left px-md-5" style={{ maxWidth: 530, whiteSpace: this.state.isMobile ? 'nowrap' : 'normal' }}>
                            {j === 0 ? <Link
                              className='hover-underline-link is-text-darkgray50'
                              to={row.link}
                              target={row.target}
                            >
                              {item}
                            </Link> : item}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Pagination
              items={['1', '2', '3', '4']}
              onChangePage={this.onChangePage}
              initialPage={1}
              className="mb-md-0 mt-md-7 mb-2"
              pageSize={2} />
          </div>
        </div>
        <SectionCarousel1 {...this.state.promotion} />
      </main>
    );
  }
}

export default Lottery;