import React, { Component } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import Link from '../../components/Link';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
class Lottery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
            content: {
                title: '活動名稱：9月填寫遠傳電信服務推薦意願調查問卷, 月月抽哈根達斯',
                text: '活動日期：2020/09/01 ~ 2020/12/31<br/>公佈日期：2020/10/14<br/>獎項：哈根達斯冰淇淋券乙張'
            },
            table: {
                head: [
                    '編號', '姓名', '中獎門號', '獎項'
                ],
                body: [
                    {
                        table: ['001', '謝*晏', '0926***399	', '哈根達斯冰淇淋券乙張']
                    }
                ]
            }
        }
    }
    msieversion = () => {
        let ua = window.navigator.userAgent;
        let isIE = /MSIE|Trident/.test(ua);
        let isEdge = ua.indexOf('Edge') > 0;
        if (isIE || isEdge) {
            // console.log('is ie');
            return true;
        } else {
            return false;
        }
    }
    componentDidMount() {
        if (typeof window !== 'undefined')
            window.addEventListener('resize', e => {
                this.setState({
                    isMobile: window.innerWidth < 960
                })
            })
    }
    render() {
        return (
            <main>
                <Breadcrumb
                    breadcrumb={[
                        { link: '/', text: '首頁' },
                        { link: '/', text: '中獎公告' },
                        { link: '', text: '中獎名單' },
                    ]}
                />
                <div className="fui-container pt-1 pb-md-6 pb-8">
                    <h2 className='pt-md-10 pt-4'>中獎公告</h2>
                    <div className="paper pt-md-7">
                        <h4 className="m-0">{this.state.content.title}</h4>
                        <p className="" dangerouslySetInnerHTML={{ __html: this.state.content.text }}>
                        </p>
                        <table className="d-block text-wrap table-gray-head mt-4 mb-4" style={this.msieversion ? null : { outline: '2px solid #E8ECF0', outlineOffset: -2 }}>
                            <tbody className='d-table' style={{ overflow: "scroll" }}>
                                <tr>
                                    {this.state.table.head.map((item, i) => {
                                        return (
                                            <th key={i} className="align-left">{item}</th>
                                        )
                                    })}
                                </tr>
                                {this.state.table.body.map((row, i) => {
                                    return (
                                        <tr key={i}>
                                            {row.table.map((item, j) => {
                                                return (
                                                    <td key={j} className="align-left" style={{ maxWidth: 530, whiteSpace: this.state.isMobile ? 'nowrap' : 'normal' }}>
                                                        {item}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <SectionCollapseInfo
                    title="貼心小叮嚀"
                    content={`
                <ol>
                  <li>參加者必須遵守遠傳電信官網使用規範及其他有關之規定，於參加本活動之同時，即同意接受本活動之活動辦法與注意事項之規範，如有違反，遠傳電信得取消其參加或得獎資格，並就因此所生之損害，得向該參加者請求損害賠償。</li>
                  <li>活動獎項寄送地區僅限台、澎、金、馬，與遠傳電信無義務處理郵寄獎品、禮券至海外地區之事宜。</li>
                  <li>參加者保證所有填寫或提出之資料均為真實且正確，並未有冒用或盜用任何第三人之資料，如有不實或不正確之情事時，將被取消參加或得獎資格，遠傳電信不負任何責任。且如因此致生損害於遠傳電信或任何第三人時，參加者應自負一切相關責任。</li>
                  <li>參加活動者必須遵守遠傳電信官網的使用規範及其他有關之規定，如有違反，即自動喪失參加資格，如為得獎者，則取消得獎資格。</li>
                  <li>活動之獎品以寄送的實物為準，獎品不得轉換、轉讓或折換現金。禮券使用方式依哈根達斯公告為主。</li>
                  <li>活動獎項價值如超過NT$1,000，需列入個人綜合所得稅申報。</li>
                  <li>參加者如因參加本活動或因活動獎項而遭受任何損失，遠傳電信及相關之母公司、子公司、關係企業、員工及代理商不負任何責任，一旦得獎者領取獎品後，若有毀損、遺失或被竊，遠傳電信或合作廠商均無義務發給任何證明或補償。</li>
                  <li>若原定獎項因不可抗拒之因素無法提供，得獎者同意遠傳電信或合作廠商得另以等值之獎品替代之，不得要求折現或更換為其他特定商品或服務。</li>
                  <li>活動因故無法進行時，遠傳電信有權決定取消、終止、修改或暫停活動。</li>
                  <li>遠傳電信保有隨時修改及終止本活動之權利，如有任何變更內容或詳細注意事項將公佈於本活動網站，恕不另行通知。</li>
                </ol>
              `}
                />
            </main>
        );
    }
}

export default Lottery;