import React from 'react';
import PropTypes from 'prop-types';

class Panel extends React.Component {
    render() {
        return (
            <div className={`fui-panel article-panel ${this.props.className}`}>
                <div className='fui-container'>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

Panel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,    
}

export default Panel;