import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';

import DatePicker from "react-datepicker";
import FormBanner from '../../components/partials/banner/FormBanner';
import Select from '../../components/form/Select';
import Modal from '../../components/tableBtnModal';
import Paginate from '../../components/paginate';

class parkingfeePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      page: 1,
      form: {
        billingEndAt: { value: '' },
        billingStartAt: { value: '' },
        car_type: { value: '' },
        car_number: { value: '' }
      },
      detailContent: {
        title1: '開單日期',
        content1: '2020/03/16',
        title2: '代收交易日',
        content2: '2020/03/23',
        title3: '車輛種類',
        content3: '汽車',
        title4: '車輛號碼',
        content4: '8656-YB',
        title5: '金額',
        content5: '$30',
        title6: '代收地區',
        content6: '台北市路邊停車',
        title7: '繳費狀態',
        content7: '扣款成功',
        // title8: '其他',
        // content8: '詳細資料',
        title9: '車單號碼',
        content9: '54656132187948',
      }
      // detailContent: [
      //   {
      //     title1: '開單日期',
      //     content1: '2020/03/16',
      //     title2: '代收交易日',
      //     content2: '2020/03/23',
      //     title3: '車輛種類',
      //     content3: '汽車',
      //     title4: '車輛號碼',
      //     content4: '8656-YB',
      //     title5: '金額',
      //     content5: '$30',
      //     title6: '代收地區',
      //     content6: '台北市路邊停車',
      //     title7: '繳費狀態',
      //     content7: '扣款成功',
      //     title8: '其他',
      //     content8: '詳細資料',
      //     title9: '車單號碼',
      //     content9: '54656132187948',
      //   },
      //   {
      //     title1: '開單日期',
      //     content1: '2020/03/18',
      //     title2: '代收交易日',
      //     content2: '2020/03/25',
      //     title3: '車輛種類',
      //     content3: '汽車',
      //     title4: '車輛號碼',
      //     content4: '8656-YB',
      //     title5: '金額',
      //     content5: '$20',
      //     title6: '代收地區',
      //     content6: '高雄市路邊停車',
      //     title7: '繳費狀態',
      //     content7: '扣款成功',
      //     title8: '其他',
      //     content8: '詳細資料',
      //     title9: '車單號碼',
      //     content9: '54656132187948',
      //   },
      //   {
      //     title1: '開單日期',
      //     content1: '2020/03/21',
      //     title2: '代收交易日',
      //     content2: '2020/03/26',
      //     title3: '車輛種類',
      //     content3: '汽車',
      //     title4: '車輛號碼',
      //     content4: '8656-YB',
      //     title5: '金額',
      //     content5: '$60',
      //     title6: '代收地區',
      //     content6: '台北市路邊停車',
      //     title7: '繳費狀態',
      //     content7: '扣款成功',
      //     title8: '其他',
      //     content8: '詳細資料',
      //     title9: '車單號碼',
      //     content9: '54656132187948',
      //   },
      // ]
    };
  };

  pageChange = () => {

  };


  onChange = (n, v) => {
    let form = Object.assign(this.state.form)
    form[n].value = v;
    this.setState({
      form: form
    })
  };

  openModal = () => {
    this.setState({
      openModal: true
    });
  };
  closeModal = () => {
    this.setState({
      openModal: false
    });
  };

  render() {
    const { form } = this.state;

    return (
      <main>

        <NavAnchor
          pageTitle='代收記錄查詢'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="停車費代收記錄查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />

        <section className={`page-body`}>
          <Formsy>
            <div className="fui-container">
              <div className='paper'>
                <h5 className="mt-0 mt-lg-4 mb-2">停車開單日期</h5>

                <Grid container>
                  <Grid item xs={12} md={5}>
                    <div className="form-group">
                      <DatePicker
                        name='billingStartAt'
                        dateFormat="Y/MM/dd"
                        lang="zh-TW"
                        minDate={new Date()}
                        selected={form.billingStartAt.value}
                        onChange={date => this.onChange('billingStartAt', date)}
                      />
                    </div>
                  </Grid>
                  <div className="datepicker-divider">~</div>
                  <Grid item xs={12} md={5}>
                    <div className="form-group">
                      <DatePicker
                        name='billingEndAt'
                        dateFormat="Y/MM/dd"
                        lang="zh-TW"
                        minDate={new Date()}
                        selected={form.billingEndAt.value}
                        onChange={date => this.onChange('billingEndAt', date)}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <div className="form-group">
                      <label>車輛種類</label>
                      <Select
                        name="car_type"
                        label="請選擇"
                        options={[
                          { text: '汽車', value: '1' }
                        ]}
                        onChang={this.onChange}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <div className="form-group">
                      <label>車輛號碼</label>
                      <Select
                        required={true}
                        name="car_number"
                        label="請選擇"
                        options={[
                          { text: '全部', value: '1' },
                          { text: '8656-YB', value: '2' },
                        ]}
                        onChang={this.onChange}
                      />
                    </div>
                  </Grid>
                </Grid>

                <div className="fui-table-response">
                  <table className="option-table center-table">
                    <thead>
                      <tr>
                        <th>開單日期</th>
                        <th className="d-none d-lg-table-cell">代收交易日</th>
                        <th className="d-none d-lg-table-cell">車輛種類</th>
                        <th className="d-none d-lg-table-cell">車輛號碼</th>
                        <th className="d-none d-lg-table-cell">金額</th>
                        <th>代收地區</th>
                        <th className="d-none d-lg-table-cell">繳費狀態</th>
                        <th>其他</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2020/03/16</td>
                        <td className="d-none d-lg-table-cell">2020/03/23</td>
                        <td className="d-none d-lg-table-cell">汽車</td>
                        <td className="d-none d-lg-table-cell">8656-YB</td>
                        <td className="d-none d-lg-table-cell">$30</td>
                        <td>台北市路邊停車</td>
                        <td className="d-none d-lg-table-cell">扣款成功</td>
                        <td className="is-text-accent" onClick={this.openModal}>詳細資料</td>
                      </tr>
                      <tr>
                        <td>2020/03/18</td>
                        <td className="d-none d-lg-table-cell">2020/03/25</td>
                        <td className="d-none d-lg-table-cell">汽車</td>
                        <td className="d-none d-lg-table-cell">8656-YB</td>
                        <td className="d-none d-lg-table-cell">$20</td>
                        <td>高雄市路邊停車</td>
                        <td className="d-none d-lg-table-cell">扣款成功</td>
                        <td className="is-text-accent" onClick={this.openModal}>詳細資料</td>
                      </tr>
                      <tr>
                        <td>2020/03/21</td>
                        <td className="d-none d-lg-table-cell">2020/03/26</td>
                        <td className="d-none d-lg-table-cell">汽車</td>
                        <td className="d-none d-lg-table-cell">8656-YB</td>
                        <td className="d-none d-lg-table-cell">$60</td>
                        <td>台北市路邊停車</td>
                        <td className="d-none d-lg-table-cell">扣款成功</td>
                        <td className="is-text-accent" onClick={this.openModal}>詳細資料</td>
                      </tr>
                    </tbody>
                  </table>
                  <Modal open={this.state.openModal} detailContent={this.state.detailContent} onClose={this.closeModal} />
                </div>
              </div>
              <Paginate
                initialPage={this.state.page - 1}
                pageCount={20}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                containerClassName='fui-pagination'
                previousLabel={<i className='icon-chevron-left'></i>}
                nextLabel={<i className='icon-chevron-right'></i>}
                firstLabel={<i className='icon-first'></i>}
                lastLabel={<i className='icon-last'></i>}
                onPageChange={this.pageChange}
              />
            </div>
          </Formsy>
        </section>
      </main>
    )
  }
}

export default parkingfeePage;