
export const promotionCard = {
  title: '你的專屬優惠',
  // more: {
  //   link: '#',
  //   text: '更多話題',
  // },
  cards: [
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
      title: '本月影片免費看',
      description: '話題電影、日劇、韓劇等等應有盡有',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
      title: '中午不知道吃什麼？',
      description: 'Uber Eats 送遠傳新客優惠金200元',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
      title: '連假打算出國？',
      description: '遠遊卡吃到飽上網不再煩惱用量 ',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
      title: '本月影片免費看',
      description: '話題電影、日劇、韓劇等等應有盡有',
      link: '#',
    },
  ]
};

export const card1 = {
  title: '漫遊上網方案申請',
  description: '你目前沒有已申請或使用中的漫遊上網方案。',
  action: '立即申請',
};

export const card2 = {
  title: '旅日省錢大作戰，遠傳漫遊讓你瘋狂打卡不心痛！',
  description: '',
  action: '了解更多',
};

export const switchCard = {
  title: '漫遊通話設定',
  icon: '/resources/cbu//help-center/images/information-gray.svg',
  description: '申請漫遊上網後請保持開啟，以確保接收漫遊通知簡訊',
  tips: 'Tips Tips Tips Tips Tips',
  switch: [
    {
      on: '關閉',
      off: '開啟',
    },
    
  ]
};


export const serviceCard = {
  title: '更多漫遊資訊',
  cards: [
    {
      title: '各國漫遊費率',
      description: '',
      link: '#',
    },
    {
      title: '漫遊優惠',
      description: '',
      link: '#',
    },
    {
      title: '機上漫遊費率',
      description: '',
      link: '#',
    },
    {
      title: '漫遊小叮嚀',
      description: '',
      link: '#',
    },
  ]
};


export const faqTab = {
  title: '常見問題',
  tabs: [
    { label: '漫遊上網促銷' },
    { label: '漫遊語音/上網費' },
    { label: '漫遊通話/上網設定' },
  ],
  collapseList: [
    [
      {
        title: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
        content: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？'
      },
      {
        title: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
        content: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？'
      },
      {
        title: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
        content: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？'
      },
    ],
    [
      {
        title: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
        content: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？'
      },
      {
        title: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
        content: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？'
      },
      {
        title: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
        content: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？'
      },
    ],
    [
      {
        title: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
        content: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？'
      },
      {
        title: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
        content: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？'
      },
      {
        title: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
        content: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？'
      },
    ]
  ]
}

export const plan = {
  required: true,
  label: '',
  name: 'plan',
  default: '',
  className: '',
  options: [
    {
      value: '0',
      name: '0',
      title: '韓國漫遊吃到飽!',
      desc: '適合於出國需要看影片、打手機遊戲、進行直播，網路用量較多的你',
      target: '適用對象：遠傳電信月租型用戶', 
      country: '適用國家：韓國',
      tips: '',
      days: '',
      action: {
        text: '看詳細說明',
        link: '#',
      },
      unit: '日付',
      price: '99',
    },
    {
      value: '1',
      name: '1',
      title: '亞洲輕量包暢用型 1GB!',
      desc: '適合於出國僅需瀏覽網頁、地圖搜索、使用Line訊息/通話，網路用量較少的你',
      target: '適用對象：遠傳電信月租型用戶',
      country: '適用國家：新加坡、韓國、香港、澳門、馬來西亞、泰國等 13 國',
      tips: 'Tips Tips Tips',
      days: '最高使用天數：10 天',
      action: {
        text: '看詳細說明',
        link: '#',
      },
      unit: '1GB',
      price: '199',
    },
    {
      value: '2',
      name: '2',
      title: '亞洲輕量包暢用型 2GB!',
      desc: '適合於出國僅需瀏覽網頁、地圖搜索、使用Line訊息/通話，網路用量較少的你',
      target: '適用對象：遠傳電信月租型用戶',
      country: '適用國家：新加坡、韓國、香港、澳門、馬來西亞、泰國等 13 國',
      tips: '',
      days: '最高使用天數：10 天',
      action: {
        text: '看詳細說明',
        link: '#',
      },
      unit: '2GB',
      price: '259',
    },

  ]
};

export const flightRoamingPlan = {
  type: 'flight',
  required: true,
  label: '',
  name: 'plan',
  default: '',
  className: '',
  options: [
    {
      value: '0',
      name: '0',
      title: '機上漫遊吃到飽!',
      desc: '在機上就像在家一樣，一點都不無聊！上網、追劇、Email、LINE、玩手遊都可以！',
      target: '適用對象：遠傳電信月租型用戶',
      flightCompany: {
        name: 'AeroMobile',
        link: '#',
      }, 
      tips: '',
      days: '',
      action: {
        text: '看詳細說明',
        link: '#',
      },
      unit: '日付',
      price: '99',
    },
  
  ]
};


export const addPlan = {
  required: true,
  label: '',
  name: 'plan',
  default: '',
  className: '',
  options: [
    {
      value: '0',
      name: '0',
      title: '亞洲輕量包暢用型 500MB!',
      desc: '原方案的上網流量使用完畢後，將立即啟用此加購流量！',
      target: '適用對象：遠傳電信月租型用戶', 
      country: '適用國家：新加坡、韓國、香港、澳門、馬來西亞、泰國等 13 國',
      tips: 'Tips Tips Tips',
      days: '最高使用天數：10 天',
      action: {
        text: '看詳細說明',
        link: '#',
      },
      unit: '每500MB',
      price: '99',
    },
  ]
};

export const hot = {
  path: '/roamingPlan/Apply',
  label: '依地區搜尋',
  // hotword: [
  //   '亞洲',
  //   '美洲',
  //   '歐洲',
  //   '大洋洲',
  //   '中東',
  //   '非洲',
  // ],
  // active: [
  //   true,
  //   false,
  //   false,
  //   false,
  //   false
  // ],
  hotword: [
    { showName: '亞洲', value: 'asia',active: true },
    { showName: '美洲', value: 'america',active: false },
    { showName: '歐洲', value: 'urope',active: false },
    { showName: '大洋洲',value: 'australia', active: false },
    { showName: '中東', value: 'middleeast',active: false },
    { showName: '非洲', value: 'africa',active: false },
  ],
};

export const keyword = {
  defaultKeyword: [
    {showName: '巴西 Brasil', value: 'value'},
    {showName: '巴拉圭 Paraguay', value: 'value'},
    {showName: '巴貝多 Barbados', value: 'value'},
    {showName: '巴哈馬 Bahamas', value: 'value'},
    {showName: '巴拿馬 Panama', value: 'value'},
    {showName: '巴勒斯坦 Palestine', value: 'value'},
  ],
  path: '/roamingPlan/Apply',
  placeholder: '請選擇或輸入目的地',
};


export const menu = {
  tabs: [
    { label: '亞洲' },
    { label: '美洲' },
    { label: '歐洲' },
    { label: '非洲' },
    { label: '大洋洲 / 其他' },
  ],
  content: [
    {
      title: '亞洲',
      all: [
        {showName: '日本 Japan', value: 'value'},
        {showName: '韓國 Korea', value: 'value'},
        {showName: '中國 China', value: 'value'},
        {showName: '泰國 Thailand', value: 'value'},
        {showName: '菲律賓 Philippines', value: 'value'},
        {showName: '越南 Vietnam', value: 'value'},
        {showName: '柬埔寨 Cambodia', value: 'value'},
        {showName: '緬甸 Myanmar', value: 'value'},
        {showName: '印尼 Country', value: 'value'},
        {showName: '印度 Country', value: 'value'},
        {showName: '香港 Country', value: 'value'},
        {showName: '澳門 Country', value: 'value'},
      ],
    },
    {
      title: '美洲',
      all: [
        { showName: '美國 U.S.A', value: 'country'},
        { showName: '加拿大 Canada', value: 'country'},
        { showName: '墨西哥 Mexico', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
      ],
    },
    {
      title: '歐洲',
      all: [
        { showName: '法國 France', value: 'country'},
        { showName: '德國 Deutschland', value: 'country'},
        { showName: '英國 ChiBritainna', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
      ],
    },
    {
      title: '非洲',
      all: [
        { showName: '南非 South Africa', value: 'country'},
        { showName: '埃及 Egypt', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
      ],
    },
    {
      title: '大洋洲 / 其他',
      all: [
        { showName: '澳洲 Australia', value: 'country'},
        { showName: '紐西蘭 New Zealand', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
        { showName: '國家 Country', value: 'country'},
      ],
    },
  ],
}