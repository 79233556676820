import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import AdaptHeightBanner from '../../components/partials/banner/AdaptHeightBanner';
import Tab from '../../components/tab/Tab';
import TabPane from '../../components/tab/TabPane';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import Select from '../../components/form/Select';
import Tooltip from '../../components/Tooltip';
import Breadcrumb from '../../components/Breadcrumb';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import RadioGroup from '../../components/form/RadioGroup';
import TextInput from '../../components/form/TextInput';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';

import * as Validation from '../../utils/validation';
class PaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef()
    this.creditGroup = React.createRef();
    this.state = {
      currentTab: 0
    }
  }
  onChange = (name, value) => {
    // console.log({ name, value });
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }
  enableButton = (val) => {
    // console.log(`val: `, val);
    this.setState({
      [val]: true
    })
  };
  disableButton = (val) => {
    this.setState({
      [val]: false
    })
  };
  selectPayment = (val) => {
    this.setState({
      selectPayment: val
    })
  }
  changeTab = (value) => {
    this.setState({
      currentTab: value
    })
  }
  render() {
    return (
      <main>
        <Header />
        <NavAnchor
          pageTitle='繳費方式'
          button={{
            text: '回個人專區',
            link: '#',
          }}
        />
        <Breadcrumb
          color='black'
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/cbu/' },
            { text: '繳費方式', link: '/cbu/payment-info' },
          ]}
        />
        <AdaptHeightBanner
          height={{
            desktop: 360,
            mobile: 180
          }}
          bgImg={{
            md: '/resources/cbu/img/cbu-payExplanation-banner-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-payExplanation-banner-375x200.jpg.jpg',
          }}
          title='<div class="is-text-black100">繳費方式</div>'
        />
        <section className="ribbon-bg pb-md-7 pb-10">
          <div className='fui-container'>
            <h4 className="is-text-darkgray50">
              遠傳帳單可以繳費的方式這裡都有，您可了解各種繳費方式，依照自己的方便及需求，進行帳單繳費！設定自動轉帳好康多更多~語音/網站/APP信用卡繳費不提供外國信用卡交易。
            </h4>
            <Tab
              name='login-tab'
              list={[
                { label: '線上繳費' },
                { label: '現金繳費' },
                { label: '手機撥打快速繳費' },
                { label: '轉帳設定繳費' },
              ]}
              onChange={this.changeTab}
            />

            <div className="mt-2">
              <TabPane active={this.state.currentTab === 0}>
                <CollapsePaper
                  title="遠傳網站信用卡／銀行繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h6 className='is-text-regular mt-0'>您可透過遠傳網站使用銀行及信用卡兩種方式線上繳費，繳費步驟如下<br />
                        線上繳費方式如下：</h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>選擇扣款方式</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6 className=''>進入「帳單與繳費」/「繳費服務」</h6>
                        </li>
                        <li className='mb-4'>
                          <div className="circle">3</div>
                          <h6 className=''>選擇「我要繳費」即可使用銀行帳號 /信用卡進行繳費。</h6>
                        </li>
                      </ul>
                      <div className="page-action mb-4">
                        <Button btnStyle='primary' size='large' link={'//'} >我要繳費</Button>
                      </div>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="遠傳行動客服APP繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h5 className='mt-0 mb-6'>遠傳行動客服APP繳費</h5>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>請登入遠傳行動客服APP，點選首頁中的「立即繳費」。</h6>
                        </li>
                        <li className='mb-0 pb-0'>
                          <div className="circle">2</div>
                          <h6 className=''>選擇銀行帳號、信用卡或持手機繳費條碼至門市、便利商店繳費。</h6>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="遠東商銀繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h5 className='mt-0 mb-6'>遠東商銀繳費</h5>
                      <h6 className='is-text-regular mt-0'>若您為遠傳月租型個人用戶且為遠東商銀存簿客戶者，可透過遠東商銀網路銀行繳納帳單。<br />
                      遠東商銀網路銀行繳費操作方式：</h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>連結至 <a href="#" className="info-link">遠東商銀網路銀行</a> -->點選「轉帳類」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6 className=''>點選「繳遠傳電信費用」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">3</div>
                          <h6 className=''>點選「轉出帳號」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">4</div>
                          <h6 className=''>輸入「您的行動電話號碼」共10碼</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">5</div>
                          <h6 className=''>輸入您的「累計應繳金額」</h6>
                        </li>
                        <li className='mb-0'>
                          <div className="circle">6</div>
                          <h6 className=''>請檢查以上數字無誤按「確定」完成繳費作業</h6>
                        </li>
                      </ul>
                      <ul className="green-dot-list">
                        <li>免手續費：轉帳手續費0元</li>
                        <li>不適用對象：已停機或帳戶移轉一退一租者</li>
                        <li>入帳時間：	營業日下午3:30之前轉帳，當日入帳。營業日下午3:30後、週六、週日或例假日轉帳，次一營業日入帳。(若銀行檔案資料有誤當日無法處理，則次營業日入帳)。</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="台新銀行網路繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h5 className='mt-0 mb-6'>台新銀行網路繳費</h5>
                      <h6 className='is-text-regular mt-0'>若您為遠傳月租型個人用戶且為台新銀行存簿/信用卡客戶者，可透過台新銀行繳納帳單。<br />
                      台新銀行繳費操作方式：</h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>連結至台新銀行 <a href="#" className="info-link">繳費/支付平台</a>（於台新銀行首頁點選右下方「繳費/支付平台」）</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6 className=''>點選「電信費用」→「遠傳電信」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">3</div>
                          <h6 className=''>選擇「信用卡」或「活期帳戶繳費」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">4</div>
                          <h6 className=''>輸入「認證資訊」- 身分證字號及台新銀行所提供的「使用者代號」及「使用者密碼」登入認證</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">5</div>
                          <h6 className=''>輸入繳費資訊</h6>
                        </li>
                        <li className='mb-0'>
                          <div className="circle">6</div>
                          <h6 className=''>確認繳費資訊，完成繳費</h6>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="國泰世華行動銀行繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h5 className='mt-0 mb-6'>國泰世華行動銀行繳費</h5>
                      <h6 className='is-text-regular mt-0'>若您為遠傳月租型個人用戶且為國泰世華銀行存簿/信用卡客戶者，可利用手機上網連接至國泰世華行動銀行繳納帳單。<br />
                      國泰世華行動銀行繳費操作方式：</h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>手機進入 <a href="#" className="info-link">國泰世華行動銀行</a></h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6 className=''>點選「線上櫃檯線上繳費MyBill」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">3</div>
                          <h6 className=''>選擇「電信費類的遠傳電信」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">4</div>
                          <h6 className=''>選擇使用「活期帳戶或信用卡繳費」輸入門號及身份證號(或網銀會員完成認證)</h6>
                        </li>
                        <li className='mb-0'>
                          <div className="circle">5</div>
                          <h6 className=''>查詢待繳帳單完成繳費作業</h6>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="花旗銀行網路繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h5 className='mt-0 mb-6'>花旗銀行網路繳費</h5>
                      <h6 className='is-text-regular mt-0'>若您為遠傳月租型個人用戶且為花旗銀行信用卡客戶，可透過花旗銀行網路繳納帳單。<br />
                      花旗銀行網路繳費操作方式：</h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>進入 <a href="#" className="info-link">花旗銀行網路繳費</a>網頁。</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6 className=''>首次使用者，需作「新會員註冊」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">3</div>
                          <h6 className=''>註冊為會員後，需設定「我的繳費名單」</h6>
                        </li>
                        <li className='mb-0'>
                          <div className="circle">4</div>
                          <h6 className=''>至「花旗銀行網路繳費」網頁繳費，即可完成繳費作業</h6>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="網路ATM繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h5 className='mt-0 mb-6'>網路ATM繳費</h5>
                      <h6 className='is-text-regular mt-0'>您可至網路ATM 繳納帳單，轉帳手續費 $ 0 元！<br />
                      線上繳費方式如下：</h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>請準備晶片讀卡機與任何銀行晶片金融卡</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6 className=''>點選 <a href="#" className="info-link">Web ATM快速繳費服務</a>(預付卡儲值：請點選「線上儲值」進行儲值作業)</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">3</div>
                          <h6 className=''>輸入帳號「您的行動電話號碼」共10碼（企業客戶請輸入十碼客戶帳號）</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">4</div>
                          <h6 className=''>輸入您的「累計應繳金額」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">5</div>
                          <h6 className=''>插入金片金融卡並輸入「個人密碼」</h6>
                        </li>
                        <li className='mb-0'>
                          <div className="circle">6</div>
                          <h6 className=''>檢查以上數字無誤按「確定」完成手續</h6>
                        </li>
                      </ul>
                      <ul className="green-dot-list">
                        <li>免手續費：遠傳用戶持任何銀行晶片金融卡繳費，轉帳手續費 $ 0 元。</li>
                        <li>不適用對象：已停機或帳戶移轉一退一租者。</li>
                        <li>入帳時間：	營業日下午3:30之前轉帳，當日入帳。營業日下午3:30後、週六、週日或例假日轉帳，次一營業日入帳。(若銀行檔案資料有誤當日無法處理，則次營業日入帳)。</li>
                        <li>列印收據：交易完成後請列印交易明細表至確認入帳，收據上若無交易訊息即代表交易已成功</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="中國信託行動銀行繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h5 className='mt-0 mb-6'>中國信託行動銀行繳費</h5>
                      <h6 className='is-text-regular mt-0'>若您為遠傳月租型個人用戶且為中國信託銀行存簿/信用卡客戶者，可利用手機上網連接至中國信託行動銀行繳納帳單。<br />
                      中國信託行動銀行繳費操作方式：</h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>手機進入 <a href="#" className="info-link">中國信託行動銀行</a>網頁。</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6>點選「帳戶交易」，登錄輸入「認證資訊」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">3</div>
                          <h6 className=''>進入帳戶交易「繳費」，輸入繳費資訊</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">4</div>
                          <h6 className=''>選擇「繳納帳單」並點選「繳費方式」</h6>
                        </li>
                        <li className='mb-0'>
                          <div className="circle">5</div>
                          <h6 className=''>確認繳費訊息，並再次進行「密碼驗證」即可完成繳費作業</h6>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="自動提款機繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h5 className='mt-0 mb-6'>自動提款機繳費</h5>
                      <h6 className='is-text-regular mt-0'>您可至全省任何有自動化服務跨行轉帳之提款機繳納帳單。<br />
                      轉帳操作方式如下：</h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>請插入您的金融卡並輸入「個人密碼」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6>選擇「轉帳」功能（選擇「其它服務或金額」→「他行轉帳」或「本行轉帳」；若您是郵局用戶，請再選擇「非約定帳戶」）</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">3</div>
                          <h6 className=''>行庫代號輸入遠東銀行代號「805」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">4</div>
                          <h6 className=''>輸入帳號「您的行動電話號碼」共10碼（企業客戶請輸入十碼客戶帳號）</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">4</div>
                          <h6 className=''>輸入您的「累計應繳金額」</h6>
                        </li>
                        <li className='mb-0'>
                          <div className="circle">5</div>
                          <h6 className=''>檢查以上數字無誤按「確定」完成手續</h6>
                        </li>
                      </ul>
                      <ul className="green-dot-list">
                        <li>手續費：跨行轉帳手續費 $15元，需由用戶自行負擔。</li>
                        <li>不適用對象：已停機或帳戶移轉一退一租者。</li>
                        <li>入帳時間：	營業日下午3:30之前轉帳，當日入帳。營業日下午3:30後、週六、週日或例假日轉帳，次一營業日入帳。(若銀行檔案資料有誤當日無法處理，則次營業日入帳)。</li>
                        <li>列印收據：交易完成後請列印交易明細表至確認入帳，收據上若無交易訊息即代表交易已成功</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
              </TabPane>
              <TabPane active={this.state.currentTab === 1}>
                <CollapsePaper
                  title="遠傳直營門市"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h6 className='is-text-regular mt-0'>您可使用下列任一方式輕鬆繳費：</h6>
                      <ul className="green-dot-list">
                        <li>出帳簡訊通知的「繳費條碼圖片」</li>
                        <li>持紙本帳單</li>
                        <li>現場補單機列印繳費聯</li>
                        <li>遠傳行動客服App「手機繳費條碼」</li>
                      </ul>
                      <h5>提供下列支付工具：</h5>
                      <ul className="green-dot-list">
                        <li>現金</li>
                        <li>happycash(有錢卡)</li>
                        <li>信用卡繳費，信用卡接受VISA、MasterCard、JCB</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="遠傳加盟門市"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h6 className='is-text-regular mt-0'>您可使用下列任一方式輕鬆繳費：</h6>
                      <ul className="green-dot-list">
                        <li>持紙本帳單</li>
                        <li>現場補單機列印繳費聯</li>
                      </ul>
                      <h5>提供下列支付工具：</h5>
                      <ul className="green-dot-list">
                        <li>現金</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="全省便利商店"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <table className="text-wrap gray-th">
                        <tbody>
                          <tr>
                            <th>7-11 統一超商</th>
                            <th>全家便利超商</th>
                            <th>萊爾富</th>
                            <th>OK超商</th>
                          </tr>
                          <tr>
                            <td colSpan="4">憑出帳簡訊通知的「繳費條碼圖片」 繳費即時入帳！</td>
                          </tr>
                          <tr>
                            <td colSpan="4">使用遠傳行動客服APP的「手機繳費條碼」直接臨櫃繳費，不再需列印繳費單！</td>
                          </tr>
                          <tr>
                            <td>ibon機台列印繳費單</td>
                            <td>FamiPort機台列印繳費單</td>
                            <td>Life-ET機台列印繳費單</td>
                            <td>OK.go機台列印繳費單</td>
                          </tr>
                          <tr>
                            <td colSpan="4">持紙本帳單臨櫃繳費</td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="郵局劃撥"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>請攜帶紙本帳單所附之繳費聯至全省郵局劃撥櫃檯繳付帳款即可，無需填寫劃撥單。</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6>若無繳費單，您亦可填寫劃撥單繳費 ，帳號為19037959 ，戶名為「遠傳電信股份有限公司」</h6>
                        </li>
                        <li className='mb-0'>
                          <div className="circle">3</div>
                          <h6 className=''>並於劃撥單上之通訊欄處寫上您的客戶帳號（共10位數）</h6>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="支票繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''> 請使用即期、劃線並禁止背書轉讓之支票，抬頭寫明「遠傳電信股份有限公司」並請在支票背面註明客戶帳號(共10位)。(如有多份帳單可合併開立支票繳費，並註明每一筆繳費門號「或客戶帳號」及金額資料且合計繳費金額需與支票金額相符)</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6>請於繳費期限的前五天，將支票連同帳單所附之繳費聯， 一併以掛號信函寄至「 板橋郵政14 - 341 號信箱帳務服務管理處」， 遠傳將於支票兌現後入帳。</h6>
                        </li>
                        <li className='mb-0'>
                          <div className="circle">3</div>
                          <h6 className=''>繳交支票之客戶如需收據可於信封內附上簽收聯，遠傳即會蓋章寄回。</h6>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
              </TabPane>
              <TabPane active={this.state.currentTab === 2}>
                <CollapsePaper
                  title="銀行帳號語音繳費"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h6 className='is-text-regular mt-0'>以手機直撥 123 (免費) 或 市話撥打449-5888(付費) 利用語音系統，使用門號持有人銀行帳號存款繳納帳單。<br />
                      銀行語音繳費操作方式：
                      </h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>手機撥 123</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6>按１查詢帳單金額及繳費</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">3</div>
                          <h6>輸入身份證後四碼認證</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">4</div>
                          <h6>按８選擇銀行轉帳繳費</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">5</div>
                          <h6>輸入欲繳納的行動電話號碼</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">6</div>
                          <h6>輸入銀行代號</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">7</div>
                          <h6>輸入銀行帳號</h6>
                        </li>
                        <li className='mb-0'>
                          <div className="circle">8</div>
                          <h6 className=''>交易成功語音訊息</h6>
                        </li>
                      </ul>
                      <ul className="green-dot-list">
                        <li>免手續費：轉帳手續費0元</li>
                        <li>不適用對象：已停機或帳戶移轉一退一租者</li>
                        <li>入帳時間：	營業日下午3:30之前轉帳，當日入帳。營業日下午3:30後、週六、週日或例假日轉帳，次一營業日入帳。(若銀行檔案資料有誤當日無法處理，則次營業日入帳)。</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="信用卡語音轉帳"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h6 className='is-text-regular mt-0'>可以手機直撥 123 (免費) 或 市話撥打449-5888(付費) 利用語音系統，以信用卡繳納帳單，可使用信用卡類別為VISA、MASTER、JCB、聯合信用卡(U卡)。<br />
                      信用卡語音繳費操作方式：</h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>手機撥 123</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6>查詢帳單繳費，請按1</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">3</div>
                          <h6>輸入身份證後四碼認證</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">4</div>
                          <h6>使用信用卡繳費，請按1</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">5</div>
                          <h6>輸入欲繳納的行動電話號碼</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">6</div>
                          <h6>輸入信用卡卡號</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">7</div>
                          <h6>輸入信用卡有效期限</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">8</div>
                          <h6>輸入信用卡背面簽名欄後驗證碼末三碼</h6>
                        </li>
                        <li className='mb-0 pb-0'>
                          <div className="circle">9</div>
                          <h6 className=''>交易成功</h6>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
              </TabPane>
              <TabPane active={this.state.currentTab === 3}>
                <CollapsePaper
                  title="設定自動扣繳"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h6 className='is-text-regular mt-0'>您可點選申請自動扣款服務，以郵局帳戶、銀行帳戶、信用卡設定自動轉帳扣繳。<br />
                      自動扣繳操作方式：
                      </h6>
                      <ul className="step-list mt-0">
                        <li className='pb-md-6'>
                          <div className="circle">1</div>
                          <h6 className=''>至FETnet「網路客服」網頁</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">2</div>
                          <h6>進入「繳費/儲值服務」/「申請自動扣款」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">3</div>
                          <h6>選擇「扣款方式」</h6>
                        </li>
                        <li className='pb-md-6'>
                          <div className="circle">4</div>
                          <h6>確認資料</h6>
                        </li>
                        <li className='mb-0 pb-0'>
                          <div className="circle">5</div>
                          <h6 className=''>填寫資料，申請完成</h6>
                        </li>
                      </ul>
                      <h6 className='is-text-regular mb-0'>本公司將於每月繳費截止日自您指定的帳戶中扣除當月應繳之電信費用。<br />
                      提醒您，若您的門號已有辦理信用卡自動轉帳，遠傳將會針對到期卡自動展延有效期，繳費前請先確認是否已完成自動轉帳扣款，避免您重複繳費，謝謝。</h6>
                    </Grid>
                  </Grid>
                </CollapsePaper>
              </TabPane>
            </div>
          </div>
        </section>
        <SectionCarousel1
          title='你的專屬優惠'
          more={null}
          bgStyle=""
          cards={[
            {
              image: process.env.PUBLIC_URL + '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
              title: '本月影片免費看!',
              description: '話題電影、日劇、韓劇等等應有盡有',
              link: '#',
            },
            {
              image: process.env.PUBLIC_URL + '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
              title: '中午不知道吃什麼？',
              description: 'Uber Eats 送遠傳新客優惠金200元',
              link: '#',
            },
            {
              image: process.env.PUBLIC_URL + '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
              title: '連假打算出國？',
              description: '遠遊卡吃到飽上網不再煩惱用量 ',
              link: '#',
            },
            {
              image: process.env.PUBLIC_URL + '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
              title: '本月影片免費看!',
              description: '話題電影、日劇、韓劇等等應有盡有',
              link: '#',
            },
          ]}
        />
      </main>
    );
  }
}

export default PaymentInfo;