import React, { Component } from 'react';
import PropTypes from 'prop-types';
class Ruler extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div className="multi-ruler">
        <h4 className='mb-1'>{this.props.title}</h4>
        <div className={`fui-ruler main`}>
          {this.props.analysis.map((item, i) => {
            return (
              <div
                className="percentage"
                key={i}
                style={{ width: (item.percentage + '%'), backgroundColor: item.color }}
              >
                {i === 0 ? <div style={{ backgroundColor: item.color }} className='first-block' /> : null}
                {i === this.props.analysis.length - 1 ? <div style={{ backgroundColor: item.color }} className='last-block' /> : null}
                <span className={item.percentage == 0 ? 'zero' : ''} />
              </div>
            )
          })}
        </div>
        <div className={`fui-ruler number-block mt-2`}>
          {this.props.analysis.map((item, i) => {
            return (
              <div
                className="percentage"
                key={i}
                style={{ width: (item.percentage + '%'), marginLeft: item.percentage < 3 ? 0 : 20 }}
              >
                <div>{item.percentage}%</div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

Ruler.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  percentage: PropTypes.number,
}

export default Ruler;