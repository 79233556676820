import React, { Component } from 'react'
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import Button from '../../components/Button';
import RadioGroup from '../../components/form/RadioGroup';
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Select from '../../components/form/Select';
import Pagination from '../../components/paginate/Pagination';
import formatNumber from '../../utils/numberFormatter';
import RadioCarousel from '../../components/form/RadioCarousel';
import * as Validation from '../../utils/validation';
class ChangeNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        wish_number: { value: '', required: false },
        numberPrefix: { value: '不限', required: false },
        hasFour: { value: 'true', required: false },
        id_number: { value: '', required: false },
        selected_number: { value: '', required: false },
      },
      tags: ['0903開頭', '價格不限', '不排除4', '希望數字3'],
      canSubmit: false,
      step: 1,
      numbers: [
        {
          value: '0903-063482',
          label: '0903-063482'
        },
        {
          value: '2',
          label: '2',
          price: 1000
        },
        {
          value: '3',
          label: '3',
          price: 1000
        },
        {
          value: '4',
          label: '4',
          price: 1000
        },
        {
          value: '5',
          label: '5',
          price: 1000
        },
        {
          value: '5',
          label: '5',
          price: 1000
        },
        {
          value: '3',
          label: '3',
          price: 1000
        },
        {
          value: '4',
          label: '4',
          price: 1000
        },
        {
          value: '5',
          label: '5',
          price: 1000
        },
        {
          value: '5',
          label: '5',
          price: 1000
        },
        {
          value: '0903-063482',
          label: '0903-063482'
        },
        {
          value: '2',
          label: '2',
          price: 1000
        },
        {
          value: '3',
          label: '3',
          price: 1000
        },
        {
          value: '4',
          label: '4',
          price: 1000
        },
        {
          value: '5',
          label: '5',
          price: 1000
        },
        {
          value: '5',
          label: '5',
          price: 1000
        },
        {
          value: '3',
          label: '3',
          price: 1000
        },
        {
          value: '4',
          label: '4',
          price: 1000
        },
        {
          value: '5',
          label: '5',
          price: 1000
        },
        {
          value: '5',
          label: '5',
          price: 1000
        },
        {
          value: '5',
          label: '5',
          price: 1000
        },
      ],
      selected: ''
    }
  }
  onChange = (name, value) => {
    // console.log(name, value);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    this.setState({
      form: newForm
    })
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  submit = () => {
    console.log('submit');
  }
  onChangePage(pageOfItems, selectedPage) {
    // this.props.history.push(`${this.props.location.pathname}?keyword=${this.state.keyword}&page=${selectedPage}`);

    // update page of items
    // this.updatePageOfItems(pageOfItems);
    // console.log('on change');
  }
  handleChange = (e, opt) => {
    console.log({ e, opt });
    // this.props.setValue(e.currentTarget.value);
    // this.props.onChange(this.props.name, e.currentTarget.value, opt);
  };
  inputChange = (n, v, form) => {
    let newForm = Object.assign(this.state[form]);
    newForm[n].value = v;

    this.setState({
      [form]: newForm,
    });
  };
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="預約門市換號"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="no-bg pt-md-7 pt-4 pb-md-7 pb-4">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-md-7">此服務提供您依搜尋條件挑選中意的門號，為您預約換號服務。</h4>
            {this.state.step === 1 ? <div>
              <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                <div className='paper mb-md-7 mb-4 pb-md-4 pb-0'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <h4 className="mt-md-4 mb-md-3 mb-0">門號選擇條件</h4>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="form-group mb-md-7">
                        <label>門號開頭</label>
                        <Select
                          name="numberPrefix"
                          className='w-100'
                          required={this.state.form.numberPrefix.required}
                          label={"門號開頭"}
                          value={this.state.form.numberPrefix.value}
                          options={[
                            { text: '不限', value: '不限' },
                            { text: '1', value: '1' },
                            { text: '2', value: '2' },
                          ]}
                          onChange={this.onChange} />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="form-group mb-md-7">
                        <label>門號價格</label>
                        <Select
                          name="numberPrefix"
                          className='w-100'
                          required={this.state.form.numberPrefix.required}
                          label={"不限"}
                          value={this.state.form.numberPrefix.value}
                          options={[
                            { text: '不限', value: '不限' },
                            { text: '1', value: '1' },
                            { text: '2', value: '2' },
                          ]}
                          onChange={this.onChange} />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <LabelInput
                        label="希望數字"
                        name='wish_number'
                        placeholder="請填入希望有的門號數字"
                        required={this.state.form.wish_number.required}
                        value={this.state.form.wish_number.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <RadioGroup
                        label='是否希望排除數字「4」'
                        name="hasFour"
                        className='mb-2'
                        options={[
                          { label: '排除', value: 'true' },
                          { label: '不排除', value: 'false' }
                        ]}
                        default={this.state.form.hasFour.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className='page-action'>
                  <Button
                    onClick={() => this.setState({ step: 2 })}
                    link="#"
                    className='mb-lg-0 mb-md-2 mb-0'
                    btnStyle='primary'
                    size='large'>
                    查詢
                </Button>
                </div>
              </Formsy>
            </div> :
              <div>
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                  <div className='paper mb-md-7 pb-md-4 pb-0'>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <h4 className="mt-md-4 mb-md-2 mb-2 d-inline-block mr-4">選擇門號</h4>
                        <div className="tag-list">
                          {this.state.tags.map((item, i) => {
                            return (
                              <span key={item + i} className="tag">{item}</span>
                            )
                          })}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <RadioCarousel
                          numbers={this.state.numbers}
                          value={this.state.form.selected_number.value}
                          name="selected_number"
                          onChange={(n, v) => this.inputChange(n, v, 'form')}
                        />
                      </Grid>
                      <div className="d-block w-100">
                        <Pagination
                          items={['1', '2', '3', '4']}
                          onChangePage={this.onChangePage}
                          initialPage={1}
                          className='mt-md-3 mt-2 mb-md-6 mb-3'
                          pageSize={2} />
                      </div>
                    </Grid>

                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                      <LabelInput
                        className="mb-2 hide-required"
                        name='id_number'
                        required={true}
                        label='證照號碼'
                        validations={{
                          idNumberValid: Validation.idNumberValid
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入證照號碼'
                        }}
                        placeholder='請輸入證照號碼'
                        value={this.state.form.id_number.value}
                        onChange={this.onChange}
                      />
                      <p className="body-1 is-text-darkgray50 mt-0 mb-3">
                        身分說明：<br />
                        個人戶：身分證字號<br />
                        公司戶：統一編號或稅籍編號<br />
                        外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                      </p>
                      <div className="page-action">
                        <Button
                          onClick={() => { this.setState({ login: true }) }}
                          link="#"
                          className={this.state.canSubmit ? '' : 'disabled'}
                          btnStyle='primary'
                          size='large'>
                          確認
                      </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Formsy>
              </div>
            }
          </div>
        </section>
      </main>
    );
  }
}

export default ChangeNumber;