import React, { Component } from 'react'
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Card from '../../components/card/Card';
import Breadcrumb from '../../components/Breadcrumb';
import FormBanner from '../../components/partials/banner/FormBanner';
import { Grid } from '@material-ui/core';
import Dropdown from '../../components/Dropdown';
import Link from '../../components/Link';
import InstructionItems from '../../components/partials/InstructionItems';
import InstructionList from '../../components/partials/InstructionList'
import CardProductInstruction from '../../components/partials/CardProductInstruction'
// import * as Mock from '../../mock/AddValue.js';
class Instruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instructionSelect: '',
      instructionSelectList: [
        { value: '上網設定', text: '上網設定' },
        { value: '安全隱私', text: '安全隱私' },
        { value: '防災應用', text: '防災應用' },
        { value: '數據功能', text: '數據功能' },
        { value: '功能設定', text: '功能設定' }
      ],
      funcLoad: true,
      func: [
        {
          name: '鈴聲設定',
          link: '#'
        },
        {
          name: '語言設定',
          link: '#'
        },
        {
          name: '雙卡管理',
          link: '#'
        },
        {
          name: '關閉背景程式',
          link: '#'
        },
        {
          name: '切換輸入法',
          link: '#'
        },
        {
          name: '增加輸入法',
          link: '#'
        },
      ],
      dataLoad: true,
      data: [
        {
          name: '軟體升級',
          link: '#'
        },
        {
          name: '藍牙設定',
          link: '#'
        },
        {
          name: '流量提醒及設定',
          link: '#'
        },
        {
          name: '查詢儲存空間',
          link: '#'
        },
        {
          name: '隨身碟模式',
          link: '#'
        },
        {
          name: '刪除網頁記錄',
          link: '#'
        },
      ],
      applicationLoad: true,
      application: [
        {
          name: '切換省電模式',
          link: '#'
        },
        {
          name: '調整螢幕亮度',
          link: '#'
        },
        {
          name: '調整螢幕關閉時間',
          link: '#'
        },
        {
          name: '開啟手電筒',
          link: '#'
        },
        {
          name: '緊急求救功能',
          link: '#'
        },
        {
          name: '查詢自己 GPS 位置',
          link: '#'
        },
      ],
      internetSettingLoad: true,
      internetSetting: [
        {
          name: '數據機模式',
          link: '#'
        },
        {
          name: '行動上網設定',
          link: '#'
        },
        {
          name: 'Wi-Fi上網設定',
          link: '#'
        },
        {
          name: 'Wi-Fi熱點設定',
          link: '#'
        },
        {
          name: '電子郵件設定',
          link: '#'
        },
        {
          name: '多媒體訊息設定',
          link: '#'
        },
      ],
      privateSettingLoad: true,
      privateSetting: [
        {
          name: '資料備份',
          link: '#'
        },
        {
          name: '資料回復',
          link: '#'
        },
        {
          name: '開關PIN碼',
          link: '#'
        },
        {
          name: '更改PIN碼',
          link: '#'
        },
        {
          name: '解除PUK碼',
          link: '#'
        },
        {
          name: '開啟螢幕鎖定',
          link: '#'
        },
      ],
      currentType: '',
      currentBrand: '',
      currentModel: '',
      typeList:
        [
          { value: '0', text: '請選擇類別' },
          { value: 'phone', text: '手機' },
          { value: 'pad', text: '平板' },
          { value: 'router', text: '網卡與路由器' },
          { value: 'smart', text: '智慧物聯網' },
        ],
      brandList: [
        {
          value: "0",
          text: "請選擇品牌",
        },
        {
          value: "ASUS",
          text: "ASUS",
        },
        {
          value: "Apple",
          text: "Apple",
        },
        {
          value: "Dynalink",
          text: "Dynalink",
        },
        {
          value: "FarEastone",
          text: "FarEastone",
        },
        {
          value: "Google",
          text: "Google",
        },
        {
          value: "Guider",
          text: "Guider",
        },
        {
          value: "HTC",
          text: "HTC",
        },
        {
          value: "HUAWEI",
          text: "HUAWEI",
        },
        {
          value: "INHON",
          text: "INHON",
        },
        {
          value: "InFocus",
          text: "InFocus",
        },
        {
          value: "Touch",
          text: "Touch",
        },
        {
          value: "Koobee",
          text: "Koobee",
        },
        {
          value: "LG",
          text: "LG",
        },
        {
          value: "Lenovo",
          text: "Lenovo",
        },
        {
          value: "Meitu",
          text: "Meitu",
        },
        {
          value: "NOKIA",
          text: "NOKIA",
        },
        {
          value: "Nextbit",
          text: "Nextbit",
        },
        {
          value: "OPPO",
          text: "OPPO",
        },
        {
          value: "Omate",
          text: "Omate",
        },
        {
          value: "Panasonic",
          text: "Panasonic",
        },
        {
          value: "Razer",
          text: "Razer",
        },
        {
          value: "SAMSUNG",
          text: "SAMSUNG",
        },
        {
          value: "SHARP",
          text: "SHARP",
        },
        {
          value: "SUGAR",
          text: "SUGAR",
        },
        {
          value: "Sony",
          text: "Sony",
        },
        {
          value: "WIZ",
          text: "WIZ",
        },
        {
          value: "Wingsland",
          text: "Wingsland",
        },
        {
          value: "Xiaomi",
          text: "Xiaomi",
        },
        {
          value: "ZTE",
          text: "ZTE"
        }
      ],
      modelList: [],
      pool: [
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-1.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'Xiaomi',
          model: 'Note 8T 64GB',
          text: 'Xiaomi 紅米 Note 8T 64GB',
          link: '#123'
        },
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-2.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'SAMSUNG',
          model: 'S20 5G',
          text: 'SAMSUNG Galaxy S20 5G',
          link: '#234'
        },
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-3.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'Apple',
          model: 'iPhone 11 Pro Max 256GB',
          text: 'iPhone 11 Pro Max 256GB',
          link: '#1111'
        },
      ],
      phone: [
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-1.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'Xiaomi',
          model: 'Note 8T 64GB',
          text: 'Xiaomi 紅米 Note 8T 64GB',
          link: '#123'
        },
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-2.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'SAMSUNG',
          model: 'S20 5G',
          text: 'SAMSUNG Galaxy S20 5G',
          link: '#234'
        },
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-3.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'Apple',
          model: 'iPhone 11 Pro Max 256GB',
          text: 'iPhone 11 Pro Max 256GB',
          link: '#1111'
        },
        {
          img: {
            url: 'resources/cbu/img/estore-product-phone-4.jpg',
            alt: 'img'
          },
          type: 'phone',
          brand: 'SAMSUNG',
          model: 'S20 Ultra 5G',
          text: 'SAMSUNG Galaxy S20 Ultra 5G',
          link: '#222'
        },
      ],
      pad: [
        {
          img: {
            url: 'resources/cbu/img/tab-1.jpg',
            alt: 'img'
          },
          type: 'pad',
          brand: 'SAMSUNG',
          model: 'Tab 4',
          text: 'SAMSUNG Galaxy Tab 4',
          link: '#123'
        },
        {
          img: {
            url: 'resources/cbu/img/tab-2.jpg',
            alt: 'img'
          },
          type: 'pad',
          brand: 'MediaPad',
          model: 'T2 8 Pro',
          text: 'MediaPad T2 8 Pro',
          link: '#234'
        },
        {
          img: {
            url: 'resources/cbu/img/tab-3.jpg',
            alt: 'img'
          },
          type: 'pad',
          brand: 'SAMSUNG',
          model: 'Tab A 8.0',
          text: 'SAMSUNG Galaxy Tab A 8.0',
          link: '#1111'
        },
        {
          img: {
            url: 'resources/cbu/img/tab-4.jpg',
            alt: 'img'
          },
          type: 'pad',
          brand: 'Omate',
          model: 'T800A',
          text: 'Omate T800A',
          link: '#222'
        },
      ],
      router: [
        {
          img: {
            url: 'resources/cbu/img/router-1.jpg',
            alt: 'img'
          },
          type: 'router',
          brand: 'HUAWEI',
          model: 'B818-263無線路由器',
          text: 'B818-263無線路由器',
          link: '#123'
        },
        {
          img: {
            url: 'resources/cbu/img/router-2.jpg',
            alt: 'img'
          },
          type: 'router',
          brand: 'FarEastone',
          model: 'FET LRT200',
          text: 'FarEastone FET LRT200',
          link: '#234'
        },
        {
          img: {
            url: 'resources/cbu/img/router-3.jpg',
            alt: 'img'
          },
          type: 'router',
          brand: 'HUAWEI',
          model: 'B315s 4G 無線路由器(白)',
          text: 'HUAWEI B315s 4G 無線路由器(白)',
          link: '#1111'
        },
        {
          img: {
            url: 'resources/cbu/img/router-4.jpg',
            alt: 'img'
          },
          type: 'router',
          brand: 'Dynalink',
          model: 'RTL0031 無線路由器',
          text: 'Dynalink RTL0031 無線路由器',
          link: '#222'
        },
      ],
      smart: [
        {
          img: {
            url: 'resources/cbu/img/smart-1.jpg',
            alt: 'img'
          },
          type: 'smart',
          brand: 'SAMSUNG',
          model: 'Gear Fit2',
          text: 'SAMSUNG Gear Fit2',
          link: '#123'
        },
        {
          img: {
            url: 'resources/cbu/img/smart-2.jpg',
            alt: 'img'
          },
          type: 'smart',
          brand: 'InFocus',
          model: 'W201 小衛星',
          text: 'InFocus W201 小衛星',
          link: '#234'
        },
        {
          img: {
            url: 'resources/cbu/img/smart-3.jpg',
            alt: 'img'
          },
          type: 'smart',
          brand: '4K空拍機Wingsland',
          model: 'Wingsland S6',
          text: '口袋型 GPS 4K空拍機Wingsland S6',
          link: '#1111'
        },
        {
          img: {
            url: 'resources/cbu/img/smart-4.jpg',
            alt: 'img'
          },
          type: 'smart',
          brand: 'FarEastone',
          model: 'BoBee',
          text: 'FarEastone BoBee 守護寶定位裝置',
          link: '#222'
        },
      ]
    }
  }
  componentDidMount() {
    this.resetModel()
  };
  resetModel = () => {
    let result = [
      // { value: '0', text: '請選擇型號' }
    ]
    let data = this.state.pool;
    data.map((item, i) => {
      return (
        result.push(
          {
            value: i,
            text: item.text,
            brand: item.brand,
            type: item.type,
            model: item.model
          })
      )
    })
    this.setState({
      modelList: result
    })
  }
  selectType = (value, i) => {
    console.log(value, i);
    // call APIs
    let result = [
      // { value: '0', text: '請選擇型號' }
    ]
    let brand = this.state.currentBrand
    let data = this.state.modelList.length !== 0 ? this.state.modelList : this.state.pool;
    // let pool = this.state.pool;

    if (value !== '0') {
      let filter = data.filter((item) => {
        // debugger
        // console.log(`data: `, data);
        return (item.type == i.value)
      }).filter((item) => {
        // debugger
        if (!!brand) {
          return (item.brand == brand)
        } else {
          return true
        }
      })
      filter.map((item, index) => {
        return (
          result.push(
            {
              value: i,
              text: item.text,
              brand: item.brand,
              type: item.type,
              model: item.model
            })
        )
      })
      this.setState({
        currentType: i.text,
        modelList: result,
        currentModel: ''
      })
    } else {
      this.resetModel()
      this.setState({
        currentType: i.text,
        currentModel: '請選擇型號',
        currentBrand: '請選擇品牌',
        // key: this.state.key + 1
      })
    }
    // this.updateKey()
  };
  selectBrand = (value, i) => {
    let result = [
      // { value: '0', text: '請選擇型號' }
    ]
    let data = this.state.modelList.length !== 0 ? this.state.modelList : this.state.pool;
    let type = this.state.currentType
    console.log(value, i);
    // call APIs
    if (value !== '0') {
      let filter = data.filter((item) => {
        // debugger
        // console.log(`data: `, data);
        return (item.brand == i.value)
      }).filter((item) => {
        // debugger
        if (!!type) {
          return (item.type == type)
        } else {
          return true
        }
      })
      filter.map((item, index) => {
        return (
          result.push(
            {
              value: i,
              text: item.text,
              brand: item.brand,
              type: item.type,
              model: item.model
            })
        )
      })
      this.setState({
        currentBrand: i.text,
        modelList: result,
        currentModel: ''
      })
    } else {
      this.resetModel()
      this.setState({
        currentBrand: i.text,
        currentType: null,
        currentModel: '請選擇型號',
        // key: this.state.key + 1
      })
    }
    // this.updateKey()
  };
  selectInstructionType = (value, i) => {
    console.log(value, i);
    // call APIs
    this.setState({
      instructionSelect: i.text
    })
  };
  selectModel = (value, i) => {
    console.log(value, i);
    // call APIs
    this.setState({
      currentModel: i.text
    })
  };
  updateKey = () => {
    this.setState({ key: this.state.key + 1 })
    console.log(this.state.key);
  }
  onChange = (e) => {
    console.log(e);
  }
  loadMore = (val) => {
    // console.log('hi');
    switch (val) {
      case 'privateSetting':
        this.setState({
          privateSettingLoad: false,
          privateSetting: [...this.state.privateSetting, ...this.state.privateSetting]
        });
        break;
      case 'internetSetting':
        this.setState({
          internetSettingLoad: false,
          internetSetting: [...this.state.internetSetting, ...this.state.internetSetting]
        });
        break;
      case 'application':
        this.setState({
          applicationLoad: false,
          application: [...this.state.application, ...this.state.application]
        });
        break;
      case 'data':
        this.setState({
          dataLoad: false,
          data: [...this.state.data, ...this.state.data]
        });
        break;
      case 'func':
        this.setState({
          funcLoad: false,
          func: [...this.state.func, ...this.state.func]
        });
        break;

      default:
        break;
    }

  };
  render() {
    return (
      <main className="Instruction">
        <Header />
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="手機/平板操作說明"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className='pb-0 pt-md-7 pt-4' style={{ zIndex: 2 }}>
          <div className='fui-container'>
            <h4 className="is-text-darkgray50 mt-0 mb-md-7 mb-4">
              此服務提供您查看各款手機之資訊與相關操作手冊之下載。
              </h4>
            <Grid container spacing={2} className="">
              <Grid item xs={12} sm={3}>
                <Dropdown
                  className="is-button"
                  list={this.state.typeList}
                  label={!!this.state.currentType ? this.state.currentType : "請選擇類別"}
                  arrow={true}
                  hasCheck={false}
                  onChange={(item, i) => this.selectType(item, i)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Dropdown
                  className="is-button"
                  list={this.state.brandList}
                  label={!!this.state.currentBrand ? this.state.currentBrand : "請選擇品牌"}
                  arrow={true}
                  hasCheck={false}
                  onChange={(item, i) => this.selectBrand(item, i)}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Dropdown
                  className="is-button"
                  list={this.state.modelList}
                  label={!!this.state.currentModel ? this.state.currentModel : "請選擇型號"}
                  arrow={true}
                  hasCheck={false}
                  onChange={(item, i) => this.selectModel(item, i)}
                />
              </Grid>
            </Grid>

          </div>
        </section>
        <CardProductInstruction
          list={[
            {
              name: 'Apple iPhone 11 Pro Max 256GB',
              content: `<table class="content-16 text-wrap bill-table table-fixed table-first-column-gray mb-2">
              <tbody>
                <tr>
                <th width="142" class="align-center-md align-left is-text-bold">作業系統</th>
                  <th class="align-left is-text-regular">iOS 13</th>
                </tr>
                <tr>
                  <td class="align-center-md align-left is-text-bold">
                  FDD-LTE Band
                  </td>
                  <td class="align-left">2100(B1),1900(B2),1800(B3),1700(B4),850(B5),2600(B7),900(B8),1500(B11),US700(B12),US700(B13),US700(B17),850(B18),850(B19),800(B20),1500(B21),1900(B25),850(B26),700(B28A),700(B28B),2300(B30),1500(B32),1700(B66)</td>
                </tr>
                <tr>
                  <td class="align-center-md align-left is-text-bold">TDD-LTE Band</td>
                  <td class="align-left">2100(B34),2600(B38),1900(B39),2300(B40),2500(B41),3500(B42)</td>
                </tr>
                <tr>
                  <td class="align-center-md align-left is-text-bold">載波聚合(CA)</td>
                  <td class="align-left">-</td>
                </tr>
                <tr>
                  <td class="align-center-md align-left is-text-bold">記憶卡插槽</td>
                  <td class="align-left">-</td>
                </tr>
                <tr>
                  <td class="align-center-md align-left is-text-bold">支援 Sim卡種類</td>
                  <td class="align-left">nano-SIM</td>
                </tr>
                <tr>
                  <td class="align-center-md align-left is-text-bold">傳輸介面</td>
                  <td class="align-left">NFC,藍牙,Lightning</td>
                </tr>
              </tbody>
          </table>`,
              image: [
                '/resources/cbu/estore/estore-product-thumb-01@2x.jpg',
                '/resources/cbu/estore/estore-product-thumb-01@2x.jpg',
                '/resources/cbu/estore/estore-product-thumb-01@2x.jpg',
                '/resources/cbu/estore/estore-product-thumb-01@2x.jpg'
              ]
            }
          ]}
          onChange={(e) => this.onChange(e)}
        />
        <section className='py-md-4 pt-2 pb-7 mb-md-9 mb-0'>
          <div className='fui-container'>
            <h2 className="mt-0 mb-md-4 mb-4">
              操作指南
            </h2>
            <Grid container spacing={2} className="">
              <Grid item xs={12} sm={6} md={3}>
                <Dropdown
                  className="is-button"
                  list={this.state.instructionSelectList}
                  label={!!this.state.instructionSelect ? this.state.instructionSelect : "設定及操作指南下載"}
                  arrow={true}
                  hasCheck={false}
                  onChange={(item, i) => this.selectInstructionType(item, i)}
                />
              </Grid>
            </Grid>
            <InstructionItems
              loadMore={() => this.loadMore('internetSetting')}
              title="上網設定"
              list={this.state.internetSetting}
              settingLoad={this.state.internetSettingLoad}
            />
            <InstructionItems
              loadMore={() => this.loadMore('privateSetting')}
              list={this.state.privateSetting}
              title="安全隱私"
              settingLoad={this.state.privateSettingLoad}
            />
            <InstructionItems
              loadMore={() => this.loadMore('application')}
              list={this.state.application}
              title="防災應用"
              settingLoad={this.state.applicationLoad}
            />
            <InstructionItems
              loadMore={() => this.loadMore('data')}
              list={this.state.data}
              title="數據功能"
              settingLoad={this.state.dataLoad}
            />
            <InstructionItems
              loadMore={() => this.loadMore('func')}
              list={this.state.func}
              title="功能設定"
              settingLoad={this.state.funcLoad}
            />
          </div>
        </section>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`為了可以正常瀏覽設定及操作說明，請安裝 <a class="hover-underline-link">Adobe Acrobat Reader</a> 軟體在您電腦中，您可透過 Adobe 官方網站免費軟體。`}
        />
      </main>
    );
  }
}
export default Instruction;