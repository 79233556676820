import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Modal from 'react-modal';

let record = {
  table: {
    head: ['反應日期', '2020/05/15<br/>16:45:12'],
    content: [
      ['事件發生地點', '反應問題', '處理結果', '回覆處理結果時間欄', '聯絡電子信箱', '看內容'],
      ['台北市中正區羅斯福路三段292號3樓', '有訊號但無法連線／連線中斷', '處理中', '無電話服務', 'mimi10152003@gmail.com', '反應內容']
    ]
  },
  info: [
    {
      text: '反應內容',
      content: `<table class="table-fixed text-wrap first-white">
      <thead>
        <tr>
          <th>行動電話/代表號</th>
          <th colspan="2">0912456789</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0912456789</td>
          <td colspan="2">王大明</td>
        </tr>
        <tr>
          <td>聯絡電話</td>
          <td colspan="2">09123456789</td>
        </tr>
        <tr>
          <td>聯絡電子信箱</td>
          <td colspan="2">abc@gmail.com</td>
        </tr>
        <tr>
          <td>事件發生日期</td>
          <td colspan="2">2020/05/12 15:55</td>
        </tr>
        <tr>
          <td>問題發生所在地</td>
          <td colspan="2">台北市中正區羅斯福路三段292號3樓</td>
        </tr>
        <tr>
          <td>反應問題</td>
          <td colspan="2">上網、室外、有訊號但無法連線/連線中斷</td>
        </tr>
        <tr>
          <td rowspan="4">反應問題內容</td>
          <td>是否出現錯誤代碼</td>
          <td>否</td>
        </tr>
        <tr>
          <td>網頁開啟或下載所使用的URL</td>
          <td>https://enterprise.fetnet.net/content/ebu/tw/index/micro-business.html</td>
        </tr>
        <tr>
          <td>第二支聯絡電話</td>
          <td>0912445789</td>
        </tr>
        <tr>
          <td>補充說明</td>
          <td>na</td>
        </tr>
        <tr>
          <td>處理結果</td>
          <td colspan="2">處理中</td>
        </tr>
        <tr>
          <td>處理結果說明</td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td>回覆處理結果時間</td>
          <td colspan="2"></td>
        </tr>
      </tbody>
    </table>`
    }
  ]
}
let emptyTable = {
  table: []
}
class FeedbackRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      titleHeight: [],
      getTitleLength: [],
      isMobile: window.innerWidth < 960,
      currentTable: record,
    }
  }
  componentDidMount() {
    this.getTitleLength()
    window.addEventListener('resize', this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({
      isMobile: window.innerWidth < 960,
    });
  };
  cleanTable = () => {
    this.setState({
      currentTable: emptyTable,
    });
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  onChangePage(pageOfItems, selectedPage) {
    // this.props.history.push(`${this.props.location.pathname}?keyword=${this.state.keyword}&page=${selectedPage}`);

    // update page of items
    // this.updatePageOfItems(pageOfItems);
    // console.log('on change');
  }


  getTitleLength = () => {
    let length = 0
    let content = this.state.currentTable.table.content
    let head = this.state.currentTable.table.head
    // console.log(this.state.content);
    // console.log(this.state.head);
    let store = []
    if (!!this.state.currentTable.table.head) {
      content.map((row, i) => {
        row.map((target, j) => {
          let result = target.replace(/[^\x00-\xff]/g, "xx").length;
          length = length < result ? result : length
        })
        // console.log(`i: `, i);
        store.push({
          row: i,
          height: length < 23 ? 56 : Math.floor(length / 18 * 34)
        })
        length = 0
      })
      head.map((header, i) => {
        let result = header.replace(/[^\x00-\xff]/g, "xx").length;
        if (store[i].height < result) {
          store[i].height = result < 19 ? 56 : (Math.floor(result / 18 * 28))
        }
      })
      // console.log(`store: `, store);
      this.setState({
        titleHeight: store
      })
    }
  }
  getHeight = (i) => {
    let data = this.state.titleHeight
    if (data.length > 0 && !!data[i]) {
      return data[i].height
    } else {
      return 0
    }
  }
  openModal = (index) => {
    console.log(record.info[index - 1]);
    this.setState({
      modalOpen: true,
      currentContent: record.info[index - 1]
    })
  }
  getLink = (item, index) => {
    return (
      <a key={item + index} onClick={() => { this.openModal(index) }} className="underline-link is-text-black50">
        {item}
      </a>
    )
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='交易/異動紀錄'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="通訊問題反應記錄"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="usage-rate mt-md-7 mt-4 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              <h4 className="is-text-darkgray50 mb-md-7 mb-5">
                可查詢您最近於遠傳官網反應的通訊問題記錄。
              </h4>
              <div className="paper mt-md-4 mt-2 mb-0 pb-md-3 pb-4">
                <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-3 mb-3">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <h4 className='m-0'>您最近三個月反應記錄如下</h4>
                    </Grid>
                  </Grid>
                </div>
                {Object.keys(this.state.currentTable.table).length === 0 ? <div>
                  <div className="body is-text-darkgray50 mb-md-5 mb-0">
                    查無通訊問題反應記錄，謝謝。
                    </div>
                </div> : <div className="compareTable content-16 clearfix text-wrap bill-table mt-0 mb-md-2 w-116">
                    <div className="tableLeft">
                      <div className="tableHead">
                        <table className='text-wrap'>
                          <tbody>
                            {this.state.currentTable.table.head.map((item, i) => {
                              return (
                                <tr className={i == 0 ? 'is-bg-lightgray70' : ''} style={{ height: this.getHeight(i) - (this.state.isMobile ? 16 : 0) }} key={item + i}>
                                  <td
                                    className={`align-center ${i == 0 ? 'is-text-medium' : 'is-text-regular'}`}
                                    dangerouslySetInnerHTML={{ __html: item, }} />
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="tableRight w-200">
                      <div className="tableBody">
                        <div className="spaceBox">
                          <table className="text-wrap">
                            <tbody>
                              {this.state.currentTable.table.content.map((list, i) => (
                                <tr style={{ height: this.getHeight(i) - (this.state.isMobile ? 16 : 0) }} key={list + i} className={i == 0 ? 'is-bg-lightgray70' : ''}>
                                  {list.map((item, j) => (
                                    (j == 5 ? <td key={item + j} style={{ minWidth: 82, width: 82 }}>{i == 0 ? item : this.getLink(item, i)}</td> : <td key={item + j} style={{ minWidth: (j == 2 ? 82 : null), width: (j == 2 ? 82 : null) }}>{item}</td>)
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </section>
        {this.state.currentContent ? <Modal
          onAfterOpen={e => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={e => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel="Example Modal"
          className="fui-prompt-modal">
          <div className="fui-prompt-modal-body">
            <h4 className="mt-0 mb-2">{this.state.currentContent.text}</h4>
            <div className="scrollable-modal-body is-bg-white">
              <div dangerouslySetInnerHTML={{ __html: this.state.currentContent.content }} />
            </div>
            <div className="page-action mt-md-2 mt-4">
              <Button btnStyle='primary' className="mb-0" onClick={() => this.setState({ modalOpen: false })}>我暸解了</Button>
            </div>
          </div>
        </Modal> : null}
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.cleanTable()} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>無資料</Button>
            </div>
          </div>
        </div>
      </main >
    )
  }
}

export default FeedbackRecord;