export const hotword = [
  '線上預約換號線上預約換號',
  '簡訊',
  'Iphone',
  '金雞救援',
  '載具號碼查詢',
  'Iphone8',
  '來電答鈴來電答鈴',
  '來電答鈴來電答鈴',
  'Iphone6',
];

export const resultFilterTab = {
  name: 'filter-tab',
  list: [
    { name: 'filter-1', label: '全部' },
    { name: 'filter-1', label: '商務' },
    { name: 'filter-1', label: '購物' },
    { name: 'filter-1', label: '幫助中心' },
    { name: 'filter-1', label: '關於遠傳' },
  ],
};
export const resultList1 = [
  {
    link: '#',
    category: 'help',
    type: 'pc',
    title: 'IoT-Enterprise',
    description:
      'Your best IoT solution partner in Taiwan!﻿ ... ｜ Copy Right ｜ Regulation ｜ Contact Us Copyright © Far EasTone Tel',
  },
  {
    link: '#',
    category: 'shopping',
    type: 'mobile',
    title: '【單機下殺花車活動】精選IoT商品...',
    description:
      '【單機下殺花車活動】精選IoT商品 ... G資費 推薦續約 獨家資費 預付型 預付型產品 易付卡申辦 易付卡儲值 易付卡上網 國際漫遊/出國上網 月租用戶號...',
  },
  {
    link: '#',
    category: 'about',
    type: 'mobile',
    title: 'NB-IoT應用資費-遠傳物聯網服務',
    description:
      'NB-IoT 應用資費方案，遠傳專業物聯網服務，車聯網/智慧路燈/智慧大樓/智慧停車，提供政府/企業創新解決方案。永續管理。安全可靠。',
  },
  {
    link: '#',
    category: 'business',
    type: 'pc',
    title: 'IoT 4G-聯網資費-物聯網服務',
    description:
      '遠傳專業物聯網服務，車聯網/智慧路燈/智慧大樓/智慧停車，提供政府/企業創新解決方案。永續管理。安全可靠。廣覆蓋 即時連線。漫遊服務。',
    extra: [
      {
        link: '#',
        title: '聖誕跨年嗨翻天八九十一二三四五六七八九十一二三四',
        description: '每月$203起送吃喝玩樂大禮包 一元 AirPods 買起來!',
      },
      {
        link: '#',
        title: '青春無價，就要給你學生價',
        description: '每月$203起送吃喝玩樂大禮包 一元 AirPods 買起來!',
      },
      {
        link: '#',
        title: '青春無價，就要給你學生價',
        description: '每月$203起送吃喝玩樂大禮包 一元 AirPods 買起來!',
      },
    ],
  },
]
export const resultList2 = [
  {
    link: '#',
    category: 'help',
    type: 'pc',
    title: 'IoT-Enterprise',
    description:
      'Your best IoT solution partner in Taiwan!﻿ ... ｜ Copy Right ｜ Regulation ｜ Contact Us Copyright © Far EasTone Tel',
  },
  {
    link: '#',
    category: 'shopping',
    type: 'mobile',
    title: '【單機下殺花車活動】精選IoT商品...',
    description:
      '【單機下殺花車活動】精選IoT商品 ... G資費 推薦續約 獨家資費 預付型 預付型產品 易付卡申辦 易付卡儲值 易付卡上網 國際漫遊/出國上網 月租用戶號...',
  },
  {
    link: '#',
    category: 'about',
    type: 'mobile',
    title: 'NB-IoT應用資費-遠傳物聯網服務',
    description:
      'NB-IoT 應用資費方案，遠傳專業物聯網服務，車聯網/智慧路燈/智慧大樓/智慧停車，提供政府/企業創新解決方案。永續管理。安全可靠。',
  },
  {
    link: '#',
    category: 'business',
    type: 'pc',
    title: 'IoT 4G-聯網資費-物聯網服務',
    description:
      '遠傳專業物聯網服務，車聯網/智慧路燈/智慧大樓/智慧停車，提供政府/企業創新解決方案。永續管理。安全可靠。廣覆蓋 即時連線。漫遊服務。',
  },
  {
    link: '#',
    category: 'business',
    type: 'pc',
    title: '遠傳電信FETnet 企業用戶',
    description:
      '遠傳電信為國內資通訊與數位服務領航者，結合物聯網及大數據專長，及雲端技術、IoT應用、人工智慧等專業能力，攜手企業打造創新的商業模式，邁向...',
    extra: [
      {
        link: '#',
        title: '聖誕跨年嗨翻天八九十一二三四五六七八九十一二三四',
        description: '每月$203起送吃喝玩樂大禮包 一元 AirPods 買起來!',
      },
      {
        link: '#',
        title: '青春無價，就要給你學生價',
        description: '每月$203起送吃喝玩樂大禮包 一元 AirPods 買起來!',
      },
      {
        link: '#',
        title: '青春無價，就要給你學生價',
        description: '每月$203起送吃喝玩樂大禮包 一元 AirPods 買起來!',
      },
    ],
  },
  {
    link: '#',
    category: 'help',
    type: 'mobile',
    title: 'IoT-Enterprise',
    description:
      'Your best IoT solution partner in Taiwan!﻿ ... ｜ Copy Right ｜ Regulation ｜ Contact Us Copyright © Far EasTone Tel',
  },
  {
    link: '#',
    category: 'shop',
    type: 'mobile',
    title: '【單機下殺花車活動】精選IoT商品...',
    description:
      '【單機下殺花車活動】精選IoT商品 ... G資費 推薦續約 獨家資費 預付型 預付型產品 易付卡申辦 易付卡儲值 易付卡上網 國際漫遊/出國上網 月租用戶號...',
  },
  {
    link: '#',
    category: 'about',
    type: 'mobile',
    title: 'NB-IoT應用資費-遠傳物聯網服務',
    description:
      'NB-IoT 應用資費方案，遠傳專業物聯網服務，車聯網/智慧路燈/智慧大樓/智慧停車，提供政府/企業創新解決方案。永續管理。安全可靠。',
  },
  {
    link: '#',
    category: 'about',
    type: 'mobile',
    title: 'IoT 4G-聯網資費-物聯網服務',
    description:
      '遠傳專業物聯網服務，車聯網/智慧路燈/智慧大樓/智慧停車，提供政府/企業創新解決方案。永續管理。安全可靠。廣覆蓋 即時連線。漫遊服務。',
  },
];

export const ResultExtra = [
  {
    link: '#',
    title: '聖誕跨年嗨翻天八九十一二三四五六七八九十一二三四',
    description: '每月$203起送吃喝玩樂大禮包 一元 AirPods 買起來!',
  },
  {
    link: '#',
    title: '青春無價，就要給你學生價',
    description: '每月$203起送吃喝玩樂大禮包 一元 AirPods 買起來!',
  },
  {
    link: '#',
    title: '青春無價，就要給你學生價',
    description: '每月$203起送吃喝玩樂大禮包 一元 AirPods 買起來!',
  },
];

export const relateKeyword = ['咖啡廳 WiFi', '5G', '大數據', '願景', '專業能力', '專長'];

export const musicCarousel = [
  {
    image: '/resources/cbu/search-result/music-1.png',
    retinaImage: '/resources/cbu/search-result/music-1@2x.png',
    title: '【花甲男孩轉大人】盧...',
    description: '盧廣仲(Crowd Lu)',
    actions: [
      {
        link: '#',
        icon: 'audition',
        text: '試聽'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      },
      {
        link: '#',
        icon: 'download',
        text: '下載'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/music-2.png',
    retinaImage: '/resources/cbu/search-result/music-2@2x.png',
    title: 'Better (feat. 陳芳語)',
    description: '吳卓源',
    actions: [
      {
        link: '#',
        icon: 'audition',
        text: '試聽'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      },
      {
        link: '#',
        icon: 'download',
        text: '下載'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/music-3.png',
    retinaImage: '/resources/cbu/search-result/music-3@2x.png',
    title: '少了一件牛仔褲',
    description: '閻奕格',
    actions: [
      {
        link: '#',
        icon: 'audition',
        text: '試聽'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      },
      {
        link: '#',
        icon: 'download',
        text: '下載'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/music-4.png',
    retinaImage: '/resources/cbu/search-result/music-4@2x.png',
    title: "梨泰院CLASS 韓劇原…",
    description: 'Gaho(가호)',
    actions: [
      {
        link: '#',
        icon: 'audition',
        text: '試聽'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      },
      {
        link: '#',
        icon: 'download',
        text: '下載'
      }
    ]
  },
];

export const ringCarousel = [
  {
    image: '/resources/cbu/search-result/ring-1.png',
    retinaImage: '/resources/cbu/search-result/ring-1@2x.png',
    title: '不說 【電影《從你的全...',
    description: '李榮浩',
    actions: [
      {
        link: '#',
        icon: 'audition',
        text: '試聽'
      },
      {
        link: '#',
        icon: 'download',
        text: '下載'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/ring-2.png',
    retinaImage: '/resources/cbu/search-result/ring-2@2x.png',
    title: '撞 - 電視劇《姊妹們，...',
    description: '畢書盡',
    actions: [
      {
        link: '#',
        icon: 'audition',
        text: '試聽'
      },
      {
        link: '#',
        icon: 'download',
        text: '下載'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/ring-3.png',
    retinaImage: '/resources/cbu/search-result/ring-3@2x.png',
    title: '我愛你',
    description: '李榮浩',
    actions: [
      {
        link: '#',
        icon: 'audition',
        text: '試聽'
      },
      {
        link: '#',
        icon: 'download',
        text: '下載'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/ring-4.png',
    retinaImage: '/resources/cbu/search-result/ring-4@2x.png',
    title: "I'm Ready",
    description: 'Sam Smith, Demi Lovato',
    actions: [
      {
        link: '#',
        icon: 'audition',
        text: '試聽'
      },
      {
        link: '#',
        icon: 'download',
        text: '下載'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
]

export const movieCarousel = [
  {
    image: '/resources/cbu/search-result/movie-1.png',
    retinaImage: '/resources/cbu/search-result/movie-1@2x.png',
    title: '深夜食堂電影版',
    description: '小林薰,小田切讓,多部未華子',
    actions: [
      {
        link: '#',
        icon: 'trailer',
        text: '預告片'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/movie-2.png',
    retinaImage: '/resources/cbu/search-result/movie-2@2x.png',
    title: 'X戰警：金鋼狼',
    description: '休傑克曼,萊恩雷諾斯,李佛...',
    actions: [
      {
        link: '#',
        icon: 'trailer',
        text: '預告片'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/movie-3.png',
    retinaImage: '/resources/cbu/search-result/movie-3@2x.png',
    title: '野蠻遊戲：全面晉級',
    description: '傑克布萊克,丹尼狄維托,柯...',
    actions: [
      {
        link: '#',
        icon: 'trailer',
        text: '預告片'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/movie-4.png',
    retinaImage: '/resources/cbu/search-result/movie-4@2x.png',
    title: '貼背戰',
    description: '安娜貝爾瓦莉絲,傑克強森,...',
    actions: [
      {
        link: '#',
        icon: 'trailer',
        text: '預告片'
      },
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
]

export const productCarousel = [
  {
    ribbon: '網路限定優惠',
    image: '/resources/cbu/search-result/product-1.png',
    retinaImage: '/resources/cbu/search-result/product-1@2x.png',
    meta: 'Apple',
    title: 'Apple iPad Pro 11 LTE 256GB (2018)',
    actions: [
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    ribbon: '新機上市',
    image: '/resources/cbu/search-result/product-2.png',
    retinaImage: '/resources/cbu/search-result/product-2@2x.png',
    meta: 'SAMSUNG',
    title: 'Samsung Galaxy A71 ',
    actions: [
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    ribbon: '新機上市',
    image: '/resources/cbu/search-result/product-3.png',
    retinaImage: '/resources/cbu/search-result/product-3@2x.png',
    meta: 'SAMSUNG',
    title: 'Samsung Galaxy A71 ',
    actions: [
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    ribbon: '網路限定優惠',
    image: '/resources/cbu/search-result/product-4.png',
    retinaImage: '/resources/cbu/search-result/product-4@2x.png',
    meta: 'Apple',
    title: 'Apple iPad Pro 11 LTE 256GB (2018)',
    actions: [
      {
        link: '#',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
]

export const productResult = [
  {
    ribbon: '網路限定優惠',
    image: '/resources/cbu/search-result/product-1.png',
    retinaImage: '/resources/cbu/search-result/product-1@2x.png',
    title: 'Apple iPad Pro 11 LTE 256GB (2018)',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    ribbon: '新機上市',
    image: '/resources/cbu/search-result/product-2.png',
    retinaImage: '/resources/cbu/search-result/product-2@2x.png',
    title: 'Apple iPhone 11 Pro 64GB福利品',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/product-3.png',
    retinaImage: '/resources/cbu/search-result/product-3@2x.png',
    title: 'Apple iPhone SE 64GB 2020',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/product-3.png',
    retinaImage: '/resources/cbu/search-result/product-3@2x.png',
    title: 'Apple iPhone SE 128GB 2020',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    ribbon: '網路限定優惠',
    image: '/resources/cbu/search-result/product-1.png',
    retinaImage: '/resources/cbu/search-result/product-1@2x.png',
    title: 'Apple iPad Pro 11 LTE 256GB (2018)',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    ribbon: '新機上市',
    image: '/resources/cbu/search-result/product-2.png',
    retinaImage: '/resources/cbu/search-result/product-2@2x.png',
    title: 'Apple iPhone 11 Pro 64GB福利品',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/product-3.png',
    retinaImage: '/resources/cbu/search-result/product-3@2x.png',
    title: 'Apple iPhone SE 64GB 2020',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/product-3.png',
    retinaImage: '/resources/cbu/search-result/product-3@2x.png',
    title: 'Apple iPhone SE 128GB 2020',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    ribbon: '網路限定優惠',
    image: '/resources/cbu/search-result/product-1.png',
    retinaImage: '/resources/cbu/search-result/product-1@2x.png',
    title: 'Apple iPad Pro 11 LTE 256GB (2018)',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    ribbon: '新機上市',
    image: '/resources/cbu/search-result/product-2.png',
    retinaImage: '/resources/cbu/search-result/product-2@2x.png',
    title: 'Apple iPhone 11 Pro 64GB福利品',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/product-3.png',
    retinaImage: '/resources/cbu/search-result/product-3@2x.png',
    title: 'Apple iPhone SE 64GB 2020',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/product-3.png',
    retinaImage: '/resources/cbu/search-result/product-3@2x.png',
    title: 'Apple iPhone SE 128GB 2020',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    ribbon: '網路限定優惠',
    image: '/resources/cbu/search-result/product-1.png',
    retinaImage: '/resources/cbu/search-result/product-1@2x.png',
    title: 'Apple iPad Pro 11 LTE 256GB (2018)',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    ribbon: '新機上市',
    image: '/resources/cbu/search-result/product-2.png',
    retinaImage: '/resources/cbu/search-result/product-2@2x.png',
    title: 'Apple iPhone 11 Pro 64GB福利品',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/product-3.png',
    retinaImage: '/resources/cbu/search-result/product-3@2x.png',
    title: 'Apple iPhone SE 64GB 2020',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
  {
    image: '/resources/cbu/search-result/product-3.png',
    retinaImage: '/resources/cbu/search-result/product-3@2x.png',
    title: 'Apple iPhone SE 128GB 2020',
    meta: 'Apple',
    link: '#',
    target: '_blank',
    actions: [
      {
        link: '#',
        target: '_blank',
        icon: 'buy',
        text: '購買'
      }
    ]
  },
]