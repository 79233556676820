import React from 'react';
import Button from '../Button';

import PropTypes from 'prop-types';

class PanelButton extends React.Component {
  render() {
    return (
      <div className='fui-panel-button fui-article-panel-button'>
        <Button 
        link={this.props.link} 
        target={this.props.target}
        btnStyle={this.props.btnStyle === 'solid' ? 'secondary' : 'arrow'}
        >
          {this.props.text}
        </Button>
      </div>
    );
  }
}

PanelButton.propTypes = {
  btnStyle: PropTypes.string, // text || solid, default text
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  target: PropTypes.string // _blank
};

export default PanelButton;
