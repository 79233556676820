import axios from 'axios';

export const getInfo = async() => {
    /**
     * 若要串接 API
     * 1. 換上正確的 url
     * 2. 檢查 return 是否成功回傳
     * 3. 可再根據需要做判斷與處理後再回傳
     *
     * axios('https://staging.vas.fetnet.net/eService_Revamp/api/mock/rateController/queryRateInfo').then(result => {
     *  return result.rateChangeInfo;
     * })
     */

    return {
      is4G: true,
      dataUnlimit: true,
      is3G: false,
      voiceRate: '',
      dataRate: '',
      vdRate: '4G新絕配199',
      voiceOptionList: null,
      dataOptionList: null,
      voiceOption4GList: [
        {
          name: '4G語音0型',
          code: '123456',
          desc: null,
          url: null,
          rate: null,
        },
      ],
      dataOption4GList: [
        {
          name: '4G高速飆網699',
          code: '654321',
          desc: null,
          url: null,
          rate: null,
        },
      ],
      vdOption4GList: [
        {
          name: '4G高速飆網699',
          code: '22222222',
          desc: null,
          url: null,
          rate: null,
        },
      ],
      hasWMPassword: false,
      validateCurrentOffer: false,
      offerDescs: '4G新絕配199',
      currentFourGVoiceRate: '',
      currentFourGDataRate: '',
      reserveFourGVoiceRate: null,
      reserveFourGDataRate: null,
      reserveFourGVDRate: null,
      currentFourGRate: 1,
      currentRateType: null,
      is5G: false,
      reserveVoiceRate: false,
      reserveDataRate: false,
      reserve4GRate: false,
      mvpn: false,
      mvpn200or400: false,
      d1D2: false,
    };
}
