import React from 'react';

import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

import * as Mock from '../../mock/EServiceIndex';

class prepaidDepositSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      canSubmit: false
    }
  }

  enableButton = (e) => {
    this.setState({ canSubmit: true });
  }

  disableButton = (e) => {
    this.setState({ canSubmit: false });
  }

  render() {
    return (
      <main>

        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <section className="e-service-result is-success">
          <div className="fui-container">
            <Grid>
              <Grid xs={12}>
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed@2x.png'} alt='' />
                <div className="content">
                  <h4 className="mb-0 mt-3">申請完成</h4>
                  <p className='body1'>您已完成申請，我們將迅速為您寄出新卡。提醒您，補卡將於下月帳單收取 300 元費用，謝謝！</p>
                </div>
              </Grid>
            </Grid>

            <div className='action align-center mt-5'>
              <Button btnStyle='primary' size='large'>返回遺失補卡</Button>
              <Button link='/' btnStyle='secondary' size='large'>返回掛失服務</Button>
            </div>
          </div>
        </section>
        <SectionCarousel1 {...Mock.promotionCard} />
      </main>
    );
  }
}

export default prepaidDepositSuccess;
