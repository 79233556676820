import React from 'react';
import Link from '../Link';
import PropTypes from 'prop-types';

class ArticleCitationPanel extends React.Component {
    render() {
        return (
            <div className={`fui-panel article-share-panel ${this.props.className}`}>
                <div className='fui-container fui-container-article'>
                    <div className='pr-2'>{this.props.title}</div>
                    <Link to={`//www.facebook.com/sharer.php?u=${encodeURIComponent('//' + window.location.host + this.props.fbLink)}`} target="_blank" className=''>
                        <i className='icon-facebook-share mr-1'></i>
                    </Link>
                    <Link to={`//social-plugins.line.me/lineit/share?url=${encodeURIComponent('//' + window.location.host + this.props.lineLink)}`} target="_blank" className=''>
                        <i className='icon-line-share mr-1'></i>
                    </Link>
                </div>
            </div>
        )
    }
}

ArticleCitationPanel.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    fbLink: PropTypes.string,
    lineLink: PropTypes.string,
}

export default ArticleCitationPanel;