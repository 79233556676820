export default {
    NavAnchor: {
        pageTitle: '<a href="#">來台旅客上網卡</a>',
        button: '聯絡客服',
        tabs: [
            { label: '上網卡方案', link: '#program' },
            { label: '如何撥打電話', link: '#howTo' },
            { label: '購買地點', link: '#whereToBuy' },
            { label: '常見問題', link: '#help' }
        ]
    },
    Breadcrumb: [
        { text: '首頁', link: '/' },
        { text: '來台旅客上網卡', link: '/' }
    ],
    SectionBanner2: {
        title: '來台上網的最佳選擇',
        description: '<h6><img src="/resources/cbu/prepaid/images/checklist.svg"/>4G吃到飽不降速</h6><h6><img src="/resources/cbu/prepaid/images/checklist.svg"/>買就送通話金</h6><h6><img src="/resources/cbu/prepaid/images/checklist.svg"/>插入SIM卡即可使用</h6>'
    },
    TabPane: {
        title: '來台旅客上網卡，插卡即用',
    },
    tabContent: {
        title: '計量型上網',
        id: 'program',
        cards: [
            {
                icon: '/resources/cbu/prepaid/images/prepaid-foreign-plan-icon-01.png',
                iconAlt: 'program1',
                title: '3日上網卡',
                subTitle: '網路吃到飽 + $100通話金',
                tag: {
                    text: '熱銷',
                    color: 'red'
                },
                list: [
                    '可撥打300分鐘語音',
                    '可連續使用72小時'
                ]
            },
            {
                icon: '/resources/cbu/prepaid/images/prepaid-foreign-plan-icon-02.png',
                iconAlt: 'program1',
                title: '5日上網卡',
                subTitle: '網路吃到飽 + $50通話金',
                tag: {
                    text: '熱銷',
                    color: 'blue'
                },
                list: [
                    '可撥打150分鐘語音',
                    '可連續使用120小時'
                ]
            },
            {
                icon: '/resources/cbu/prepaid/images/prepaid-foreign-plan-icon-01.png',
                iconAlt: 'program1',
                title: '5日語音放送上網卡',
                subTitle: '網路吃到飽 + $100通話金',
                tag: {
                    text: '熱銷',
                    color: 'gray'
                },
                list: [
                    '可撥打300分鐘語音',
                    '可連續使用120小時'
                ]
            },
            {
                icon: '/resources/cbu/prepaid/images/prepaid-foreign-plan-icon-02.png',
                iconAlt: 'program1',
                title: '5日語音放送上網卡',
                subTitle: '網路吃到飽 + $100通話金',
                list: [
                    '可撥打300分鐘語音',
                    '可連續使用120小時'
                ]
            },
        ]
    },
    dial: {
        title: '如何撥打電話',
        panel:
            `<h4 class="is-text-medium mt-0">在台灣打給台灣門號</h4>
            <ul>
                <li>行動電話：行動電話號碼，如 0912-345-678</li>
                <li>市內電話：區域電話 + 電話號碼，如 02-1234-5678</li>
            </ul>
            <div class="wrapper pb-2">
                <div class="d-column">
                    <h4 class="is-text-darkgray50">網內</h4>
                    <div class="d-row bg-gray">
                        <div class="d-flex">
                            <p class="m-0">一般時段</p>
                            <h2 class="is-text-black50 m-0">NT $0.1<span>/秒</span></h2>
                        </div>
                        <div class="d-flex">
                            <p class="m-0">減價時段</p>
                            <h2 class="is-text-black50 m-0">NT $0.05<span>/秒</span></h2>
                        </div>
                    </div>
                </div>
                <div class="d-column">
                    <h4 class="is-text-darkgray50">網外</h4>
                    <div class="d-row bg-gray">
                        <div class="d-flex">
                            <p class="m-0">一般時段</p>
                            <h2 class="is-text-black50 m-0">NT $0.174<span>/秒</span></h2>
                        </div>
                        <div class="d-flex">
                            <p class="m-0">減價時段</p>
                            <h2 class="is-text-black50 m-0">NT $0.0869<span>/秒</span></h2>
                        </div>
                    </div>
                </div>
            </div>
            <h4 class="is-text-medium mt-5">在台灣打給外國門號</h4>
            <ul class="mb-4">
                <li>撥打電話：017 + 國碼 + 區域號碼 + 當地電話號碼，如 017-86-21-12345678</li>
            </ul>`,
        PanelTable:
            `<div class="compareTable content-16 clearfix">
                <div class="tableLeft">
                    <div class="tableHead">
                        <table>
                            <tbody>
                                <tr style="height: 52px;">
                                    <th>州別</th>
                                </tr>
                                <tr style="height: 260px;">
                                    <th>亞洲</th>
                                </tr>
                                <tr style="height: 52px;">
                                    <th>美洲</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tableRight">
                    <div class="tableBody">
                        <div class="spaceBox">
                            <table class="compareList">
                                <tbody>
                                    <tr style="height: 52px;">
                                        <td>國家</td>
                                        <td>國碼</td>
                                        <td>每分鐘通話費</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>香港</td>
                                        <td>852</td>
                                        <td>NT $6.7</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>日本/南韓</td>
                                        <td>81/82</td>
                                        <td>NT $9.1</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>中國</td>
                                        <td>86</td>
                                        <td>NT $8.4</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>新加坡</td>
                                        <td>65</td>
                                        <td>NT $7.3</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>馬来西亞</td>
                                        <td>65</td>
                                        <td>NT $7.3</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>美國/加拿大</td>
                                        <td>65</td>
                                        <td>NT $7.3</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>
                </div>
            </div>`,
        PanelInformation: {
            title: '備註說明',
            content: '備註說明'
        }
    },
    whereToBuy: {
        title: '桃園機場購買地點',
        tabs: {
            name: 'panel-tab',
            default: 0,
            list: [
                { name: 'panel-tab-1', label: '第一航廈 證照檢查口前' },
                { name: 'panel-tab-2', label: '第一航廈 證照檢查口後' },
                { name: 'panel-tab-4', label: '第二航廈' },
            ],
        },
        tabContent:
            `<div class="airport-info">
                <img src="/resources/cbu/prepaid/images/airport-store-before-terminal-1-big-5@2x.jpg" alt="airport" />
                <p>服務時間：9:00AM - 12:00PM (全年無休)</p>
            </div>`
    },
    info: {
        title: '備註事項',
        content: `<ol style="line-height:1.5">
            <li>The prepaid card can only be used in Taiwan.</li>
            <li>Internet speeds may vary depending on the location, weather, smartphone.</li>
            <li>FET Taoyuan Airport store only. Fareastone Telecom reserves the right to change tariffs and services. The latest tariff service content is based on the announcement of Fareastone Telecom.</li>
            <li>Peak Hours : Monday to Friday 08:00~23:00</li>
            <li>Off-Peak Hours: Monday to Friday 23:00~08:00 ; Saturday 00:00 to Monday 08:00 ; all National Holidays</li>
            <li>For more Tariff information, please refer to International Call Website.</li>
            <li>Far Eastone Telecom reserves the right to modify the rate at any time.</li>
        </ol>`
    },
    qa: {
        title: '常見問題',
        faq: {
            collapseList: [
                {
                    title: '用上網卡撥打電話的費率是多少？',
                    content: `用上網卡撥打電話的費率是多少？`,
                },
                {
                    title: '如何用上網卡撥打國際電話？',
                    content: `如何用上網卡撥打國際電話？`,
                },
                {
                    title: '誰可以購買來台旅客上網卡？  ',
                    content: `誰可以購買來台旅客上網卡？  `,
                },
            ],
            to: '#',
            container: false
        }
    }

}