import React from 'react';
import Link from '../Link';
import { Grid } from '@material-ui/core';
import Button from '../Button';
import PropTypes from 'prop-types';

const Card = (props) => {
  const [isEn, setIsEn] = React.useState(false);

  React.useEffect(() => {
    setIsEn(typeof window !== 'undefined' && window.$isEn);
  }, [props]);

  const renderCardContent = () => {
    return (
      <section className={props.type}>
        <div className='fui-container'>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              {props.image ? (
                <div className='img fui-bancard-image'>
                  <img src={props.image} srcSet={props.retinaImage || props.image} alt={props.meta} />
                </div>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className='fui-bancard-content'>
                {!props.subtitle ? (
                  ''
                ) : (
                  <p
                    className='my-0 is-life-circle-gray fui-bancard-subtitle'
                    dangerouslySetInnerHTML={{ __html: props.subtitle }}></p>
                )}
                {!props.title ? (
                  ''
                ) : (
                  <h3 className='is-text-regular' dangerouslySetInnerHTML={{ __html: props.title }}></h3>
                )}
                {!props.description ? (
                  ''
                ) : (
                  <p className='fui-bancard-description' dangerouslySetInnerHTML={{ __html: props.description }}></p>
                )}
                {!props.checkList ? (
                  ''
                ) : (
                  <div className='checklist'>
                    {props.checkListArrList.map((item) => (
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/check.svg'}
                          alt=''
                        />
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                )}
                {!props.action ? (
                  ''
                ) : (
                  <Button
                    btnStyle='secondary'
                    target={props.action.target}
                    link={props.action.link}
                    size=''
                    className='is-sm-block'>
                    {props.action.text}
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  };

  return (
    <div className={`fui-bancard ${props.className ? props.className : ''}`} id={props.id}>
      {renderCardContent()}
    </div>
  );
};

Card.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  retinaImage: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  checkList: PropTypes.bool,
  checkListArrList: PropTypes.array,
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
};

export default Card;
