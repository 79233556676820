import React from 'react';
import { Grid } from '@material-ui/core';
import LinkItem from '../../item/LinkItem';

const MenuContent2 = (props) => {
  // console.log(`props`, props);
  const renderList = (array) =>
    array &&
    array.map((item, i) => (
      <LinkItem key={`meun-content-list-item-${i}`} link={item.link} className='item'>
        {item.text}
      </LinkItem>
    ));
  return (
    <Grid item sm={10} md={10} lg={8} xl={10} className='menu-body'>
      <div className='menu-content'>
        <Grid container spacing={2}>
          {props.menu.map((menuItem, i) => (
            <Grid item md={4} key={`menu-item-${i}`} className='mb-2' id={`${props.id}-${i + 2}`}>
              <h6>{menuItem.title}</h6>
              {renderList(menuItem.list)}
              {menuItem.more && menuItem.more.text ? (
                <LinkItem link={menuItem.more.link} icon='chevron-right' className='more'>
                  {menuItem.more.text}
                </LinkItem>
              ) : null}
            </Grid>
          ))}
        </Grid>
      </div>
    </Grid>
  );
};

export default MenuContent2;
