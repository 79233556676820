import React from 'react';

import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner'
import Tab from '../../components/tab/Tab'
import TabPane from '../../components/tab/TabPane'
import CollapsePaper from '../../components/collapse/CollapsePaper'
import Button from '../../components/Button'

class getBarcodePayInitPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0
    }
  }

  render() {
    return (
      <main>

        <NavAnchor
          pageTitle='繳費服務'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <FormBanner
          title="手機條碼繳費"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />
        <section className="pt-0">
          <div className="fui-container">
            <Tab
              default={this.state.currentTab}
              list={[
                { label: "超商繳費" },
                { label: "門市繳費" },
              ]}
              onChange={e => this.setState({ currentTab: e })}
            />
            <div className="pt-4">
              <TabPane active={this.state.currentTab === 0}>
                <h4 className="is-text-darkgray50">你可至下列超商出示以下條碼繳費</h4>
                <div className="subtitle mb-1">適用超商</div>
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-logo-7-11.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-logo-7-11@2x.png'} alt='7-11' width="34" className="mr-1" />
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-logo-familymart.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-logo-familymart@2x.png'} alt='familymart' width="34" className="mr-1" />
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-logo-hilife.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-logo-hilife@2x.png'} alt='hilife' width="34" className="mr-1" />
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-logo-ok.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-logo-ok@2x.png'} alt='ok' width="34" />
                <div className="paper mt-4">
                  <div className="subtitle">
                    <div className="float-right payment-price">1,199元</div>
                    應繳金額
                  </div>
                  <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/barcode-1.png'} alt='' className="barcode-img my-1" />
                  <div className="align-center">
                    <small className="body2 is-text-gray100">超商繳費條碼</small>
                  </div>
                </div>

                <CollapsePaper
                  title="全部應繳"
                  className="barcode-paper"
                  openCollapse={e => { console.log(e) }}
                >
                  <div className="subtitle">
                    <div className="float-right payment-price">1,199元</div>
                    總金額
                  </div>
                  <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/barcode-1.png'} alt='' className="barcode-img my-1" />
                  <div className="align-center pb-2">
                    <small className="body2 is-text-gray100">超商繳費條碼</small>
                  </div>
                </CollapsePaper>

                <CollapsePaper
                  title="上期應繳"
                  className="barcode-paper"
                  openCollapse={e => { console.log(e) }}
                >
                  <div className="subtitle">
                    <div className="float-right payment-price">1,199元</div>
                    <span>總金額</span>
                  </div>
                  <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/barcode-1.png'} alt='' className="barcode-img my-1" />
                  <div className="align-center pb-2">
                    <small className="body2 is-text-gray100">超商繳費條碼</small>
                  </div>
                </CollapsePaper>
              </TabPane>
              <TabPane active={this.state.currentTab === 1}>
                <h4 className="is-text-darkgray50">你可至遠傳直營門市出示以下條碼繳費</h4>
                <div className="paper mt-4">
                  <div className="subtitle">應繳金額</div>
                  <div className="float-right payment-price">1,199元</div>
                  <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/barcode-2.png'} alt='' className="barcode-img my-1" />
                  <div className="align-center">
                    <small className="body2 is-text-gray100">直營門市繳費條碼</small>
                  </div>
                </div>
              </TabPane>
            </div>
          </div>
        </section>

        <section classNam="pt-0">
          <div className='fui-container'>
            <div className='paper'>
              <div className='body is-text-darkgray50'>親愛的用戶，你的門號目前無帳款需繳納，謝謝。</div>
            </div>

            <div className="page-action mt-4">
              <Button btnStyle='primary' size='large'>帳單明細</Button>
            </div>
          </div>
        </section>

      </main>
    );
  }
}

export default getBarcodePayInitPage;
