import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import RadioGroup from '../../components/form/RadioGroup';
import Checkbox from '../../components/form/Checkbox';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import Select from '../../components/form/Select';
import numberFormatter from '../../utils/numberFormatter';


class PayNothingPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      step: 1,
      canSubmit: false,
      modalOpen: false,
      form: {
        credit_number_1: { value: "", required: true },
        credit_number_2: { value: "", required: true },
        credit_number_3: { value: "", required: true },
        credit_number_4: { value: "", required: true },
        credit_year: { value: null, required: true },
        credit_month: { value: null, required: true },
        credit_code: { value: '', required: true },
        agree: { value: false, required: true }
      }
    }
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  onChange = (n, v) => {
    let form = Object.assign(this.state.form);
    form[n].value = v;

    this.setState({
      form: form
    })
  }

  nextStep = () => {
    this.setState({
      step: 2
    })
  }

  submit = (autopay) => {
    let form = this.state.form
  }

  render() {
    const { form, step } = this.state;

    return (
      <main>

        <NavAnchor
          pageTitle='線上繳費'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="線上繳費"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className='pt-2 pt-md-5'>
          <div className="fui-container">
            <div className="paper">
              <h4>應繳費項</h4>
              <p className='is-text-darkgray50'>親愛的用戶，你的門號目前無帳款需繳納，謝謝。</p>
            </div>
            <div className="page-action">
              <Button btnStyle='primary' size='large'>查未結帳金額／上網用量</Button>
              <Button btnStyle='secondary' size='large' link="/payController/payPage">立即續約</Button>
            </div>
          </div>
        </section>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            <ol>
              <li>此功能不需帳號及密碼，只要輸入身分資料完成登入檢核就可進行繳費。</li>
              <li>快速登入後僅能進行繳費功能，若需使用其他功能，請先進行登入。</li>
              <li>繳納費用非一般性消費支出，請洽各發卡銀行確認是否有刷卡紅利或現金回饋。</li>
            </ol>
          `}
        />
      </main>
    )
  }
}

export default PayNothingPage;