import React from 'react';
import { Grid } from '@material-ui/core'
import NavAnchor from '../../components/partials/NavAnchor'
import NavContentTab1 from '../../components/partials/NavContentTab1'
import Breadcrumb from '../../components/Breadcrumb'
import Link from '../../components/Link'
import Panel from '../../components/panel/Panel'
import TabPane from '../../components/tab/TabPane'
import Dropdown from '../../components/Dropdown'

const record = [
  {
    date: '2020/08/01',
    trans: '消費1200',
    title: 'friDay 購物消費回饋 - 基本回饋',
    type: 'income',
    status: 0,
    fcoin: '+90'
  },
  {
    date: '2020/08/01',
    trans: '支出金額',
    title: 'friDay Shopping - 促銷回饋',
    type: 'outcome',
    status: 1,
    fcoin: '-90'
  },
  {
    date: '2020/08/01',
    trans: '支出金額',
    title: 'friDay Shopping',
    type: 'outcome',
    status: -1,
    fcoin: '-90'
  },
];

const activity = [
  {
    title: '填問卷免費賺點數',
    description: '填寫問卷即可獲得50f幣',
    link: '/',
    target: '_self'
  },
  {
    title: 'friDay購物買就集',
    description: '填寫問卷即可獲得50f幣',
    link: '/',
    target: '_self'
  },
  {
    title: '7-11 咖啡 - 美式',
    description: '填寫問卷即可獲得50f幣',
    link: '/',
    target: '_self'
  },
  {
    title: 'Apple store 購買 - Fimo',
    description: '填寫問卷即可獲得50f幣',
    link: '/',
    target: '_self'
  },
  {
    title: 'Apple store 購買 - Fimo',
    description: '填寫問卷即可獲得50f幣',

    link: '/',
    target: '_self'
  },
  {
    title: 'Apple store 購買 - Fimo',
    description: '填寫問卷即可獲得50f幣',

    link: '/',
    target: '_self'
  },
  {
    title: 'Apple store 購買 - Fimo',
    description: '填寫問卷即可獲得50f幣',
    link: '/',
    target: '_self'
  },
  {
    title: 'Apple store 購買 - Fimo',
    description: '填寫問卷即可獲得50f幣',

    link: '/',
    target: '_self'
  },
  {
    title: 'Apple store 購買 - Fimo',
    description: '填寫問卷即可獲得50f幣',

    link: '/',
    target: '_self'
  },
]

class Fcoin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      filterSelected: { text: '全部', value: '' },
      isMobile: window.innerWidth < 768,
      dashboardOpen: true,
      activityOpen: false,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', e => {
      this.setState({
        isMobile: window.innerWidth < 768
      })
    })
  }

  toggleDashboard = () => {
    this.setState({
      dashboardOpen: !this.state.dashboardOpen
    })
  }

  filterChange = (e, v, i) => {
    // console.log(`e,v,i:`, e, v, i);
    this.setState({
      filterSelected: v
    })
  }

  tabChange = index => {
    this.setState({
      currentTab: index
    })
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='遠傳幣'
          button={{
            text: '回個人專區',
            link: '#'
          }}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '遠傳幣', link: '/' },
          ]}
          color='black'
        />
        <section className='fcoin-dashboard'>
          <div className='fui-container'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5} lg={6} className='fcoin-container'>
                <h3 className='is-text-darkgray50'>目前累積遠傳幣</h3>
                <h1 className='fcoin-number' onClick={this.toggleDashboard}>
                  <img src={'/resources/common/member-icon/fcoin.svg'} width='60' alt='遠傳幣' />
                  <span>3,488</span>
                  <div className='d-inline-block d-md-none ml-2'>
                    <i className="icon-chevron-down i-24 is-text-black50" ></i>
                  </div>
                </h1>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={6} className={`${this.state.dashboardOpen ? '' : 'd-none'} d-md-block`}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className='gray-block'>
                      <div className='subtitle'>本月新增</div>
                      <h2>3,400</h2>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className='gray-block'>
                      <div className='subtitle'>30天內將到期</div>
                      <h2>1,000</h2>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className='gray-block'>
                      <div className='subtitle'>今年可使用</div>
                      <h2>3,488</h2>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </section>
        <NavContentTab1
          tabs={{
            name: 'fcoin-page-tab',
            list: [
              { label: '集點活動' },
              { label: '交易記錄' }
            ]
          }}
          onChange={this.tabChange}
        />
        <section className="fcoin-tab-wrapper ribbon-bg">
          <div className="fui-container">
            <TabPane active={this.state.currentTab === 0}>

              {activity.length > 0 ?
                <div>
                  <h2>推薦</h2>
                  <Panel>
                    <div className="fui-cards is-recommand-activity three-card">
                      {
                        activity.map((act, i) => this.state.activityOpen || (!this.state.activityOpen && i < 6) ? (
                          <div className='fui-card' key={`recommand-activity-${i}`}>
                            <Link to={act.link} target={act.target} className="fui-card-action">
                              <div className="fui-card-content">
                                <h5 className="my-0">{act.title}</h5>
                                <p className="" dangerouslySetInnerHTML={{ __html: act.description }}></p>
                                {
                                  act.meta ? (
                                    <div className="description">
                                      <img src={process.env.PUBLIC_URL + 'resources/common/images/timer.svg'} width='30' alt='' />
                                      <span className='is-text-accent'>{act.meta}</span>
                                    </div>
                                  ) : null
                                }
                              </div>
                            </Link>
                          </div>
                        ) : null
                        )
                      }
                    </div>
                    {
                      !this.state.activityOpen && activity.length > 6 ? (
                        <div className='align-center' onClick={e => this.setState({ activityOpen: true })}>
                          <div role='button'>
                            <span>看更多</span>
                            <i className="icon-plus i-30"></i>
                          </div>
                        </div>
                      ) : null
                    }
                  </Panel>
                </div> : <div className="paper is-bg-lightgray70">
                  <div className="is-text-darkgray50 body">
                    目前無任何集點活動
                  </div>
                </div>
              }
              <div className="paper is-bg-lightgray70">
                <div className="is-text-darkgray50 body">
                  目前無任何集點活動
                  </div>
              </div>
            </TabPane>
            <TabPane active={this.state.currentTab === 1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={9}><h3>fCoin 交易記錄</h3></Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <Dropdown
                    label={this.state.filterSelected.text}
                    className="is-button"
                    list={[
                      { text: '全部', value: 'all' },
                      { text: '獲得的遠傳幣', value: 'income' },
                      { text: '花費的遠傳幣', value: 'outcome' },
                    ]}
                    arrow={true}
                    onChange={(e, v, i) => this.filterChange(e, v, i)}
                  />
                </Grid>
              </Grid>
              <Panel>
                <div className='fcoin-record-list'>
                  {
                    record.map((rec, i) => this.state.recordOpen || (!this.state.recordOpen && i < 9) ? (
                      <div className='fui-record-item' key={`fcoin-record-${i}`}>
                        <div className='content'>
                          <h4 className='is-text-darkgray50'>{rec.date} {rec.trans}</h4>
                          <h5>{rec.title}</h5>
                        </div>
                        <div className='extra'>
                          {
                            rec.status === 0 ? (
                              <span className='record-status is-text-darkgray50'>未入帳</span>
                            ) : null
                          }
                          {
                            rec.status === 1 ? (
                              <span className='record-status is-text-darkgray50'>已使用</span>
                            ) : null
                          }
                          {
                            rec.status === -1 ? (
                              <span className='record-status is-text-accent'>已失效</span>
                            ) : null
                          }
                          <div className={`fcoin ${rec.type === 'outcome' ? 'is-text-accent' : ''}`}>
                            <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/fcoin.svg'} width='24' alt='' />{rec.fcoin}
                          </div>
                        </div>
                      </div>
                    ) : null
                    )
                  }
                </div>

                {
                  !this.state.recordOpen && record.length > 9 ? (
                    <div className='align-center' onClick={e => this.setState({ activityOpen: true })}>
                      <div role='button'>
                        <span>看更多</span>
                        <i className="icon-plus i-30"></i>
                      </div>
                    </div>
                  ) : null
                }
              </Panel>
            </TabPane>
          </div>
        </section>
      </main>
    )
  }
}

export default Fcoin;