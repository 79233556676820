import React from 'react';

import PropTypes from 'prop-types';

class PanelTable extends React.Component {
  render() {
    return <div className='fui-table' dangerouslySetInnerHTML={{ __html: this.props.content }}></div>;
  }
}

PanelTable.propTypes = {
  content: PropTypes.string.isRequired,
};

export default PanelTable;
