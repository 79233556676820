import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

class BillResendSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.form = React.createRef();
        this.state = {
            type: 'success',
            description: `<h4 class="mb-1">Email 補寄帳單成功</h4><p class="mt-0 mb-4">您已完成申請，帳單將於 <span class="is-text-accent">1-3</span> 小時寄到您指定的信箱，謝謝</p>`,
            promotion: {
                title: '你的專屬優惠',
                cards: [
                    {
                        image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
                        title: '本月影片免費看!',
                        description: '話題電影、日劇、韓劇等等應有盡有',
                        link: '#',
                    },
                    {
                        image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
                        title: '中午不知道吃什麼？',
                        description: 'Uber Eats 送遠傳新客優惠金200元',
                        link: '#',
                    },
                    {
                        image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
                        title: '連假打算出國？',
                        description: '遠遊卡吃到飽上網不再煩惱用量 ',
                        link: '#',
                    },
                    {
                        image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
                        title: '本月影片免費看!',
                        description: '話題電影、日劇、韓劇等等應有盡有',
                        link: '#',
                    },
                ]
            }
        }
    }

    onChange = (name, value) => {
        // console.log(`name, value: `, name, value);
        let newForm = Object.assign(this.state.form);
        newForm[name].value = value;
        this.setState({
            form: newForm
        })
    }

    handleChange = () => {
        this.setState({
            authCode: this.input.current.value,
        });
    }
    disableButton = () => {
        this.setState({ canSubmit: false });
    }
    enableButton = () => {
        this.setState({ canSubmit: false });
    }
    submit = model => {
        // console.log('submitted');
    };
    render() {
        return (
            <main>
                <NavAnchor
                    pageTitle='帳單與用量'
                    button={{
                        text: '回個人專區',
                        link: '#'
                    }} />

                <section className="e-service-result is-success mt-4">
                    <div className="fui-container">
                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    this.state.type !== 'fail' ? (
                                        <img src={'/resources/cbu/e-service/images/completed.png'} srcSet={'/resources/cbu/e-service/images/completed@2x.png'} alt='' />
                                    ) : (
                                            <img src={'/resources/cbu/e-service/images/failed.png'} srcSet={'/resources/cbu/e-service/images/failed@2x.png'} alt='' />
                                        )
                                }

                                <div className="content">
                                    <h4 className="mb-0 mt-3">{this.state.title || ''}</h4>
                                    <p className='body1' dangerouslySetInnerHTML={{
                                        __html: this.state.description || ''
                                    }}></p>
                                </div>
                            </Grid>
                        </Grid>
                        <div className='fui-buttons two-buttons mt-md-0 mt-2 pt-2'>
                            <Button className='d-block d-md-inline-block' link="#" btnStyle='primary' size='large'>返回帳單補寄</Button>
                            <Button className='d-block d-md-inline-block' link="#" btnStyle="secondary" size='large'>前往個人專區</Button>
                        </div>


                    </div>
                </section>
                <SectionCarousel1 {...this.state.promotion} />
            </main >
        )
    }
}

export default BillResendSuccess;