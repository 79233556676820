import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import Modal from 'react-modal';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import numberFormatter from '../../utils/numberFormatter';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

let bill1 = {
	detailLink: '#123',
	date: '2020年04月',
	period: '2020/03/20 至 2020/04/19',
	info: {
		number: '0916-814-747',
		endDate: '2020/04/20',
	},
	fee: [
		{
			title: '他網行動',
			fee: 999,
			isRed: false
		},
		{
			title: '行動上網傳輸費',
			fee: 120,
			isRed: false
		},
		{
			title: '合計',
			fee: 1119,
			isRed: true
		}
	],
	payment: '#',
	resendPayment: '#',
	upgradeLink: '#',
	usageDetail: {
		inter: 9,
		outer: 3,
		local: 1,
		special: 0,
		internet: 0
	},
	usageAmount: {
		isPackage: false,
		amount: '1.5GB'
	}
}

let bill2 = {
	detailLink: '#123',
	date: '2020年04月',
	period: '2020/03/20 至 2020/04/19',
	info: {
		number: '0916-814-747',
		endDate: '2020/04/20',
	},
	fee: [
		{
			title: '網內互打',
			fee: 50,
			isRed: false
		},
		{
			title: '他網行動',
			fee: 50,
			isRed: false
		},
		{
			title: '市內通話',
			fee: 50,
			isRed: false
		},
		{
			title: '行動上網傳輸費',
			fee: 50,
			isRed: false
		},
		{
			title: '合計',
			fee: 1190,
			isRed: true
		},
	],
	usage: [
		{
			title: '0936-000-093',
			usage: 2.09,
			isRed: false
		},
		{
			title: '0935-000-091',
			usage: 2.09,
			isRed: false
		},
		{
			title: '用量合計',
			usage: 4.18,
			isRed: true
		}
	],
	payment: '#',
	resendPayment: '#',
	upgradeLink: '#',
	usageDetail: {
		inter: 9,
		outer: 3,
		local: 1,
		special: 0,
		internet: 0
	},
	usageAmount: {
		isPackage: true,
		amount: '1.5GB',
		cumulation: '2.39GB',

	}
}
let bill3 = {
	detailLink: '#123',
	date: '2020年04月',
	period: '2020/03/20 至 2020/04/19',
	info: {
		number: '0916-814-747',
		endDate: '2020/04/20',
	},
	fee: [],
	payment: '#',
	resendPayment: '#',
	upgradeLink: '#',
	usageDetail: {
		inter: 9,
		outer: 3,
		local: 1,
		special: 0,
		internet: 0
	},
	usageAmount: {
		isPackage: false,
		amount: '1.5GB'
	}
}

class QueryInternet extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			canSubmit: false,
			modalOpen: false,
			form: {
				id: { value: '', required: true },
			},
			selectPeriod: { value: '1', text: '2020/04月份' },
			periodOption: [
				{ value: '1', text: '2020/04月份' },
				{ value: '2', text: '2020/05月份' },
				{ value: '3', text: '2020/06月份' },
			],
			currentBill: bill1,
		}
	}

	onChange = (name, value) => {
		let newForm = Object.assign(this.state.form);
		newForm[name].value = value;
		this.setState({
			form: newForm
		})
	}

	render() {
		return (
			<main>
				<NavAnchor
					pageTitle='帳單與用量'
					button={[
						{
							text: '立即續約',
							link: '/',
						},
						{
							text: '回個人專區',
							link: '/',
						}
					]
					}
				/>

				<FormBanner
					title="未出帳金額/上網用量"
					image={{
						md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
						sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
					}}
				/>

				<section className="query-index pb-md-2 pb-0">
					<div className="fui-container">
						<h4 className="is-text-darkgray50 mt-0 mb-5 pb-md-2">
							此服務提供月租型用戶查詢尚未結帳金額及國內行動上網用量。
						</h4>

						<h2 className='mb-0'>
							未出帳收費項目
							<i onClick={() => { this.setState({ modalOpen: true }) }} className='icon-information is-text-darkgray100 ml-1 d-inline-block' />
						</h2>
						<div className='body is-text-darkgray50 mb-4 mt-1'>費用計算期間：2020/04/20 至 2020/05/19</div>
						<div className="paper mb-2">
							<h4 className="mt-md-4 mb-md-3 mb-2">
								帳務資訊
								</h4>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<div className="is-bg-lightgray70 px-3 py-3">
										<h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
											門號
											</h5>
										<h2 className='mb-0'>{this.state.currentBill.info.number}</h2>
									</div>
								</Grid>
								<Grid item xs={12} sm={6}>
									<div className="is-bg-lightgray70 px-3 py-3">
										<h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0 d-sm-block d-flex justify-between">
											下次結帳日
										</h5>
										<h2 className='mb-0'>{this.state.currentBill.info.endDate}</h2>
									</div>
								</Grid>
							</Grid>
							<div className="d-flex justify-between flex-align-center mt-md-2 pt-md-4 mt-2">
								<h4 className='m-0'>費用明細</h4>
							</div>
							<div className="frammer mb-md-4 mb-0">
								{this.state.currentBill.fee.length !== 0 ? this.state.currentBill.fee.map((item, index) => {
									return (
										<div className="frammer--list" key={index}>
											<h5>{item.title}</h5>
											{item.isRed ? <h2 className='is-text-error'>{item.fee.toLocaleString()}元</h2> : <h4>{item.fee}元</h4>}
										</div>
									)
								}) : <div>
										<p className='body mb-0 is-text-darkgray50'>本月無使用記錄，謝謝。</p>
									</div>}
							</div>
						</div>
						{!this.state.currentBill.usageAmount.isPackage ?
							<div className="paper">
								<div className='rate-group'>
									<div className="d-flex justify-between flex-align-center mt-md-4 mt-2 mb-md-4 mb-2">
										<h4 className="mb-mb-3 mt-md-4 mt-0">國內行動上網用量</h4>
										<Link className='fui-button is-secondary is-small mb-0 mr-0' to={this.state.currentBill.upgradeLink}>
											<span className='text'>升級詳情</span>
										</Link>
									</div>

									<Grid container spacing={2}>
										<Grid item xs={12} sm={12}>
											<div className="is-bg-lightgray70 px-3 py-3">
												<h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
													累計上網用量
										</h5>
												<h2 className='mb-0'>{this.state.currentBill.usageAmount.amount}</h2>
											</div>
										</Grid>
									</Grid>
									<div className='mt-md-1 mt-4 mb-md-2 pt-md-3 page-action'>
										<Button link="#" className='mb-2 mb-sm-0' btnStyle='primary' size='large'>4.5G即時用量查詢</Button>
										<Button link="#" className='mb-0' btnStyle="secondary" size='large'>4.5G上網用量加購</Button>
									</div>
								</div>
							</div> :
							<div className="paper">
								<div className='rate-group'>
									<h4 className="mb-mb-3 mt-md-4 mt-0">國內行動上網用量</h4>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											<div className="is-bg-lightgray70 px-3 py-3">
												<h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
													遠傳分享包可共享上網用量
									</h5>
												<h2 className='mb-0'>{this.state.currentBill.usageAmount.amount}</h2>
											</div>
										</Grid>
										<Grid item xs={12} sm={6}>
											<div className="is-bg-lightgray70 px-3 py-3">
												<h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
													累積上網用量
									</h5>
												<h2 className='mb-0'>{this.state.currentBill.usageAmount.cumulation}</h2>
											</div>
										</Grid>
									</Grid>
									<h4 className="mb-mb-3 mt-md-5 pt-2 mt-0">各門號使用量</h4>
									<div className="frammer mb-md-4 mb-0">
										{this.state.currentBill.usage.map((item, index) => {
											return (
												<div className="frammer--list" key={index}>
													<h5>{item.title}</h5>
													{item.isRed ? <h2 className='is-text-error'>{item.usage}GB</h2> : <h4>{item.usage}GB</h4>}
												</div>
											)
										})}
									</div>
								</div>
							</div>
						}
					</div>


				</section>
				<SectionCollapseInfo
					title="注意事項"
					content={`
						1. 本服務提供尚未結算之估算金額，故僅供參考，實際以本公司出帳之金額為準。<br/>
						2. 上述金額不包含語音及上網月租費；已扣除您「合約贈送」的優惠及「月租費內含」的優惠。<br/>
						3. 「行動上網傳輸費」僅列示國內上網使用量，未含國際漫遊上網傳輸量。<br/>
						4. 若您門號曾進行帳戶異動(如一退一租、過戶、升級4G等)，僅可查得轉換後新帳戶之金額。<br/>
						5. 未結帳金額非即時計算，國內費用約延遲1日；國際漫遊使用記錄需待漫遊國出帳提供，故可能延遲計費1~2個月。
					`}
				/>
				<Modal
					onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
					onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
					isOpen={this.state.modalOpen}
					contentLabel='Example Modal'
					className='fui-prompt-modal is-large'>
					<div className='fui-prompt-modal-body'>
						<h5>未出帳金額/上網用量說明</h5>
						<p className='body mt-4 mb-5'>未出帳金額/上網用量為上次結帳日至本次結帳日之區間中，所產生之費用及上網用量，如下圖示意。此服務提供月租型用戶查詢尚未出帳金額及國內行動上網用量。</p>
						<div className="description">
							<div className="body is-text-gray70">
								<div className="mark-1" />
									上次結帳日
								</div>
							<div className="body is-text-darkgray50">
								<div className="mark-2" />
									結帳前時間點
								</div>
							<div className="body is-text-gray70">
								<div className="mark-3" />
									這次結帳日
								</div>
							<div className="usage-graph">
								未結帳金額/上網用量
								</div>
						</div>
						<Button className='mb-0' btnStyle='primary' onClick={(e) => this.setState({ modalOpen: false })}>
							我瞭解了
						</Button>
					</div>
				</Modal>
				{/* remove in production */}
				<div className="switcher py-5">
					<div className="fui-container">
						<div className="d-flex flex-column">
							<p>測試用資料切換</p>
							<Button onClick={() => this.setState({ currentBill: bill1 })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.2.1_未出帳金額/上網用量_主頁</Button>
							<Button onClick={() => this.setState({ currentBill: bill2 })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.2.2未出帳金額/上網用量_上網分享包</Button>
							<Button onClick={() => this.setState({ currentBill: bill3 })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.2.4未出帳金額/上網用量_空值</Button>
						</div>
					</div>
				</div>
			</main >
		)
	}
}

export default QueryInternet;