import React from 'react';
import Slider from 'react-slick';
import Link from '../../Link';

import ArrowLeftWhite from '../../animateArrow/ArrowLeftWhite';
import ArrowRightWhite from '../../animateArrow/ArrowRightWhite';
import ProductBgArrow from '../../animateArrow/ProductBgArrow';
import ProductCardWithMultiAction from '../../card/ProductCardWithMultiAction';

import PropTypes from 'prop-types';

const CarouselCards = (props) => {
  const [setting] = React.useState({
    dots: false,
    infinite: false,
    slidesToShow: 3,
    draggable: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  });

  const renderBg = () => {
    switch (props.bgStyle) {
      case 'arrow':
        return (
          <div className='section-bg'>
            <ProductBgArrow />
          </div>
        );
      default:
        return (
          <div className='section-bg'>
            <ArrowLeftWhite />
            <ArrowRightWhite />
          </div>
        );
    }
  };

  return (
    <div className="fui-product-cards">
      <h2 className='section-title'>{props.title}</h2>
      <Slider {...setting} className='horizontal-cards'>
        {props.cards.map((card, idx) => (
          <ProductCardWithMultiAction
            type={props.type}
            {...card} 
          />
        ))}
      </Slider>
      {props.more ? (
        <div className='more align-center'>
          <Link to={props.more.link} className='fui-button is-secondary'>
            <span className='text'>{props.more.text}</span>
          </Link>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

CarouselCards.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,  // product | movie | ring | music
  cards: PropTypes.arrayOf(PropTypes.objectOf(ProductCardWithMultiAction)),
  bgStyle: PropTypes.string,
  more: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
};

export default CarouselCards;
