import React from 'react';
import Collapse from '../collapse/Collapse';

import PropTypes from 'prop-types';

class PanelInformation extends React.Component {
    render() {
        return (
            <div className="fui-panel-information">
                <Collapse title={this.props.title} date={this.props.date} content={this.props.content} />
            </div>
        )
    }
}

PanelInformation.propTypes = {
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    content: PropTypes.string.isRequired
}

export default PanelInformation;