import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';

import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Tooltip from '../../components/Tooltip';

class NonLoginPrepaidDepositPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      form: {
        phone_number: { value: '', required: false },
        id_number: { value: '', required: false },
        reCaptcha: { value: '', required: false },
      }
    }
  }

  enableButton = (e) => {
    this.setState({ canSubmit: true });
  }

  disableButton = (e) => {
    this.setState({ canSubmit: false });
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    this.setState({
      form: newForm
    })
  }

  submit = () => {
    
  }

  render() {
    return (
      <main>
        <Header />
        <NavAnchor 
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '預付卡專區', link: '/' },
            { text: '預付卡儲值', link: '/' },
          ]}
          color='black'
        />
        <div className='fui-banner is-e-service fui-login-section'>
          <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-cbu-arrow-pattern.png'} alt='' className="arrow" />
          <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/bg-cbu-colorslash-pattern-1440x620.png'} alt='' className="bg d-none d-md-block" />
          <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/bg-cbu-colorslash-pattern-375x704.png'} alt='' className="bg d-none d-md-block" />
          <div className="fui-container">
            <h2 className="mb-0">預付卡快速儲值</h2>
            <h3 className="is-text-darkgray50">不需輸入遠傳官網帳號及密碼，只要輸入身分資料即可進行預付卡門號或安心講門號儲值。</h3>
            <div className="login-paper">
              <Formsy>
                <Grid container spacing={8}>
                  <Grid item md={6}>
                    <LabelInput 
                      label="門號"
                      name='phone_number'
                      placeholder="請輸入門號"
                      required={this.state.form.phone_number.required}
                      value={this.state.form.phone_number.value}
                      onChange={this.onChange} 
                    />
                    
                    <div className="">
                      <span className="float-right tooltip-container" ref={this.tooltipContent}>
                        <Tooltip
                          parentNode={null}
                          content={<i className='icon-information'></i>}
                          trigger="click"
                          tooltip={'Tooltip Tooltip Tooltip'} 
                        />
                      </span>
                      <LabelInput 
                        label="身份證號碼／統編"
                        name='id_number'
                        placeholder="請輸入身份證號碼／統編"
                        required={this.state.form.id_number.required}
                        value={this.state.form.id_number.value}
                        onChange={this.onChange} 
                      />
                    </div>

                    <div className="recaptcha-wrap">
                      <img src='https://cldup.com/p-jwfiXPY0-3000x3000.png' alt='recaptcha' className="mb-3" />
                    </div>

                    <Button btnStyle='primary' size='large' onClick={this.submit} disabled={!this.state.canSubmit} className="is-sm-block">確認送出</Button>
                  </Grid>
                  

                  <Grid item md={6}>
                    <div className="login-ad">
                      <h2 className="is-text-white mb-0">遠傳易付卡飆網免綁約</h2>
                      <h4 className="is-text-white">新用戶首購上網服務，最高可享雙倍送</h4>
                      <Button btnStyle='primary' link='' size='large' reverse={true} className="is-sm-block">了解更多</Button>
                    </div>
                  </Grid>
                </Grid>
              </Formsy>
            </div>
          </div>
        </div>
      
      </main>
    )
  }
}

export default NonLoginPrepaidDepositPage;