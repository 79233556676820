import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';

import Button from '../../components/Button';
import DatePicker from "react-datepicker";
import FormBanner from '../../components/partials/banner/FormBanner';
import Select from '../../components/form/Select';
import NavContentTab1 from '../../components/partials/NavContentTab1';
// import Tab from '../../components/tab/Tab'
import TabPane from '../../components/tab/TabPane'

class parkingfeeChangeRecordPage extends React.Component {
  constructor(props) {
    super(props);
    this.form_1 = React.createRef();
    this.form_2 = React.createRef();
    this.state = {
      currentTab: 0,
      canSubmit1: false,
      canSubmit2: false,
      form_1: {
        updateStartAt: {value: '', required: false},
        updateEndAt: {value: '', required: false},
        car_number: {value: '', required: false},
      },
      form_2: {
        applyStartAt: {value: ''},
        applyEndAt: {value: ''}
      }
    };
  };

  tabChange = (i) => {
    this.setState({
      currentTab: i
    })

    this.forceUpdate();
  }

  onChange = (n, v) => {
    let form = Object.assign(this.state.currentTab === 0 ? this.state.form_1 : this.state.form_2);
    form[n].value = v;

    if (this.state.currentTab === 0)
      this.setState({
        form_1: form
      })
    else
      this.setState({
        form_2: form
      })
  }

  render() {
    const {currentTab, form_1, form_2} = this.state;
    return (
      <main>
        
        <NavAnchor 
          pageTitle='異動記錄查詢'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="停車費異動記錄查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />

        <NavContentTab1
          tabs={{
            name: 'page-tab',
            list: [
              { label: '車輛異動記錄查詢' },
              { label: '服務異動記錄查詢' },
            ]
          }}
          onChange={this.tabChange}
        />
        <TabPane active={currentTab === 0}>
          <section className='pt-0 pt-md-5'>
            <div className="fui-container">
              <Formsy ref={this.form_1}>
                <div className="pt-4">
                  <div className='paper'>
                    <h5 className="mt-0 mt-lg-4 mb-2">異動日期</h5>

                    <Grid container>
                      <Grid item xs={12} md={5}>
                        <div className="form-group">
                          <DatePicker 
                            name="updateStartAt"
                            dateFormat="Y/MM/dd"
                            lang="zh-TW"
                            minDate={new Date()}
                            selected={form_1.updateStartAt.value}
                            onChange={date => this.onChange('updateStartAt', date)}
                          />
                        </div>
                      </Grid>
                      <div className="datepicker-divider">~</div>
                      <Grid item xs={12} md={5}>
                        <div className="form-group">
                          <DatePicker 
                            name="updateEndAt"
                            dateFormat="Y/MM/dd"
                            lang="zh-TW"
                            minDate={new Date()}
                            selected={form_1.updateEndAt.value}
                            onChange={date => this.onChange('updateEndAt', date)}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <div className="form-group">
                          <label>車輛號碼</label>
                          <Select 
                            required={true}
                            name="car_number"
                            label="請選擇"
                            options={[
                              {text: '全部', value: '1'},
                              {text: '8656-YB', value: '2'},
                            ]}
                            onChange={this.onChange}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <div className="fui-table-response">
                      <table className="option-table center-table scroll-table">
                        <thead>
                          <tr>
                            <th>日期</th>
                            <th>車輛種類</th>
                            <th>車輛號碼</th>
                            <th>申請狀態</th>
                            <th>代收地區</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>2020/03/16</td>
                            <td>汽車</td>
                            <td>8656-YB</td>
                            <td>刪除車輛成功</td>
                            <td>台北公有停車場</td>
                          </tr>
                          <tr>
                            <td>2020/03/18</td>
                            <td>汽車</td>
                            <td>8656-YB</td>
                            <td>刪除車輛成功</td>
                            <td>台北公有停車場</td>
                          </tr>
                          <tr>
                            <td>2020/03/21</td>
                            <td>汽車</td>
                            <td>8656-YB</td>
                            <td>新增車輛成功</td>
                            <td>台北公有停車場</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Formsy>
              <Button btnStyle='primary' link='' size='large' className="is-sm-block">停車費代收設定</Button>
            </div>
          </section>
        </TabPane>

        <TabPane active={this.state.currentTab === 1}>
          <section className='pt-0 pt-md-5'>
            <div className="fui-container">
              <Formsy ref={this.form_2}>
                <div className="pt-4">
                  <div className='paper'>
                    <h5 className="mt-0 mt-lg-4 mb-2">申請日期</h5>

                    <Grid container>
                      <Grid item xs={12} md={5}>
                        <div className="form-group">
                          <DatePicker 
                            name='applyStartAt'
                            dateFormat="Y/MM/dd"
                            lang="zh-TW"
                            minDate={new Date()}
                            selected={form_2.applyStartAt.value}
                            onChange={date => this.onChange('applyStartAt', date)}
                          />
                        </div>
                      </Grid>
                      <div className="datepicker-divider">~</div>
                      <Grid item xs={12} md={5}>
                        <div className="form-group">
                          <DatePicker 
                            name='applyEndAt'
                            dateFormat="Y/MM/dd"
                            lang="zh-TW"
                            minDate={new Date()}
                            selected={form_2.applyEndAt.value}
                            onChange={date => this.onChange('applyEndAt', date)}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <div className="fui-table-response">
                      <table className="option-table center-table scroll-table">
                        <thead>
                          <tr>
                            <th>日期</th>
                            <th>申請管道</th>
                            <th>申請狀態</th>
                            <th>生效日期</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>2020/03/16</td>
                            <td>FETNET</td>
                            <td>申請成功</td>
                            <td>2020/03/18</td>
                          </tr>
                          <tr>
                            <td>2020/03/19</td>
                            <td>FETNET</td>
                            <td>申請成功</td>
                            <td>2020/03/20</td>
                          </tr>
                          <tr>
                            <td>2020/03/22</td>
                            <td>FETNET</td>
                            <td>申請成功</td>
                            <td>2020/03/14</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </Formsy>
              <Button btnStyle='primary' link='' size='large' className="is-sm-block">停車費代收設定</Button>
            </div>
          </section>
          
        </TabPane>

      </main>
    )
  }
}

export default parkingfeeChangeRecordPage;