import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner'
import Collapse from '../../components/collapse/Collapse';
import Button from '../../components/Button';
import ProgressBar from '../../components/ProgressBar';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import numberFormatter from '../../utils/numberFormatter'

import * as Mock from '../../mock/EServiceIndex';
import * as PrepaidService from '../../service/PrepaidService';
import * as CfgMsgService from '../../service/CfgMsgService';

const progressList = [
  {
    title: '絕配 1,399月租(內含 4.5GB)',
    end_at: '2020/10/31',
    total: 8192,
    use: 1524
  },
  {
    title: '上網傳輸量優惠 4GB*24個月',
    end_at: '2020/09/12',
    total: 500,
    use: 0
  },
  {
    title: '上網計量包 200MB',
    end_at: '2020/12/12',
    total: 200,
    use: 165
  },
  {
    title: '上網計量包 200MB',
    end_at: '2020/12/31',
    total: 200,
    use: 0
  },
  {
    title: '上網計量包 200MB',
    end_at: '2020/12/31',
    total: 200,
    use: 0
  },
  {
    title: '上網計量包 200MB',
    end_at: '2020/12/31',
    total: 200,
    use: 0
  },
]

class prepaidDetailQueryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      usageInfo: {}
    }

    PrepaidService.prepaidUsage().then(data => {
      // debugger
      this.setState({
        usageInfo: data
      });
    })

    CfgMsgService.cfgMsg().then(data => {
      this.setState({
        cfgMsg: data
      });
    })
  }

  render() {
    const { usageInfo, cfgMsg } = this.state;

    return (
      <main>
        
        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <FormBanner
          title="預付卡餘額/上網用量"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />

        <section className="pb-0">
          <div className="fui-container">
            <h4 className="is-text-darkgray50">{cfgMsg}</h4>
            <div className="fui-segments">
              <div className="fui-segment">
                <h4>門號資訊</h4>
                <Grid container spacing={2} className='fui-boards mb-0'>
                  <Grid item>
                    <div className="fui-board">
                      <h5>門號</h5>
                      <h3>{usageInfo.msisdn}</h3>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="fui-board">
                      <h5>門號類別</h5>
                      <h3>3G 易付卡</h3>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="fui-board">
                      <h5>門號效期</h5>
                      <h3>{usageInfo.expireDate}</h3>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-0">
          <div className="fui-container">
            <h2>通話金餘額/上網用量</h2>
            <div className="fui-segments">
              <div className="fui-segment">
                <h4>可使用餘額</h4>
                <Grid container spacing={2} className='fui-boards mb-0'>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="fui-board">
                      <h5>通話金餘額</h5>
                      <h3>{usageInfo.rechargeBalance}</h3>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="fui-board">
                      <h5>贈送餘額</h5>
                      <h3>{usageInfo.rewardBalance}</h3>
                    </div>
                  </Grid>
                </Grid>
                <div className='page-action mt-0'>
                  <Button btnStyle='primary' size='large'>我要儲值</Button>
                </div>
              </div>

              <Collapse title="備註說明" content={`
                詳細資費方案，請點選此<a href='#' target="_blank">查看更多</a>
              `} />
            </div>
          </div>
        </section>
        <section>
          <div className="fui-container">
            <h2>上網用量明細</h2>
            <div className="fui-segments">
              <div className="fui-segment">
                <h4>用量總覽</h4>
                <ProgressBar progress={60} bold={true} color='red' />
                {usageInfo.totalUsagePlan ? (
                  <Grid container spacing={2} className='fui-boards mt-4 mb-0'>
                    <Grid item>
                      <div className="fui-board">
                        <h5>可使用</h5>
                        <h3>{usageInfo.totalUsagePlan.totalUsage} <span className='body1'>(2,548MB)</span></h3>
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="fui-board">
                        <h5>已使用</h5>
                        <h3>{usageInfo.totalUsagePlan.usedUsage} <span className='body1'>(1,524MB)</span></h3>
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="fui-board">
                        <h5>剩餘</h5>
                        <h3>{usageInfo.totalUsagePlan.overageUsage} <span className='body1'>(1,024MB)</span></h3>
                      </div>
                    </Grid>
                  </Grid>
                ) : null
                }
                <div className='page-action'>
                  <Button btnStyle='primary' size='large' className='mt-3'>上網用量加購</Button>
                </div>
              </div>
              <div className="fui-segment">
                <h4>使用明細</h4>
                {
                  progressList.map((progress, i) => (
                    this.state.showMore || (!this.state.showMore && i < 4) ? (
                      <div className='fui-progress-box' key={`progress-container-${i}`}>
                        <h6 className="my-0">{progress.title}</h6>
                        <div>
                          <div className="body1 last-block">
                            剩餘 <span className='last'>{progress.total - progress.use}MB</span>
                          </div>
                          <div className="body1">
                            使用期限 {progress.end_at}<br />
                            共 {progress.total < 1000 ? progress.total + 'MB' : numberFormatter(progress.total / 1000, 2)}GB ({numberFormatter(progress.total)}MB) /
                            已使用 {numberFormatter(progress.use / 1000, 2)}GB ({numberFormatter(progress.use)}MB)
                          </div>
                          <ProgressBar progress={numberFormatter(progress.use / progress.total * 100)} />
                        </div>
                      </div>
                    ) : null
                  ))
                }

                {!this.state.showMore ? (
                  <div className="align-center my-5">
                    <div onClick={e => this.setState({ showMore: !this.state.showMore })} role="button">看更多 <i className="icon-plus"></i></div>
                  </div>
                ) : null}

              </div>
              <Collapse title="上網用量計算說明" content={`
                <div class="fui-table-response mb-1">
                  <table>
                    <thead>
                      <tr>
                        <th width="50%">上網用量</th>
                        <th width="50%">預估可用時間</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>700,000 MB以上</td>
                        <td>40 小時以上</td>
                      </tr>
                      <tr>
                        <td>700,000 ~ 600,000MB</td>
                        <td>30 ~ 40 小時</td>
                      </tr>
                      <tr>
                        <td>500,000 ~ 600,000MB</td>
                        <td>20 ~ 30 小時</td>
                      </tr>
                      <tr>
                        <td>400,000 ~ 500,000MB</td>
                        <td>10 ~ 20 小時</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="fui-table-response mb-1">
                  <table>
                    <thead>
                      <tr>
                        <th colspan="2">通訊及社群軟體上網用量</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="50%">通訊 Line 傳送 10 字</td>
                        <td width="50%">消耗約 160KB</td>
                      </tr>
                      <tr>
                        <td>檢查一次 Mail</td>
                        <td>消耗約 800KB</td>
                      </tr>
                      <tr>
                        <td>至Fb上傳照片、打卡</td>
                        <td>消耗約 8,192KB</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p>每項服務及行動上網應用之用量不同，上述用量為平均值非絕對值，建議以實際使用、測量為主。</p>
              `} />
            </div>
          </div>
        </section>
        {/* 計日型 start */}
        <section>
          <div className="fui-container">
            <h2>上網用量明細</h2>
            <div className="fui-segments">
              <div className="fui-segment">
                <h4>用量總覽</h4>
                <ProgressBar progress={60} bold={true} color='red' />
                <Grid container spacing={2} className='fui-boards mt-4 mb-0'>
                  <Grid item>
                    <div className="fui-board">
                      <h5>目前使用</h5>
                      <h3>計日型無限制上網用量</h3>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="fui-board">
                      <h5>使用期限</h5>
                      <h3>2020/06/14</h3>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="fui-board">
                      <h5>不降速剩餘可用總量</h5>
                      <h3>200GB</h3>
                    </div>
                  </Grid>
                </Grid>
                <div className='page-action'>
                  <Button btnStyle='primary' size='large'>上網用量加購</Button>
                </div>
              </div>
              <div className="fui-segment">
                <h4>使用明細</h4>
                <div className='fui-progress-box'>
                  <h6 className="my-0">易付飆網30日(100GB後降速)</h6>
                  <div>
                    <div className="body1 last-block">
                    不降速剩餘可用量 <span className='last'>100GB</span>
                    </div>
                    <div className="body1">
                      使用期限 使用期限 2020/10/31
                    </div>
                  </div>
                </div>
                <div className='fui-progress-box'>
                  <h6 className="my-0">易付飆網30日(100GB後降速)</h6>
                  <div>
                    <div className="body1 last-block">
                    不降速剩餘可用量 <span className='last'>100GB</span>
                    </div>
                    <div className="body1">
                      使用期限 使用期限 2020/10/31
                    </div>
                  </div>
                </div>
                <div className='fui-progress-box'>
                  <h6 className="my-0">超4代易付外勞30MB_30天_$80(100型450MB)</h6>
                  <div>
                    <div className="body1 last-block">
                      剩餘 <span className='last'>{10}MB</span>
                    </div>
                    <div className="body1">
                      使用期限 使用期限 2020/10/31<br />
                      共 30.00MB / 已使用 20MB
                    </div>
                    <ProgressBar progress={numberFormatter(80)} />
                  </div>
                </div>
                {!this.state.showMore ? (
                  <div className="align-center my-5">
                    <div onClick={e => this.setState({ showMore: !this.state.showMore })} role="button">看更多 <i className="icon-plus"></i></div>
                  </div>
                ) : null}

              </div>
              <Collapse title="上網用量計算說明" content={`
                <div class="fui-table-response mb-1">
                  <table>
                    <thead>
                      <tr>
                        <th width="50%">上網用量</th>
                        <th width="50%">預估可用時間</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>700,000 MB以上</td>
                        <td>40 小時以上</td>
                      </tr>
                      <tr>
                        <td>700,000 ~ 600,000MB</td>
                        <td>30 ~ 40 小時</td>
                      </tr>
                      <tr>
                        <td>500,000 ~ 600,000MB</td>
                        <td>20 ~ 30 小時</td>
                      </tr>
                      <tr>
                        <td>400,000 ~ 500,000MB</td>
                        <td>10 ~ 20 小時</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="fui-table-response mb-1">
                  <table>
                    <thead>
                      <tr>
                        <th colspan="2">通訊及社群軟體上網用量</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="50%">通訊 Line 傳送 10 字</td>
                        <td width="50%">消耗約 160KB</td>
                      </tr>
                      <tr>
                        <td>檢查一次 Mail</td>
                        <td>消耗約 800KB</td>
                      </tr>
                      <tr>
                        <td>至Fb上傳照片、打卡</td>
                        <td>消耗約 8,192KB</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p>每項服務及行動上網應用之用量不同，上述用量為平均值非絕對值，建議以實際使用、測量為主。</p>
              `} />
            </div>
          </div>
        </section>
        {/* 計日型 end */}
        <section>
          <div className="fui-container">
            <h2>上網用量明細</h2>
            <div className="fui-segments">
              <div className="fui-segment">
                <div className="body1 is-text-darkgray50">
                  您目前尚未持有任何漫遊上網用量方案，謝謝。
                </div>
                <div className='page-action mt-4'>
                  <Button btnStyle='primary' size='large'>上網用量加購</Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
          1.預付卡提供通信費儲值服務，儲值金額分為300元、500元及1000元三種，單一門號之儲值上限為5000元。<br/>
          2.請務必於易付卡門號有效日前一天進行儲值，以保有您專屬門號的權益<br/>
          3.加購上網優惠請至「<a href="#">易付卡上網加購</a>」
        `}
        />
      </main>
    );
  }
}

export default prepaidDetailQueryPage;
