import axios from 'axios';
import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import RadioButtons from '../../components/form/RadioButtons';
import Select from '../../components/form/Select';
import LabelInput from '../../components/form/LabelInput';

import * as Mock from '../../mock/RateChange';
import * as RateService from '../../service/RateService';

const apiHost = 'https://staging.vas.fetnet.net/eService_Revamp/api';

class RateChangeInitPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      canSubmit: false,
      step: 0,
      rateInfo: null,
      dataOption: [],
      voiceOption: [],
      form: {
        plan: { value: '1', required: true },
        wifi: { value: '4G高速飆網699', required: true },
        voice: { value: '4G語音0型', required: true },
        id_number: { value: '', required: true },
      },
    };

    RateService.getInfo().then((response) => {
      // 將選項調整為 select 格式
      let dataOption = response.dataOption4GList.reduce((accumulator, value, index) => {
        accumulator.push({
          value: value.code,
          text: value.name,
        });
        return accumulator;
      }, []);

      let voiceOption = response.voiceOption4GList.reduce((accumulator, value, index) => {
        accumulator.push({
          value: value.code,
          text: value.name,
        });
        return accumulator;
      }, []);

      // 將資料寫入 state
      this.setState({
        voiceOption: voiceOption,
        dataOption: dataOption,
        rateInfo: response,
        form: {
          plan: { value: '1', required: true },
          wifi: { value: dataOption[0].value, required: true },
          voice: { value: voiceOption[0].value, required: true },
          id_number: { value: '', required: true },
        },
      });
    });
  }

  componentDidMount() {}

  onChange = (name, value) => {
    let form = Object.assign(this.state.form);
    form[name].value = value;

    this.setState({
      form: form,
    });

    this.forceUpdate();
  };

  nextStep = () => {
    this.setState({ step: 1 });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  submit = () => {
    console.log('submit');
  };

  render() {
    return (
      <main>
        
        <NavAnchor {...Mock.navAnchor} />
        <FormBanner
          title='費率變更/異動'
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section>
          <div className='fui-container'>
            <div className='paper'>
              <h4 className='mt-0'>您目前的費率</h4>
              <div className='rate-plan-box'>
                <h5>方案</h5>
                <h2>{this.state.rateInfo ? this.state.rateInfo.vdRate : null}</h2>
              </div>
            </div>

            <Formsy ref={this.form} onValid={this.enableButton} onInvalid={this.disableButton}>
              <div className='paper'>
                <h3>請選擇加購方案</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <RadioButtons
                      label='方案類別'
                      name='plan'
                      options={[
                        { value: '1', label: '4G高速飆網' },
                        { value: '2', label: '4G絕配費率' },
                      ]}
                      selected={this.state.form.plan.value}
                      getSelected={(v) => this.onChange('plan', v)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className='form-group'>
                      <label>數據上網</label>
                      <Select
                        name='wifi'
                        options={this.state.dataOption}
                        value={this.state.form.wifi.value}
                        onChange={this.onChange}
                      />
                    </div>
                  </Grid>
                  {this.state.form.plan.value === '1' ? (
                    <Grid item xs={12} sm={12} md={6}>
                      <div className='form-group'>
                        <label>語音通話</label>
                        <Select
                          name='voice'
                          options={this.state.voiceOption}
                          value={this.state.form.voice.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </div>
              <section className={`pb-0 ${this.state.step === 1 ? 'd-block' : 'd-none'}`}>
                <h3>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h3>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <LabelInput
                      name='id_number'
                      validationErrors={{
                        isDefaultRequiredValue: '請輸入證照號碼',
                      }}
                      required={this.state.form.id_number.required}
                      label='證照號碼'
                      placeholder='請輸入證照號碼'
                      value={this.state.form.id_number.value}
                      onChange={this.onChange}
                    />
                    <div className='body1 is-text-darkgray50'>
                      身分說明：
                      <br />
                      個人戶：身分證字號
                      <br />
                      公司戶：統一編號或稅籍編號
                      <br />
                      外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                    </div>
                  </Grid>
                </Grid>
              </section>

              {this.state.step === 0 ? (
                <div className='page-action'>
                  <Button btnStyle='primary' onClick={this.nextStep} size='large'>
                    下一步
                  </Button>
                  <Button btnStyle='secondary' size='large'>
                    取消
                  </Button>
                </div>
              ) : (
                <div className='page-action'>
                  <Button btnStyle='primary' onClick={this.submit} size='large' disabled={!this.state.canSubmit}>
                    確認
                  </Button>
                  <Button btnStyle="secondary" size='large'>取消</Button>
                </div>
              )}
            </Formsy>
          </div>
        </section>
        <SectionCollapseInfo {...Mock.collapseNote} />
      </main>
    );
  }
}

export default RateChangeInitPage;
