export const InvoiceTable = `
<table width="980" border="1" align="center" cellpadding="0" cellspacing="0">
<tbody><tr>
  <td>
    <table width="970" border="0" align="center" cellpadding="1" cellspacing="5">
      <tbody><tr>
        <td>
          <div align="center">
            <table width="970" border="0" cellpadding="1" cellspacing="1">
              <tbody><tr>
                <td width="100">
                  <img width="150" height="45" alt="遠傳電信" src="http://fmail.fetnet.net/fareastone_erp_a/FET_logo.jpg">
                </td>
                <td width="124">&nbsp;</td>
                <td colspan="3">
                  <div align="center">
                    <font size="5" face="微軟正黑體">
                      <strong>電子發票開立資訊</strong>
                    </font>
                  </div>
                </td>
                <td width="194" style="padding-top:5px;padding-bottom:0px">
                  <table width="120" border="1" align="center" cellpadding="1" cellspacing="0">
                    <tbody><tr>
                      <td height="17">
                        <div align="center">
                          <strong>
                            <font color="#FF0000" size="+2" face="微軟正黑體">開立</font>
                          </strong>
                        </div>
                      </td>
                    </tr>
                  </tbody></table>
                </td>
              </tr>
              <tr>
                <td colspan="3">開立單位：<font color="#000000">97179430 遠傳電信股份有限公司</font>
                </td>
                <td width="221">&nbsp;</td>
                <td width="185">&nbsp;</td>
                <td>
                  <div align="center">
                    <font color="#FF0000" size="2">
                      <strong>捐贈</strong>
                    </font>
                  </div>
                </td>
              </tr>
            </tbody></table>
            <font size="4" face="微軟正黑體"></font>
          </div>
        </td>
      </tr>
      <tr>
        <td style="padding-left:5px;padding-right:5px">
          <table width="970" border="1" align="center" cellpadding="1" cellspacing="0">
            <tbody><tr>
              <td height="20" bgcolor="#FFFFFF">
                <table width="970" border="0" align="center" cellpadding="1" cellspacing="2">
                  <tbody><tr>
                    <td width="109" height="21" bgcolor="#E0E0E0">發票號碼：</td>
                    <td width="321">
                      <font color="#000000">WW4762****</font>
                    </td>
                    <td width="121" bgcolor="#E0E0E0">開立對象：</td>
                    <td width="401">
                      <font color="#000000">非營業人                        </font>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#E0E0E0">發票日期：</td>
                    <td>
                      <font color="#000000">2019/12/25</font>
                    </td>
                    <td bgcolor="#E0E0E0">買受人統一編號：</td>
                    <td>
                      <font color="#000000"></font>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#E0E0E0">隨機碼：</td>
                    <td>
                      <font color="#000000">4196</font>
                    </td>
                    <td bgcolor="#E0E0E0">買受人名稱：</td>
                    <td>
                      <font color="#000000"></font>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#E0E0E0"></td>
                    <td>
                      <font color="#000000"></font>
                    </td>
                    <td bgcolor="#E0E0E0">門號/會員帳號：</td>
                    <td>
                      <font color="#000000">0903524549</font>
                    </td>
                  </tr>
                </tbody></table>
              </td>
            </tr>
          </tbody></table>
        </td>
      </tr>
      <tr>
        <td>
          <table width="970" border="1" align="center" cellpadding="1" cellspacing="0">
            <tbody><tr>
              <td height="20" bgcolor="#FFFFFF">
                <table width="970" border="0" align="center" cellpadding="1" cellspacing="2">
                  <tbody><tr>
                    <td width="109" height="21" bgcolor="#E0E0E0">載具名稱：</td>
                    <td width="321">
                      <font color="#000000"></font>
                    </td>
                    <td width="121" bgcolor="#E0E0E0">載具編號：</td>
                    <td width="401">
                      <font color="#000000"></font>
                    </td>
                  </tr>
                </tbody></table>
              </td>
            </tr>
          </tbody></table>
        </td>
      </tr>
      <tr>
        <td style="padding-top:5px;padding-bottom:0px">
          <table width="970" border="1" align="center" cellpadding="1" cellspacing="1" bgcolor="#FFFFFF">
            <tbody><tr>
              <td height="20" colspan="2" bgcolor="#FFFFFF">
                <table width="970" border="0" cellpadding="1" cellspacing="2">
                  <tbody><tr bgcolor="#E0E0E0">
                    <td width="600" bgcolor="#E0E0E0">
                      <div align="center">品名</div>
                    </td>
                    <td width="76">
                      <div align="center">稅別</div>
                    </td>
                    <td width="66">
                      <div align="center">數量</div>
                    </td>
                    <td width="90">
                      <div align="center">單價(內含稅) </div>
                    </td>
                    <td width="122">
                      <div align="center">金額(內含稅)</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div align="left">
                        <font face="Arial, Helvetica, sans-serif">易付卡飆網計日型(信用卡/銀行帳戶付費)</font>
                        <br>                        </div>
                      </td>
                      <td>
                        <div align="center">
                          <font face="Arial, Helvetica, sans-serif">應稅</font>
                        </div>
                      </td>
                      <td>
                        <div align="right" style="padding-right:3px">
                          <font face="Arial, Helvetica, sans-serif">1</font>
                        </div>
                      </td>
                      <td>
                        <div align="right" style="padding-right:3px">
                          <font face="Arial, Helvetica, sans-serif">100</font>
                        </div>
                      </td>
                      <td>
                        <div align="right" style="padding-right:3px">
                          <font face="Arial, Helvetica, sans-serif">100</font>
                        </div>
                      </td>
                    </tr>
                  </tbody></table>
                </td>
              </tr>
              <tr>
                <td height="20" valign="top" bgcolor="#FFFFFF">
                  <table width="668" border="0" cellpadding="1" cellspacing="2">
                    <tbody><tr bgcolor="#E0E0E0">
                      <td colspan="2">
                        <div align="center">備註欄位</div>
                      </td>
                    </tr>
                    <tr>
                      <td width="109" height="21">交易序號：</td>
                      <td width="482">
                        <font face="Arial, Helvetica, sans-serif">115770837735449</font>
                      </td>
                    </tr>
                    <tr>
                      <td>交易日期：</td>
                      <td>
                        <font face="Arial, Helvetica, sans-serif">2019/12/23</font>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">折讓單：</td>
                      <td>
                        <font face="Arial, Helvetica, sans-serif"></font>
                      </td>
                    </tr>
                    <tr>
                      <td>列印備註：</td>
                      <td>
                        <font face="Arial, Helvetica, sans-serif"></font>
                      </td>
                    </tr>
                  </tbody></table>
                </td>
                <td valign="top" bgcolor="#FFFFFF">
                  <table width="300" border="0" cellpadding="1" cellspacing="2">
                    <tbody><tr>
                      <td bgcolor="#E0E0E0">發票總金額：</td>
                      <td>
                        <div align="right" style="padding-right:3px">
                          <font face="Arial, Helvetica, sans-serif">100</font>
                        </div>
                      </td>
                    </tr>
                  </tbody></table>
                </td>
              </tr>
            </tbody></table>
          </td>
        </tr>
        <tr>
          <td height="21" style="padding-left:6px">說明：此為電子發票開立資訊，非電子發票證明聯。</td>
        </tr>
      </tbody></table>
    </td>
  </tr>
</tbody></table>
`