import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import FormBanner from '../../components/partials/banner/FormBanner';

import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';


class getPhoneBillPaySettingCheckResetPasswordPage extends React.Component {
  constructor (props) {
    super(props);
    this.form = React.createRef();

    this.state = {
      canSubmit: false,
      form: {
        id_number: {value: '', required: true}
      }
    }
  }

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }

  onChange = (n, v) => {
    let form = Object.assign(this.state.form)
    form[n].value = v;

    this.setState({
      form: form
    })
  }

  submit = () => {
    this.props.history.push('/phoneBillPaySettingController/getPhoneBillPaySettingValidateRocidPage')
  }

  render () {
    const { form } = this.state;

    return (
      <main>
        
        <NavAnchor
          pageTitle='帳單代收'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner 
          title="交易密碼重設"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} 
        />
        <section>
          <div className="fui-container">
            <Grid container justify='center'>
              <Grid item xs={12} sm={12} md={10}>
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                  <div className='form-group'>
                    <h4 className='form-description is-text-darkgray50'>為了確保你的帳戶安全，我們將進行身份驗證，確認需要重設交易密碼？</h4>
                    <div className='is-text-accent text-sm'>
                      <span className='required-dot'></span> 必填
                    </div>
                  </div>
                  <div className='form-group'>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          name='id_number'
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入身份證字號',
                          }}
                          required={form.id_number.required}
                          label="身份證字號"
                          value={form.id_number.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="form-group two-buttons">
                    <Button btnStyle='primary' size='large' onClick={this.submit} disabled={!this.state.canSubmit}>下一步</Button>
                    <Button btnStyle='secondary' link='/e-service' size='large'>取消</Button>
                  </div>
                </Formsy>
              </Grid>
            </Grid>
          </div>
        </section>
      </main>
    )
  }
}

export default getPhoneBillPaySettingCheckResetPasswordPage;