
export const faqTab2 = {
  title: '常見問題',
  tabs: [
    { label: '會員查詢與管理' },
    { label: '遠傳生活圈' }
  ],
  collapseList: [
    [
      {
        title: '如何成為生活+會員?',
        content: '只要您以遠傳電信門號或其他電信公司門號登入遠傳生活+網站或APP，即可成為遠傳生活+會員。'
      },
      {
        title: '參加遠傳生活+有什麼好處?',
        content: '舉凡加入會員、消費、生日皆享遠傳幣回饋，更可用遠傳幣購買與遠傳生活+嚴選商品，進行折抵或兌換，除定價遠低於市場售價，1遠傳幣折抵價值亦遠大於新台幣1元，讓您的遠傳幣既超值又划算！'
      },
      {
        title: '生活+會員身分等級差在哪?',
        content: '遠傳生活+會員共分為5個等級，您可從遠傳生活+APP的【會員】頁的最上方看到您所屬的會員等級，並可在等級下方得到累積該資格的計算期間，與進入下個等級尚須累積的計等遠傳幣。詳細的等級計算方式可參考【遠傳生活+會員機制與各等級條件說明】。'
      },
    ],
    [
      {
        title: '遠傳幣是甚麼?',
        content: `遠傳幣分類方式有以下幾類：<br/>1.依效期可分為1天期/3天期/7天期/15天期/30天期/1年期/3年期，共7種。<br/> 2.依用途可分為：可累計會員等級型/不可可累計會員等級型，共2種。目前，生活+會員之基本回饋均為可累計會員等級的3年期遠傳幣，各合作夥伴則提供不可累計會員等級的各效期遠傳幣。`
      },
      {
        title: '在哪裡可以用遠傳幣?',
        content: '您可在遠傳生活+的官網、APP以及各家合作夥伴的活動中找到"遠傳幣"icon，您如果有符合該活動所需的遠傳幣數量便可立即兌換，如該活動同時提供遠傳幣回饋，您也可以立即享有!'
      },
      {
        title: '甚麼時候可以拿到我的點數?',
        content: '當您參加了遠傳幣的回饋活動，系統會依各合作夥伴的作業時間，最晚在30天內將您應得的遠傳幣撥到您的帳戶中，您可從交易記錄裡看到每一筆交易與其發幣狀態。'
      },
    ]
  ]
}
export const faqTab = {
  title: '常見問題',
  tabs: [
    { label: '電子帳單' },
    { label: '帳單/費用' },
    { label: '繳費服務' },
  ],
  collapseList: [
    [
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
    ],
    [
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
    ],
    [
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
    ]
  ]
}

export const shortcut1 = [
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-invoice-inquiry.svg',
    text: '帳單查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-invoice-detail.svg',
    text: '帳單明細',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-outstanding-account-network-traffic.svg',
    text: '未結帳金額/上網用量',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-pay-online.svg',
    text: '線上繳費',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-transaction-record.svg',
    text: '繳費/代收交易/發票查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-contract-inquiry.svg',
    text: '合約資訊查詢',
  }
]

export const shortcut2 = [
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-online-top-up.svg',
    text: '上網儲值',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-phone-top-up.svg',
    text: '通話金儲值',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-blance-online-expiry-date-search.svg',
    text: '餘額/上網/到期日查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-roaming-online-purchase.svg',
    text: '漫遊上網加購',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-invoice-search.svg',
    text: '儲值發票查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-help-center.svg',
    text: '常見問題',
  }
]

export const shortcut = [
  {
    link: '#',
    image: '/resources/cbu/img/friday-shopping-1.svg',
    text: 'friDay購物',
  },
  {
    link: '#',
    image: '/resources/cbu/img/flight-video.svg',
    text: 'friDay影音',
  },
  {
    link: '#',
    image: '/resources/cbu/img/friday-music.svg',
    text: 'friDay音樂',
  },
  {
    link: '#',
    image: '/resources/cbu/img/friday-57.svg',
    text: 'friDay 57',
  },
  {
    link: '#',
    image: '/resources/cbu/img/friday-photo.svg',
    text: 'friDay相片書',
  },
  {
    link: '#',
    image: '/resources/cbu/img/flight.svg',
    text: '遠遊卡',
  }
]

export const happygo = {
  title: 'HAPPYGO點數折抵',
  more: {
    link: '#',
    text: '看更多',
  },
  className: 'happy-go',
  cards: [
    {
      title: '折抵帳單金額50元',
      description: '<img src="/resources/cbu/img/ic-24-px-fcoin.svg" alt="fcoin" /> 200',
      link: '#',
    },
    {
      title: '折抵帳單金額100元',
      description: '<img src="/resources/cbu/img/ic-24-px-fcoin.svg" alt="fcoin" /> 200',
      link: '#',
    },
    {
      title: '折抵帳單金額300元',
      description: '<img src="/resources/cbu/img/ic-24-px-fcoin.svg" alt="fcoin" /> 200',
      link: '#',
    },
    {
      title: '折抵帳單金額500元',
      description: '<img src="/resources/cbu/img/ic-24-px-fcoin.svg" alt="fcoin" /> 200',
      link: '#',
    },
  ]
};

export const promotionCard = {
  title: '你的專屬優惠',
  cards: [
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
      title: '本月影片免費看!',
      description: '話題電影、日劇、韓劇等等應有盡有',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
      title: '中午不知道吃什麼？',
      description: 'Uber Eats 送遠傳新客優惠金200元',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
      title: '連假打算出國？',
      description: '遠遊卡吃到飽上網不再煩惱用量 ',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
      title: '本月影片免費看!',
      description: '話題電影、日劇、韓劇等等應有盡有',
      link: '#',
    },
  ]
};
