import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import Modal from 'react-modal';
import Button from '../../components/Button';

import SwitchCard from '../../components/partials/card/SwitchCard'
import ServiceEntry from '../../components/partials/card/ServiceEntry'
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';
import AppPromotion from '../../components/partials/AppPromotion';


import * as Mock from '../../mock/RoamingPlan';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      form: {
        type: { value: true },
      }
    }
  }

  render() {
    return (
      <main className="">
        <NavAnchor
          pageTitle='漫遊方案申請/查詢'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '漫遊方案申請/查詢', link: '' },
          ]}
          color='black'
        />

        <section className="roaming-plan roaming-plan-search">
          <div className="fui-container">
            <Grid>
              <Grid item sm={12}>
                <h2 className="mb-1">漫遊方案申請/查詢</h2>
                <h4 className="sub-head is-text-regular">
                  已申請：<span className="">0</span> 筆
                  <span className="action-arrow" role='button'>
                    <span>查詢記錄/取消 <img src={'/resources/cbu/e-service/images/right.svg'} alt='' />
                    </span>
                  </span>
                </h4>
              </Grid>
            </Grid>
          </div>

          <div className="fui-container">
            <div className="main-card">
              <Grid container spacing={2}>
                <Grid item sm={12} md={8}>
                  <div className="fui-cards w-100 left-card">
                    <div className='fui-card'>
                      <div className="fui-card-action">
                        <div className="fui-card-content">
                          <h4 className="mb-1">漫遊上網方案申請</h4>
                          <p className="is-text-darkgray50 mt-0 mb-4">你目前沒有已申請或使用中的漫遊上網方案。</p>
                          <div className='fui-card-extra'>
                            <Button btnStyle='primary' size='large' link="#" className="w-sm-100">立即申請</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={4}>
                  <div className="fui-cards w-100 right-card">
                    <div className='fui-card'>
                      <div className="fui-card-action">
                        <div className="fui-card-content">
                          <h4 className="is-text-white">旅日省錢大作戰，遠傳漫遊讓你瘋狂打卡不心痛！</h4>
                          <div className='fui-card-extra'>
                            <Button btnStyle='primary' reverse={true} size='large' link="#" className="w-sm-100">了解更多</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>


          <SwitchCard {...Mock.switchCard} />

          <ServiceEntry {...Mock.serviceCard} />

          <SectionCarousel1 {...Mock.promotionCard} />

          <SectionFaqTab {...Mock.faqTab} />


          <AppPromotion
            bgImage='/resources/cbu/help-center/images/bg-all.png'
            title="遠傳行動客服 APP"
            subtitle="貼心服務不用等"
            description={`
              <p>
                以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
                <br />
                還有許多用戶獨享驚喜優惠
              </p>`
            }
            icon='/resources/cbu/help-center/images/img-logo-fet.png'
            android={{
              qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
              icon: '/resources/cbu/help-center/images/google-play.png',
              link: '/',
              title: '支援 Android 7 以上版本'
            }}
            ios={{
              qrCode: '/resources/cbu/help-center/images/qr-ios.png',
              icon: '/resources/cbu/help-center/images/app-store.png',
              link: '/',
              title: '支援 iOS 10 以上版本'
            }}
          />

        </section>


      </main>
    );
  }
}

export default Search;
