import { TYPES } from './../action';
import { analyticsInitialState } from './../state';

export default function analyticsReducer(state = analyticsInitialState, action) {
  switch (action.type) {
    case TYPES.TRACK_PAGE_VIEW_START:
      try {
        fireViewStartCustomEvent({
          customEventName: 'event-view-start',
          data: {},
        });

        return Object.assign({}, state, {
          customEventName: 'event-view-start',
        });
      } catch (error) {
        console.log(error);
      }
      break;
    case TYPES.TRACK_PAGE_VIEW_END:
      try {
        // Prepare Data Layer Setting
        const digitalData = getDigitalDataByEventType(TYPES.TRACK_PAGE_VIEW_END, action.payload) || {};

        if (!digitalData) throw new Error('digitalData is empty object');

        // Trigger Custom Event
        fireViewEndCustomEvent({
          customEventName: 'event-view-end',
          data: digitalData,
        });

        // Update Redux state
        return Object.assign({}, state, {
          customEventName: 'event-view-end',
          digitalData: digitalData,
        });
      } catch (error) {
        console.log(error);
      }
      break;
    case TYPES.TRACK_ACTION_TRIGGER:
      try {
				const digitalData = getDigitalDataByEventType(TYPES.TRACK_ACTION_TRIGGER, action.payload) || {};

        fireActionTriggerCustomEvent({
          customEventName: 'event-action-trigger',
          data: digitalData,
        });

        return Object.assign({}, state, {
					customEventName: 'event-action-trigger',
					digitalData: digitalData
        });
      } catch (error) {
        console.log(error);
      }
			break;
			
		case TYPES.TRACK_PURCHASE_COMPLETED:
			try {				
				_satelliteTrack('event-purchase-completed', {            
					isPurchase: action.payload.isPurchase || false, // 是否訂購完成，如果是，下面訂購資訊 & 產品資訊都需要供
					purchasers: action.payload.purchasers || {},	// 訂購資訊
					product: action.payload.product || {},	// 產品資訊
					subscription: action.payload.subscription || ""	// 電子報表單
      	});

				return Object.assign({}, state, {
					customEventName: 'event-purchase-completed'
        });
			} catch (error) {
				console.log(error);
			}
			break;
		
		case TYPES.TRACK_PURCHASE_FAILURE:
			try {
				_satelliteTrack('event-purchase-failure', {            
					name: action.payload.name
      	});

				return Object.assign({}, state, {
					customEventName: 'event-purchase-failure'
        });
			} catch (error) {
				console.log(error);
			}
			break;
		
		case TYPES.TRACK_SEARCH_ITEM_CLICK:
			try {
				_satelliteTrack('search-item-click', {            
					id: action.payload.clickedId,
					searchType: action.payload.searchType || (isHelpCenter() ? '常見問題' : '站內搜尋')
				});

				return Object.assign({}, state, {
					customEventName: 'search-item-click'
				});
			} catch (error) {
				console.log(error);
			}
			break;
		
		case TYPES.TRACK_ACTION_BUTTON:
			try {
				_satelliteTrack('action-button-click', {
					pageFrom: action.payload.pageFrom,
          actionLocation: action.payload.actionLocation,
          actionName: action.payload.actionName
				});
				
				return Object.assign({}, state, {
					customEventName: 'action-button-click'
				});
			} catch (error) {
				console.log(error);
			}
			break;

		case TYPES.TRACK_SHARE_BUTTON_CLICK:
			try {
				const digitalData = getDigitalDataByEventType(TYPES.TRACK_SHARE_BUTTON_CLICK, action.payload) || {};
				const customEventName = `EBU-share-to-${action.payload.type.toUpperCase()}` || '';

				fireCustomEvent({
          customEventName: customEventName,
          data: digitalData,
        });

				return Object.assign({}, state, {
					customEventName: customEventName
				});
			} catch (error) {
				console.log(error);
			}
			break;

		case TYPES.TRACK_FAQ_FILL_IN:
			try {
				const digitalData = getDigitalDataByEventType(TYPES.TRACK_FAQ_FILL_IN, action.payload) || {};
				const customEventName = 'EBU-FAQ-fill-in';

				fireCustomEvent({
          customEventName: customEventName,
          data: digitalData,
				});
				
				return Object.assign({}, state, {
					customEventName: customEventName
				});
			} catch (error) {
				console.log(error);
			}
		break;

    default:
      return state;
  }
}

// --- Prepare Data Layer ---
export const getDigitalDataByEventType = (eventType, payload) => {
  let digitalData = {};

  switch (eventType) {
    case TYPES.TRACK_PAGE_VIEW_END:
      digitalData = {
        pageInstanceTitle: pageInstanceTitle(),
        pageInfo: pageInfo(payload),
        isForm: isForm(),
				isProductsView: isProductsView(),
				search: payload.search || {}
      };
			break;
			
		case TYPES.TRACK_ACTION_TRIGGER:
			console.log(payload);
			digitalData = {
				pageInstanceTitle: window.digitalData ? window.digitalData.pageInstanceTitle : pageInstanceTitle(),
				pageInfo: window.digitalData ? window.digitalData.pageInfo : pageInfo(),
			}

			digitalData.pageInfo.pageHashTag = payload.pageHashTag;
			digitalData.pageInfo.tabName = payload.tabName;
			break;

		case TYPES.TRACK_SHARE_BUTTON_CLICK:
			digitalData = {
				pageInstanceTitle: window.digitalData ? window.digitalData.pageInstanceTitle : pageInstanceTitle(),
				pageInfo: window.digitalData ? window.digitalData.pageInfo : pageInfo(),
				Share: {
					title: payload.title,
					URL: payload.url
				}
			}
			break;

		case TYPES.TRACK_FAQ_FILL_IN:
			digitalData = {
				pageInstanceTitle: window.digitalData ? window.digitalData.pageInstanceTitle : pageInstanceTitle(),
				pageInfo: window.digitalData ? window.digitalData.pageInfo : pageInfo(),
				faq: {
					title: payload.title, //如何申請NB-IoT服務
					isHelpful: payload.isHelpful, //否
					reason: payload.isHelpful ? 'na' : payload.reason, // 我看不懂/其他(如果勾”是”此值請寫”na”)
					details: payload.isHelpful ? 'na' : payload.details // (如果勾”是”此值請寫”na”)
				}
			}
			break;

    default:
      break;
  }

	return digitalData;
};

export const _setGlobalDataLayer = data => {
  // TODO: 因為頁面拿不到 digitalData，暫時先寫 global 物件，並非為最佳寫法。
  window.digitalData = window.digitalData || {};
	window.digitalData = data;
	console.log("DataLayer: ", window.digitalData);
};

// --- Call AA function: _satellite.track ---
export const _satelliteTrack = (eventName, payload) => {
	console.log("_satelliteTrack: " + eventName, payload);
	if ( window._satellite && (typeof(window._satellite.track) === "function") ) {
		window._satellite.track(eventName, payload);
	}
}

// --- Trigger Event ---
export const fireViewStartCustomEvent = payload => {
  console.log(`Firing Custom Event: ${payload.customEventName}`);

  var event = new CustomEvent(payload.customEventName);
  document.dispatchEvent(event);
};

export const fireViewEndCustomEvent = payload => {
  console.log(`Firing Custom Event: ${payload.customEventName}`);

  _setGlobalDataLayer(payload.data);
  var event = new CustomEvent(payload.customEventName);
  document.getElementById('root').dispatchEvent(event);
};

export const fireActionTriggerCustomEvent = payload => {
	console.log(`Firing Custom Event: ${payload.customEventName}`);
	
  _setGlobalDataLayer(payload.data);
  var event = new CustomEvent(payload.customEventName);
  document.getElementById('root').dispatchEvent(event);
};

export const fireCustomEvent = payload => {
	console.log(`Firing Custom Event: ${payload.customEventName}`);

	_setGlobalDataLayer(payload.data);
	var event = new CustomEvent(payload.customEventName);
  document.getElementById('root').dispatchEvent(event);
}



const hTag = () => {
  // 頁面中最高層級的<h1>如該頁面最高層級只有H2就送H2
  let h1Tags = document.getElementsByTagName('h1');
  let h2Tags = document.getElementsByTagName('h2');
  let h3Tags = document.getElementsByTagName('h3');

  if (h1Tags.length && h1Tags.item(0)) return h1Tags.item(0).innerText;
  if (h2Tags.length && h2Tags.item(0)) return h2Tags.item(0).innerText;
  if (h3Tags.length && h2Tags.item(0)) return h3Tags.item(0).innerText;

  return '';
};

const destinationURL = () => {
  // 目前 URL，包含參數
  return window.location.href || '';
};

const breadCrumbs = () => {
  // (首頁範例) na|na|na
  // (洞見科技範例) 趨勢觀點-科技洞見|第25期|沙崙綠能
  // (產品範例) 產品服務|寬頻上網|大寬頻企業ADSL
  // (表單範例) 表單微型|填寫步驟|na
  // 1.表單-中型|填寫|na
  // 2.表單-大型|填寫|na
  // 3.表單-政府公部門|填寫|na
  // 4.表單-產品|填寫|na
  // 5.表單-活動報名|填寫|na
  // 6.表單-線上問題反應|填寫|na
  // 7.表單-訂閱電子報|填寫|na
  // 8.表單-取消訂閱|填寫|na
  // 9.表單-(以上1-8點名稱)|送出成功|na

  let target = document.querySelectorAll('.fui-breadcrumb a');
  let arr = [];

  target.forEach((element, i) => {
    arr.push(element.innerText);
  });

  for (let i = 0; i < 3; i++) {
    if (!arr[i]) arr.push('na');
  }

  return arr.join('|') || '';
};

const pageInstanceTitle = () => {
  // title標籤
  return document.title || "";
};

const pageInfo = (payload = {}) => {
  return {
    hTag: hTag(),
    referrerURL: payload.referrerURL || '', // 前一頁 URL，包含參數
    destinationURL: destinationURL(),
		tabId: payload.tabId || '',
    tabName: payload.tabName || '',
    breadCrumbs: breadCrumbs() || '',
  };
};

const isForm = () => {
	// 是否表單填寫頁
  return /(\/form\/)/g.test(window.location.pathname);
};

const isProductsView = () => {
  // 是否進入產品頁下面產品資訊必須有值, false時不需提供值
  return /(\/product\/)/g.test(window.location.pathname);
};

const isHelpCenter = () => {
	return /(\/help-center\/)/g.test(window.location.pathname);
}

// --- YouTube ---
// const YT = window.YT || {};
const onPlayerStateChange = (e) => {
	if (!(window.YT && window.YT.PlayerState)) return;
	e['data'] === window.YT.PlayerState.PLAYING && setTimeout(onPlayerPercent, 1000, e['target']);
  };
  
  const onPlayerError = (e) => {};
  
  const onPlayerPercent = (e) => {
	if (!(window.YT && window.YT.PlayerState)) return;
	if (!(e['getPlayerState']() === window.YT.PlayerState.PLAYING)) return;
  
	let t =
	  e['getDuration']() - e['getCurrentTime']() <= 1.5
		? 1
		: (Math.floor((e['getCurrentTime']() / e['getDuration']()) * 4) / 4).toFixed(2);
  
	if (!e['lastP'] || t > e['lastP']) {
	  let video_data = e['getVideoData']();
	  e['lastP'] = t;
  
	  _satelliteTrack('Youtube-iframe-Time-Played', {
		videoName: video_data.video_id + ':' + video_data.title,
		playedRate: t * 100 + '%',
	  });
	  console.log('playedRate: ', t * 100 + '%');
	}
  
	e['lastP'] !== 1 && setTimeout(onPlayerPercent, 1000, e);
  };
  
  export const videoTrack = {
	yt: {
	  onPlayerStateChange,
	  onPlayerError,
	},
  };