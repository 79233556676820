import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Formsy from 'formsy-react';
import Modal from 'react-modal';
import LabelInput from '../../components/form/LabelInput';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import * as Validation from '../../utils/validation';
class NumberSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      canSubmit: false,
      form: {
        number: { value: '', required: true },
        validation: { value: '', required: true },
      }
    }
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }

  onChange = (n, v, i) => {
    // console.log(n, v, i)
    let form = Object.assign(this.state.form);
    if (!i && i !== 0) {
      form[n].value = v
    } else {
      form[n][i].value = v
    }

    this.setState({
      form: form
    })
  }
  render() {
    return (
      <main className="Instruction">
        <Header />
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="網內外門號查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className='pt-md-7 pt-4'>
          <div className='fui-container'>
            <h4 className="is-text-darkgray50 mt-0 mb-md-7 mb-4">
              本服務提供您查詢門號是否屬於遠傳門號。提醒您：因號碼可攜服務日漸普及，同一門號於不同日期的查詢結果可能不同。故本查詢僅供參考。
            </h4>
            <Formsy
              className=''
              onValidSubmit={this.submit}
              onValid={this.enableButton}
              onInvalidSubmit={this.disableButton}
              noValidate
              ref={this.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} sm={12} md={6}>
                  <LabelInput
                    label="欲查詢門號"
                    name='number'
                    required={true}
                    className="hide-required mb-md-10"
                    validations={{
                      phoneNumber: Validation.phoneNumber
                    }}
                    placeholder='請輸入欲查詢門號'
                    value={this.state.form.number.value}
                    onChange={this.onChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} sm={12} md={6}>
                  <div className="captcha">
                    <div className="form-group mb-0">
                      {/* <input id="vertifyNum" placeholder="請輸入驗證碼" className="form-control" /> */}
                      <LabelInput
                        label=""
                        name='validation'
                        required={true}
                        className="hide-required mb-md-4 mb-0 form-control"
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入驗證碼',
                        }}
                        placeholder='請輸入驗證碼'
                        value={this.state.form.validation.value}
                        onChange={this.onChange}
                      />
                      <div className="captcha-box">
                        <div className="captcha-number">
                          <img src="https://cldup.com/gZ7MdGePW6-3000x3000.png" alt="驗證碼圖片" />
                        </div>
                        <button type="button" className="captcha-reload">
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcYiIiIeHh39/f4iIiIeHh4eHh4iIiIiIiIiIiIeHh4eHh4WFhYiIiImJiYiIiOpIBKYAAAAPdFJOUwDfIBCgfT+/75DPYFCvUPAcHYoAAAErSURBVHjatVKxSgNBFHzEeCKxOUHbTSpLt1Hbs7JUEOsNIraxsLALYiHYnHDgD1hoFwt7W2vF2m84uSMxiCNvdzWDKaycZt/Ozt7OvHvyb5i5v8XFS1sYyQkUwzviGq8IKEn7qPsbA6D+4WaB0ZUVOTBAJhEOKHzRMqgitwAsx3IFuAxVh77v8BGKbSzKN5oorfcDtCchDHbC4TtZ7uFMl1W8ETmHT10ekHFgVOGdLncmL3Xpwwph4Ld6RAgXMRSGmyI30jTHabokfL1hoMjE8EMd5Sq2FKXq22lalo41pqZl6bVPWwtLx9Y3fmRZ+iwh55FMoUcN7dIfLmI5X1MPyqBIBhN7zThFSR9kzwE43tvKeZh0MiIKMrAW2SdhtDYBnO/KL6wf7v8x7V/iaqjxLs+LqgAAAABJRU5ErkJggg==" alt="button" />
                        </button>
                      </div>
                    </div>
                  </div>

                </Grid>
              </Grid>
              <div className='page-action mt-md-0 mt-2'>
                <Button btnStyle='primary' className={this.state.canSubmit ? '' : 'disabled'} size="large" onClick={() => this.setState({ modalOpen: true })}>
                  查詢
              </Button>
              </div>
            </Formsy>
          </div>
        </section>
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5>查詢成功</h5>
            <img style={{ width: 140 }} src="/resources/cbu/e-service/images/completed.png" alt="success" />
            <p className='m-0'>您的門號為：<span className="is-text-error">網內</span></p>
            <div className='page-action'>
              <Button className='mb-0' btnStyle='primary' onClick={() => this.setState({ modalOpen: false })}>
                確定
              </Button>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}

export default NumberSearch;