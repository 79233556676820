export default {
    NavAnchor: {
        pageTitle: '<a href="#">Tourist Prepaid Card</a>',
        button: 'Contact us',
        tabs: [
            { label: 'Product', link: '#program' },
            { label: 'How to dial', link: '#howTo' },
            { label: 'Airport store', link: '#whereToBuy' },
            { label: 'FAQs', link: '#help' }
        ]
    },
    Breadcrumb: [
        { text: 'Home', link: '/' },
        { text: 'Tourist Prepaid Card', link: '/' }
    ],
    SectionBanner2: {
        title: 'Your Best Choice for Internet in Taiwan ',
        description: '<h6><img src="/resources/cbu/prepaid/images/checklist.svg"/>4G unlimited data day pass product</h6><h6><img src="/resources/cbu/prepaid/images/checklist.svg"/>Free voice</h6><h6><img src="/resources/cbu/prepaid/images/checklist.svg"/>SIM card can be used immediately</h6>'
    },
    TabPane: {
        title: 'Tourist Prepaid Card',
    },
    tabContent: {
        title: 'Tourist Prepaid Card',
        id: 'program',
        cards: [
            {
                icon: '/resources/cbu/prepaid/images/prepaid-foreign-plan-icon-en-01.png',
                iconAlt: 'program1',
                title: 'Plan A',
                subTitle: 'Unlimited Data + Free Voice NT $100',
                tag: 'HOT',
                list: [
                    'NT$100 credit for voice',
                    '72 hours (3 days) of unlimited data'
                ]
            },
            {
                icon: '/resources/cbu/prepaid/images/prepaid-foreign-plan-icon-en-02.png',
                iconAlt: 'program1',
                title: 'Plan B',
                subTitle: 'Unlimited Data + Free Voice NT $50',
                list: [
                    'NT$50 credit for voice',
                    '120 hours (5 days) of unlimited data'
                ]
            },
            {
                icon: '/resources/cbu/prepaid/images/prepaid-foreign-plan-icon-en-01.png',
                iconAlt: 'program1',
                title: 'Plan C',
                subTitle: 'Unlimited Data + Free Voice NT $300',
                list: [
                    'NT$300 credit for voice',
                    '120 hours (5 days) of unlimited data'
                ]
            },
            {
                icon: '/resources/cbu/prepaid/images/prepaid-foreign-plan-icon-en-02.png',
                iconAlt: 'program1',
                title: 'Plan D',
                subTitle: 'Unlimited Data + Free Voice NT $300',
                list: [
                    'NT$300 credit for voice',
                    '120 hours (5 days) of unlimited data'
                ]
            },
        ]
    },
    dial: {
        title: 'How to dial',
        panel:
            `<h4 class="is-text-medium mt-0">Domestic call</h4>
            <ul>
                <li>Mobile: “Mobile phone number”. For example, 0912-345-678.</li>
                <li>Domestic Land Line: “Area code” + “Phone number”. For example, 02-1234-5678.</li>
            </ul>
            <div class="wrapper pb-2">
                <div class="d-column">
                    <h4 class="is-text-darkgray50">On-net</h4>
                    <div class="d-row bg-gray">
                        <div class="d-flex">
                            <p class="m-0">Peak Time</p>
                            <h2 class="is-text-black50 m-0">NT $0.1<span>/S</span></h2>
                        </div>
                        <div class="d-flex">
                            <p class="m-0">Off-peak Time</p>
                            <h2 class="is-text-black50 m-0">NT $0.05<span>/S</span></h2>
                        </div>
                    </div>
                </div>
                <div class="d-column">
                    <h4 class="is-text-darkgray50">Off-net</h4>
                    <div class="d-row bg-gray">
                        <div class="d-flex">
                            <p class="m-0">Peak Time</p>
                            <h2 class="is-text-black50 m-0">NT $0.174<span>/S</span></h2>
                        </div>
                        <div class="d-flex">
                            <p class="m-0">Off-peak Time</p>
                            <h2 class="is-text-black50 m-0">NT $0.0869<span>/S</span></h2>
                        </div>
                    </div>
                </div>
            </div>
            <h4 class="is-text-medium mt-5">International call from Taiwan</h4>
            <ul class="mb-4">
                <li>1. Dial: ”017”+ “Country code” + “Area code” + “Phone number”. For example, 017-86-21-12345678.</li>
            </ul>`,
        PanelTable:
            `<div class="compareTable content-16 clearfix">
                <div class="tableLeft">
                    <div class="tableHead">
                        <table>
                            <tbody>
                                <tr style="height: 52px;">
                                    <th>Continent</th>
                                </tr>
                                <tr style="height: 260px;">
                                    <th>Asia</th>
                                </tr>
                                <tr style="height: 62px;">
                                    <th>North America</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tableRight">
                    <div class="tableBody">
                        <div class="spaceBox">
                            <table class="compareList">
                                <tbody>
                                    <tr style="height: 52px;">
                                        <td>Country</td>
                                        <td>Country code</td>
                                        <td>Min. charge</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>Hong kong</td>
                                        <td>852</td>
                                        <td>NT $6.7</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>Japan/Korea</td>
                                        <td>81/82</td>
                                        <td>NT $9.1</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>China</td>
                                        <td>86</td>
                                        <td>NT $8.4</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>Singapore</td>
                                        <td>65</td>
                                        <td>NT $7.3</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                        <td>Malaysia</td>
                                        <td>65</td>
                                        <td>NT $7.3</td>
                                    </tr>
                                    <tr style="height: 62px;">
                                        <td>U.S/Canada</td>
                                        <td>65</td>
                                        <td>NT $7.3</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>
                </div>
            </div>`,
        PanelInformation: {
            title: 'Note',
            content: 'Note'
        }
    },
    whereToBuy: {
        title: 'Airport Store',
        tabs: {
            name: 'panel-tab',
            default: 0,
            list: [
                { name: 'panel-tab-1', label: 'Terminal 1 (Before Immigration)' },
                { name: 'panel-tab-2', label: 'Terminal 1 (After Immigration)' },
                { name: 'panel-tab-4', label: 'Terminal 2 (After Immigration)' },
            ],
        },
        tabContent:
            `<div class="airport-info">
                <img src="/resources/cbu/prepaid/images/airport-store-before-terminal-1-big-5@2x.jpg" alt="airport" />
                <p>Open hours: 9:00AM - 12:00PM daily</p>
            </div>`
    },
    info: {
        title: 'Note',
        content: `<ol>
            <li>The prepaid card can only be used in Taiwan.</li>
            <li>Internet speeds may vary depending on the location, weather, smartphone.</li>
            <li>FET Taoyuan Airport store only. Fareastone Telecom reserves the right to change tariffs and services. The latest tariff service content is based on the announcement of Fareastone Telecom.</li>
            <li>Peak Hours : Monday to Friday 08:00~23:00</li>
            <li>Off-Peak Hours: Monday to Friday 23:00~08:00 ; Saturday 00:00 to Monday 08:00 ; all National Holidays</li>
            <li>For more Tariff information, please refer to International Call Website.</li>
            <li>Far Eastone Telecom reserves the right to modify the rate at any time.</li>
        </ol>`
    },
    qa: {
        title: 'FAQs',
        faq: {
            collapseList: [
                {
                    title: 'Can everyone buy FET tourist prepaid cards at your airport store? ',
                    content: `Can everyone buy FET tourist prepaid cards at your airport store? `,
                },
                {
                    title: 'What documents are needed to apply for FET tourist prepaid cards?',
                    content: `What documents are needed to apply for FET tourist prepaid cards?`,
                },
                {
                    title: 'Where to buy tourist prepaid card? Can I buy a prepaid card when my flight arrives?',
                    content: `Where to buy tourist prepaid card? Can I buy a prepaid card when my flight arrives?`,
                },
                {
                    title: 'How to recharge for tourist prepaid cards?',
                    content: `How to recharge for tourist prepaid cards?`,
                },
            ],
            container: false
        }
    }

}