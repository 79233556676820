import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

export class CollapseMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEn: false,
      isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
      showMore:
        this.props.searchTagItem && this.props.searchTagItem.index && this.props.searchTagItem.index >= 10
          ? true
          : false,
      toggle: false,
      toggleMenu: false,
      activeLevel: [0, -1],
      openLevel: -1,
      currentGroup: null,
      currentSelected: null,
    };
  }

  componentDidMount() {
    let menu = this.props.menu;

    this.selectedItem(this.props.searchTagItem);

    this.setState({
      currentGroup: menu,
      // openLevel: open,
      // currentSelected: menu.content[active]
    });
  }

  componentDidUpdate() {
    if ((typeof window !== 'undefined' && window.$isEn) !== this.state.isEn) {
      this.setState({
        isEn: typeof window !== 'undefined' && window.$isEn,
      });
    }
  }
  searchMenu = (list, parent, val) => {
    var result = {
      index: 0,
      parentIndex: -1,
      hasValue: false,
    };

    if (!val) {
      result = {
        hasValue: true,
        parentIndex: list && list.length && list[0].content && list[0].content.length ? 0 : -1,
        index: 0,
        item: list && list.length && list[0].content && list[0].content.length ? list[0].content[0] : list[0],
      };
    } else {
      for (var i in list) {
        let menu = list[i];
        if (menu.id === val.id) {
          // console.log(menu, i, this.props.searchTagItem.id)
          result = {
            hasValue: true,
            parentIndex: parent,
            index: Number(i),
            item: menu,
          };
          break;
        } else {
          if (!!menu.content && !result.hasValue) {
            result = this.searchMenu(menu.content, Number(i), val);
          }
        }
      }
    }

    return result;
  };

  selectedItem = (val, event) => {
    let toggle = this.state.toggleMenu;
    let open = this.state.openLevel;
    let level = this.state.activeLevel;

    if (this.props.onClickTag) this.props.onClickTag(val.id);

    // 尋找被點選或 props 帶入的內容
    let selected = this.searchMenu(this.props.menu.content, -1, val);

    if ((selected.item && !selected.item.content) || !event) {
      // 沒有子項目或不是 click event
      // console.log('!val.content && !event', selected)
      this.setState({
        toggle: false,
        toggleMenu: selected.parentIndex > -1 ? true : false,
        openLevel: selected.parentIndex !== open && selected.parentIndex > -1 ? selected.parentIndex : open,
        activeLevel: [
          selected.parentIndex === -1 ? selected.index : selected.parentIndex,
          selected.parentIndex !== -1 ? selected.index : -1,
        ],
        currentSelected: selected.item,
      });

      this.props.selectMenu(selected.item);
    } else {
      // 有子項目或是 click event
      toggle = open === selected.index ? !toggle : true;

      this.setState({
        toggle: false,
        openLevel: selected.index,
        toggleMenu: toggle,
      });
    }

    this.forceUpdate();
  };

  toggleMenu = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  toggleStatus = (val) => {
    let toggle = this.state.toggleMenu;
    let level = this.state.openLevel;
    return val === level && toggle ? 'menu-active' : '';
  };

  getActiveStatus = (val, parent) => {
    let active = this.state.activeLevel;
    // console.log(`val, parent`, active, val, parent);
    // return ((parent && active[0] === parent) && active[1] === val) || (!parent && active[0] === val) ? 'active' : '';
    if (!parent && parent !== 0) {
      return active[0] === val && active[1] === -1 ? 'active' : '';
    } else {
      return active[0] === parent && active[1] === val ? 'active' : '';
    }
  };

  render() {
    return (
      <div className={`fui-collapse-menu ${this.props.usage}`}>
        <div className={`collapse-menu-group ${this.state.toggle ? 'menu-active' : ''}`}>
          <div className='collapse-menu-header'>
            <h6 className='group-name' onClick={this.toggleMenu}>
              {this.props.menu.name}
              <i className={`icon-${this.state.toggle ? 'minus' : 'plus'}`} />
            </h6>
            {!!this.state.currentSelected ? (
              <div className='current-select-item'>
                <p className='body1'>{this.state.currentSelected.name}</p>
              </div>
            ) : null}
          </div>
          <ul>
            {this.props.menu.content.map((list, j) => {
              if (this.state.showMore || (j < 10 && !this.state.showMore)) {
                if (!!list.content) {
                  return (
                    <li className={`${this.toggleStatus(j, 1)}`} key={`collapse-menu-child-${j}`}>
                      <p className='body1' onClick={(event) => this.selectedItem({ index: j, ...list }, event)}>
                        {list.name} {list.count ? `(${list.count})` : null}
                        <i className={`icon-chevron-down`} />
                      </p>
                      <div className='item-group'>
                        {list.content.map((item, k) => {
                          return item.link ? (
                            <NavLink
                              to={item.link}
                              className={this.getActiveStatus(k, j)}
                              key={k + item.name}
                              // isActive={(match, location) => {
                              //   return typeof window !== 'undefined' && location.pathname === window.location.pathname && window.location.search.indexOf(list.id) > -1;
                              // }}
                            >
                              {item.name}
                            </NavLink>
                          ) : (
                            <p
                              key={k}
                              onClick={(event) => this.selectedItem({ parentIndex: j, index: k, ...item }, event)}
                              className={this.getActiveStatus(k, j)}>
                              {item.name}
                            </p>
                          );
                        })}
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li key={list.id}>
                      {list.link ? (
                        <NavLink
                          exact
                          to={list.link}
                          className='body1 hahaha'
                          onClick={(event) => this.selectedItem({ level: 0, index: j, ...list }, event)}>
                          {/* <h2>{this.state.activeLevel[0] === j ? 'true' : 'false'}</h2> */}
                          {list.name} {list.count ? `(${list.count})` : null}
                        </NavLink>
                      ) : (
                        <p
                          className={`body1 ${this.getActiveStatus(j, null)}`}
                          onClick={(event) => this.selectedItem({ level: 0, index: j, ...list }, event)}>
                          {list.name} {list.count ? `(${list.count})` : null}
                        </p>
                      )}
                    </li>
                  );
                }
              }
            })}
            {!this.state.showMore && this.props.menu.content.length > 10 ? (
              <li>
                <p className='more-btn' onClick={(e) => this.setState({ showMore: true })}>
                  <span className='text'>{this.state.isEn ? 'More' : '看更多'}</span> <i className='icon-plus'></i>
                </p>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    );
  }
}

CollapseMenu.propTypes = {
  menu: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string.isRequired,
        link: PropTypes.string,
        content: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.any,
            name: PropTypes.string,
            link: PropTypes.string,
          })
        ),
      })
    ),
  }),
  searchTagItem: PropTypes.object,
  selectMenu: PropTypes.func,
  onClickTag: PropTypes.func,
};
export default CollapseMenu;
