import React from 'react';

import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

import * as Mock from '../../mock/EServiceIndex';
import * as FourGService from '../../service/FourGService';

class fourGExtraBuySubmitPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      submitData: {}
    }

    FourGService.extraBuySubmit().then(data => {
      this.setState({
        submitData: data
      })
    })
  }

  render() {
    const { submitData } = this.state;
    return (
      <main>

        <NavAnchor
          pageTitle='帳單繳費'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <section className="e-service-result is-success">
          <div className="fui-container">
            <Grid container>
              <Grid item xs={12}>
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed.png'} alt='' />
                <div className="content">
                  <h4 className="mb-0 mt-3">購買成功</h4>
                  <p className='body1'>
                    你已完成上網用量加購 <br />
                    方案名稱：{submitData.offerName} / ${submitData.price}
                  </p>
                  <div className='action'>
                    <Button btnStyle='primary' size='large'>查詢購買記錄</Button>
                    <Button link='/' btnStyle='secondary' size='large'>前往個人專區</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <SectionCarousel1 {...Mock.promotionCard} />
      </main>
    );
  }
}

export default fourGExtraBuySubmitPage;
