import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';

import LabelInput from '../../components/form/LabelInput';
import RadioGroup from '../../components/form/RadioGroup';
import Select from '../../components/form/Select';
import Button from '../../components/Button';;

class payDepositPage extends React.Component {
  constructor(props) {
    super(props);
    this.tooltipContent = React.createRef();
    this.bankForm = React.createRef();
    this.creditForm = React.createRef();
    this.state = {
      step: 1,
      canSubmit1: false,
      canSubmit2: false,
      form_1: {
        payment: { value: '1', required: true },
        happygo: { value: '500', required: true },
      },
      form_2: {
        id_number: { value: '', required: true },
        payment: { value: '1' },
        msisdn: { value: '', required: true },
        credit_number_1: { value: "", required: true },
        credit_number_2: { value: "", required: true },
        credit_number_3: { value: "", required: true },
        credit_number_4: { value: "", required: true },
        credit_year: { value: null, required: true },
        credit_month: { value: null, required: true },
        credit_code: { value: '', required: true },
        bank_code: { value: '', required: true },
        bank_account: { value: '', required: true },
        receipt: { value: '1', required: true },
        receipt_extra_2: { value: '', required: true },
        receipt_extra_3: { value: '', required: true },
        agree: { value: false, required: true }
      }
    }
  }

  changeTab = (value) => {
    this.setState({
      currentTab: value
    })
  }

  enableButton = () => {
    if (this.state.step === 1)
      this.setState({
        canSubmit1: true
      })
    else
      this.setState({
        canSubmit2: true
      })
  }

  disableButton = () => {
    if (this.state.step === 1)
      this.setState({
        canSubmit1: false
      })
    else
      this.setState({
        canSubmit2: false
      })
  }

  onChange1 = (n, v) => {
    let form = Object.assign(this.state.form_1);
    form[n].value = v;

    this.setState({
      form_1: form
    })
  }

  onChange2 = (n, v) => {
    let form = Object.assign(this.state.form_2);
    form[n].value = v;

    this.setState({
      form_2: form
    })
  }

  nextStep = () => {
    this.setState({
      step: 2
    })
  }

  submit = () => {
    this.props.history.push('/billCreditcardPayController/billCreditcardPayPage')
  }

  render() {
    const { step, form_1, form_2 } = this.state;

    return (
      <main>

        <NavAnchor
          pageTitle='線上繳費'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="線上繳費"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <div>
          <section className='pt-2 pt-md-5'>
            <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
              <div className="fui-container">
                <div className="paper">
                  <h4>帳單明細</h4>
                  <table className="text-wrap">
                    <tbody>
                      <tr>
                        <th>手機門號</th>
                        <td className="align-left">0936-000-123</td>
                      </tr>
                      <tr>
                        <th>結帳日期</th>
                        <td className="align-left">2014 / 06 / 23	</td>
                      </tr>
                      <tr>
                        <th>繳費日期</th>
                        <td className="align-left">2014 / 06 / 23	</td>
                      </tr>
                      <tr>
                        <th>本期新增金額</th>
                        <td className="align-left">1,401元</td>
                      </tr>
                      <tr>
                        <th>累積應繳金額</th>
                        <td className="align-left">1,401元</td>
                      </tr>
                      <tr>
                        <th>最後一次繳費記錄</th>
                        <td className="align-left">於 2014 / 06 / 27 繳費，金額為 $ 1,541。</td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 className="mt-5">應繳費項</h4>
                  <table className="payment-table is-text-black50">
                    <tbody>
                      <tr>
                        <th className='align-left p-2'>
                          <Grid container>
                            <Grid item xs={6}>
                              <span className='is-text-black50'>電信費用</span>
                            </Grid>
                            <Grid item xs={6}>
                              <span className="float-right is-text-black50">1,401元</span>
                            </Grid>
                          </Grid>
                          <Grid container className='happygo'>
                            <Grid item xs={6}>
                              <span className='is-text-black50'>HAPPY GO 點數折抵</span>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="float-right">
                                <div className='d-inline-block my-2'>
                                  <Select
                                    name='happygo'
                                    options={[
                                      { text: '500 元', value: '500' },
                                      { text: '1000 元', value: '1000' },
                                      { text: '1500 元', value: '1500' }
                                    ]}
                                    value={form_1.happygo.value}
                                    onChange={this.onChange1}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} className="note">
                              <span className="is-text-gray100">尚有 1819 點 (截至 2014 / 09 / 29 )</span>
                            </Grid>
                          </Grid>
                        </th>
                      </tr>
                      <tr>
                        <th className='align-left p-2'>
                          <Grid container>
                            <Grid item xs={6}>
                              <span className='is-text-black50'>帳單總額</span>
                            </Grid>
                            <Grid item xs={6}>
                              <h2 className="is-text-error my-0 float-right">1,401元</h2>
                            </Grid>
                          </Grid>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <table className="payment-table is-text-black50">
                    <tbody>
                      <tr>
                        <th className='align-left p-2'>
                          <Grid container>
                            <Grid item xs={6}>
                              <span className='is-text-black50'>電信費用</span>
                            </Grid>
                            <Grid item xs={6}>
                              <span className="float-right is-text-black50">1,401元</span>
                            </Grid>
                          </Grid>
                          <Grid container className="happygo">
                            <Grid item xs={6}>
                              <span className='is-text-black50'>HAPPY GO 點數折抵</span>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="float-right">
                                <div className='d-inline-block my-2'>
                                  <Select
                                    name='happygo'
                                    options={[
                                      { text: '500 元', value: '500' },
                                      { text: '1000 元', value: '1000' },
                                      { text: '1000 元', value: '1000' }
                                    ]}
                                    value={form_1.happygo.value}
                                    onChange={this.onChange1}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} className="note">
                              <span className="is-text-gray100">點數不足，或尚未申請 HAPPY GO，未能使用 HAPPY GO 紅利點數</span>
                            </Grid>
                          </Grid>
                        </th>
                      </tr>
                      <tr>
                        <th className='align-left p-2'>
                          <Grid container>
                            <Grid item xs={6}>
                              <span className='is-text-black50'>帳單總額</span>
                            </Grid>
                            <Grid item xs={6}>
                              <h2 className="is-text-error my-0 float-right">1,401元</h2>
                            </Grid>
                          </Grid>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='paper'>
                  <div className='mt-5'>
                    <RadioGroup
                      label='請選擇付款方式'
                      name="payment"
                      options={[
                        { label: '銀行帳號', value: '1' },
                        { label: '信用卡', value: '2' }
                      ]}
                      default={form_1.payment.value}
                      onChange={this.onChange1}
                    />
                  </div>
                </div>
                <div className="page-action">
                  <Button btnStyle='primary' size='large' onClick={this.nextStep}>填寫付款資料</Button>
                  <Button btnStyle='secondary' size='large' link="/payController/payPage">取消</Button>
                </div>
              </div>
            </Formsy>
          </section>
        </div>

      </main>
    )
  }
}

export default payDepositPage;