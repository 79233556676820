import React from 'react';
import { Grid } from '@material-ui/core';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';
import Tooltip from '../../components/Tooltip';

class RoamingPlanRadio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      required: this.props.required || false,
      options: this.props.options,
      selected: this.props.default || null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.default) {
      this.props.setValue(this.props.default);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.default !== this.props.default) {
      this.setState({
        selected: this.props.default,
      });
    }
  }

  triggerModal = e => {
    e.preventDefault();
    this.props.openModal();
  };

  handleChange(event) {
    //   debugger
    if (event.currentTarget.checked) {
      this.setState({ selected: event.currentTarget.value });
      this.props.setValue(this.state.selected);
      this.props.getSelected(event.currentTarget.value);
    }
  }


  render() {
    return (
      <div className={`form-group roaming-plan-radio ${this.props.className}`}>
        <label className={this.state.required ? 'is-required' : ''}>{this.props.label}</label>

        <div className='radio-group'>
          {this.props.options.map((option, i) => (
            <label className='radio is-icon-box' key={`radio-${this.props.name}-${i}`}>
              <input
                type='radio'
                name={this.props.name}
                value={option.value}
                onChange={this.handleChange}
                defaultChecked={this.props.default === option.value}
              />
              <div className='content'>
                <Grid className='' container spacing={2}>
                  <Grid item xs={12} sm={12} md={9}>
                    <div className="content-info">
                      <h3 className='title'>{option.title}</h3>
                      <div className='is-text-darkgray50 desc'>{option.desc}</div>
                      <div className='is-text-darkgray50 body-2 is-text-medium target'>{option.target} </div>
                      { this.props.type == 'flight' ?
                        <div className='is-text-darkgray50 body-2 is-text-medium country'>
                          適用航班：請至 <a href={`${option.flightCompany.link}`} target="_blank" className="">{option.flightCompany.name}</a> 官網查詢
                        </div>
                        : 
                        <div className='is-text-darkgray50 body-2 is-text-medium country'>
                          { option.country }
                          { option.tips ? 
                            <span className="tips">
                              <Tooltip 
                                parentNode={null}
                                content={<img src="/resources/cbu//help-center/images/information-gray-24.svg"/>}
                                trigger="click"
                                tooltip={option.tips} 
                              />
                            </span>
                            : null
                          }
                        </div>
                      }
                      
                      <div className='is-text-darkgray50 body-2 is-text-medium days'>{option.days} </div>
                      <div className='underline-link is-text-black50 body-2 action' onClick={this.triggerModal}>{option.action.text}</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <div className="content-price">
                      <div className='body-2 unit'>{option.unit}</div>
                      <h1 className='is-text-error price'>${option.price}</h1>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </label>

          ))}
        </div>
      </div>
    );
  }
}

RoamingPlanRadio.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  default: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      desc: PropTypes.string,
      target: PropTypes.string,
      country: PropTypes.string,
      flightCompany: PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
      }),
      days: PropTypes.string,
      tips: PropTypes.string,
      action: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
      }),
      unit: PropTypes.string,
      price: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  openModal: PropTypes.func,
};

export default withFormsy(RoamingPlanRadio);
