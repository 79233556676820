import React from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class CheckGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      required: this.props.required || false,
      options: this.props.options,
      defaultChecked: this.props.default ? this.props.default.split(/,/g) : [],
      selected: this.props.default ? this.props.default.split(/,/g) : [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.default) {
      this.props.setValue(this.props.default);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.default !== this.props.default) {
      // console.log(prevProps.default, this.props.default)
      this.setState({
        defaultChecked: this.props.default ? this.props.default.split(/,/g) : [],
      });
    }
  }

  handleChange(event) {
    let selected = Object.assign(this.state.selected);
    let val = event.currentTarget.value;
    if (event.currentTarget.checked) {
      selected.push(val);
    } else {
      selected.splice(selected.indexOf(val), 1);
    }

    this.setState({ selected: selected });
    this.props.setValue(selected.length ? selected.toString() : null);
    this.props.onChange(this.props.name, selected.toString());
  }

  getDefaultCheck = (option) => {
    let isCheck = this.state.defaultChecked.indexOf(option.value) > -1;
    // console.log(this.state.defaultChecked, option, isCheck);
    return isCheck;
  }

  render() {
    const errorMessage = this.props.getErrorMessage();

    return (
      <div className={`form-group ${this.props.className ? this.props.className : ''}`}>
        <label className={this.state.required ? 'is-required' : ''}>{this.props.label}</label>

        <div className='checkbox-group'>
          {this.props.options.map((option, i) => (
            <label className='checkbox is-icon-box' key={`checkbox-${this.props.name}-${i}`}>
              <input
                type='checkbox'
                name={this.props.name}
                value={option.value}
                onChange={this.handleChange}
                defaultChecked={this.getDefaultCheck(option)}
              />
              <div className='content'>
                {option.icon ? <div className='icon'>{option.icon}</div> : ''}
                <div className='text'>{option.label}</div>
              </div>
            </label>
          ))}
        </div>
        {!!errorMessage ? <span className='error-message'>{errorMessage}</span> : ''}
      </div>
    );
  }
}

CheckGroup.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  default: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
};

export default withFormsy(CheckGroup);
