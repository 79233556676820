import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import DatePicker from "react-datepicker";
import RadioButtons from '../../components/form/RadioButtons';
import TW from 'date-fns/locale/zh-TW';

import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';

class MemberData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '0916****56',
      name: '王*明',
      defaultOpt: 1,
      canSubmit: false,
      emailValidation: false,
      form: {
        sex: { value: '1', required: true },
        nickname: { value: '', required: true },
        email: { value: '', required: true },
        date: { value: '' },
        selectedDate: { value: Date.parse('03/07/2000') },
      }
    }
  }

  selectedOption = (val) => {
    this.setState({
      defaultOpt: val
    })
  }

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }

  disabledButton = () => {
    this.setState({
      canSubmit: false
    })
  }

  onChange = (name, value) => {
    let form = Object.assign(this.state.form);
    form[name].value = value;
    this.setState({
      form: form
    })
  }

  nextStep = () => {
    console.log('nextStep');
  }
  // 串email驗證
  validateEmail = () => {
    if (!!this.state.form.email.value) {
      this.setState({ emailValidation: true })
    }
  }
  render() {
    return (
      <main>

        <NavAnchor
          pageTitle='帳戶管理'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="個人資料維護"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className="form-section pt-md-7 pt-4">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-md-7 mb-4">此服務提供您維護個人資料</h4>
            <div className="paper mb-md-7 mb-4">
              <Formsy onValid={this.enableButton} onInvalid={this.disabledButton}>
                <div className="form-group mb-md-4 mb-0">
                  <h4 className='mb-md-4 mt-2'>個人資料</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <div className="is-bg-lightgray70 px-3 py-3">
                        <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                          門號
                      </h5>
                        <h2 className='mb-0'>{this.state.phoneNumber}</h2>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="is-bg-lightgray70 px-3 py-3">
                        <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                          帳號
                    </h5>
                        <h2 className='mb-0'>{this.state.name}</h2>
                      </div>
                    </Grid>
                    <Grid className="mt-md-6 mt-2" item xs={12} md={6}>
                      <LabelInput
                        name='nickname'
                        className="hide-required"
                        required={true}
                        label='暱稱'
                        placeholder='請輸入你的暱稱'
                        value={this.state.form.nickname.value}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入你的暱稱'
                        }}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid className="mb-md-4" item xs={12} md={6}>
                      <label className="required">性別</label>
                      <RadioButtons
                        name='sex'
                        options={[
                          { value: '1', label: '男' },
                          { value: '2', label: '女' }
                        ]}
                        selected={this.state.form.sex.value}
                        getSelected={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={12} md={6}>
                      <LabelInput
                        name='email'
                        validations={{ isEmail: true }}
                        className="hide-required "
                        required
                        label='電子信箱'
                        placeholder='請輸入你的電子信箱'
                        value={this.state.form.email.value}
                        validationErrors='請輸入正確的 Email'
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {!this.state.emailValidation ? <div>
                        <LabelInput
                          name='email'
                          validations={{ isEmail: true }}
                          className="hide-required mb-0"
                          required
                          label='電子信箱'
                          placeholder='請輸入你的電子信箱'
                          value={this.state.form.email.value}
                          validationErrors='請輸入正確的 Email'
                          onChange={this.onChange}
                        />
                        <small className="email-validation is-text-darkgray50">未認證，請完成認證程序，<a onClick={() => { this.validateEmail() }} className="info-link">開始認證</a>。</small>
                      </div> : <div className="mb-md-6 mb-2">
                          <label>電子信箱</label>
                          <div className="checked-email">
                            <p>fet123@gmail.com</p>
                            <img src="/resources/cbu/e-service/images/checked@2x.png" alt="checked" />
                          </div>
                        </div>}

                    </Grid>
                    <Grid className='mb-md-6 mb-2' item xs={12} md={6}>
                      <label className="required">生日</label>
                      <DatePicker
                        dateFormat="Y/MM/dd"
                        lang="zh-TW"
                        required={true}
                        disabled={false}
                        maxDate={new Date()}
                        placeholderText="輸入出生年月日"
                        selected={this.state.form.date.value}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入出生年月日'
                        }}
                        onChange={date => this.onChange('date', date)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="required">生日</label>
                      <DatePicker
                        dateFormat="Y/MM/dd"
                        lang="zh-TW"
                        required={true}
                        disabled={true}
                        maxDate={new Date()}
                        placeholderText="輸入出生年月日"
                        selected={this.state.form.selectedDate.value}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入出生年月日'
                        }}
                        onChange={date => this.onChange('selectedDate', date)}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Formsy>
            </div>
            <div className="page-action">
              <Button className={this.state.canSubmit && !!this.state.form.date.value ? '' : 'disabled'} btnStyle='primary' size='large' onClick={this.nextStep}>確認修改</Button>
            </div>
          </div>
        </section>
      </main>
    )
  }
}

export default MemberData;