export const helpCenterInitialState = {
  headerType: 'common',
  footType: 'common',
  storeType: '',
  helpCenterSearchKeyword: '',
  helpCenterSearchHot: '',
  helpCenterSearchCategory: '',
  helpCenterFaqCategoryMenu: null,
  searchPath: ''
};

export const dataLayerInitialState = {
  dataLayerEventName: '',
  dataLayerPayload: {},
};

export const formInitialState = {
  formPayload: {
    URI: '',
    successUrl: '',
    failUrl: '',
  },
  formName: '',
};

export const analyticsInitialState = {
  customEventName: '',
  digitalData: {}
}

export const emmaInitialState = {
  useEmmaAvatar: true,
  show: true,
  link: "/content/ebu/tw/help-center/contact-us.html"
};