import React from 'react';
import PanelList from '../panelContent/PanelList'

import PropTypes from 'prop-types';

class CollapseDiscount extends React.Component {
  constructor(props) {
    super(props);
    this.collapse = React.createRef();
    this.state = {
      minHeight: window.innerWidth < 768 ? 240 : 160,
      collapseHeight: 0,
      collapseOpen: false,
    };

  }

  componentDidMount() {
    this.setCollapseHeight();

    window.addEventListener('resize', (e) => {
      this.setCollapseHeight();
    });
  }

  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };

  setCollapseHeight = () => {
    this.setState({
      minHeight: window.innerWidth < 768 ? 240 : 160,
      collapseHeight: this.collapse.current.children[0].clientHeight,
    });
  };

  render() {
    const {collapseOpen} = this.state;
    return (
      <div className='fui-collapse'>
        <div
          className='collapse-body'
          ref={this.collapse}
          style={{
            height: collapseOpen ? this.state.collapseHeight : this.state.minHeight,
          }}>
          <PanelList
            prefix='check'
            list={this.props.list}
          />
        </div>
        {
          this.state.collapseHeight > this.state.minHeight ? (
            <div className='fui-collapse-action mt-2'>
              <div role='button' onClick={this.toggleCollapse}>
                <span className='text'>{collapseOpen ? '收合' : '看更多'}</span>
                <i className={`${collapseOpen ? 'icon-minus' : 'icon-plus'}`}></i>
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}

CollapseDiscount.propTypes = {
  list: PropTypes.array
};

export default CollapseDiscount;
