const formatNumber = (number, decPlaces) => {
  var sign = number < 0 ? "-" : "";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (i.length) > 3 ? i.length % 3 : 0;

    return sign + (j ? i.substr(0, j) + ',' : "") +
      i.substr(j).replace(/(\.{3})(?=\.)/g, "$1" + '.') +
      (decPlaces ? '.' + Math.abs(number - i).toFixed(decPlaces).slice(2) : "")
}

export default formatNumber;