import React from 'react';

import Card from '../../card/Card';
import PropTypes from 'prop-types';

const HappyGoCard = (props) => {
    return (
        <section className={`pb-0 ${props.className}`}>
            <div className="fui-container">
                <div className="fui-section-header">
                    <h2 className="section-title">
                        {props.title}
                    </h2>
                    {
                        props.more ? (
                            <a href={props.more.link} className="fui-button is-text">
                                {props.more.text}
                                <i className='icon-chevron-right'></i>
                            </a>
                        ) : ''
                    }
                </div>
                <div className={`fui-cards is-happy-go ${props.cards.length===3 ? 'three' : 'four'}-card`}>
                    {
                        props.cards.map((card, idx) => (
                            <Card key={idx} {...card} />
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

HappyGoCard.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            public_at: PropTypes.string,
            meta: PropTypes.string,
            link: PropTypes.string
        })
    ),
    more: PropTypes.shape({
        link: PropTypes.string,
        text: PropTypes.string
    })
}

export default HappyGoCard;