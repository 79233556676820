import React from 'react';
import { Grid } from '@material-ui/core';
import NavAnchor from '../components/partials/NavAnchor';
import Breadcrumb from '../components/Breadcrumb';
import CarouselBanner3 from '../components/partials/carousel/CarouselBanner3';
import { CollapseMenu as Menu } from '../components/partials/collapse/CollapseMenu';
import GroupExtraLinkCard from '../components/partials/card/GroupExtraLinkCard';
import LoadMore from '../components/LoadMore';
import ProductMap from '../components/partials/ProductMap';
import FooterAd from '../components/ad/FooterAd';

import * as DiscountMock from '../mock/Discount';

class Discount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEn: false,
      data: null,
      article: DiscountMock.a,
      currentArticleLoadMore: true,
    };
  }

  loadMore = () => {
    // console.log(DiscountMock.loadMoreData);
    this.setState({
      article: [...this.state.article, ...DiscountMock.loadMoreData],
      currentArticleLoadMore: false,
    });
  };

  // 切換menu頁面
  selectMenu = val => {
    if (!!!val.content) {
      this.setState({
        article: DiscountMock[`${val.id}`],
      });
    }
  };

  render() {
    return (
      <main>
        <NavAnchor
        pageTitle='優惠'
        button={{
          text: '優惠通知',
          link: '#'
        }} />
        <Breadcrumb 
          color='white'
          breadcrumb={DiscountMock.breadcrumb}
        />
        <CarouselBanner3 slider={DiscountMock.sliders} />
        <div className='fui-container'>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={4}>
              <Menu selectMenu={this.selectMenu} menu={DiscountMock.menu} />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <div className='cards'>
                <GroupExtraLinkCard article={this.state.article} />
                <LoadMore
                  click={this.loadMore}
                  load={this.state.currentArticleLoadMore}
                  moreLabel={this.state.isEn ? 'More' : '展開看更多'}
                  noMoreLabel={this.state.isEn ? 'No More Article' : '沒有更多文章'}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <FooterAd
          image={{
            md: '/resources/cbu/e-service/images/cbu-promotion-banner-02-1920x156.jpg',
            sm: '/resources/cbu/e-service/images/cbu-promotion-banner-02-750x360.jpg',
          }}
          title='網路門市限定 週三驚爆閃購日，AirPods、藍牙耳機週週1折起'
          action={{
            text: '立即領折扣序號',
            link: '#',
          }} 
        />
        <ProductMap 
          {...DiscountMock.productMap}
        />
      </main>
    )
  }
}


export default Discount;