import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import DatePicker from "react-datepicker";
import Button from '../../components/Button';
import Select from '../../components/form/Select';
import CheckGroup from '../../components/form/CheckGroup';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import moment from 'moment'

let record = {
  head: ['動作', '點此取消預約'],
  content: [
    ['預約日期', '預約時間', '預約項目', '預約門市', '地點'],
    ['2020/06/15', '00:10~11:00', '續約', '瑞光門市', '台北市內湖區瑞光路468號']
  ]
}
let recordEvent = [
  {
    cancelEvent: 'cancel event 1',
    locationLink: 'go to store 1'
  }
]
let newForm = {
  number: {
    value: '',
    label: ''
  },
  bookingService: {
    value: '', required: false
  },
  recordEvent: recordEvent,
  receipt_send_city: { value: '', require: false },
  receipt_send_area: { value: '', require: false },
  store: { value: '', require: false },
  date: { value: '', require: false },
  time: { value: '', require: false },
  name: { value: '', require: false },
  contactNumber: { value: '', require: false },
  serviceNumber: { value: '', require: false },
}
class ServiceBooking extends Component {
  constructor(props) {
    super(props);
    this.form01 = React.createRef()
    this.form02 = React.createRef()
    this.form03 = React.createRef()
    this.state = {
      openForm: false,
      titleHeight: [],
      getTitleLength: [],
      record: {},
      form: {
        number: {
          value: '',
          label: ''
        },
        bookingService: {
          value: '', required: false
        },
        recordEvent: recordEvent,
        receipt_send_city: { value: '', require: false },
        receipt_send_area: { value: '', require: false },
        store: { value: '', require: false },
        date: { value: '', require: false },
        time: { value: '', require: false },
        name: { value: '', require: false },
        contactNumber: { value: '', require: false },
        serviceNumber: { value: '', require: false },
      },
      isMobile: false,
      canSubmit01: false,
      canSubmit02: false,
      canSubmit03: false,
    }
  }
  enableButton = (val) => {
    switch (val) {
      case 'form01':
        this.setState({
          canSubmit01: true
        })
        break;
      case 'form02':
        this.setState({
          canSubmit02: true
        })
        break;
      case 'form03':
        this.setState({
          canSubmit03: true
        })
        break;
      default:
        break;
    }

  };
  disableButton = (val) => {
    switch (val) {
      case 'form01':
        this.setState({
          canSubmit01: false
        })
        break;
      case 'form02':
        this.setState({
          canSubmit02: false
        })
        break;
      case 'form03':
        this.setState({
          canSubmit03: false
        })
        break;
      default:
        break;
    }
  };
  cleanTable = () => {
    this.setState({
      record: {}
    });
  };
  onChange = (name, value) => {
    // console.log({ name, value });
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }
  componentDidMount() {
    this.getTitleLength()
    window.addEventListener('resize', this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({
      isMobile: window.innerWidth < 960,
    });
  };
  getTitleLength = () => {
    setTimeout(() => {
      let length = 0
      let content = this.state.record.content
      let head = this.state.record.head
      // console.log(this.state.content);
      // console.log(this.state.head);
      let store = []
      if (!!content > 0) {
        content.map((row, i) => {
          row.map((target, j) => {
            let result = target.replace(/[^\x00-\xff]/g, "xx").length;
            length = length < result ? result : length
          })
          console.log(`i: `, i);
          store.push({
            row: i,
            height: length < 23 ? 65 : Math.floor(length / 18 * 34 + 24)
          })
          length = 0
        })
        head.map((header, i) => {
          let result = header.replace(/[^\x00-\xff]/g, "xx").length;
          if (store[i].height < result) {
            store[i].height = result < 19 ? 65 : (Math.floor(result / 18 * 28))
          }
        })
        // console.log(`store: `, store);
        this.setState({
          titleHeight: store
        })
      }
    }, 200);
  }
  getHeight = (i) => {
    let data = this.state.titleHeight
    if (data.length > 0 && !!data[i]) {
      return data[i].height
    } else {
      return 0
    }
  }
  form03Validation = () => {
    return this.state.canSubmit03 && !!this.state.form.receipt_send_area.value && !!this.state.form.receipt_send_city.value && !!this.state.form.store.value && !!this.state.form.date.value && !!this.state.form.time.value
  }
  cancelEvent = (i) => {
    let event = recordEvent
    // console.log(event[i - 1].cancelEvent);
  }
  gotoEvent = (i) => {
    let event = recordEvent
    // console.log(event[i - 1].locationLink);
  }
  cancelBtn = (item, i) => {
    return (
      <a key={i} className='underline-link is-text-black50' onClick={() => this.cancelEvent(i)}>{item}</a>
    )
  }
  getLocation = (item, index) => {
    return (
      <a
        onClick={() => this.gotoEvent(index)}
        className="is-text-darkgray70 d-flex flex-align-center justify-center"
      >
        {item}
        <img style={{ width: 30 }} src={process.env.PUBLIC_URL + "/resources/cbu/img/pin.svg"} alt="pin" />
      </a>
    )
  }
  submitData = () => {
    let area = this.state.form.receipt_send_area.value
    let city = this.state.form.receipt_send_city.value
    let store = this.state.form.store.value
    let date = moment(this.state.form.date.value).format('Y/MM/DD')
    let time = this.state.form.time.value
    let service = this.state.form.bookingService.value
    record.head.push('點此取消預約')
    record.content.push([date, time, service, store, '台北市內湖區瑞光路468號'])
    recordEvent.push({
      cancelEvent: 'cancel event 1',
      locationLink: 'go to store 1'
    })
    this.setState({
      record: record,
      recordEvent: recordEvent,
      form: newForm
    })
    this.forceUpdate()
    this.getTitleLength()
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='交易/異動紀錄'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="門市服務預約及查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="usage-rate mt-md-7 mt-4 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              <h4 className="is-text-darkgray50 mb-md-7 mb-5">
                此服務提供您符合門市預約相關項目的預約及查詢您的預約狀況。
              </h4>
              <div className="paper mt-md-4 mt-2 mb-0 pb-md-3 pb-2 pt-md-4 pt-2">
                <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-3 mb-0">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <h4 className='mb-md-4 mb-2'>門市服務預約須知</h4>
                      <ul className="green-dot-list mb-0">
                        <li>可預約服務項目：新申辦、續約、攜碼至遠傳、過戶、門市一對一商品教學</li>
                      </ul>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Formsy onValid={() => this.enableButton('form01')} onInvalid={() => this.disableButton('form01')} ref={this.form01}>
                <div className="paper mt-2 mb-0 pb-md-2 pb-4 merged  pt-md-4 pt-2">
                  <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-2 mb-0">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <h4>請輸入欲查詢的門號</h4>
                        <LabelInput
                          className="hide-required"
                          name='number'
                          required={true}
                          label='門號'
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入門號'
                          }}
                          placeholder='請輸入門號'
                          value={this.state.form.number.value}
                          onChange={this.onChange}
                        />
                        <div className="page-action">
                          <Button
                            onClick={() => { this.setState({ record: record }); this.getTitleLength() }}
                            link="#"
                            className={`${this.state.canSubmit01 ? '' : 'disabled'} mb-0`}
                            btnStyle='primary'
                            size='large'>
                            查詢報名記錄
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="paper mb-0 pb-md-7 pb-4 pt-md-4 pt-2">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <h4>以下為您目前的門市預約記錄</h4>

                      {Object.keys(this.state.record).length === 0 ? <div>
                        <div className="body is-text-darkgray50">查無預約門市資料</div>
                      </div> : <div>
                          <div className="compareTable content-16 clearfix text-wrap bill-table mt-0 mb-md-2 w-142">
                            <div className="tableLeft">
                              <div className="tableHead">
                                <table className='text-wrap'>
                                  <tbody>
                                    {this.state.record.head.map((item, i) => {
                                      return (
                                        <tr style={{ height: this.getHeight(i) - (this.state.isMobile ? 6 : 0) }} key={item + i}>
                                          <td
                                            className={`align-center ${i === 1 ? 'is-text-medium' : ''} ${i === 0 ? 'is-bg-lightgray70' : ''}`}
                                          // style={{ minWidth: i !== 2 ? 'auto' : null }}
                                          >
                                            {i > 0 ? this.cancelBtn(item, i) : item}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="tableRight w-164">
                              <div className="tableBody">
                                <div className="spaceBox">
                                  <table className="text-wrap">
                                    <tbody>
                                      {this.state.record.content.map((list, i) => (
                                        <tr style={{ height: this.getHeight(i) - (this.state.isMobile ? 6 : 0) }} key={list + i} className={i == 0 ? 'is-bg-lightgray70' : ''}>
                                          {list.map((item, j) => (
                                            <td
                                              style={{ minWidth: j !== 2 ? 164 : null }}
                                              key={item + j}
                                            >
                                              {/* {j == 4 && i == 1 ? this.getLocation(item, i) : { item }} */}
                                              {j === 4 && i > 0 ? this.getLocation(item, i) : item}
                                              {/* {j} */}
                                            </td>
                                          ))}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <small className="d-block mt-1 is-text-darkgray50">＊如欲取消預約，請點選「取消預約」按鈕，輸入預約時所填寫之姓名</small>
                        </div>}

                    </Grid>
                  </Grid>
                </div>
              </Formsy>
              <div className="paper mt-2 mb-0 pb-md-3 pb-3 merged">
                <Formsy onValid={() => this.enableButton('form02')} onInvalid={() => this.disableButton('form02')} ref={this.form02}>
                  <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-2 mb-0">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <h4 className='mb-1'>預約門市服務</h4>
                        <CheckGroup
                          onChange={this.onChange}
                          className='mb-2 hide-required'
                          validationErrors={{ isDefaultRequiredValue: '請選擇預約之項目' }}
                          label='您欲預約之項目（可複選）'
                          name='bookingService'
                          required={true}
                          default={this.state.form.bookingService.value}
                          options={[
                            { value: '4G升級5G', label: '4G升級5G' },
                            { value: '申裝業務(門號、手機、平板…電信業務)', label: '申裝業務(門號、手機、平板…電信業務)' },
                            { value: '服務業務(補卡、換號、過戶…服務業務)', label: '服務業務(補卡、換號、過戶…服務業務)' },
                            { value: '手機/平板教學', label: '手機/平板教學' },
                            { value: '智能家電體驗', label: '智能家電體驗' },
                            { value: '在地活動', label: '在地活動' }
                          ]}
                        />
                        <div className="page-action">
                          <Button
                            onClick={() => { this.setState({ openForm: true }) }}
                            link="#"
                            className={`${this.state.canSubmit02 ? '' : 'disabled'} my-0`}
                            btnStyle='primary'
                            size='large'>
                            查詢
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Formsy>
              </div>
              {this.state.openForm ? <div className="paper mb-0 pb-md-7 pb-2 pt-md-7 pt-3">
                <Formsy onValid={() => this.enableButton('form03')} onInvalid={() => this.disableButton('form03')} ref={this.form03}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <h4 className='mb-0'>請留下您的預約資訊</h4>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <h5 className='mb-0 mt-md-4 mt-2'>您欲前往之門市據點</h5>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Select
                            name="receipt_send_city"
                            label="縣市"
                            require={true}
                            options={[
                              { text: '台北市', value: '台北市' },
                              { text: '新北市', value: '新北市' }
                            ]}
                            default={'1'}
                            value={this.state.form.receipt_send_city.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Select
                            name="receipt_send_area"
                            require={true}
                            label="鄉鎮市區"
                            options={[
                              { text: '中正區', value: '1' },
                              { text: '萬華區', value: '2' }
                            ]}
                            value={this.state.form.receipt_send_area.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Select
                            name="store"
                            require={true}
                            label="無符合服務項目的門市"
                            options={[
                              { text: '內湖瑞光直營門市', value: '內湖瑞光直營門市' }
                            ]}
                            value={this.state.form.store.value}
                            onChange={this.onChange}
                          />
                        </Grid>

                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <h5 className='mb-0 mt-md-4 mt-2'>您欲前往日期與時段</h5>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <div className="form-group mb-md-4 mb-2">
                            <DatePicker
                              name="date"
                              dateFormat="Y/MM/dd"
                              lang="zh-TW"
                              minDate={new Date()}
                              selected={this.state.form.date.value}
                              onChange={date => this.onChange('date', date)}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Select
                            name="time"
                            label=""
                            options={[
                              { text: '09:00~10:00', value: '09:00~10:00' },
                              { text: '10:00~11:00', value: '10:00~11:00' },
                            ]}
                            value={this.state.form.time.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <LabelInput
                            className="hide-required mb-md-4 mb-2"
                            name='name'
                            required={true}
                            label='客戶姓名'
                            validationErrors={{
                              isDefaultRequiredValue: '請輸入客戶姓名'
                            }}
                            value={this.state.form.name.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <LabelInput
                            className="hide-required mb-md-4 mb-2"
                            name='contactNumber'
                            required={true}
                            label='聯絡電話'
                            validationErrors={{
                              isDefaultRequiredValue: '請輸入聯絡電話'
                            }}
                            value={this.state.form.contactNumber.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <LabelInput
                            className="hide-required mb-md-4 mb-2"
                            name='serviceNumber'
                            required={true}
                            label='預約服務門號'
                            validationErrors={{
                              isDefaultRequiredValue: '請輸入預約服務門號'
                            }}
                            value={this.state.form.serviceNumber.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                      </Grid>
                      <div className="page-action mt-1">
                        <Button
                          onClick={() => { this.submitData() }}
                          link="#"
                          className={`${this.form03Validation() ? '' : 'disabled'} mb-0`}
                          btnStyle='primary'
                          size='large'>
                          確認送出
                      </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Formsy>
              </div> : null}

            </div>
          </div>
        </section>
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.cleanTable()} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>無資料</Button>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ServiceBooking;