
export const navAnchor = {
  pageTitle: '合約費率',
  button: {
    text: '回個人專區',
    link: '/',
  }
}

export const banner = {
  title: "費率變更/異動",
  image: {
    md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
    sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
  }
}
export const collapseNote = {
  title: '注意事項',
  content: `
        <p>
          1.此服務僅提供費率的變更。<br/>
          2.若您未使用過遠傳行動上網功能，歡迎您至門市申請七日上網試用服務，若不需申請試用者，請直接變更費率。<br/>
          3.上網費率僅限國內使用，若需出國，其相關收費至該國漫遊費率查詢。
        </p>
    `,
};
