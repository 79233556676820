import React, { Component } from 'react';
import FormBanner from '../../components/partials/banner/FormBanner';
import Modal from 'react-modal';
import LabelTextarea from '../../components/form/LabelTextarea';
import Formsy from 'formsy-react';
import Dropdown from "../../components/Dropdown";
import LabelInput from '../../components/form/LabelInput';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';
class FixQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      haveData: true,
      canSubmit: false,
      step: 1,
      modalOpen: false,
      currentDeleteItem: null,
      table: {
        head: ['網路預約單號', 'NS2007070001', 'NS2007070002'],
        content: [
          ['維修單號', 'IMEI(手機序號)', '送件方式', '廠牌', '機型', '維修金額', '報修資料修改'],
          ['', '352008099891704', '處理中', 'SAMSUNG', 'Samsung GALAXY Note 8', '', { id: 1 }],
          ['', '352008099891705', '處理中', 'SAMSUNG', 'Samsung GALAXY Note 8', '', { id: 2 }],
        ]
      },
      form: {
        name: { value: '王大明', required: true },
        phone: { value: ' 021345672', required: true },
        cellphone: { value: ' 0912345678', required: true },
        email: { value: 'abc@gmail.com', required: true },
        submitWay: { value: '', required: false },
        issue: { value: '', required: true },
      },
      selectModel: {
        text: 'SAMSUNG GALAXY Note8',
        value: 'SAMSUNG GALAXY Note8'
      },
      isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
    }
  }
  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', e => {
        this.setState({
          isMobile: window.innerWidth < 960
        })
      })
  }
  selectModel = (val, v) => {
    // console.log(parseInt(val));
    // console.log({ val, v });
    this.setState({
      selectModel: v
    })
  }
  delete = (val) => {
    // console.log(`val:`, val);
    this.setState({
      modalOpen: true,
      currentDeleteItem: val
    })
    this.forceUpdate();
  }
  edit = (val) => {
    this.setState({
      step: 2
    })
  }
  confirmDelete = () => {
    let head = Object.assign(this.state.table.head);
    let content = Object.assign(this.state.table.content);
    head.splice(this.state.currentDeleteItem, 1)
    content.splice(this.state.currentDeleteItem, 1)
    // console.log(head, content);
    this.setState({
      modalOpen: false,
      currentDeleteItem: null
    })
    this.forceUpdate();
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }
  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }
  submit = () => {
    this.setState({ step: 1 })
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title={this.state.step === 1 ? '網路預約報修查詢' : '報修資料修改'}
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className={this.state.step === 2 ? 'd-block' : 'd-none'}>
          <div className='fui-container'>
            <Formsy className='w-100' onValid={() => this.enableButton()} onInvalid={() => this.disableButton()}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="hide-required form-group">
                    <label>送件方式</label>
                    <div className="text-input">
                      <input
                        disabled
                        value="親送門市"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="hide-required form-group">
                    <label>IMEI(手機序號)</label>
                    <div className="text-input">
                      <input
                        disabled
                        value="352008099891704"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="hide-required form-group">
                    <label>廠牌</label>
                    <div className="text-input">
                      <input
                        disabled
                        value="SAMSUNG"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="form-group">
                    <label>機型</label>
                    <Dropdown
                      className="is-button"
                      list={[
                        { value: '0', text: 'SAMSUNG GALAXY Note8' },
                        { value: '1', text: 'SAMSUNG GALAXY Note7' }
                      ]}
                      label={this.state.selectModel.text}
                      arrow={true}
                      hasCheck={false}
                      onChange={this.selectModel}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="hide-required form-group mb-0">
                    <LabelTextarea
                      placeholder='請輸入內容'
                      label='故障原因(選填)'
                      className='mb-2'
                      maxLength={200}
                      name='issue'
                      value={this.state.form.issue.value}
                      onChange={this.onChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LabelInput
                    name='name'
                    required
                    label='姓名'
                    className="hide-required"
                    placeholder='輸入姓名'
                    value={this.state.form.name.value}
                    validationErrors='請輸入正確的姓名'
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LabelInput
                    name='phone'
                    required
                    label='市話'
                    className="hide-required"
                    placeholder='輸入市話'
                    value={this.state.form.phone.value}
                    validationErrors='請輸入正確的市話'
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LabelInput
                    name='cellphone'
                    required
                    label='手機'
                    className="hide-required"
                    placeholder='輸入手機'
                    value={this.state.form.cellphone.value}
                    validationErrors='請輸入正確的手機'
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LabelInput
                    name='email'
                    required={true}
                    label='Email'
                    className="hide-required"
                    placeholder='輸入Email'
                    value={this.state.form.email.value}
                    validationErrors='請輸入正確的Email'
                    onChange={this.onChange}
                  />
                </Grid>
                <div className="page-action mb-0 w-100">
                  <Button btnStyle='primary' size="large" className='' onClick={() => { this.submit() }}>送出</Button>
                  <Button btnStyle='secondary' size="large" onClick={() => { this.setState({ step: 1 }) }}>取消修改</Button>
                </div>
              </Grid>
            </Formsy>
          </div>
        </section>
        <section className={this.state.step === 1 ? 'd-block' : 'd-none'}>
          <div className='fui-container'>
            <div className="paper pb-md-7">
              <h4 className='mt-md-4 mb-md-3'>預約報修記錄</h4>
              {
                this.state.haveData ?
                  <div className={`compareTable content-16 clearfix text-wrap bill-table mt-0 table-gray-head stick-to-right ${this.state.isMobile ? '' : 'w-142'}`}>
                    <div className="tableLeft">
                      <div className="tableHead">
                        <table className="table-gray-head">
                          <tbody>
                            {this.state.table.head.map((item, i) => {
                              return (
                                <tr key={item + i}>
                                  <td className={`align-center ${i === 0 ? 'is-text-medium' : ''}`}>{item}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="tableRight auto-width">
                      <div className="tableBody">
                        <div className="spaceBox">
                          <table className="table-gray-head compareList not-fixed">
                            <tbody>
                              {this.state.table.content.map((list, i) => (
                                <tr key={list + i}>
                                  {list.map((item, j) => {
                                    return (
                                      <td
                                        className={`${i === 0 ? 'is-text-medium' : ' is-text-regular'}`}
                                        key={item + j}
                                      >
                                        {!!item.id ? <div>
                                          <Button btnStyle='secondary' size="small" className='my-0' onClick={() => { this.edit(i) }}>
                                            修改
                                      </Button>
                                          <Button btnStyle='secondary' size="small" className='my-0 mr-0' onClick={() => this.delete(i)}>
                                            刪除
                                      </Button>
                                        </div> : item}
                                      </td>
                                    )
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  : <p className="body is-text-darkgray70">查無14天內預約報修記錄，請重新預約。</p>
              }
            </div>
          </div>
        </section>
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5 className='align-center'>您將刪除報修資料</h5>
            <p className='mt-0 align-center'>是否確定刪除報修資料？</p>
            <div className="page-action">
              <Button btnStyle='primary' className='' onClick={() => { this.confirmDelete() }}>確定</Button>
              <Button btnStyle='secondary' onClick={() => { this.setState({ modalOpen: false, currentDeleteItem: null }) }}>取消</Button>
            </div>
          </div>
        </Modal>
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ haveData: !this.state.haveData })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>5.13.0.1_幫助中心_網路預約報修查詢_空值</Button>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default FixQuery;