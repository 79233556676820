import React from 'react';
import Tab from '../tab/Tab'
import TabPane from '../tab/TabPane'
import PropTypes from 'prop-types';

class PanelTab extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {
            currentTab: this.props.activeTab?this.props.activeTab:0
        }

        this.tabChange = this.tabChange.bind(this)
    }

    rand() {
        return Math.ceil(Math.random()*100)
    }

    tabChange(i) {
        this.setState({
            currentTab: i
        })
    }

    render() {
        return (
            <div className="fui-panel-tab">
                <Tab {...this.props.tabs} default={this.props.activeTab} onChange={this.tabChange} />
                <div className="fui-tab-body" >
                {
                    this.props.children.map((child, i) => (
                        <TabPane active={this.state.currentTab === i} key={`${this.props.tabs.name}-panel-tab-pane-${i}`}>
                            {child}
                        </TabPane>
                    ))
                }
                </div>
            </div>
        )
    }
}

PanelTab.propTypes = {
    tabs: PropTypes.shape({
        name: PropTypes.string,
        list: PropTypes.arrayOf(
            PropTypes.shape({ 
                name: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired
            })
        )
    }),
    activeTab: PropTypes.number,
    tabContent: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.string
        })
    ),
}

export default PanelTab;