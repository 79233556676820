export const idNumberValid = (values, value) => {
  var reg = /^[A-Z]{1}[1-2]{1}[0-9]{8}$/;  //身份證的正規表示式;
  // 小寫可過
  if (!!value) {
    if (reg.test(value.toUpperCase())) {
      var s = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";  //把 A 取代成 10,把 B 取代成 11...的作法
      var ct = ["10", "11", "12", "13", "14", "15", "16", "17", "34", "18", "19", "20", "21",
        "22", "35", "23", "24", "25", "26", "27", "28", "29", "32", "30", "31", "33"];

      var i = s.indexOf(value.toUpperCase().charAt(0));
      var tempuserid = ct[i] + value.substr(1, 9); //若此身份證號若是A123456789=>10123456789
      var num = tempuserid.charAt(0) * 1;

      for (i = 1; i <= 9; i++) {
        num = num + tempuserid.charAt(i) * (10 - i);
      }

      num += tempuserid.charAt(10) * 1;

      if ((num % 10) === 0) {
        return true;
      }
      else {
        return '請輸入正確的證照號碼';
      }
    } else {
      return '請輸入正確的證照號碼';
    }

  }
}

export const simCode = (values, value) => {
  console.log(value);

  if (!!value) {
    if (value !== '0000') {
      return '末四碼輸入錯誤，請重新操作'
    } else {
      return true
    }
  }
}

export const phoneNumber = function (values, value) {
  return !value === false && value.length === 10 && /^09\d{8}$/.test(value)
    ? true
    : '請輸入正確的行動電話';
}