import React from 'react';
import Card from '../../card/Card';

import PropTypes from 'prop-types';

class ExtraPromotion extends React.Component {
  render () {
    return (
      <div className='result-extra'>
        <div className='fui-cards three-card'>
          {this.props.cards.map((card, j) => (
            <Card key={`result-item-extra-${j}`} {...card} />
          ))}
        </div>
      </div>
    )
  }
}

ExtraPromotion.propTypes = {
  cards: PropTypes.arrayOf(Card)
};

export default ExtraPromotion;