import React from 'react';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import MultiRuler from '../../components/partials/MultiRuler';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';

import * as Validation from '../../utils/validation';
let data = [
  {
    detailLink: '#123',
    applyLink: '#123',
    date: '2020年04月',
    period: '2020/03/20 至 2020/04/19',
    usage: {
      totalCall: '00:11:28',
      internet: '2.08 GB',
      payBill: '無需繳費'
    },
    fee: [
      {
        title: '電信費用',
        fee: 999,
        isRed: false
      },
      {
        title: '小額代收',
        fee: 120,
        isRed: false
      },
      {
        title: '本期新增金額合計',
        fee: 1119,
        isRed: true
      },
      {
        title: '累計應繳金額',
        fee: 0,
        isRed: true
      },
    ],
    payment: '#',
    resendPayment: '#',
    usageDetail: {
      inter: 9,
      outer: 3,
      local: 1,
      special: 0,
      internet: 0
    },
    usageAnalysis: 20
  },
  {
    detailLink: '#123',
    applyLink: '#123',
    date: '2020年05月',
    period: '2020/04/20 至 2020/05/19',
    usage: {
      totalCall: '00:11:28',
      internet: '2.08 GB',
      payBill: '無需繳費'
    },
    fee: [
      {
        title: '電信費用',
        fee: 999,
        isRed: false
      },
      {
        title: '小額代收',
        fee: 120,
        isRed: false
      },
      {
        title: '本期新增金額合計',
        fee: 1119,
        isRed: true
      }
    ],
    payment: null,
    resendPayment: '#',
    usageDetail: {
      inter: 9,
      outer: 3,
      local: 1,
      special: 0,
      internet: 0
    }
  },

  // 7.1.0.3_帳單與用量_帳單查詢_popupover_空值
  {
    detailLink: '#123',
    applyLink: '#123',
    date: '2020年06月',
    period: '2020/05/20 至 2020/06/19',
    usage: {
      totalCall: '00:11:28',
      internet: '2.08 GB',
      payBill: '無需繳費'
    },
    fee: [
      {
        title: '電信費用',
        fee: 999,
        isRed: false
      },
      {
        title: '小額代收',
        fee: 120,
        isRed: false
      },
      {
        title: '本期新增金額合計',
        fee: 1119,
        isRed: true
      },
      {
        title: '累計應繳金額（含未繳及溢繳金額）',
        fee: 0,
        isRed: true
      },
    ],
    payment: null,
    resendPayment: '#',
    usageDetail: null
  }
]

let periodOption = [
  { value: '1', text: '2020/04月份' },
  { value: '2', text: '2020/05月份' },
  { value: '3', text: '2020/06月份' },
]

class QueryIndex extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef()
    this.state = {
      modalOpen: false,
      selectBill: { value: '0', text: '0916-814-747' },
      billOption: [
        { value: '0', text: '0916-814-747' },
        { value: '1', text: '0911-814-747' }
      ],
      analysisList: [
        {
          name: '月租費',
          color: '#5cc3d2'
        },
        {
          name: '數據服務',
          color: '#9edfe9'
        },
        {
          name: '語音服務',
          color: '#f3cf63'
        },
        {
          name: '代收服務',
          color: '#fcdc6d'
        },
        {
          name: '國際服務',
          color: '#fc7b66'
        },
        {
          name: '加值服務',
          color: '#ffa188'
        },
        {
          name: '數據與語言服務',
          color: '#eff4f1'
        },
      ],
      analysisData: [
        {
          title: '3G',
          analysis: [
            {
              percentage: 1,
              color: '#5cc3d2'
            },
            {
              percentage: 20,
              color: '#9edfe9'
            },
            {
              percentage: 1,
              color: '#f3cf63'
            },
            {
              percentage: 5,
              color: '#fc7b66'
            },
            {
              percentage: 25,
              color: '#ffa188'
            },
            {
              percentage: 20,
              color: '#fc7b66'
            },
            {
              percentage: 18,
              color: '#ffa188'
            },
          ]
        },
        {
          title: '4G',
          analysis: [
            {
              percentage: 20,
              color: '#5cc3d2'
            },
            {
              percentage: 10,
              color: '#9edfe9'
            },
            {
              percentage: 5,
              color: '#f3cf63'
            },
            {
              percentage: 5,
              color: '#fc7b66'
            },
            {
              percentage: 15,
              color: '#ffa188'
            },
            {
              percentage: 5,
              color: '#eff4f1'
            },
          ]
        },
        {
          title: '5G',
          analysis: [
            {
              percentage: 25,
              color: '#5cc3d2'
            },
            {
              percentage: 30,
              color: '#9edfe9'
            },
            {
              percentage: 5,
              color: '#f3cf63'
            },
            {
              percentage: 15,
              color: '#fc7b66'
            },
            {
              percentage: 15,
              color: '#ffa188'
            },
            {
              percentage: 5,
              color: '#eff4f1'
            },
          ]
        },
      ],
      percentage: 20,
      canSubmit: false,
      form: {
        id: { value: '', required: true },
        id_number: { value: '', required: true },
      },
      selectPeriod: periodOption[0],
      periodOption: [],
      currentBill: data[0],
      login: true,
      noData: false
    }
  }
  componentDidMount() {
    this.setState({
      periodOption: periodOption,
      selectPeriod: periodOption[0]
    })
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }

  selectPeriod = (val) => {
    let arr = this.state.periodOption
    arr.map((item, index) => {
      if (val == item.value) {
        this.setState({
          currentBill: data[index],
          selectPeriod: item
        })
      }
    })
    this.forceUpdate()
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };
  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  selectBill = (val, v) => {
    // console.log(parseInt(val));
    console.log({ val, v });
    this.setState({
      selectBill: v
    })
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={[
            {
              text: '立即續約',
              link: '#',
            },
            {
              text: '回個人專區',
              link: '/',
            },
          ]}
        />

        <FormBanner
          title="帳單查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        {/* 7.1.0.2_帳單與用量_帳單查詢_無帳單 */}
        {this.state.noData ?
          <section className="query-index pb-0">
            <div className="fui-container">
              <div className="paper">
                <p className="body is-text-darkgray50 mt-0 mb-3">親愛的用戶，您的門號目前尚未有帳單產生，您可使用「未出帳金額／上網用量」查詢門號使用詳細情形，謝謝！</p>
                <Button link="#" btnStyle='primary' size='large'>未出帳金額／上網用量</Button>
              </div>
            </div>
          </section> : null
        }

        {
          !this.state.login ?
            <section className="query-index pb-md-0 pb-5">
              <div className="fui-container">
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼進行確認</h4>
                      <LabelInput
                        className="mb-2"
                        name='id_number'
                        required={this.state.form.id_number.required}
                        label='證照號碼'
                        validations={{
                          idNumberValid: Validation.idNumberValid
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入證照號碼'
                        }}
                        placeholder='請輸入證照號碼'
                        value={this.state.form.id_number.value}
                        onChange={this.onChange}
                      />
                      <p className="body-1 is-text-darkgray50 mt-0 mb-3">
                        身分說明：<br />
                        個人戶：身分證字號<br />
                        公司戶：統一編號或稅籍編號<br />
                        外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼<br />
                        ※當您使用此服務時，視為您已詳細閱讀並同意"<a className="info-link" to="#">遠傳官網會員條款</a>"所有內容 。
                      </p>
                      <Button
                        onClick={() => { this.setState({ login: true }) }}
                        link="#"
                        className={this.state.canSubmit ? '' : 'disabled'}
                        btnStyle='primary'
                        size='large'>
                        確認
                      </Button>
                    </Grid>
                  </Grid>
                </Formsy>
              </div>
            </section> :

            <div>
              <section className="query-index pb-0">
                <div className="fui-container">
                  <Dropdown
                    className="is-button d-block d-md-none"
                    list={this.state.billOption}
                    label={this.state.selectBill.text}
                    arrow={true}
                    hasCheck={false}
                    onChange={this.selectBill}
                  />
                  <Dropdown
                    className="is-button d-block d-md-none"
                    list={this.state.periodOption}
                    label={this.state.selectPeriod.text}
                    arrow={true}
                    hasCheck={false}
                    onChange={(item) => this.selectPeriod(item)}
                  />
                  <h2 className="mb-0">{this.state.currentBill.date}帳單</h2>
                  <div className="d-flex justify-between flex-align-center">
                    <div className="body is-text-darkgray50">
                      費用計算期間：{this.state.currentBill.period}
                    </div>
                    <div className="d-inline-flex float-right" style={{ flex: '1 1 auto', justifyContent: "flex-end" }}>
                      <Dropdown
                        className="is-button d-none d-md-block mr-4"
                        list={this.state.billOption}
                        label={this.state.selectBill.text}
                        arrow={true}
                        hasCheck={false}
                        onChange={this.selectBill}
                      />
                      <Dropdown
                        className="is-button d-none d-md-block"
                        list={this.state.periodOption}
                        label={this.state.selectPeriod.text}
                        arrow={true}
                        hasCheck={false}
                        onChange={(item) => this.selectPeriod(item)}
                      />
                    </div>
                  </div>
                  <div className="paper mt-4 mb-2">
                    <div className="d-flex justify-between flex-align-center">
                      <h4 className="mt-md-4 mt-0 mb-md-3 mb-2">
                        本期使用總量
                    </h4>
                      <Link className='fui-button is-secondary is-small mr-0' to={this.state.currentBill.applyLink}>
                        <span className='text'>申請電子帳單</span>
                      </Link>
                    </div>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <div className="is-bg-lightgray70 px-3 py-3">
                          <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                            通話時數
                      </h5>
                          <h2 className='mb-0'>{this.state.currentBill.usage.totalCall}</h2>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="is-bg-lightgray70 px-3 py-3">
                          <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0 d-sm-block d-flex justify-between">
                            上網用量
                        <Tooltip
                              parentNode={null}
                              content={<i className='icon-information is-text-darkgray100 ml-1 d-flex'></i>}
                              trigger="click"
                              tooltip={'此處上網用量是指您該月份帳單內所有門號的上網用量加總，各門號使用明細請至「帳單明細」查詢。'}
                            />
                          </h5>
                          <h2 className='mb-0'>{this.state.currentBill.usage.internet}</h2>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="is-bg-lightgray70 px-3 py-3">
                          <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                            繳費期限
                      </h5>
                          <h2 className='mb-0'>{this.state.currentBill.usage.payBill}</h2>
                        </div>
                      </Grid>
                    </Grid>
                    <div className="d-flex justify-between flex-align-center mt-md-2 pt-md-4 mt-4">
                      <h4 className='m-0'>費用明細</h4>
                      <Link className='fui-button is-secondary is-small mr-0' to={this.state.currentBill.detailLink}>
                        <span className='text'>查詢帳單明細</span>
                      </Link>
                    </div>
                    <div className="frammer">
                      {this.state.currentBill.fee.map((item, index) => {
                        return (
                          <div className="frammer--list" key={index}>
                            <h5>{item.title}</h5>
                            {item.isRed ? <h2 className='is-text-error'>{item.fee.toLocaleString()}元</h2> : <h4>{item.fee}元</h4>}
                          </div>
                        )
                      })}
                    </div>
                    <div className='page-action'>
                      {this.state.currentBill.payment ? <Button link={this.state.currentBill.payment} btnStyle='primary' size='large'>我要繳費</Button> : null}
                      <Button link={this.state.currentBill.resendPayment} btnStyle="secondary" size='large'>補寄帳單</Button>
                    </div>
                  </div>
                </div>
              </section>
              <section className='usage-rate no-bg pb-2'>
                <div className="fui-container">
                  <div className="paper mb-0">
                    <div className=''>
                      <h4 className="mb-2 mt-md-3 mt-0">本月帳單費用分析</h4>
                      {this.state.currentBill.usageAnalysis ?
                        <div>
                          <p className="mt-0 mb-7">此分析圖提供您門號在該月份帳單內各項收費的金額及佔比，不含其他優惠及費用。故與您的帳單本期新增金額可能會有所不同。</p>
                          {this.state.analysisData.map((item, index) => {
                            return (
                              <MultiRuler
                                key={index}
                                {...item}
                              />
                            )
                          })}
                          <div className="analysis-list">
                            {this.state.analysisList.map((item, i) => {
                              return (
                                <div className="list-block" key={item, i}>
                                  <div className="rectangle" style={{ backgroundColor: item.color }} />
                                  {item.name}
                                </div>
                              )
                            })}
                          </div>
                          <div className="page-action">
                            <Button
                              onClick={() => { console.log('hi'); }}
                              link="#"
                              className="mt-md-3 mb-md-4 mt-3 mb-0"
                              btnStyle='primary'
                              size='large'>
                              六期帳單分析
                          </Button>
                          </div>
                        </div> : <p className='is-text-darkgray50 mb-0 mt-4 body'>本月無使用記錄，謝謝。</p>}

                    </div>
                  </div>
                </div>
              </section>

              <section className='usage-rate no-bg pb-4'>
                <div className="fui-container">
                  <div className="paper">
                    <div className='rate-group'>
                      <h4 className="mb-2 mt-md-3 mt-0">本月通話/使用明細</h4>
                      {this.state.currentBill.usageDetail ?
                        <div>
                          <p className="mt-0">此分析圖提供您門號在該月份撥打國內通話的分鐘數。</p>
                          <div className="item-wrapper">
                            <div className='rate-item'>
                              <div className='number'>
                                <h1>{this.state.currentBill.usageDetail.inter}</h1>
                                <h6 className='usage'>分鐘</h6>
                              </div>
                              <div>網內通話</div>
                            </div>
                            <div className='rate-item'>
                              <div className='number'>
                                <h1>{this.state.currentBill.usageDetail.outer}</h1>
                                <h6 className='usage'>分鐘</h6>
                              </div>
                              <div>網外通話</div>
                            </div>
                            <div className='rate-item'>
                              <div className='number'>
                                <h1>{this.state.currentBill.usageDetail.local}</h1>
                                <h6 className='usage'>分鐘</h6>
                              </div>
                              <div>市話</div>
                            </div>
                            <div className='rate-item'>
                              <div className='number'>
                                <h1>{this.state.currentBill.usageDetail.special}</h1>
                                <h6 className='usage'>分鐘</h6>
                              </div>
                              <div>特殊撥號</div>
                            </div>
                            <div className='rate-item'>
                              <div className='number'>
                                <h1>{this.state.currentBill.usageDetail.internet}</h1>
                                <h6 className='usage'>分鐘</h6>
                              </div>
                              <div>智慧網路</div>
                            </div>
                          </div>
                        </div> : <p className='is-text-darkgray50 mb-0 mt-4 body'>本月無使用記錄，謝謝。</p>}

                    </div>
                  </div>
                </div>
              </section>
            </div>
        }
        <Modal
          onAfterOpen={e => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={e => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel="Example Modal"
          className="fui-prompt-modal">
          <div className="fui-prompt-modal-body">
            <h5>提示訊息</h5>
            <img style={{ width: 140 }} src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/error@2x.jpg'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/error@2x.jpg'} alt='fail' />
            <p className='mt-0 mb-4'>系統繁忙中，請稍後再試，謝謝！</p>
            <div className="page-action">
              <Button btnStyle='primary' onClick={() => this.setState({ modalOpen: false })}>確定</Button>
            </div>
          </div>
        </Modal>
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ modalOpen: true })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>Open Modal</Button>
            </div>
          </div>
        </div>
      </main >
    )
  }
}

export default QueryIndex;