import React from 'react';

import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import FormBanner from '../../components/partials/banner/FormBanner';
import RadioButtons from '../../components/form/RadioButtons';
import CheckGroup from '../../components/form/CheckGroup';
import PanelInformation from '../../components/panelContent/PanelInformation';
import RadioGroup from '../../components/form/RadioGroup';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';

class RatePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        step01: { value: null, required: false },
        step02: { value: null, required: false },
        step03: { value: null, required: false },
        step04: { value: null, required: false },
      },
    }
    this.onChange = this.onChange.bind(this);
  }
  onChange(name, value) {
    let form = Object.assign(this.state.form);
    form[name].value = value;
    this.setState({
      form: form,
    });
  }
  render() {
    return (
      <main className="rate-plan">
        
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '資費方案', link: '/' },
            { text: '其他服務與資費', link: '/' },
            { text: '其他收費', link: '/' },
          ]}
          color='white'
        />

        <SectionBanner2
          image={{
            md: '/resources/cbu/e-service/images/ebu-survey-banner-1440-x-240-01-copy-2@2x.jpg',
            sm: '/resources/cbu/e-service/images/ebu-survey-banner-375-x-170-01@2x.jpg',
          }}
          title='資費試算 輕鬆配對理想方案'
          description='還在尋找最適合自己的方案嗎？<br/>讓資費試算幫你找出最精打細算的組合！'
        />

        <section className="">
          <div className="fui-container">
            <Grid>
              <Formsy
                onValidSubmit={this.submit}
                onValid={this.enableButton}
                onInvalidSubmit={this.disableButton}
                noValidate
                ref={this.form}>
                <RadioGroup
                  validationErrors='請選擇下方試算方案'
                  onChange={this.onChange}
                  label='請選擇下方試算方案'
                  name='step01'
                  required={this.state.form.step01.required}
                  default={this.state.form.step01.value}
                  options={[
                    {
                      value: '0',
                      label: '4G'
                    },
                    {
                      value: '1',
                      label: '5g'
                    }
                  ]}
                />
                {this.state.form.step01.value !== null ?
                  <RadioGroup
                    validationErrors='請選擇下方試算方案'
                    onChange={this.onChange}
                    label='此門號的使用目的?'
                    name='step02'
                    required={this.state.form.step02.required}
                    default={this.state.form.step02.value}
                    options={[
                      {
                        value: '0',
                        label: '語音通話及上網'
                      },
                      {
                        value: '1',
                        label: '上網'
                      }
                    ]}
                  /> : null
                }
                {this.state.form.step02.value !== null ?
                  <RadioGroup
                    validationErrors='請選擇下方試算方案'
                    onChange={this.onChange}
                    label='您使用行動上網最主要的目的?'
                    name='step03'
                    required={this.state.form.step03.required}
                    default={this.state.form.step03.value}
                    options={[
                      {
                        value: '0',
                        label: '瀏覽資訊、社群軟體 (新聞、Line、Skype、Facebook等)'
                      },
                      {
                        value: '1',
                        label: '遊戲、影片、音樂、影像電話 (Youtube、PPS、觀看線上影城、收聽線上音樂等)'
                      }
                    ]}
                  /> : null
                }
                {this.state.form.step03.value !== null ?
                  <RadioGroup
                    validationErrors='請選擇下方試算方案'
                    onChange={this.onChange}
                    label='您最近1個月的帳單金額 (語音+上網總費用)?'
                    name='step04'
                    required={this.state.form.step04.required}
                    default={this.state.form.step04.value}
                    options={[
                      {
                        value: '0',
                        label: '$600 以下'
                      },
                      {
                        value: '1',
                        label: '$601 - $1,000'
                      },
                      {
                        value: '3',
                        label: '$1,001 - $1,500'
                      },
                      {
                        value: '4',
                        label: '$1,500 以上'
                      },
                    ]}
                  /> : null
                }
                {this.state.form.step04.value !== null ? <div className="result">
                  <h2>您的理想方案</h2>
                  <div className="result-wrapper">
                    <div className="left">
                      <img src={process.env.PUBLIC_URL + "/resources/cbu/e-service/images/calculator.svg"} alt="calculator" />
                      <h5>資費試算結果</h5>
                      <p>因為您使用入門用量型的月租費，加上您同時使用語音及上網，推薦您使用遠傳新絕配系列資費，讓您輕鬆控制預算的同時，享用遠傳4.5G超級三頻網路，讓您飆出最快速!</p>
                    </div>
                    <div className="right">
                      <h6>試算結果建議您:</h6>
                      <h2>高速飆網699</h2>
                      <p>選擇高速飆網系列，即刻享用遠傳4.5G超級三頻網路，讓您飆出最快速!</p>
                      <div className='read-more button-group two-buttons'>

                        <Button link='newsContentVersion2' size='medium' className='w-100' btnStyle='primary'>
                          立即申辦
                        </Button>
                        <Button link='newsContentVersion2' size='medium' className='w-100' btnStyle='secondary'>
                          重新試算
                        </Button>
                      </div>
                      <div className="body-2 is-text-gray100">資費試算結果僅供參考。用戶使用手機行為變更時，適合的費率方案也可能會有所改變。</div>
                    </div>
                  </div>
                </div> : null}

              </Formsy>
            </Grid>
          </div>
        </section>
        <PanelInformation title='貼心小叮嚀' content='<ol>
          <li>「資費試算」為用戶自行填入撥打行為，所提供之資費推薦。</li>
          <li>「新推薦資費」，僅適用遠傳門號用戶，此服務是依據用戶的用量提供最近六個月的推薦資費。會員登入網址如下: https://ecare.fetnet.net/eServiceV3/bestRateController/bestRateQueryInitialPage.action</li>
        </ol>' />
      </main>
    );
  }
}

export default RatePlan;