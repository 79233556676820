import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import AdaptHeightBanner from '../../components/partials/banner/AdaptHeightBanner';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import Tab from '../../components/tab/Tab'
import TabPane from '../../components/tab/TabPane'
import Breadcrumb from '../../components/Breadcrumb';
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';

class filterInstruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0
    }
  }
  render() {
    return (
      <main>
        <Header />
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回個人專區',
            link: '#',
          }}
        />
        <Breadcrumb
          color='white'
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/cbu/' },
            { text: '服務申請證件提醒', link: '#' },
          ]}
        />
        <AdaptHeightBanner
          height={{
            desktop: 360,
            mobile: 180
          }}
          bgImg={{
            md: '/resources/cbu/img/cbu-eservice-service-application-certificate-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-eservice-service-application-certificate-750x400.jpg',
          }}
          title='服務申請證件提醒'
        />
        <section className="ribbon-bg pb-md-8 pb-7 pt-md-7 pt-4">
          <div className='fui-container'>
            <h4 className="is-text-darkgray50 mb-md-8 mb-6">至全省遠傳門市或特約服務中心辦理電信服務時，請依照下列說明攜帶相關證件</h4>
            <Tab {...{
              name: 'filter-tab',
              list: [
                { name: 'filter-1', label: '個人用戶' },
                { name: 'filter-1', label: '公司及機關團體用戶' }
              ],
            }} onChange={index => this.setState({ currentTab: index })} scroll={true} />
            <TabPane active={this.state.currentTab === 0}>
              <div className='mt-1 no-scrollbar'>
                <CollapsePaper
                  title="已成年人（20歲以上）"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="green-dot-list m-0">
                        <li>第一證件正本：中華民國國民身分證或護照。</li>
                        <li>第二證件正本：健保卡、駕照（台灣核發）、護照、戶口名簿、戶籍謄本、軍人身份證、學校核發之學生證、身心障礙證明、榮民證、替代役役男身份證、技術士證(如美容、烘培)、其他(符合台灣政府機關核發之個人身份證明文件，需含姓名、身份證號，例如：國家圖書館閱覽證)</li>
                      </ul>
                      <small className="is-text-gray100 d-block mb-2">
                        說明：以護照當作第一證件時，有效之第二證明文件須登載「住址」。
                      </small>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="已婚未成年人（未滿20歲）"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="green-dot-list m-0">
                        <li>第一證件正本：中華民國國民身分證(身份證配偶欄需登記配偶姓名，若配偶欄無配偶姓名，需檢附戶籍謄本) 或護照</li>
                        <li>第二證件正本：健保卡、駕照（台灣核發）、戶口名簿、戶籍謄本、軍人身份證、學校核發之學生證、身心障礙證明、替代役役男身份證、技術士證(如美容、烘培)、其他(符合台灣政府機關核發之個人身份證明文件，需含姓名、身份證號，例如：國家圖書館閱覽證)</li>
                      </ul>
                      <small className="is-text-gray100 d-block mb-2">
                        說明：以護照當作第一證件時，有效之第二證明文件須登載「住址」。
                      </small>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="未婚未成年人（未滿20歲）"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <p>需連同法定代理人到場辦理或攜帶法定代理人授權書</p>
                      <ul className="green-dot-list m-0">
                        <li>第一證件正本：本人及法定代理人中華民國國民身分證或護照。(本人若未滿15歲因未領中華民國國民身分證，可用戶口名簿作為第一證件，但第二證件應提供與戶口名簿不同之證件)</li>
                        <li>第二證件正本：本人及法定代理人健保卡、駕照（台灣核發）、戶口名簿、戶籍謄本、軍人身份證、學校核發之學生證、身心障礙證明、替代役役男身份證、技術士證(如美容、烘培)、其他(符合台灣政府機關核發之個人身份證明文件，需含姓名、身份證號，例如：國家圖書館閱覽證)</li>
                      </ul>
                      <small className="is-text-gray100 d-block mb-2">
                        說明：<br />
                        １）法定代理人若未到場辦理，則需攜帶<a href="#" className="info-link">法定代理人授權書</a>辦理。<br />
                        ２）以護照當作第一證件時，有效之第二證明文件須登載「住址」。<br />
                      </small>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="代理人"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <p>需檢附本人及代理人雙方雙證件外籍攜帶代理人授權書</p>
                      <ul className="green-dot-list m-0">
                        <li>第一證件正本：本人及代理人中華民國國民身分證。</li>
                        <li>第二證件正本：本人及代理人健保卡、駕照（台灣核發）、護照、戶口名簿、戶籍謄本、軍人身份證、學校核發之學生證、身心障礙證明、榮民證、替代役役男身份證、技術士證(如美容、烘培)、其他(符合台灣政府機關核發之個人身份證明文件，需含姓名、身份證號，例如：國家圖書館閱覽證)</li>
                        <li>檢附：攜帶<a href="#" className="info-link">「代辦委託書」</a></li>
                      </ul>
                      <small className="is-text-gray100 d-block mb-2">
                        說明：代辦人需為持有中華民國身分證件並年滿20歲且具行為能力之成年人。
                      </small>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="外籍人士"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="green-dot-list m-0">
                        <li>第一證件正本：本人護照、外僑永久居留證、大陸居民往來台灣通行證(僅適用大陸人士)。</li>
                        <li>第二證件正本：在台居留證、中華民國台灣地區入出境許可證、在台工作證、駕照（台灣核發）、國外政府核發之身份證件、簽證、健保卡、身心障礙證明、政府核發之旅行證、其他 (不含臨時通行證、入港證、漁工證、船員證)</li>
                      </ul>
                      <small className="is-text-gray100 d-block mb-2">
                        說明：<br />
                      １）需年滿20歲 。(申辦預付卡若屬菲律賓、印尼、越南、中國大陸、香港、澳門、馬來西亞、泰國、新加坡等國國籍年滿18歲則不受此限)<br />
                      ２） 證明文件有效期限須大於門號申辦日6個月以上。
                      </small>
                    </Grid>
                  </Grid>
                </CollapsePaper>
              </div>
            </TabPane>
            <TabPane active={this.state.currentTab === 1}>
              <div className='mt-1 no-scrollbar'>
                <CollapsePaper
                  title="一般公司戶（公司負責人本人親辦）"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="green-dot-list m-0">
                        <li>公司證明文件：營利事業登記證或抄錄登記表或變更登記表或設立登記表或核准公文正本/影本。(需加蓋公司大小章)</li>
                        <li>公司負責人身份證正本及第二身份證件正本。</li>
                        <li>公司印鑑章，及負責人或代表人章(即公司大小章)。</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="一般公司戶（公司代理人到場申辦）"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="green-dot-list m-0">
                        <li>公司證明文件：營利事業登記證或抄錄登記表或變更登記表或設立登記表或核准公文正本/影本。(需加蓋公司大小章)</li>
                        <li>公司負責人身份證及第二身份證件正本/影本及代理人的身分證正本及第二身份證件正本</li>
                        <li><a href="#" className="info-link">代辦授權書</a>加蓋公司大小章(或負責人私章)</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="公司籌備處"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="green-dot-list m-0">
                        <li>財政部國稅局稅捐稽徵處之扣繳單或統一編號編配通知單正本。</li>
                        <li>公司負責人或代表人身份證正本及第二身份證件正本。</li>
                        <li>公司印鑑章，及負責人或代表人章(即公司大小章)。</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="外商公司"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="green-dot-list m-0">
                        <li>外國公司申請指派代表人在中華民國境內行為報備卡正本。</li>
                        <li>代表人身份證正本及第二身份證件正本。</li>
                        <li>公司印鑑章，及代表人章(即公司大小章)。</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="私立學校/文化單位/立案補習班/人民團體"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="green-dot-list m-0">
                        <li>立案登記證或稅籍證明或免稅證明正本。</li>
                        <li>機關負責人身份證正本及第二身份證件正本。</li>
                        <li>機關(人民團體)印鑑章，及負責人章。</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="政府、軍警、檢調、公立學校等單位"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="green-dot-list m-0">
                        <li>出具加蓋關防或單位最高主管職銜章之公文函正本。</li>
                        <li>承（代）辦人身份證正本及第二身份證件正本。</li>
                        <li>關防或承辦單位最高主管簽章</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
                <CollapsePaper
                  title="專業人士（如會計師、律師、建築師事務所或診所等）"
                  className='mb-2'
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ul className="green-dot-list m-0">
                        <li>開業證明或個人證照或稅籍證明正本。</li>
                        <li>負責人身份證正本及第二身份證件正本。</li>
                        <li>負責人章。</li>
                      </ul>
                    </Grid>
                  </Grid>
                </CollapsePaper>
              </div>
            </TabPane>
          </div>
        </section>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
                <ol>
                  <li>代理人需持有中華國民身分證並年滿20歲且具行為能力者之成年人</li>
                </ol>
              `}
        />
      </main>
    );
  }
}

export default filterInstruction;