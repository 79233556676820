export const tabContent = [
    {
        tabs: {
            name: 'addValue',
            list: [
                { name: 'amount', label: '網路定量', meta: '計量型上網' },
                { name: 'daily', label: '網路吃到飽', meta: '計日型上網' },
                { name: 'phonecall', label: '網路/簡訊', meta: '語音通話' },
            ],
        },
        tabContent: {
            title: '計量型上網',
            id: 'addValue',
            cards: [
                {
                    icon: '/resources/cbu/prepaid/images/prepaid-signup-plan-icon-01.png',
                    iconAlt: 'program1',
                    subTitle: '偶爾上網 / 通話需求',
                    tag: '網路限定',
                    list: [
                        '上網流量 1.2GB',
                        '國內通話費 $100',
                        '網內通話費 $2000'
                    ],
                    button: [
                        {
                            title: '了解更多',
                            link: '#'
                        },
                    ]
                },
                {
                    icon: '/resources/cbu/prepaid/images/prepaid-signup-plan-icon-02.png',
                    iconAlt: 'program1',
                    subTitle: '好用通訊軟體及少量通話',
                    list: [
                        '上網流量 2.2GB',
                        '國內通話費 $195',
                        '網內通話費 $3000'
                    ],
                    button: [
                        {
                            title: '了解更多',
                            link: '#'
                        },
                    ]
                },
                {
                    icon: '/resources/cbu/prepaid/images/prepaid-signup-plan-icon-03.png',
                    iconAlt: 'program1',
                    subTitle: '就愛開話匣 怎麼講都省錢<br/>網內外通通1.8元',
                    list: [
                        '國內通話費 $300',
                        '3日上網吃到飽'
                    ],
                    button: [
                        {
                            title: '了解更多',
                            link: '#'
                        },
                    ]
                },
                {
                    icon: '/resources/cbu/prepaid/images/prepaid-signup-plan-icon-04.png',
                    iconAlt: 'program1',
                    subTitle: '3日短期上網不斷線',
                    list: [
                        '3天上網吃到飽',
                        '國內通話費 $100',
                        '網內通話費 $2000'
                    ],
                    button: [
                        {
                            title: '了解更多',
                            link: '#'
                        },
                    ]
                },
                {
                    icon: '/resources/cbu/prepaid/images/prepaid-signup-plan-icon-05.png',
                    iconAlt: 'program1',
                    subTitle: '5天流量吃飽不降速',
                    list: [
                        '5天上網吃到飽',
                        '國內通話費 $200',
                        '網內通話費 $3000'
                    ],
                    button: [
                        {
                            title: '了解更多',
                            link: '#'
                        },
                    ]
                },
            ]
        },
    },
];

export const tabs = {
    name: '5g-tab',
    icon: false,
    title: true,
    list: [
        {
            name: 'cbu',
            meta: '生活',
            label: '迎向未來生活',
        },
        {
            name: 'ebu',
            meta: '商務',
            label: '產業新進化',
        },
    ],
    default: 0
};
